import React, {useRef} from 'react';
import { 
        Typography,
        Paper,
        Box,
        Grid,
        TextField,
        MenuItem,
        Alert,
        OutlinedInput
} from '@mui/material';
import { styled } from '@mui/material/styles';
import NumberFormat from 'react-number-format';
import Category from './Category';
import Specification from './Specification';
const weights = [
    {
      value: 'gram',
      label: 'Gram (g)',
    },
    {
      value: 'kg',
      label: 'Kilogram (kg)',
    },
  ];

const WeightFormat = React.forwardRef((props, ref) => (
    <NumberFormat
        {...props.other}
        onValueChange={(values) => {
          props.onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        customInput={TextField}
        placeholder={props.placeholder}
        value={props.value}
        helperText={props.helperText}
        error={props.error}
        thousandSeparator={"."}
        decimalSeparator={","}
        allowNegative={false}
        decimalScale={2}
        allowedDecimalSeparators={false}
        isNumericString
      />
));


export default function Information({onChange, onUpdateName, product, error}) {
    const { name, description, weight, weight_type, product_options, product_variants, product_specifications } = product;
    
    const inputRef = useRef(null);
    function productWeight() {
        if (product_options.length !== 0 || product_variants.length !== 0) {
            return null;
        }

        return (
            <WeightFormat
                error={!!error('weight')}
                name="weight"
                value={weight}
                placeholder="Masukan Berat"
                inputRef={inputRef}
                helperText={error('weight')}
                variant="outlined"
                onChange={onChange} />
        )
    }
    
    return (
        <ContentPaper elevation={3}>
            <Grid container spacing={6} direction="column">
                <Grid item container>
                    <Grid item xs>
                        <Typography fontWeight={700} variant="h6" lineheight="normal">
                            Informasi Produk
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Alert severity="error">
                            Wajib
                        </Alert>
                    </Grid>
                </Grid>
                <Grid item container direction="column" spacing={5}>
                    <Grid item container spacing={8}>
                        <Grid item xs={4}>
                            <Typography fontSize={16} fontWeight={700} component="div">
                                Nama Produk
                            </Typography>
                            <Typography variant="body2" gutterBottom color="textSecondary">
                                Masukan nama produk, dan model produk untuk produk satuan.
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <TextField
                                fullWidth
                                error={!!error('name')}
                                name="name"
                                placeholder="Nama Produk"
                                value={name}
                                helperText={error('name')}
                                variant="outlined"
                                onChange={onUpdateName}
                            />
                        </Grid> 
                    </Grid>
                    <Category /> 
                    <Grid item container spacing={8}>
                        <Grid item xs={4}>
                            <Typography fontSize={16} fontWeight={700} component="div">
                                Deskripsi Produk
                            </Typography>
                            <Typography variant="body2" gutterBottom color="textSecondary">
                                Pastikan deskripsi produk memuat spesifikasi, ukuran, bahan, masa berlaku, dan lainnya. Semakin detail, semakin berguna bagi pembeli.
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <TextField
                                fullWidth
                                error={!!error('description')}
                                rows={6}
                                multiline
                                name="description"
                                placeholder="Deskripsi produk"
                                value={description}
                                helperText={error('description')}
                                variant="outlined"
                                onChange={onChange}
                            />
                        </Grid> 
                    </Grid>
                    <Specification product_specifications={product_specifications} error={error} /> 
                    <Grid item container spacing={8}>
                        <Grid item xs={4}>
                            <Typography fontSize={16} fontWeight={700} component="div">
                                Jenis & Berat Produk
                            </Typography>
                            <Typography variant="body2" gutterBottom color="textSecondary">
                                Masukan jenis & berat produk bersih setelah dikemas.
                            </Typography>
                        </Grid>
                        <Grid item xs={8} container spacing={3}>
                            <Grid item>
                                <TextField
                                    select
                                    value={weight_type}
                                    onChange={onChange}
                                    name="weight_type"
                                    variant="outlined"
                                    >
                                    {weights.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item>
                                {productWeight()}
                            </Grid>
                        </Grid> 
                    </Grid>
                </Grid>
            </Grid>
        </ContentPaper>
    )
}

const ContentPaper = styled(Paper)(({theme}) => ({
    padding: 35,
    paddingTop: 32,
    marginBottom: 25
}));

const FieldGrid = styled(Grid)(({theme}) => ({
    marginTop: 10
}));

  