import React, { useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import * as employeeActions from '../../../../../../actions/employeeActions';
import { 
        Typography,
        Box,
        Grid,
        Paper,
        List,
        ListItemButton,
        ListSubheader,
        ListItemText,
        ListItemIcon,
        Avatar
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { AccountBoxTwoTone, BadgeTwoTone, DocumentScannerTwoTone, CalculateTwoTone, AdminPanelSettingsTwoTone } from '@mui/icons-material';
import { useParams } from "react-router-dom";
import Loader from '../../../../../Helper/Loader';
import Personal from './Personal';
import Employment from './Employment';
import Payroll from './Payroll';
import Access from './Access';
import JobHistory from './JobHistory';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <Box
      >
        {value === index && (
          <ContentPaper elevation={3}>
            {children}
          </ContentPaper>
        )}
      </Box>
    );
}

function stringToColor(string) {
    let hash = 0;
    let i;
  
    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
  
    let color = '#';
  
    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.substr(-2);
    }
    /* eslint-enable no-bitwise */
  
    return color;
}
  
function stringAvatar(name) {
    return {
        sx: {
        bgcolor: stringToColor(name),
        width: 95, height: 95
        },
        children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
}
export default function Show() {
    let { id } = useParams();
    const dispatch = useDispatch();
    const { employee, errors } = useSelector(state => ({
        ...state.employee
    }), shallowEqual);


    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
    },[errors]);

    useEffect(() => {
        dispatch(employeeActions.onEmployeeShow(id));
		
        return () => {
            dispatch(employeeActions.onUnloadForm())
        };
    },[dispatch, id]);

    const [value, setValue] = React.useState(0);

    function onChange(value) {
        setValue(value)
    }

    if (!employee) {
        return <Loader />
    }

    const { increment_id, full_name, status, profile } = employee;
    return (
        <ContentBox>
            <HeaderBox>
                <Typography fontWeight={700} variant="h6">
                    Informasi Karyawan
                </Typography>
            </HeaderBox>
            <Grid container spacing={3}>
                <Grid item xs={3}>
                    <NavPaper elevation={3}>
                        <Grid sx={{marginBottom: '35px', marginTop: '25px'}} container alignItems="center" direction={"column"} spacing={2}>
                            <Grid item>
                                {profile ? <ProfileAvatar alt={full_name} src={profile} variant="rounded" /> : <Avatar {...stringAvatar(full_name)} variant="rounded" />}
                            </Grid>
                            <Grid item container direction="column" alignItems="center" spacing={1}>
                                <Grid item>
                                    <Typography fontWeight={500} color="#444" variant="body2">
                                        {increment_id}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography fontWeight={700} variant="body1">
                                        {full_name}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography fontWeight={500} color="primary" variant="body1">
                                        {status}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <List
                            component="nav"
                            subheader={<ListSubheader>General</ListSubheader>}
                        >
                            <ListItemButton selected={value === 0} onClick={onChange.bind(this, 0)}>
                                <ListItemIcon>
                                    <AccountBoxTwoTone color={value === 0 ? "primary" : ""} />
                                </ListItemIcon>
                                <ListItemText primary="Personal" />
                            </ListItemButton>
                            <ListItemButton selected={value === 1} onClick={onChange.bind(this, 1)}>
                                <ListItemIcon>
                                    <BadgeTwoTone color={value === 1 ? "primary" : ""} />
                                </ListItemIcon>
                                <ListItemText primary="Employment" />
                            </ListItemButton>
                        </List>
                        <List
                            component="nav"
                            subheader={<ListSubheader>Payroll</ListSubheader>}
                        >
                            <ListItemButton selected={value === 2} onClick={onChange.bind(this, 2)}>
                                <ListItemIcon>
                                    <CalculateTwoTone color={value === 2 ? "primary" : ""} />
                                </ListItemIcon>
                                <ListItemText primary="Payroll Info" />
                            </ListItemButton>
                        </List>
                        <List
                            component="nav"
                            subheader={<ListSubheader>Employee Access</ListSubheader>}
                        >
                            <ListItemButton selected={value === 3} onClick={onChange.bind(this, 3)}>
                                <ListItemIcon>
                                    <AdminPanelSettingsTwoTone color={value === 3 ? "primary" : ""} />
                                </ListItemIcon>
                                <ListItemText primary="Manage Access" />
                            </ListItemButton>
                        </List>
                        <List
                            component="nav"
                            subheader={<ListSubheader>History</ListSubheader>}
                        >
                            <ListItemButton selected={value === 4} onClick={onChange.bind(this, 4)}>
                                <ListItemIcon>
                                    <DocumentScannerTwoTone color={value === 4 ? "primary" : ""} />
                                </ListItemIcon>
                                <ListItemText primary="Job History" />
                            </ListItemButton>
                        </List>
                    </NavPaper>
                </Grid>
                <Grid item xs>
                    <TabPanel value={value} index={0}>
                        <Personal />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                       <Employment />
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <Payroll />
                    </TabPanel>
                     <TabPanel value={value} index={3}>
                        <Access />
                    </TabPanel>
                    <TabPanel value={value} index={4}>
                        <JobHistory />
                    </TabPanel>
                    <TabPanel value={value} index={5}>
                        Item Six
                    </TabPanel>
                    <TabPanel value={value} index={6}>
                        Item Seven
                    </TabPanel>
                    <TabPanel value={value} index={7}>
                        Item Seven
                    </TabPanel>
                    <TabPanel value={value} index={8}>
                        Item Seven
                    </TabPanel>
                </Grid>
            </Grid>
        </ContentBox>
    )
}

const ContentBox = styled(Box)(({theme}) => ({
padding: '25px 0',
[theme.breakpoints.down('sm')]: {
    padding: '15px 15px 0 15px',
},
[theme.breakpoints.down('md')]: {
    padding: '15px 15px 0 15px',
},
height: '100%'
}));

const HeaderBox = styled(Box)(({theme}) => ({
paddingLeft: 0,
marginBottom: 25,
[theme.breakpoints.down('sm')]: {
    paddingLeft: 55,
},
}));

const NavPaper = styled(Paper)(({theme}) => ({
    paddingTop: 25,
    paddingBottom: 25,
}));

const ContentPaper = styled(Paper)(({theme}) => ({
    padding: 25
}));

const ProfileAvatar = styled(Avatar)(({theme}) => ({
    width: 95, height: 95
}));
    
