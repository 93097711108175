import React, { useState, useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import * as dashboardActions from '../../../actions/dashboardActions';
import { 
    Box,
    Grid,
    Typography,
    Paper
} from '@mui/material';
import Header from './Header';
import { styled } from '@mui/material/styles';
import { ArrowDropUpRounded, ArrowDropDownRounded } from '@mui/icons-material';
import NumberFormat from 'react-number-format';
import Loader from '../../Helper/Loader';
import Chart from 'chart.js/auto';
import DailyChart from './DailyChart';

function LineChart({chartName, chartData, color}) {
    
    useEffect(() => {
        var dashboardChart;
        if (chartData) {
            dashboardChart = new Chart(document.getElementById(`${chartName}`), {
                type: 'line',
                data: {
                  labels: chartData,
                  datasets: [{ 
                      data: chartData,
                      borderColor: color,
                      fill: false,
                      borderWidth: 2,
                      pointStyle: false,
                      tension: 0.5
                    }
                  ]
                },
                options: {
                    interaction: {
                        mode: 'index'
                    },
                    plugins: {
                        legend: {
                            display: false,
                        },
                        tooltip: {
                            enabled: false,
                        },
                        layout: {
                            padding: {
                                // Any unspecified dimensions are assumed to be 0                     
                                bottom: 2
                            }
                        },
                        elements: {
                            point:{
                                radius: 0
                            }
                        },
                    },
                    elements: {
                        point: {
                            borderWidth: 5
                        }
                    },
                    scales: {
                        y: {
                            beginAtZero: true,
                            gridLines: {
                                display:false
                            },
                            display: false
                        },
                        x: {
                            display: false
                        }
                    },
                }
            })
            return () => {
                dashboardChart.destroy()
            };
        }
     

        
        
      },[]);
    return (
        <Box sx={{width: '85px'}}>
             <canvas id={`${chartName}`} width="100%"></canvas>
        </Box>
    )
}

export default function Dashboard() {
    const dispatch = useDispatch();
    const { stores, reports } = useSelector(state => ({
        ...state.dashboard
    }), shallowEqual);

    const [query, setQuery] = useState({ store_id: '' });

    useEffect(() => {
        dispatch(dashboardActions.onLoadDashboard(query))
    },[query]);


    if (!stores || !reports) {
        return (
            <Loader />
        )
    }

    const { summary, daily_reports } = reports;
    const { total_payable_on_due, total_payable, total_receivable_on_due, total_receivable } = summary;

    function getDataInPercentage(data) {
       var diff = [];
        data.reduce(function (a, b) {
            if (a === 0) return b;
            var isNegative = b - a < 0;
            diff.push(parseInt((b - a) / (isNegative? a : b) * 100));
            return b;
        }, 0);
        return diff;
    }


    function average(arr) {
        var sum = 0;
     
        // Iterate the elements of the array
        arr.forEach(function (item, idx) {
          sum += item;
        });
     
        // Returning the average of the numbers
        return sum / arr.length;
    }

    const total_payable_on_due_percentage = average(getDataInPercentage(total_payable_on_due.data));
    const total_payable_percentage = average(getDataInPercentage(total_payable.data));
    const total_receivable_on_due_percentage = average(getDataInPercentage(total_receivable_on_due.data));
    const total_receivable_percentage = average(getDataInPercentage(total_receivable.data));

    function percentage(value) {
        if (!value) {
            return (
                <GainTypography variant="body2">
                    <ArrowDropUpRounded />100%
                </GainTypography>
            ) 
        }
        if (parseFloat(value) <= 0) {
            return (
                <LossTypography variant="body2">
                    <ArrowDropDownRounded />{value}%
                </LossTypography>
            ) 
        }


        return (
            <GainTypography variant="body2">
                <ArrowDropUpRounded />{value}%
            </GainTypography>
        ) 
    }

    return (
        <ContentBox>
            <Header
            stores={stores}
            query={query}
            setQuery={setQuery} />
            <Grid container direction="column" spacing={3}>
                <Grid item container spacing={3}> 
                    <Grid item xs>
                        <HeaderPaper>
                            <Grid container >
                                <Grid container item xs={8} direction="column" justifyContent="space-between">
                                    <Grid item>
                                        <Typography sx={{marginBottom: '30px'}} variant="body2" fontSize={14} fontWeight={500} color="#444">
                                            Utang Jatuh Tempo
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <NumberFormat
                                            value={total_payable_on_due.total}
                                            displayType="text"
                                            thousandSeparator={"."}
                                            decimalSeparator={","}
                                            prefix="Rp "
                                            isNumericString
                                            decimalScale={2}
                                            renderText={(value) => <Typography lineheight="normal" fontSize={15} fontWeight={700}>
                                            {value}
                                        </Typography>}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={4} container>
                                    <Grid item xs={12}>
                                        <NumberFormat
                                            value={total_payable_on_due_percentage}
                                            displayType="text"
                                            thousandSeparator={"."}
                                            decimalSeparator={","}
                                            isNumericString
                                            decimalScale={2}
                                            renderText={(value) => <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                                                {percentage(value)}
                                            </Box>}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <LineChart chartData={total_payable_on_due.data} color="#4B00D1" chartName="accountsPayableOnDue" />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </HeaderPaper>
                    </Grid>
                    <Grid item xs>
                        <HeaderPaper>
                            <Grid container >
                                <Grid container item xs={8} direction="column" justifyContent="space-between">
                                    <Grid item>
                                        <Typography sx={{marginBottom: '30px'}} variant="body2" fontSize={14} fontWeight={500} color="#444">
                                            Total Utang
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <NumberFormat
                                            value={total_payable.total}
                                            displayType="text"
                                            thousandSeparator={"."}
                                            decimalSeparator={","}
                                            prefix="Rp "
                                            isNumericString
                                            decimalScale={2}
                                            renderText={(value) => <Typography lineheight="normal" fontSize={15} fontWeight={700}>
                                            {value}
                                        </Typography>}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={4} container>
                                    <Grid item xs={12}>
                                        <NumberFormat
                                            value={total_payable_percentage}
                                            displayType="text"
                                            thousandSeparator={"."}
                                            decimalSeparator={","}
                                            isNumericString
                                            decimalScale={2}
                                            renderText={(value) => <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                                                {percentage(value)}
                                            </Box>}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <LineChart chartData={total_payable.data} color="#D6001C" chartName="accountsPayable" />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </HeaderPaper>
                    </Grid>
                    <Grid item xs>
                        <HeaderPaper>
                            <Grid container >
                                <Grid container item xs={8} direction="column" justifyContent="space-between">
                                    <Grid item>
                                        <Typography sx={{marginBottom: '30px'}} variant="body2" fontSize={14} fontWeight={500} color="#444">
                                            Piutang Jatuh Tempo
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <NumberFormat
                                            value={total_receivable_on_due.total}
                                            displayType="text"
                                            thousandSeparator={"."}
                                            decimalSeparator={","}
                                            prefix="Rp "
                                            isNumericString
                                            decimalScale={2}
                                            renderText={(value) => <Typography lineheight="normal" fontSize={15} fontWeight={700}>
                                            {value}
                                        </Typography>}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={4} container>
                                    <Grid item xs={12}>
                                        <NumberFormat
                                            value={total_receivable_on_due_percentage}
                                            displayType="text"
                                            thousandSeparator={"."}
                                            decimalSeparator={","}
                                            isNumericString
                                            decimalScale={2}
                                            renderText={(value) => <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                                                {percentage(value)}
                                            </Box>}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <LineChart chartData={total_receivable_on_due.data} color="#279F51" chartName="accountsReceivableOnDue" />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </HeaderPaper>
                    </Grid>
                    <Grid item xs>
                        <HeaderPaper>
                            <Grid container >
                                <Grid container item xs={8} direction="column" justifyContent="space-between">
                                    <Grid item>
                                        <Typography sx={{marginBottom: '30px'}} variant="body2" fontSize={14} fontWeight={500} color="#444">
                                            Total Piutang
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <NumberFormat
                                            value={total_receivable.total}
                                            displayType="text"
                                            thousandSeparator={"."}
                                            decimalSeparator={","}
                                            prefix="Rp "
                                            isNumericString
                                            decimalScale={2}
                                            renderText={(value) => <Typography lineheight="normal" fontSize={15} fontWeight={700}>
                                            {value}
                                        </Typography>}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={4} container>
                                    <Grid item xs={12}>
                                        <NumberFormat
                                            value={total_receivable_percentage}
                                            displayType="text"
                                            thousandSeparator={"."}
                                            decimalSeparator={","}
                                            isNumericString
                                            decimalScale={2}
                                            renderText={(value) => <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                                                {percentage(value)}
                                            </Box>}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <LineChart chartData={total_receivable.data} color="#FF8432" chartName="accountsReceivable" />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </HeaderPaper>
                    </Grid>
                </Grid>
                <Grid item>
                    <DailyChart data={daily_reports} />
                </Grid>
            </Grid>
        </ContentBox>
    )
}

const ContentBox = styled(Box)(({theme}) => ({
    padding: '20px 0',
    [theme.breakpoints.down('sm')]: {
        padding: '15px 15px 0 15px',
    },
    [theme.breakpoints.down('md')]: {
        padding: '15px 15px 0 15px',
    },
}));

const HeaderPaper = styled(Paper)(({theme}) => ({
    padding: '25px'
}));

const HeaderGrid = styled(Grid)(({theme}) => ({
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
        justifyContent: 'flex-end'
    },
}));


const HeaderTypography = styled(Typography)(({theme}) => ({
    color: '#112232',
    fontSize: 24,
    [theme.breakpoints.down('sm')]: {
        fontSize: 18
    },
}));

const LossTypography = styled(Typography)(({theme}) => ({
    color: '#d50000',
    display: 'flex',
    alignItems: 'center'
}));

const GainTypography = styled(Typography)(({theme}) => ({
    color: '#008000',
    display: 'flex',
    alignItems: 'center'
}));