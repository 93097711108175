import React, { useEffect } from 'react';
import { 
    Grid,
    FormControlLabel,
    CircularProgress,
    Button,
    Typography,
    Box,
    TextField,
    InputAdornment,
    Switch,
    FormGroup,
    MenuItem
} from '@mui/material';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import * as userConfigurationActions from '../../../../actions/userConfigurationActions';
import NumberFormat from 'react-number-format';
import Loader from '../../../Helper/Loader';

const CurrencyFormat = React.forwardRef((props, ref) => (
    <NumberFormat
        {...props.other}
        onValueChange={(values) => {
          props.onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        customInput={TextField}
        InputProps={{
            startAdornment: <InputAdornment position="start">
                <Box fontWeight={700}>
                    Rp
                </Box>
            </InputAdornment>,
        }}
        placeholder="0"
        helperText={props.helperText}
        error={props.error}
        value={props.value}
        thousandSeparator={"."}
        decimalSeparator={","}
        allowNegative={false}
        decimalScale={0}
        isNumericString
      />
));

// function Alert(props) {
//     return <MuiAlert elevation={6} variant="filled" {...props} />;
// }

  
export default function PointOfSale() {
    const dispatch = useDispatch();
    const { current_user, user_configuration, inProgress, errors } = useSelector(state => ({
        ...state.common,
        ...state.user_configuration
      }), shallowEqual);  

    

    useEffect(() => {
        dispatch(userConfigurationActions.onLoadUserCashier(current_user._id));
        return () => {
            dispatch(userConfigurationActions.onUnloadUserConfiguration());
        };
    },[]);

    function onUpdateField(event) {
		const key = event.target.name;
        const value = event.target.value;
        const newUserConfiguration = {...user_configuration};
        newUserConfiguration[key] = value;
        dispatch(userConfigurationActions.onUpdateUserConfiguration(newUserConfiguration));
    }

    function onToggle(value, event) {
		const key = event.target.name;
        const newUserConfiguration = {...user_configuration};
        newUserConfiguration[key] = value;
        dispatch(userConfigurationActions.onUpdateUserConfiguration(newUserConfiguration));
    }

    function onToggleShippingCharge(value, event) {
		const key = event.target.name;
        const newUserConfiguration = {...user_configuration};
        if (!value) {
            newUserConfiguration.shipping_charge_amount = 0;
            newUserConfiguration.shipping_charge_min_order = 0;
        }
        newUserConfiguration[key] = value;
        dispatch(userConfigurationActions.onUpdateUserConfiguration(newUserConfiguration));
    }


    function onToggleRoundingCharge(value, event) {
		const key = event.target.name;
        const newUserConfiguration = {...user_configuration};
        if (!value) {
            newUserConfiguration.rounding_amount = 0;
            newUserConfiguration.rounding_type = 'round_up';
        }
        newUserConfiguration[key] = value;
        dispatch(userConfigurationActions.onUpdateUserConfiguration(newUserConfiguration));
    }

    


    function onEdit() {
        dispatch(userConfigurationActions.onEditUserConfigurationCashier(user_configuration._id, user_configuration));
    }

    function error(key) {
        if (errors != null && errors[`user_configuration.${key}`] != null) {
            return errors[`user_configuration.${key}`].msg
        }
        return null;
    }

    if (!user_configuration) {
        return (
            <Loader />
        )
    }

   
   
    const { daily_cash, shipping_charge, shipping_charge_amount, shipping_charge_min_order, rounding_charge, rounding_amount, rounding_type, customer_group_validation } = user_configuration;
    return (
        <React.Fragment>
            <Grid container spacing={4} direction="column">
                <Grid item container>
                    <Grid item xs>
                        <Typography fontWeight={700} component="h6" lineheight="normal">
                            Pengaturan Kasir
                        </Typography>
                    </Grid>
                    <Grid item>
                    </Grid>
                </Grid>
                <Grid item container direction="column" spacing={3}>
                    <Grid item container spacing={8}>
                        <Grid item xs={4}>
                            <Typography fontSize={16} fontWeight={700} component="div">
                                Kas Kasir
                            </Typography>
                            <Typography variant="body2" gutterBottom color="textSecondary">
                                Kas kasir saat akan memulai shift POS.
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <CurrencyFormat
                                name="daily_cash"
                                error={!!error('daily_cash')}
                                helperText={error('daily_cash')}
                                value={daily_cash}
                                variant="outlined"
                                onChange={onUpdateField} 
                            />
                        </Grid> 
                    </Grid>
                    <Grid item container spacing={8}>
                        <Grid item xs={4}>
                            <Typography fontSize={16} fontWeight={700} component="div">
                                Validasi Group Pelanggan
                            </Typography>
                            <Typography variant="body2" gutterBottom color="textSecondary">
                                Validasi discount berdasarkan group pelanggan.
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <FormGroup row>
                                <FormControlLabel
                                    control={
                                    <Switch
                                        checked={customer_group_validation}
                                        onChange={onToggle.bind(this, !customer_group_validation)}
                                        name="customer_group_validation"
                                        color="primary"
                                    />
                                    }
                                />
                            </FormGroup>
                        </Grid> 
                    </Grid>
                    <Grid item container spacing={8}>
                        <Grid item xs={4}>
                            <Typography fontSize={16} fontWeight={700} component="div">
                                Biaya Pengiriman
                            </Typography>
                            <Typography variant="body2" gutterBottom color="textSecondary">
                                Biaya tambahan untuk pesanan yang harus dikirim, aktifkan apabila pengiriman dikenakan biaya.
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <FormGroup row>
                                <FormControlLabel
                                    control={
                                    <Switch
                                        checked={shipping_charge}
                                        onChange={onToggleShippingCharge.bind(this, !shipping_charge)}
                                        name="shipping_charge"
                                        color="primary"
                                    />
                                    }
                                />
                            </FormGroup>
                        </Grid> 
                    </Grid>
                    {shipping_charge && [
                    <Grid item container spacing={8} key={1}>
                        <Grid item xs={4}>
                            <Typography fontSize={16} fontWeight={700} component="div">
                                Biaya
                            </Typography>
                            <Typography variant="body2" gutterBottom color="textSecondary">
                                Biaya yang ditambahkan untuk setiap pesanan yang akan dikirim.
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <CurrencyFormat
                                name="shipping_charge_amount"
                                placeholder="Biaya Kirim"
                                value={shipping_charge_amount}
                                variant="outlined"
                                onChange={onUpdateField} 
                            />
                        </Grid> 
                    </Grid>,
                    <Grid item container spacing={8} key={2}>
                        <Grid item xs={4}>
                            <Typography fontSize={16} fontWeight={700} component="div">
                                Min. Pembelian
                            </Typography>
                            <Typography variant="body2" gutterBottom color="textSecondary">
                                Batas bawah pesanan yang akan dikenakan biaya pengriman untuk pesanan yang akan dikirim.
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <CurrencyFormat
                                name="shipping_charge_min_order"
                                placeholder="Min. Pesanan"
                                value={shipping_charge_min_order}
                                variant="outlined"
                                onChange={onUpdateField} 
                            />
                        </Grid> 
                    </Grid>]}
                    <Grid item container spacing={8}>
                        <Grid item xs={4}>
                            <Typography fontSize={16} fontWeight={700} component="div">
                                Pembulatan Total Transaksi
                            </Typography>
                            <Typography variant="body2" gutterBottom color="textSecondary">
                                Total transaksi pada kasir dapat dibulatkan keatas, kebawah, atau pembulatan terdekat.
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <FormGroup row>
                                <FormControlLabel
                                    control={
                                    <Switch
                                        checked={rounding_charge}
                                        onChange={onToggleRoundingCharge.bind(this, !rounding_charge)}
                                        name="rounding_charge"
                                        color="primary"
                                    />
                                    }
                                />
                            </FormGroup>
                        </Grid> 
                    </Grid>
                    {rounding_charge && [
                    <Grid item container spacing={8} key={1}>
                        <Grid item xs={4}>
                            <Typography fontSize={16} fontWeight={700} component="div">
                                Jenis Pembulatan
                            </Typography>
                            <Typography variant="body2" gutterBottom color="textSecondary">
                                Pembulatan terdekat akan otomatis mengikuti nominal terdekat dari total transaksi, contoh: Rp. 10.200 akan menjadi Rp. 10.000 dan  Rp. 10.350 akan menjadi Rp. 10.500 (dengan nominal pembulatan Rp. 500).
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <TextField
                                select
                                value={rounding_type}
                                onChange={onUpdateField} 
                                name="rounding_type"
                                variant="outlined"
                                >
                                    <MenuItem value="round_up">
                                        Pembulatan Keatas
                                    </MenuItem>
                                    <MenuItem value="round_down">
                                        Pembulatan Kebawah
                                    </MenuItem>
                                    <MenuItem value="round">
                                        Pembulatan Terdekat
                                    </MenuItem>
                            </TextField>`
                        </Grid> 
                    </Grid>,
                    <Grid item container spacing={8} key={2}>
                        <Grid item xs={4}>
                            <Typography fontSize={16} fontWeight={700} component="div">
                                Nominal pembulatan
                            </Typography>
                            <Typography variant="body2" gutterBottom color="textSecondary">
                                Masukan nominal pembulatan terkecil contoh. Rp. 500.
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <CurrencyFormat
                                name="rounding_amount"
                                placeholder="Pembulatan"
                                value={rounding_amount}
                                variant="outlined"
                                onChange={onUpdateField} 
                            />
                        </Grid> 
                    </Grid>]}
                </Grid>
                <Grid item container sx={{marginBottom: '15px'}} justifyContent="space-between" spacing={3}>
                    <Grid item>
                    </Grid>
                    <Grid item>
                        <Grid container spacing={3}>
                            <Grid item>
                            </Grid>
                            <Grid item>
                                <Button 
                                    variant="contained"
                                    size="large"
                                    color="primary"
                                    sx={{minWidth: 195}}
                                    onClick={onEdit}
                                    disabled={inProgress}
                                    disableElevation>
                                    {inProgress ? <CircularProgress size={26} /> : 'Simpan'}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>  
            </Grid>
        </React.Fragment>
    )
}
