import React, { useState, useRef, useEffect } from 'react';
import * as productActions from '../../../../../../../../../../actions/productActions';
import Dropdown from './Dropdown';
import TextSwatch from './TextSwatch';
import VisualSwatch from './VisualSwatch';

export default function Option({input_type, dragId, option, config, index, optIndex, productConfigurations, onChange, error, name}) {
   

    const { product_configuration_option_swatch_images } = option;
        
    useEffect(() => {
        if ( product_configuration_option_swatch_images ) {
            const newImages = [...product_configuration_option_swatch_images];
            if (newImages) {
                newImages.map((image, imageIndex) => {
                    if (image.onRemove) {
                        image.onRemove = false
                        productActions.onRemoveSwatch(image).then(
                            res => {
                                if (res.data._id) {
                                    newImages.splice(imageIndex, 1, res.data)
                                } else {
                                    newImages.splice(imageIndex, 1)
                                }
                                return onOptionImagesChange(optIndex, index, newImages)
                            }
                        );
                    }
                    return image;
                    
                })
            }
        }
    }, [config])

    function onOptionChange(optIndex, index, event) {
        const key = event.target.name;
        const value = event.target.value;

        const newConfigurations = [...productConfigurations];
        newConfigurations.map((item, i) => {
            if (i !== index) {
              // This isn't the item we care about - keep it as-is
              return item
            }
            const newOptions = [...newConfigurations[index].product_configuration_options]
            newOptions.map((option, o) => {
                if (o !== optIndex) {
                  // This isn't the item we care about - keep it as-is
                  return option
                }
              
                const updateOption = { ...option, [key]: value}
                return newOptions.splice(optIndex, 1, updateOption)
               
            })

            const updateConfig = { ...item, product_configuration_options: newOptions}
            return newConfigurations.splice(index, 1, updateConfig)
        
        })
        onChange(newConfigurations);
    }

    function onUploadingImages(optIndex, index, images) {
        const newConfigurations = [...productConfigurations];
        newConfigurations.map((item, i) => {
            if (i !== index) {
              // This isn't the item we care about - keep it as-is
              return item
            }
            const newOptions = [...newConfigurations[index].product_configuration_options]
            newOptions.map((option, o) => {
                if (o !== optIndex) {
                  // This isn't the item we care about - keep it as-is
                  return option
                }
                // const updateOption = { ...option, product_configuration_option_swatch_images: newImages, product_configuration_option_swatch_value: {...option.product_configuration_option_swatch_value, _destroy: true}}
                let updateOption = { ...option, product_configuration_option_swatch_images: images}     
                return newOptions.splice(optIndex, 1, updateOption);
            })

            const updateConfig = { ...item, product_configuration_options: newOptions}
            return newConfigurations.splice(index, 1, updateConfig)
        
        })
        onChange(newConfigurations);
    }


    function onOptionImagesChange(optIndex, index, images) {
        const newConfigurations = [...productConfigurations];
        newConfigurations.map((item, i) => {
            if (i !== index) {
              // This isn't the item we care about - keep it as-is
              return item
            }
            const newOptions = [...newConfigurations[index].product_configuration_options]
            newOptions.map((option, o) => {
                if (o !== optIndex) {
                  // This isn't the item we care about - keep it as-is
                  return option
                }
                // const updateOption = { ...option, product_configuration_option_swatch_images: newImages, product_configuration_option_swatch_value: {...option.product_configuration_option_swatch_value, _destroy: true}}
                let updateOption;
                if (images && images.filter(el => {
                    return !el._destroy && el.image && !el.onRemove;
                }).length !== 0) {
                    if (images.length === 0) {
                    //     updateOption = { ...option, product_configuration_option_swatch_value: {...option.product_configuration_option_swatch_value, _destroy: true}}
                    } else {
                        if (option.product_configuration_option_swatch_value && option.product_configuration_option_swatch_value._id) {
                            updateOption = { ...option, product_configuration_option_swatch_images: images, product_configuration_option_swatch_value: {...option.product_configuration_option_swatch_value, _destroy: true}}
                        } else {
                            delete option.product_configuration_option_swatch_value;
                            updateOption = { ...option, product_configuration_option_swatch_images: images}
                        }
                    }
                } else {
                    if (images.length === 0) {
                        delete option.product_configuration_option_swatch_images;
                        updateOption = { ...option}    
                    } else {
                        updateOption = { ...option, product_configuration_option_swatch_images: images}     
                    }
                }
                
                return newOptions.splice(optIndex, 1, updateOption);
            })

            const updateConfig = { ...item, product_configuration_options: newOptions}
            return newConfigurations.splice(index, 1, updateConfig)
        
        })
        onChange(newConfigurations);
    }

  


    function onRemoveOption(optIndex, index) {
        const newConfigurations = [...productConfigurations];
        newConfigurations.map((config, i) => {
            if (i !== index) {
              // This isn't the item we care about - keep it as-is
              return config
            }
            const newOptions = [...config.product_configuration_options]
            newOptions.map((option, o) => {
                if (o !== optIndex) {
                  // This isn't the item we care about - keep it as-is
                  return option
                }
              
                if (option._id) {
                    const updateOption = { ...option, _destroy: true}
                    return newOptions.splice(optIndex, 1, updateOption)
                } else {
                    return newOptions.splice(optIndex, 1)
                }
            })

            newOptions.map((option, index)=> {
                const updateOption = { ...option, sort_order: index};
                return newOptions.splice(index, 1, updateOption);
            })

            const updateConfig = { ...config, product_configuration_options: newOptions}
            return newConfigurations.splice(index, 1, updateConfig)
        
        })
        onChange(newConfigurations);
    }

    function onSwatchValue(type, value) {
        const newConfigurations = [...productConfigurations];
        newConfigurations.map((config, i) => {
            if (i !== index) {
              // This isn't the item we care about - keep it as-is
              return config
            }

            
            const newOptions = [...config.product_configuration_options]
            newOptions.map((option, o) => {
                if (o !== optIndex) {
                  // This isn't the item we care about - keep it as-is
                  return option
                }

                if (!type && !value) {
                    let updateOption;
                    if (option.product_configuration_option_swatch_images) {
                        const newImages = [...option.product_configuration_option_swatch_images]; 
                        if (newImages) {
                            newImages.map((image, imageIndex) => {
                                if (!image._destroy) {
                                    const updateImage = { ...image, onRemove: true}
                                    return newImages.splice(imageIndex, 1, updateImage)
                                }
                            })
                        }
                        updateOption = { ...option, product_configuration_option_swatch_images: newImages, product_configuration_option_swatch_value: {...option.product_configuration_option_swatch_value, value: ''}}
                    } else {
                        updateOption = { ...option, product_configuration_option_swatch_value: {...option.product_configuration_option_swatch_value, value: ''}}
                      
                    }
                    return newOptions.splice(optIndex, 1, updateOption)
                }

                if (type === 'color' && value) {
                    let updateOption;
                    if (option.product_configuration_option_swatch_images) {
                        console.log('22')
                        const newImages = [...option.product_configuration_option_swatch_images]; 
                        if (newImages) {
                            newImages.map((image, imageIndex) => {
                                if (!image._destroy) {
                                    const updateImage = { ...image, onRemove: true}
                                    return newImages.splice(imageIndex, 1, updateImage)
                                }
                            })
                        }
                        if (option.product_configuration_option_swatch_value && option.product_configuration_option_swatch_value._id) {
                            delete option.product_configuration_option_swatch_value._destroy;
                            updateOption = { ...option, product_configuration_option_swatch_images: newImages, product_configuration_option_swatch_value: {...option.product_configuration_option_swatch_value, value: value}}
                        } else {
                            updateOption = { ...option, product_configuration_option_swatch_images: newImages, product_configuration_option_swatch_value: {value: value}}
                        }
                    } else {
                        console.log('33')
                        if (option.product_configuration_option_swatch_value && option.product_configuration_option_swatch_value._id) {
                            updateOption = { ...option, product_configuration_option_swatch_value: {...option.product_configuration_option_swatch_value, value: value}}
                        } else {
                            updateOption = { ...option, product_configuration_option_swatch_value: {value: value}}
                        }
                      
                    }
                    return newOptions.splice(optIndex, 1, updateOption)
                }
               
            })
            const updateConfig = { ...config, product_configuration_options: newOptions}
            return newConfigurations.splice(index, 1, updateConfig)
        
        })
        onChange(newConfigurations);
	}
   
    const components = {
        dropdown: Dropdown,
        vswatch: VisualSwatch,
        tswatch: TextSwatch
    };
    
    const Component = components[input_type];
    if (!dragId) {
        return null;
    }

    return (
        <Component
            input_type={input_type}
            dragId={dragId}
            option={option}
            config={config}
            optIndex={optIndex}
            error={error}
            name={name}
            onUploadingImages={onUploadingImages.bind(this, optIndex, index)}
            onOptionImagesChange={onOptionImagesChange.bind(this, optIndex, index)}
            onRemove={onRemoveOption.bind(this, optIndex, index)}
            onChange={onOptionChange.bind(this, optIndex, index)}
            onSwatchValue={onSwatchValue} />
    )
}
