import React, { useState } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import * as productActions from '../../../../../../../../actions/productActions';
import { 
    Dialog,
    DialogActions,
    Slide,
    Button,
    Grid,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Content from './Content';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function Generate() {
    const dispatch = useDispatch();
    const {onConfiguration} = useSelector(state => ({
        ...state.product
    }), shallowEqual);

    const [productConfigurations, setProductConfigurations] = useState([]);

    function onChange(productConfigurations) {
        setProductConfigurations(productConfigurations);
    }

    function onHide() {
        dispatch(productActions.onHideConfiguration());
        setProductConfigurations([]);
    }
    
    function onDone() {
        productConfigurations.map((config, i)=> {
            const updateitem = { ...config, sort_order: i}
            return productConfigurations.splice(i, 1, updateitem)
        })	
        dispatch(productActions.onDoneConfiguration(productConfigurations));
    }

    return (
        <Dialog
            open={onConfiguration}
            maxWidth="md"
            fullWidth={true}
            TransitionComponent={Transition}
            scroll={'paper'}>
            <Content
                productConfigurations={productConfigurations}
                onChange={onChange} />
            <WrapperDialogActions>
                <Grid container spacing={3} justifyContent="flex-end">
                    <Grid item>
                        <CancelButton  
                            size="large"
                            disableElevation
                            variant="contained"
                            onClick={onHide} 
                            color="secondary">
                            Batal
                        </CancelButton>
                    </Grid>
                    <Grid item>
                        <Button size="large"
                            sx={{minWidth: 165}}
                            disableElevation
                            onClick={onDone}
                            variant="contained" color="primary">
                            Selesai
                        </Button>
                    </Grid>
                </Grid>
            </WrapperDialogActions>
        </Dialog>
    )
}

const WrapperDialogActions = styled(DialogActions)(({theme}) => ({
    padding: '15px 45px 15px 45px !important'
}));

const CancelButton = styled(Button)(({theme}) => ({
    color: theme.palette.text.primary,
    backgroundColor: "#FFFFFF",
    '&:hover': {
    backgroundColor: "#FFFFFF",
    },
    fontWeight: 500,
    minWidth: 165,
    border: `1px solid #ddd`
}));