

import * as types from './actionTypes';
import { axiosClient } from '../api';


export const onLoadCustomerDisplays = (query) => {
	const fetch = axiosClient.get(`/customer-displays`,
	{
		params: query
	});
	return {
		type: types.CUSTOMER_DISPLAYS_LOADED,
		payload: fetch
	}
}


export const onCustomerDisplayNew = () => {
	return {
		type: types.NEW_CUSTOMER_DISPLAY_LOADED
	}
}

export const onCustomerDisplayEdit = (id) => {
	const fetch = axiosClient.get(`/customer-displays/${id}/edit`);
	return {
		type: types.EDIT_CUSTOMER_DISPLAY_LOADED,
		payload: fetch
	}
}

export const onUpdateCustomerDisplay = (customer_display) => {
	return {
		type: types.CUSTOMER_DISPLAY_UPDATED,
		customer_display
	}
}


export const onCreate = (customer_display) => {
    const fetch = axiosClient.post(`/customer-displays`, {customer_display});
    return {
        type: types.CUSTOMER_DISPLAY_CREATED, 
        payload: fetch
    }
}

export const onEdit = (id, customer_display) => {
    const fetch = axiosClient.put(`/customer-displays/${id}`, {customer_display});
    return {
        type: types.CUSTOMER_DISPLAY_EDITED, 
        payload: fetch
    } 
}

export const onUnloadForm = () => {
	return {
		type: types.CUSTOMER_DISPLAY_FORM_UNLOADED
	}
}

export const onUploadRequest = (image) => {
	return {
		type: types.CUSTOMER_DISPLAY_UPLOAD_REQUESTED,
		image
	}
}

export const onUpload = (file, index, progressCallback) => {
	const fetch = axiosClient.post(`/customer-displays/upload`, {file}, {onUploadProgress: progressCallback});
	return {
		type: types.CUSTOMER_DISPLAY_UPLOADED,
		payload: fetch,
		index
	}
}

export const onUploading = (image, index) => {
	return {
		type: types.CUSTOMER_DISPLAY_UPLOADING,
		image,
		index
	}
}

export const onRemoveRequest = (image, index) => {
	return {
		type: types.CUSTOMER_DISPLAY_REMOVE_REQUESTED,
		image,
		index
	}
}

export const onRemove = (file, index) => {
	const fetch = axiosClient.post(`/customer-displays/remove`, {file});
	return {
		type: types.CUSTOMER_DISPLAY_REMOVED,
		payload: fetch,
		index
	}
}