import * as types from './actionTypes';
import { axiosClient } from '../api';

export const onLoadLogin = () => {
	return {
		type: types.USER_LOGIN_LOADED
	}
}

export const onUnloadLogin = () => {
	return {
		type: types.USER_LOGIN_UNLOADED
	}
}

export const onUpdateValidationField = (key, value) => {
	return {
        type: types.USER_LOGIN_VALIDATION_FIELD_UPDATED,
        key,
        value
	}
}

export const onUpdateField = (key, value) => {
	return {
        type: types.USER_LOGIN_FIELD_UPDATED,
        key,
        value
	}
}

export const onUserExist = (user) => {
	return {
        type: types.USER_LOGIN_EXISTED,
        user
	}
}

export const onValidateUser = (user) => {
	const fetch = axiosClient.post(`/user-validations`, {user});
	return {
		type: types.USER_LOGIN_VALIDATION,
		payload: fetch
	}
}

export const onCancelUser = () => {
	return {
		type: types.USER_LOGIN_CANCELED
	}
}

export const onSignIn = (user) => {
	const fetch = axiosClient.post(`/user-sessions`, {user});
	return {
		type: types.USER_LOGGED_IN,
		payload: fetch
	}
}

export const onCancelRegister = () => {
	return {
		type: types.NEW_USER_CANCELED
	}
}
