import React, {useEffect} from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import * as employeeActions from '../../../../../../../../actions/employeeActions';
import { 
    Typography,
    Grid,
    Paper,
    Button,
    TextField,
    CircularProgress,
    Autocomplete,
    List,
    ListSubheader,
    ListItem
} from '@mui/material';
import NumberFormat from 'react-number-format';
import { styled } from '@mui/material/styles';
import { useParams } from "react-router-dom";
import { AddRounded } from '@mui/icons-material';

const CustomFormat = React.forwardRef((props, ref) => (
    <NumberFormat
        {...props.other}
        onValueChange={(values) => {
          props.onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        customInput={TextField}
        placeholder={props.placeholder}
        value={props.value}
        helperText={props.helperText}
        error={props.error}
        fullWidth={props.fullWidth}
        allowLeadingZeros={true}
        allowNegative={false}
        allowedDecimalSeparators={false}
        isNumericString
      />
));

  
export default function Edit() {
    let { id } = useParams();
    const dispatch = useDispatch();
    const { editEmployee, family_relationships, errors, inProgress } = useSelector(state => ({
        ...state.employee
    }), shallowEqual);
    
    const {employee_emergency_contacts} = editEmployee;

    useEffect(() => {
        if (employee_emergency_contacts.length === 0) {
            const addContacts = [...employee_emergency_contacts, {full_name: "", family_relationship_id: "", phone: "" }]
            const newEmergencyContacts = addContacts.map((contact, index)=> {
                return {...contact, sort_order: index}
            })
            dispatch(employeeActions.onUpdateEmergencyContacts(newEmergencyContacts));
        }
        return () => {
            dispatch(employeeActions.onUnloadEditEmployee());
        };
    },[dispatch]);

    function onUpdateEmergencyContacts(index, event) {
        const key = event.target.name;
        const value = event.target.value;
	    const newEmergencyContacts = { ...employee_emergency_contacts[index], [key]: value}
	    employee_emergency_contacts.splice(index, 1, newEmergencyContacts)
        dispatch(employeeActions.onUpdateEmergencyContacts(employee_emergency_contacts));
  	}

    function onSelectFamilyRelationship(index, option) {
        const newEmergencyContacts = { ...employee_emergency_contacts[index], family_relationship_id: option._id};
        employee_emergency_contacts.splice(index, 1, newEmergencyContacts);
        dispatch(employeeActions.onUpdateEmergencyContacts(employee_emergency_contacts));
    }

    function onAddContact() {
        const addContacts = [...employee_emergency_contacts, {full_name: "", family_relationship_id: "", phone: "" }]
        const newEmergencyContacts = addContacts.map((contact, index)=> {
            return {...contact, sort_order: index}
        })
        dispatch(employeeActions.onUpdateEmergencyContacts(newEmergencyContacts));
    }

    function onRemoveContact(index) {
		if (employee_emergency_contacts[index]._id) {
			employee_emergency_contacts[index]._destroy = true
		} else {
			employee_emergency_contacts.splice(index, 1)
        }

        const newEmergencyContacts = employee_emergency_contacts.map((contact, index)=> {
            return {...contact, sort_order: index}
        })
        
        dispatch(employeeActions.onUpdateEmergencyContacts(newEmergencyContacts));
    }
    
    function onCancel() {
        dispatch(employeeActions.onUnloadEditEmployee())
    };

    function onSubmit() {
        dispatch(employeeActions.onEditEmergencyContacts(id, editEmployee));
    };
    
    function error(key) {
        if (errors != null && errors[`employee.${key}`] != null) {
            return errors[`employee.${key}`].msg
        }
        return null;
    }
    return (
        <Grid container spacing={4} direction="column">
            <Grid item container>
                <Grid item xs>
                    <Typography fontWeight={700} component="h6" lineheight="normal">
                        Edit Emergency Contact
                    </Typography>
                </Grid>
                <Grid item>
                </Grid>
            </Grid>
            <Grid item>
                <Paper sx={{  border: "1px solid #ddd", position: 'relative', zIndex: 9, padding: '0 25px'}}>
                    <List component="div" sx={{ marginBottom: '15px'}}>
                        <ListSubheader sx={{display: 'flex', padding: 0}} component="div">
                            <Grid container spacing={2}>
                                <Grid item xs>
                                    Nama Lengkap
                                </Grid>
                                <Grid item xs={3}>
                                    Hubungan
                                </Grid>
                                <Grid item xs={3}>
                                    No. Kontak
                                </Grid>
                                <Grid item xs={1}>
                                </Grid>
                            </Grid>
                        </ListSubheader>
                        {
                            employee_emergency_contacts.map((row, index) => {
                                if (row._destroy) {
                                    return null;
                                }
                                const familyRelationshipValue = family_relationships.find(opt => opt._id === row.family_relationship_id);
                                return (
                                    <ListItem key={index} sx={{width: '96%', alignItems: "normal", padding: '15px 0'}} disableGutters divider={index !== employee_emergency_contacts.length - 1}>
                                        <Grid container spacing={2}>
                                            <Grid item xs>
                                                <TextField
                                                    fullWidth
                                                    error={!!error(`employee_emergency_contacts[${index}].full_name`)}
                                                    name="full_name"
                                                    placeholder="Nama Lengkap"
                                                    value={row.full_name}
                                                    helperText={error(`employee_emergency_contacts[${index}].full_name`)}
                                                    variant="outlined"
                                                    onChange={onUpdateEmergencyContacts.bind(this, index)}
                                                />
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Autocomplete
                                                    options={family_relationships.sort((a, b) => -b.name.localeCompare(a.name))}
                                                    getOptionLabel={(option) => option.name}
                                                    selectOnFocus
                                                    clearOnBlur
                                                    filterSelectedOptions
                                                    disableClearable
                                                    onChange={(event, option) => {
                                                        onSelectFamilyRelationship(index, option)
                                                    }}
                                                    value={familyRelationshipValue ? familyRelationshipValue : null}
                                                    renderInput={(params) =>   <TextField {...params} error={!!error(`employee_emergency_contacts[${index}].family_relationship_id`)} 
                                                        helperText={error(`employee_emergency_contacts[${index}].family_relationship_id`)}
                                                        placeholder="Relationship" variant="outlined" />
                                                    }
                                                />
                                            </Grid>
                                            <Grid item xs={3}>
                                                <CustomFormat
                                                    fullWidth
                                                    error={!!error(`employee_emergency_contacts[${index}].phone`)}
                                                    name="phone"
                                                    placeholder="No. HP"
                                                    value={row.phone}
                                                    helperText={error(`employee_emergency_contacts[${index}].phone`)}
                                                    variant="outlined"
                                                    onChange={onUpdateEmergencyContacts.bind(this, index)}
                                                />
                                            </Grid>
                                            <Grid item xs={1}>
                                                <DelButton 
                                                    variant="contained"
                                                    size="large"
                                                    disableElevation
                                                    onClick={onRemoveContact.bind(this, index)} >
                                                    Delete
                                                </DelButton>
                                            </Grid>
                                        </Grid>
                                    </ListItem>
                                )
                            })
                        }
                    </List>
                </Paper>
            </Grid>
            <Grid item container sx={{marginBottom: '15px'}} justifyContent="space-between" spacing={3}>
                <Grid item>
                    <AddButton 
                        variant="contained"
                        size="large"
                        disableElevation
                        startIcon={<AddRounded />}
                        onClick={onAddContact}
                        >
                        New Contact
                    </AddButton>
                </Grid>
                <Grid item>
                    <Grid container spacing={3}>
                        <Grid item>
                            <BackButton 
                                variant="contained"
                                size="large"
                                disableElevation
                                disabled={inProgress}
                                onClick={onCancel}
                                >
                                Cancel
                            </BackButton>
                        </Grid>
                        <Grid item>
                            <Button 
                                variant="contained"
                                size="large"
                                color="primary"
                                sx={{minWidth: 195}}
                                onClick={onSubmit}
                                disabled={inProgress}
                                disableElevation>
                                {inProgress ? <CircularProgress size={26} /> : 'Save'}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid> 
        </Grid>
    )
}


const BackButton = styled(Button)(({theme}) => ({
    color: theme.palette.text.primary,
    backgroundColor: "#FFFFFF",
    '&:hover': {
    backgroundColor: "#FFFFFF",
    },
    fontWeight: 500,
    border: '1px solid #ddd',
    minWidth: 195
}));

const AddButton = styled(Button)(({theme}) => ({
    color: theme.palette.primary.main,
    backgroundColor: "#FFFFFF",
    '&:hover': {
      backgroundColor: "#FFFFFF",
    },
    fontWeight: 500,
    border: `1px solid ${theme.palette.primary.main}`
}));


const DelButton = styled(Button)(({theme}) => ({
    color: theme.palette.text.primary,
    backgroundColor: "#FFFFFF",
    '&:hover': {
    backgroundColor: "#FFFFFF",
    },
    fontWeight: 500,
    border: `1px solid #ddd`,
    height: 56
}));