import React, { useState, useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import * as employeeActions from '../../../../../actions/employeeActions';
import { TableContainer,
         Table,
         TableHead,
         TableRow,
         TableCell,
         TableBody,
         Paper,
         Typography,
         TableSortLabel,
         TablePagination,
         Box,
         CircularProgress,
         Card,
         CardMedia,
         CardContent,
         IconButton
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Header from './Header';
import { visuallyHidden } from '@mui/utils';
import DataEmpty from '../../../../Helper/DataEmpty';
import { FirstPage, LastPage, KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';

const headCells = [
    { id: 'name', numeric: false, sortable: true, disablePadding: false, label: 'Nama Karyawan' },
    { id: 'store', numeric: false, sortable: false, disablePadding: false, label: 'Cabang' },
    { id: 'employee_organization', numeric: false, sortable: false, disablePadding: false, label: 'Organisasi' },
    { id: 'employee_job_position', numeric: false, sortable: true, disablePadding: false, label: 'Jabatan' },
    { id: 'status', numeric: false, sortable: true, disablePadding: false, label: 'Status' }
];
  
 
  
function EnhancedTableHead({ order, orderBy, onRequestSort }) {
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => {
                    if (headCell.sortable) {
                        return (
                            <TableCell
                                key={headCell.id}
                                sx={{fontWeight: 700}}
                                align={headCell.numeric ? 'right' : 'left'}
                                padding={headCell.disablePadding ? 'none' : 'normal'}
                                sortDirection={orderBy === headCell.id ? order : false}
                            >
                                <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                                >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                ) : null}
                                </TableSortLabel>
                            </TableCell>
                        )
                    }
                    return (
                        <TableCell
                        key={headCell.id}
                        sx={{fontWeight: 700}}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        >
                            {headCell.label}
                        </TableCell>
                    )
                })}
            </TableRow>
        </TableHead>
    );
};


function TablePaginationActions(props) {
    const { count, page, rowsPerPage, onPageChange } = props;
  
    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };
  
    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };
  
    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };
  
    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
  
    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          <FirstPage />
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          <KeyboardArrowLeft />
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
        <KeyboardArrowRight />
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
        <LastPage />
        </IconButton>
      </Box>
    );
}


export default function Employee() {
    const dispatch = useDispatch();
    const { employees, employees_count } = useSelector(state => ({
        ...state.employee
    }), shallowEqual);

    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('name');
    const [query, setQuery] = useState({ page: 0, limit: 15, search: '' });

    useEffect(() => {
        dispatch(employeeActions.onLoadEmployees(query))
    },[dispatch, query]);

    const onRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
        if (isAsc) {
          const newQuery = {...query};
          delete newQuery.sort_asc;
          newQuery.sort_desc = property;
          setQuery(newQuery)
        } else {
          const newQuery = {...query};
          delete newQuery.sort_desc;
          newQuery.sort_asc = property;
          setQuery(newQuery)
        }
    };


    const onChangePage = (event, newPage) => {
        const newQuery = {...query};
        newQuery.page = newPage;
        setQuery(newQuery)
    };

    const onChangeRowsPerPage = (event) => {
        const newQuery = {...query};
        newQuery.limit = parseInt(event.target.value, 10);
        newQuery.page = 0;
        setQuery(newQuery)
    };

    if (!employees) {
        return (
            <ProgressBox>
              <CircularProgress />
            </ProgressBox>
        )
    }


    function Content() {
        if (employees.length === 0) {
            return (
                <DataEmpty />
            )
        }

        const {page, limit} = query;
        return (
            <Box>
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}>
                        <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={onRequestSort}
                        />
                        <TableBody>
                        {
                            employees.map((employee, index) => {
                                const { _id, increment_id, image, name, store, organization, job_position, status } = employee;
                                return (
                                    <TableRow
                                        hover
                                        onClick={() => {
                                            window.open(`/dashboard/employees/${_id}`);
                                        }}
                                        tabIndex={-1}
                                        key={index} >
                                        <ItemTableCell component="th" scope="row">
                                            <Card variant="outlined" sx={{ display: 'flex', alignItems: 'center', background: "none", border: 0 }}>
                                                <CardMedia
                                                    component="img"
                                                    sx={{ width: '45px', height: '45px' }}
                                                    image={image}
                                                    alt={name}
                                                />
                                                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                                    <CardContent sx={{ flex: '1 0 auto', padding: '10px !important' }}>
                                                        <Typography fontWeight={500} variant="subtitle1">
                                                           {name}
                                                        </Typography>
                                                        <Typography fontSize={12} variant="body2" color="textSecondary" component='div' gutterBottom>
                                                            EM{increment_id}
                                                        </Typography>
                                                    </CardContent>
                                                </Box>
                                                
                                            </Card>
                                        </ItemTableCell>
                                        <ItemTableCell>{store}</ItemTableCell>
                                        <ItemTableCell>{organization}</ItemTableCell>
                                        <ItemTableCell>{job_position}</ItemTableCell>
                                        <ItemTableCell>{status}</ItemTableCell>
                                    </TableRow>
                                );
                            })
                        }
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                rowsPerPageOptions={[15, 20, 25]}
                component="div"
                count={employees_count}
                rowsPerPage={limit}
                page={page}
                ActionsComponent={TablePaginationActions}
                onPageChange={onChangePage}
                onRowsPerPageChange={onChangeRowsPerPage}
                />
            </Box>
        )
    }
    
    return(
        <ContentBox>
            <Header
                query={query}
                setQuery={setQuery} />
            <Paper sx={{ width: '100%', mb: 2, mt: '15px' }} elevation={3}>
                {Content()}
            </Paper>
        </ContentBox>
    )
}

const ProgressBox = styled(Box)(({theme}) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%'
 }));

const ContentBox = styled(Box)(({theme}) => ({
    padding: '25px 0',
    [theme.breakpoints.down('sm')]: {
        padding: '15px 15px 0 15px',
    },
    [theme.breakpoints.down('md')]: {
        padding: '15px 15px 0 15px',
    },
}));


const ItemTableCell = styled(TableCell)(({theme}) => ({
   padding: '0 16px'
}));
