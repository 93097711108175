import React, { useState, useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import * as categoryActions from '../../../../../actions/categoryActions';
import { Paper,
         Box,
         CircularProgress,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Tree from './Tree';
import Header from './Header';
import DataEmpty from '../../../../Helper/DataEmpty';


export default function Category() {
    const dispatch = useDispatch();
    const { categories } = useSelector(state => ({
        ...state.category
    }), shallowEqual);

    // const [treeData, setTreeData] = useState( [
    //     { id: 1, title: 'Chicken', expanded: false, children: [{ id: 3, title: 'Egg', expanded: false, children: [{ id: 7, title: 'Egg2', expanded: false }] }] },
    //     { id: 2, title: 'Fish', expanded: true, children: [{ id: 4, title: 'fingerline', expanded: false }] },
    // ])
    const [treeData, setTreeData] = useState([])

    useEffect(() => {
      dispatch(categoryActions.onLoadCategories())
    },[dispatch]);

    useEffect(() => {
        setTreeData(categories)
    },[categories]);

    
    function onChange(treeData) {
        setTreeData(treeData);
    }

    if (!categories) {
        return (
          <div className="loaderPage">
            <CircularProgress />
          </div>
        )
    }

    function Content() {
        if (categories.length === 0) {
            return <DataEmpty />
        }
        return (
            <TreePaper 
                sx={{position:'relative'}}
                elevation={3}>
                
                <Tree
                    treeData={treeData}
                    // Need to set getNodeKey to get meaningful ids in paths
                    getNodeKey={({ node }) => node._id}
                    onChange={onChange}
                    />
                    
            </TreePaper>
        )
    }

    return (
        <ContentBox>
            <Header />
            <Paper sx={{ width: '100%', mb: 2, mt: '15px' }} elevation={3}>
                {Content()}
            </Paper>
        </ContentBox>
    )
}

const ContentBox = styled(Box)(({theme}) => ({
    padding: '25px 0',
    [theme.breakpoints.down('sm')]: {
        padding: '15px 15px 0 15px',
    },
    [theme.breakpoints.down('md')]: {
        padding: '15px 15px 0 15px',
    },
}));

const TreePaper = styled(Paper)(({theme}) => ({
    padding: 25,
    overflowY: 'scroll',
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100vh - 265px)',
    marginBottom: 25
}));
