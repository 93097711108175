import * as types from '../actions/actionTypes';

const defaultState = {
}

export default function employee (state = defaultState, action) {
	function getDestroyedImages(images) {
		return images.filter(el => {
	    	return el._destroy === true;
	  	}).length;
	}

	let employeeProfiles;
	let employeeIdentities;
	let employeeTaxIdentities;
	let currentUpload;
	let currentRemove;
	let emptyImages;
	switch(action.type) {
		case types.EMPLOYEES_LOADED:
			return {
				...state,
				employees: action.error ? null : action.payload.employees,
				employees_count: action.error ? null : action.payload.employees_count,
            };
		case types.NEW_EMPLOYEE_LOADED:
            return {
                ...state,
                employee: action.payload ? action.payload.employee : null,
				approvals: [],
                employee_job_positions: [],
				gender_options: action.payload ? action.payload.gender_options : null,
				marital_statuses: action.payload ? action.payload.marital_statuses : null,
				employee_statuses: action.payload ? action.payload.employee_statuses : null,
				salary_types: action.payload ? action.payload.salary_types : null,
				bank_options: action.payload ? action.payload.bank_options : null,
				employee_organizations: action.payload ? action.payload.employee_organizations : null,
				tax_statuses: action.payload ? action.payload.tax_statuses : null,
				blood_types: action.payload ? action.payload.blood_types : null,
				religion_options: action.payload ? action.payload.religion_options : null,
				stores: action.error ? null : action.payload.stores,
				areas: [],
				errors: null
            };
		case types.SHOW_EMPLOYEE_LOADED:
			return {
				...state,
				employee: action.payload ? action.payload.employee : null
			};
		case types.EDIT_EMPLOYEE_PERSONAL_INFORMATION_LOADED:
			return {
				...state,
				onEditing: false,
				editPersonalInformation: true,
				areas: [],
				editEmployee: action.payload ? action.payload.employee : null,
				gender_options: action.payload ? action.payload.gender_options : null,
				marital_statuses: action.payload ? action.payload.marital_statuses : null,
				employee_statuses: action.payload ? action.payload.employee_statuses : null,
				blood_types: action.payload ? action.payload.blood_types : null,
				religion_options: action.payload ? action.payload.religion_options : null,
			};
		case types.EDIT_EMPLOYEE_FAMILY_LOADED:
			return {
				...state,
				onEditing: false,
				editFamily: true,
				editEmployee: action.payload ? action.payload.employee : null,
				gender_options: action.payload ? action.payload.gender_options : null,
				family_relationships: action.payload ? action.payload.family_relationships : null,
				religion_options: action.payload ? action.payload.religion_options : null,
			};
		case types.EDIT_EMPLOYEE_EMERGENCY_CONTACT_LOADED:
			return {
				...state,
				onEditing: false,
				editFamily: false,
				editEmergencyContact: true,
				editEmployee: action.payload ? action.payload.employee : null,
				family_relationships: action.payload ? action.payload.family_relationships : null
			};
		case types.EMPLOYEE_FAMILIES_UPDATED:
			return {
				...state,
				editEmployee: {...state.editEmployee, employee_families: action.employee_families }
			};
		case types.EMPLOYEE_EMERGENCY_CONTACTS_UPDATED:
			return {
				...state,
				editEmployee: {...state.editEmployee, employee_emergency_contacts: action.employee_emergency_contacts }
			};
		case types.EDIT_EMPLOYMENT_LOADED:
			return {
				...state,
				onEditing: false,
				editEmployment: true,
				editEmployee: action.payload ? action.payload.employee : null,
				employee_organizations: action.payload ? action.payload.employee_organizations : null,
				employee_statuses: action.payload ? action.payload.employee_statuses : null,
				employee_job_positions: action.payload ? action.payload.employee_job_positions : null,
				stores: action.payload ? action.payload.stores : null,
				approvals: action.payload ? action.payload.approvals : null,
			};
		case types.EMPLOYMENT_ORGANIZATION_SELECTED:
            return {
                ...state,
                editEmployee: action.employee,
                employee_job_positions: action.payload ? action.payload.employee_job_positions : [],
                positionsFetching: false
            };
		case types.EMPLOYMENT_JOB_POSITION_SELECTED:
			return {
				...state,
				editEmployee: action.employee,
				approvals: action.payload ? action.payload.approvals : [],
				approvalsFetching: false
			};
		case types.EDIT_EMPLOYEE_PAYROLL_LOADED:
			return {
				...state,
				onEditing: false,
				editPayroll: true,
				editEmployee: action.payload ? action.payload.employee : null,
				salary_types: action.payload ? action.payload.salary_types : null,
				bank_options: action.payload ? action.payload.bank_options : null,
				tax_statuses: action.payload ? action.payload.tax_statuses : null,
			};
		case types.EDIT_EMPLOYEE_ACCESS_LOADED:
			return {
				...state,
				onEditing: false,
				editAccess: true,
				editEmployee: action.payload ? action.payload.employee : null,
				employee_accesses: action.payload ? action.payload.employee_accesses : null,
			};
		case types.AREAS_LOADED:
			return {
				...state,
				areas: action.payload ? action.payload.areas : null,
                areasFetching: false
			};
        case types.AREAS_UNLOADED:
            return {
				...state,
				areas: [],
                areasFetching: false
			};
        case types.EMPLOYEE_ORGANIZATION_SELECTED:
            return {
                ...state,
                employee: action.employee,
                employee_job_positions: action.payload ? action.payload.employee_job_positions : [],
                positionsFetching: false
            };
        case types.EMPLOYEE_JOB_POSITION_SELECTED:
            return {
                ...state,
                employee: action.employee,
                approvals: action.payload ? action.payload.approvals : [],
                approvalsFetching: false
            };
		case types.EMPLOYEE_FORM_UNLOADED:
			return {
				...state,
                employee: null,
                errors: [],
                inProgress: false,
            };
		case types.FORM_VALIDATION:
			return {
				...state,				
				errors: {...state.errors, ...action.errors}
			};
		case types.EDIT_EMPLOYEE_UPDATED:
            return {
                ...state,
                editEmployee: action.employee
            };
		case types.EDIT_EMPLOYEE_UNLOADED:
			return {
				...state,
				errors: null,
				editEmployee: null,
				editFamily: false,
				editEmergencyContact: false,
				editPersonalInformation: false,
				editEmployment: false,
				editPayroll: false,
				editAccess: false
			};
		case types.EMPLOYEE_UPDATED:
            return {
                ...state,
                employee: action.employee
            };
		case types.EMPLOYEE_CREATED:
			return {
				...state,
				inProgress: false,
				errors: action.error ? action.payload.errors : null
			};
		case types.EMPLOYEE_EDITED:
			return {
				...state,
				inProgress: false,
				errors: action.error ? action.payload.errors : null,
				editPersonalInformation: action.error ? true : false,
				editFamily: action.error ? state.editFamily : false,
				editEmergencyContact: action.error ? state.editEmergencyContact : false,
				editEmployment: action.error ? state.editEmployment : false,
				editPayroll: action.error ? state.editPayroll : false,
				editAccess: action.error ? state.editAccess : false,
				employee: action.error ? state.employee : action.payload.employee
			};
		case types.EMPLOYEE_PIN_SENDED:
			return {
				...state,
				inProgress: false,
			};
		case types.EMPLOYEE_PROFILE_UPLOAD_REQUESTED:
			employeeProfiles = [...state.employee.employee_profiles];
			currentUpload = employeeProfiles[state.employee.employee_profiles.length - 1];
			currentUpload.image = action.image;
			currentUpload.onUpload = true;
			return {
				...state,
				employee: {...state.employee, employee_profiles: employeeProfiles}
			};
		case types.EMPLOYEE_PROFILE_UPLOADED:
			employeeProfiles = [...state.employee.employee_profiles];
			employeeProfiles[action.index] = action.payload;
			return {
				...state,
				employee: action.payload ? {...state.employee, employee_profiles: employeeProfiles} : state.employee
			};
		case types.EMPLOYEE_PROFILE_UPLOADING:
			employeeProfiles = [...state.employee.employee_profiles];
			employeeProfiles[action.index] = action.image;
			return {
				...state,
				employee: {...state.employee, employee_profiles: employeeProfiles}
			};
		case types.EMPLOYEE_PROFILE_REMOVE_REQUESTED:
			employeeProfiles = [...state.employee.employee_profiles];
			currentRemove = employeeProfiles[action.index];
			currentRemove.onRemove = true;
			return {
				...state,
				employee: {...state.employee, employee_profiles: employeeProfiles}
			};
		case types.EMPLOYEE_PROFILE_REMOVED:
			employeeProfiles = [...state.employee.employee_profiles];
			employeeProfiles[action.index] = action.payload;
			emptyImages = employeeProfiles.length - getDestroyedImages(employeeProfiles);
			if (emptyImages === 0) {
				employeeProfiles.push({image: ''})
			}
			return {
				...state,
				employee: action.payload ? {...state.employee, employee_profiles: employeeProfiles} : state.employee
			};
		case types.EMPLOYEE_PROFILE_UPLOAD_EDIT_REQUESTED:
			employeeProfiles = [...state.editEmployee.employee_profiles];
			currentUpload = employeeProfiles[state.editEmployee.employee_profiles.length - 1];
			currentUpload.image = action.image;
			currentUpload.onUpload = true;
			return {
				...state,
				editEmployee: {...state.editEmployee, employee_profiles: employeeProfiles}
			};
		case types.EMPLOYEE_PROFILE_EDIT_UPLOADED:
			employeeProfiles = [...state.editEmployee.employee_profiles];
			employeeProfiles[action.index] = action.payload;
			return {
				...state,
				editEmployee: action.payload ? {...state.editEmployee, employee_profiles: employeeProfiles} : state.editEmployee
			};
		case types.EMPLOYEE_PROFILE_EDIT_UPLOADING:
			employeeProfiles = [...state.editEmployee.employee_profiles];
			employeeProfiles[action.index] = action.image;
			return {
				...state,
				editEmployee: {...state.editEmployee, employee_profiles: employeeProfiles}
			};
		case types.EMPLOYEE_PROFILE_REMOVE_EDIT_REQUESTED:
			employeeProfiles = [...state.editEmployee.employee_profiles];
			currentRemove = employeeProfiles[action.index];
			currentRemove.onRemove = true;
			return {
				...state,
				editEmployee: {...state.editEmployee, employee_profiles: employeeProfiles},
			};
			
		case types.EMPLOYEE_PROFILE_EDIT_REMOVED:
			employeeProfiles = [...state.editEmployee.employee_profiles];
			employeeProfiles[action.index] = action.payload;
			emptyImages = employeeProfiles.length - getDestroyedImages(employeeProfiles);
			if (emptyImages === 0) {
				employeeProfiles.push({image: ''})
			}
			return {
				...state,
				editEmployee: action.payload ? {...state.editEmployee, employee_profiles: employeeProfiles} : state.editEmployee
			};
		case types.EMPLOYEE_IDENTITY_UPLOAD_REQUESTED:
			employeeIdentities = [...state.employee.employee_identities];
			currentUpload = employeeIdentities[state.employee.employee_identities.length - 1];
			currentUpload.image = action.image;
			currentUpload.onUpload = true;
			return {
				...state,
				employee: {...state.employee, employee_identities: employeeIdentities}
			};
		case types.EMPLOYEE_IDENTITY_UPLOADED:
			employeeIdentities = [...state.employee.employee_identities];
			employeeIdentities[action.index] = action.payload;
			return {
				...state,
				employee: action.payload ? {...state.employee, employee_identities: employeeIdentities} : state.employee
			};
		case types.EMPLOYEE_IDENTITY_UPLOADING:
			employeeIdentities = [...state.employee.employee_identities];
			employeeIdentities[action.index] = action.image;
			return {
				...state,
				employee: {...state.employee, employee_identities: employeeIdentities}
			};
		case types.EMPLOYEE_IDENTITY_REMOVE_REQUESTED:
			employeeIdentities = [...state.employee.employee_identities];
			currentRemove = employeeIdentities[action.index];
			currentRemove.onRemove = true;
			return {
				...state,
				employee: {...state.employee, employee_identities: employeeIdentities}
			};
		case types.EMPLOYEE_IDENTITY_REMOVED:
			employeeIdentities = [...state.employee.employee_identities];
			employeeIdentities[action.index] = action.payload;
			emptyImages = employeeIdentities.length - getDestroyedImages(employeeIdentities);
			if (emptyImages === 0) {
				employeeIdentities.push({image: ''})
			}
			return {
				...state,
				employee: action.payload ? {...state.employee, employee_identities: employeeIdentities} : state.employee
			};
		case types.EMPLOYEE_IDENTITY_UPLOAD_EDIT_REQUESTED:
			employeeIdentities = [...state.editEmployee.employee_identities]; 
			currentUpload = employeeIdentities[state.editEmployee.employee_identities.length - 1];
			currentUpload.image = action.image;
			currentUpload.onUpload = true;
			return {
				...state,
				editEmployee: {...state.editEmployee, employee_identities: employeeIdentities}
			};
		case types.EMPLOYEE_IDENTITY_EDIT_UPLOADED:
			employeeIdentities = [...state.editEmployee.employee_identities]; 
			employeeIdentities[action.index] = action.payload;
			return {
				...state,
				editEmployee: action.payload ? {...state.editEmployee, employee_identities: employeeIdentities} : state.editEmployee
			};
		case types.EMPLOYEE_IDENTITY_EDIT_UPLOADING:
			employeeIdentities = [...state.editEmployee.employee_identities]; 
			employeeIdentities[action.index] = action.image;
			return {
				...state,
				editEmployee: {...state.editEmployee, employee_identities: employeeIdentities}
			};
		case types.EMPLOYEE_IDENTITY_REMOVE_EDIT_REQUESTED:
			employeeIdentities = [...state.editEmployee.employee_identities]; 
			currentRemove = employeeIdentities[action.index];
			currentRemove.onRemove = true;
			return {
				...state,
				editEmployee: {...state.editEmployee, employee_identities: employeeIdentities}
			};
		case types.EMPLOYEE_IDENTITY_EDIT_REMOVED:
			employeeIdentities = [...state.editEmployee.employee_identities]; 
			employeeIdentities[action.index] = action.payload;
			emptyImages = employeeIdentities.length - getDestroyedImages(employeeIdentities);
			if (emptyImages === 0) {
				employeeIdentities.push({image: ''})
			}
			return {
				...state,
				editEmployee: action.payload ? {...state.editEmployee, employee_identities: employeeIdentities} : state.editEmployee
			};
		case types.EMPLOYEE_TAX_IDENTITY_UPLOAD_REQUESTED:
			employeeTaxIdentities = [...state.employee.employee_payroll.employee_tax_identities];
			currentUpload = employeeTaxIdentities[state.employee.employee_payroll.employee_tax_identities.length - 1];
			currentUpload.image = action.image;
			currentUpload.onUpload = true;
			return {
				...state,
				employee: {...state.employee, employee_payroll: {...state.employee.employee_payroll, employee_tax_identities: employeeTaxIdentities}}
			};
		case types.EMPLOYEE_TAX_IDENTITY_UPLOADED:
			employeeTaxIdentities = [...state.employee.employee_payroll.employee_tax_identities];
			employeeTaxIdentities[action.index] = action.payload;
			return {
				...state,
				employee: action.payload ?  {...state.employee, employee_payroll: {...state.employee.employee_payroll, employee_tax_identities: employeeTaxIdentities}} : state.employee
			};
		case types.EMPLOYEE_TAX_IDENTITY_UPLOADING:
			employeeTaxIdentities = [...state.employee.employee_payroll.employee_tax_identities];
			employeeTaxIdentities[action.index] = action.image;
			return {
				...state,
				employee: {...state.employee, employee_payroll: {...state.employee.employee_payroll, employee_tax_identities: employeeTaxIdentities}}
			};
		case types.EMPLOYEE_TAX_IDENTITY_REMOVE_REQUESTED:
			employeeTaxIdentities = [...state.employee.employee_payroll.employee_tax_identities];
			currentRemove = employeeTaxIdentities[action.index];
			currentRemove.onRemove = true;
			return {
				...state,
				employee: {...state.employee, employee_payroll: {...state.employee.employee_payroll, employee_tax_identities: employeeTaxIdentities}}
			};
		case types.EMPLOYEE_TAX_IDENTITY_REMOVED:
			employeeTaxIdentities = [...state.employee.employee_payroll.employee_tax_identities];
			employeeTaxIdentities[action.index] = action.payload;
			emptyImages = employeeTaxIdentities.length - getDestroyedImages(employeeTaxIdentities);
			if (emptyImages === 0) {
				employeeTaxIdentities.push({image: ''})
			}
			return {
				...state,
				employee: action.payload ? {...state.employee, employee_payroll: {...state.employee.employee_payroll, employee_tax_identities: employeeTaxIdentities}} : state.employee
			};
		case types.EMPLOYEE_TAX_IDENTITY_UPLOAD_EDIT_REQUESTED:
			employeeTaxIdentities = [...state.editEmployee.employee_payroll.employee_tax_identities];
			currentUpload = employeeTaxIdentities[state.editEmployee.employee_payroll.employee_tax_identities.length - 1];
			currentUpload.image = action.image;
			currentUpload.onUpload = true;
			return {
				...state,
				editEmployee: {...state.editEmployee, employee_payroll: {...state.editEmployee.employee_payroll, employee_tax_identities: employeeTaxIdentities}}
			};
		case types.EMPLOYEE_TAX_IDENTITY_EDIT_UPLOADED:
			employeeTaxIdentities = [...state.editEmployee.employee_payroll.employee_tax_identities];
			employeeTaxIdentities[action.index] = action.payload;
			return {
				...state,
				editEmployee: action.payload ?  {...state.editEmployee, employee_payroll: {...state.editEmployee.employee_payroll, employee_tax_identities: employeeTaxIdentities}} : state.editEmployee
			};
		case types.EMPLOYEE_TAX_IDENTITY_EDIT_UPLOADING:
			employeeTaxIdentities = [...state.editEmployee.employee_payroll.employee_tax_identities];
			employeeTaxIdentities[action.index] = action.image;
			return {
				...state,
				editEmployee: {...state.editEmployee, employee_payroll: {...state.editEmployee.employee_payroll, employee_tax_identities: employeeTaxIdentities}}
			};
		case types.EMPLOYEE_TAX_IDENTITY_REMOVE_EDIT_REQUESTED:
			employeeTaxIdentities = [...state.editEmployee.employee_payroll.employee_tax_identities];
			currentRemove = employeeTaxIdentities[action.index];
			currentRemove.onRemove = true;
			return {
				...state,
				editEmployee: {...state.editEmployee, employee_payroll: {...state.editEmployee.employee_payroll, employee_tax_identities: employeeTaxIdentities}}
			};
		case types.EMPLOYEE_TAX_IDENTITY_EDIT_REMOVED:
			employeeTaxIdentities = [...state.editEmployee.employee_payroll.employee_tax_identities];
			employeeTaxIdentities[action.index] = action.payload;
			emptyImages = employeeTaxIdentities.length - getDestroyedImages(employeeTaxIdentities);
			if (emptyImages === 0) {
				employeeTaxIdentities.push({image: ''})
			}
			return {
				...state,
				editEmployee: action.payload ? {...state.editEmployee, employee_payroll: {...state.editEmployee.employee_payroll, employee_tax_identities: employeeTaxIdentities}} : state.editEmployee
			};
		case types.ASYNC_START:
			if ( action.subtype === types.EMPLOYEE_CREATED ||
					action.subtype === types.EMPLOYEE_EDITED ||
					action.subtype === types.EMPLOYEE_PIN_SENDED
				) {
				return { 
					...state, 
					inProgress: true };
			};
			if ( action.subtype === types.AREAS_LOADED ) {
                return { 
                    ...state, 
                    areasFetching: true
                };
            };
			if ( action.subtype === types.EDIT_EMPLOYEE_PERSONAL_INFORMATION_LOADED ||
				action.subtype === types.EDIT_EMPLOYEE_FAMILY_LOADED ||
				action.subtype === types.EDIT_EMPLOYEE_EMERGENCY_CONTACT_LOADED ||
				action.subtype === types.EDIT_EMPLOYMENT_LOADED ||
				action.subtype === types.EDIT_EMPLOYEE_PAYROLL_LOADED ||
				action.subtype === types.EDIT_EMPLOYEE_ACCESS_LOADED) {
                return { 
                    ...state, 
                    onEditing: true 
                };
            };
            if ( action.subtype === types.EMPLOYEE_ORGANIZATION_SELECTED || action.subtype === types.EMPLOYMENT_ORGANIZATION_SELECTED ) {
                return { 
                    ...state, 
                    employee_job_positions: [],
                    positionsFetching: true 
                };
            };
            if ( action.subtype === types.EMPLOYEE_JOB_POSITION_SELECTED || action.subtype === types.EMPLOYMENT_JOB_POSITION_SELECTED ) {
                return { 
                    ...state, 
                    approvals: [],
                    approvalsFetching: true 
                };
            };
			return state;
		default:
			return state;
	}
}