import React, {useEffect} from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import * as employeeActions from '../../../../../../../actions/employeeActions';
import { 
    Typography,
    Grid,
    Button,
    Autocomplete,
    TextField,
    CircularProgress
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { styled } from '@mui/material/styles';
import { useParams } from "react-router-dom";
  
export default function Edit() {
    let { id } = useParams();
    const dispatch = useDispatch();
    const { editEmployee, stores, employee_organizations, employee_job_positions, approvals, employee_statuses, errors, inProgress, positionsFetching, approvalsFetching } = useSelector(state => ({
        ...state.employee
    }), shallowEqual);


    const {store_id, employee_organization_id, employee_job_position_id, parent_id, employee_status_id, started_date, ended_date } = editEmployee;

    useEffect(() => {
        return () => {
            dispatch(employeeActions.onUnloadEditEmployee());
        };
    },[dispatch]);

    function onSelectStore(option) {
        const newEmployee = {...editEmployee};
        newEmployee.store_id = option._id;
        dispatch(employeeActions.onUpdateEditEmployee(newEmployee));
    }

    function onSelectEmployeeOrganization(option) {
        const newEmployee = {...editEmployee};
        newEmployee.employee_organization_id = option._id;
        newEmployee.employee_job_position_id = '';
        newEmployee.parent_id = '';
        dispatch(employeeActions.onSelectEmploymentOrganization(newEmployee));
    }

    function onSelectEmployeeJobPosition(option) {
        if (option.name.toLowerCase() === 'ceo') {
            if (errors && errors.parent_id) {
                delete errors.parent_id;
            }
        }
        const newEmployee = {...editEmployee};
        newEmployee.employee_job_position_id = option._id;
        newEmployee.parent_id = '';
        dispatch(employeeActions.onSelectEmploymentJobPosition(newEmployee));
    }

    function onSelectParent(option) {
        const newEmployee = {...editEmployee};
        newEmployee.parent_id = option._id;
        dispatch(employeeActions.onUpdateEditEmployee(newEmployee));
    }

    function onSelectEmployeeStatus(option) {
        const newEmployee = {...editEmployee};
        newEmployee.employee_status_id = option._id;
        dispatch(employeeActions.onUpdateEditEmployee(newEmployee));
    }


    const onSelectStartDate = (date) => {
        const newEmployee = {...editEmployee};
        newEmployee.started_date = date;
        dispatch(employeeActions.onUpdateEditEmployee(newEmployee));
    };

    const onSelectEndDate = (date) => {
        const newEmployee = {...editEmployee};
        newEmployee.ended_date = date;
        dispatch(employeeActions.onUpdateEditEmployee(newEmployee));
    };

    function onCancel() {
        dispatch(employeeActions.onUnloadEditEmployee());
    };

    function onSubmit() {
        dispatch(employeeActions.onEditEmployments(id, editEmployee));
    };

    const onKeyDown = (e) => {
        e.preventDefault();
    };
    

    function error(key) {
        if (errors != null && errors[`employee.${key}`] != null) {
            return errors[`employee.${key}`].msg
        }
        return null;
    }

    const storeOptions = stores.map((option) => {
        const firstLetter = option.name[0].toUpperCase();
        return {
          firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
          ...option,
        };
    });


    const storeValue = storeOptions.find(opt => opt._id === store_id);
    const employeeOrganizationValue = employee_organizations.find(opt => opt._id === employee_organization_id);
    const employeeJobPositionValue = employee_job_positions.find(opt => opt._id === employee_job_position_id);
    const employeeParentValue = approvals.find(opt => opt._id === parent_id);
    const employeeStatusValue = employee_statuses.find(opt => opt._id === employee_status_id);
    return (
        <Grid container spacing={4} direction="column">
            <Grid item container>
                <Grid item xs>
                    <Typography fontWeight={700} component="h6" lineheight="normal">
                        Ubah Pekerjaan
                    </Typography>
                </Grid>
                <Grid item>
                </Grid>
            </Grid>
            <Grid item container direction="column" spacing={3}>
                <Grid item container spacing={8}>
                    <Grid item xs={4}>
                        <Typography fontSize={16} fontWeight={700} component="div">
                            Cabang
                        </Typography>
                        <Typography variant="body2" gutterBottom color="textSecondary">
                            Pilih cabang karyawan bekerja.
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Autocomplete
                            options={storeOptions.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                            groupBy={(option) => option.firstLetter}
                            getOptionLabel={(option) => option.name}
                            blurOnSelect
                            filterSelectedOptions
                            disableClearable
                            onChange={(event, option) => {
                                onSelectStore(option)
                            }}
                            value={storeValue ? storeValue : null}
                            isOptionEqualToValue={(
                                option,
                                value,
                            ) => value.value === option.value}
                            renderInput={(params) => <TextField {...params} error={!!error('store_id')} helperText={error('store_id')} placeholder="Pilih Cabang" variant="outlined" />}
                        />
                    </Grid> 
                </Grid>
                <Grid item container spacing={8}>
                    <Grid item xs={4}>
                        <Typography fontSize={16} fontWeight={700} component="div">
                            Organisasi
                        </Typography>
                        <Typography variant="body2" gutterBottom color="textSecondary">
                            Pilih organisasi karyawan.
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Autocomplete
                            options={employee_organizations.sort((a, b) => -b.name.localeCompare(a.name))}
                            groupBy={(option) => option.firstLetter}
                            getOptionLabel={(option) => option.name}
                            blurOnSelect
                            filterSelectedOptions
                            disableClearable
                            onChange={(event, option) => {
                                onSelectEmployeeOrganization(option)
                            }}
                            value={employeeOrganizationValue ? employeeOrganizationValue : null}
                            renderInput={(params) => <TextField {...params} error={!!error('employee_organization_id')} helperText={error('employee_organization_id')} placeholder="Pilih Organisasi" variant="outlined" />}
                        />
                    </Grid> 
                </Grid>
                <Grid item container spacing={8}>
                    <Grid item xs={4}>
                        <Typography fontSize={16} fontWeight={700} component="div">
                            Jabatan
                        </Typography>
                        <Typography variant="body2" gutterBottom color="textSecondary">
                            Pilih jabatan karyawan.
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Autocomplete
                            options={employee_job_positions.sort((a, b) => -b.name.localeCompare(a.name))}
                            groupBy={(option) => option.firstLetter}
                            getOptionLabel={(option) => option.name}
                            blurOnSelect
                            disabled={employee_job_positions.length === 0}
                            filterSelectedOptions
                            disableClearable
                            onChange={(event, option) => {
                                onSelectEmployeeJobPosition(option)
                            }}
                            value={employeeJobPositionValue ? employeeJobPositionValue : null}
                            loading={positionsFetching}
                            renderInput={(params) => <TextField {...params} 
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                    <React.Fragment>
                                        {positionsFetching ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                    ),
                                }}
                                error={!!error('employee_job_position_id')} helperText={error('employee_job_position_id')} placeholder="Pilih Jabatan" variant="outlined" />}
                        />
                    </Grid> 
                </Grid>
                <Grid item container spacing={8}>
                    <Grid item xs={4}>
                        <Typography fontSize={16} fontWeight={700} component="div">
                            Atasan
                        </Typography>
                        <Typography variant="body2" gutterBottom color="textSecondary">
                            Pilih atasan/pengawas langsung karyawan.
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Autocomplete
                            options={approvals}
                            groupBy={(option) => option.firstLetter}
                            getOptionLabel={(option) => option.name}
                            blurOnSelect
                            disabled={!employeeJobPositionValue || !employeeOrganizationValue}
                            filterSelectedOptions
                            disableClearable
                            onChange={(event, option) => {
                                onSelectParent(option)
                            }}
                            value={employeeParentValue ? employeeParentValue : null}
                            loading={approvalsFetching}
                            renderInput={(params) => <TextField {...params}
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                    <React.Fragment>
                                        {approvalsFetching ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                    ),
                                }}
                                error={!!error('parent_id')} helperText={error('parent_id')} placeholder="Pilih Atasan" variant="outlined" />}
                        />
                    </Grid> 
                </Grid>
                <Grid item container spacing={8}>
                    <Grid item xs={4}>
                        <Typography fontSize={16} fontWeight={700} component="div">
                            Status
                        </Typography>
                        <Typography variant="body2" gutterBottom color="textSecondary">
                            Pilih satus karyawan.
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Autocomplete
                            options={employee_statuses.sort((a, b) => -b.name.localeCompare(a.name))}
                            getOptionLabel={(option) => option.name}
                            blurOnSelect
                            filterSelectedOptions
                            disableClearable
                            onChange={(event, option) => {
                                onSelectEmployeeStatus(option)
                            }}
                            value={employeeStatusValue ? employeeStatusValue : null}
                            renderInput={(params) => <TextField {...params} error={!!error('employee_status_id')} helperText={error('employee_status_id')} placeholder="Pilih Status Karyawan" variant="outlined" />}
                        />
                    </Grid> 
                </Grid>
                {<Grid item container spacing={8}>
                    <Grid item xs={4}>
                        <Typography fontSize={16} fontWeight={700} component="div">
                            Tanggal Mulai - Tanggal Berakhir
                        </Typography>
                        <Typography variant="body2" gutterBottom color="textSecondary">
                            Masukan tanggal mulai dan berakhir.
                        </Typography>
                    </Grid>
                    <Grid item xs={8} container spacing={5}>
                        <Grid item xs>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DatePicker
                                    openTo="year"
                                    disablePast
                                    value={started_date}
                                    format="dd/MM/yyyy"
                                    onChange={onSelectStartDate}
                                    renderInput={(params) => <TextField fullWidth onKeyDown={onKeyDown} {...params} />}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DatePicker
                                    disabled={employeeStatusValue && employeeStatusValue.name.toLowerCase() === "permanent"}
                                    openTo="year"
                                    disablePast
                                    value={ended_date}
                                    format="dd/MM/yyyy"
                                    onChange={onSelectEndDate}
                                    renderInput={(params) => <TextField fullWidth onKeyDown={onKeyDown} {...params} />}
                                />
                            </LocalizationProvider>
                        </Grid>
                    </Grid> 
                </Grid>   }
            </Grid>
            <Grid item container sx={{marginBottom: '15px'}} justifyContent="space-between" spacing={3}>
                <Grid item>
                </Grid>
                <Grid item>
                    <Grid container spacing={3}>
                        <Grid item>
                            <BackButton 
                                variant="contained"
                                size="large"
                                disableElevation
                                disabled={inProgress}
                                onClick={onCancel}>
                                Batal
                            </BackButton>
                        </Grid>
                        <Grid item>
                            <Button 
                                variant="contained"
                                size="large"
                                color="primary"
                                sx={{minWidth: 195}}
                                onClick={onSubmit}
                                disabled={inProgress}
                                disableElevation>
                                {inProgress ? <CircularProgress size={26} /> : 'Simpan'}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>  
        </Grid>
    )
}

const BackButton = styled(Button)(({theme}) => ({
    color: theme.palette.text.primary,
    backgroundColor: "#FFFFFF",
    '&:hover': {
    backgroundColor: "#FFFFFF",
    },
    fontWeight: 500,
    border: '1px solid #ddd',
    minWidth: 195
}));