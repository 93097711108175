import React, { useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import * as orderActions from '../../../../../../../actions/orderActions';
import { TableContainer,
         Table,
         TableHead,
         TableRow,
         TableCell,
         TableBody,
         Paper,
         Typography,
         Grid,
         Box,
         IconButton,
         AppBar,
         Toolbar,
         Divider,
         Collapse,
         Stack,
         Tooltip
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { TextSnippetTwoTone, Close, KeyboardArrowDown, KeyboardArrowUp, HelpTwoTone  } from '@mui/icons-material';
import Loader from '../../../../../../Helper/Loader';
import NumberFormat from 'react-number-format';
import { tooltipClasses } from '@mui/material/Tooltip';
import moment from 'moment';
import 'moment/locale/id'; 

const headCells = [
    { id: 'order_id', numeric: false, sortable: true, disablePadding: false, label: 'ID Pesanan' },
    { id: 'due_date', numeric: false, sortable: false, disablePadding: false, label: 'Tgl. Jatuh Tempo' },
    { id: 'grand_total', numeric: true, sortable: false, disablePadding: false, label: 'Total Tagihan' },
    { id: 'canceled_total', numeric: true, sortable: false, disablePadding: false, label: 'Total Pembatalan' },
    { id: 'paid_total', numeric: true, sortable: false, disablePadding: false, label: 'Total Pembayaran' },
    { id: 'due_total', numeric: true, sortable: false, disablePadding: false, label: 'Total Jatuh Tempo' },
    { id: 'status', numeric: true, sortable: true, disablePadding: false, label: 'Status' },
];
 
  
function EnhancedTableHead() {
    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => {
                    return (
                        <ItemTableCell
                        key={headCell.id}
                        sx={{fontWeight: 700}}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        >
                            {headCell.label}
                        </ItemTableCell>
                    )
                })}
            </TableRow>
        </TableHead>
    );
}

export default function Content() {
    const dispatch = useDispatch();
    const { order, payments } = useSelector(state => ({
        ...state.order
    }), shallowEqual);

    useEffect(() => {
        dispatch(orderActions.onLoadPayments(order._id))
    },[dispatch]);

    function onOpen(index, event) {
        const currentItem = payments[index];
        currentItem.open = true;
        const newPayments = [...payments];
        newPayments[index] = currentItem;
        dispatch(orderActions.onCollapsePayments(newPayments));
    }
    
    function onClose(index, event) {
        const currentItem = payments[index];
        currentItem.open = false;
        const newPayments = [...payments];
        newPayments[index] = currentItem;
        dispatch(orderActions.onCollapsePayments(newPayments));
    }

    function onOpenNote(index, event) {
        const currentItem = payments[index];
        currentItem.openNote = true;
        const newPayments = [...payments];
        newPayments[index] = currentItem;
        dispatch(orderActions.onCollapsePayments(newPayments));
    }
    
    function onCloseNote(index, event) {
        const currentItem = payments[index];
        currentItem.openNote = false;
        const newPayments = [...payments];
        newPayments[index] = currentItem;
        dispatch(orderActions.onCollapsePayments(newPayments));
    }

    function onHide() {
        dispatch(orderActions.onHidePayments());
    }

    function onCollapsed(index, event) {
        const currentItem = payments[index];
        if (currentItem.expanded) {
            currentItem.expanded = false;
        } else {
            currentItem.expanded = true;
        }
        const newPayments = [...payments];
        newPayments[index] = currentItem;
        dispatch(orderActions.onCollapsePayments(newPayments));
    }

    function total(item) {
        const { payment_amount, invoice_total, outstanding_balance} = item;
        return (
            <Box>
                <Grid container spacing={1} direction="column">
                    <Grid item container justifyContent="flex-end">
                        <Grid item xs>
                            <Typography fontWeight={700}>
                                Total Tagihan
                            </Typography>
                        </Grid>
                        <Grid item>
                            <NumberFormat
                                value={invoice_total}
                                displayType="text"
                                thousandSeparator={"."}
                                decimalSeparator={","}
                                prefix="Rp "
                                isNumericString
                                decimalScale={2}
                                renderText={(value) => <Typography fontWeight={700}>
                                {value}
                                </Typography>}
                            />
                        </Grid>
                    </Grid>                  
                    <Grid item container justifyContent="flex-end">
                        <Grid item xs>
                            <Typography color="rgb(56, 142, 60)"  fontWeight={700}>
                                Total Pembayaran
                            </Typography>
                        </Grid>
                        <Grid item>
                            <NumberFormat
                                value={payment_amount}
                                displayType="text"
                                thousandSeparator={"."}
                                decimalSeparator={","}
                                prefix="Rp "
                                isNumericString
                                decimalScale={2}
                                renderText={(value) => <Typography color="rgb(56, 142, 60)" fontWeight={700}>
                                {value}
                                </Typography>}
                            />
                        </Grid>
                    </Grid>
                    {
                        outstanding_balance !== 0 && <Grid item container justifyContent="flex-end">
                        <Grid item xs>
                            <Typography color="rgb(211, 47, 47)" fontWeight={700}>
                                Sisa Tagihan
                            </Typography>
                        </Grid>
                        <Grid item>
                            <NumberFormat
                                value={outstanding_balance}
                                displayType="text"
                                thousandSeparator={"."}
                                decimalSeparator={","}
                                prefix="Rp "
                                isNumericString
                                decimalScale={2}
                                renderText={(value) => <Typography color="rgb(211, 47, 47)" fontWeight={700}>
                                {value}
                            </Typography>}
                            />
                        </Grid>
                    </Grid>
                    }
                </Grid>
            </Box>
        )
    }


    function Items() {
        if (!payments) {
            return (
                <Loader />
            )
        }
        
        return (
            <Stack spacing={2} sx={{padding: '25px'}}>
                {payments.map((item ,index) => {
                    const { increment_id, payment_amount, payment_title, expanded, created_at, note } = item;
                    return (
                        <Item key={index}>
                             <Grid container direction="column">
                                <Grid item container spacing={6} alignItems="center">
                                    <Grid xs item>
                                        <Typography sx={{position: 'relative'}} fontWeight={500} fontSize={13} variant="body1">
                                            ID Pembayaran
                                            {note && <NoteTooltip open={item.openNote} onClose={onCloseNote.bind(this, index)} onOpen={onOpenNote.bind(this, index)} title={note}>
                                                <TextSnippetTwoTone color="primary" />
                                            </NoteTooltip>}
                                        </Typography>
                                        <Typography fontWeight={700} fontSize={16} variant="h6">
                                            {increment_id}
                                        </Typography>
                                    </Grid>
                                    <Grid xs item>
                                        <Typography sx={{position: 'relative'}} fontWeight={500} fontSize={13} variant="body1">
                                            Jumlah Pembayaran
                                            <LightTooltip open={item.open} onClose={onClose.bind(this, index)} onOpen={onOpen.bind(this, index)} title={total(item)}>
                                                <HelpTwoTone color="primary" />
                                            </LightTooltip>
                                        </Typography>
                                        <NumberFormat
                                            value={payment_amount}
                                            displayType="text"
                                            thousandSeparator={"."}
                                            decimalSeparator={","}
                                            prefix="Rp "
                                            isNumericString
                                            decimalScale={2}
                                            renderText={(value) => <Typography fontWeight={700} fontSize={16} variant="h6">
                                            {value}
                                            </Typography>}
                                        />
                                    </Grid>
                                    <Grid xs item>
                                        <Typography fontWeight={500} fontSize={13} variant="body1">
                                            Metode Pembayaran
                                        </Typography>
                                        <Typography fontWeight={700} fontSize={16} variant="h6">
                                            {payment_title}
                                        </Typography>
                                    </Grid>
                                    <Grid xs item>
                                        <Typography fontWeight={500} fontSize={13} variant="body1">
                                            Tgl. Pembayaran
                                        </Typography>
                                        <Typography fontWeight={700} fontSize={16} variant="h6">
                                            {moment(created_at).format('LL')}, {moment(created_at).format('LT')} WIB
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <IconButton
                                            aria-label="expand row"
                                            size="small"
                                            onClick={onCollapsed.bind(this, index)}
                                        >
                                            {expanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                                        </IconButton>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Collapse in={item.expanded} timeout="auto" unmountOnExit>
                                        <Divider sx={{padding: '15px'}} />
                                        <TableContainer>
                                            <Table sx={{width: '120%'}}>
                                                <EnhancedTableHead />
                                                <TableBody>
                                                {
                                                    item.order_payment_items.sort((a, b) => -a.order_id.localeCompare(b.order_id)).map((order, itemIndex) => {
                                                        const { order_id, due_date, grand_total, canceled_amount, paid_amount, due_amount, status_title } = order;
                                                        return (
                                                            <TableRow
                                                                hover
                                                                tabIndex={-1}
                                                                key={itemIndex} >
                                                                <ItemTableCell component="th" scope="row">
                                                                    {order_id}
                                                                </ItemTableCell>
                                                                <ItemTableCell>
                                                                    {moment(due_date).format('LL')}
                                                                </ItemTableCell>
                                                                <ItemTableCell align="right">
                                                                    <NumberFormat
                                                                        value={grand_total}
                                                                        displayType="text"
                                                                        thousandSeparator={"."}
                                                                        decimalSeparator={","}
                                                                        prefix="Rp "
                                                                        isNumericString
                                                                        decimalScale={2}
                                                                    />
                                                                </ItemTableCell>
                                                                <ItemTableCell align="right">
                                                                    <NumberFormat
                                                                        value={canceled_amount}
                                                                        displayType="text"
                                                                        thousandSeparator={"."}
                                                                        decimalSeparator={","}
                                                                        prefix="Rp "
                                                                        isNumericString
                                                                        decimalScale={2}
                                                                    />
                                                                </ItemTableCell>
                                                                <ItemTableCell align="right">
                                                                    <NumberFormat
                                                                        value={paid_amount}
                                                                        displayType="text"
                                                                        thousandSeparator={"."}
                                                                        decimalSeparator={","}
                                                                        prefix="Rp "
                                                                        isNumericString
                                                                        decimalScale={2}
                                                                    />
                                                                </ItemTableCell>
                                                                <ItemTableCell align="right">
                                                                    <NumberFormat
                                                                        value={due_amount}
                                                                        displayType="text"
                                                                        thousandSeparator={"."}
                                                                        decimalSeparator={","}
                                                                        prefix="Rp "
                                                                        isNumericString
                                                                        decimalScale={2}
                                                                    />
                                                                </ItemTableCell>
                                                                <ItemTableCell align="right">
                                                                    {status_title}
                                                                </ItemTableCell>
                                                            </TableRow>
                                                        );
                                                    })
                                                }
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Collapse>
                                </Grid>
                            </Grid>
                        </Item>
                    )
                })}
            </Stack>
        )
    }
    return(
        <Box sx={{height: '100%'}}>
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton
                    edge="start"
                    color="inherit"
                    onClick={onHide} 
                    aria-label="close"
                    >
                    <Close />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        Pembayaran
                    </Typography>
                </Toolbar>
            </AppBar>
            {Items()}
        </Box>
    )  
}


const ItemTableCell = styled(TableCell)(({theme}) => ({
    padding: '15px 5px'
}));


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(3),
    color: theme.palette.text.secondary,
}));

const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} PopperProps={{
        sx: {
          zIndex: 9999
        }
      }} sx={{marginLeft: '5px', position: 'absolute', top: -2}} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      width: 325,
    //   position: 'absolute',
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 11,
      padding: 15
    }
}));

const NoteTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} PopperProps={{
        sx: {
          zIndex: 9999
        }
      }} sx={{marginLeft: '5px', position: 'absolute', top: -2}} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
    //   position: 'absolute',
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 11,
      padding: 15
    }
}));