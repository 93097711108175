import React, { useState, useRef, useEffect } from 'react';
import {Box, 
        Grid,
        Paper,
        Typography} from '@mui/material';
import { styled } from '@mui/material/styles';
import trackImg from '../../../assets/images/home/features/track.png';
import customerImg from '../../../assets/images/home/features/customer.png';
import customerGroupImg from '../../../assets/images/home/features/customer-group.png';
import stockImg from '../../../assets/images/home/features/stock.png';
import invoiceImg from '../../../assets/images/home/features/invoice.png';
import labelImg from '../../../assets/images/home/features/label.png';
import outletImg from '../../../assets/images/home/features/outlet.png';
import variantImg from '../../../assets/images/home/features/variant.png';
import optionImg from '../../../assets/images/home/features/option.png';
import activityImg from '../../../assets/images/home/features/activity.png';
import bulkPaymentImg from '../../../assets/images/home/features/bulk-payment.png';
import presentationImg from '../../../assets/images/home/features/presentation.png';
import deviceImg from '../../../assets/images/home/features/device.png';
// import Slider from "react-slick";

export default function Feature() {
    const slider1 = useRef();
    const slider2 = useRef();
    const [currentSlide, setCurrentSlide] = useState(0);
    const [nav, setNav] = useState({nav1: null, nav2: null});

    useEffect(() => {
        setNav({nav1: slider1.current, nav2: slider2.current})
    },[]);

    const features = [
        {
            label: 'Scan & Track',
            description: "Cari pesanan, inventory, dan laporan dengan mudah.",
            imgPath: trackImg
        },
        {
            label: 'Data Pelanggan',
            description: "Ketahui pelanggan potensial.",
            imgPath: customerImg
        },
        {
            label: 'Grup Pelanggan',
            description: "Harga khusus untuk pelanggan khusus.",
            imgPath: customerGroupImg
        },
        {
            label: 'Kelola Stok',
            description: "Pantau persedian barang, mutasi dan transfer.",
            imgPath: stockImg
        },
        {
            label: 'Print Invoice',
            description: "Hanya dengan satu kali klik.",
            imgPath: invoiceImg
        },
        {
            label: 'Print Label',
            description: "Label khusus dengan QR-CODE.",
            imgPath: labelImg
        },
        {
            label: 'Multi Cabang',
            description: "Kelola cabang dan laporan dengan mudah.",
            imgPath: outletImg
        },
        {
            label: 'Produk Varian',
            description: "Kelola varian dengan mudah.",
            imgPath: variantImg
        },
        {
            label: 'Produk Opsi',
            description: "Jual barang kubikasi / meteran / satuan.",
            imgPath: optionImg
        },
        {
            label: 'Aktifitas Kasir',
            description: "Pantau aktivitas harian kasir.",
            imgPath: activityImg
        },
        {
            label: 'Bulk Payment',
            description: "Pembayaran invoice menjadi mudah.",
            imgPath: bulkPaymentImg
        },
        {
            label: 'Layar Pelanggan',
            description: "Promo khusus untuk pelanggan.",
            imgPath: presentationImg
        },
        {
            label: 'Dukungan Perangkat',
            description: "Scan, print struk, print label, dan print nota.",
            imgPath: deviceImg
        },
    ];

    const settings = {
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        centerMode: true,
        speed: 450,
        autoplaySpeed: 3000,
        cssEase: "linear",
        vertical: true,
        verticalSwiping: true,
        swipeToSlide:true,
        focusOnSelect: true,
        arrows: false,
        afterChange: function(currentSlide) {
            setCurrentSlide(currentSlide)
        }
    };

    const mobileSettings = {
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        centerMode: true,
        speed: 450,
        autoplaySpeed: 3000,
        cssEase: "linear",
        vertical: false,
        verticalSwiping: true,
        swipeToSlide:true,
        focusOnSelect: true,
        arrows: false,
        afterChange: function(currentSlide) {
            setCurrentSlide(currentSlide)
        }
    };

    const {nav1, nav2} = nav;
    return (
       <SectionBox>
            {/* <WrapperGrid container alignItems="center" spacing={2}>
                <ListGrid item container xs={12} sm={5}>
                    <SlickSlider
                        asNavFor={nav1}
                        ref={slider => (slider2.current = slider)}
                        {...settings}>
                        {
                            features.map((feature, index) => {
                                return (
                                    <Box key={index}>
                                        <SliderPaper elevation={3}>
                                            <Typography fontWeight={500} fontSize={18} variant="h6" gutterBottom>
                                                {feature.label}
                                            </Typography>
                                            <Typography
                                                component="span"
                                                variant="body2"
                                                color={currentSlide === index ? "primary" : "textSecondary"}
                                                fontWeight={300}>
                                                {feature.description}
                                            </Typography>
                                        </SliderPaper>
                                    </Box>
                                )
                            })
                        }
                    </SlickSlider>
                </ListGrid>
                <ImageWrapperGrid item container xs={12} sm={7}>
                    <SlickSlider
                        asNavFor={nav2}
                        fade={true}
                        arrows={false}
                        ref={slider => (slider1.current = slider)}>
                        {
                            features.map((feature, index) => {
                                return (
                                    <Box key={index} component="img" src={feature.imgPath} />
                                )
                            })
                        }
                    </SlickSlider>
                </ImageWrapperGrid>
                <MobileListGrid item container xs={12} sm={5}>
                    <SlickSlider
                        asNavFor={nav1}
                        ref={slider => (slider2.current = slider)}
                        {...mobileSettings}>
                        {
                            features.map((feature, index) => {
                                return (
                                    <Box key={index}>
                                        <SliderPaper elevation={3}>
                                            <Typography fontWeight={500} fontSize={18} variant="h6" gutterBottom>
                                                {feature.label}
                                            </Typography>
                                            <Typography
                                                component="span"
                                                variant="body2"
                                                color={currentSlide === index ? "primary" : "textSecondary"}
                                                fontWeight={300}>
                                                {feature.description}
                                            </Typography>
                                        </SliderPaper>
                                    </Box>
                                )
                            })
                        }
                    </SlickSlider>
                </MobileListGrid>
            </WrapperGrid> */}
       </SectionBox>
    )

    
}


const SectionBox = styled(Box)(({theme}) => ({
    paddingLeft: 75,
    paddingRight: 75,
    paddingTop: 90,
    [theme.breakpoints.between('xs','sm')]: {
        paddingLeft: 15,
        paddingRight: 15
    },
    [theme.breakpoints.between('sm', 'lg')]: {
        paddingLeft: 25,
        paddingRight: 25
    }
}));

const WrapperGrid = styled(Grid)(({theme}) => ({
    position: 'relative',
    ':after': {
        position: 'absolute',
        content: '""',
        width: '60%',
        zIndex: '1',
        top: 0,
        right: 0,
        height: '70%',
        backgroundSize: '18px 18px',
        backgroundImage: 'radial-gradient(rgba(47, 106, 217, 0.4) 20%, transparent 20%)',
        opacity: '0.2'
    },
}));

const ListGrid = styled(Grid)(({theme}) => ({
    position: 'relative',
    zIndex: 5,
    [theme.breakpoints.only('xs')]: {
       display: 'none'
    },
}));

const MobileListGrid = styled(Grid)(({theme}) => ({
    position: 'relative',
    zIndex: 5,
    display: 'none',
    marginTop: 5,
    [theme.breakpoints.only('xs')]: {
        display: 'block'
    },
}));

const ImageWrapperGrid = styled(Grid)(({theme}) => ({
   position: 'relative',
   zIndex: 5
}));

// const SlickSlider = styled(Slider)(({theme}) => ({
//     position: 'relative',
//     width: '100%'
//  }));

const SliderPaper = styled(Paper)(({theme}) => ({
    padding: '10px 15px',
    margin: '5px 10px 5px 0',
    [theme.breakpoints.between('xs','sm')]: {
        margin: '5px 5px 5px 0',
        minHeight: 75
    },
}));

