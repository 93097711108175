import * as types from '../actions/actionTypes';

const defaultState = {
	
}

export default (state = defaultState, action) => {

	switch(action.type) {
        case types.INITIALIZE_LOADED:
			return {
				...state,
                areas: [],
                initialize_user: action.payload ? action.payload.user : null,
                gender_options: action.payload ? action.payload.gender_options : null,
                business_types: action.payload ? action.payload.business_types : null,
            };
        case types.INITIALIZE_AREAS_LOADED:
			return {
				...state,
				areas: action.payload ? action.payload.areas : null,
                areasFetching: false
			};
        case types.INITIALIZE_AREAS_UNLOADED:
            return {
				...state,
				areas: [],
                areasFetching: false
			};
        case types.USER_UPDATED:
            return {
                ...state,
                initialize_user: action.user
            };
        case types.USER_INITIALIZED:
            return {
                ...state,
                inProgress: false
            };
        case types.INITIALIZE_UNLOADED:
			return null;
        case types.FORM_VALIDATION:
			return {
				...state,				
				errors: action.errors
			};
        case types.ASYNC_START:
            if ( action.subtype === types.INITIALIZE_AREAS_LOADED ) {
                return { 
                    ...state, 
                    areasFetching: true
                };
            };
            if ( action.subtype === types.USER_INITIALIZED ) {
                return { 
                    ...state, 
                    inProgress: true
                };
            };
            return state;
		default:
			return state;
	}
}