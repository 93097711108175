import * as types from '../actions/actionTypes';

const defaultState = {
	onOrderHistories: false,
    onAddresses: false,
    onFormAddresses: false,
    onEdit: false
}

export default (state = defaultState, action) => {
	switch(action.type) {
        case types.CUSTOMERS_LOADED:
            return {
                ...state,
                customers: action.payload ? action.payload.customers : null,
                customers_count: action.error ? null : action.payload.customers_count,
            };
		
            case types.CUSTOMER_LOADED:
                return {
                    ...state,
                    customer: action.payload ? action.payload.customer : null,
                    customer_groups: action.payload ? action.payload.customer_groups : null,
                    gender_options: action.payload ? action.payload.gender_options : null,
                    onOrderHistories: false,
                    onAddresses: false,
                    onFormAddresses: false,
                    onEdit: false
                };
            case types.CUSTOMER_ORDERS_SHOWED:
                return {
                    ...state,
                    onOrderHistories: true,
                    orders: null
                };
            case types.CUSTOMER_ORDERS_HIDED:
                return {
                    ...state,
                    onOrderHistories: false,
                    orders:  null
                };
            case types.CUSTOMER_ORDERS_LOADED:
                return {
                    ...state,
                    orders: action.payload ? action.payload.orders : null,
                    orders_count: action.error ? null : action.payload.orders_count,
                    stores: action.payload ? action.payload.stores : null,
                };
            case types.CUSTOMER_ORDER_ITEMS_LOADED:
                return {
                    ...state,
                    order_items: action.payload ? action.payload.order_items : null,
                    order_items_count: action.error ? null : action.payload.order_items_count,
                };
            case types.AREAS_LOADED:
                return {
                    ...state,
                    areas: action.payload ? action.payload.areas : null,
                    areasFetching: false
                };
            case types.CUSTOMER_ADDRESSES_SHOWED:
                return {
                    ...state,
                    onAddresses: true,
                    onFormAddresses: false,
                };
            case types.CUSTOMER_ADDRESSES_HIDED:
                return {
                    ...state,
                    onAddresses: false,
                    onFormAddresses: false,
                };
            case types.CUSTOMER_ADDRESSES_LOADED:
                return {
                    ...state,
                    customer_addresses: action.payload ? action.payload.customer_addresses : null
                };
            case types.CUSTOMER_ADDRESSES_UNLOADED:
                return {
                    ...state,
                    customer_addresses: null
                };
            case types.NEW_CUSTOMER_ADDRESS_LOADED:
                return {
                    ...state,
                    onFormAddresses: true,
                    customer_address: {
                        recipient: "",
                        phone: "",
                        address: "",
                        state: "",
                        city: "",
                        district: "",
                        code_area: ""
                    },
                    areas: [],
                    errors: null
                };
            case types.EDIT_CUSTOMER_ADDRESS_LOADED:
                return {
                    ...state,
                    onFormAddresses: true,
                    customer_address: action.address,
                    areas: [],
                    errors: null
                };            
            case types.CUSTOMER_ADDRESSES_FORM_CANCELED:
                return {
                    ...state,
                    onFormAddresses: false,
                    onAddresses: true,
                    customer_address: null,
                    address_id: null
                };
            case types.CUSTOMER_ADDRESS_UPDATED:
                return {
                    ...state,
                    customer_address: action.customer_address
                };
            case types.CUSTOMER_EDITED:   
            case types.CUSTOMER_ADDRESS_CREATED:
            case types.CUSTOMER_ADDRESS_EDITED:
                return {
                    ...state,
                    onFormAddresses: action.error ? true : false,
                    onAddresses: action.error ? state.onAddresses : false,
                    errors: action.error ? action.payload.errors : null,
                    customer: action.error ? state.customer : action.payload.customer,
                    onEdit: action.error ? state.onEdit : false,
                    inProgress: false,
                };
            case types.CUSTOMER_EDIT_SHOWED:
                return {
                    ...state,
                    onEdit: true,
                    errors: null,
                    inProgress: false
                };
            case types.CUSTOMER_EDIT_HIDED:
                return {
                    ...state,
                    onEdit: false,
                    errors: null,
                    inProgress: false
                };
            case types.CUSTOMER_EDIT_LOADED: 
                return {
                    ...state,
                    editCustomer: {
                        ...state.customer,
                        date_of_birth: state.customer.date_of_birth ? state.customer.date_of_birth : new Date(),
                        gender_option_id: state.customer.gender_option_id ? state.customer.gender_option_id : "",
                    }
                };
            case types.EDIT_CUSTOMER_UPDATED: 
                return {
                    ...state,
                    editCustomer: action.customer
                };
            case types.ASYNC_START:
                if (    action.subtype === types.CUSTOMER_EDITED || 
                        action.subtype === types.CUSTOMER_ADDRESS_CREATED ||
                        action.subtype === types.CUSTOMER_ADDRESS_EDITED
                    ) {
                    return { 
                        ...state, 
                        inProgress: true };
                };
                if ( action.subtype === types.AREAS_LOADED ) {
                    return { 
                        ...state, 
                        areasFetching: true
                    };
                };
                return state;
            default:
                return state;
        }
    }