import React, { useState, useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import * as customerActions from '../../../../../../../actions/customerActions';
import { 
    Typography,
    Box,
    Button,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Grid,
    Paper,
    Divider,
    Chip
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useParams } from "react-router-dom";
import Loader from '../../../../../../Helper/Loader';

function Content({customer_addresses, onEdit}) {
    if (!customer_addresses) {
        return (
            <Box sx={{height: '500px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <Loader />
            </Box>
           
        )
    }
    return (
        <Box sx={{height: '500px', marginTop: '25px'}}>
            <Grid container direction="column" spacing={2}>
                {
                     customer_addresses.map(option => {
                        if (option.primary) {
                            return (
                                <Grid key={option._id} item onClick={onEdit.bind(this, option)}>
                                    <PrimaryPaperItem elevation={1} sx={{padding: '25px'}}>
                                        <Grid container direction="column" spacing={2}>
                                            <Grid item container>
                                                <Grid item xs container direction="column">
                                                    <Grid item>
                                                        <Typography variant="body2" color="textSecondary">
                                                            Penerima
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item sx={{display: 'flex'}}>
                                                        <Typography sx={{marginRight: '5px'}} component="div" fontWeight={700} variant="body2">
                                                            {option.recipient}
                                                        </Typography>
                                                        <Typography component="div" fontWeight={500} variant="body2">
                                                            ({option.phone})
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                                <Grid item>
                                                    <Chip label="Utama" color="primary" />
                                                </Grid>
                                            </Grid>
                                           
                                            <Grid item>
                                                <Divider />
                                            </Grid>
                                            <Grid item container direction="column">
                                                <Grid item>
                                                    <Typography variant="body2" color="textSecondary">
                                                        Alamat
                                                    </Typography>
                                                </Grid>
                                                <Grid item sx={{display: 'flex'}}>
                                                    <Typography component="div" fontWeight={500} variant="body2">
                                                        {option.address}, {option.state}, {option.city}, {option.district}, {option.code_area}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </PrimaryPaperItem>
                                </Grid>
                            )
                        }
                        return (
                            <Grid key={option._id} item onClick={onEdit.bind(this, option)}>
                                <PaperItem elevation={1} sx={{padding: '25px'}}>
                                    <Grid container direction="column" spacing={2}>
                                        <Grid item container direction="column">
                                            <Grid item>
                                                <Typography variant="body2" color="textSecondary">
                                                    Penerima
                                                </Typography>
                                            </Grid>
                                            <Grid item sx={{display: 'flex'}}>
                                                <Typography sx={{marginRight: '5px'}} component="div" fontWeight={700} variant="body2">
                                                    {option.recipient}
                                                </Typography>
                                                <Typography component="div" fontWeight={500} variant="body2">
                                                    ({option.phone})
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Divider />
                                        </Grid>
                                        <Grid item container direction="column">
                                            <Grid item>
                                                <Typography variant="body2" color="textSecondary">
                                                    Alamat
                                                </Typography>
                                            </Grid>
                                            <Grid item sx={{display: 'flex'}}>
                                                <Typography component="div" fontWeight={500} variant="body2">
                                                    {option.address}, {option.state}, {option.city}, {option.district}, {option.code_area}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </PaperItem>
                            </Grid>
                        )
                     })
                }
            </Grid>
        </Box>
    )
}

export default function List() {
    let { id } = useParams();
    const dispatch = useDispatch();
    const {customer_addresses, customer} = useSelector(state => ({
        ...state.customer
    }), shallowEqual);

    const [initialOptions, setInitialOptions] = useState([]);
    const [options, setOptions] = useState([]);

    useEffect(() => {
        setOptions(customer_addresses)
        setInitialOptions(customer_addresses)
    },[customer_addresses]);
   

    useEffect(() => {
        dispatch(customerActions.onLoadCustomerAddresses(id));
        return () => {
            dispatch(customerActions.onUnloadCustomerAddresses())
        };
    },[]);

    function onCancel() {
        dispatch(customerActions.onHideAddresses());
    }

    function onNew() {
        dispatch(customerActions.onNewAddresses());
    }

    function onEdit(address) {
        dispatch(customerActions.onEditAddresses(address));
    }


    function onSearch(event) {
        let filteredOptions = initialOptions;
        filteredOptions = filteredOptions.filter((option) => {
            let value = event.target.value.toLowerCase()
            return (
                option.recipient.toLowerCase().indexOf(value) !== -1 ||
                option.phone.toLowerCase().indexOf(value) !== -1
            )
        })
        setOptions(filteredOptions);
    }

    return (
        <React.Fragment>
             <DialogTitle textAlign="center" style={{textTransform: 'capitalize'}}>
                Alamat Pelanggan
            </DialogTitle>
            <DialogContent>
                <TextField
                    fullWidth
                    name="search"
                    placeholder={"Pencarian"}
                    variant="outlined"
                    onChange={onSearch}
                />
                    
                <Content 
                    customer={customer}
                    customer_addresses={options}
                    onEdit={onEdit} />
            </DialogContent>
            <DialogActions sx={{padding: '25px', justifyContent: 'space-between'}}>
                <BackButton  
                    size="large"
                    disableElevation
                    variant="contained"
                    onClick={onCancel} 
                    color="secondary">
                    Batal
                </BackButton>
                <Button size="large"
                    sx={{minWidth: 195}}
                    disableElevation
                    onClick={onNew}
                    variant="contained" color="primary">
                        Alamat Baru
                </Button>
            </DialogActions>
        </React.Fragment>
    )
}

const BackButton = styled(Button)(({theme}) => ({
    color: theme.palette.text.primary,
    backgroundColor: "#FFFFFF",
    '&:hover': {
    backgroundColor: "#FFFFFF",
    },
    fontWeight: 500,
    border: '1px solid #ddd',
    minWidth: 195
}));


const PaperItem = styled(Paper)(({theme}) => ({
    position: 'relative',
    '&:before': {
        width: 6,
        position: 'absolute',
        height: 42,
        top: 25,
        left: 0,
        borderTopRightRadius: 10,
        borderBottomRightRadius: 10,
        backgroundColor: `var(--G300,#4B00D1)`,
        content: '""'
    }, 
 }));

 const PrimaryPaperItem = styled(Paper)(({theme}) => ({
    position: 'relative',
    backgroundColor: 'var(--GN50,#f4edff)',
    '&:before': {
        width: 6,
        position: 'absolute',
        height: 42,
        top: 25,
        left: 0,
        borderTopRightRadius: 10,
        borderBottomRightRadius: 10,
        backgroundColor: `var(--G300,#4B00D1)`,
        content: '""'
    }, 
    '&:after': {
        borderRadius: '8px ',
        border: '1px solid var(--G500,#6200EE)',
        content: '""',
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: 0,
        top: 0
    },
 }));

