import React, { useState, useRef, useEffect } from 'react';
import * as productActions from '../../../../../../../../../../actions/productActions';
import { 
    Grid,
    Popper,
    Grow,
    Paper,
    MenuList,
    MenuItem,
    TextField,
    Button,
    IconButton,
    ClickAwayListener,
    Dialog,
    DialogContent,
    DialogActions,
    Slide,
    Box,
    LinearProgress
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { DragIndicatorRounded } from '@mui/icons-material';
import { Draggable } from "react-beautiful-dnd";
import { SketchPicker } from 'react-color';

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    
    left: 0,
    margin: 0,
    padding: 0,
    // change background colour if dragging
    border: "1px solid transparent",
  
    // styles we need to apply on draggables
    ...draggableStyle
});

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

export default function VisualSwatch({dragId, option, config, optIndex, onRemove, onOptionImagesChange, onUploadingImages, onChange, onSwatchValue, error, name}) {
    const anchorRef = useRef(null);
    const imageField = useRef();
    
    const { product_configuration_option_swatch_images } = option;

    const [open, setOpen] = useState(false);
    const [pickColor, setPickColor] = useState(false);
    const [pickedColor, setPickedColor] = useState('#FFFFFF');
    const prevOpen = useRef(open);
    
    // useEffect(() => {
    //     if ( product_configuration_option_swatch_images ) {
    //         const newImages = [...product_configuration_option_swatch_images];
    //         if (newImages) {
    //             newImages.map((image, imageIndex) => {
    //                 if (image.onRemove) {
    //                     image.onRemove = false
    //                     productActions.onRemoveSwatch(image).then(
    //                         res => {
    //                             if (res.data._id) {
    //                                 newImages.splice(imageIndex, 1, res.data)
    //                             } else {
    //                                 newImages.splice(imageIndex, 1)
    //                             }
    //                             return onOptionImagesChange(newImages)
    //                         }
    //                     );
    //                 }
    //                 return image;
                    
    //             })
    //         }
    //     }
    // }, [option])

    useEffect(() => {
        if (prevOpen.current === true && open === false) {
        anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
        return;
        }

        setOpen(false);
    };


    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
          event.preventDefault();
          setOpen(false);
        }
    }

    
    function onShowPallete(event) {
        handleClose(event)
        setPickColor(true);
    };
  
    function onHidePallete() {
        setPickColor(false);
    };

    function onSelectImage() {
        imageField.current.click()
    }

    function onChangeColor(color) {
        setPickedColor(color.hex)
    }

    function onPickColor() {    
        onSwatchValue('color', pickedColor);
        onHidePallete();
    }

    function onPickImage(e) {
        e.preventDefault();
        const files = e.target.files;
        
        let file = files[0]
        const reader = new FileReader();
        reader.onloadend = () => {
            let newImages;
            if (product_configuration_option_swatch_images) {
                newImages = [...option.product_configuration_option_swatch_images]
                if (newImages && newImages.filter(el => {
                    return !el._destroy;
                }).length !== 0) {
                    newImages.map((image, imageIndex) => {
                        if (!image._destroy) {
                            const updateImage = { ...image};
                            newImages.splice(imageIndex, 1, updateImage);
                            onRemoveImage(image, imageIndex, newImages);
                        }
                    });
                    newImages.push({image: reader.result, onUploading: true, progress: 0 })
                } else {
                    newImages.push({image: reader.result, onUploading: true, progress: 0 })
                }
            } else {
                newImages = [{image: reader.result, onUploading: true, progress: 0 }]
            }

            onOptionImagesChange(newImages)
            onUpload(newImages)
        }
        reader.readAsDataURL(file);
    }

    function onUpload(images) {
        const image = images[images.length - 1];
        productActions.onUploadSwatch(image, (event) => {
            const progress = Math.round((event.loaded * 100) / event.total);
            const updateImage = { ...image, progress}
            images.splice(images.length - 1, 1, updateImage)
            return onUploadingImages(images, optIndex);
            
        }).then(
            res => {
                images.splice(images.length - 1, 1, res.data)
                return onOptionImagesChange(images)
            }
        );
    }

    function onRemoveImage(image, imageIndex, images) {
        productActions.onRemoveSwatch(image).then(
            res => {
                if (res.data._id) {
                    images.splice(imageIndex, 1, res.data)
                } else {
                    images.splice(imageIndex, 1)
                }
                return onOptionImagesChange(images)
            }
        );
    }

    function onClear(event) {
        handleClose(event)
        onSwatchValue('', '');
    }
    
    function swatchPicked() {
        let swatchWindow = "";
        let error_class = "";
        // let swatchWindow = classes.swatchWindow;
	    // let error_class = classes.hasError;
		if (!!error(`${name}.product_configuration_option_swatch_value.value`)) {
	        swatchWindow = swatchWindow.concat(' ', error_class)
		}
        const hasImage = product_configuration_option_swatch_images && product_configuration_option_swatch_images.filter(el => {
            return !el._destroy && el.image && !el.onRemove;
        }).length !== 0;

        const hasValue = option.product_configuration_option_swatch_value && !option.product_configuration_option_swatch_value._destroy && option.product_configuration_option_swatch_value.value && !hasImage;
        if (hasValue) {
            const value = option.product_configuration_option_swatch_value.value;
            return (
                <SwatchBox>
                    <SwatchPickedBox sx={{background: value}} >
                    </SwatchPickedBox>
                </SwatchBox>
            )
        };

        if (hasImage) {
            const data = product_configuration_option_swatch_images.find(data => !data._destroy && data.image !== '' && !data.onRemove);
      
            if (data.onUploading) {
                return (
                    <SwatchBox>
                        <SwatchPickedBox
                            sx={{backgroundImage: `url(${data.image})`, backgroundRepeat: 'no-repeat', backgroundSize: 40, backgroundPosition: 'center'}} >
                        </SwatchPickedBox>
                        <Box sx={{ width: '70%', position: 'absolute', bottom: '10px' }}>
                            <LinearProgress sx={{height: '8px', borderRadius: '35px'}} variant="determinate" value={data.progress || 0} />
                        </Box>
                    </SwatchBox>
                )
            }
            return (
                <SwatchBox>
                    <SwatchPickedBox
                        sx={{backgroundImage: `url(${data.image})`, backgroundRepeat: 'no-repeat', backgroundSize: 40, backgroundPosition: 'center'}} >
                    </SwatchPickedBox>
                </SwatchBox>
            )
        }
   
       
       

        return (
            <SwatchBox  error={!!error(`${name}.product_configuration_option_swatch_value.value`)}>
                <SwatchNilBox />
            </SwatchBox>
        )
    }
    return (
        <Draggable draggableId={dragId} index={optIndex}>
            {(provided, snapshot) => 
            <Box
                sx={{marginBottom: '10px !important'}}
                {...provided.draggableProps}
                ref={provided.innerRef}
                style={getItemStyle(
                    snapshot.isDragging,
                    provided.draggableProps.style
                )}
                >
            
                    <Grid container spacing={3}>
                        <Grid item xs={1}>
                            <IconButton sx={{marginTop: '8px'}} color="primary" {...provided.dragHandleProps}>
                                <DragIndicatorRounded />
                            </IconButton>
                        </Grid>
                        <Grid item xs={1}>
                            <SwatchButton 
                                ref={anchorRef}
                                disableElevation
                                disableFocusRipple
                                disableRipple
                                aria-controls={open ? 'menu-list-grow' : undefined}
                                aria-haspopup="true"
                                onClick={handleToggle}
                                >
                                {swatchPicked()}
                            </SwatchButton>
                            <Popper open={open} anchorEl={anchorRef.current} role={undefined} 
                                sx={{zIndex: 9999}}
                                placement={'bottom-start'} transition disablePortal>
                            {({ TransitionProps, placement }) => (
                                <Grow
                                {...TransitionProps}
                                style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                                >
                                <Paper>
                                    <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                        <MenuItem onClick={onShowPallete}>
                                            Pilih Warna
                                        </MenuItem>
                                        <MenuItem onClick={onSelectImage}>Upload Gambar</MenuItem>
                                        <MenuItem onClick={onClear}>Hapus</MenuItem>
                                    </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                                </Grow>
                            )}
                            </Popper>
                        </Grid>
                        <Grid item xs={8}>
                            <TextField
                                fullWidth
                                error={!!error(`${name}.value`)}
                                helperText={error(`${name}.value`)}
                                name="value"
                                placeholder="Nama Opsi"
                                value={option.value}
                                variant="outlined"
                                onChange={onChange}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <DelButton 
                                disabled={config.product_configuration_options.length === 1}
                                fullWidth
                                variant="contained"
                                size="large"
                                disableElevation
                                onClick={onRemove}>
                                Hapus
                            </DelButton>
                        </Grid>
                        
                    </Grid>
                    <input ref={imageField} type="file" style={{display: 'none'}} accept="image/*"  onChange={onPickImage} />
                    <Dialog
                        open={pickColor}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-slide-title"
                        aria-describedby="alert-dialog-slide-description"
                    >
                        
                        <DialogContent
                            sx={{padding: 0}}
                            >
                            <SketchPicker
                                color={pickedColor}
                                onChangeComplete={onChangeColor} />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={onHidePallete} color="primary">
                                Batal
                            </Button>
                            <Button onClick={onPickColor} color="primary">
                                Pilih
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Box>
            }
        </Draggable>
    )
}

const SwatchBox = styled(Box, {
    shouldForwardProp: (prop) => prop !== "error",
  })(({ theme, error }) => ({
    border: error ? '1px solid #f44336' : '1px solid rgba(0, 0, 0, 0.23)',
    width: 54,
    height: 54,
    borderRadius: 4,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&:before': {
        position: "absolute",
        content:  '""',
        top: "1px",
        fontSize: "19px",
        color: "grey",
        left: "1px",
        width: "46px",
        height: "46px",
        background: "transparent",
        borderRadius: "4px",
        border: "4px solid #fff",
        zIndex: "6"
    },
}));

const SwatchNilBox = styled(Box)(({theme}) => ({
    border: '1px solid #ddd',
    width: 44,
    height: 44,
    position: 'relative',
    '&:after': {
        content: '""',
        position: "absolute",
        width: "67px",
        height: "2px",
        background: "red",
        transform: "rotate(-45deg)",
        zIndex: 5,
        left: "-11px",
        top: "21px"
    }
}));

const SwatchPickedBox = styled(Box)(({theme}) => ({
    border: '1px solid #ddd',
    width: 44,
    height: 44,
    position: 'relative'
}));



const DelButton = styled(Button)(({theme}) => ({
    color: theme.palette.text.primary,
    backgroundColor: "#FFFFFF",
    '&:hover': {
      backgroundColor: "#FFFFFF",
    },
    fontWeight: 500,
    border: `1px solid #ddd`,
    height: 56
}));


const SwatchButton = styled(Button)(({theme}) => ({
    minWidth: 0,
    padding: 0,
    backgroundColor: "#FFFFFF",
        '&:hover': {
        backgroundColor: "#FFFFFF",
    }
}));

