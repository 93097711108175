import * as types from './actionTypes';
import { axiosClient } from '../api';


export const onLoadEmployeeOrganizations = () => {
	const fetch = axiosClient.get(`/employee-organizations`);
	return {
		type: types.EMPLOYEE_ORGANIZATIONS_LOADED,
		payload: fetch
	}
}

export const onEmployeeOrganizationNew = (parent_id) => {
	const fetch = axiosClient.get(`/employee-organizations/${parent_id}/new`);
	return {
		type: types.NEW_EMPLOYEE_ORGANIZATION_LOADED,
		payload: fetch
	}
}

export const onUpdateField = (key, value) => {
	return {
        type: types.EMPLOYEE_ORGANIZATION_FIELD_UPDATED,
        key,
        value
	}
}

export const onEmployeeOrganizationEdit = (id) => {
	const fetch = axiosClient.get(`/employee-organizations/${id}/edit`);
	return {
		type: types.EDIT_EMPLOYEE_ORGANIZATION_LOADED,
		payload: fetch
	}
}

// export const onUpdateEmployeeOrganization = (employee_organization) => {
// 	return {
// 		type: types.EMPLOYEE_ORGANIZATION_UPDATED,
// 		employee_organization
// 	}
// }

// export const onSetParent = (parent_id) => {
// 	return {
// 		type: types.SET_EMPLOYEE_ORGANIZATION_PARENT_ID,
// 		parent_id
// 	}
// }

// export const onDragEnd = (employeeOrganizationId, employee_organization) => {
// 	const fetch = axiosClient.put(`/employee_organizations/${employeeOrganizationId}`, {employee_organization});
// 	return {
// 		type: types.EMPLOYEE_ORGANIZATIONS_SORTED,
// 		payload: fetch
// 	}
// }

export const onCreate = (employee_organization) => {
	const fetch = axiosClient.post(`/employee-organizations`, {employee_organization});
	return {
		type: types.EMPLOYEE_ORGANIZATION_CREATED, 
		payload: fetch
	}
}

export const onEdit = (employeeOrganizationId, employee_organization) => {
	const fetch = axiosClient.put(`/employee-organizations/${employeeOrganizationId}`, {employee_organization});
	return {
		type: types.EMPLOYEE_ORGANIZATION_EDITED, 
		payload: fetch
	}
}

export const onUnloadForm = () => {
	return {
		type: types.EMPLOYEE_ORGANIZATION_FORM_UNLOADED
	}
}