import React from 'react';
import { Paper,
         Grid,
         Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { AssessmentTwoTone } from '@mui/icons-material';
export default function DataEmpty() {
    return (
        <EmptyPaper elevation={0}>
            <Grid item xs={12} sm container  justify="center" alignItems="center">
                <Grid item xs container direction="column" justify="center" alignItems="center" spacing={2}>
                    <Grid item xs>
                        <AssessmentTwoTone color="primary" style={{fontSize: 120}} />
                    </Grid>
                    <Grid item>
                        <Typography fontWeight={700} variant="h6" lineheight="normal">
                            Tidak ada data.
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </EmptyPaper>
    )
}

const EmptyPaper = styled(Paper)(({theme}) => ({
    padding: 25,
    overflowY: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100vh - 265px)',
    marginBottom: 25,
}));
