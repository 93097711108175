import React, { useState, useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import * as commonActions from './actions/commonActions';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation, useNavigate, Outlet } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './store';
import {
  Box,
  Snackbar,
  Alert,
  Slide
  } from '@mui/material';
import About from './components/Frontend/About';
import Home from './components/Frontend/Home';
import Privacy from './components/Frontend/Privacy';
import ResetPassword from './components/Frontend/ResetPassword';
import SignIn from './components/Frontend/SignIn';
import SignUp from './components/Frontend/SignUp';
import Term from './components/Frontend/Term';
import NotFound from './components/NotFound';
import Backend from './components/Backend';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import '../src/assets/stylesheets/Style.css';
import Setting from './components/Backend/Accounts/Settings';
import Adjustment from './components/Backend/Stores/Inventories/Adjustment';
import AdjustmentNew from './components/Backend/Stores/Inventories/Adjustment/New';
import AdjustmentEdit from './components/Backend/Stores/Inventories/Adjustment/Edit';
import Dashboard from './components/Backend/Dashboard';
import Category from './components/Backend/Products/Inventories/Category';
import CategoryNew from './components/Backend/Products/Inventories/Category/New';
import CategoryEdit from './components/Backend/Products/Inventories/Category/Edit';
import CustomerDisplay from './components/Backend/Marketings/Contents/CustomerDisplay';
import CustomerDisplayNew from './components/Backend/Marketings/Contents/CustomerDisplay/New';
import CustomerDisplayEdit from './components/Backend/Marketings/Contents/CustomerDisplay/Edit';
import Customer from './components/Backend/Customers/Customer';
import CustomerShow from './components/Backend/Customers/Customer/Show';
import CustomerGroup from './components/Backend/Customers/CustomerGroup';
import CustomerGroupNew from './components/Backend/Customers/CustomerGroup/New';
import CustomerGroupEdit from './components/Backend/Customers/CustomerGroup/Edit';
import Inventory from './components/Backend/Stores/Inventories/Inventory';
import Employee from './components/Backend/Organizations/Employees/Employee';
import EmployeeNew from './components/Backend/Organizations/Employees/Employee/New';
import EmployeeShow from './components/Backend/Organizations/Employees/Employee/Show';
import Organization from './components/Backend/Organizations/Others/Organization';
import OrganizationEdit from './components/Backend/Organizations/Others/Organization/Edit';
import OrganizationNew from './components/Backend/Organizations/Others/Organization/New';
import JobPosition from './components/Backend/Organizations/Others/JobPosition';
import JobPositionEdit from './components/Backend/Organizations/Others/JobPosition/Edit';
import JobPositionNew from './components/Backend/Organizations/Others/JobPosition/New';
import Product from './components/Backend/Products/Inventories/Product';
import ProductNew from './components/Backend/Products/Inventories/Product/New';
import ProductEdit from './components/Backend/Products/Inventories/Product/Edit';
import Order from './components/Backend/Stores/Histories/Order';
import OrderShow from './components/Backend/Stores/Histories/Order/Show';
import OrderCanceled from './components/Backend/Stores/Histories/OrderCanceled';
import OrderCanceledShow from './components/Backend/Stores/Histories/OrderCanceled/Show';
import OrderDelivery from './components/Backend/Stores/Histories/OrderDelivery';
import OrderDeliveryShow from './components/Backend/Stores/Histories/OrderDelivery/Show';
import OrderDeliveryReturn from './components/Backend/Stores/Histories/OrderDeliveryReturn';
import OrderDeliveryReturnShow from './components/Backend/Stores/Histories/OrderDeliveryReturn/Show';
import PurchaseOrder from './components/Backend/Stores/Inventories/PurchaseOrder';
import PurchaseOrderNew from './components/Backend/Stores/Inventories/PurchaseOrder/New';
import PurchaseOrderEdit from './components/Backend/Stores/Inventories/PurchaseOrder/Edit';
import PurchaseOrderReturn from './components/Backend/Stores/Inventories/PurchaseOrderReturn';
import PurchaseOrderReturnNew from './components/Backend/Stores/Inventories/PurchaseOrderReturn/New';
import PurchaseOrderReturnEdit from './components/Backend/Stores/Inventories/PurchaseOrderReturn/Edit';
import Store from './components/Backend/Stores/Outlets/Store';
import StoreNew from './components/Backend/Stores/Outlets/Store/New';
import StoreEdit from './components/Backend/Stores/Outlets/Store/Edit';
import StoreShow from './components/Backend/Stores/Outlets/Store/Show';
import Supplier from './components/Backend/Stores/Suppliers/Supplier';
import SupplierNew from './components/Backend/Stores/Suppliers/Supplier/New';
import SupplierEdit from './components/Backend/Stores/Suppliers/Supplier/Edit';
import SupplierShow from './components/Backend/Stores/Suppliers/Supplier/Show';
import Transfer from './components/Backend/Stores/Inventories/Transfer';
import TransferNew from './components/Backend/Stores/Inventories/Transfer/New';
import TransferEdit from './components/Backend/Stores/Inventories/Transfer/Edit';
import EmployeeAccess from './components/Backend/Organizations/EmployeeAccess';
import EmployeeAccessNew from './components/Backend/Organizations/EmployeeAccess/New';
import EmployeeAccessEdit from './components/Backend/Organizations/EmployeeAccess/Edit';

function ScrollToTop() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  }, [pathname]);

  return null;
}

function Notice() {
  const dispatch = useDispatch();
  const { notice, onNotice } = useSelector(state => ({
      ...state.common
  }), shallowEqual);


  const [state, setState] = useState({
    Transition: Slide,
  });

  useEffect(() => {
    if (onNotice) {
        setState({
            Transition: SlideTransition,
        });
    }  
  },[onNotice]);

  function onClose(event, reason) {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(commonActions.onHideNotice());
  };

  function SlideTransition(props) {
    return <Slide {...props} direction="down" />;
  }
  if (!notice) {
    return null;
  }
  return (
    <Snackbar
        open={onNotice}
        anchorOrigin={{vertical: 'top', horizontal: 'center'}}
        onClose={onClose}
        autoHideDuration={3500}
        disableWindowBlurListener
        TransitionComponent={state.Transition}
        key={state.Transition.name}>
        <Alert severity={notice.type ? notice.type : "error"}>{notice.text}</Alert>
    </Snackbar>
  )
}

function HomeRoute() {
  let location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { redirectTo } = useSelector(state => ({
      ...state.common
  }), shallowEqual);

  useEffect(() => {
    if (redirectTo) {
        navigate(redirectTo);
        dispatch(commonActions.onRedirect());
    }
  },[redirectTo, navigate, dispatch]);

  if (window.localStorage.getItem('userToken')) {
    return <Navigate to="/dashboard" state={{ from: location }} replace/>;
  }

  return <Outlet />;
}

function PrivateRoute() {
  let location = useLocation();
 const navigate = useNavigate();
  const dispatch = useDispatch();
  const { redirectTo } = useSelector(state => ({
      ...state.common
  }), shallowEqual);

  useEffect(() => {
    if (redirectTo) {
        navigate(redirectTo);
        dispatch(commonActions.onRedirect());
    }
  },[redirectTo, navigate, dispatch]);

  if (!window.localStorage.getItem('userToken')) {
    return <Navigate to="/sign-in" state={{ from: location }} replace />;
  }

  return <Backend />;
}

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>  
          <Router>
            <ScrollToTop />
            <Notice />
            <Routes>
              <Route path="/" element={<HomeRoute />}>
                <Route index element={<Home />} />
                <Route path="sign-in" element={<SignIn />} />
                <Route path="sign-up" element={<SignUp />} />
                <Route path="reset-password" element={<ResetPassword />} />
                <Route path="about" element={<About />} />
                <Route path="privacy" element={<Privacy />} />
                <Route path="term" element={<Term />} />
              </Route>
              <Route path="/dashboard" element={<PrivateRoute />} >
                <Route index element={<Dashboard />} />
                <Route path="accounts/settings" element={<Setting />} />
                <Route path="store-adjustments" element={<Adjustment />} />
                <Route path="store-adjustments/new" element={<AdjustmentNew />} />
                <Route path="store-adjustments/:id/edit" element={<AdjustmentEdit />} />
                <Route path="categories" element={<Category />} />
                <Route path="categories/new" element={<CategoryNew />} />
                <Route path="categories/new/:parent_id" element={<CategoryNew />} />
                <Route path="categories/:id/edit" element={<CategoryEdit />} />
                <Route path="customer-displays" element={<CustomerDisplay />} />
                <Route path="customer-displays/new" element={<CustomerDisplayNew />} />
                <Route path="customer-displays/:id/edit" element={<CustomerDisplayEdit />} />
                <Route path="customers" element={<Customer />} />
                <Route path="customers/:id" element={<CustomerShow />} />
                <Route path="customer-groups" element={<CustomerGroup />} />
                <Route path="customer-groups/new" element={<CustomerGroupNew />} />
                <Route path="customer-groups/:id/edit" element={<CustomerGroupEdit />} />
                <Route path="inventories" element={<Inventory />} />
                <Route path="employees" element={<Employee />} />
                <Route path="employees/new" element={<EmployeeNew />} /> 
                <Route path="employees/:id" element={<EmployeeShow />} />
                <Route path="roles" element={<EmployeeAccess />} />
                <Route path="roles/new" element={<EmployeeAccessNew />} /> 
                <Route path="roles/:id/edit" element={<EmployeeAccessEdit />} />
                <Route path="organizations" element={<Organization />} />
                <Route path="organizations/new/:parent_id" element={<OrganizationNew />} />
                <Route path="organizations/:id/edit" element={<OrganizationEdit />} />
                <Route path="job-positions" element={<JobPosition />} />
                <Route path="job-positions/new/:parent_id" element={<JobPositionNew />} />
                <Route path="job-positions/:id/edit" element={<JobPositionEdit />} />
                <Route path="products" element={<Product />} />
                <Route path="products/new" element={<ProductNew />} /> 
                <Route path="products/:id/edit" element={<ProductEdit />} />   
                <Route path="orders" element={<Order />} /> 
                <Route path="orders/:id" element={<OrderShow />} />
                <Route path="order-canceleds" element={<OrderCanceled />} /> 
                <Route path="order-canceleds/:id" element={<OrderCanceledShow />} />
                <Route path="order-deliveries" element={<OrderDelivery />} /> 
                <Route path="order-deliveries/:id" element={<OrderDeliveryShow />} />
                <Route path="order-delivery-returns" element={<OrderDeliveryReturn />} /> 
                <Route path="order-delivery-returns/:id" element={<OrderDeliveryReturnShow />} />
                <Route path="purchase-orders" element={<PurchaseOrder />} />
                <Route path="purchase-orders/new" element={<PurchaseOrderNew />} />
                <Route path="purchase-orders/:id/edit" element={<PurchaseOrderEdit />} />
                <Route path="purchase-order-returns" element={<PurchaseOrderReturn />} />
                <Route path="purchase-order-returns/new" element={<PurchaseOrderReturnNew />} />
                <Route path="purchase-order-returns/:id/edit" element={<PurchaseOrderReturnEdit />} />
                <Route path="stores" element={<Store />} />
                <Route path="stores/new" element={<StoreNew />} />
                <Route path="stores/:id/edit" element={<StoreEdit />} />
                <Route path="stores/:id" element={<StoreShow />} />
                <Route path="suppliers" element={<Supplier />} />
                <Route path="suppliers/new" element={<SupplierNew />} />
                <Route path="suppliers/:id/edit" element={<SupplierEdit />} />
                <Route path="suppliers/:id" element={<SupplierShow />} />
                <Route path="store-transfers" element={<Transfer />} />
                <Route path="store-transfers/new" element={<TransferNew />} />
                <Route path="store-transfers/:id/edit" element={<TransferEdit />} />
              </Route>
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Router>
        </PersistGate>
      </Provider>
    </ThemeProvider>
  );
}

export default App;
