import React, { useState, useEffect, useRef } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import * as purchaseOrderActions from '../../../../../../../actions/purchaseOrderActions';
import { TableContainer,
         Table,
         TableHead,
         TableRow,
         TableCell,
         TableBody,
         Paper,
         Typography,
         Grid,
         Box,
         IconButton,
         AppBar,
         Toolbar,
         Divider,
         Collapse,
         Tooltip,
         Stack
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useParams } from "react-router-dom";
import { Close, KeyboardArrowDown, KeyboardArrowUp, HelpCenterTwoTone } from '@mui/icons-material';
import Loader from '../../../../../../Helper/Loader';
import NumberFormat from 'react-number-format';
import moment from 'moment';
import 'moment/locale/id' 


const headCells = [
    { id: 'name', numeric: false, sortable: true, disablePadding: false, label: 'Nama Produk' },
    { id: 'in_stock', numeric: true, sortable: true, disablePadding: false, label: 'Stok Awal' },
    { id: 'returned', numeric: true, sortable: false, disablePadding: false, label: 'Dikembalikan' },
    { id: 'issued', numeric: true, sortable: false, disablePadding: false, label: 'Disetujui' },
    { id: 'unit_price', numeric: true, sortable: false, disablePadding: false, label: 'Harga Pokok' },
    { id: 'discount', numeric: true, sortable: false, disablePadding: false, label: 'Diskon' },
    { id: 'net_price', numeric: true, sortable: false, disablePadding: false, label: 'Harga Satuan' },
    { id: 'subtotal', numeric: true, sortable: true, disablePadding: false, label: 'Subtotal' },
];
 
  
function EnhancedTableHead() {
    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => {
                    return (
                        <ItemTableCell
                        key={headCell.id}
                        sx={{fontWeight: 700}}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        >
                            {headCell.label}
                        </ItemTableCell>
                    )
                })}
            </TableRow>
        </TableHead>
    );
}

export default function Content() {
    const dispatch = useDispatch();
    let { slug } = useParams();
    const { purchase_order, returns } = useSelector(state => ({
        ...state.purchase_order
    }), shallowEqual);

    const [open, setOpen] = useState(false);

    useEffect(() => {
        dispatch(purchaseOrderActions.onLoadReturns(purchase_order._id))
    },[dispatch]);

    const handleClose = () => {
        setOpen(false);
      };
    
    const handleOpen = () => {
        setOpen(true);
    };

    function onHide() {
        dispatch(purchaseOrderActions.onHideReturns());
    }

    function onCollapsed(index, event) {
        const currentItem = returns[index];
        if (currentItem.expanded) {
            currentItem.expanded = false;
        } else {
            currentItem.expanded = true;
        }

        const newReturns = [...returns];
        newReturns[index] = currentItem;
        dispatch(purchaseOrderActions.onCollapseReturns(newReturns));
    }

    function hasOptions(product) {
        if (product.has_options) {
            return (
                <Tooltip sx={{marginLeft: '5px'}} open={open} arrow onClose={handleClose} onOpen={handleOpen} title={itemOptions(product)}>
                    <HelpCenterTwoTone color="primary" />
                </Tooltip>
            )
        }

        return null;
    }

    function itemOptions(product) { 
        return (
            <Box>
                {product.product_options.map((option, index) => {
                    return (
                        <Box key={index} sx={{display: 'flex'}}>
                            <Typography fontWeight={700}>
                                {option.name} 
                            </Typography>
                            <Typography sx={{marginLeft: '5px'}}>
                                ({option.name}) 
                            </Typography>
                        </Box>
                    )
                })}
            </Box>
        )
    }


    function Items() {
        if (!returns) {
            return (
                <Loader />
            )
        }
        
        return (
            <Stack spacing={2} sx={{padding: '25px'}}>
               {returns.map((item ,index) => {
                const { increment_id, grand_total, status, status_title, created_at, purchase_order_return_items } = item;
                    return (
                        <Item key={index}>
                             <Grid container direction="column">
                                <Grid item container spacing={6} alignItems="center">
                                    <Grid xs item>
                                        <Typography fontWeight={500} fontSize={13} variant="body1">
                                            ID Pengembalian
                                        </Typography>
                                        <Typography 
                                            sx={{cursor: "pointer"}}
                                             onClick={() => {
                                                window.open(`/dashboard/purchase-order-returns/${item._id}/edit`);
                                            }}
                                            fontWeight={700} fontSize={16} variant="h6">
                                            {increment_id}
                                        </Typography>
                                    </Grid>
                                    <Grid xs item>
                                        <Typography fontWeight={500} fontSize={13} variant="body1">
                                            Total Pengembalian
                                        </Typography>
                                        <NumberFormat
                                            value={grand_total}
                                            displayType="text"
                                            thousandSeparator={"."}
                                            decimalSeparator={","}
                                            prefix="Rp "
                                            isNumericString
                                            decimalScale={2}
                                            renderText={(value) => <Typography fontWeight={700} fontSize={16} variant="h6">
                                            {value}
                                            </Typography>}
                                        />
                                    </Grid>
                                    <Grid xs item>
                                        <Typography fontWeight={500} fontSize={13} variant="body1">
                                            Status
                                        </Typography>
                                        <Typography fontWeight={700} color={status === "approved" ? "rgb(56, 142, 60)" : "rgb(211, 47, 47)"} fontSize={16} variant="h6">
                                            {status_title}
                                        </Typography>
                                    </Grid>
                                    <Grid xs item>
                                        <Typography fontWeight={500} fontSize={13} variant="body1">
                                            Tgl. Pembuatan
                                        </Typography>
                                        <Typography fontWeight={700} fontSize={16} variant="h6">
                                            {moment(created_at).format('LL')}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <IconButton
                                            aria-label="expand row"
                                            size="small"
                                            onClick={onCollapsed.bind(this, index)}
                                        >
                                            {item.expanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                                        </IconButton>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Collapse in={item.expanded} timeout="auto" unmountOnExit>
                                        <Divider sx={{padding: '15px'}} />
                                        <TableContainer>
                                            <Table sx={{width: '120%'}}>
                                                <EnhancedTableHead />
                                                <TableBody>
                                                {
                                                   purchase_order_return_items.map((order_item, itemIndex) => {
                                                        const { name, sku, in_stock, returned_qty, approved_qty, unit_price, discount_amount, discount_percent, discount_type, net_price, row_total} = order_item;
                                                        return (
                                                            <TableRow
                                                                hover
                                                                tabIndex={-1}
                                                                key={itemIndex} >
                                                                <ItemTableCell component="th" scope="row">
                                                                    <Typography sx={{display:'flex'}} lineheight="normal" fontSize={16} fontWeight={700}>
                                                                        {name}{hasOptions(order_item)}
                                                                    </Typography>
                                                                    <Typography lineheight="normal" fontSize={11} style={{color: '#999'}}>
                                                                        SKU #{sku}
                                                                    </Typography>
                                                                </ItemTableCell>
                                                                <ItemTableCell>
                                                                    <NumberFormat
                                                                        value={in_stock}
                                                                        displayType="text"
                                                                        thousandSeparator={"."}
                                                                        decimalSeparator={","}
                                                                        isNumericString
                                                                        decimalScale={2}
                                                                        renderText={(value) => <Typography textAlign="right" fontSize={16} fontWeight={500}>
                                                                        {value}
                                                                        </Typography>}
                                                                    />
                                                                </ItemTableCell>
                                                                <ItemTableCell>
                                                                    <NumberFormat
                                                                        value={returned_qty}
                                                                        displayType="text"
                                                                        thousandSeparator={"."}
                                                                        decimalSeparator={","}
                                                                        isNumericString
                                                                        decimalScale={2}
                                                                        renderText={(value) => <Typography textAlign="right" fontSize={16} fontWeight={500}>
                                                                        {value}
                                                                        </Typography>}
                                                                    />
                                                                </ItemTableCell>
                                                                <ItemTableCell align="right">
                                                                    <NumberFormat
                                                                        value={approved_qty}
                                                                        displayType="text"
                                                                        thousandSeparator={"."}
                                                                        decimalSeparator={","}
                                                                        isNumericString
                                                                        decimalScale={2}
                                                                        renderText={(value) => <Typography textAlign="right" fontSize={16} fontWeight={500}>
                                                                        {value}
                                                                        </Typography>}
                                                                    />
                                                                </ItemTableCell>
                                                                <ItemTableCell align="right">
                                                                    <NumberFormat
                                                                        value={unit_price}
                                                                        displayType="text"
                                                                        thousandSeparator={"."}
                                                                        decimalSeparator={","}
                                                                        prefix="Rp "
                                                                        isNumericString
                                                                        decimalScale={2}
                                                                        renderText={(value) => <Typography textAlign="right" fontSize={16} fontWeight={500}>
                                                                        {value}
                                                                        </Typography>}
                                                                    />
                                                                </ItemTableCell>
                                                                <ItemTableCell align="right">
                                                                    <NumberFormat
                                                                        value={discount_type === 'percent' ? discount_percent : discount_amount}
                                                                        displayType="text"
                                                                        thousandSeparator={"."}
                                                                        decimalSeparator={","}
                                                                        prefix={discount_type === 'percent' ? "" : "Rp "}
                                                                        suffix={discount_type === 'percent' ? "%" : ""}
                                                                        isNumericString
                                                                        decimalScale={2}
                                                                        renderText={(value) => <Typography textAlign="right" fontSize={16} fontWeight={500}>
                                                                        {value}
                                                                        </Typography>}
                                                                    />
                                                                </ItemTableCell>
                                                                <ItemTableCell align="right">
                                                                    <NumberFormat
                                                                        value={net_price}
                                                                        displayType="text"
                                                                        thousandSeparator={"."}
                                                                        decimalSeparator={","}
                                                                        prefix="Rp "
                                                                        isNumericString
                                                                        decimalScale={2}
                                                                        renderText={(value) => <Typography textAlign="right" fontSize={16} fontWeight={500}>
                                                                        {value}
                                                                        </Typography>}
                                                                    />
                                                                </ItemTableCell>
                                                                <ItemTableCell align="right">
                                                                    <NumberFormat
                                                                        value={row_total}
                                                                        displayType="text"
                                                                        thousandSeparator={"."}
                                                                        decimalSeparator={","}
                                                                        prefix="Rp "
                                                                        isNumericString
                                                                        decimalScale={2}
                                                                        renderText={(value) => <Typography textAlign="right" fontSize={16} fontWeight={500}>
                                                                        {value}
                                                                        </Typography>}
                                                                    />
                                                                </ItemTableCell>
                                                            </TableRow>
                                                        );
                                                    })
                                                }
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Collapse>
                                </Grid>
                            </Grid>
                        </Item>
                    )
                })}
            </Stack>
        )
    }
    return(
        <Box sx={{height: '100%'}}>
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton
                    edge="start"
                    color="inherit"
                    onClick={onHide} 
                    aria-label="close"
                    >
                    <Close />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        Pengembalian
                    </Typography>
                </Toolbar>
            </AppBar>
            {Items()}
        </Box>
    )  
}


const ItemTableCell = styled(TableCell)(({theme}) => ({
    padding: '15px 5px'
}));


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(3),
    color: theme.palette.text.secondary,
}));