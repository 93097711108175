import React from 'react';
import { Route, Routes, Navigate, useLocation, Outlet } from 'react-router-dom';
import * as commonActions from '../../../actions/commonActions';
import { Box, Snackbar, Alert } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import NotFound from '../../../components/NotFound';
import Dashboard from '../Dashboard';
// // import CategoryEdit from '../Products/Inventories/Category/Edit';
// // import CategoryNew from '../Products/Inventories/Category/New';
// import Category from '../Products/Inventories/Category';
import Product from '../Products/Inventories/Product';
// import ProductNew from '../Products/Inventories/Product/New';
// import ProductEdit from '../Products/Inventories/Product/Edit';
// import Organization from '../Organizations/Others/Organization';
// import OrganizationEdit from '../Organizations/Others/Organization/Edit';
// import OrganizationNew from '../Organizations/Others/Organization/New';
// import JobPosition from '../Organizations/Others/JobPosition';
// import JobPositionEdit from '../Organizations/Others/JobPosition/Edit';
// import JobPositionNew from '../Organizations/Others/JobPosition/New';
// // import OrganizationEdit from '../Organizations/Others/Organization/Edit';
// // import OrganizationNew from '../Organizations/Others/Organization/New';
// // import Customer from '../Customers/Customers';
// // import CustomerNew from '../Customers/Customers/New';
// // import CustomerShow from '../Customers/Customers/Show';
// import CustomerGroup from '../Customers/CustomerGroup';
// import CustomerGroupNew from '../Customers/CustomerGroup/New';
// import CustomerGroupEdit from '../Customers/CustomerGroup/Edit';
// import Employee from '../Organizations/Employees/Employee';
// import EmployeeNew from '../Organizations/Employees/Employee/New';
// import EmployeeShow from '../Organizations/Employees/Employee/Show';
// import CustomerDisplay from '../Marketings/Contents/CustomerDisplay';
// import CustomerDisplayNew from '../Marketings/Contents/CustomerDisplay/New';
// import CustomerDisplayEdit from '../Marketings/Contents/CustomerDisplay/Edit';
// import Inventory from '../Stores/Inventories/Inventory';
// import PurchaseOrder from '../Stores/Inventories/PurchaseOrder';
// import PurchaseOrderNew from '../Stores/Inventories/PurchaseOrder/New';
// import PurchaseOrderEdit from '../Stores/Inventories/PurchaseOrder/Edit';
// import PurchaseOrderReturn from '../Stores/Inventories/PurchaseOrderReturn';
// import PurchaseOrderReturnNew from '../Stores/Inventories/PurchaseOrderReturn/New';
// import PurchaseOrderReturnEdit from '../Stores/Inventories/PurchaseOrderReturn/Edit';
// import Supplier from '../Stores/Suppliers/Supplier';
// import SupplierNew from '../Stores/Suppliers/Supplier/New';
// import SupplierEdit from '../Stores/Suppliers/Supplier/Edit';
// import SupplierShow from '../Stores/Suppliers/Supplier/Show';
// // import Order from '../Stores/Histories/Orders';
// // import OrderShow from '../Stores/Histories/Orders/Show';
// // import StoreActivity from '../Stores/Histories/StoreActivities';
// // import StorePosShift from '../Stores/Histories/StorePosShifts';
// // import StorePosShiftShow from '../Stores/Histories/StorePosShifts/Show';
// import Store from '../Stores/Outlets/Store';
// import StoreNew from '../Stores/Outlets/Store/New';
// import StoreEdit from '../Stores/Outlets/Store/Edit';
// import StoreShow from '../Stores/Outlets/Store/Show';
// import StoreUser from '../Stores/Outlets/StoreUser';
// // import StoreUserNew from '../Stores/Outlets/StoreUser/New';
// // import StoreUserEdit from '../Stores/Outlets/StoreUser/Edit';
// import Transfer from '../Stores/Inventories/Transfer';
// import TransferNew from '../Stores/Inventories/Transfer/New';
// import TransferEdit from '../Stores/Inventories/Transfer/Edit';
// import Adjustment from '../Stores/Inventories/Adjustment';
// import AdjustmentNew from '../Stores/Inventories/Adjustment/New';
// import AdjustmentEdit from '../Stores/Inventories/Adjustment/Edit';
// // import Subscribe from '../Accounts/Subscribe';
// // import Billings from '../Accounts/Billings';
// // import Settings from '../Accounts/Settings';
// import Role from '../Organizations/EmployeeAccess/Role';
// import RoleNew from '../Organizations/EmployeeAccess/Role/New';
// import RoleEdit from '../Organizations/EmployeeAccess/Role/Edit';
// import { isAuth } from './Authorization';

const current_access = {
  roles: ['can_view_catgories']
};



// function SubscribedRoute({ component: Component, ...rest }) {
//     const { subscribed } = useSelector(state => ({
//       ...state.common
//     }), shallowEqual);  

//     return (
//       <Route
//         {...rest}
//         render={props =>
//           {
//           //  if (!subscribed) {
//           //     return <Redirect
//           //     to={{
//           //       pathname: "/dashboard/accounts/subscribe",
//           //       state: { from: props.location }
//           //     }}
//           //   />
//           //   } else {
//               return <Component {...props}  />
//             // }
//           }
//         }
//       />
//     );
// }

function SubscribedRoute({children}) {
  const { subscribed } = useSelector(state => ({
    ...state.common
  }), shallowEqual);  
  let location = useLocation();

  // if (!subscribed) {
  //   return <Navigate to="/dashboard/accounts/subscribe" state={{ from: location }} replace />;
  // }

  return <Outlet />;
}

const vertical = 'top';
const horizontal = 'center';

export default function Main() {
  const dispatch = useDispatch();
  const { notice } = useSelector(state => ({
    ...state.common
  }), shallowEqual);

  const handleClose = () => {
    dispatch(commonActions.onCloseNotice());
  };

    return (
      <ContentBox>
        <Outlet />
        {/* <Snackbar 
          anchorOrigin={{vertical: 'top', horizontal: 'center'}}
          open={!!notice.text} 
          autoHideDuration={3500}
          onClose={handleClose}>
          {!!notice.text ? <Alert severity={notice.type}
          onClose={handleClose}>
              {notice.text}
          </Alert> : null}
      </Snackbar> */}
        {/* <Snackbar
            anchorOrigin={{ vertical, horizontal }}
            open={!!notice && !!notice.text} 
            autoHideDuration={3000} 
            onClose={handleClose}
            >
            {!!notice ? <Alert 
              onClose={handleClose}
              severity={notice.type}>
                {notice.text}
            </Alert> : null}
          </Snackbar> */}
      </ContentBox>
      // <Routes>
      //   <Route path="/dashboard" element={<SubscribedRoute />}>
      //     <Route element={<Dashboard />} />
      //     {/* <Route path='products/:id/edit' component={ProductEdit} />
      //     <Route path='/dashboard/products/new' component={ProductNew} />  */}
      //     <Route path='/products' component={<Product />} />
      //     {/* <Route path="sign-in" element={<SignIn />} /> */}
      //   </Route>
      // </Routes>
      // // <ContentBox>
      //     <Route
      //       index
      //       path="dashboard"
      //       element={
      //           <Dashboard />
      //       }
      //     />

      //       <SubscribedRoute path='/dashboard' exact component={Dashboard} />
      //       <SubscribedRoute path='/dashboard/categories/new/:parent_id' component={CategoryNew} />
      //       <SubscribedRoute path='/dashboard/categories/:slug/edit' component={CategoryEdit} />
      //       <SubscribedRoute path='/dashboard/categories/new' component={CategoryNew} />
      //       {isAuth(current_access, ['can_view_categories']) && <SubscribedRoute path='/dashboard/categories' component={Category} /> }
      //         <SubscribedRoute path='/dashboard/customers/new' component={CustomerNew} />
      //       <SubscribedRoute path='/dashboard/customers/:slug' component={CustomerShow} />
      //       <SubscribedRoute path='/dashboard/customers' component={Customer} />
      //       <SubscribedRoute path='/dashboard/employees/new' component={EmployeeNew} />
      //       <SubscribedRoute path='/dashboard/employees/:id' component={EmployeeShow} />
      //       <SubscribedRoute path='/dashboard/employees' component={Employee} />
      //       <SubscribedRoute path='/dashboard/customer-groups/:id/edit' component={CustomerGroupEdit} />
      //       <SubscribedRoute path='/dashboard/customer-groups/new' component={CustomerGroupNew} />
      //       <SubscribedRoute path='/dashboard/customer-groups' component={CustomerGroup} />
            // <SubscribedRoute path='/dashboard/products/:id/edit' component={ProductEdit} />
            // <SubscribedRoute path='/dashboard/products/new' component={ProductNew} /> 
            // <SubscribedRoute path='/dashboard/products' component={Product} />
      //       <SubscribedRoute path='/dashboard/organizations/new' component={OrganizationNew} />
      //       <SubscribedRoute path='/dashboard/organizations/:id/edit' component={OrganizationEdit} />
      //       <SubscribedRoute path='/dashboard/organizations' component={Organization} />
      //       <SubscribedRoute path='/dashboard/job-positions/new' component={JobPositionNew} />
      //       <SubscribedRoute path='/dashboard/job-positions/:id/edit' component={JobPositionEdit} />
      //       <SubscribedRoute path='/dashboard/job-positions' component={JobPosition} />
      //       <SubscribedRoute path='/dashboard/customer-displays/:id/edit' component={CustomerDisplayEdit} />
      //       <SubscribedRoute path='/dashboard/customer-displays/new' component={CustomerDisplayNew} />
      //       <SubscribedRoute path='/dashboard/customer-displays' component={CustomerDisplay} />
      //       <SubscribedRoute path='/dashboard/inventories' component={Inventory} />
      //       <SubscribedRoute path='/dashboard/purchase-orders/:id/edit' component={PurchaseOrderEdit} />
      //       <SubscribedRoute path='/dashboard/purchase-orders/new' component={PurchaseOrderNew} />
      //       <SubscribedRoute path='/dashboard/purchase-orders' component={PurchaseOrder} />
      //       <SubscribedRoute path='/dashboard/purchase-order-returns/:id/edit' component={PurchaseOrderReturnEdit} />
      //       <SubscribedRoute path='/dashboard/purchase-order-returns/new' component={PurchaseOrderReturnNew} />
      //       <SubscribedRoute path='/dashboard/purchase-order-returns' component={PurchaseOrderReturn} />
      //       <SubscribedRoute path='/dashboard/store-transfers/:id/edit' component={TransferEdit} />
      //       <SubscribedRoute path='/dashboard/store-transfers/new' component={TransferNew} />
      //       <SubscribedRoute path='/dashboard/store-transfers' component={Transfer} />
      //       <SubscribedRoute path='/dashboard/store-adjustments/:id/edit' component={AdjustmentEdit} />
      //       <SubscribedRoute path='/dashboard/store-adjustments/new' component={AdjustmentNew} />
      //       <SubscribedRoute path='/dashboard/store-adjustments' component={Adjustment} />
      //       <SubscribedRoute path='/dashboard/suppliers/:id/edit' component={SupplierEdit} />
      //       <SubscribedRoute path='/dashboard/suppliers/new' component={SupplierNew} />
      //       <SubscribedRoute path='/dashboard/suppliers/:id' component={SupplierShow} />
      //       <SubscribedRoute path='/dashboard/suppliers' component={Supplier} />
      //       <SubscribedRoute path='/dashboard/orders/:slug' component={OrderShow} />
      //       <SubscribedRoute path='/dashboard/orders' component={Order} />
      //       <SubscribedRoute path='/dashboard/roles/:id/edit' component={RoleEdit} />
      //       <SubscribedRoute path='/dashboard/roles/new' component={RoleNew} />
      //       <SubscribedRoute path='/dashboard/roles' component={Role} />
      //       <SubscribedRoute path='/dashboard/store-activities' component={StoreActivity} />
      //       <SubscribedRoute path='/dashboard/store-pos-shifts/:slug' component={StorePosShiftShow} />
      //       <SubscribedRoute path='/dashboard/store-pos-shifts' component={StorePosShift} />
      //       <SubscribedRoute path='/dashboard/stores/:id/edit' component={StoreEdit} />
      //       <SubscribedRoute path='/dashboard/stores/new' component={StoreNew} />
      //       <SubscribedRoute path='/dashboard/stores/:id' component={StoreShow} />
      //       <SubscribedRoute path='/dashboard/stores' component={Store} /> 
      //       <SubscribedRoute path='/dashboard/store-users/:slug/edit' component={StoreUserEdit} />
      //       <SubscribedRoute path='/dashboard/store-users/new' component={StoreUserNew} />
      //       <SubscribedRoute path='/dashboard/store-users' component={StoreUser} />
      //       <SubscribedRoute path='/dashboard/accounts/settings' component={Settings} />
      //       <Route path='/dashboard/accounts/subscribe' component={Subscribe} />
      //       <Route path='/dashboard/accounts/billings' component={Billings} /> */}
  
        
    )
}


const ContentBox = styled(Box)(({theme}) => ({
  paddingLeft: 110,
  paddingRight: 25,
  height: '100%'
}));