import * as types from '../actions/actionTypes';

const defaultState = {
}

export default function employee_access (state = defaultState, action) {
	switch(action.type) {
        case types.EMPLOYEE_ACCESSES_LOADED:
			return {
				...state,
				employee_accesses: action.error ? null : action.payload.employee_accesses,
				employee_accesses_count: action.error ? null : action.payload.employee_accesses_count,
            };
        case types.NEW_EMPLOYEE_ACCESS_LOADED:
			return {
				...state,
                employee_access: {
                    name: '',
                    accesses: [],
                    permissions: []  
                }
			};
        case types.EDIT_EMPLOYEE_ACCESS_LOADED:
			return {
				...state,
				employee_access: action.error ? null : action.payload.employee_access,
				errors: []
			};
        case types.EMPLOYEE_ACCESS_FIELD_UPDATED:
            return {
                ...state,
                employee_access: {...state.employee_access, [action.key]: action.value}
            };
        case types.EMPLOYEE_ACCESS_UPDATED:
            return {
                ...state,
                employee_access: action.employee_access
            };
        case types.EMPLOYEE_ACCESS_CREATED:
        case types.EMPLOYEE_ACCESS_EDITED:
            return { 
                ...state,
                inProgress: false,
                errors: action.error ? action.payload.errors : null
            };
        case types.EMPLOYEE_ACCESS_FORM_UNLOADED:
			return {
				...state,
			};
		case types.ASYNC_START:
            if ( action.subtype === types.EMPLOYEE_ACCESS_CREATED ||
                action.subtype === types.EMPLOYEE_ACCESS_EDITED
               ) {
               return { 
                   ...state, 
                   inProgress: true };
           };
			return state;
		default:
			return state;
	}
}