// import { inventory } from './inventory';
import { employee_access } from './employee_access';
// import { product } from './product';

import { product_price } from './product_price'
// import { customer_display } from './customer_display'
// import { store_user } from './store_user'
import { product_configuration } from './product_configuration'


export const validate = (data, model) => {
	let errors = {}
	let error_notice = {}
	// if (model === 'inventory') {
	// 	inventory(data, errors)
	// }
	// if (model === 'product') {
	// 	product(data, errors)
	// }

	if (model === 'employee_access') {
		employee_access(data, errors, error_notice)
	}
	if (model === 'product_price') {
		product_price(data, errors)
	}
	// if (model === 'customer_display') {
	// 	customer_display(data, errors)
	// }

	// if (model === 'store_user') {
	// 	store_user(data, errors)
	// }

	if (model === 'product_configuration') {
		product_configuration(data, errors)
	}
	
	return {
		errors, 
		error_notice,
		isValid: Object.keys(errors).length === 0  && Object.keys(error_notice).length === 0
	}
}