import React, {useState} from "react";
import {
    InputBase,
    Paper,
    Typography,
    Grid,
    Box,
    IconButton,
    FormControl, 
    OutlinedInput,
    Button,
} from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import { AddBoxTwoTone, Search, AddRounded } from '@mui/icons-material';
import { useHistory } from "react-router-dom";

export default function Header() {
    return (
        <Box>
            <MobileBox>
                <HeaderBox>
                    <Grid container alignItems="center" justifyContent="space-between">
                        <Grid item>
                            <Box>
                                <Typography fontWeight={500} variant="h6">
                                    Organisasi
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item>
                            <IconButton color="primary">
                                <AddBoxTwoTone />
                            </IconButton>
                        </Grid>
                    </Grid>
                </HeaderBox>
                <Box>
                    <FormControl fullWidth variant="outlined">
                        <OutlinedInput
                            placeholder="Search"
                        />
                    </FormControl>
                </Box>
            </MobileBox>
            <DesktopBox>
                <HeaderBox>
                    <Grid container alignItems="center" justifyContent="space-between">
                        <Grid item>
                            <Box>
                                <Typography fontWeight={500} variant="h6">
                                    Organisasi
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item>
                        </Grid>
                    </Grid>
                </HeaderBox>
            </DesktopBox>
        </Box>
    )
}

const HeaderBox = styled(Box)(({theme}) => ({
    paddingLeft: 0,
    marginBottom: 25,
    [theme.breakpoints.down('sm')]: {
        paddingLeft: 55,
    },
}));

const MobileBox = styled(Box)(({theme}) => ({
    display: 'none',
    [theme.breakpoints.down('sm')]: {
        display: 'block'
    },
}));

const DesktopBox = styled(Box)(({theme}) => ({
    display: 'block',
    [theme.breakpoints.down('sm')]: {
        display: 'none'
    },
}));
