import * as types from '../actions/actionTypes';

const defaultState = {
	currentMenu: {
		navName: '',
		navItems: [],
		navIcon: '',
		navType: ''
	},
	notice: {
        text: '',
        type: ''
	},
	onNotice: null,
	errors: [],
	userVerification: false,
}

export default function (state = defaultState, action) {
	switch(action.type) {
		case types.NAV_OPENED:
			return {
				...state,
				currentMenu: action.currentMenu,
				openMenu: true
			};
		case types.NAV_CLOSED:
			return {
				...state,
				currentMenu: {
					navName: '',
					navItems: [],
					navIcon: '',
					navType: ''
				},
				openMenu: false
			};
		case types.MOBILE_NAV_OPENED:
			return {
				...state,
				openMobileMenu: true
			};
		case types.MOBILE_NAV_CLOSED:
			return {
				...state,
				openMobileMenu: false
			};
		case types.REDIRECT:
			return {
				...state,
				redirectTo: null
			};
		case types.USER_RESET_EDITED:
			return {
				...state,
				notice: action.payload ? action.payload.notice : state.notice,
				onNotice: action.payload ? !!action.payload.notice : null,
				redirectTo: action.error ? null : '/dashboard',
			};
		case types.USER_LOGGED_IN:
		case types.USER_REGISTERED:
			return {
				...state,
				subscribed: action.error ? null : action.payload.subscribed,
				current_user: action.error ? null : action.payload.user,
				redirectTo: action.error ? null : '/dashboard',
				notice: action.payload ? action.payload.notice : state.notice,
				onNotice: action.payload ? !!action.payload.notice : null,
			};
		case types.USER_LOGGED_OUT:
			return { 
				...state, 
				redirectTo: '/',
				current_user: {
					first_name: '',
					last_name: '',
					email: ''
				}
			};
		// case types.USER_CREATED:
		// case types.USER_RESET_PASSWORD_CREATED:
		// case types.USER_RESET_PASSWORD_EDITED:
        //     return {
        //         ...state,
        //         notice: action.error ? state.notice : action.payload.notice
		// 	};
		// case types.USER_LOGIN:
		// 	return {
		// 		...state,
		// 		subscribed: action.error ? null : action.payload.subscribed,
		// 		current_user: action.error ? null : action.payload.user
		// 	};
		
		// case types.USER_ACTIVATED:
        //     return {
        //         ...state,
        //         notice: action.error ? state.notice : action.payload.notice
		// 	};
		// case types.NAV_OPENED:
		// 	return {
		// 		...state,
		// 		currentMenu: action.currentMenu,
		// 		openMenu: true
		// 	};
		// case types.NAV_CLOSED:
		// 	return {
		// 		...state,
		// 		currentMenu: {
		// 			navName: '',
		// 			navItems: [],
		// 			navIcon: '',
		// 			navType: ''
		// 		},
		// 		openMenu: false
		// 	};
		case types.USER_INITIALIZED:
            return {
				...state,
				// subscribed: action.error ? state.subscribed : action.payload.subscribed,
				current_user: action.error ? state.current_user : {...state.current_user, initialized: action.payload.initialized},
				redirectTo: action.error ? null : '/dashboard'
			};
		// case types.USER_LOADED:
        //     return {
		// 		...state,
		// 		subscribed: action.payload ? action.payload.subscribed : null,
        //     };
		case types.PRODUCT_CREATED:
		case types.PRODUCT_EDITED:
			return {
				...state,
				redirectTo: action.error ? null : '/dashboard/products'
			};
		case types.EMPLOYEE_ORGANIZATION_CREATED:
		case types.EMPLOYEE_ORGANIZATION_EDITED:
			return {
				...state,
				redirectTo: action.error ? null : '/dashboard/organizations'
			};
		case types.EMPLOYEE_JOB_POSITION_CREATED:
		case types.EMPLOYEE_JOB_POSITION_EDITED:
			return {
				...state,
				redirectTo: action.error ? null : '/dashboard/job-positions'
			};
		case types.EMPLOYEE_CREATED:
			return {
				...state,
				redirectTo: action.error ? null : '/dashboard/employees'
			};
		case types.EMPLOYEE_ACCESS_CREATED:
		case types.EMPLOYEE_ACCESS_EDITED:
			return {
				...state,
				redirectTo: action.error ? null : '/dashboard/roles'
			};
		case types.CATEGORY_CREATED:
		case types.CATEGORY_EDITED:
			return {
				...state,
				redirectTo: action.error ? null : '/dashboard/categories'
			};
		case types.CUSTOMER_DISPLAY_CREATED:
		case types.CUSTOMER_DISPLAY_EDITED:
			return {
				...state,
				redirectTo: action.error ? null : '/dashboard/customer-displays'
			};
		case types.PURCHASE_ORDER_CREATED:
        case types.PURCHASE_ORDER_EDITED:
			return {
				...state,
				redirectTo: action.error ? null : '/dashboard/purchase-orders'
			};
		case types.PURCHASE_ORDER_RETURN_CREATED:
        case types.PURCHASE_ORDER_RETURN_EDITED:
			return {
				...state,
				redirectTo: action.error ? null : '/dashboard/purchase-order-returns'
			};
		case types.SUPPLIER_CREATED:
        case types.SUPPLIER_EDITED:
		case types.SUPPLIER_PAYMENT_CREATED:
			return {
				...state,
				redirectTo: action.error ? null : '/dashboard/suppliers'
			};
		case types.STORE_CREATED:
        case types.STORE_EDITED:
			return {
				...state,
				redirectTo: action.error ? null : '/dashboard/stores'
			};
		case types.ORDER_CANCELED_EDITED:
			return {
				...state,
				redirectTo: action.error ? null : '/dashboard/order-canceleds'
			};
		case types.ORDER_DELIVERY_EDITED:
			return {
				...state,
				redirectTo: action.error ? null : '/dashboard/order-deliveries'
			};
		case types.ORDER_DELIVERY_RETURN_EDITED:
			return {
				...state,
				redirectTo: action.error ? null : '/dashboard/order-delivery-returns'
			};
		case types.EMPLOYEE_PIN_SENDED:
			return {
				...state,
				notice: action.error ? state.notice : action.payload.notice,
				onNotice:  action.payload ? !!action.payload.notice : null,
			};
		// case types.FORM_VALIDATION:
		// 	return {
		// 		...state,				
		// 		errors: {...state.errors, ...action.errors},
		// 		notice: {...state.error_notice, ...action.error_notice}
		// 	};
		case types.USER_CONFIGURATION_EDITED:
			return {
				...state,
                notice: action.error ? state.notice : action.payload.notice,
				onNotice: action.payload ? !!action.payload.notice : null,
            };
		case types.NOTICE_HIDED:
			return {
				...state,
				onNotice: false
			};
		case types.STORE_TRANSFER_CREATED:
        case types.STORE_TRANSFER_EDITED:
			return {
				...state,
				redirectTo: action.error ? null : '/dashboard/store-transfers'
			};
		case types.STORE_ADJUSTMENT_CREATED:
        case types.STORE_ADJUSTMENT_EDITED:
			return {
				...state,
				redirectTo: action.error ? null : '/dashboard/store-adjustments'
			};
		// case types.STORE_USER_CREATED:
        // case types.STORE_USER_EDITED:
		// 	return {
		// 		...state,
		// 		redirectTo: action.error ? null : '/dashboard/store-users'
		// 	};
		case types.CUSTOMER_GROUP_CREATED:
        case types.CUSTOMER_GROUP_EDITED:
            return {
                ...state,
                redirectTo: action.error ? null : '/dashboard/customer-groups/'
			};
		case types.USER_CONFIGURATION_EDITED:
			return {
				...state,
				notice: action.error ? state.notice : action.payload.notice,
			};
		// case types.CUSTOMER_CREATED:
        //     return {
        //         ...state,
        //         redirectTo: action.error ? null : '/dashboard/customers/'
		// 	};
		// case types.BILLING_CREATED:
        //     return {
		// 		...state,
		// 		subscribed: action.error ? null : action.payload.subscribed,
		// 		redirectTo: action.error ? null : '/dashboard',
        //     };
		// case types.REDIRECT:
		// 	return {
		// 		...state,
		// 		redirectTo: null
		// 	};
		case types.USER_REGISTER_LOADED:
		case types.USER_RESET_LOADED:
            return {
                ...state,
				user: {
                    email_phone: "",
                },
				userVerification: false,
			};
        case types.USER_REGISTER_UNLOADED:
		case types.USER_RESET_UNLOADED:
            return {
                ...state,
				user: {
                    email_phone: "",
                },
                userVerified: false,
                userVerification: false
			};
		case types.NEW_USER_REGISTER_LOADED:
			return {
				...state,
				user: action.user,
			};
		case types.USER_REGISTER_VALIDATION_FIELD_UPDATED:
		case types.USER_RESET_VALIDATION_FIELD_UPDATED:
			return {
				...state,
				user: { ...state.user, [action.key]: action.value }
			};
		case types.USER_LOGIN_VALIDATION:
            return {
                ...state,
				notice: action.error ? action.payload.notice : state.notice,
				onNotice: action.error ? !!action.payload.notice : null,
			};
		case types.USER_REGISTER_VALIDATION:
		case types.USER_RESET_VALIDATION:
            return {
                ...state,
				user: action.error ? state.user : action.payload.user,
                userVerification: action.error ? false : true,
				onVerification: false,
				notice: action.error ? action.payload.notice : state.notice,
				onNotice: action.error ? !!action.payload.notice : null,
			};
		case types.USER_VERIFICATION_CANCELED:
			return {
				...state,
				userVerification: false,
				// userExist: false
			};
		case types.USER_EMAIL_VERIFICATION:
        case types.USER_MESSAGE_VERIFICATION:
            return {
                ...state,
                onVerification: true,
                dialogInProgress: false,
                unVerified: null
            };
        case types.USER_VERIFICATION:
            return {
                ...state,
                userVerified: action.payload.verified,
                unVerified: action.error,
                dialogInProgress: false,
				notice: action.payload ? action.payload.notice : state.notice,
				onNotice: action.payload ? !!action.payload.notice : null,
            };
        
        case types.ASYNC_START:
            if ( action.subtype === types.USER_VERIFICATION || action.subtype === types.USER_EMAIL_VERIFICATION || action.subtype === types.USER_MESSAGE_VERIFICATION
                ) {
                return { 
                    ...state, 
                    dialogInProgress: true };
            };
            return state;
		default:
			return state;
	}
}