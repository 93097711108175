import React, { useEffect} from 'react';
import { 
    Typography,
    Box,
    Grid,
    Paper,
    Divider,
} from '@mui/material';
import { useSelector, shallowEqual } from 'react-redux';
import Chart from 'chart.js/auto';

export default function Payment() {
    const { customer } = useSelector(state => ({
        ...state.customer
    }), shallowEqual);
    const { payments } = customer;
    useEffect(() => {
        new Chart(document.getElementById("pie-chart"), {
            type: 'pie',
            data: {     
                labels: payments.map(d => d.label),               
                datasets: [{
                    backgroundColor: payments.map(d => d.color),
                    data: payments.map(d => d.amount),
                    borderWidth: 1
                }]
            },
            options: {
                interaction: {
                    mode: 'index'
                },
                plugins: {
                    legend: {
                        display: false,
                        labels: {
                            color: 'rgb(255, 99, 132)'
                        }
                    },
                    tooltip: {
                        callbacks: {
                            title: function(context) {
                                return null
                            },
                            label: function(context) {
                                let label = context.label || '';
                                if (label) {
                                    label += ': ';
                                }
                                if (context.parsed !== null) {
                                    label += new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(context.parsed);
                                }
                                return label;
                            }
                        }
                    }
                }
            }
        });
    },[]);

    return (
        <Paper elevation={3} style={{width: '100%'}}>
            <Grid container direction="column" spacing={3}>
                <Grid item xs container direction="column" spacing={3}>
                    <Grid item xs>
                        <Typography style={{padding: 25}} variant="h6" fontSize={16} fontWeight={700}>
                            Pembayaran
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Paper elevation={0} style={{width: '100%', justifyContent: 'center', display: 'flex'}}>
                            <Box style={{width: 200}}>
                                <canvas id="pie-chart"></canvas>
                            </Box>
                        </Paper>
                    </Grid>
                </Grid>
                <Grid item xs>
                    <Divider />
                    <Paper style={{padding: "25px", borderRadius: 4}} elevation={0}>
                        <Grid container spacing={2}>
                            {
                                payments.map((d, i)=> {
                                    return (
                                        <Grid key={i} item xs={6} container direction="column">
                                            <Grid item xs>
                                                <Typography component="div" variant="body2" color="textPrimary">
                                                    <Box fontSize={14} fontWeight={700} display="flex" alignItems="center">
                                                        Rp {Number(d.amount).toFixed().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.")}
                                                    </Box>
                                                </Typography>
                                            </Grid>
                                            <Grid item xs>
                                                <Typography component="div" variant="body2" color="textSecondary">
                                                    <Box fontSize={14} fontWeight={500} display="flex" alignItems="center">
                                                        {d.label} <span style={{display: "block", borderRadius: 15, width: 15, height: 5, marginLeft: 10, backgroundColor: d.color}} /> 
                                                    </Box>
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    )
                                })
                            }
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </Paper>
    )
}