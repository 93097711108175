import React, {useState} from "react";
import {
    InputBase,
    Paper,
    Typography,
    Grid,
    Box,
    IconButton,
    FormControl, 
    OutlinedInput,
    Button,
} from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import { AddBoxTwoTone, Search, AddRounded } from '@mui/icons-material';
import { useNavigate } from "react-router-dom";

export default function Header({query, setQuery}) {
    let navigate = useNavigate();
    const [search, setSearch] = useState(query.search);

    function onSearch(e) {
        var enterKey = 13;
        if (e.which === enterKey) {
            e.target.blur(); 
            const newQuery = {...query};
            newQuery.page = 0;
            newQuery.search = search;
            setQuery(newQuery)
        }
    }
    return (
        <Box>
            <MobileBox>
                <HeaderBox>
                    <Grid container alignItems="center" justifyContent="space-between">
                        <Grid item>
                            <Box>
                                <Typography fontWeight={500} variant="h6">
                                    Karyawan
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item>
                            <IconButton color="primary">
                                <AddBoxTwoTone />
                            </IconButton>
                        </Grid>
                    </Grid>
                </HeaderBox>
                <Box>
                    <FormControl fullWidth variant="outlined">
                        <OutlinedInput
                            placeholder="Search"
                        />
                    </FormControl>
                </Box>
            </MobileBox>
            <DesktopBox>
                <HeaderBox>
                    <Grid container alignItems="center" justifyContent="space-between">
                        <Grid item>
                            <Box>
                                <Typography fontWeight={500} variant="h6">
                                    Karyawan
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item>
                        </Grid>
                    </Grid>
                </HeaderBox>
                <HeaderPaper elevation={3}>
                    <Grid container alignItems="center" justifyContent="space-between">
                        <Grid item>
                            <SearchField
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                                onKeyDown={onSearch}>
                                <SearchIconWrapper>
                                    <Search />
                                </SearchIconWrapper>
                                <StyledInputBase
                                placeholder="Search…"/>
                            </SearchField>
                        </Grid>
                        <Grid item>
                            <Button 
                                variant="contained" 
                                color="primary"
                                disableElevation
                                startIcon={<AddRounded />}
                                onClick={(e) => navigate('/dashboard/employees/new')} >
                                Karyawan Baru
                            </Button> 
                        </Grid>
                    </Grid>
                </HeaderPaper>
            </DesktopBox>
        </Box>
    )
}

const HeaderBox = styled(Box)(({theme}) => ({
    paddingLeft: 0,
    marginBottom: 25,
    [theme.breakpoints.down('sm')]: {
        paddingLeft: 55,
    },
}));

const MobileBox = styled(Box)(({theme}) => ({
    display: 'none',
    [theme.breakpoints.down('sm')]: {
        display: 'block'
    },
}));

const DesktopBox = styled(Box)(({theme}) => ({
    display: 'block',
    [theme.breakpoints.down('sm')]: {
        display: 'none'
    },
}));

const HeaderPaper = styled(Paper)(({theme}) => ({
    padding: 15
}));

const SearchField = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.black, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.black, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    // [theme.breakpoints.up('sm')]: {
    //   marginLeft: theme.spacing(1),
    //   width: 'auto',
    // },
}));
  
const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));
  
const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: '12ch',
        '&:focus': {
          width: '20ch',
        },
      },
    },
  }));