import React from 'react';
import { useDispatch } from 'react-redux';
import * as productActions from '../../../../../../../actions/productActions';
import { 
        Typography,
        Box,
        Grid,
        Button,
        TextField,
        IconButton,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { AddRounded, DragIndicatorRounded } from '@mui/icons-material';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    
    left: 0,
    margin: 0,
    padding: 0,
    // change background colour if dragging
    border: "1px solid transparent",
  
    // styles we need to apply on draggables
    ...draggableStyle
});

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);
  
    destClone.splice(droppableDestination.index, 0, removed);
  
    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;
  
    return result;
  };


export default function Specification({product_specifications, error}) {
    const dispatch = useDispatch();
    
    function onAddSpecification() {
        const addSpecifications = [...product_specifications, {name: '', sort_order: '', product_specification_tables: [{ label: '', content: '', sort_order: '' }] }]
        const newSpecifications = addSpecifications.map((specification, index)=> {
            return {...specification, sort_order: index}
        })
        dispatch(productActions.onUpdateSpecifications(newSpecifications));
        
    }

       
    function onSpecificationTitleChange(index, event) {
        const key = event.target.name;
        const value = event.target.value;
	    const newSpecifications = { ...product_specifications[index], [key]: value}
	    product_specifications.splice(index, 1, newSpecifications)
        dispatch(productActions.onUpdateSpecifications(product_specifications));
  	}

    function onRemoveSpecification(index) {
		if (product_specifications[index]._id) {
			product_specifications[index]._destroy = true
		} else {
			product_specifications.splice(index, 1)
        }

        const newSpecifications = product_specifications.map((specification, index)=> {
            return {...specification, sort_order: index}
        })
        
        dispatch(productActions.onUpdateSpecifications(newSpecifications));
    }
   
    
    function onAddAttribute(index) {
        const addAttributes = [...product_specifications[index].product_specification_tables, { label: '', content: '', sort_order: '' }]
        const newAttributes = addAttributes.map((attribute, index)=> {
            return {...attribute, sort_order: index}
        })
        
        const newSpecifications = [...product_specifications];
        newSpecifications[index].product_specification_tables = newAttributes;
        
        dispatch(productActions.onUpdateSpecifications(newSpecifications));
    }



    function onAttributeChange(attrIndex, index, event) {
        const key = event.target.name;
        const value = event.target.value;
        const newAttributes = { ...product_specifications[index].product_specification_tables[attrIndex], [key]: value }
        product_specifications[index].product_specification_tables.splice(attrIndex, 1, newAttributes)
        dispatch(productActions.onUpdateSpecifications(product_specifications));
    }

    function onRemoveAttribute(attrIndex, index) {
        const attribute = product_specifications[index].product_specification_tables[attrIndex];
		if (attribute._id) {
			attribute._destroy = true
		} else {
			product_specifications[index].product_specification_tables.splice(attrIndex, 1)
        }
        
        const newSpecifications = [...product_specifications];
        newSpecifications[index].product_specification_tables = product_specifications[index].product_specification_tables.map((attribute, index)=> {
            return {...attribute, sort_order: index}
        })
        
        dispatch(productActions.onUpdateSpecifications(newSpecifications));
    }
      


    function onDragEnd(result) {
        const { source, destination, type } = result;

        // dropped outside the list
        if (!destination) {
        return;
        }

        if (type === 'specification') {
            const specificationsReorder = reorder(
            product_specifications,
                result.source.index,
                result.destination.index
            );
            
            const newSpecifications = specificationsReorder.map((specification, index)=> {
                return {...specification, sort_order: index}
            })
            dispatch(productActions.onUpdateSpecifications(newSpecifications));
        }

        if (type === "attribute") {
            const sInd = +source.droppableId;
            const dInd = +destination.droppableId;
    
            if (sInd === dInd) {
                
                const attributesReorder = reorder(
                product_specifications[sInd].product_specification_tables,
                    result.source.index,
                    result.destination.index
                );

                const newSpecifications = [...product_specifications]
                newSpecifications[sInd].product_specification_tables = attributesReorder.map((attribute, index)=> {
                    return {...attribute, sort_order: index}
                })
            
                dispatch(productActions.onUpdateSpecifications(newSpecifications));
            } else {
                const result = move(product_specifications[sInd].product_specification_tables, product_specifications[dInd].product_specification_tables, source, destination);
                const newSpecifications = [...product_specifications]
                newSpecifications[sInd].product_specification_tables = result[sInd].map((attribute, index)=> {
                    return {...attribute, sort_order: index}
                })
                newSpecifications[dInd].product_specification_tables = result[dInd].map((attribute, index)=> {
                    return {...attribute, sort_order: index}
                })
                if (newSpecifications[sInd].product_specification_tables.length === 0) {
                    return onRemoveSpecification(sInd)
                }
                dispatch(productActions.onUpdateSpecifications(newSpecifications));
            }
        }
        
    }
    

   
    
    return (
        
        <Grid item container spacing={8} >
            <Grid item xs={4}>
                <Typography fontSize={16} fontWeight={700} component="div">
                    Spesifikasi Produk
                </Typography>
                <Typography variant="body2" gutterBottom color="textSecondary">
                    Tambahkan spesifikasi produk, seperti warna, ukuran, satuan, kelengkapan dan detail produk lainnya.
                </Typography>
            </Grid>
            <Grid item xs={8} container direction="column">
                <Grid item>
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="specifications" type="specification">
                            {(provided, snapshot) => (
                                <Box
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}>
                                    {
                                        product_specifications.sort((a, b) => a.sort_order - b.sort_order).map((specification, index) => {
                                            if (specification._destroy) {
                                                return null;
                                            }
                                            return (
                                                <Draggable key={index} draggableId={index.toString()} index={index}>
                                                    {(provided, snapshot) => (
                                                        <Box
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps} 
                                                            style={getItemStyle(
                                                            snapshot.isDragging,
                                                            provided.draggableProps.style)}>
                                                            <SpecWrapperGrid container spacing={3} justifyContent="center">
                                                                <Grid item xs={1}>
                                                                    <IconButton sx={{marginTop: '8px'}} color="primary" {...provided.dragHandleProps}>
                                                                        <DragIndicatorRounded />
                                                                    </IconButton>
                                                                </Grid> 
                                                                <Grid item xs container>
                                                                    <TextField
                                                                        fullWidth
                                                                        error={!!error(`product_specifications[${index}].name`)}
                                                                        id="name"
                                                                        name="name"
                                                                        placeholder="Nama Table"
                                                                        value={specification.name}
                                                                        helperText={error(`product_specifications[${index}].name`)}
                                                                        variant="outlined"
                                                                        onChange={onSpecificationTitleChange.bind(this, index)}
                                                                    />
                                                                </Grid>
                                                                <Grid item>
                                                                    <DelButton 
                                                                        variant="contained"
                                                                        size="large"
                                                                        disableElevation
                                                                        onClick={onRemoveSpecification.bind(this, index)}
                                                                        >
                                                                        Hapus
                                                                    </DelButton>
                                                                </Grid>
                                                            </SpecWrapperGrid>
                                                            <Droppable droppableId={index.toString()} type="attribute">
                                                                {(provided) => (
                                                                    <Box
                                                                    ref={provided.innerRef}
                                                                    {...provided.droppableProps}>
                                                                        {
                                                                            specification.product_specification_tables.length >= 1 && specification.product_specification_tables.sort((a, b) => a.sort_order - b.sort_order).map((attribute, attrIndex) => {
                                                                                // const entity = specification.product_specification_tables[attrIndex];
                                                                                
                                                                                const dragId = `specification-${index}-${attrIndex}`
                                                                                if (!dragId || attribute._destroy) {
                                                                                    return null;
                                                                                }                                                                  
                                                                                return (
                                                                                    <Draggable
                                                                                        key={dragId}
                                                                                        draggableId={dragId}
                                                                                        index={attrIndex}>
                                                                                        {(provided) => (
                                                                                            <Box
                                                                                            ref={provided.innerRef}
                                                                                            {...provided.draggableProps}
                                                                                            style={getItemStyle(
                                                                                                snapshot.isDragging,
                                                                                                provided.draggableProps.style
                                                                                            )}
                                                                                            >
                                                                                            
                                                                                            <ItemWrapperGrid container spacing={3} 
                                                                                                justifyContent="center"
                                                                                            >
                                                                                                <Grid item xs={1}>
                                                                                                    <IconButton sx={{marginTop: '8px'}} color="primary" {...provided.dragHandleProps}>
                                                                                                        <DragIndicatorRounded />
                                                                                                    </IconButton>
                                                                                                </Grid>
                                                                                                <Grid item xs container>
                                                                                                    <TextField
                                                                                                        fullWidth
                                                                                                        error={!!error(`product_specifications[${index}].product_specification_tables[${attrIndex}].label`)}
                                                                                                        id="label"
                                                                                                        name="label"
                                                                                                        placeholder="Label"
                                                                                                        value={attribute.label}
                                                                                                        helperText={error(`product_specifications[${index}].product_specification_tables[${attrIndex}].label`)}
                                                                                                        variant="outlined"
                                                                                                        // onChange={onAttributeChange.bind(this, index, specification)}
                                                                                                        onChange={onAttributeChange.bind(this, attrIndex, index)}
                                                                                                    />
                                                                                                </Grid>
                                                                                                <Grid item xs container>
                                                                                                    <TextField
                                                                                                        fullWidth
                                                                                                        error={!!error(`product_specifications[${index}].product_specification_tables[${attrIndex}].content`)}
                                                                                                        id="content"
                                                                                                        name="content"
                                                                                                        placeholder="Konten"
                                                                                                        value={attribute.content}
                                                                                                        helperText={error(`product_specifications[${index}].product_specification_tables[${attrIndex}].content`)}
                                                                                                        variant="outlined"
                                                                                                        onChange={onAttributeChange.bind(this, attrIndex, index)}
                                                                                                    />
                                                                                                </Grid>
                                                                                                <Grid item>
                                                                                                    <DelButton 
                                                                                                        disabled={specification.product_specification_tables.length === 1}
                                                                                                        variant="contained"
                                                                                                        size="large"
                                                                                                        disableElevation
                                                                                                        onClick={onRemoveAttribute.bind(this, attrIndex, index)}
                                                                                                        >
                                                                                                        Hapus
                                                                                                    </DelButton>
                                                                                                </Grid>
                                                                                            </ItemWrapperGrid>
                                                                                        </Box>
                                                                                    )}
                                                                                    </Draggable>
                                                                                )
                                                                            })
                                                                        }
                                                                        
                                                                    {provided.placeholder}
                                                                    </Box>
                                                                )}
                                                                </Droppable>
                                                            <Grid container justifyContent="flex-end" sx={{marginBottom: '15px'}}>
                                                                <Grid item >
                                                                    <WhiteButton 
                                                                        variant="contained"
                                                                        size="large"
                                                                        disableElevation
                                                                        onClick={onAddAttribute.bind(this, index)}
                                                                        >
                                                                            Tambah Attribute
                                                                    </WhiteButton>
                                                                </Grid>
                                                            </Grid>
                                                        </Box>
                                                    )}
                                                </Draggable>
                                            )
                                        })
                                    }
                                    {provided.placeholder}
                                </Box>
                            )}
                                    
                        
                        </Droppable>
                    </DragDropContext>
                </Grid>
                <Grid item>
                    <AddButton 
                        variant="contained"
                        size="large"
                        disableElevation
                        startIcon={<AddRounded />}
                        onClick={onAddSpecification}>
                        Tambah Spesifikasi
                    </AddButton>
                </Grid>
            </Grid>
        </Grid>
        
    )
}

const AddButton = styled(Button)(({theme}) => ({
    color: theme.palette.primary.main,
    backgroundColor: "#FFFFFF",
    '&:hover': {
      backgroundColor: "#FFFFFF",
    },
    fontWeight: 500,
    border: `1px solid ${theme.palette.primary.main}`
}));

const DelButton = styled(Button)(({theme}) => ({
    color: theme.palette.text.primary,
    backgroundColor: "#FFFFFF",
    '&:hover': {
    backgroundColor: "#FFFFFF",
    },
    fontWeight: 500,
    border: `1px solid #ddd`,
    height: 56
}));

const WhiteButton = styled(Button)(({theme}) => ({
    color: theme.palette.text.primary,
    backgroundColor: "#FFFFFF",
    '&:hover': {
      backgroundColor: "#FFFFFF",
    },
    fontWeight: 500,
    border: `1px solid #ddd`,
    marginBottom: 25
}));

const SpecWrapperGrid = styled(Grid)(({theme}) => ({
    marginBottom: 20
}));

const ItemWrapperGrid = styled(Grid)(({theme}) => ({
    marginBottom: 10
}));
