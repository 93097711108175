import * as types from '../actions/actionTypes';

const defaultState = {
	onSupplierProducts: false
}

export default (state = defaultState, action) => {
	switch(action.type) {
		case types.SUPPLIERS_LOADED:
			return {
				...state,
				suppliers: action.error ? null : action.payload.suppliers,
				suppliers_count: action.error ? null : action.payload.suppliers_count,
				page_limit: action.error ? null : action.payload.page_limit,
            };
        case types.NEW_SUPPLIER_LOADED:
			return {
				...state,
				supplier: {
					name: '', 
					sales: '',
					email: '',
					address: '', 
					telephone: '', 
					fax: '',
					phone: '', 
					state: '', 
					city: '', 
					district: '', 
					code_area: '',
					net: 0
				},
				areas: []
			};
		case types.AREAS_LOADED:
			return {
				...state,
				areas: action.payload ? action.payload.areas : null,
				areasFetching: false
			};
		case types.AREAS_UNLOADED:
			return {
				...state,
				areas: [],
				areasFetching: false
			};
		case types.EDIT_SUPPLIER_LOADED:
			return {
				...state,
				supplier: action.payload ? action.payload.supplier : null,
				areas: []
			};
		case types.SUPPLIER_UPDATED:
			return {
				...state,
				supplier: action.supplier
			};
		case types.SUPPLIER_CREATED:
		case types.SUPPLIER_EDITED:
		case types.SUPPLIER_PAYMENT_CREATED:
			return {
				...state,
				inProgress: false,
				errors: action.error ? action.payload.errors : null
			};
		case types.SUPPLIER_FORM_UNLOADED:
            return {
                ...state,
                supplier: null,
				errors: [],
				areas: null,
				inProgress: false,
			};
		case types.SUPPLIER_LOADED:
			return {
				...state,
				supplier: action.error ? null : action.payload.supplier,
				onSupplierPurchaseOrders: false
			};
		case types.SUPPLIER_UNLOADED:
			return {
				...state,
				supplier: null,
				onSupplierPurchaseOrders: false
			};
		case types.SUPPLIER_PURCHASE_ORDERS_SHOWED:
			return {
				...state,
				onSupplierPurchaseOrders: true,
				purchase_order_payment: {}
			};
		case types.SUPPLIER_PURCHASE_ORDERS_HIDED:
			return {
				...state,
				onSupplierPurchaseOrders: false,
				purchase_orders: null,
				purchase_order_payment: {}
			};
		case types.SUPPLIER_PURCHASE_ORDERS_LOADED:
			return {
				...state,
				purchase_orders: action.payload ? action.payload.purchase_orders : null,
				purchase_order_payment: {
					subtotal: 0,
					grand_total: 0,
					payment_amount: 0,
					payment_method: "",
					payment_title: "",
					payment_date: new Date(),
					discount_amount: 0,
					discount_percent: 0,
					note: "",
					discount_type: 'percent',
					purchase_order_payment_items: []
				},
				onPayment: false
			};
		case types.SUPPLIER_PURCHASE_ORDER_PAYMENT_UPDATED:
			return {
				...state,
				purchase_order_payment: action.purchase_order_payment
			};
		case types.SUPPLIER_PAYMENT_SHOWED:
			return {
				...state,
				onPayment: true
			};
		case types.SUPPLIER_PAYMENT_HIDED:
			return {
				...state,
				onPayment: false,
				errors: null
			};
		// case types.CURRENT_SUPPLIER_PRODUCTS_LOADED:
		// 	return {
		// 		...state,
		// 		supplier_products: action.payload ? action.payload.supplier_products : null,
		// 		supplier_products_count: action.error ? null : action.payload.supplier_products_count,
		// 		page_limit: action.error ? null : action.payload.page_limit,
		// 	};	
		case types.ASYNC_START:
            if ( action.subtype === types.SUPPLIER_CREATED ||
                 action.subtype === types.SUPPLIER_EDITED || 
				 action.subtype === types.SUPPLIER_PAYMENT_CREATED
                ) {
                return { 
                    ...state, 
                    inProgress: true };
            };
			if ( action.subtype === types.AREAS_LOADED ) {
                return { 
                    ...state, 
                    areasFetching: true
                };
            };
            return state;
		default:
			return state;
	}
}