import React from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { onHideVariants } from '../../../../../../../../actions/storeAdjustmentActions';
import { 
    Dialog,
    Slide,
    Typography,
    Box,
    DialogTitle,
    IconButton,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Close } from '@mui/icons-material';
import Content from './Content';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function Title({ children, onClose, ...other }) {
    return (
      <DialogTitle {...other}>
        <Typography fontWeight={700} textAlign="center" sx={{textTransform: 'capitalize'}} component="div" variant="h6">
            Pilih {children}
        </Typography>
        {onClose ? (
          <CloseButton aria-label="close" 
           onClick={onClose}>
            <Close />
          </CloseButton>
        ) : null}
      </DialogTitle>
    );
};

export default function Variant() {
    const dispatch = useDispatch();
    const {onVariant, variantProduct, variantPage} = useSelector(state => ({
        ...state.store_adjustment
    }), shallowEqual);
   

    function onHide() {
        dispatch(onHideVariants());
    }
    
    if (!variantProduct && !onVariant) {
        return null;
    }
    const { product_configurations } = variantProduct;;
    const name = product_configurations[variantPage - 1].name;
    const options = product_configurations[variantPage - 1].product_configuration_options;

    return (
        <Dialog
            open={onVariant}
            maxWidth="md"
            fullWidth={true}
            TransitionComponent={Transition}
            scroll={'paper'}
            >
            <Title onClose={onHide}>
                {name}
            </Title>
            <Content
                label={name}
                options={options} />
        </Dialog>
    )
}

const CloseButton = styled(IconButton)(({theme}) => ({
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
    color: theme.palette.grey[500],
}));
