import * as types from './actionTypes';
import { axiosClient } from '../api';


export const onLoadEmployeeJobPositions = () => {
	const fetch = axiosClient.get(`/employee-job-positions`);
	return {
		type: types.EMPLOYEE_JOB_POSITIONS_LOADED,
		payload: fetch
	}
}

export const onEmployeeJobPositionNew = (parent_id) => {
    const fetch = axiosClient.get(`/employee-job-positions/${parent_id}/new`);
	return {
		type: types.NEW_EMPLOYEE_JOB_POSITION_LOADED,
        payload: fetch
	}
}

export const onUpdateField = (key, value) => {
	return {
        type: types.EMPLOYEE_JOB_POSITION_FIELD_UPDATED,
        key,
        value
	}
}

export const onUpdateDescriptions = (newDescriptions) => {
	return {
        type: types.EMPLOYEE_JOB_DESCRIPTIONS_UPDATED,
        newDescriptions
	}
}

export const onEmployeeJobPositionEdit = (id) => {
	const fetch = axiosClient.get(`/employee-job-positions/${id}/edit`);
	return {
		type: types.EDIT_EMPLOYEE_JOB_POSITION_LOADED,
		payload: fetch
	}
}

export const onCreate = (employee_job_position) => {
	const fetch = axiosClient.post(`/employee-job-positions`, {employee_job_position});
	return {
		type: types.EMPLOYEE_JOB_POSITION_CREATED, 
		payload: fetch
	}
}

export const onEdit = (id, employee_job_position) => {
	const fetch = axiosClient.put(`/employee-job-positions/${id}`, {employee_job_position});
	return {
		type: types.EMPLOYEE_JOB_POSITION_EDITED, 
		payload: fetch
	}
}

export const onUnloadForm = () => {
	return {
		type: types.EMPLOYEE_JOB_POSITION_FORM_UNLOADED
	}
}