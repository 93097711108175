
import * as types from './actionTypes';
import { axiosClient } from '../api';

export const onLoadSuppliers = (query) => {
	const fetch = axiosClient.get(`/suppliers`,
	{
		params: query
	});
	return {
		type: types.SUPPLIERS_LOADED,
		payload: fetch
	}
}

export const onSupplierNew = () => {
	return {
		type: types.NEW_SUPPLIER_LOADED
	}
}


export const onSupplierEdit = (id) => {
	const fetch = axiosClient.get(`/suppliers/${id}/edit`);
	return {
		type: types.EDIT_SUPPLIER_LOADED,
		payload: fetch
	}
}

export const onUpdateSupplier = (supplier) => {
	return {
		type: types.SUPPLIER_UPDATED,
		supplier
	}
}

export const onCreate = (supplier) => {
	const fetch = axiosClient.post(`/suppliers`, {supplier});
	return {
		type: types.SUPPLIER_CREATED, 
		payload: fetch
	}
	
}

export const onEdit = (id, supplier) => {
	const fetch = axiosClient.put(`/suppliers/${id}`, {supplier});
	return {
		type: types.SUPPLIER_EDITED, 
		payload: fetch
	}
}

export const onUnloadForm = () => {
	return {
		type: types.SUPPLIER_FORM_UNLOADED
	}
}

export const onShowPurchaseOrders = () => {
	return {
		type: types.SUPPLIER_PURCHASE_ORDERS_SHOWED
	}
}

export const onHidePurchaseOrders = () => {
	return {
		type: types.SUPPLIER_PURCHASE_ORDERS_HIDED
	}
}

export const onLoadSupplier = (id) => {
	const fetch = axiosClient.get(`/suppliers/${id}`);
	return {
		type: types.SUPPLIER_LOADED,
		payload: fetch
	}
}

export const onLoadPurchaseOrders = (id, query) => {
	const fetch = axiosClient.get(`/suppliers/${id}/purchase-orders`, 
	{
		params: query
	});
	return {
		type: types.SUPPLIER_PURCHASE_ORDERS_LOADED,
		payload: fetch
	}
}

export const onUpdatePurchaseOrderPayment = (purchase_order_payment) => {
	return {
		type: types.SUPPLIER_PURCHASE_ORDER_PAYMENT_UPDATED,
		purchase_order_payment
	}
}


export const onUnloadSupplier = () => {
	return {
		type: types.SUPPLIER_UNLOADED
	}
}

export const onShowPayment = () => {
	return {
		type: types.SUPPLIER_PAYMENT_SHOWED
	}
}

export const onHidePayment = () => {
	return {
		type: types.SUPPLIER_PAYMENT_HIDED
	}
}

export const onCreatePurchaseOrderPayment = (purchase_order_payment) => {
	const fetch = axiosClient.post(`/purchase-order-payments`, {purchase_order_payment});
	return {
		type: types.SUPPLIER_PAYMENT_CREATED, 
		payload: fetch
	}
	
}

// export const onLoadSupplierProducts = (id, query) => {
// 	const fetch = axiosClient.get(`/suppliers/${id}/products`,
// 	{
// 		params: query
// 	});
// 	return {
// 		type: types.CURRENT_SUPPLIER_PRODUCTS_LOADED,
// 		payload: fetch
// 	}
// }