import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import * as productActions from '../../../../../../../actions/productActions';
import { 
        Typography,
        Paper,
        Box,
        Grid,
        Button,
        TextField,
        IconButton,
        InputAdornment,
        Switch,
        Chip
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {AddRounded, DragIndicatorRounded, DeleteForeverRounded} from '@mui/icons-material';
import NumberFormat from 'react-number-format';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};
  
const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    
    left: 0,
    margin: 0,
    padding: 0,
    // change background colour if dragging
    border: isDragging ? "1px solid #ddd" : "1px solid transparent",
  
    // styles we need to apply on draggables
    ...draggableStyle
});

const CurrencyFormat = React.forwardRef((props, ref) => (
    <NumberFormat
        {...props.other}
        onValueChange={(values) => {
          props.onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        customInput={TextField}
        InputProps={{
            startAdornment: <InputAdornment position="start">
                <Box fontWeight={700}>
                    Rp
                </Box>
            </InputAdornment>,
        }}
        placeholder={props.placeholder}
        value={props.value}
        error={props.error}
        helperText={props.helperText}
        fullWidth={props.fullWidth}
        thousandSeparator={"."}
        decimalSeparator={","}
        allowNegative={false}
        decimalScale={0}
        isNumericString
      />
));


const UnitFormat = React.forwardRef((props, ref) => (
    <NumberFormat
        {...props.other}
        onValueChange={(values) => {
          props.onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        placeholder={props.placeholder}
        value={props.value}
        fullWidth={props.fullWidth}
        customInput={TextField}
        thousandSeparator={"."}
        decimalSeparator={","}
        decimalScale={2}
        error={props.error}
        helperText={props.helperText}
        allowNegative={false}
        isNumericString
      />
));

const WeightFormat = React.forwardRef((props, ref) => (
    <NumberFormat
        {...props.other}
        onValueChange={(values) => {
          props.onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        customInput={TextField}
        placeholder={props.placeholder}
        value={props.value}
        error={props.error}
        helperText={props.helperText}
        fullWidth={props.fullWidth}
        thousandSeparator={"."}
        decimalSeparator={","}
        allowNegative={false}
        allowedDecimalSeparators={false}
        isNumericString
      />
));

function pad(data, size) {
    var s = String(data);
    while (s.length < (size || 2)) {s = "0" + s;}
    return s;
}

function validEANBarcode(barcode){
    let s = 0;
    for (let i = 0; i < 12; i++) {
        let c = parseInt(barcode.charAt(i));
        s += c * ( i%2 == 0? 1: 3);
    }
    s = (10 - s % 10) % 10;
    return barcode.slice(0, -1) + s;
}


export default function ProductOption({product, autoSKU, error}) {    
    const dispatch = useDispatch();
    const unitRefs = useRef([]); 
    const { product_options, option_title } = product;
    // const { title, product_options } = product_options.find(op => !op._destroy);

	function onRemoveOption() {
        const newOptions = product_options.filter(op => op._id);
        newOptions.map(op => {
            if (!op._destroy) {
                op._destroy = true;
            }
        });
        dispatch(productActions.onRemoveOptions(newOptions));
    }

    function onUpdateField(event) {
		const key = event.target.name;
        const value = event.target.value;
        dispatch(productActions.onUpdateField(key, value));
    }

    function onAddOption() {
        var generateSKU;
        let currentDate = new Date();
        let date = currentDate.getDate().toString().padStart(2, "0");
        let month = currentDate.getMonth().toString().padStart(2, "0");
        let year = currentDate.getUTCFullYear().toString().substr(-2);
        let dateSKU = date + month + year;
        let minutes = currentDate.getMinutes().toString().padStart(2, "0");
        let seconds = currentDate.getSeconds().toString().padStart(2, "0");
        let milliSeconds = currentDate.getMilliseconds().toString().padStart(3, "0");
        let timeSKU = minutes + seconds + milliSeconds;

        
        generateSKU = dateSKU.concat(timeSKU);
        var optionSKU;
        if (!autoSKU) {
            optionSKU = "";
        } else {
            optionSKU = validEANBarcode(generateSKU);
        }
        const addOptions = [...product_options, {name: '', sku: optionSKU, price: '', product_type: "option_item", unit: '', uom: '', weight: '', status: true, sort_order: ''  }];
        const newOptions = [];
		addOptions.map((option, index)=> {
			const newEntity = {...option, sort_order: index}
			return newOptions.push(newEntity)
		})
        dispatch(productActions.onUpdateOptions(newOptions));
    }

    function onPressEnter(index, event) {
        const value = event.target.value;
        if(event.keyCode == 13 && !product_options[index].uom){
            const newOptions = { ...product_options[index], uom: value.toUpperCase()};
            product_options.splice(index, 1, newOptions);
            dispatch(productActions.onUpdateOptions(product_options));
        }
    }

    function onBlurUnit(index, event) {
        const value = event.target.value;
        if (!product_options[index].uom) {
            const newOptions = { ...product_options[index], uom: value.toUpperCase()};
            product_options.splice(index, 1, newOptions);
            dispatch(productActions.onUpdateOptions(product_options));
        }
    }

    function onDeleteUOM(index, event) {
        const newOptions = { ...product_options[index], uom: ''};
        product_options.splice(index, 1, newOptions);
        dispatch(productActions.onUpdateOptions(product_options));
        setTimeout(function() {
            unitRefs.current[index].focus(); 
        }, 100);        
    }

    function getOptionsLength() {
        return product_options.filter(el => {
            return !el._destroy;
        }).length
    }

    function onChangeEntity(index, event) {
        const key = event.target.name;
        const value = event.target.value;
        const newOptions = { ...product_options[index], [key]: value }
        product_options.splice(index, 1, newOptions);
        dispatch(productActions.onUpdateOptions(product_options));
    }

    function onSwitchEntity(index) {
        const newOptions = { ...product_options[index], status: !product_options[index].status }
        product_options.splice(index, 1, newOptions)
        dispatch(productActions.onUpdateOptions(product_options));
    }

   
    
    function onRemoveEntity(index, option) {
		if (option._id) {
			product_options[index]._destroy = true
		} else {
			product_options.splice(index, 1)
        }
        
        const newOptions = [];
		product_options.map((option, index)=> {
			const newOption = {...option, sort_order: index}
			return newOptions.push(newOption)
		})
        dispatch(productActions.onUpdateOptions(newOptions));
    }
    
    function onDragEnd(result) {
		
		// dropped outside the list
		if (!result.destination) {
		  return;
		}
	
		const optionsReorder = reorder(
        product_options,
		  result.source.index,
		  result.destination.index
		);
		
		const newOptions = [];
		optionsReorder.map((option, index)=> {
			const newOption = {...option, sort_order: index}
			return newOptions.push(newOption)
		})
    
        dispatch(productActions.onUpdateOptions(newOptions));
    }

    return (
        <ContentPaper elevation={3}>
            <Grid container spacing={6} direction="column">
                <Grid item container>
                    <Grid item xs>
                        <Typography fontWeight={700} variant="h6" lineheight="normal">
                            Opsi Produk
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                            Tambah opsi produk, dengan memasukan harga dan kuantitas pengurangan untuk setiap transaksi.
                        </Typography>
                    </Grid>
                    <Grid item>
                        <ClearButton 
                            variant="contained"
                            size="large"
                            disableElevation
                            onClick={onRemoveOption}>
                            Hapus Opsi
                        </ClearButton>
                    </Grid>
                </Grid>
                <Grid item container direction="column" spacing={5}>
                    <Grid item container spacing={8}>
                        <Grid item xs={4}>
                            <Typography fontSize={16} fontWeight={700} component="div">
                                Nama Opsi
                            </Typography>
                            <Typography variant="body2" gutterBottom color="textSecondary">
                                Masukan nama opsi, seperti ukuran, kemasan, atau kapasitas.
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <TextField
                                fullWidth
                                error={!!error('option_title')}
                                name="option_title"
                                placeholder="Nama Opsi"
                                value={option_title}
                                helperText={error('option_title')}
                                variant="outlined"
                                onChange={onUpdateField}
                            />
                        </Grid> 
                    </Grid>
                    <Grid item>
                        <OptionsPaper elevation={0}>
                            <Grid container direction="column" spacing={2} sx={{width: '120%'}}>
                                <Grid item>
                                    <TitleGrid container spacing={2}>
                                        <Grid item xs={1}>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Typography lineheight="normal" component="div">
                                                <Box fontSize={14} fontWeight={500}>
                                                    Nama
                                                </Box>
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Typography lineheight="normal" component="div">
                                                <Box fontSize={14} fontWeight={500}>
                                                    SKU
                                                </Box>
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Typography lineheight="normal" component="div">
                                                <Box fontSize={14} fontWeight={500}>
                                                    Harga
                                                </Box>
                                            </Typography>
                                        </Grid>
                                        <Grid item xs>
                                            <Typography lineheight="normal" component="div">
                                                <Box fontSize={14} fontWeight={500}>
                                                    Unit
                                                </Box>
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <Typography fontSize={14} fontWeight={500} component="div">
                                                UOM
                                            </Typography>
                                        </Grid> 
                                        <Grid item xs={1}>
                                            <Typography lineheight="normal" component="div">
                                                <Box fontSize={14} fontWeight={500}>
                                                    Berat
                                                </Box>
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Typography lineheight="normal" component="div">
                                                <Box fontSize={14} fontWeight={500}>
                                                Status 
                                                </Box>
                                            </Typography>
                                        </Grid> 
                                    </TitleGrid>
                                </Grid>
                                <Grid item>
                                    <DragDropContext onDragEnd={onDragEnd}>
                                        <Droppable droppableId="droppable">
                                        {(provided, snapshot) => (
                                            <Box
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                            >
                                            {product_options.map((option, index) => {
                                                if (option._destroy) {
                                                    return null;
                                                }
                                                return (
                                                    <Draggable key={index} draggableId={index.toString()} index={index}>
                                                        {(provided, snapshot) => (
                                                            <ItemBox
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            style={getItemStyle(
                                                                snapshot.isDragging,
                                                                provided.draggableProps.style
                                                            )}
                                                            >
                                                                <Grid container alignItems="center" justifyContent="center" spacing={2}>
                                                                    <Grid item xs={1} sx={{textAlign: 'right'}}>
                                                                        <IconButton color="primary" {...provided.dragHandleProps}>
                                                                            <DragIndicatorRounded />
                                                                        </IconButton>
                                                                    </Grid>
                                                                    <Grid item xs={2}>
                                                                        <TextField
                                                                            fullWidth
                                                                            error={!!error(`product_options[${index}].name`)}
                                                                            name="name"
                                                                            placeholder="Nama Opsi"
                                                                            value={option.name}
                                                                            variant="outlined"
                                                                            onChange={onChangeEntity.bind(this, index)}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={2}>
                                                                        <TextField
                                                                            fullWidth
                                                                            name="sku"
                                                                            placeholder="SKU"
                                                                            value={option.sku}
                                                                            variant="outlined"
                                                                            disabled={autoSKU}
                                                                            error={!!error(`product_options[${index}].sku`)}
                                                                            onChange={onChangeEntity.bind(this, index)}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={2}>
                                                                        <CurrencyFormat
                                                                            fullWidth
                                                                            error={!!error(`product_options[${index}].price`)}
                                                                            name="price"
                                                                            placeholder="Harga"
                                                                            value={option.price}
                                                                            variant="outlined"
                                                                            onChange={onChangeEntity.bind(this, index)}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={1}>
                                                                        <UnitFormat
                                                                            fullWidth
                                                                            error={!!error(`product_options[${index}].unit`)}
                                                                            name="unit"
                                                                            placeholder="Unit"
                                                                            value={option.unit}
                                                                            variant="outlined"
                                                                            onChange={onChangeEntity.bind(this, index)}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={1}>
                                                                        {
                                                                            !!option.uom ? <Chip
                                                                            tabIndex={-1}
                                                                            size="medium"
                                                                            label={option.uom}
                                                                            sx={{marginRight: '5px'}}
                                                                            onDelete={onDeleteUOM.bind(this, index)}
                                                                            variant="outlined"
                                                                            /> :  
                                                                            <TextField
                                                                                error={!!error(`product_options[${index}].uom`)}
                                                                                placeholder="Satuan"
                                                                                inputRef={(ref) => (unitRefs.current[index] = ref)} 
                                                                                variant="outlined"
                                                                                onKeyDown={onPressEnter.bind(this, index)}
                                                                                onBlur={onBlurUnit.bind(this, index)}
                                                                            />
                                                                        }
                                                                    </Grid> 
                                                                    <Grid item xs={1}>
                                                                        <WeightFormat
                                                                            name="weight"
                                                                            value={option.weight}
                                                                            placeholder="Weight"
                                                                            error={!!error(`product_options[${index}].weight`)}
                                                                            variant="outlined"
                                                                            onChange={onChangeEntity.bind(this, index)} />
                                                                    </Grid>
                                                                    <Grid item xs={2}>
                                                                        <Grid container spacing={5} alignItems="center">
                                                                            <Grid item>
                                                                                <Switch
                                                                                    checked={option.status}
                                                                                    onChange={onSwitchEntity.bind(this, index)}
                                                                                    color="primary"
                                                                                    name="status"/>
                                                                            </Grid>
                                                                            <Grid item>
                                                                                <IconButton 
                                                                                    disabled={getOptionsLength() === 1}
                                                                                    color="primary"
                                                                                    onClick={onRemoveEntity.bind(this, index, option)}>
                                                                                    <DeleteForeverRounded />
                                                                                </IconButton>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid> 
                                                                </Grid>
                                                            </ItemBox>
                                                        )}
                                                    </Draggable>
                                                )
                                            }
                                            )}
                                            {provided.placeholder}
                                            </Box>
                                        )}
                                        </Droppable>
                                    </DragDropContext>
                                </Grid>
                            </Grid>
                            
                        </OptionsPaper>
                    </Grid>
                </Grid>
                <Grid item>
                    <OptionButton 
                        variant="contained"
                        size="large"
                        disableElevation
                        startIcon={<AddRounded />}
                        onClick={onAddOption}>
                        Opsi Produk
                    </OptionButton>
                </Grid>
            </Grid>
        </ContentPaper>
    )
}

const ContentPaper = styled(Paper)(({theme}) => ({
    padding: 35,
    paddingTop: 32,
    marginBottom: 25
}));

const OptionsPaper = styled(Paper)(({theme}) => ({
    border: "1px solid #ddd",
    marginTop: 10,
    padding: 15,
    overflowY: 'scroll',
    paddingBottom: 75
}));


const TitleGrid = styled(Grid)(({theme}) => ({
    marginTop: 10,
    marginBottom: 15
}));

const ItemBox = styled(Box)(({theme}) => ({
    paddingTop: '10px !important',
    paddingBottom: '10px !important'
}));

const ClearButton = styled(Button)(({theme}) => ({
    color: theme.palette.text.primary,
    backgroundColor: "#FFFFFF",
    '&:hover': {
    backgroundColor: "#FFFFFF",
    },
    fontWeight: 500,
    border: `1px solid #ddd`
}));

const OptionButton = styled(Button)(({theme}) => ({
    color: theme.palette.primary.main,
    backgroundColor: "#FFFFFF",
    '&:hover': {
      backgroundColor: "#FFFFFF",
    },
    fontWeight: 500,
    border: `1px solid ${theme.palette.primary.main}`
}));