import React from 'react';
import {
    Paper
    } from '@mui/material';
import Banner from './Banner';
import Price from './Price';
import Feature from './Feature';
import Hardware from './Hardware';
import Footer from '../Common/Footer';
import { Outlet } from 'react-router-dom';

export default function Home() {

    return (
        <Paper>
            <Banner />
            <Price />
            <Feature />
            <Hardware />
            <Footer />
            <Outlet />
        </Paper>
    
    )

    
}
