import React, { useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import * as orderActions from '../../../../../../actions/orderActions';
import { 
        Typography,
        Box,
        Grid,
        Paper,
        Divider,
        List,
        ListSubheader,
        TableRow,
        TableCell,
        TableHead,
        TableSortLabel,
        ListItem,
        Tooltip,
        Button
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate, useParams } from "react-router-dom";
import Loader from '../../../../../Helper/Loader';
import { PaymentsTwoTone, AssignmentIndTwoTone, ReceiptTwoTone, MoveDownTwoTone, HelpTwoTone, AccessTimeTwoTone, LocalShippingTwoTone } from '@mui/icons-material';
import { green, red, grey } from '@mui/material/colors';
import { visuallyHidden } from '@mui/utils';
import { tooltipClasses } from '@mui/material/Tooltip';
import { keyframes } from '@emotion/react'
import NumberFormat from 'react-number-format';
import moment from 'moment';
import 'moment/locale/id';
import Payment from './Payment';
import Canceled from './Canceled';
import Delivery from './Delivery';

export default function Show() {    
    let navigate = useNavigate();
    let { id } = useParams();
    const dispatch = useDispatch();
    const {order, errors} = useSelector(state => ({
        ...state.order
    }), shallowEqual); 

    const [totalOpen, setTotalOpen] = React.useState(false);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
    },[errors]);

    useEffect(() => {
        dispatch(orderActions.onLoadOrder(id));
        return () => {
            dispatch(orderActions.onUnloadOrder())
        };
    },[]);

    const handleTotalClose = () => {
        setTotalOpen(false);
    };
    
    const handleTotalOpen = () => {
        setTotalOpen(true);
    };

    function onShowPayments() {
        dispatch(orderActions.onShowPayments());
    }

    function onShowCanceleds() {
        dispatch(orderActions.onShowCanceleds());
    }

    function onShowDeliveries() {
        dispatch(orderActions.onShowOrderDeliveries());
    }

   
    if (!order) {
        return (
            <Loader />
        )
    }
    const { status, 
        increment_id, 
        store, 
        created_at, 
        due_date, 
        customer_name, 
        discount, 
        shipping_cost, 
        subtotal, 
        grand_total, 
        order_items, 
        order_type,
        has_payments,
        has_canceleds,
        has_deliveries,
        order_payments
    } = order;

    function total() {
        return (
            <Box>
                <Grid container spacing={1} direction="column">
                    <Grid item container justifyContent="flex-end">
                        <Grid item xs>
                            <Typography fontWeight={500}>
                                Subtotal
                            </Typography>
                        </Grid>
                        <Grid item>
                            <NumberFormat
                                value={subtotal}
                                displayType="text"
                                thousandSeparator={"."}
                                decimalSeparator={","}
                                prefix="Rp "
                                isNumericString
                                decimalScale={2}
                                renderText={(value) => <Typography fontWeight={500}>
                                {value}
                            </Typography>}
                            />
                        </Grid>
                    </Grid>
                    <Grid item container justifyContent="flex-end">
                        <Grid item xs>
                            <Typography fontWeight={500}>
                                Diskon
                            </Typography>
                        </Grid>
                        <Grid item>
                            <NumberFormat
                                value={discount}
                                displayType="text"
                                thousandSeparator={"."}
                                decimalSeparator={","}
                                prefix="Rp "
                                isNumericString
                                decimalScale={2}
                                renderText={(value) => <Typography fontWeight={500}>
                                {value}
                            </Typography>}
                            />
                        </Grid>
                    </Grid>
                    <Grid item container justifyContent="flex-end">
                        <Grid item xs>
                            <Typography fontWeight={500}>
                                Biaya Kirim
                            </Typography>
                        </Grid>
                        <Grid item>
                            <NumberFormat
                                value={shipping_cost}
                                displayType="text"
                                thousandSeparator={"."}
                                decimalSeparator={","}
                                prefix="Rp "
                                isNumericString
                                decimalScale={2}
                                renderText={(value) => <Typography fontWeight={500}>
                                {value}
                            </Typography>}
                            />
                        </Grid>
                    </Grid>
                    <Grid item >
                        <Divider />
                    </Grid>
                    <Grid item container justifyContent="flex-end">
                        <Grid item xs>
                            <Typography fontWeight={700}>
                                Total
                            </Typography>
                        </Grid>
                        <Grid item>
                            <NumberFormat
                                value={grand_total}
                                displayType="text"
                                thousandSeparator={"."}
                                decimalSeparator={","}
                                prefix="Rp "
                                isNumericString
                                decimalScale={2}
                                renderText={(value) => <Typography fontWeight={700}>
                                {value}
                            </Typography>}
                            />
                        </Grid>
                    </Grid>
                    { order_payments && [<Grid key={0} item container justifyContent="flex-end">
                        <Grid item xs>
                            <Typography fontWeight={700}>
                                {order_payments.payment_title}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <NumberFormat
                                value={order_payments.payment_amount}
                                displayType="text"
                                thousandSeparator={"."}
                                decimalSeparator={","}
                                prefix="Rp "
                                isNumericString
                                decimalScale={2}
                                renderText={(value) => <Typography fontWeight={700}>
                                {value}
                            </Typography>}
                            />
                        </Grid>
                    </Grid>,
                    <Grid key={1} item container justifyContent="flex-end">
                        <Grid item xs>
                            <Typography fontWeight={700}>
                                Kembali
                            </Typography>
                        </Grid>
                        <Grid item>
                            <NumberFormat
                                value={order_payments.change}
                                displayType="text"
                                thousandSeparator={"."}
                                decimalSeparator={","}
                                prefix="Rp "
                                isNumericString
                                decimalScale={2}
                                renderText={(value) => <Typography fontWeight={700}>
                                {value}
                            </Typography>}
                            />
                        </Grid>
                    </Grid>]}
                </Grid>
            </Box>
        )
    }

    function orderType() {
        if (order_type === 'invoice') {
            return (
                <Typography fontWeight={700} sx={{display: 'flex'}}>
                    <ReceiptTwoTone style={{ fontSize: 18, marginRight: '5px', marginTop: '3px' }} color="primary" /> {status}
                </Typography>
            )
        } else if (order_type === 'shipping') {
            return (
                <Typography fontWeight={700} sx={{display: 'flex'}}>
                    <LocalShippingTwoTone style={{ fontSize: 18, marginRight: '5px', marginTop: '3px' }} color="primary" /> {status}
                </Typography>
            )
        } else {
            return (
                <Typography fontWeight={700} sx={{display: 'flex'}}>
                    <PaymentsTwoTone style={{ fontSize: 18, marginRight: '5px', marginTop: '3px' }} color="primary" /> {status}
                </Typography>
            )
        }
    }
    return (
        <ContentBox>
            <HeaderBox>
                <Typography fontWeight={700} variant="h6">
                    Informasi Pesanan
                </Typography>
            </HeaderBox>
            <Content>
                <ContentPaper elevation={3}>
                    <Box sx={{display: 'flex', padding: '35px', paddingTop: '25px', paddingBottom: '25px'}}>
                        {orderType()}
                        <Slash>
                            /
                        </Slash>
                        <Typography fontWeight={700} color="primary">
                            {increment_id}
                        </Typography>
                        {customer_name && [<Slash key={1}>
                            /
                        </Slash>,
                        <Typography key={2} fontWeight={700} sx={{display: 'flex'}}>
                            <AssignmentIndTwoTone style={{ fontSize: 18, marginRight: '5px', marginTop: '3px', color: green[600] }} /> {customer_name}
                        </Typography>]}
                        <Slash>
                            /
                        </Slash>
                        <Typography sx={{display: 'flex'}}>
                            <AccessTimeTwoTone style={{ fontSize: 18, marginRight: '5px', marginTop: '3px' }} color="primary" /> {moment(created_at).format('LL')}, {moment(created_at).format('LT')} WIB
                        </Typography>
                    </Box>
                    <Box>
                        <Divider />
                    </Box>
                    <Box sx={{padding: '35px'}}>
                        <Grid container>
                            {due_date && <Grid item xs={3} container direction="column">
                                    <Grid item>
                                        <Typography color={grey[500]} fontWeight={500} fontSize={13} variant="body1">
                                            Jatuh  Tempo
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography fontWeight={700} sx={{display: 'flex'}}>
                                            {moment(due_date).format('LL')}
                                        </Typography>
                                    </Grid>
                                </Grid>}
                            <Grid item xs={3} container direction="column">
                                <Grid item>
                                    <Typography color={grey[500]} fontWeight={500} fontSize={13} variant="body1">
                                        Cabang
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography fontWeight={700} sx={{display: 'flex'}}>
                                        {store}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs={3} container direction="column">
                                <Grid item>
                                    <Typography color={grey[500]} fontWeight={500} fontSize={13} variant="body1">
                                        Aktivitas
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography gutterBottom variant="h6" color="primary" fontSize={14} style={{textTransform: 'capitalize', cursor: 'pointer'}}>
                                        Lihat Aktivitas
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </ContentPaper>
                <ContentPaper elevation={3}>
                    <Box sx={{display: 'flex', padding: '35px', justifyContent:"space-between", paddingBottom: 0}}>
                        <Typography fontWeight={700}>
                            Informasi Produk
                        </Typography>
                    </Box>
                    <Box sx={{overflowX: 'scroll', padding: '35px', paddingBottom: 0, paddingTop: '15px'}}>
                        <List component="div" sx={{width: '145%'}}>
                            <ListSubheader sx={{display: 'flex', padding: 0}} component="div">
                                <Box sx={{width: '30%', marginRight: '25px'}}>
                                    Nama Barang
                                </Box>
                                <Box textAlign='right' sx={{width:  '10%', marginRight: '25px'}}>
                                    Dipesan
                                </Box>
                                <Box textAlign='right' sx={{width:  '10%', marginRight: '25px'}}>
                                    Dikirim
                                </Box>
                                <Box textAlign='right' sx={{width:  '10%', marginRight: '25px'}}>
                                    Dibatalkan
                                </Box>
                                <Box textAlign='right' sx={{width:  '10%', marginRight: '25px'}}>
                                    Harga Jual
                                </Box>
                                <Box textAlign='right' sx={{width:  '10%', marginRight: '25px'}}>
                                    Diskon
                                </Box>
                                <Box textAlign='right' sx={{width:  '10%', marginRight: '25px'}}>
                                    Harga Netto
                                </Box>
                                <Box textAlign='right' sx={{width:  '10%'}}>
                                    Subtotal
                                </Box>
                            </ListSubheader>
                            {
                                order_items.map((item, index) => {
                                    return (
                                        <ListItem key={index} disableGutters divider={index !== order_items.length - 1}>
                                            <Box sx={{width: '30%', marginRight: '25px'}}>
                                                <Typography sx={{display:'flex', position: 'relative'}} lineheight="normal" fontSize={16} fontWeight={700}>
                                                    {item.name}
                                                </Typography>
                                                <Typography lineheight="normal" fontSize={11} style={{color: '#999'}}>
                                                    SKU #{item.sku}
                                                </Typography>
                                            </Box>
                                            <Box sx={{width:  '10%', marginRight: '25px'}}>
                                                <NumberFormat
                                                    value={item.quantity}
                                                    displayType="text"
                                                    thousandSeparator={"."}
                                                    decimalSeparator={","}
                                                    isNumericString
                                                    decimalScale={2}
                                                    renderText={(value) => <Typography textAlign="right" fontSize={16} fontWeight={500}>
                                                    {value} {item.uom}
                                                    </Typography>}
                                                />
                                            </Box>
                                            <Box sx={{width:  '10%', marginRight: '25px'}}>
                                                <NumberFormat
                                                    value={item.delivered_qty}
                                                    displayType="text"
                                                    thousandSeparator={"."}
                                                    decimalSeparator={","}
                                                    isNumericString
                                                    decimalScale={2}
                                                    renderText={(value) => <Typography textAlign="right" fontSize={16} fontWeight={500}>
                                                    {value ? value : 0} {item.uom}
                                                    </Typography>}
                                                />
                                            </Box>
                                            <Box sx={{width:  '10%', marginRight: '25px'}}>
                                                <NumberFormat
                                                        value={item.canceled_qty}
                                                        displayType="text"
                                                        thousandSeparator={"."}
                                                        decimalSeparator={","}
                                                        isNumericString
                                                        decimalScale={2}
                                                        renderText={(value) => <Typography textAlign="right" fontSize={16} fontWeight={500}>
                                                        {value ? value : 0} {item.uom}
                                                        </Typography>}
                                                    />
                                            </Box>
                                            <Box sx={{width:  '10%', marginRight: '25px'}}>
                                                    <NumberFormat
                                                    value={item.unit_price}
                                                    displayType="text"
                                                    thousandSeparator={"."}
                                                    decimalSeparator={","}
                                                    prefix="Rp "
                                                    isNumericString
                                                    decimalScale={2}
                                                    renderText={(value) => <Typography textAlign="right" fontSize={16} fontWeight={500}>
                                                    {value}
                                                    </Typography>}
                                                />
                                            </Box>
                                            <Box sx={{width:  '10%', marginRight: '25px'}}>
                                                <NumberFormat
                                                    value={item.discount_type === 'percent' ? item.discount_percent : item.discount_amount}
                                                    displayType="text"
                                                    thousandSeparator={"."}
                                                    decimalSeparator={","}
                                                    prefix={item.discount_type === 'percent' ? "" : "Rp "}
                                                    suffix={item.discount_type === 'percent' ? "%" : ""}
                                                    isNumericString
                                                    decimalScale={2}
                                                    renderText={(value) => <Typography textAlign="right" fontSize={16} fontWeight={500}>
                                                    {value}
                                                    </Typography>}
                                                />
                                            </Box>
                                            <Box sx={{width:  '10%', marginRight: '25px'}}>
                                                <NumberFormat
                                                    value={item.net_price}
                                                    displayType="text"
                                                    thousandSeparator={"."}
                                                    decimalSeparator={","}
                                                    prefix="Rp "
                                                    isNumericString
                                                    decimalScale={2}
                                                    renderText={(value) => <Typography textAlign="right" fontSize={16} fontWeight={500}>
                                                    {value}
                                                    </Typography>}
                                                />
                                            </Box>
                                            <Box sx={{width:  '10%'}}>
                                                <NumberFormat
                                                    value={item.row_total}
                                                    displayType="text"
                                                    thousandSeparator={"."}
                                                    decimalSeparator={","}
                                                    prefix="Rp "
                                                    isNumericString
                                                    decimalScale={2}
                                                    renderText={(value) => <Typography textAlign="right" fontSize={16} fontWeight={500}>
                                                    {value}
                                                    </Typography>}
                                                />
                                            </Box>
                                        </ListItem>
                                    )
                                })
                            }
                        </List>
                    </Box>
                    <Box sx={{display: 'flex', padding: '35px'}}>
                        <Grid container spacing={3} justifyContent="flex-end">
                            {has_payments && <Grid item>
                                <Button variant="outlined" onClick={onShowPayments} startIcon={<ReceiptTwoTone />}>
                                    Pembayaran
                                </Button>
                            </Grid>}
                            {has_canceleds && <Grid item>
                                <Button variant="outlined" onClick={onShowCanceleds} startIcon={<MoveDownTwoTone />}>
                                    Pembatalan
                                </Button>
                            </Grid>}
                            {has_deliveries &&<Grid item>
                                <Button variant="outlined"  onClick={onShowDeliveries} startIcon={<LocalShippingTwoTone />}>
                                    Pengiriman
                                </Button>
                            </Grid>}
                            {(has_deliveries || has_canceleds || has_payments) && <Grid item>
                                <Divider orientation="vertical" />
                            </Grid>}
                            <Grid item container xs spacing={3} alignItems="center" justifyContent="space-between">
                                <Grid item>
                                    <Typography sx={{display: 'flex'}} lineheight="normal" fontSize={16} fontWeight={700}>
                                        Total Pesanan 
                                        <LightTooltip open={totalOpen} placement='right' onClose={handleTotalClose} onOpen={handleTotalOpen} title={total()}>
                                            <HelpTwoTone color="primary" />
                                        </LightTooltip>
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <NumberFormat
                                        value={grand_total}
                                        displayType="text"
                                        thousandSeparator={"."}
                                        decimalSeparator={","}
                                        prefix="Rp "
                                        isNumericString
                                        decimalScale={2}
                                        renderText={(value) => <Typography lineheight="normal" fontSize={16} fontWeight={700}>
                                        {value}
                                    </Typography>}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </ContentPaper>
            </Content>
            <Payment/>
            <Canceled />
            <Delivery />
        </ContentBox>
    )
}

const ContentPaper = styled(Paper)(({theme}) => ({
    marginBottom: 25
}));

const ContentBox = styled(Box)(({theme}) => ({
    padding: '25px 0',
    [theme.breakpoints.down('sm')]: {
    padding: '15px 15px 0 15px',
    },
    [theme.breakpoints.down('md')]: {
    padding: '15px 15px 0 15px',
    },
    height: '100%'
}));


const HeaderBox = styled(Box)(({theme}) => ({
    paddingLeft: 0,
    marginBottom: 25,
    [theme.breakpoints.down('sm')]: {
        paddingLeft: 55,
    },
}));

const Content = styled(Box)(({theme}) => ({
    paddingBottom: 15
}));

const Slash = styled(Typography)(({theme}) => ({
    margin: '0 6px',
    color: 'var(--N75, #E5E7E9)'
}));


const TimelineBox = styled(Box)(({theme}) => ({
    display: 'flex',
    minHeight: 60
 }));

const DateBox = styled(Box)(({theme}) => ({
    width: '20%',
    textAlign: 'right'
}));

const LineWrapperBox = styled(Box)(({theme}) => ({
   margin: '5px 16px',
   alignItems: 'center',
   flexDirection: 'column',
   display: 'flex'
}));

const grow = keyframes`
    from {
        opacity: 1;
        transform: scale(0);
    }
    to {
        opacity: 0;
        transform: scale(2);
    }
`;

const CurrentCircleBox = styled(Box)(({theme}) => ({
    width: '15px',
    height: '15px',
    borderRadius: '50%',
    position: 'relative',
    marginBottom: 'auto',
    '&:before': {
        width: 15,
        position: 'absolute',
        height: 15,
        borderRadius: '50%',
        backgroundColor: `var(--G300,#4B00D1)`,
        animationName:  `${grow}`,
        animationDuration: '1400ms',
        animationTimingFunction: 'ease-out',
        animationIterationCount: 'infinite',
        animationFillMode: 'none',
        animationPlayState: 'running',
        content: '""'
    }, 
    '&:after': {
        borderRadius: '50%',
        backgroundColor: 'var(--G500,#6200EE)',
        content: '""',
        position: 'absolute',
        width: 11,
        height: 11,
        left: 2,
        top: 2
    },
 }));


const CircleBox = styled(Box)(({theme}) => ({
    width: '15px',
    height: '15px',
    borderRadius: '50%',
    position: 'relative',
    marginBottom: 'auto',
    backgroundColor: 'var(--N100,#DBDEE2)',
    '&:after': {
        borderRadius: '50%',
        backgroundColor: 'var(--N200,#9FA6B0)',
        content: '""',
        position: 'absolute',
        width: '9px',
        height: '9px',
        left: '3px',
        top: '3px'
    },
 }));

const LineBox = styled(Box, {
    shouldForwardProp: (prop) => prop !== "last",
  })(({ theme, last }) => ({
    display: last ? 'none' : 'block',
    width: 1,
    height: 'calc(100% - 9px)',
    marginBottom: -6,
    backgroundImage: 'linear-gradient(to bottom,var(--N100,#DBDEE2) 55%,rgba(255,255,255,0) 55%)',
    backgroundPosition: 'left center',
    backgroundSize: '1px 12px',
    backgroundRepeat: 'repeat-y'
 }));

 const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} sx={{marginLeft: '5px'}} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      width: 325,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 11,
      padding: 15
    },
  }));