import * as types from '../actions/actionTypes';

const defaultState = {
}

export default function (state = defaultState, action) {
	switch(action.type) {
		case types.ORDER_DELIVERY_RETURNS_LOADED:
			return {
				...state,
				stores: action.payload ? action.payload.stores : null,
				order_delivery_returns: action.payload ? action.payload.order_delivery_returns : null,
				order_delivery_returns_count: action.payload ? action.payload.order_delivery_returns_count : null,
            };
        case types.ORDER_DELIVERY_RETURN_LOADED:
            return {
                ...state,
                order_delivery_return: action.payload ? action.payload.order_delivery_return : null,
                onOrderDeliveryReturnConfirm: false
            };
        case types.ORDER_DELIVERY_RETURN_UNLOADED:
            return {
                ...state,
                order_delivery_return: null
            };
        case types.ORDER_DELIVERY_RETURN_UPDATED:
            return {
                ...state,
                order_delivery_return: action.order_delivery_return
            };
        case types.ORDER_DELIVERY_RETURN_CONFIRM_SHOWED:
            return {
                ...state,
                onOrderDeliveryReturnConfirm: true,
                actionType: action.action_type
            };
        case types.ORDER_DELIVERY_RETURN_CONFIRM_HIDED:
            return {
                ...state,
                onOrderDeliveryReturnConfirm: false,
                actionType: null
            };
        case types.ORDER_DELIVERY_RETURN_EDITED:
            return { 
                ...state,
                inProgress: false,
                onOrderDeliveryReturnConfirm:  action.error ? false : state.onOrderDeliveryReturnConfirm,
                errors: action.error ? action.payload.errors : null,
            };
        case types.ASYNC_START:
        if ( action.subtype === types.ORDER_DELIVERY_RETURN_EDITED ) {
            return { 
                ...state, 
                inProgress: true };
        };
        return state;
		default:
			return state;
	}
}