import React, { useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import * as resetActions from '../../../actions/resetActions';
import { 
    Box, 
    Container,
    Divider,
    Paper,
    Typography, 
    TextField,
    Button,
    } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";
import { WhatsApp } from '@mui/icons-material';
import logo from '../../../assets/images/logo-pp.png';
import Form from './Form';
import Verification from '../Common/Verification';

export default function ResetPassword() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { user, userVerified, userVerification, userExist } = useSelector(state => ({
        ...state.common,
        ...state.reset
    }), shallowEqual);

    useEffect(() => {
        dispatch(resetActions.onLoadReset())
        return () => {
            dispatch(resetActions.onUnloadReset())
        };
    },[]);

    function onUpdateField(event) {
		const key = event.target.name;
        const value = event.target.value;
        dispatch(resetActions.onUpdateValidationField(key, value));
	}

    function onNext(event) {
        dispatch(resetActions.onValidateUser(user));
	}

    if (userVerified) {
        return (
            <Form />
        )
    }

    if (!user) {
        return null;
    }
    const { email_phone } = user;

    return (
        <SectionBox>
            <Verification verifyType="reset-password" open={userVerification && userExist} />
            <ContentBox>
                <ContentContainer maxWidth="sm">
                    <LogoBox onClick={() => navigate('/')} sx={{ cursor: 'pointer'}} component="img" src={logo} />
                    <FormPaper elevation={3}>
                        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                            <Typography fontWeight={700} variant="h5" gutterBottom>
                                Lupa Password
                            </Typography>
                        </Box>
                        <FieldBox>
                            <TextField 
                                fullWidth 
                                name="email_phone"
                                label="Email / No. HP" 
                                variant="outlined"
                                helperText="Contoh: email@alipos.com"
                                value={email_phone}
                                onChange={onUpdateField} />
                        </FieldBox>
                        
                        <Button onClick={onNext} fullWidth size="large" variant="contained">Selanjutnya</Button>

                        <AuthDivider>
                            Butuh bantuan?
                        </AuthDivider>

                        <Button fullWidth size="large"  onClick={() => {
                                window.location.assign('https://api.whatsapp.com/send?phone=628176009888&text=Hi,%20%20saya%20ingin%20bertanya%20mengenai%20AliPOS.');
                            }}
                            variant="outlined" startIcon={<WhatsApp />} >
                            Hubungi kami
                        </Button>
                        
                    </FormPaper>
                </ContentContainer>
            </ContentBox>
        </SectionBox>

    )
}

const SectionBox = styled(Box)(({theme}) => ({
    background: '#f4f3fc',
    height: '100vh'
}));

const ContentBox = styled(Box)(({theme}) => ({
    padding: 35
}));


const ContentContainer = styled(Container)(({theme}) => ({
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center'
}));

const FormPaper = styled(Paper)(({theme}) => ({
    backgroundColor: 'rgb(255, 255, 255)',
    color: 'rgb(34, 51, 84)',
    transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    borderRadius: '8px',
    boxShadow: 'rgb(159 162 191 / 18%) 0px 9px 16px, rgb(159 162 191 / 32%) 0px 2px 2px',
    overflow: 'hidden',
    marginTop: 55,
    padding: 35,
    width: '100%',
    [theme.breakpoints.between('xs','sm')]: {
        padding: 20
    },
}));

const FieldBox = styled(Box)(({theme}) => ({
    marginTop: 45,
    marginBottom: 25
}));


const LogoBox = styled(Box)(({theme}) => ({
    width: 175
}));

const AuthDivider = styled(Divider)(({theme}) => ({
    marginTop: 35,
    marginBottom: 35
}));