import React from 'react';

export default function NotFound() {
  
    return (
        <div>
            <h1>404: Not found</h1>
        </div>
  
    )
    
}
