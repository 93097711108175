import * as types from './actionTypes';
import { axiosClient } from '../api';

export const onLoadDashboard = (query) => {
	const fetch = axiosClient.get(`/dashboards`,
	{
		params: query
	});
	return {
		type: types.DASHBOARD_LOADED,
		payload: fetch
	}
}

export const onUnloadDashboard = () => {
	return {
		type: types.DASHBOARD_UNLOADED
	}
}