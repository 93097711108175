import * as types from '../actions/actionTypes';

const defaultState = {
    // userVerification: false,
    // userExists: false,
    // newUser: false
}

export default function register (state = defaultState, action) {
	switch(action.type) {
		case types.USER_REGISTER_LOADED:
            return {
                ...state,
                // user: {
                //     email_phone: "",
                //     // verify_type: "register"
                // },
                // userVerification: false,
                userExist: false,
                newUser: false,
			};
        case types.USER_REGISTER_UNLOADED:
            return {
                ...state,
                // user: {
                //     email_phone: ""
                // }, 
                userExist: false,
                newUser: false,
                errors: null,
                // userVerified: false,
                // userVerification: false
			};
        case types.USER_REGISTER_VALIDATION_FIELD_UPDATED:
            return {
                ...state,
                // user: { ...state.user, [action.key]: action.value }
			};
        case types.USER_REGISTER_FIELD_UPDATED:
            return {
                ...state,
                verified_user: { ...state.verified_user, [action.key]: action.value }
			};
        case types.USER_REGISTER_VALIDATION:
            return {
                ...state,
                // userVerification: action.error ? false : true,
                userExist: action.payload.user_exist,
                // user: action.error ? state.user : action.payload.user,
                errors: action.error ? action.payload.errors : null,
                // onVerification: false,
                inProgress: false
			};
        case types.NEW_USER_REGISTER_LOADED:
            return {
                ...state,
                // user: action.user,
                newUser: true,
			};
        case types.USER_VERIFICATION_CANCELED:
            return {
                ...state,
                // userVerification: false,
                userExist: false
            };
        // case types.USER_EMAIL_VERIFICATION:
        // case types.USER_MESSAGE_VERIFICATION:
        //     return {
        //         ...state,
        //         onVerification: true,
        //         dialogInProgress: false,
        //         unVerified: null
        //     };
        case types.USER_VERIFICATION:
            return {
                ...state,
        //         userVerified: action.payload.verified,
                verified_user: action.payload ? action.payload.user : state.user,
        //         unVerified: action.error,
        //         dialogInProgress: false
            };
        case types.USER_REGISTERED:
            return {
                ...state,
                errors: action.error ? action.payload.errors : null,
                user_token: action.payload ? action.payload.user_token : null,
                inProgress: false
            };
        case types.ASYNC_START:
            if ( action.subtype === types.USER_REGISTER_VALIDATION || action.subtype === types.USER_REGISTERED 
                ) {
                return { 
                    ...state, 
                    inProgress: true };
            };
            // if ( action.subtype === types.USER_VERIFICATION || action.subtype === types.USER_EMAIL_VERIFICATION || action.subtype === types.USER_MESSAGE_VERIFICATION
            //     ) {
            //     return { 
            //         ...state, 
            //         dialogInProgress: true };
            // };
            return state;
		default:
			return state;
	}
}