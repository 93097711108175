import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { 
    Dialog,
    Slide,
} from '@mui/material';
import Content from './Content';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function Inventory() {
    const {onStoreInventories} = useSelector(state => ({
        ...state.outlet
    }), shallowEqual);   

    return (
        <Dialog
            open={onStoreInventories}
            fullScreen
            TransitionComponent={Transition}
            scroll={'paper'}
            style={{zIndex: 1500}}
            >
            <Content />
        </Dialog>
    )
}

