import React from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import * as employeeActions from '../../../../../../../actions/employeeActions';
import { 
    Typography,
    Grid,
    Button,
    Box,
    Chip,
} from '@mui/material';
import { useParams } from "react-router-dom";
import Edit from './Edit';
import Loader from '../../../../../../Helper/Loader';

export default function Access() {
    let { id } = useParams();
    const dispatch = useDispatch();
    const { employee, editAccess, onEditing } = useSelector(state => ({
        ...state.employee
    }), shallowEqual);

    const { employee_access } = employee;

    

    function onEdit() {
        dispatch(employeeActions.onEditAccess(id))
    };

    function onDelete() {
        dispatch(employeeActions.onDeleteAccess(id))
    };

    if (onEditing) {
        return (
            <Box sx={{ minHeight: '415px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Loader />
            </Box>
        )
    }

    if (editAccess) {
        return <Edit />
    }

    if (!employee_access) {
        return (
            <Grid container spacing={4} direction="column">
                <Grid item container>
                    <Grid item xs>
                        <Typography fontWeight={700} component="h6" lineheight="normal">
                            Akses Karyawan
                        </Typography>
                    </Grid>
                    <Grid item>
                    </Grid>
                </Grid>
                <Grid item>
                    <Box sx={{minHeight: 250, display: 'flex'}}>
                        <Grid container direction="column" alignItems="center" justifyContent="center" spacing={3}>
                            <Grid item>
                                <Button 
                                    variant="contained"
                                    size="large"
                                    color="primary"
                                    sx={{maxWidth: 225}}
                                    onClick={onEdit}
                                    disableElevation>
                                    Pilih Akses
                                </Button>
                            </Grid>
                            <Grid item>
                                <Typography fontSize={16} fontWeight={400} component="div">
                                    Tentukan akses karyawan.
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        )
    }

    const { name, accesses, permissions_count } = employee_access;

    var access = '';
    if (accesses.length === 2) {
        access = accesses.join(' & ')
    } else {
        access = accesses[0] + " Only"
    }

    return (
        <Grid container spacing={4} direction="column">
            <Grid item container>
                <Grid item xs>
                    <Typography fontWeight={700} component="h6" lineheight="normal">
                        Akses Karyawan
                    </Typography>
                </Grid>
                <Grid item>
                    <Button onClick={onEdit}>
                        Ubah
                    </Button>
                </Grid>
                <Grid item>
                    <Button onClick={onDelete}>
                        Hapus
                    </Button>
                </Grid>
            </Grid>
            <Grid item container direction="column" alignItems="center" justifyContent="center" spacing={3}>
                <Grid item container spacing={8} >
                    <Grid item xs={4}>
                        <Typography fontSize={16} fontWeight={700} component="div">
                            Nama Hak Akses
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography fontSize={16} fontWeight={700} component="div">
                            {name}
                        </Typography>
                    </Grid> 
                </Grid>
                <Grid item container spacing={8}>
                    <Grid item xs={4}>
                        <Typography fontSize={16} fontWeight={700} component="div">
                            Hak Akses
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography fontSize={16} fontWeight={700} component="div">
                            {access}
                        </Typography>
                    </Grid> 
                </Grid>
                <Grid item container spacing={8}>
                    <Grid item xs={4}>
                        <Typography fontSize={16} fontWeight={700} component="div">
                            Wewenang
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography fontSize={16} fontWeight={700} component="div">
                            <Chip label={`${permissions_count} Tugas & Akses`} color="success" variant="outlined" />
                        </Typography>
                    </Grid> 
                </Grid>
            </Grid>
        </Grid>
    )
}