import React, { useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import * as registerActions from '../../../actions/registerActions';
import { 
    Box, 
    Container,
    Paper,
    Typography, 
    TextField,
    Button,
    Grid
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { styled } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";
import logo from '../../../assets/images/logo-pp.png';

export default function Form() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { verified_user, inProgress, errors } = useSelector(state => ({
        ...state.common,
        ...state.register,
    }), shallowEqual);

    // useEffect(() => {
    //     dispatch(registerActions.onLoadRegister())
    // },[]);

    function onUpdateField(event) {
        
		const key = event.target.name;
        const value = event.target.value;
        dispatch(registerActions.onUpdateField(key, value));
	}

    function onCreate() {
        dispatch(registerActions.onSignUp(verified_user));
	}

    function onEnter(event) {
        if (event.key === 'Enter') {
            dispatch(registerActions.onSignUp(verified_user));
        }
    }

    function ActionButton() {
        if (inProgress) {
            return (
                <LoadingButton loading fullWidth size="large" variant="contained">Selanjutnya</LoadingButton>
            )
        } else {
            return (
                <Button onClick={onCreate} fullWidth size="large" variant="contained">Selesai</Button>
            )
        }
    }

    if (!verified_user) {
        return null;
    }

    

    const { email, phone, first_name, last_name, password, password_confirmation, email_verified, phone_verified  } = verified_user;

    function error(key) {
        if (errors != null && errors[`user.${key}`] != null) {
            return errors[`user.${key}`].msg
        }
        return null;
    }

    return (
        <SectionBox>
            <ContentBox>
                <ContentContainer maxWidth="sm">
                    <LogoBox onClick={() => navigate('/')} sx={{ cursor: 'pointer'}} component="img" src={logo} />
                    <FormPaper elevation={3}>
                        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                            <Typography fontWeight={700} variant="h5" gutterBottom>
                                Aktivasi Akun
                            </Typography> 
                            <Box display="flex" sx={{textAlign: 'center'}}>
                                <Typography fontWeight={500} variant="body1" gutterBottom>
                                    Lengkapi data yang dibutuhkan untuk aktivasi akun Anda.
                                </Typography>
                            </Box>
                        </Box>
                        <FieldWrapperBox>
                            <FieldBox>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={6}>
                                        <TextField 
                                            fullWidth 
                                            name="first_name"
                                            label="Nama Depan" 
                                            variant="outlined"
                                            value={first_name}
                                            onChange={onUpdateField}
                                            error={!!error('first_name')}
                                            helperText={error('first_name')} 
                                            />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField 
                                            fullWidth 
                                            name="last_name"
                                            label="Nama Belakang" 
                                            variant="outlined"
                                            value={last_name}
                                            onChange={onUpdateField}
                                            error={!!error('last_name')}
                                            helperText={error('last_name')} 
                                            />
                                    </Grid>
                                </Grid>
                            </FieldBox>
                            <FieldBox>
                                <TextField 
                                    fullWidth 
                                    disabled={email_verified}
                                    name="email"
                                    label="Email" 
                                    variant="outlined"
                                    value={email}
                                    onChange={onUpdateField}
                                    error={!!error('email')}
                                    helperText={error('email')} 
                                    />
                            </FieldBox>
                            <FieldBox>
                                <TextField 
                                    fullWidth 
                                    disabled={phone_verified}
                                    name="phone"
                                    label="No. HP" 
                                    variant="outlined"
                                    value={phone}
                                    onChange={onUpdateField}
                                    error={!!error('phone')}
                                    helperText={error('phone')}
                                     />
                            </FieldBox>
                            <FieldBox>
                                <TextField 
                                    fullWidth 
                                    name="password"
                                    label="Password" 
                                    variant="outlined"
                                    type="password"
                                    value={password}
                                    onChange={onUpdateField}
                                    error={!!error('password')}
                                    helperText={error('password')} 
                                    />
                            </FieldBox>
                            <FieldBox>
                                <TextField 
                                    fullWidth 
                                    name="password_confirmation"
                                    label="Konfirmasi Password" 
                                    variant="outlined"
                                    type="password"
                                    value={password_confirmation}
                                    onChange={onUpdateField}
                                    onKeyDown={onEnter}
                                    error={!!error('password_confirmation')}
                                    helperText={error('password_confirmation')} 
                                    />
                            </FieldBox>
                        </FieldWrapperBox>
                        <ActionButton />
                        <BottomBox>
                            <Typography sx={{textAlign: "center"}} color={"#999"}variant="body2">
                                Dengan mendaftar, saya menyetujui
                            </Typography> 
                            <Box display="flex" sx={{textAlign: "center"}}>
                                <Typography variant="body2">
                                    <LinkBox component="a" onClick={() => navigate('/term')}>Syarat dan Ketentuan</LinkBox> <span style={{color: "#999"}}>serta </span><LinkBox onClick={() => navigate('/privacy')} component="a" >Kebijakan Privasi</LinkBox>
                                </Typography> 
                            </Box>
                        </BottomBox>
                    </FormPaper>
                </ContentContainer>
            </ContentBox>
        </SectionBox>

    )
}

const SectionBox = styled(Box)(({theme}) => ({
    background: '#f4f3fc',
    height: '100%'
}));

const ContentBox = styled(Box)(({theme}) => ({
    padding: 35
}));


const ContentContainer = styled(Container)(({theme}) => ({
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center'
}));

const FormPaper = styled(Paper)(({theme}) => ({
    backgroundColor: 'rgb(255, 255, 255)',
    color: 'rgb(34, 51, 84)',
    transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    borderRadius: '8px',
    boxShadow: 'rgb(159 162 191 / 18%) 0px 9px 16px, rgb(159 162 191 / 32%) 0px 2px 2px',
    overflow: 'hidden',
    marginTop: 55,
    padding: 35,
    width: '100%',
    [theme.breakpoints.between('xs','sm')]: {
        padding: 20
    },
}));

const FieldWrapperBox = styled(Box)(({theme}) => ({
    marginTop: 45,
    marginBottom: 35
}));

const FieldBox = styled(Box)(({theme}) => ({
    marginBottom: 20
}));

const LogoBox = styled(Box)(({theme}) => ({
    width: 175
}));

const BottomBox = styled(Box)(({theme}) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 20
}));

const LinkBox = styled(Box)(({theme}) => ({
    cursor: 'pointer',
    color: theme.palette.primary.main
}));
