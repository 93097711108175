import React, { useState, useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { 
    DialogContent,
    Grid,
    Typography,
    Box,
    TextField,
    Button,
    IconButton,
    FormControl,
    Select,
    MenuItem
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { AddRounded, DragIndicatorRounded } from '@mui/icons-material';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Option from './Option';
import Loader from '../../../../../../../../Helper/Loader';

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    
    left: 0,
    margin: 0,
    padding: 0,
    // change background colour if dragging
    border: "1px solid transparent",
  
    // styles we need to apply on draggables
    ...draggableStyle
});

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);
  
    destClone.splice(droppableDestination.index, 0, removed);
  
    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;
  
    return result;
  };

function pad(data, size) {
    var s = String(data);
    while (s.length < (size || 2)) {s = "0" + s;}
    return s;
}


export default function Content({productConfigurations, onChange}) {
    const dispatch = useDispatch();
    const {product, configurationsErrors, uploadedConfigurations} = useSelector(state => ({
        ...state.product
    }), shallowEqual);

    const { product_configurations } = product;

    const [typeOptions] = useState([
        {
            id: 1,
            title: 'Dropdown',
            value: 'dropdown'
        },
        {
            id: 2,
            title: 'Visual Swatch',
            value: 'vswatch'
        },
        {
            id: 3,
            title: 'Text Swatch',
            value: 'tswatch'
        }
    ]);


    useEffect(() => {
        var configurationsAttributes;
        if (product_configurations.filter(el => {return !el._destroy}).length === 0) {      
            if (product_configurations.length === 0) {
                configurationsAttributes = [{name: '', sort_order: 0, input_type: 'dropdown', product_configuration_options: [{sort_order: 0, value: ''}]}];
            } else {
                configurationsAttributes = [...product_configurations, {name: '', sort_order: 0, input_type: 'dropdown', product_configuration_options: [{sort_order: 0, value: ''}]}];
            }
        } else {
            configurationsAttributes = product_configurations;
        }
        onChange(configurationsAttributes)
    }, []);


     
    function onAddConfiguration() {
        const newConfigurations = [...productConfigurations, {name: '', sort_order: '', input_type: 'dropdown', product_configuration_options: [{sort_order: 0, value: ''}]}]
        newConfigurations.map((config, index)=> {
            const updateitem = { ...config, sort_order: index};
            return newConfigurations.splice(index, 1, updateitem);
        })
        onChange(newConfigurations);
    }


    function onConfigurationChange(index, event) {
        const key = event.target.name;
        const value = event.target.value;

        const newConfigurations = [...productConfigurations];
        newConfigurations.map((item, i) => {
            if (i !== index) {
              // This isn't the item we care about - keep it as-is
              return item
            }
            const updateitem = { ...item, [key]: value}
            return newConfigurations.splice(index, 1, updateitem)  
        })
        onChange(newConfigurations);
    }
      

    function onTypeChange(index, event) {
        const key = event.target.name;
        const value = event.target.value;
        const newConfigurations = [...productConfigurations];
        newConfigurations.map((config, i) => {
            if (i !== index) {
              // This isn't the item we care about - keep it as-is
              return config
            }

            const newOptions = [...config.product_configuration_options]
            newOptions.map((option, o) => {
               
                let updateOption;
                if (value === 'tswatch' || value === 'dropdown') {
                    if (option.product_configuration_option_swatch_images) {
                        console.log('11')
                        const newImages = [...option.product_configuration_option_swatch_images]; 
                        if (newImages) {
                            newImages.map((image, imageIndex) => {
                                if (!image._destroy) {
                                    const updateImage = { ...image, onRemove: true}
                                    return newImages.splice(imageIndex, 1, updateImage)
                                }
                            })
                        }
                        updateOption = { ...option, product_configuration_option_swatch_images: newImages}
                    } else if (option.product_configuration_option_swatch_value) {
                        console.log('22')
                        if (option.product_configuration_option_swatch_value._id) {
                            updateOption = { ...option, product_configuration_option_swatch_value: {...option.product_configuration_option_swatch_value, _destroy: true}}
                        } else {
                            delete option.product_configuration_option_swatch_value;
                            updateOption = { ...option}
                        }
                    } else {
                        updateOption = option;
                    }
                } else if (value === 'vswatch') {
                    console.log('vswatch')
                    if (option.product_configuration_option_swatch_images) {
                        console.log('1')
                        const newImages = [...option.product_configuration_option_swatch_images]; 
                        if (newImages) {
                            newImages.map((image, imageIndex) => {
                                if (image._destroy) {
                                    console.log(image)
                                    delete image._destroy;
                                    const updateImage = { ...image}
                                    return newImages.splice(imageIndex, 1, updateImage)
                                }
                            })
                        }
                        updateOption = { ...option, product_configuration_option_swatch_images: newImages}
                    } else if (option.product_configuration_option_swatch_value) {
                        if (option.product_configuration_option_swatch_value._id) {
                            delete option.product_configuration_option_swatch_value._destroy;
                            updateOption = { ...option, product_configuration_option_swatch_value: {...option.product_configuration_option_swatch_value}}
                        } else {
                            delete option.product_configuration_option_swatch_value;
                            updateOption = { ...option }
                        }
                    }
                     else {
                        updateOption = option;
                    }

                }

                return newOptions.splice(o, 1, updateOption);
            })

            // if (value === 'tswatch' || value === 'dropdown') {


            //     config.product_configuration_options.map((option, o) => {
            //         const swatchValue = option.product_configuration_option_swatch_value;
            //         if (swatchValue) {
            //             return config.product_configuration_options.splice(index, 1, { ...swatchValue, _destroy: true})  
            //         }

            //         const swatchImages = option.product_configuration_option_swatch_images;
            //         if (swatchImages) {
            //             swatchImages.map((image, imageIndex) => {
            //                 const updateImage = { ...image, _destroy: true}
            //                 return swatchImages.splice(imageIndex, 1, updateImage)
            //             })
            //         }
            //     })
            // }

            // if (value === 'vswatch') {
            //     config.product_configuration_options.map((option, o) => {
            //         const swatchValue = option.product_configuration_option_swatch_value;
            //         if (swatchValue) {
            //             return config.product_configuration_options.splice(index, 1, { ...swatchValue, _destroy: false})  
            //         }

            //         const swatchImages = option.product_configuration_option_swatch_images;
            //         if (swatchImages) {
            //             const updateImage = { ...swatchImages[swatchImages.length - 1], _destroy: false}
            //             return swatchImages.splice(swatchImages.length - 1, 1, updateImage)
            //         }
            //     })
            // }

            const updateConfig = { ...config, [key]: value, product_configuration_options: newOptions}
            return newConfigurations.splice(index, 1, updateConfig)
        
        })
        console.log('update')
        onChange(newConfigurations);
        // if (value === 'tswatch' || value === 'dropdown') {
        //     newConfigurations[index].product_configuration_options.map((option) => {
        //         const swatchValue = option.product_configuration_option_swatch_value;
        //         if (swatchValue) {
        //             swatchValue._destroy = true;
        //         }

        //         const swatchImage = option.product_configuration_option_swatch_image_attributes;
        //         if (swatchImage) {
        //             swatchImage._destroy = true;
        //         }


        //         return option;
        //     }) 
        // } 
        // if (value === 'vswatch') {
        //     newConfigurations[index].product_configuration_options.map((option) => {
                
        //         const swatchValue = option.product_configuration_option_swatch_value;
        //         if (swatchValue) {
        //             swatchValue._destroy = false;
        //         }

        //         const swatchImage = option.product_configuration_option_swatch_image_attributes;
        //         if (swatchImage) {
        //             swatchImage._destroy = false;
        //         }

        //         // if (option.product_configuration_option_swatch_attributes.swatch_type !== 'image' && option.product_configuration_option_swatch_attributes.swatch_type !== 'color') {
        //             // option.product_configuration_option_swatch_attributes.swatch_type = "";
        //             option.product_configuration_option_swatch_value = {value: ""};
        //         // }

             
                
        //         return option;
        //     }) 
        // } 
        // newConfigurations.map((config, i) => {
        //     if (i !== index) {
        //       // This isn't the item we care about - keep it as-is
        //       return config
        //     }
        //     const updateConfig = { ...config, [key]: value}
        //     return newConfigurations.splice(index, 1, updateConfig)  
        // })
        // onChange(newConfigurations);
    }

    function onRemoveConfiguration(index) {

        const newConfigurations = [...productConfigurations];
        newConfigurations.map((item, i) => {
            if (i !== index) {
              // This isn't the item we care about - keep it as-is
              return item
            }
            if (item._id) {
                const updateitem = { ...item, _destroy: true}
                return newConfigurations.splice(index, 1, updateitem)
            } else {
                return newConfigurations.splice(index, 1)
            }
        })
        

        newConfigurations.map((config, i)=> {
            const updateitem = { ...config, sort_order: i}
            return newConfigurations.splice(i, 1, updateitem)
        })	
        onChange(newConfigurations);
    }


    function onAddOption(index) {
        const newConfigurations = [...productConfigurations];
        const inputType = newConfigurations[index].input_type;
        var addOptions;
        if (inputType === 'dropdown') {
            addOptions = [...newConfigurations[index].product_configuration_options, {sort_order: '', value: ''}]
        } 
        if (inputType === 'tswatch') {
            addOptions = [...newConfigurations[index].product_configuration_options, {sort_order: '', value: ''}]
        }
        if (inputType === 'vswatch') {
            addOptions = [...newConfigurations[index].product_configuration_options, {sort_order: '', value: ''}]
        }
        addOptions.map((item, i)=> {
            const updateitem = { ...item, sort_order: i}
            return addOptions.splice(i, 1, updateitem)
        })	
        
        newConfigurations.map((item, i) => {
            if (i !== index) {
              // This isn't the item we care about - keep it as-is
              return item
            }
           
            const updateitem = { ...item, product_configuration_options: addOptions}
            return newConfigurations.splice(index, 1, updateitem)
        
        })
        onChange(newConfigurations);
    }

   
    function onDragEnd(result) {
        const { source, destination, type } = result;

        // dropped outside the list
        if (!destination) {
            return;
        }

        if (type === 'config') {
            const configurationsReorder = reorder(
            productConfigurations,
                result.source.index,
                result.destination.index
            );
            
            const newConfigurations = [...configurationsReorder];
            newConfigurations.map((config, i)=> {
                const updateitem = { ...config, sort_order: i}
                return newConfigurations.splice(i, 1, updateitem)
            });
            onChange(newConfigurations);
            // const newConfigurations = configurationsReorder.map((config, index)=> {
            //     return {...config, sort_order: index}
            // })
        
            // dispatch(productActions.onUpdateConfigurations(newConfigurations));
            return;
        }

        
        const sInd = +source.droppableId;
        const dInd = +destination.droppableId;

        if (sInd === dInd) {
            const optionsReorder = reorder(
            productConfigurations[sInd].product_configuration_options,
                source.index,
                destination.index
            );

            const newConfigurations = [...productConfigurations];

            const newOptions = [...optionsReorder]
            newOptions.map((option, o) => {
                let updateOption = { ...option, sort_order: o}     
                return newOptions.splice(o, 1, updateOption);
            })

            const updateConfig = { ...newConfigurations[sInd], product_configuration_options: newOptions}
            newConfigurations.splice(sInd, 1, updateConfig)

            onChange(newConfigurations);
        } else {
            const result = move(productConfigurations[sInd].product_configuration_options, productConfigurations[dInd].product_configuration_options, source, destination);
            const newConfigurations = [...productConfigurations];
     
            const sNewOptions = [...result[sInd]];
            sNewOptions.map((option, o) => {
                let updateOption = { ...option, sort_order: o}     
                return sNewOptions.splice(o, 1, updateOption);
            })

            const sUpdateConfig = { ...newConfigurations[sInd], product_configuration_options: sNewOptions}
            newConfigurations.splice(sInd, 1, sUpdateConfig)

            


            const dNewOptions = [...result[dInd]];
            dNewOptions.map((option, o) => {
                let updateOption = { ...option, sort_order: o}     
                return dNewOptions.splice(o, 1, updateOption);
            })

            const dUpdateConfig = { ...newConfigurations[dInd], product_configuration_options: dNewOptions}
            newConfigurations.splice(dInd, 1, dUpdateConfig)

            if (newConfigurations[sInd].product_configuration_options.length === 0) {
                if (newConfigurations[sInd]._id) {
                    const updateitem = { ...newConfigurations[sInd], _destroy: true}
                    newConfigurations.splice(sInd, 1, updateitem)
                } else {
                    newConfigurations.splice(sInd, 1)
                }
            } 
            onChange(newConfigurations);
        }
        
    }

    function error(name) {
        if (configurationsErrors != null && configurationsErrors[name] != null) {
            return configurationsErrors[name][0]
        }

        return null;
    }

    function OptionHeader(input_type) {
        if (input_type === 'vswatch') {
            return (
                <Grid container spacing={3} sx={{marginBottom: '5px'}}>
                    <Grid item xs={1}>
                    </Grid>
                    <Grid item xs={1}>
                        <Typography fontWeight={700} component="div">
                            Swatch
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography fontWeight={700} component="div">
                            Swatch Text
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>

                    </Grid>
                </Grid>
            )
        } else if (input_type === 'tswatch') {
            return (
                <Grid container spacing={3} sx={{marginBottom: '5px'}}>
                    <Grid item xs={1}>
                    </Grid>
                    <Grid item xs={9}>
                        <Typography fontWeight={700} component="div">
                            Swatch Text
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>

                    </Grid>
                </Grid>
            )
        } else {
            return (
                <Grid container spacing={3} sx={{marginBottom: '5px'}}>
                    <Grid item xs={1}>
                    </Grid>
                    <Grid item xs={9}>
                        <Typography fontWeight={700} component="div">
                            Nama Opsi
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                    </Grid>
                </Grid>
            )
        }
    }

    if (productConfigurations.length === 0) {
        return (
            <Box sx={{minHeight: 500, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <Loader />
            </Box>
        )
    }
    return (
        <WrapperDialogContent>
            <Grid container spacing={3} direction="column">
                <Grid item>
                    <Typography fontWeight={700} variant="h6">
                        Varian Produk
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                        Masukan varian produk, dapat berupa ukuran, jenis barang, kemasan, kapasitas, atau warna.
                    </Typography>
                </Grid>
                <Grid item>
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable
                            droppableId="all-droppables"
                            type="config"
                            >
                            {provided => (
                                <Box
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                >
                                {productConfigurations.sort((a, b) => a.sort_order - b.sort_order).map((config, index) => {
                                    if (config._destroy) {
                                        return null;
                                    }
                                    return (
                                        <Draggable key={index} draggableId={index.toString()}  index={index}>
                                            {(provided, snapshot) => (
                                                <Box
                                                    {...provided.draggableProps}
                                                    ref={provided.innerRef}
                                                    style={getItemStyle(
                                                        snapshot.isDragging,
                                                        provided.draggableProps.style
                                                    )}
                                                    >
                                                    <Grid container direction="column" spacing={2}>
                                                        <Grid item container spacing={3}>
                                                            <Grid item xs={1}>
                                                            </Grid>
                                                            <Grid item xs={5}>
                                                                <Typography fontWeight={700} component="div">
                                                                    Nama Varian
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <Typography fontWeight={700} component="div">
                                                                    Input Tipe
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item container spacing={3}>
                                                            <Grid item xs={1}>
                                                                <IconButton sx={{marginTop: '8px'}} color="primary" {...provided.dragHandleProps}>
                                                                    <DragIndicatorRounded />
                                                                </IconButton>
                                                            </Grid>
                                                            <Grid item xs={5}>
                                                                <TextField
                                                                    error={!!error(`product_configurations[${index}].name`)}
                                                                    helperText={error(`product_configurations[${index}].name`)}
                                                                    fullWidth
                                                                    name="name"
                                                                    placeholder="Nama Varian"
                                                                    value={config.name}
                                                                    variant="outlined"
                                                                    onChange={onConfigurationChange.bind(this, index)}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <FormControl 
                                                                    fullWidth
                                                                    variant="outlined">
                                                                    <Select
                                                                        name="input_type"
                                                                        value={config.input_type}
                                                                        onChange={onTypeChange.bind(this, index)}
                                                                        displayEmpty
                                                                        inputProps={{ 'aria-label': 'Without label' }}>
                                                                        <MenuItem value="" disabled>
                                                                            Pilih Tipe
                                                                        </MenuItem>
                                                                        {
                                                                            typeOptions.map(option => {
                                                                                return(
                                                                                    <MenuItem key={option.value} value={option.value}>
                                                                                        {option.title}
                                                                                    </MenuItem>
                                                                                )
                                                                            })
                                                                        }
                                                                    </Select>
                                                                </FormControl>
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                <DelButton 
                                                                    fullWidth
                                                                    variant="contained"
                                                                    size="large"
                                                                    disableElevation
                                                                    onClick={onRemoveConfiguration.bind(this, index)} >
                                                                    Hapus
                                                                </DelButton>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item>
                                                            {OptionHeader(config.input_type)}
                                                            <Droppable
                                                                droppableId={index.toString()}>
                                                                    {(provided) => (
                                                                <Box
                                                                    ref={provided.innerRef}
                                                                    {...provided.droppableProps}>
                                                                        {
                                                                            config.product_configuration_options.sort((a, b) => a.sort_order - b.sort_order).map((option, optIndex) => {
                                                                                const dragId = `option-${index}-${optIndex}`
                                                                                if (option._destroy) {
                                                                                    return null;
                                                                                }
                                                                                return (
                                                                                    <Option 
                                                                                        key={dragId}
                                                                                        input_type={config.input_type} 
                                                                                        dragId={dragId}
                                                                                        config={config}
                                                                                        index={index}
                                                                                        optIndex={optIndex}
                                                                                        option={option}
                                                                                        error={error}
                                                                                        name={`product_configurations[${index}].product_configuration_options[${optIndex}]`}
                                                                                        getItemStyle={getItemStyle()}
                                                                                        onChange={onChange}
                                                                                        productConfigurations={productConfigurations}
                                                                                        />
                                                                                )
                                                                            })
                                                                        }        
                                                                    {provided.placeholder}
                                                                </Box>
                                                            )}
                                                            </Droppable>
                                                        </Grid>
                                                        <Grid item>
                                                            <Box display="flex"
                                                                justifyContent="flex-end">
                                                                <VariantButton 
                                                                    variant="contained"
                                                                    size="large"
                                                                    disableElevation
                                                                    startIcon={<AddRounded />}
                                                                    onClick={onAddOption.bind(this, index)}>
                                                                    Tambah Opsi Varian
                                                                </VariantButton>
                                                            </Box>
                                                        </Grid>
                                                    </Grid>
                                            </Box>
                                        )}
                                        </Draggable>
                                    )
                                })}
                                {provided.placeholder}
                                </Box>
                            )}
                            </Droppable>
                    </DragDropContext>
                </Grid>
                <Grid item>
                    <Box display="flex" justifyContent="flex-end">
                        <AddButton 
                            variant="contained"
                            size="large"
                            disableElevation
                            startIcon={<AddRounded />}
                            onClick={onAddConfiguration}>
                            Tambah Varian Produk
                        </AddButton>
                    </Box>
                </Grid>
            </Grid>
        </WrapperDialogContent>
    )
}

const WrapperDialogContent = styled(DialogContent)(({theme}) => ({
    padding: '45px !important',
    minHeight: 400,
}));

const DelButton = styled(Button)(({theme}) => ({
    color: theme.palette.text.primary,
    backgroundColor: "#FFFFFF",
    '&:hover': {
      backgroundColor: "#FFFFFF",
    },
    fontWeight: 500,
    border: `1px solid #ddd`,
    height: 56
}));

const VariantButton = styled(Button)(({theme}) => ({
    color: theme.palette.text.primary,
    backgroundColor: "#FFFFFF",
    '&:hover': {
      backgroundColor: "#FFFFFF",
    },
    fontWeight: 500,
    minWidth: 165,
    border: `1px solid #ddd`,
    marginTop: 15
}));

const AddButton = styled(Button)(({theme}) => ({
    color: theme.palette.primary.main,
    backgroundColor: "#FFFFFF",
    '&:hover': {
      backgroundColor: "#FFFFFF",
    },
    fontWeight: 500,
    border: `1px solid ${theme.palette.primary.main}`,
    marginTop: 20
}));
