import * as types from './actionTypes';
import { axiosClient } from '../api';
import { validate } from '../components/Helper/Validation';

export const onLoadOrderDeliveryReturns = (query) => {
	const fetch = axiosClient.get(`/order-delivery-returns`,
	{
		params: query
	});
	return {
		type: types.ORDER_DELIVERY_RETURNS_LOADED,
		payload: fetch
	}
}

export const onLoadOrderDeliveryReturn = (id) => {
	const fetch = axiosClient.get(`/order-delivery-returns/${id}`);
	return {
		type: types.ORDER_DELIVERY_RETURN_LOADED,
		payload: fetch
	}
}


export const onUnloadOrderDeliveryReturn = (id) => {
	return {
		type: types.ORDER_DELIVERY_RETURN_UNLOADED
	}
}

export const onUpdateOrderDeliveryReturn = (order_delivery_return) => {
	return {
		type: types.ORDER_DELIVERY_RETURN_UPDATED,
		order_delivery_return
	}
}

export const onShowConfirm = (action_type) => {
	return {
		type: types.ORDER_DELIVERY_RETURN_CONFIRM_SHOWED,
		action_type
	}
}

export const onHideConfirm = () => {
	return {
		type: types.ORDER_DELIVERY_RETURN_CONFIRM_HIDED
	}
}

export const onCanceled = (id, order_delivery_return) => {
	const fetch = axiosClient.put(`/order-delivery-returns/${id}/canceled`, {order_delivery_return});
	return {
		type: types.ORDER_DELIVERY_RETURN_EDITED, 
		payload: fetch
	}
}

export const onDelivered = (id, order_delivery_return) => {
	const fetch = axiosClient.put(`/order-delivery-returns/${id}/returned`, {order_delivery_return});
	return {
		type: types.ORDER_DELIVERY_RETURN_EDITED, 
		payload: fetch
	}
}