import React, { useState, useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import * as employeeJobPositionActions from '../../../../../actions/employeeJobPositionActions';
import { Paper,
         Box,
         CircularProgress,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Tree from './Tree';
import Header from './Header';
import DataEmpty from '../../../../Helper/DataEmpty';


export default function JobPosition() {
    const dispatch = useDispatch();
    const { employee_job_positions } = useSelector(state => ({
        ...state.employee_job_position
    }), shallowEqual);

    // const [treeData, setTreeData] = useState( [
    //     { id: 1, title: 'Chicken', expanded: false, children: [{ id: 3, title: 'Egg', expanded: false, children: [{ id: 7, title: 'Egg2', expanded: false }] }] },
    //     { id: 2, title: 'Fish', expanded: true, children: [{ id: 4, title: 'fingerline', expanded: false }] },
    // ])
    const [treeData, setTreeData] = useState([])

    useEffect(() => {
        dispatch(employeeJobPositionActions.onLoadEmployeeJobPositions())
    },[dispatch]);

    useEffect(() => {
        setTreeData(employee_job_positions)
    },[employee_job_positions]);

    
    function onChange(treeData) {
        setTreeData(treeData);
    }

    if (!employee_job_positions) {
        return (
          <ProgressBox>
            <CircularProgress />
          </ProgressBox>
        )
    }

    function Content() {
        if (employee_job_positions.length === 0) {
            return <DataEmpty />
        }
        return (
            <TreePaper 
                sx={{position:'relative'}}
                elevation={3}>
                <Tree
                    treeData={treeData}
                    // Need to set getNodeKey to get meaningful ids in paths
                    getNodeKey={({ node }) => node._id}
                    onChange={onChange}
                    />
                    
            </TreePaper>
        )
    }

    return(
        <ContentBox>
            <Header />
            <Paper sx={{ width: '100%', mb: 2, mt: '15px' }} elevation={3}>
                {Content()}
            </Paper>
        </ContentBox>
    )
}

const ContentBox = styled(Box)(({theme}) => ({
padding: '25px 0',
[theme.breakpoints.down('sm')]: {
    padding: '15px 15px 0 15px',
},
[theme.breakpoints.down('md')]: {
    padding: '15px 15px 0 15px',
},
}));


const ProgressBox = styled(Box)(({theme}) => ({
   display: 'flex',
   justifyContent: 'center',
   alignItems: 'center',
   height: '100%'
}));

const TreePaper = styled(Paper)(({theme}) => ({
    padding: 25,
    display: 'flex',
    flexDirection: 'column',
    minHeight: 'calc(100vh - 265px)',
    marginBottom: 25
}));