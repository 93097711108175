import * as types from './actionTypes';
import { axiosClient } from '../api';


export const onLoadEmployees = (query) => {
	const fetch = axiosClient.get(`/employees`,
	{
		params: query
	});
	return {
		type: types.EMPLOYEES_LOADED,
		payload: fetch
	}
}

export const onUnloadEmployees = () => {
	return {
		type: types.EMPLOYEES_UNLOADED
	}
}

export const onEmployeeNew = () => {
    const fetch = axiosClient.get(`/employees/new`);
	return {
		type: types.NEW_EMPLOYEE_LOADED,
        payload: fetch
	}
}

export const onEmployeeShow = (id) => {
	const fetch = axiosClient.get(`/employees/${id}`);
	return {
		type: types.SHOW_EMPLOYEE_LOADED,
		payload: fetch
	}
}

export const onEditPersonalInformation = (id) => {
	const fetch = axiosClient.get(`/employees/${id}/personal-information`);
	return {
		type: types.EDIT_EMPLOYEE_PERSONAL_INFORMATION_LOADED,
		payload: fetch
	}
}

export const onEditFamily = (id) => {
	const fetch = axiosClient.get(`/employees/${id}/families`);
	return {
		type: types.EDIT_EMPLOYEE_FAMILY_LOADED,
		payload: fetch
	}
}


export const onUpdateFamilies = (employee_families) => {
	return {
		type: types.EMPLOYEE_FAMILIES_UPDATED,
		employee_families
	}
}

export const onEditEmergencyContact = (id) => {
	const fetch = axiosClient.get(`/employees/${id}/emergency-contacts`);
	return {
		type: types.EDIT_EMPLOYEE_EMERGENCY_CONTACT_LOADED,
		payload: fetch
	}
}

export const onUpdateEmergencyContacts = (employee_emergency_contacts) => {
	return {
		type: types.EMPLOYEE_EMERGENCY_CONTACTS_UPDATED,
		employee_emergency_contacts
	}
}

export const onEditEmployment = (id) => {
	const fetch = axiosClient.get(`/employees/${id}/employments`);
	return {
		type: types.EDIT_EMPLOYMENT_LOADED,
		payload: fetch
	}
}

export const onEditEmployments = (id, employee) => {
	const fetch = axiosClient.put(`/employees/${id}/employments`, {employee});
	return {
		type: types.EMPLOYEE_EDITED, 
		payload: fetch
	}
}

export const onSelectEmploymentOrganization = (employee) => {
	const fetch = axiosClient.get(`/employee/job-positions`, {
		params: {id: employee.employee_organization_id}
	});
	return {
		type: types.EMPLOYMENT_ORGANIZATION_SELECTED,
		employee,
        payload: fetch
	}
}

export const onSelectEmploymentJobPosition = (employee) => {
	const fetch = axiosClient.get(`/employee/approvals`, {
		params: {id: employee.employee_organization_id, employee_id: employee._id}
	});
	return {
		type: types.EMPLOYMENT_JOB_POSITION_SELECTED,
		employee,
        payload: fetch
	}
}

export const onEditPayroll = (id) => {
	const fetch = axiosClient.get(`/employees/${id}/payrolls`);
	return {
		type: types.EDIT_EMPLOYEE_PAYROLL_LOADED,
		payload: fetch
	}
}

export const onEditPayrolls = (id, employee) => {
	const fetch = axiosClient.put(`/employees/${id}/payrolls`, {employee});
	return {
		type: types.EMPLOYEE_EDITED, 
		payload: fetch
	}
}

export const onEditAccess = (id) => {
	const fetch = axiosClient.get(`/employees/${id}/accesses`);
	return {
		type: types.EDIT_EMPLOYEE_ACCESS_LOADED,
		payload: fetch
	}
}

export const onDeleteAccess = (id) => {
	const fetch = axiosClient.put(`/employees/${id}/unaccesses`);
	return {
		type: types.EMPLOYEE_EDITED,
		payload: fetch
	}
}

export const onEditAccesses = (id, employee) => {
	const fetch = axiosClient.put(`/employees/${id}/accesses`, {employee});
	return {
		type: types.EMPLOYEE_EDITED, 
		payload: fetch
	}
}

export const onSendPin = (id) => {
	const fetch = axiosClient.put(`/employees/${id}/reset-pin`);
	return {
		type: types.EMPLOYEE_PIN_SENDED,
		payload: fetch
	}
}


export const onUnloadEditEmployee = () => {
	return {
		type: types.EDIT_EMPLOYEE_UNLOADED
	}
}

export const onUnloadForm = () => {
	return {
		type: types.EMPLOYEE_FORM_UNLOADED
	}
}

export const onUpdateEmployee = (employee) => {
	return {
		type: types.EMPLOYEE_UPDATED,
		employee
	}
}

export const onUpdateEditEmployee = (employee) => {
	return {
		type: types.EDIT_EMPLOYEE_UPDATED,
		employee
	}
}



export const onSelectEmployeeOrganization = (employee) => {
	const fetch = axiosClient.get(`/employee/job-positions`, {
		params: {id: employee.employee_organization_id}
	});
	return {
		type: types.EMPLOYEE_ORGANIZATION_SELECTED,
		employee,
        payload: fetch
	}
}


export const onSelectEmployeeJobPosition = (employee) => {
	const fetch = axiosClient.get(`/employee/approvals`, {
		params: {id: employee.employee_organization_id}
	});
	return {
		type: types.EMPLOYEE_JOB_POSITION_SELECTED,
		employee,
        payload: fetch
	}
}


// export const onLoadApproval = (id) => {
//     const fetch = axiosClient.get(`/employee/approval_options`, {
// 		params: {id}
// 	});
// 	return {
// 		type: types.EMPLOYEE_APPROVAL_LOADED,
//         payload: fetch
// 	}
// }


export const onCreate = (employee) => {
	const fetch = axiosClient.post(`/employees`, {employee});
	return {
		type: types.EMPLOYEE_CREATED, 
		payload: fetch
	}
}

export const onEditPersonalInformations = (id, employee) => {
	const fetch = axiosClient.put(`/employees/${id}/personal-information`, {employee});
	return {
		type: types.EMPLOYEE_EDITED, 
		payload: fetch
	}
}

export const onEditFamilies = (id, employee) => {
	const fetch = axiosClient.put(`/employees/${id}/families`, {employee});
	return {
		type: types.EMPLOYEE_EDITED, 
		payload: fetch
	}
}

export const onEditEmergencyContacts = (id, employee) => {
	const fetch = axiosClient.put(`/employees/${id}/emergency-contacts`, {employee});
	return {
		type: types.EMPLOYEE_EDITED, 
		payload: fetch
	}
}

export const onValidation = (errors) => {	
    return {
        type: types.FORM_VALIDATION,
        errors
    }
}

// export const onSetCurrentAreas = (current_areas) => {	
//     return {
//         type: types.SET_CURRENT_AREAS,
//         current_areas
//     }
// }

export const onUploadRequest = (image) => {
	return {
		type: types.EMPLOYEE_PROFILE_UPLOAD_REQUESTED,
		image
	}
}


export const onUpload = (file, index, progressCallback) => {
	const fetch = axiosClient.post(`/employees/upload`, {file}, {onUploadProgress: progressCallback});

	return {
		type: types.EMPLOYEE_PROFILE_UPLOADED,
		payload: fetch,
		index
		
	}
	
}

export const onUploading = (image, index) => {
	return {
		type: types.EMPLOYEE_PROFILE_UPLOADING,
		image,
		index	
	}
}

export const onRemoveRequest = (image, index) => {
	return {
		type: types.EMPLOYEE_PROFILE_REMOVE_REQUESTED,
		image,
		index
	}
}

export const onRemove = (file, index) => {
	const fetch = axiosClient.post(`/employees/remove`, {file});
	return {
		type: types.EMPLOYEE_PROFILE_REMOVED,
		payload: fetch,
		index
	}
}


export const onEditUploadRequest = (image) => {
	return {
		type: types.EMPLOYEE_PROFILE_UPLOAD_EDIT_REQUESTED,
		image
	}
}


export const onEditUpload = (file, index, progressCallback) => {
	const fetch = axiosClient.post(`/employees/upload`, {file}, {onUploadProgress: progressCallback});

	return {
		type: types.EMPLOYEE_PROFILE_EDIT_UPLOADED,
		payload: fetch,
		index
		
	}
	
}

export const onEditUploading = (image, index) => {
	return {
		type: types.EMPLOYEE_PROFILE_EDIT_UPLOADING,
		image,
		index	
	}
}

export const onEditRemoveRequest = (image, index) => {
	return {
		type: types.EMPLOYEE_PROFILE_REMOVE_EDIT_REQUESTED,
		image,
		index
	}
}

export const onEditRemove = (file, index) => {
	const fetch = axiosClient.post(`/employees/remove`, {file});
	return {
		type: types.EMPLOYEE_PROFILE_EDIT_REMOVED,
		payload: fetch,
		index
	}
}

export const onIdentityUploadRequest = (image) => {
	return {
		type: types.EMPLOYEE_IDENTITY_UPLOAD_REQUESTED,
		image
	}
}


export const onIdentityUpload = (file, index, progressCallback) => {
	const fetch = axiosClient.post(`/employees/identity-upload`, {file}, {onUploadProgress: progressCallback});

	return {
		type: types.EMPLOYEE_IDENTITY_UPLOADED,
		payload: fetch,
		index
		
	}
	
}

export const onIdentityUploading = (image, index) => {
	return {
		type: types.EMPLOYEE_IDENTITY_UPLOADING,
		image,
		index	
	}
}

export const onIdentityRemoveRequest = (image, index) => {
	return {
		type: types.EMPLOYEE_IDENTITY_REMOVE_REQUESTED,
		image,
		index
	}
}

export const onIdentityRemove = (file, index) => {
	const fetch = axiosClient.post(`/employees/identity-remove`, {file});
	return {
		type: types.EMPLOYEE_IDENTITY_REMOVED,
		payload: fetch,
		index
	}
}

export const onEditIdentityUploadRequest = (image) => {
	return {
		type: types.EMPLOYEE_IDENTITY_UPLOAD_EDIT_REQUESTED,
		image
	}
}


export const onEditIdentityUpload = (file, index, progressCallback) => {
	const fetch = axiosClient.post(`/employees/identity-upload`, {file}, {onUploadProgress: progressCallback});

	return {
		type: types.EMPLOYEE_IDENTITY_EDIT_UPLOADED,
		payload: fetch,
		index
		
	}
	
}

export const onEditIdentityUploading = (image, index) => {
	return {
		type: types.EMPLOYEE_IDENTITY_EDIT_UPLOADING,
		image,
		index	
	}
}

export const onEditIdentityRemoveRequest = (image, index) => {
	return {
		type: types.EMPLOYEE_IDENTITY_REMOVE_EDIT_REQUESTED,
		image,
		index
	}
}

export const onEditIdentityRemove = (file, index) => {
	const fetch = axiosClient.post(`/employees/identity-remove`, {file});
	return {
		type: types.EMPLOYEE_IDENTITY_EDIT_REMOVED,
		payload: fetch,
		index
	}
}


export const onTaxIdentityUploadRequest = (image) => {
	return {
		type: types.EMPLOYEE_TAX_IDENTITY_UPLOAD_REQUESTED,
		image
	}
}


export const onTaxIdentityUpload = (file, index, progressCallback) => {
	const fetch = axiosClient.post(`/employees/tax-identity-upload`, {file}, {onUploadProgress: progressCallback});

	return {
		type: types.EMPLOYEE_TAX_IDENTITY_UPLOADED,
		payload: fetch,
		index
		
	}
	
}

export const onTaxIdentityUploading = (image, index) => {
	return {
		type: types.EMPLOYEE_TAX_IDENTITY_UPLOADING,
		image,
		index	
	}
}

export const onTaxIdentityRemoveRequest = (image, index) => {
	return {
		type: types.EMPLOYEE_TAX_IDENTITY_REMOVE_REQUESTED,
		image,
		index
	}
}

export const onTaxIdentityRemove = (file, index) => {
	const fetch = axiosClient.post(`/employees/tax-identity-remove`, {file});
	return {
		type: types.EMPLOYEE_TAX_IDENTITY_REMOVED,
		payload: fetch,
		index
	}
}


export const onEditTaxIdentityUploadRequest = (image) => {
	return {
		type: types.EMPLOYEE_TAX_IDENTITY_UPLOAD_EDIT_REQUESTED,
		image
	}
}

export const onEditTaxIdentityUpload = (file, index, progressCallback) => {
	const fetch = axiosClient.post(`/employees/tax-identity-upload`, {file}, {onUploadProgress: progressCallback});

	return {
		type: types.EMPLOYEE_TAX_IDENTITY_EDIT_UPLOADED,
		payload: fetch,
		index
		
	}
}

export const onEditTaxIdentityUploading = (image, index) => {
	return {
		type: types.EMPLOYEE_TAX_IDENTITY_EDIT_UPLOADING,
		image,
		index	
	}
}

export const onEditTaxIdentityRemoveRequest = (image, index) => {
	return {
		type: types.EMPLOYEE_TAX_IDENTITY_REMOVE_EDIT_REQUESTED,
		image,
		index
	}
}

export const onEditTaxIdentityRemove = (file, index) => {
	const fetch = axiosClient.post(`/employees/tax-identity-remove`, {file});
	return {
		type: types.EMPLOYEE_TAX_IDENTITY_EDIT_REMOVED,
		payload: fetch,
		index
	}
}
