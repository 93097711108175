import React, { useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { onEdit, onUnload, onShowConfirm} from '../../../../../../actions/storeAdjustmentActions';
import { 
        Typography,
        Box,
        Grid,
        Button
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate, useParams } from "react-router-dom";
import Information from './Information';
// import Product from './Product';
// import Activity from './Activity';
// import Confirm from './Confirm';
import Loader from '../../../../../Helper/Loader';


export default function Edit() { 
    let navigate = useNavigate();
    let { id } = useParams();
    const dispatch = useDispatch();
    const {store_adjustment, errors} = useSelector(state => ({
        ...state.store_adjustment
    }), shallowEqual); 

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
    },[errors]);

    useEffect(() => {
        dispatch(onEdit(id));
        return () => {
            dispatch(onUnload())
        };
    },[dispatch, id]);

    function onSubmit() {
        const { status } = store_adjustment;
        if (status === 'processing') {
            dispatch(onShowConfirm("approved"))
        }
        if (status === 'approved') {
            dispatch(onShowConfirm("received"))
        }
        if (status === 'received') {
            dispatch(onShowConfirm("transfered"))
        }
    }

    if (!store_adjustment) {
        return <Loader/>
          
    }

    const { status } = store_adjustment;

    function Cancel() {
        if (status === 'processing') {
            return (
                <Button 
                    variant="contained"
                    size="large"
                    disableElevation
                    onClick={ () => {dispatch(onShowConfirm("canceled"))}}>
                    Cancel Transfer
                </Button>
            )
        }
        return null;
    }

    return (
        <ContentBox>
            <HeaderBox>
                <Typography fontWeight={700} variant="h6">
                    Detail Transfer
                </Typography>
            </HeaderBox>
            <Information />
            {/* <Product /> */}
            <Box>
                <Grid container sx={{marginBottom: '55px'}} justifyContent="space-between" spacing={3}>
                    <Grid item>
                        {Cancel()}
                    </Grid>
                    <Grid item>
                        <Grid container spacing={3}>
                            <Grid item>
                                <BackButton 
                                    variant="contained"
                                    size="large"
                                    disableElevation
                                    onClick={ () => {navigate(`/dashboard/store-transfers`)}}>
                                    Back
                                </BackButton>
                            </Grid>
                            <Grid item>
                                <Button 
                                    variant="contained"
                                    size="large"
                                    color="primary"
                                    sx={{minWidth: 195}}
                                    onClick={onSubmit}
                                    disabled={status === 'canceled' || status === 'transfered'}
                                    disableElevation>
                                    Save
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>  
            </Box>
            {/* <Activity />
            <Confirm /> */}
        </ContentBox>
    )
}

const ContentBox = styled(Box)(({theme}) => ({
    padding: '25px 0',
    [theme.breakpoints.down('sm')]: {
        padding: '15px 15px 0 15px',
    },
    [theme.breakpoints.down('md')]: {
        padding: '15px 15px 0 15px',
    },
}));

const HeaderBox = styled(Box)(({theme}) => ({
    paddingLeft: 0,
    marginBottom: 25,
    [theme.breakpoints.down('sm')]: {
        paddingLeft: 55,
    },
}));

const BackButton = styled(Button)(({theme}) => ({
    color: theme.palette.text.primary,
    backgroundColor: "#FFFFFF",
    '&:hover': {
    backgroundColor: "#FFFFFF",
    },
    fontWeight: 500,
    border: '1px solid #ddd',
    minWidth: 195
}));
