

import * as types from './actionTypes';
import { axiosClient } from '../api';

export const onLoadCustomerGroups = (query) => {
	const fetch = axiosClient.get(`/customer-groups`,
	{
		params: query
	});
	return {
		type: types.CUSTOMER_GROUPS_LOADED,
		payload: fetch
	}
}

export const onCustomerGroupNew = () => {
	return {
		type: types.NEW_CUSTOMER_GROUP_LOADED
	}
}

export const onCustomerGroupEdit= (id) => {
	const fetch = axiosClient.get(`/customer-groups/${id}/edit`);
	return {
		type: types.EDIT_CUSTOMER_GROUP_LOADED,
		payload: fetch
	}
}

export const onUnloadForm = () => {
	return {
		type: types.CUSTOMER_GROUP_FORM_UNLOADED
	}
}

export const onUpdateCustomerGroup = (customer_group) => {
	return {
		type: types.CUSTOMER_GROUP_UPDATED,
		customer_group
	}
}

export const onCreate = (customer_group) => {
	const fetch = axiosClient.post(`/customer-groups`, {customer_group});
	return {
		type: types.CUSTOMER_GROUP_CREATED, 
		payload: fetch
	}
}

export const onEdit = (id, customer_group) => {
	const fetch = axiosClient.put(`/customer-groups/${id}`, {customer_group});
	return {
		type: types.CUSTOMER_GROUP_EDITED, 
		payload: fetch
	}
}