export const ASYNC_START = 'ASYNC_START';
export const NOTICE_HIDED = 'NOTICE_HIDED';
export const REDIRECT = 'REDIRECT';
export const SET_PAGE = 'SET_PAGE';
export const FORM_VALIDATION = 'FORM_VALIDATION';
export const AREAS_LOADED = 'AREAS_LOADED';
export const AREAS_UNLOADED = 'AREAS_UNLOADED';
export const NAV_OPENED = 'NAV_OPENED';
export const NAV_CLOSED = 'NAV_CLOSED';
export const MOBILE_NAV_OPENED = 'MOBILE_NAV_OPENED';
export const MOBILE_NAV_CLOSED = 'MOBILE_NAV_CLOSED';

// INITIALIZE 
export const INITIALIZE_LOADED = 'INITIALIZE_LOADED';
export const INITIALIZE_UNLOADED = 'INITIALIZE_UNLOADED';
export const USER_INITIALIZED = 'USER_INITIALIZED';
export const USER_UPDATED = 'USER_UPDATED';
export const INITIALIZE_AREAS_LOADED = 'INITIALIZE_AREAS_LOADED';
export const INITIALIZE_AREAS_UNLOADED = 'INITIALIZE_AREAS_UNLOADED';

// USER
export const USER_LOGIN_LOADED = 'USER_LOGIN_LOADED';
export const USER_LOGIN_UNLOADED = 'USER_LOGIN_UNLOADED';
export const USER_LOGIN_FIELD_UPDATED = 'USER_LOGIN_FIELD_UPDATED';
export const USER_LOGIN_EXISTED = 'USER_LOGIN_EXISTED';
export const USER_LOGIN_CANCELED = 'USER_LOGIN_CANCELED';
export const USER_LOGIN_VALIDATION = 'USER_LOGIN_VALIDATION';
export const USER_LOGIN_VALIDATION_FIELD_UPDATED = 'USER_LOGIN_VALIDATION_FIELD_UPDATED';
export const USER_LOGGED_IN = 'USER_LOGGED_IN';
export const USER_LOGGED_OUT = 'USER_LOGGED_OUT';
export const NEW_USER_CANCELED = 'NEW_USER_CANCELED';
export const USER_REGISTER_LOADED = 'USER_REGISTER_LOADED';
export const USER_REGISTER_UNLOADED = 'USER_REGISTER_UNLOADED';
export const USER_REGISTER_FIELD_UPDATED = 'USER_REGISTER_FIELD_UPDATED';
export const USER_REGISTER_VALIDATION_FIELD_UPDATED = 'USER_REGISTER_VALIDATION_FIELD_UPDATED';
export const USER_REGISTER_VALIDATION = 'USER_REGISTER_VALIDATION';
export const NEW_USER_REGISTER_LOADED = 'NEW_USER_REGISTER_LOADED';
export const USER_REGISTERED = 'USER_REGISTERED';
export const USER_RESET_LOADED = 'USER_RESET_LOADED';
export const USER_RESET_UNLOADED = 'USER_RESET_UNLOADED';
export const USER_RESET_VALIDATION_FIELD_UPDATED = 'USER_RESET_VALIDATION_FIELD_UPDATED';
export const USER_RESET_FIELD_UPDATED = 'USER_RESET_FIELD_UPDATED';
export const USER_VERIFICATION_CANCELED = 'USER_VERIFICATION_CANCELED';
export const USER_EMAIL_VERIFICATION = 'USER_EMAIL_VERIFICATION';
export const USER_MESSAGE_VERIFICATION = 'USER_MESSAGE_VERIFICATION';
export const USER_VERIFICATION = 'USER_VERIFICATION';
export const USER_LOADED = 'USER_LOADED';
export const USER_RESET_EDITED = 'USER_RESET_EDITED';
export const USER_RESET_VALIDATION = 'USER_RESET_VALIDATION';


// // CATEGORY
export const CATEGORIES_LOADED = 'CATEGORIES_LOADED';
export const CATEGORIES_SORTED = 'CATEGORIES_SORTED';
export const CATEGORIES_SET_TREE_IDS = 'CATEGORIES_SET_TREE_IDS';
export const NEW_CATEGORY_LOADED = 'NEW_CATEGORY_LOADED';
export const EDIT_CATEGORY_LOADED = 'EDIT_CATEGORY_LOADED';
export const CATEGORY_UPDATED = 'CATEGORY_UPDATED';
export const CATEGORY_CREATED = 'CATEGORY_CREATED';
export const CATEGORY_EDITED = 'CATEGORY_EDITED';
export const CATEGORY_FORM_UNLOADED = 'CATEGORY_FORM_UNLOADED';
export const SET_CATEGORY_PARENT_ID = 'SET_CATEGORY_PARENT_ID';
export const CATEGORY_IMAGE_UPLOAD_REQUESTED = 'CATEGORY_IMAGE_UPLOAD_REQUESTED';
export const CATEGORY_IMAGE_UPLOADED = 'CATEGORY_IMAGE_UPLOADED';
export const CATEGORY_IMAGE_UPLOADING = 'CATEGORY_IMAGE_UPLOADING';
export const CATEGORY_IMAGE_REMOVE_REQUESTED = 'CATEGORY_IMAGE_REMOVE_REQUESTED';
export const CATEGORY_IMAGE_REMOVED = 'CATEGORY_IMAGE_REMOVED';


// PRODUCT
export const PRODUCTS_LOADED = 'PRODUCTS_LOADED';
export const PRODUCTS_UNLOADED = 'PRODUCTS_UNLOADED';
export const NEW_PRODUCT_LOADED = 'NEW_PRODUCT_LOADED';
export const EDIT_PRODUCT_LOADED = 'EDIT_PRODUCT_LOADED';
export const PRODUCT_FORM_UNLOADED = 'PRODUCT_FORM_UNLOADED';
export const PRODUCT_IMAGE_UPLOAD_REQUESTED = 'PRODUCT_IMAGE_UPLOAD_REQUESTED';
export const PRODUCT_IMAGE_UPLOADED = 'PRODUCT_IMAGE_UPLOADED';
export const PRODUCT_IMAGE_UPLOADING = 'PRODUCT_IMAGE_UPLOADING';
export const PRODUCT_IMAGE_REMOVE_REQUESTED = 'PRODUCT_IMAGE_REMOVE_REQUESTED';
export const PRODUCT_IMAGE_REMOVED = 'PRODUCT_IMAGE_REMOVED';
export const PRODUCT_FIELD_UPDATED = 'PRODUCT_FIELD_UPDATED';
export const PRODUCT_NAME_UPDATED = 'PRODUCT_NAME_UPDATED';
export const PRODUCT_CATEGORIES_TOGGLED = 'PRODUCT_CATEGORIES_TOGGLED';
export const PRODUCT_CATEGORIES_SELECTED = 'PRODUCT_CATEGORIES_SELECTED';
export const PRODUCT_SPECIFICATIONS_UPDATED = 'PRODUCT_SPECIFICATION_UPDATED';
export const PRODUCT_OPTIONS_ADDED = 'PRODUCT_OPTIONS_ADDED';
export const PRODUCT_OPTIONS_REMOVED = 'PRODUCT_OPTIONS_REMOVED';
export const PRODUCT_OPTIONS_UPDATED = 'PRODUCT_OPTIONS_UPDATED';
export const PRODUCT_CONFIGURATION_SHOWED = 'PRODUCT_CONFIGURATION_SHOWED';
export const PRODUCT_CONFIGURATION_HIDED = 'PRODUCT_CONFIGURATION_HIDED';
export const CONFIGUATIONS_VALIDATION = 'CONFIGUATIONS_VALIDATION';
export const PRODUCT_CONFIGURATION_EDITED  = 'PRODUCT_CONFIGURATION_EDITED';
export const PRODUCT_CONFIGURATIONS_DONE = 'PRODUCT_CONFIGURATIONS_DONE';
export const PRODUCT_VARIANT_IMAGE_UPLOADED = 'PRODUCT_VARIANT_IMAGE_UPLOADED';
export const PRODUCT_VARIANT_IMAGE_UPLOADING = 'PRODUCT_VARIANT_IMAGE_UPLOADING';
export const PRODUCT_VARIANT_IMAGE_REMOVED = 'PRODUCT_VARIANT_IMAGE_REMOVED';
export const PRODUCT_ADVANCED_PRICE_SHOWED = 'PRODUCT_ADVANCED_PRICE_SHOWED';
export const PRODUCT_ADVANCED_PRICE_HIDED = 'PRODUCT_ADVANCED_PRICE_HIDED';
export const PRICES_VALIDATION = 'PRICES_VALIDATION';
export const PRODUCT_PRICES_LOADED = 'PRODUCT_PRICES_LOADED';
export const PRODUCT_PRICES_DONE = 'PRODUCT_PRICES_DONE';
export const PRODUCT_SKU_TOGGLED = 'PRODUCT_SKU_TOGGLED';
export const PRODUCT_VARIANTS_UPDATED = 'PRODUCT_VARIANTS_UPDATED';
export const PRODUCT_VARIANTS_REMOVED = 'PRODUCT_VARIANTS_REMOVED';
export const PRODUCT_CREATED = 'PRODUCT_CREATED';
export const PRODUCT_EDITED = 'PRODUCT_EDITED';
// export const PRODUCT_EDIT_SHOWED = 'PRODUCT_EDIT_SHOWED';
// export const PRODUCT_EDIT_HIDED = 'PRODUCT_EDIT_HIDED';
export const PRODUCT_CONFIRM_HIDED = 'PRODUCT_CONFIRM_HIDED';
export const PRODUCT_MODIFIER_TOGGLED = 'PRODUCT_MODIFIER_TOGGLED';
export const PRODUCT_MODIFIERS_DELETED = 'PRODUCT_MODIFIERS_DELETED';
export const PRODUCT_MODIFIER_UPDATED = 'PRODUCT_MODIFIER_UPDATED';
export const PRODUCT_IMAGES_UPDATED = 'PRODUCT_IMAGES_UPDATED';


// STORE
export const STORES_LOADED = 'STORES_LOADED';
export const NEW_STORE_LOADED = 'NEW_STORE_LOADED';
export const EDIT_STORE_LOADED = 'EDIT_STORE_LOADED';
export const STORE_UPDATED = 'STORE_UPDATED';
export const STORE_CREATED = 'STORE_CREATED';
export const STORE_EDITED = 'STORE_EDITED';
export const STORE_FORM_UNLOADED = 'STORE_FORM_UNLOADED';
export const STORE_LOADED = 'STORE_LOADED';
export const STORE_UNLOADED = 'STORE_UNLOADED';
export const STORE_INVENTORIES_SHOWED = 'STORE_INVENTORIES_SHOWED';
export const STORE_INVENTORIES_HIDED = 'STORE_INVENTORIES_HIDED';
// export const STORE_PRODUCTS_UPDATED = 'STORE_PRODUCTS_UPDATED';
// export const STORE_LOW_STOCK_UPDATED = 'STORE_LOW_STOCK_UPDATED';


// EMPLOYEE
export const EMPLOYEES_LOADED = 'EMPLOYEES_LOADED';
export const EMPLOYEES_UNLOADED = 'EMPLOYEES_UNLOADED';
export const NEW_EMPLOYEE_LOADED = 'NEW_EMPLOYEE_LOADED';
export const SHOW_EMPLOYEE_LOADED = 'SHOW_EMPLOYEE_LOADED';
export const EDIT_EMPLOYEE_PERSONAL_INFORMATION_LOADED = 'EDIT_EMPLOYEE_PERSONAL_INFORMATION_LOADED';
export const EDIT_EMPLOYEE_FAMILY_LOADED = 'EDIT_EMPLOYEE_FAMILY_LOADED';
export const EDIT_EMPLOYEE_UPDATED = 'EDIT_EMPLOYEE_UPDATED';
export const EMPLOYEE_FAMILIES_UPDATED = 'EMPLOYEE_FAMILIES_UPDATED';
export const EDIT_EMPLOYEE_EMERGENCY_CONTACT_LOADED = 'EDIT_EMPLOYEE_EMERGENCY_CONTACT_LOADED';
export const EMPLOYEE_EMERGENCY_CONTACTS_UPDATED = 'EMPLOYEE_EMERGENCY_CONTACTS_UPDATED';
export const EDIT_EMPLOYMENT_LOADED = 'EDIT_EMPLOYMENT_LOADED';
export const EMPLOYMENT_ORGANIZATION_SELECTED = 'EMPLOYMENT_ORGANIZATION_SELECTED';
export const EMPLOYMENT_JOB_POSITION_SELECTED = 'EMPLOYMENT_JOB_POSITION_SELECTED';
export const EDIT_EMPLOYEE_PAYROLL_LOADED = 'EDIT_EMPLOYEE_PAYROLL_LOADED';
export const EDIT_EMPLOYEE_UNLOADED = 'EDIT_EMPLOYEE_UNLOADED';
export const EMPLOYEE_FORM_UNLOADED = 'EMPLOYEE_FORM_UNLOADED';
export const EMPLOYEE_UPDATED = 'EMPLOYEE_UPDATED';
export const EMPLOYEE_ORGANIZATION_SELECTED = 'EMPLOYEE_ORGANIZATION_SELECTED';
export const EMPLOYEE_JOB_POSITION_SELECTED = 'EMPLOYEE_JOB_POSITION_SELECTED';
export const EMPLOYEE_CREATED = 'EMPLOYEE_CREATED';
export const EMPLOYEE_EDITED = 'EMPLOYEE_EDITED';
export const EMPLOYEE_PIN_SENDED = 'EMPLOYEE_PIN_SENDED'
export const EMPLOYEE_PROFILE_UPLOAD_REQUESTED = 'EMPLOYEE_PROFILE_UPLOAD_REQUESTED';
export const EMPLOYEE_PROFILE_UPLOADING = 'EMPLOYEE_PROFILE_UPLOADING';
export const EMPLOYEE_PROFILE_UPLOADED = 'EMPLOYEE_PROFILE_UPLOADED';
export const EMPLOYEE_PROFILE_REMOVE_REQUESTED = 'EMPLOYEE_PROFILE_REMOVE_REQUESTED';
export const EMPLOYEE_PROFILE_REMOVED = 'EMPLOYEE_PROFILE_REMOVED';
export const EMPLOYEE_PROFILE_UPLOAD_EDIT_REQUESTED = 'EMPLOYEE_PROFILE_UPLOAD_EDIT_REQUESTED';
export const EMPLOYEE_PROFILE_EDIT_UPLOADING = 'EMPLOYEE_PROFILE_EDIT_UPLOADING';
export const EMPLOYEE_PROFILE_EDIT_UPLOADED = 'EMPLOYEE_PROFILE_EDIT_UPLOADED';
export const EMPLOYEE_PROFILE_REMOVE_EDIT_REQUESTED = 'EMPLOYEE_PROFILE_REMOVE_EDIT_REQUESTED';
export const EMPLOYEE_PROFILE_EDIT_REMOVED = 'EMPLOYEE_PROFILE_EDIT_REMOVED';
export const EMPLOYEE_IDENTITY_UPLOAD_REQUESTED = 'EMPLOYEE_IDENTITY_UPLOAD_REQUESTED';
export const EMPLOYEE_IDENTITY_UPLOADING = 'EMPLOYEE_IDENTITY_UPLOADING';
export const EMPLOYEE_IDENTITY_UPLOADED = 'EMPLOYEE_IDENTITY_UPLOADED';
export const EMPLOYEE_IDENTITY_REMOVE_REQUESTED = 'EIDENTITY_PROFILE_REMOVE_REQUESTED';
export const EMPLOYEE_IDENTITY_REMOVED = 'EMPLOYEE_IDENTITY_REMOVED';
export const EMPLOYEE_IDENTITY_UPLOAD_EDIT_REQUESTED = 'EMPLOYEE_IDENTITY_UPLOAD_EDIT_REQUESTED';
export const EMPLOYEE_IDENTITY_EDIT_UPLOADING = 'EMPLOYEE_IDENTITY_EDIT_UPLOADING';
export const EMPLOYEE_IDENTITY_EDIT_UPLOADED = 'EMPLOYEE_IDENTITY_EDIT_UPLOADED';
export const EMPLOYEE_IDENTITY_REMOVE_EDIT_REQUESTED = 'EIDENTITY_PROFILE_REMOVE_EDIT_REQUESTED';
export const EMPLOYEE_IDENTITY_EDIT_REMOVED = 'EMPLOYEE_IDENTITY_EDIT_REMOVED';
export const EMPLOYEE_TAX_IDENTITY_UPLOAD_REQUESTED = 'EMPLOYEE_TAX_IDENTITY_UPLOAD_REQUESTED';
export const EMPLOYEE_TAX_IDENTITY_UPLOADED = 'EMPLOYEE_TAX_IDENTITY_UPLOADED';
export const EMPLOYEE_TAX_IDENTITY_UPLOADING = 'EMPLOYEE_TAX_IDENTITY_UPLOADING';
export const EMPLOYEE_TAX_IDENTITY_REMOVE_REQUESTED = 'EMPLOYEE_TAX_IDENTITY_REMOVE_REQUESTED';
export const EMPLOYEE_TAX_IDENTITY_REMOVED = 'EMPLOYEE_TAX_IDENTITY_REMOVED';
export const EMPLOYEE_TAX_IDENTITY_UPLOAD_EDIT_REQUESTED = 'EMPLOYEE_TAX_IDENTITY_UPLOAD_EDIT_REQUESTED';
export const EMPLOYEE_TAX_IDENTITY_EDIT_UPLOADING = 'EMPLOYEE_TAX_IDENTITY_EDIT_UPLOADING';
export const EMPLOYEE_TAX_IDENTITY_EDIT_UPLOADED = 'EMPLOYEE_TAX_IDENTITY_EDIT_UPLOADED';
export const EMPLOYEE_TAX_IDENTITY_REMOVE_EDIT_REQUESTED = 'EMPLOYEE_TAX_IDENTITY_REMOVE_EDIT_REQUESTED';
export const EMPLOYEE_TAX_IDENTITY_EDIT_REMOVED = 'EMPLOYEE_TAX_IDENTITY_EDIT_REMOVED';

// EMPLOYEE ACCESS
export const EMPLOYEE_ACCESSES_LOADED = 'EMPLOYEE_ACCESSES_LOADED';
export const NEW_EMPLOYEE_ACCESS_LOADED = 'NEW_EMPLOYEE_ACCESS_LOADED';
export const EDIT_EMPLOYEE_ACCESS_LOADED = 'EDIT_EMPLOYEE_ACCESS_LOADED';
export const EMPLOYEE_ACCESS_FIELD_UPDATED = 'EMPLOYEE_ACCESS_FIELD_UPDATED';
export const EMPLOYEE_ACCESS_UPDATED = 'EMPLOYEE_ACCESS_UPDATED';
export const EMPLOYEE_ACCESS_CREATED = 'EMPLOYEE_ACCESS_CREATED';
export const EMPLOYEE_ACCESS_EDITED = 'EMPLOYEE_ACCESS_EDITED';
export const EMPLOYEE_ACCESS_FORM_UNLOADED = 'EMPLOYEE_ACCESS_FORM_UNLOADED';

// EMPLOYEE JOB POSITIONS
export const EMPLOYEE_JOB_POSITIONS_LOADED = 'EMPLOYEE_JOB_POSITIONS_LOADED';
export const NEW_EMPLOYEE_JOB_POSITION_LOADED = 'NEW_EMPLOYEE_JOB_POSITION_LOADED';
export const EMPLOYEE_JOB_POSITION_FIELD_UPDATED = 'EMPLOYEE_JOB_POSITION_FIELD_UPDATED';
export const EDIT_EMPLOYEE_JOB_POSITION_LOADED = 'EDIT_EMPLOYEE_JOB_POSITION_LOADED';
export const EMPLOYEE_JOB_POSITION_CREATED = 'EMPLOYEE_JOB_POSITION_CREATED';
export const EMPLOYEE_JOB_POSITION_EDITED = 'EMPLOYEE_JOB_POSITION_EDITED';
export const EMPLOYEE_JOB_POSITION_FORM_UNLOADED = 'EMPLOYEE_JOB_POSITION_FORM_UNLOADED';
export const EMPLOYEE_JOB_DESCRIPTIONS_UPDATED = 'EMPLOYEE_JOB_DESCRIPTIONS_UPDATED';

// EMPLOYEE ORGANIZATIONS
export const EMPLOYEE_ORGANIZATIONS_LOADED = 'EMPLOYEE_ORGANIZATIONS_LOADED';
export const EMPLOYEE_ORGANIZATION_FORM_UNLOADED = 'EMPLOYEE_ORGANIZATION_FORM_UNLOADED';
export const NEW_EMPLOYEE_ORGANIZATION_LOADED = 'NEW_EMPLOYEE_ORGANIZATION_LOADED';
export const EMPLOYEE_ORGANIZATION_FIELD_UPDATED = 'EMPLOYEE_ORGANIZATION_FIELD_UPDATED';
export const EDIT_EMPLOYEE_ORGANIZATION_LOADED = 'EDIT_EMPLOYEE_ORGANIZATION_LOADED';
// export const SET_EMPLOYEE_ORGANIZATION_PARENT_ID = 'SET_EMPLOYEE_ORGANIZATION_PARENT_ID';
// export const EMPLOYEE_ORGANIZATION_UPDATED = 'EMPLOYEE_ORGANIZATION_UPDATED';
export const EMPLOYEE_ORGANIZATION_CREATED = 'EMPLOYEE_ORGANIZATION_CREATED';
export const EMPLOYEE_ORGANIZATION_EDITED = 'EMPLOYEE_ORGANIZATION_EDITED';
// export const EMPLOYEE_ORGANIZATIONS_SORTED = 'EMPLOYEE_ORGANIZATIONS_SORTED';

// 

// export const USER_CREATED = 'USER_CREATED';
// export const USER_SIGN_UP_LOADED = 'USER_SIGN_UP_LOADED';
// export const USER_SIGN_UP_UNLOADED = 'USER_SIGN_UP_UNLOADED';
// export const USER_ACTIVATED = 'USER_ACTIVATED';
// export const USER_ACTIVATE_UNLOADED = 'USER_ACTIVATE_UNLOADED';
// export const USER_RESET_PASSWORD_CREATED = 'USER_RESET_PASSWORD_CREATED';
// export const USER_FORGOT_PASSWORD_LOADED = 'USER_FORGOT_PASSWORD_LOADED';
// export const USER_FORGOT_PASSWORD_UNLOADED = 'USER_FORGOT_PASSWORD_UNLOADED';
// export const USER_RESET_PASSWORD_LOADED = 'USER_RESET_PASSWORD_LOADED';
// export const USER_RESET_PASSWORD_UNLOADED = 'USER_RESET_PASSWORD_UNLOADED';
// export const USER_RESET_PASSWORD_EDITED = 'USER_RESET_PASSWORD_EDITED';
// export const USER_LOGIN = 'USER_LOGIN';
// export const USER_LOGOUT = 'USER_LOGOUT';
// export const NAV_OPENED = 'NAV_OPENED';
// export const NAV_CLOSED = 'NAV_CLOSED';
// export const USER_CHANGE_PASSWORD_SHOWED = 'USER_CHANGE_PASSWORD_SHOWED';
// export const USER_CHANGE_PASSWORD_HIDED = 'USER_CHANGE_PASSWORD_HIDED';
// export const USER_CHANGE_PASSWORD_LOADED = 'USER_CHANGE_PASSWORD_LOADED';
// export const USER_PASSWORD_EDITED = 'USER_PASSWORD_EDITED';
// export const USER_ACCOUNT_LOADED = 'USER_ACCOUNT_LOADED';
// export const USER_ACCOUNT_UNLOADED = 'USER_ACCOUNT_UNLOADED';
// export const USER_BUSINESS_INFORMATION_LOADED = 'USER_BUSINESS_INFORMATION_LOADED';
// export const USER_BUSINESS_INFORMATION_UNLOADED = 'USER_BUSINESS_INFORMATION_UNLOADED'
// export const USER_CHANGE_INFORMATION_SHOWED = 'USER_CHANGE_INFORMATION_SHOWED';
// export const USER_CHANGE_INFORMATION_HIDED = 'USER_CHANGE_INFORMATION_HIDED';
// export const USER_EDIT_BUSINESS_INFORMATION_LOADED = 'USER_EDIT_BUSINESS_INFORMATION_LOADED';
// export const USER_EDIT_BUSINESS_INFORMATION_UNLOADED = 'USER_EDIT_BUSINESS_INFORMATION_UNLOADED';
// export const USER_BUSINESS_INFORMATION_UPDATED = 'USER_BUSINESS_INFORMATION_UPDATED';
// export const USER_BUSINESS_INFORMATION_EDITED = 'USER_BUSINESS_INFORMATION_EDITED';


// USER CONFIGURATION
export const USER_CONFIGURATION_LOADED = 'USER_CONFIGURATION_LOADED';
export const USER_CONFIGURATION_UPDATED = 'USER_CONFIGURATION_UPDATED';
export const USER_CONFIGURATION_UNLOADED = 'USER_CONFIGURATION_UNLOADED';
export const USER_CONFIGURATION_EDITED = 'USER_CONFIGURATION_EDITED';




// CUSTOMER DISPLAY
export const CUSTOMER_DISPLAYS_LOADED = 'CUSTOMER_DISPLAYS_LOADED';
export const NEW_CUSTOMER_DISPLAY_LOADED = 'NEW_CUSTOMER_DISPLAY_LOADED';
export const EDIT_CUSTOMER_DISPLAY_LOADED = 'EDIT_CUSTOMER_DISPLAY_LOADED';
export const CUSTOMER_DISPLAY_UPDATED = 'CUSTOMER_DISPLAY_UPDATED';
export const CUSTOMER_DISPLAY_CREATED = 'CUSTOMER_DISPLAY_CREATED';
export const CUSTOMER_DISPLAY_EDITED = 'CUSTOMER_DISPLAY_EDITED';
export const CUSTOMER_DISPLAY_FORM_UNLOADED = 'CUSTOMER_DISPLAY_FORM_UNLOADED';
export const CUSTOMER_DISPLAY_UPLOAD_REQUESTED = 'CUSTOMER_DISPLAY_UPLOAD_REQUESTED';
export const CUSTOMER_DISPLAY_UPLOADED = 'CUSTOMER_DISPLAY_UPLOADED';
export const CUSTOMER_DISPLAY_UPLOADING = 'CUSTOMER_DISPLAY_UPLOADING';
export const CUSTOMER_DISPLAY_REMOVE_REQUESTED = 'CUSTOMER_DISPLAY_REMOVE_REQUESTED';
export const CUSTOMER_DISPLAY_REMOVED = 'CUSTOMER_DISPLAY_REMOVED';



// // SUPPLIER
export const SUPPLIERS_LOADED = 'SUPPLIERS_LOADED';
export const NEW_SUPPLIER_LOADED = 'NEW_SUPPLIER_LOADED';
export const EDIT_SUPPLIER_LOADED = 'EDIT_SUPPLIER_LOADED';
export const SUPPLIER_UPDATED = 'SUPPLIER_UPDATED';
export const SUPPLIER_CREATED = 'SUPPLIER_CREATED';
export const SUPPLIER_EDITED = 'SUPPLIER_EDITED';
export const SUPPLIER_FORM_UNLOADED = 'SUPPLIER_FORM_UNLOADED';
export const SUPPLIER_PURCHASE_ORDERS_SHOWED = 'SUPPLIER_PURCHASE_ORDERS_SHOWED';
export const SUPPLIER_PURCHASE_ORDERS_HIDED = 'SUPPLIER_PURCHASE_ORDERS_HIDED';
export const SUPPLIER_PURCHASE_ORDERS_LOADED = 'SUPPLIER_PURCHASE_ORDERS_LOADED';
export const SUPPLIER_PURCHASE_ORDER_PAYMENT_UPDATED = 'SUPPLIER_PURCHASE_ORDER_PAYMENT_UPDATED';
export const SUPPLIER_PAYMENT_SHOWED = 'SUPPLIER_PAYMENT_SHOWED';
export const SUPPLIER_PAYMENT_HIDED = 'SUPPLIER_PAYMENT_HIDED';
export const SUPPLIER_PAYMENT_CREATED ='SUPPLIER_PAYMENT_CREATED';
// export const CURRENT_SUPPLIER_PRODUCTS_LOADED = 'CURRENT_SUPPLIER_PRODUCTS_LOADED';
export const SUPPLIER_LOADED = 'SUPPLIER_LOADED';
export const SUPPLIER_UNLOADED = 'SUPPLIER_UNLOADED';


// PUCHASE ORDER
export const PURCHASE_ORDERS_LOADED = 'PURCHASE_ORDERS_LOADED';
export const NEW_PURCHASE_ORDER_LOADED = 'NEW_PURCHASE_ORDER_LOADED';
export const EDIT_PURCHASE_ORDER_LOADED = 'EDIT_PURCHASE_ORDER_LOADED';
export const PURCHASE_ORDER_PRODUCT_SELECTED = 'PURCHASE_ORDER_PRODUCT_SELECTED';
export const PURCHASE_ORDER_SUPPLIER_PRODUCT_SELECTED = 'PURCHASE_ORDER_SUPPLIER_PRODUCT_SELECTED';
export const STORE_INVENTORIES_LOADED = 'STORE_INVENTORIES_LOADED';
export const STORE_INVENTORIES_HANDLED = 'STORE_INVENTORIES_HANDLED';
export const PURCHASE_ORDER_UPDATED = 'PURCHASE_ORDER_UPDATED';
export const PURCHASE_ORDER_ITEMS_SELECTED = 'PURCHASE_ORDER_ITEMS_SELECTED';
// export const PURCHASE_ORDER_RETURN_ITEMS_SELECTED = 'PURCHASE_ORDER_RETURN_ITEMS_SELECTED';
export const PURCHASE_ORDER_VARIANTS_SHOWED = 'PURCHASE_ORDER_VARIANTS_SHOWED';
export const PURCHASE_ORDER_VARIANTS_HIDED = 'PURCHASE_ORDER_VARIANTS_HIDED';
export const PURCHASE_ORDER_VARIANT_SELECTED = 'PURCHASE_ORDER_VARIANT_SELECTED';
export const PURCHASE_ORDER_PRODUCT_VARIANT_SELECTED = 'PURCHASE_ORDER_PRODUCT_VARIANT_SELECTED';
export const PURCHASE_ORDER_SUPPLIER_VARIANTS_SHOWED = 'PURCHASE_ORDER_SUPPLIER_VARIANTS_SHOWED';
export const RESTORE_PRODUCT_OPTIONS_HANDLED = 'RESTORE_PRODUCT_OPTIONS_HANDLED';
// export const PURCHASE_ORDER_RETURN_VARIANTS_HIDED = 'PURCHASE_ORDER_RETURN_VARIANTS_HIDED';
// export const PURCHASE_ORDER_RETURN_VARIANT_SELECTED = 'PURCHASE_ORDER_RETURN_VARIANT_SELECTED';
// export const PURCHASE_ORDER_RETURN_PRODUCT_VARIANT_SELECTED = 'PURCHASE_ORDER_RETURN_PRODUCT_VARIANT_SELECTED';
// export const RESTORE_RETURN_PRODUCT_OPTIONS_HANDLED = 'RESTORE_RETURN_PRODUCT_OPTIONS_HANDLED';
export const PURCHASE_ORDER_CREATED = 'PURCHASE_ORDER_CREATED';
export const PURCHASE_ORDER_EDITED = 'PURCHASE_ORDER_EDITED';
export const PURCHASE_ORDER_ACTIVITIES_SHOWED = 'PURCHASE_ORDER_ACTIVITIES_SHOWED';
export const PURCHASE_ORDER_ACTIVITIES_HIDED = 'PURCHASE_ORDER_ACTIVITIES_HIDED';
export const PURCHASE_ORDER_UNLOADED = 'PURCHASE_ORDER_UNLOADED';
export const PURCHASE_ORDER_CONFIRM_SHOWED = 'PURCHASE_ORDER_CONFIRM_SHOWED';
export const PURCHASE_ORDER_CONFIRM_HIDED = 'PURCHASE_ORDER_CONFIRM_HIDED';
export const PURCHASE_ORDER_RETURNS_SHOWED = 'PURCHASE_ORDER_RETURNS_SHOWED';
export const PURCHASE_ORDER_RETURNS_HIDED = 'PURCHASE_ORDER_RETURNS_HIDED';
export const PURCHASE_ORDER_PAYMENTS_SHOWED = 'PURCHASE_ORDER_PAYMENTS_SHOWED';
export const PURCHASE_ORDER_PAYMENTS_HIDED = 'PURCHASE_ORDER_PAYMENTS_HIDED';
export const PURCHASE_ORDER_PAYMENTS_COLLAPSED = 'PURCHASE_ORDER_PAYMENTS_COLLAPSED';
export const PURCHASE_ORDER_PAYMENTS_LOADED = 'PURCHASE_ORDER_PAYMENTS_LOADED';

// PUCHASE ORDER RETURN
export const PURCHASE_ORDER_RETURNS_LOADED = 'PURCHASE_ORDER_RETURNS_LOADED';
export const NEW_PURCHASE_ORDER_RETURN_LOADED = 'NEW_PURCHASE_ORDER_RETURN_LOADED';
export const EDIT_PURCHASE_ORDER_RETURN_LOADED = 'EDIT_PURCHASE_ORDER_RETURN_LOADED';
export const PURCHASE_ORDER_RETURN_UNLOADED = 'PURCHASE_ORDER_RETURN_UNLOADED';
export const PURCHASE_ORDER_RETURN_UPDATED = 'PURCHASE_ORDER_RETURN_UPDATED';
export const PURCHASE_ORDER_RETURN_SELECTED = 'PURCHASE_ORDER_RETURN_SELECTED';
export const PURCHASE_ORDER_RETURN_PRODUCT_SELECTED = 'PURCHASE_ORDER_RETURN_PRODUCT_SELECTED';
export const PURCHASE_ORDER_RETURN_ITEMS_SELECTED = 'PURCHASE_ORDER_RETURN_ITEMS_SELECTED';
export const PURCHASE_ORDER_RETURN_VARIANTS_SHOWED = 'PURCHASE_ORDER_RETURN_VARIANTS_SHOWED';
export const PURCHASE_ORDER_RETURN_VARIANTS_HIDED = 'PURCHASE_ORDER_RETURN_VARIANTS_HIDED';
export const PURCHASE_ORDER_RETURN_VARIANT_SELECTED = 'PURCHASE_ORDER_RETURN_VARIANT_SELECTED';
export const PURCHASE_ORDER_RETURN_PRODUCT_VARIANT_SELECTED = 'PURCHASE_ORDER_RETURN_PRODUCT_VARIANT_SELECTED';
export const RESTORE_RETURN_PRODUCT_OPTIONS_HANDLED = 'RESTORE_RETURN_PRODUCT_OPTIONS_HANDLED';
export const PURCHASE_ORDER_RETURN_CREATED = 'PURCHASE_ORDER_RETURN_CREATED';
export const PURCHASE_ORDER_RETURN_EDITED = 'PURCHASE_ORDER_RETURN_EDITED';
export const PURCHASE_ORDER_RETURN_CONFIRM_SHOWED = 'PURCHASE_ORDER_RETURN_CONFIRM_SHOWED';
export const PURCHASE_ORDER_RETURN_CONFIRM_HIDED = 'PURCHASE_ORDER_RETURN_CONFIRM_HIDED';
export const PURCHASE_ORDER_RETURNS_COLLAPSED = 'PURCHASE_ORDER_RETURNS_COLLAPSED';

// INVENTORY
export const INVENTORIES_LOADED = 'INVENTORIES_LOADED';
export const INVENTORIES_COLLAPSED = 'INVENTORIES_COLLAPSED';
export const INVENTORY_HISTORIES_SHOWED = 'INVENTORY_HISTORIES_SHOWED';
export const INVENTORY_HISTORIES_HIDED = 'INVENTORY_HISTORIES_HIDED';
export const INVENTORY_HISTORIES_LOADED = 'INVENTORY_HISTORIES_LOADED';


// // STORE TRANSFER
export const STORE_TRANSFERS_LOADED = 'STORE_TRANSFERS_LOADED';
export const NEW_STORE_TRANSFER_LOADED = 'NEW_STORE_TRANSFER_LOADED';
export const EDIT_STORE_TRANSFER_LOADED = 'EDIT_STORE_TRANSFER_LOADED';
export const STORE_TRANSFER_CREATED = 'STORE_TRANSFER_CREATED';
export const STORE_TRANSFER_EDITED = 'STORE_TRANSFER_EDITED';
export const STORE_TRANSFER_FROM_SELECTED = 'STORE_TRANSFER_FROM_SELECTED';
export const STORE_TRANSFER_TO_SELECTED = 'STORE_TRANSFER_TO_SELECTED';
export const STORE_TRANSFER_UPDATED = 'STORE_TRANSFER_UPDATED';
export const STORE_TRANSFER_ITEMS_SELECTED = 'STORE_TRANSFER_ITEMS_SELECTED';
export const RESTORE_TRANSFER_PRODUCT_OPTIONS_HANDLED = 'RESTORE_TRANSFER_PRODUCT_OPTIONS_HANDLED';
export const STORE_TRANSFER_VARIANTS_SHOWED = 'STORE_TRANSFER_VARIANTS_SHOWED';
export const STORE_TRANSFER_VARIANTS_HIDED = 'STORE_TRANSFER_VARIANTS_HIDED';
export const STORE_TRANSFER_VARIANT_SELECTED = 'STORE_TRANSFER_VARIANT_SELECTED';
export const STORE_TRANSFER_PRODUCT_VARIANT_SELECTED = 'STORE_TRANSFER_PRODUCT_VARIANT_SELECTED';
export const STORE_TRANSFER_UNLOADED = 'STORE_TRANSFER_UNLOADED';
export const STORE_TRANSFER_ACTIVITIES_SHOWED = 'STORE_TRANSFER_ACTIVITIES_SHOWED';
export const STORE_TRANSFER_ACTIVITIES_HIDED = 'STORE_TRANSFER_ACTIVITIES_HEDED';
export const STORE_TRANSFER_SHOWED = 'STORE_TRANSFER_SHOWED';
export const STORE_TRANSFER_HIDED = 'STORE_TRANSFER_HIDED';
export const STORE_TRANSFER_PRODUCT_SELECTED = 'STORE_TRANSFER_PRODUCT_SELECTED';


// // STORE ADJUSTMENT
export const STORE_ADJUSTMENTS_LOADED = 'STORE_ADJUSTMENTS_LOADED';
export const NEW_STORE_ADJUSTMENT_LOADED = 'NEW_STORE_ADJUSTMENT_LOADED';
export const EDIT_STORE_ADJUSTMENT_LOADED = 'EDIT_STORE_ADJUSTMENT_LOADED';
export const STORE_ADJUSTMENT_PRODUCT_SELECTED = 'STORE_ADJUSTMENT_PRODUCT_SELECTED';
export const STORE_ADJUSTMENT_UNLOADED = 'STORE_ADJUSTMENT_UNLOADED';
export const STORE_ADJUSTMENT_INVENTORIES_LOADED = 'STORE_ADJUSTMENT_INVENTORIES_LOADED';
export const STORE_ADJUSTMENT_UPDATED = 'STORE_ADJUSTMENT_UPDATED';
// export const STORE_ADJUSTMENT_INVENTORIES_HANDLED = 'STORE_ADJUSTMENT_INVENTORIES_HANDLED';
export const STORE_ADJUSTMENT_ITEMS_SELECTED = 'STORE_ADJUSTMENT_ITEMS_SELECTED';
export const RESTORE_ADJUSTMENT_PRODUCT_OPTIONS_HANDLED = 'RESTORE_ADJUSTMENT_PRODUCT_OPTIONS_HANDLED';
export const STORE_ADJUSTMENT_VARIANTS_SHOWED = 'STORE_ADJUSTMENT_VARIANTS_SHOWED';
export const STORE_ADJUSTMENT_VARIANTS_HIDED = 'STORE_ADJUSTMENT_VARIANTS_HIDED';
export const STORE_ADJUSTMENT_VARIANT_SELECTED = 'STORE_ADJUSTMENT_VARIANT_SELECTED';
export const STORE_ADJUSTMENT_PRODUCT_VARIANT_SELECTED = 'STORE_ADJUSTMENT_PRODUCT_VARIANT_SELECTED';
export const STORE_ADJUSTMENT_CREATED = 'STORE_ADJUSTMENT_CREATED';
export const STORE_ADJUSTMENT_EDITED = 'STORE_ADJUSTMENT_EDITED';
export const STORE_ADJUSTMENT_ACTIVITIES_SHOWED = 'STORE_ADJUSTMENT_ACTIVITIES_SHOWED';
export const STORE_ADJUSTMENT_ACTIVITIES_HIDED = 'STORE_ADJUSTMENT_ACTIVITIES_HIDED';
export const STORE_ADJUSTMENT_SHOWED = 'STORE_ADJUSTMENT_SHOWED';
export const STORE_ADJUSTMENT_HIDED = 'STORE_ADJUSTMENT_HIDED';


// // DASHBOARD
export const DASHBOARD_LOADED = 'DASHBOARD_LOADED';
export const DASHBOARD_CHART_LOADED = 'DASHBOARD_CHART_LOADED';
// export const DASHBOARD_ACTIVITIES_LOADED = 'DASHBOARD_ACTIVITIES_LOADED';
// // export const DASHBOARD_DAILY_REPORT_LOADED = 'DASHBOARD_DAILY_REPORT_LOADED';
// // export const DASHBOARD_SUMMARY_REPORT_LOADED = 'DASHBOARD_SUMMARY_REPORT_LOADED';
// // export const DASHBOARD_LOW_STOCK_LOADED = 'DASHBOARD_LOW_STOCK_LOADED';
export const DASHBOARD_UNLOADED = 'DASHBOARD_UNLOADED';


// // STORE USER
// export const STORE_USERS_LOADED = 'STORE_USERS_LOADED';
// export const NEW_STORE_USER_LOADED = 'NEW_STORE_USER_LOADED';
// export const EDIT_STORE_USER_LOADED = 'EDIT_STORE_USER_LOADED';
// export const STORE_USER_FORM_UNLOADED = 'STORE_USER_FORM_UNLOADED';
// export const STORE_USER_UPDATED = 'STORE_USER_UPDATED';
// export const STORE_USER_CREATED = 'STORE_USER_CREATED';
// export const STORE_USER_EDITED = 'STORE_USER_EDITED';

// // CUSTOMER GROUP
export const CUSTOMER_GROUPS_LOADED = 'CUSTOMER_GROUPS_LOADED';
export const NEW_CUSTOMER_GROUP_LOADED = 'NEW_CUSTOMER_GROUP_LOADED';
export const EDIT_CUSTOMER_GROUP_LOADED = 'EDIT_CUSTOMER_GROUP_LOADED';
export const CUSTOMER_GROUP_FORM_UNLOADED = 'CUSTOMER_GROUP_FORM_UNLOADED';
export const CUSTOMER_GROUP_UPDATED = 'CUSTOMER_GROUP_UPDATED';
export const CUSTOMER_GROUP_CREATED = 'CUSTOMER_GROUP_CREATED';
export const CUSTOMER_GROUP_EDITED = 'CUSTOMER_GROUP_EDITED';

// CUSTOMER
export const CUSTOMERS_LOADED = 'CUSTOMERS_LOADED';
export const CUSTOMER_LOADED = 'CUSTOMER_LOADED';
export const CUSTOMER_UNLOADED = 'CUSTOMER_UNLOADED';
export const CUSTOMER_ORDERS_SHOWED = 'CUSTOMER_ORDERS_SHOWED';
export const CUSTOMER_ORDERS_HIDED = 'CUSTOMER_ORDERS_HIDED';
export const CUSTOMER_ORDERS_LOADED = 'CUSTOMER_ORDERS_LOADED';
export const CUSTOMER_ORDER_ITEMS_LOADED = 'CUSTOMER_ORDER_ITEMS_LOADED';
export const CUSTOMER_EDITED = 'CUSTOMER_EDITED';
export const CUSTOMER_ADDRESSES_SHOWED = 'CUSTOMER_ADDRESSES_SHOWED';
export const CUSTOMER_ADDRESSES_HIDED = 'CUSTOMER_ADDRESSES_HIDED';
export const CUSTOMER_ADDRESSES_LOADED = 'CUSTOMER_ADDRESSES_LOADED';
export const CUSTOMER_ADDRESSES_UNLOADED = 'CUSTOMER_ADDRESSES_UNLOADED';
export const NEW_CUSTOMER_ADDRESS_LOADED = 'NEW_CUSTOMER_ADDRESS_LOADED';
export const EDIT_CUSTOMER_ADDRESS_LOADED = 'EDIT_CUSTOMER_ADDRESS_LOADED';
export const CUSTOMER_ADDRESSES_FORM_CANCELED = 'CUSTOMER_ADDRESSES_FORM_CANCELED';
export const CUSTOMER_ADDRESS_UPDATED = 'CUSTOMER_ADDRESS_UPDATED';
export const CUSTOMER_ADDRESS_CREATED = 'CUSTOMER_ADDRESS_CREATED';
export const CUSTOMER_ADDRESS_EDITED = 'CUSTOMER_ADDRESS_EDITED';
export const CUSTOMER_EDIT_SHOWED = 'CUSTOMER_EDIT_SHOWED';
export const CUSTOMER_EDIT_HIDED = 'CUSTOMER_EDIT_HIDED';
export const CUSTOMER_EDIT_LOADED = 'CUSTOMER_EDIT_LOADED';
export const EDIT_CUSTOMER_UPDATED = 'EDIT_CUSTOMER_UPDATED';

// // BILLING
// export const BILLINGS_LOADED = 'BILLINGS_LOADED';
// export const BILLING_STORES_LOADED = 'BILLING_STORES_LOADED';
// export const BILLINGS_UPDATED = 'BILLINGS_UPDATED';
// export const BILLING_UPDATED = 'BILLING_UPDATED';
// export const BILLING_CREATED = 'BILLING_CREATED';


// ORDER
export const ORDERS_LOADED = 'ORDERS_LOADED';
export const ORDER_LOADED = 'ORDER_LOADED';
export const ORDER_UNLOADED = 'ORDER_UNLOADED';
export const ORDER_PAYMENTS_SHOWED = 'ORDER_PAYMENTS_SHOWED';
export const ORDER_PAYMENTS_HIDED = 'ORDER_PAYMENTS_HIDED';
export const ORDER_PAYMENTS_COLLAPSED = 'ORDER_PAYMENTS_COLLAPSED';
export const ORDER_PAYMENTS_LOADED = 'ORDER_PAYMENTS_LOADED';
export const ORDER_CANCELEDS_SHOWED = 'ORDER_CANCELEDS_SHOWED';
export const ORDER_CANCELEDS_HIDED = 'ORDERCANCELEDS_HIDED';
export const ORDER_CANCELEDS_COLLAPSED = 'ORDER_CANCELEDS_COLLAPSED';
// export const ORDER_CANCELEDS_LOADED = 'ORDER_CANCELEDS_LOADED';
export const ORDER_DELIVERIES_SHOWED = 'ORDER_DELIVERIES_SHOWED';
export const ORDER_DELIVERIES_HIDED = 'ORDER_DELIVERIES_HIDED';
export const ORDER_DELIVERIES_COLLAPSED = 'ORDER_DELIVERIES_COLLAPSED';
export const ORDER_DELIVERIES_LOADED = 'ORDER_DELIVERIES_LOADED';

// ORDER CANCELED
export const ORDER_CANCELEDS_LOADED = 'ORDER_CANCELEDS_LOADED';
export const ORDER_CANCELED_LOADED = 'ORDER_CANCELED_LOADED';
export const ORDER_CANCELED_UNLOADED = 'ORDER_CANCELED_UNLOADED';
export const ORDER_CANCELED_UPDATED = 'ORDER_CANCELED_UPDATED';
export const ORDER_CANCELED_EDITED = 'ORDER_CANCELED_EDITED';
export const ORDER_CANCELED_CONFIRM_SHOWED = 'ORDER_CANCELED_CONFIRM_SHOWED';
export const ORDER_CANCELED_CONFIRM_HIDED = 'ORDER_CANCELED_CONFIRM_HIDED';

// ORDER DELIVERY
export const ORDER_DELIVERY_LOADED = 'ORDER_DELIVERY_LOADED';
export const ORDER_DELIVERY_UNLOADED = 'ORDER_DELIVERY_UNLOADED';
export const ORDER_DELIVERY_UPDATED = 'ORDER_DELIVERY_UPDATED';
export const ORDER_DELIVERY_EDITED = 'ORDER_DELIVERY_EDITED';
export const ORDER_DELIVERY_CONFIRM_SHOWED = 'ORDER_DELIVERY_CONFIRM_SHOWED';
export const ORDER_DELIVERY_CONFIRM_HIDED = 'ORDER_DELIVERY_CONFIRM_HIDED';

// ORDER DELIVERY RETURN
export const ORDER_DELIVERY_RETURNS_LOADED = 'ORDER_DELIVERY_RETURNS_LOADED';
export const ORDER_DELIVERY_RETURN_LOADED = 'ORDER_DELIVERY_RETURN_LOADED';
export const ORDER_DELIVERY_RETURN_UNLOADED = 'ORDER_DELIVERY_RETURN_UNLOADED';
export const ORDER_DELIVERY_RETURN_UPDATED = 'ORDER_DELIVERY_RETURN_UPDATED';
export const ORDER_DELIVERY_RETURN_EDITED = 'ORDER_DELIVERY_RETURN_EDITED';
export const ORDER_DELIVERY_RETURN_CONFIRM_SHOWED = 'ORDER_DELIVERY_RETURN_CONFIRM_SHOWED';
export const ORDER_DELIVERY_RETURN_CONFIRM_HIDED = 'ORDER_DELIVERY_RETURN_CONFIRM_HIDED';

// // ORDER BULK

// export const ORDER_BULK_LOADED = 'ORDER_BULK_LOADED';
// export const ORDER_BULK_SHOWED = 'ORDER_BULK_SHOWED';
// export const ORDER_BULK_HIDED = 'ORDER_BULK_HIDED';


// // STORE ACTIVITY
// export const STORE_ACTIVITIES_LOADED = 'STORE_ACTIVITIES_LOADED';

// // STORE SHIFT
// export const STORE_POS_SHIFTS_LOADED = 'STORE_POS_SHIFTS_LOADED';
// export const STORE_POS_SHIFT_LOADED = 'STORE_POS_SHIFT_LOADED';
// export const SHIFT_SOLD_ITEMS_SHOWED = 'SHIFT_SOLD_ITEMS_SHOWED';
// export const SHIFT_SOLD_ITEMS_HIDED = 'SHIFT_SOLD_ITEMS_HIDED';
// export const SHIFT_CANCELED_ITEMS_SHOWED = 'SHIFT_CANCELED_ITEMS_SHOWED';
// export const SHIFT_CANCELED_ITEMS_HIDED = 'SHIFT_CANCELED_ITEMS_HIDED';
// export const SHIFT_JOURNALS_SHOWED = 'SHIFT_JOURNALS_SHOWED';
// export const SHIFT_JOURNALS_HIDED = 'SHIFT_JOURNALS_HIDED';