import * as types from './actionTypes';
import { axiosClient } from '../api';
import { validate } from '../components/Helper/Validation';

export const onLoadEmployeeAccesses = (query) => {
	const fetch = axiosClient.get(`/employee-accesses`,
	{
		params: query
	});
	return {
		type: types.EMPLOYEE_ACCESSES_LOADED,
		payload: fetch
	}
}

export const onEmployeeAccessNew = () => {
	return {
		type: types.NEW_EMPLOYEE_ACCESS_LOADED
	}
}

export const onEmployeeAccessEdit = (id) => {
	const fetch = axiosClient.get(`/employee-accesses/${id}/edit`);
	return {
		type: types.EDIT_EMPLOYEE_ACCESS_LOADED,
		payload: fetch
	}
}

export const onUpdateField = (key, value) => {
	return {
        type: types.EMPLOYEE_ACCESS_FIELD_UPDATED,
        key,
        value
	}
}

export const onUpdateEmployeeAccess = (employee_access) => {
	return {
		type: types.EMPLOYEE_ACCESS_UPDATED,
		employee_access
	}
}


export const onCreate = (employee_access) => {
    // const { errors, error_notice, isValid } = validate(employee_access, 'employee_access')
	// if (isValid) {
        const fetch = axiosClient.post(`/employee-accesses`, {employee_access});
        return {
            type: types.EMPLOYEE_ACCESS_CREATED, 
            payload: fetch
        }
	// } else {
	// 	return {
	// 		type: types.FORM_VALIDATION,
	// 		errors,
    //         error_notice
	// 	}
	// }
}



export const onEdit = (id, employee_access) => {
	const fetch = axiosClient.put(`/employee-accesses/${id}`, {employee_access});
	return {
		type: types.EMPLOYEE_ACCESS_EDITED, 
		payload: fetch
	}
}

export const onUnloadForm = () => {
	return {
		type: types.EMPLOYEE_ACCESS_FORM_UNLOADED
	}
}