import React, { useEffect} from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import * as productActions from '../../../../../../../actions/productActions';
import { 
        Typography,
        Box,
        Grid,
        Divider,
        List,
        ListItemButton,
        ListItemText,
        Collapse,
        IconButton,
        ClickAwayListener
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { KeyboardArrowDown, KeyboardArrowUp, KeyboardArrowRightTwoTone } from '@mui/icons-material';

export default function Category() {
    const theme = useTheme();
    const dispatch = useDispatch();
    const {product, categories, onCategories, errors} = useSelector(state => ({
        ...state.product
    }), shallowEqual); 
    const [selectableCategories, setSelectableCategories] = React.useState([]);
    const [selectCategoryIds, setSelectCategoryIds] = React.useState([]);

    useEffect(() => {
        setSelectCategoryIds(product.category_ids)
        const newCategories = [...categories];
        product.category_ids.map((id, i) => {
            if (categories[i]) {
                const selectCategory = categories[i].children.find(c => c._id === id);
                if (selectCategory && selectCategory.children) {
                    newCategories.push({children: selectCategory.children});
                }
            }
        })
        
        setSelectableCategories(newCategories);
    },[]);

    function onSelectCategory(child, index, event) {
        const newCategoryIds = [...selectCategoryIds];
        const newCategories = [...selectableCategories];
        newCategoryIds.splice(index, selectCategoryIds.length + 1)
        newCategoryIds.push(child._id);
        setSelectCategoryIds(newCategoryIds);
        newCategories.splice(index + 1, newCategories.length + 1);
        if (child.children) {
            newCategories.push({children: child.children});
            setSelectableCategories(newCategories);
        } else {
            setSelectableCategories(newCategories);
            dispatch(productActions.onSelectedCategory(newCategoryIds, newCategories));
        }
    }
    
    function onClick(event) {
        dispatch(productActions.onToggleCategories(!onCategories));
    };
    
    function onClose() {
        dispatch(productActions.onToggleCategories(false));
    };

    let selectedName = "";
        
    if (onCategories) {
        selectCategoryIds.map((id, i) => {
            const selectCategory = selectableCategories[i].children.find(c => c._id === id);
            if (selectCategory) {
                selectedName += selectCategory.name;
                if (i !== selectCategoryIds.length - 1) {
                    selectedName += " > "
                }
            }
        })
    } else {
        if (selectableCategories.length === 0 ){
            return;
        }
        product.category_ids.map((id, i) => {
            const selectCategory = selectableCategories[i].children.find(c => c._id === id);
            if (selectCategory) {
                selectedName += selectCategory.name;
                if (i !== product.category_ids.length - 1) {
                    selectedName += " > "
                }
            }
        })
    }
  
    function error(key) {
        if (errors != null && errors[`product.${key}`] != null) {
            return errors[`product.${key}`].msg
        }
        return null;
    }
    
    return (
        
        <Grid item container spacing={8}>
            <Grid item xs={4} container direction="column">
                <Typography fontSize={16} fontWeight={700} component="div">
                    Kategori Produk
                </Typography>
                <Typography variant="body2" gutterBottom color="textSecondary">
                    Pilih kategori yang sesuai untuk produk Anda.
                </Typography>
            </Grid>
            <Grid item xs={8}>
                <Box sx={{
                    borderRadius: '4px', 
                    borderTopLeftRadius: 4,
                    borderTopRightRadius: 4,
                    borderBottomLeftRadius: onCategories ? 0 : 4,
                    borderBottomRightRadius: onCategories ? 0 : 4,
                    lineHeight: '1.4375em', 
                    position: 'relative',
                    borderColor: !!error(`category_ids`) ? '#d32f2f' : 'rgba(0, 0, 0, 0.23)',
                    borderStyle: 'solid',
                    borderWidth: '1px'}}>
                        <Grid sx={{cursor: 'pointer'}} container onClick={onClick} direction="column">
                            <Grid item container spacing={6} alignItems="center">
                                <Grid xs item>
                                    <Typography sx={{
                                        font: 'inherit', 
                                        padding: '16.5px 14px',
                                        height: '1.4375em', 
                                        color: 'currentColor'
                                        }} variant="body1">
                                        {selectedName === "" ? "Nama Kategori" : selectedName}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <IconButton
                                        aria-label="expand row"
                                        size="small"
                                        sx={{padding: '0px 14px'}}
                                        disabled >
                                        {onCategories ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Collapse in={onCategories} sx={{
                                position: 'absolute',
                                background: '#fff',
                                width: '100%',
                                left: -1,
                                zIndex: 99,
                                borderBottomLeftRadius: 4,
                                borderBottomRightRadius: 4,
                                borderColor: !!error(`category_ids`) ? '#d32f2f' : 'rgba(0, 0, 0, 0.23)',
                                borderStyle: 'solid',
                                borderWidth: '1px'
                            }}
                            timeout="auto" unmountOnExit>
                            <ClickAwayListener onClickAway={onClose}>
                                <Grid container sx={{overflow: 'hidden'}}>
                                    {
                                        selectableCategories.map((c, i) => {
                                            return (
                                                <Grid key={i} item xs container>
                                                    <Grid item xs>
                                                        <List sx={{
                                                            maxHeight: 300,
                                                            padding: 0,
                                                            height: '100%',
                                                            overflow: "hidden scroll",
                                                            }}>
                                                            {
                                                                c.children.map((child, childIndex) => {
                                                                    return (
                                                                        <ListItemButton sx={selectCategoryIds[i] === child._id ? {borderColor: theme.palette.primary.main, borderLeftWidth: 3, borderStyle: 'solid', borderTopRightRadius: 4, borderBottomRightRadius: 4} : null} key={childIndex} onClick={onSelectCategory.bind(this, child, i)}>
                                                                            <ListItemText primary={child.name} />
                                                                            {child.children ? <KeyboardArrowRightTwoTone /> : null}
                                                                        </ListItemButton>
                                                                    )
                                                                })
                                                            }
                                                        </List>
                                                    </Grid>
                                                    <Grid item>
                                                        <Divider sx={{height: i !== selectableCategories.length - 1 ? '100%' : '0%' }} orientation="vertical" flexItem />
                                                    </Grid>
                                                </Grid>
                                            )
                                        })
                                    }
                                </Grid>
                            </ClickAwayListener>
                        </Collapse>
                </Box>
            </Grid> 
        </Grid>
    )
}
