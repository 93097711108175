import React, { useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import * as employeeActions from '../../../../../../actions/employeeActions';
import { 
    Paper,
    Grid,
    Typography,
    Box,
    TextField,
    FormControl,
    FormHelperText,
    IconButton,
    RadioGroup,
    Radio,
    FormControlLabel,
    InputAdornment,
    Alert,
    Autocomplete,
    CircularProgress,
    LinearProgress
} from '@mui/material';
import { styled } from '@mui/material/styles';
import NumberFormat from 'react-number-format';
import {ImageRounded, DeleteForeverRounded} from '@mui/icons-material';

const CurrencyFormat = React.forwardRef((props, ref) => (
    <NumberFormat
        {...props.other}
        onValueChange={(values) => {
          props.onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        fullWidth={props.fullWidth}
        customInput={TextField}
        InputProps={{
            startAdornment: <InputAdornment position="start">
                <Box fontWeight={700}>
                    Rp
                </Box>
            </InputAdornment>,
        }}
        placeholder="0"
        helperText={props.helperText}
        error={props.error}
        value={props.value}
        thousandSeparator={"."}
        decimalSeparator={","}
        allowNegative={false}
        decimalScale={0}
        isNumericString
      />
));


const CustomFormat = React.forwardRef((props, ref) => (
    <NumberFormat
        {...props.other}
        onValueChange={(values) => {
          props.onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        fullWidth={props.fullWidth}
        customInput={TextField}
        placeholder={props.placeholder}
        value={props.value}
        helperText={props.helperText}
        error={props.error}
        allowLeadingZeros={true}
        allowNegative={false}
        decimalSeparator={false}
        allowedDecimalSeparators={false}
        isNumericString
      />
));
export default function Payroll() {
    const dispatch = useDispatch();
    const {employee, salary_types, tax_statuses, bank_options, errors} = useSelector(state => ({
        ...state.employee
    }), shallowEqual); 
    
    useEffect(() => {
        if (employee && employee.employee_payroll.employee_tax_identities) {
            employee_payroll.employee_tax_identities.map((image, index) => {
                if (image.onUpload && !image.uploading) {
                    dispatch(employeeActions.onTaxIdentityUpload(image, index, (event) => {
                        image.progress = Math.round((event.loaded * 100) / event.total);
                        image.uploading = true;
                        dispatch(employeeActions.onTaxIdentityUploading(image, index))
                    }));
                }
            })

            employee_payroll.employee_tax_identities.map((image, index) => {
                if (image.onRemove && !image._destroy) {
                    dispatch(employeeActions.onTaxIdentityRemove(image, index));
                }                
            })
        }

    },[employee]);

    function onUpdateField(event) {
		const key = event.target.name;
        const value = event.target.value;
        const newEmployee = {...employee};
        newEmployee.employee_payroll[key] = value;
        dispatch(employeeActions.onUpdateEmployee(newEmployee));
    }


    function onSelectTaxStatus(option) {
        const newEmployee = {...employee};
        newEmployee.employee_payroll.tax_status_id = option._id;
        dispatch(employeeActions.onUpdateEmployee(newEmployee));
    }


    function onSelectBank(option) {
        const newEmployee = {...employee};
        newEmployee.employee_payroll.bank_option_id = option._id;
        dispatch(employeeActions.onUpdateEmployee(newEmployee));
    }


    function onSelectImage(event) {
        event.preventDefault();
        const files = event.target.files;
        
        let file = files[0]
        const reader = new FileReader();
        reader.onloadend = () => {
            dispatch(employeeActions.onTaxIdentityUploadRequest(reader.result));
        }
        reader.readAsDataURL(file);
    }


    function onRemoveImage(image, index, event) {
        event.preventDefault();
        dispatch(employeeActions.onTaxIdentityRemoveRequest(image, index));
    }

    function error(key) {
        if (errors != null && errors[key] != null) {
            return errors[key][0]
        }

        return null;
    }

    const { employee_payroll } = employee;
    const { basic_salary, tax_account_number, bank_account_number, bank_account_holder, employee_tax_identities, salary_type_id, tax_status_id, bank_option_id } = employee_payroll;

    const taxStatusValue = tax_statuses.find(opt => opt._id === tax_status_id);
    const bankValue = bank_options.find(opt => opt._id === bank_option_id);
    return (
        <Grid container spacing={6} direction="column">
            <Grid item container>
                <Grid item xs>
                    <Typography fontWeight={700} variant="h6" lineheight="normal">
                        Informasi Pendapatan
                    </Typography>
                </Grid>
                <Grid item>
                    <Alert severity="error">
                        Wajib
                    </Alert>
                </Grid>
            </Grid>
            <Grid item container direction="column" spacing={5}>
                <Grid item container spacing={8}>
                    <Grid item xs={4}>
                        <Typography fontSize={16} fontWeight={700} component="div">
                            Gaji Pokok
                        </Typography>
                        <Typography variant="body2" gutterBottom color="textSecondary">
                            Masukan gaji pokok karyawan.
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <CurrencyFormat
                            error={!!error('employee_payroll.basic_salary')}
                            name="basic_salary"
                            placeholder="Gaji Pokok"
                            value={basic_salary}
                            helperText={error('employee_payroll.basic_salary')}
                            variant="outlined"
                            onChange={onUpdateField}
                        />
                    </Grid> 
                </Grid>
                <Grid item container spacing={8}>
                    <Grid item xs={4}>
                        <Typography fontSize={16} fontWeight={700} component="div">
                            Jenis Gaji
                        </Typography>
                        <Typography variant="body2" gutterBottom color="textSecondary">
                            Pilih jenis pembayaran gaji karyawan.
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <FormControl component="fieldset" error={!!error('employee_payroll.salary_type_id')}>
                            <RadioGroup row name="salary_type_id" value={salary_type_id} onChange={onUpdateField}>
                                {
                                    salary_types.map((option, index) => {
                                        return (
                                            <FormControlLabel key={index} value={option._id} control={<Radio />} label={option.name} />
                                        )
                                    })
                                }
                            </RadioGroup>
                            {!!error('employee_payroll.salary_type_id') && <FormHelperText>{error('employee_payroll.salary_type_id')}</FormHelperText> }
                        </FormControl>
                    </Grid> 
                </Grid>
                <Grid item container spacing={8}>
                    <Grid item xs={4}>
                        <Typography fontSize={16} fontWeight={700} component="div">
                            Foto NPWP
                        </Typography>
                        <Typography variant="body2" gutterBottom color="textSecondary">
                            Masukan foto NPWP karyawan.
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        { 
                            employee_tax_identities.map((identity, index) => {
                                if (identity._destroy) {
                                    return null;
                                }
                                if (identity.image && identity.onUpload && identity.progress !== 100) {
                                    return (
                                        <ImageAttachedPaper key={index} >
                                            <ImagePreviewBox>
                                                <Box component="img" alt={identity.image} src={identity.image} />
                                                <Box sx={{ width: '80%', position: 'absolute', bottom: '15px' }}>
                                                    <LinearProgress sx={{height: '8px', borderRadius: '35px'}} variant="determinate" value={identity.progress || 0} />
                                                </Box>
                                            </ImagePreviewBox>
                                        </ImageAttachedPaper>
                                    )
                                }
                                if (identity.image && identity.onRemove) {
                                    return (
                                        <ImageAttachedPaper key={index} >
                                            <ImagePreviewBox>
                                                <Box component="img" alt={identity.image} src={identity.image} />
                                                <Box sx={{
                                                    position: 'absolute', 
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    width: '100%',
                                                    height: '100%'}}>
                                                    <Box sx={{
                                                        position: 'absolute',
                                                        background: '#fff',
                                                        opacity: 0.45,
                                                        width: '100%',
                                                        height: '100%'
                                                     }} />
                                                    <Box sx={{ display: 'flex' }}>
                                                        <CircularProgress />
                                                    </Box>
                                                </Box>
                                            </ImagePreviewBox>
                                        </ImageAttachedPaper>
                                    )
                                }
                                if (identity.image) {
                                    return (
                                        <ImageAttachedPaper key={index} >
                                            <ImagePreviewBox>
                                                <DeleteIconButton size="small" 
                                                    color="inherit" aria-label="menu" onClick={onRemoveImage.bind(this, identity, index)} >
                                                    <DeleteForeverRounded style={{ color: '#999' }} />
                                                </DeleteIconButton>
                                                <Box component="img" alt={identity.image} src={identity.image} />
                                            </ImagePreviewBox>
                                        </ImageAttachedPaper>
                                    )
                                }
                                return (
                                    <ImagePaper key={index}>
                                        <ImageEmptyBox error={!!error('employee_payroll.employee_tax_identities.image')}>
                                            <ImageRounded
                                                style={{ color: '#999', fontSize: 50 }} />
                                                <input type="file" multiple={true} accept="image/*" onChange={onSelectImage.bind(this)}/>
                                        </ImageEmptyBox>
                                    </ImagePaper>
                                )
                            })
                        }
                    </Grid> 
                </Grid>
                <Grid item container spacing={8}>
                    <Grid item xs={4}>
                        <Typography fontSize={16} fontWeight={700} component="div">
                            NPWP
                        </Typography>
                        <Typography variant="body2" gutterBottom color="textSecondary">
                            Masukan No. NPWP karyawan.
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <CustomFormat
                            fullWidth
                            error={!!error('employee_payroll.tax_account_number')}
                            name="tax_account_number"
                            placeholder="NPWP"
                            value={tax_account_number}
                            helperText={error('employee_payroll.tax_account_number')}
                            variant="outlined"
                            onChange={onUpdateField}
                        />
                    </Grid> 
                </Grid>
                <Grid item container spacing={8}>
                    <Grid item xs={4}>
                        <Typography fontSize={16} fontWeight={700} component="div">
                            PTKP Status
                        </Typography>
                        <Typography variant="body2" gutterBottom color="textSecondary">
                            Pilih status PTKP karyawan.
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Autocomplete
                            options={tax_statuses.sort((a, b) => -b.name.localeCompare(a.name))}
                            groupBy={(option) => option.firstLetter}
                            getOptionLabel={(option) => option.name}
                            blurOnSelect
                            filterSelectedOptions
                            disableClearable
                            onChange={(event, option) => {
                                onSelectTaxStatus(option)
                            }}
                            value={taxStatusValue ? taxStatusValue : null}
                            renderInput={(params) => <TextField {...params} error={!!error('employee_payroll.tax_status_id')} helperText={error('employee_payroll.tax_status_id')} placeholder="Pilih Status Pajak" variant="outlined" />}
                        />
                    </Grid> 
                </Grid>
                <Grid item container spacing={8}>
                    <Grid item xs={4}>
                        <Typography fontSize={16} fontWeight={700} component="div">
                            Nama Bank
                        </Typography>
                        <Typography variant="body2" gutterBottom color="textSecondary">
                            Pilih bank yang digunakan karyawan.
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Autocomplete
                            options={bank_options.sort((a, b) => -b.name.localeCompare(a.name))}
                            getOptionLabel={(option) => option.name}
                            blurOnSelect
                            filterSelectedOptions
                            disableClearable
                            onChange={(event, option) => {
                                onSelectBank(option)
                            }}
                            value={bankValue ? bankValue : null}
                            renderInput={(params) => <TextField {...params} error={!!error('employee_payroll.bank_option_id')} helperText={error('employee_payroll.bank_option_id')} placeholder="Pilih Bank" variant="outlined" />}
                        />
                    </Grid> 
                </Grid>
                <Grid item container spacing={8}>
                    <Grid item xs={4}>
                        <Typography fontSize={16} fontWeight={700} component="div">
                            No. Rekening Bank
                        </Typography>
                        <Typography variant="body2" gutterBottom color="textSecondary">
                            Masukan No. Rekening bank karyawan.
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <CustomFormat
                            fullWidth
                            error={!!error('employee_payroll.bank_account_number')}
                            name="bank_account_number"
                            placeholder="No. Rekening"
                            value={bank_account_number}
                            helperText={error('employee_payroll.bank_account_number')}
                            variant="outlined"
                            onChange={onUpdateField}
                        />
                    </Grid> 
                </Grid>
                <Grid item container spacing={8}>
                    <Grid item xs={4}>
                        <Typography fontSize={16} fontWeight={700} component="div">
                            Pemilik Rekening Bank
                        </Typography>
                        <Typography variant="body2" gutterBottom color="textSecondary">
                            Masukan nama pemilik bank.
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <TextField
                            fullWidth
                            error={!!error('employee_payroll.bank_account_holder')}
                            name="bank_account_holder"
                            placeholder="Nama Pemilik"
                            value={bank_account_holder}
                            helperText={error('employee_payroll.bank_account_holder')}
                            variant="outlined"
                            onChange={onUpdateField}
                        />
                    </Grid> 
                </Grid>
            </Grid>
        </Grid>
    )
}


const ImageAttachedPaper = styled(Paper)(({theme}) => ({
    width: 141,
    height: 141,
    padding: 3,
    position: 'relative',
    '&:hover': {
        "& button": {
            visibility: 'visible'
        }
    }
}));

const ImagePaper = styled(Paper)(({theme}) => ({
    padding: 10,
    width: 127,
    height: 127,
    position: 'relative'
}));

const DeleteIconButton = styled(IconButton)(({theme}) => ({
    background: '#ddd',
    position: 'absolute',
    right: 10,
    top: 10,
    width: 30,
    height: 30,
    visibility: 'hidden'
}));
  

const ImagePreviewBox = styled(Box)(({theme}) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    '& img': {
        width: '100%'
    }
}));

const ImageEmptyBox = styled(Box, {
    shouldForwardProp: (prop) => prop !== "error",
  })(({ theme, error }) => ({
    border: error ? '1px dashed #f44336' : '1px dashed #999',
    height: 125,
    width: 125,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 3,
    position: 'relative',
    "& input[type='file']": {
        bottom: 0,
        cursor: 'pointer',
        fontSize: '10em',
        height: '100%',
        left: 0,
        opacity: 0,
        position: 'absolute',
        right: 0,
        top: 0,
        width: '100%',
        zIndex: 3
    },

}));