import { applyMiddleware, createStore, combineReducers } from 'redux';
import { promiseMiddleware, localStorageMiddleware } from './middleware';
import { persistReducer, persistStore} from 'redux-persist';
import storage from 'redux-persist/lib/storage' 
import category from './reducers/category';
import customer_group from './reducers/customer_group';
import customer from './reducers/customer';
import common from './reducers/common';
import dashboard from './reducers/dashboard';
import login from './reducers/login';
import initialize from './reducers/initialize';
import inventory from './reducers/inventory';
import order from './reducers/order';
import order_canceled from './reducers/order_canceled';
import order_delivery from './reducers/order_delivery';
import order_delivery_return from './reducers/order_delivery_return';
import outlet from './reducers/outlet';
import product from './reducers/product';
import purchase_order from './reducers/purchase_order';
import purchase_order_return from './reducers/purchase_order_return';
import register from './reducers/register';
import reset from './reducers/reset';
import store_adjustment from './reducers/store_adjustment';
import store_transfer from './reducers/store_transfer';
import employee from './reducers/employee';
import employee_access from './reducers/employee_access';
import employee_job_position from './reducers/employee_job_position';
import employee_organization from './reducers/employee_organization';

// import dashboard from './reducers/dashboard';
// import user from './reducers/user';
import user_configuration from './reducers/user_configuration';


import customer_display from './reducers/customer_display';
// import customer_group from './reducers/customer_group';
// import customer from './reducers/customer';
import supplier from './reducers/supplier';

// import store_adjustment from './reducers/store_adjustment';
// import store_transfer from './reducers/store_transfer';
// import store_user from './reducers/store_user';
// import store_activity from './reducers/store_activity';
// import store_pos_shift from './reducers/store_pos_shift';
// import billing from './reducers/billing';
// import order from './reducers/order';
// import order_bulk from './reducers/order_bulk';
// import initialize from './reducers/initialize';


const commonPersistConfig = {
	key: 'common',
	storage: storage,
	whitelist: ['current_user', 'subscribed']
};


const reducer = combineReducers ({
	// initialize,
	// billing,
	category,
	common: persistReducer(commonPersistConfig, common),
	customer_display,
	customer_group,
	customer,
	dashboard,
	employee,
	employee_access,
	employee_job_position,
	employee_organization,
	login,
	inventory,
	initialize,
	order,
	order_canceled,
	order_delivery,
	order_delivery_return,
	outlet,
	product,
	purchase_order,
	purchase_order_return,
	register,
	reset,
	supplier,
	store_adjustment,
	store_transfer,
	// dashboard,
	// user,
	user_configuration,
	// product,
	// customer_group,
	
	// customer,
	// store_adjustment,
	// outlet,
	// order,
	// order_bulk,
	// store_user,
	// store_activity,
	// store_pos_shift,
	// employee
});

const persistConfig = {
    key: 'root',
	storage,
	blacklist: [
		'category',
		'customer_display', 
		'customer_group',
		'customer',
		'dashboard',
		'employee',
		'employee_access',
		'employee_job_position',
		'employee_organization',
		'login',
		'inventory',
		'initialize',
		'register',
		'reset',
		'common',
		'product',
		'purchase_order',
		'purchase_order_return',
		'order',
		'order_canceled',
		'order_delivery',
		'order_delivery_return',
		'outlet',
		'supplier',
		'store_adjustment',
		'store_transfer',
		// 'initialize', 
		// 'billing', 
		// 'dashboard', 
		// 'category', 
		// 'customer_group', 
		// 'customer', 
		
		// 'common',
		// 'employee',
		// 'user', 
		'user_configuration', 
		// 'product', 
		// 'purchase_order', 
		// 'store_adjustment', 
		// 'store_transfer', 
		// 'store_user',
		// 'store_activity', 
		// 'store_pos_shift',
		// 'supplier', 
		// 'outlet', 
		// 'order', 
		// 'order_bulk'
	]
};


const persistedReducer = persistReducer(persistConfig, reducer);
const store = createStore(persistedReducer, applyMiddleware(promiseMiddleware, localStorageMiddleware)); 
const persistor = persistStore(store);
export { persistor, store };