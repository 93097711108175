import React from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import * as purchaseOrderActions from '../../../../../../../actions/purchaseOrderActions';
import { 
    Dialog,
    Slide,
    CircularProgress,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useHistory, useParams } from "react-router-dom";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function Confirm() {
    let { id } = useParams();
    const dispatch = useDispatch();
    const {onPurchaseOrderConfirm, purchase_order, actionType, inProgress} = useSelector(state => ({
        ...state.purchase_order
    }), shallowEqual);
   

    function onHide() {
        dispatch(purchaseOrderActions.onHideConfirm());
    }

   
    function Title() {
        if (actionType === 'canceled') {
            return (
                <DialogTitle>{"Batalkan PO?"}</DialogTitle>
            )
        }

        if (actionType === 'ordered') {
            return (
                <DialogTitle>{"Proses PO?"}</DialogTitle>
            )
        }

        if (actionType === 'received') {
            return (
                <DialogTitle>{"Terima PO?"}</DialogTitle>
            )
        }

        if (actionType === 'approved') {
            return (
                <DialogTitle>{"Validasi PO?"}</DialogTitle>
            )
        }

        if (actionType === 'updated') {
            return (
                <DialogTitle>{"Perbaharui PO?"}</DialogTitle>
            )
        }

        if (actionType === 'paid') {
            return (
                <DialogTitle>{"Process Payment?"}</DialogTitle>
            )
        }
        return null;
    }

    function ContentText() {
        if (actionType === 'canceled') {
            return (
                <DialogContentText>
                    Apakah kamu yakin ingin membatalkan PO dengan ID <span style={{fontWeight: 'bold'}}>#{purchase_order.increment_id}</span>.
                </DialogContentText>
            )
        }

        if (actionType === 'ordered') {
            return (
                <DialogContentText>
                    PO dengan ID <span style={{fontWeight: 'bold'}}>#{purchase_order.increment_id}</span> telah dipesan dengan kuantitas yang benar..
                </DialogContentText>
            )
        }

        if (actionType === 'received') {
            return (
                <DialogContentText>
                    PO dengan ID  <span style={{fontWeight: 'bold'}}>#{purchase_order.increment_id}</span> telah diterima dengan kuantitas dan harga yang benar.
                </DialogContentText>
            )
        }
        if (actionType === 'approved') {
            return (
                <DialogContentText>
                    PO dengan ID <span style={{fontWeight: 'bold'}}>#{purchase_order.increment_id}</span> telah disetujui dengan kuantitas dan harga yang benar..
                </DialogContentText>
            )
        }
        if (actionType === 'updated') {
            return (
                <DialogContentText>
                     PO dengan ID <span style={{fontWeight: 'bold'}}>#{purchase_order.increment_id}</span> diperbaharui.
                </DialogContentText>
            )
        }
        if (actionType === 'paid') {
            return (
                <DialogContentText>
                     PO dengan ID  <span style={{fontWeight: 'bold'}}>#{purchase_order.increment_id}</span> has been paid according to the bill.
                </DialogContentText>
            )
        }
        return null;
    }

    function onSubmit() {
        if (actionType === 'canceled') {
            dispatch(purchaseOrderActions.onCanceled(id, purchase_order));
        }

        if (actionType === 'ordered') {
            dispatch(purchaseOrderActions.onOrdered(id, purchase_order));
        }

        if (actionType === 'received') {
            dispatch(purchaseOrderActions.onReceived(id, purchase_order));
        }

        if (actionType === 'approved') {
            dispatch(purchaseOrderActions.onApproved(id, purchase_order));
        }

        if (actionType === 'updated') {
            dispatch(purchaseOrderActions.onUpdate(id, purchase_order));
        }
    }

    return (
        <Dialog
            open={onPurchaseOrderConfirm}
            maxWidth="xs"
            TransitionComponent={Transition}
            scroll={'paper'}>
            {Title()}
            <DialogContent>
                {ContentText()}
            </DialogContent>
            <DialogActions style={{padding: 25, justifyContent: 'space-between'}}>
                <CancelButton size="large" variant="contained" disabled={inProgress} disableElevation onClick={onHide}>
                    Batal
                </CancelButton>
                <Button sx={{minWidth: '165px'}} size="large" variant="contained" disabled={inProgress} disableElevation onClick={onSubmit} color="primary">
                    {inProgress ? <CircularProgress size={26} /> : 'Proses'}
                </Button>
            </DialogActions>    
        </Dialog>
    )
}

const CancelButton = styled(Button)(({theme}) => ({
    color: theme.palette.text.primary,
    backgroundColor: "#FFFFFF",
    '&:hover': {
    backgroundColor: "#FFFFFF",
    },
    fontWeight: 500,
    minWidth: 165,
    border: `1px solid #ddd`
}));
