import React, {useState} from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { List, 
         ListItem, 
         Avatar  
        } from '@mui/material';
import { styled } from '@mui/material/styles';

export default function UserMenu({setClose, setOpen}) {
    const { current_user, currentMenu} = useSelector(state => ({
        ...state.common
    }), shallowEqual);
    const [menuList] = useState([
        {
            "navName":"Users",
            "navType":"user",
            "navIcon": `${current_user.first_name.charAt(0) + current_user.last_name.charAt(0)}`,
            "navItems": [
                {
                    "name":`${current_user.first_name + " " + current_user.last_name}`,
                    "menuIcon":"",
                    "submenu": [{
                        "name":"Tagihan",
                        "pathUrl":"/dashboard/accounts/subscribe",
                    },{
                        "name":"Riwayat Tagihan",
                        "pathUrl":"/dashboard/accounts/billings",
                    },{
                        "name":"Pengaturan",
                        "pathUrl":"/dashboard/accounts/settings",
                    },{
                        "name":"Logout",
                        "pathUrl":"/logout",
                    }]
                }
            ]
        },        
    ])

    
    return (
        <NavList>
             {
                menuList.map( menu => {
                    return (
                        <NavListItem key={menu.navName} button onClick={(e) => {
                            currentMenu.navName === menu.navName ? 
                                setClose() :
                                window.setTimeout(() => {
                                    setOpen(menu)
                                }, 300);
                                setClose();
                            }} >
                            <NavAvatar variant="rounded">
                                {menu.navIcon}
                            </NavAvatar>
                        </NavListItem>
                    )
                })
            }
        </NavList>
    )
};


const NavList = styled(List)(({theme}) => ({
    position: 'relative',
    zIndex: '9999',
    color: '#ffffff'
}));

const NavListItem = styled(ListItem)(({theme}) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
}));


const NavAvatar = styled(Avatar)(({theme}) => ({
    color: theme.palette.getContrastText(theme.palette.primary.main),
    backgroundColor: theme.palette.primary.main,
    textTransform: 'uppercase',
}));