import React from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import * as purchaseOrderActions from '../../../../../../actions/purchaseOrderActions';
import { 
        Typography,
        Paper,
        Grid,
        TextField,
        Autocomplete,
        Alert
} from '@mui/material';
import { styled } from '@mui/material/styles';

export default function Information() {
    const dispatch = useDispatch();
    const {purchase_order, stores, suppliers, errors} = useSelector(state => ({
        ...state.purchase_order
    }), shallowEqual); 

    const { purchase_order_items, supplier_id, store_id, note } = purchase_order;

    function onSelectStore(option) {
        if (purchase_order_items[0].product_id && purchase_order_items.length >= 1) {
            dispatch(purchaseOrderActions.onHandleInventories(option._id));
		} else {
            dispatch(purchaseOrderActions.onLoadStoreInventories(option._id));
		}
    }

    function onSelectSupplier(option) {
        const newPurchaseOrder = {...purchase_order};
        newPurchaseOrder.supplier_id = option._id;
        dispatch(purchaseOrderActions.onUpdatePurchaseOrder(newPurchaseOrder));
    }

    function onUpdateField(event) {
		const key = event.target.name;
        const value = event.target.value;
        const newPurchaseOrder = {...purchase_order};
        newPurchaseOrder[key] = value;
        dispatch(purchaseOrderActions.onUpdatePurchaseOrder(newPurchaseOrder));
    }
 
    function error(key) {
        if (errors != null && errors[`purchase_order.${key}`] != null) {
            return errors[`purchase_order.${key}`].msg
        }
        return null;
    }

    const storeOptions = stores.map((option) => {
        const firstLetter = option.name[0].toUpperCase();
        return {
          firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
          ...option,
        };
    });

    const supplierOptions = suppliers.map((option) => {
        const firstLetter = option.name[0].toUpperCase();
        return {
          firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
          ...option,
        };
    });

    const storeValue = stores.find(opt => opt._id === store_id);
    const supplierValue = suppliers.find(opt => opt._id === supplier_id);

    return (
        <ContentPaper elevation={3}>
            <Grid container spacing={6} direction="column">
                <Grid item container>
                    <Grid item xs>
                        <Typography fontWeight={700} variant="h6" lineheight="normal">
                            Informasi Pembelian
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Alert severity="error">
                            Dibutuhkan
                        </Alert>
                    </Grid>
                </Grid>
                <Grid item container direction="column" spacing={5}>
                    <Grid item container spacing={8}>
                        <Grid item xs={4}>
                            <Typography lineheight="normal" fontSize={16} fontWeight={700} component="div">
                                Cabang
                            </Typography>
                            <Typography variant="body2" gutterBottom color="textSecondary">
                                Silahkan pilih kantor cabang.
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Autocomplete
                                options={storeOptions.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                                groupBy={(option) => option.firstLetter}
                                getOptionLabel={(option) => option.name}
                                selectOnFocus
                                clearOnBlur
                                filterSelectedOptions
                                disableClearable
                                onChange={(event, option) => {
                                    onSelectStore(option)
                                }}
                                value={storeValue ? storeValue : null}
                                isOptionEqualToValue={(option, value) => option._id === value._id}
                                renderInput={(params) => <TextField {...params} error={!!error('store_id')} helperText={error('store_id')} placeholder="Pilih Cabang" variant="outlined" />}
                            />
                        </Grid> 
                    </Grid>
                    <Grid item container spacing={8}>
                        <Grid item xs={4}>
                            <Typography lineheight="normal" fontSize={16} fontWeight={700} component="div">
                                Supplier
                            </Typography>
                            <Typography variant="body2" gutterBottom color="textSecondary">
                                Silahkan pilih supplier
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Autocomplete
                                options={supplierOptions.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                                groupBy={(option) => option.firstLetter}
                                getOptionLabel={(option) => option.name}
                                selectOnFocus
                                clearOnBlur
                                filterSelectedOptions
                                disableClearable
                                onChange={(event, option) => {
                                    onSelectSupplier(option)
                                 }}
                                value={supplierValue ? supplierValue : null}
                                isOptionEqualToValue={(option, value) => option._id === value._id}
                                renderInput={(params) => <TextField {...params} error={!!error('supplier_id')} helperText={error('supplier_id')} placeholder="Pilih Supplier" variant="outlined" />}
                            />
                        </Grid> 
                    </Grid>
                    <Grid item container spacing={8}>
                        <Grid item xs={4}>
                            <Typography lineheight="normal" fontSize={16} fontWeight={700} component="div">
                                Catatan
                            </Typography>
                            <Typography variant="body2" gutterBottom color="textSecondary">
                                Masukan catatan pembelian.
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <TextField
                                fullWidth
                                rows={6}
                                multiline
                                name="note"
                                placeholder="Catatan"
                                value={note}
                                variant="outlined"
                                onChange={onUpdateField}
                            />
                        </Grid> 
                    </Grid>
                </Grid>
            </Grid>
        </ContentPaper>
    )
}

const ContentPaper = styled(Paper)(({theme}) => ({
    padding: 35,
    paddingTop: 32,
    marginBottom: 25
}));