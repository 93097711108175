
import * as types from './actions/actionTypes';
import { setAuthorizationToken } from  './api';
const promiseMiddleware = store => next => action => {
	if (isPromise(action.payload)) {
		store.dispatch({ type: types.ASYNC_START, subtype: action.type });
		action.payload.then(
			res => {
				action.payload = res.data;
				store.dispatch(action);
			},
			error => {
				action.error = true;
				action.payload = error.response.data;
				store.dispatch(action)
			}
		);

		return;
	}

	next(action);
};

function isPromise(v) {
	return v && typeof v.then === 'function';
}

const localStorageMiddleware = store => next => action => {
	if (action.payload && action.payload.session_expired) {
		setAuthorizationToken('');
		window.localStorage.setItem('userToken', '');
		window.location.href = "/sign-in";
	}

	if ( action.type === types.USER_LOGGED_IN ) {
		if (!action.error) {
			setAuthorizationToken(action.payload.user_token);
			window.localStorage.setItem('userToken', action.payload.user_token);
		} 
	} else if ( action.type === types.USER_REGISTERED ) {
		if (!action.error) {
			setAuthorizationToken(action.payload.user_token);
			window.localStorage.setItem('userToken', action.payload.user_token);
		}
	} else if (action.type === types.USER_LOGGED_OUT) {
		setAuthorizationToken('');
		window.localStorage.setItem('userToken', '');
	}
	next(action);
};


export {
	promiseMiddleware,
	localStorageMiddleware
}