import React from 'react';
import {Box, 
        Typography, 
        Grid,
        } from '@mui/material';
import { styled } from '@mui/material/styles';
import Footer from '../Common/Footer';
import customer from '../../../assets/images/about/customer.png';
import lq310 from '../../../assets/images/about/lq310.png';
import bulkPayment from '../../../assets/images/about/bulk-payment.png';
import Nav from '../Common/Nav';

export default function About({onScroll}) {
    return (
        <SectionBox>
            <Nav />
            <AboutBox>
                <Grid container alignItems="center" spacing={6}>
                    <Grid item xs={12} sm={6}>
                        <ImgBox component="img" src={customer} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box>
                            <Typography fontWeight={600} color="primary" variant="h5" gutterBottom>   
                                TENTANG AliPOS
                            </Typography>
                            <br/>
                            <Typography component="div" variant="body1" textAlign="justify" gutterBottom>
                                AliPOS adalah aplikasi kasir retail berbasis cloud. 
                                Kami tidak hanya membangun ekosistem, namun juga
                                membangun mimpi, khususnya untuk UMKM di 
                                Indonesia. Kami percaya dengan adanya sistem yang
                                terintegrasi, memungkinkan Anda untuk tumbuh lebih
                                cepat.
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </AboutBox>
            <InvoiceBox>
                <Grid container alignItems="center" spacing={6}>
                    <MobileInvoiceGrid item xs={12} sm={6}>
                        <ImgBox component="img" src={lq310} />
                    </MobileInvoiceGrid>
                    <Grid item xs={12} sm={6}>
                        <Box>
                            <Typography fontWeight={600} color="primary" variant="h5" gutterBottom>   
                                CETAK NOTA
                            </Typography>
                            <br/>
                            <Typography component="div" variant="body1" textAlign="justify" gutterBottom>
                                Kami berkomitmen untuk meningkatkan efisiensi dan
                                efektifitas dalam bertransaksi, tracking transaksi, 
                                laporan kasir, laporan persediaan, dan mengetahui
                                pelanggan potential Anda. Anda dapat dengan mudah
                                dalam mencetak Struk, Label (QR-Code), dan 
                                Nota (Continuous Form) dengan sistem AliPOS. 
                            </Typography>
                        </Box>
                    </Grid>
                    <InvoiceGrid item xs={12} sm={6}>
                        <ImgBox component="img" src={lq310} />
                    </InvoiceGrid>
                </Grid>
            </InvoiceBox>
            <BulkPaymentBox>
                <Grid container alignItems="center" spacing={6}>
                    <Grid item xs={12} sm={6}>
                        <BulkImgBox component="img" src={bulkPayment} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box>
                            <Typography fontWeight={600} color="primary" variant="h5" gutterBottom>   
                                BULK PAYMENT
                            </Typography>
                            <br/>
                            <Typography component="div" variant="body1" textAlign="justify" gutterBottom>
                                Menerima pembayaran dari pelanggan menjadi lebih
                                mudah, pelanggan Anda dapat membayar, sebagian
                                atau keseluruhan tagihan. Anda tidak perlu lagi 
                                menghitung total tagihan dengan cara konvensional. 
                                AliPOS dilengkapi juga dengan fitur pencarian pelanggan, 
                                sehingga meminimalisir kesalahan tagihan kepada 
                                pelanggan Anda. 
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </BulkPaymentBox>
            <Footer />
        </SectionBox>
    )
}


const SectionBox = styled(Box)(({theme}) => ({
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 135
}));

const ImgBox = styled(Box)(({theme}) => ({
    width: '115%',
    [theme.breakpoints.between('xs','lg')]: {
        width: '100%',
    },
}));

const BulkImgBox = styled(Box)(({theme}) => ({
    width: '100%'
}));

const AboutBox = styled(Box)(({theme}) => ({
    padding: 75,
    paddingTop: 115,
    paddingBottom: 115,
    [theme.breakpoints.between('xs','sm')]: {
        paddingLeft: 15,
        paddingRight: 15,
        paddingTop: 25,
    },
    [theme.breakpoints.between('sm', 'lg')]: {
        paddingLeft: 25,
        paddingRight: 25
    }
}));

const InvoiceBox = styled(Box)(({theme}) => ({
    background: "#f4f3fc",
    padding: 75,
    paddingTop: 115,
    paddingBottom: 115,
    [theme.breakpoints.between('xs','md')]: {
        paddingLeft: 15,
        paddingRight: 15,
    },
    [theme.breakpoints.between('sm', 'lg')]: {
        paddingLeft: 25,
        paddingRight: 25
    }
}));

const InvoiceGrid = styled(Grid)(({theme}) => ({
    display: 'block',
    [theme.breakpoints.between('xs', 'sm')]: {
        display: 'none',
    },
}));

const MobileInvoiceGrid = styled(Grid)(({theme}) => ({
    display: 'none',
    [theme.breakpoints.between('xs', 'sm')]: {
        display: 'block',
    }
}));

const BulkPaymentBox = styled(Box)(({theme}) => ({
    padding: 75,
    paddingTop: 115,
    marginBottom: -295,
    position: 'relative',
    zIndex: 100,
    [theme.breakpoints.between('xs','sm')]: {
        paddingLeft: 15,
        paddingRight: 15,
        marginBottom: '-50%'
    },
    [theme.breakpoints.between('sm', 'lg')]: {
        paddingLeft: 25,
        paddingRight: 25,
        marginBottom: '-25%'
    }
}));