import React, { useEffect, useState } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import * as registerActions from '../../../actions/registerActions';
import { 
    Box, 
    Container,
    Paper,
    Typography, 
    TextField,
    Button,
    Snackbar,
    Alert,
    Slide
    } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { styled } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";
import logo from '../../../assets/images/logo-pp.png';
import Verification from '../Common/Verification';
import UserExist from '../Common/UserExist';
import Form from './Form';

export default function SignUp() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { user, userVerification, userExist, newUser, inProgress, userVerified, errors } = useSelector(state => ({
        ...state.common,
        ...state.register,
    }), shallowEqual);

    const [validated, setValidated] = useState(false);

    useEffect(() => {
        if (!newUser) {
            dispatch(registerActions.onLoadRegister());
        } else if (newUser) {
            onNext()
        }
        return () => {
            dispatch(registerActions.onUnloadRegister())
        };
    },[]);

    useEffect(() => {
        if (user && user.email_phone) {
            if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(user.email_phone)) {
                setValidated(true);
            } else if (/^(\+?62|0)8(1[123456789]|2[1238]|3[1238]|5[12356789]|7[78]|9[56789]|8[123456789])([\s?|\d]{5,11})$/.test(user.email_phone)) {
                setValidated(true);
            } else {
                setValidated(false);
            }
        }
    },[user]);

    function onUpdateField(event) {
		const key = event.target.name;
        const value = event.target.value;
        dispatch(registerActions.onUpdateValidationField(key, value));
	}

    function onNext() {
		dispatch(registerActions.onValidateUser(user));
	}

    function onEnter(event) {
        if (event.key === 'Enter') {
            onNext()
        }
    }


    function ActionButton() {
        if (inProgress) {
            return (
                <LoadingButton loading fullWidth size="large" variant="contained">Selanjutnya</LoadingButton>
            )
        } else {
            return (
                <Button disabled={!validated} onClick={onNext} fullWidth size="large" variant="contained">Selanjutnya</Button>
            )
        }
    }

    if (!user) {
        return null;
    }

    const { email_phone } = user;

    if (userVerified) {
        return (
            <Form />
        )
    }

    function error(key) {
        if (errors != null && errors[`user.${key}`] != null) {
            return errors[`user.${key}`].msg
        }
        return null;
    }

    return (
        <WrapperBox>
            <UserExist open={userVerification && userExist} />
            <Verification verifyType="register" open={userVerification && !userExist} />
            <ContentBox>
                <ContentContainer maxWidth="sm">
                    <LogoBox onClick={() => navigate('/')} sx={{ cursor: 'pointer'}} component="img" src={logo} />
                    <FormPaper elevation={3}>
                        <HeaderBox display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                            <Typography fontWeight={700} variant="h5" gutterBottom>
                                Daftar Sekarang
                            </Typography> 
                            <Box display="flex">
                                <Typography fontWeight={500} variant="body1" gutterBottom>
                                    Sudah memiliki akun? <LinkBox component="a" onClick={() => navigate('/sign-in')}>Masuk</LinkBox>
                                </Typography>
                            </Box>
                        </HeaderBox>
                        <FieldBox>
                            <TextField 
                                fullWidth 
                                name="email_phone"
                                label="Email / No. HP" 
                                variant="outlined"
                                helperText={!!error('email_phone') ? error('email_phone') : "Contoh: email@livin.com"}
                                value={email_phone}
                                onKeyDown={onEnter}
                                error={!!error('email_phone')}
                                onChange={onUpdateField} />
                        </FieldBox>
                        <ActionButton />
                        <BottomBox>
                            <Typography color={"#999"}variant="body2">
                                Dengan mendaftar, saya menyetujui
                            </Typography> 
                            <Box display="flex">
                                <Typography variant="body2">
                                    <LinkBox component="a" onClick={() => navigate('/term')}>Syarat dan Ketentuan</LinkBox> <span style={{color: "#999"}}>serta </span><LinkBox onClick={() => navigate('/privacy')} component="a" >Kebijakan Privasi</LinkBox>
                                </Typography> 
                            </Box>
                        </BottomBox>
                        
                    </FormPaper>
                </ContentContainer>
            </ContentBox>
        </WrapperBox>

    )
}

const WrapperBox = styled(Box)(({theme}) => ({
    background: "#f4f3fc",
    height: '100%'
}));

const ContentBox = styled(Box)(({theme}) => ({
    padding: 35
}));


const ContentContainer = styled(Container)(({theme}) => ({
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center'
}));

const FormPaper = styled(Paper)(({theme}) => ({
    backgroundColor: 'rgb(255, 255, 255)',
    color: 'rgb(34, 51, 84)',
    transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    borderRadius: '8px',
    boxShadow: 'rgb(159 162 191 / 18%) 0px 9px 16px, rgb(159 162 191 / 32%) 0px 2px 2px',
    overflow: 'hidden',
    marginTop: 55,
    padding: 35,
    width: '100%',
    [theme.breakpoints.between('xs','sm')]: {
        padding: 20
    },
}));

const HeaderBox = styled(Box)(({theme}) => ({
    marginBottom: 45
}));

const FieldBox = styled(Box)(({theme}) => ({
    marginTop: 20,
    marginBottom: 35
}));

const LogoBox = styled(Box)(({theme}) => ({
    width: 175
}));

const BottomBox = styled(Box)(({theme}) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 20
}));

const LinkBox = styled(Box)(({theme}) => ({
    cursor: 'pointer',
    color: theme.palette.primary.main
}));
