import React from 'react';
import {
    Typography, 
    Grid,
    TextField,
    Alert,
    Autocomplete
} from '@mui/material';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import * as initializeActions from '../../../actions/initializeActions';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

export default function UserInformation() {
    const dispatch = useDispatch();
    const { initialize_user, gender_options, errors } = useSelector(state => ({
        ...state.initialize
    }), shallowEqual); 

    function onSelectGender(value) {
        const newUser = {...initialize_user};
        newUser.gender_option_id = value;
        dispatch(initializeActions.onUpdateUser(newUser));
    }

    const onSelectDate = (date) => {
        const newUser = {...initialize_user};
        newUser.date_of_birth = date;
        dispatch(initializeActions.onUpdateUser(newUser));
    };

    function error(key) {
        if (errors != null && errors[key] != null) {
            return errors[key][0]
        }

        return null;
    }

    const onKeyDown = (e) => {
        e.preventDefault();
     };

    const { first_name, last_name, email, phone, gender_option_id, date_of_birth } = initialize_user;

    const genderValue = gender_options.find(option => option.value === gender_option_id);

    return (
        <Grid container spacing={6} direction="column">
            <Grid item container spacing={3}>
                <Grid item xs>
                    <Typography fontWeight={700} variant="h6" lineheight="normal">
                        Informasi Pengguna
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                        Tambahkan detail pengguna seperti jenis kelamin dan tgl. lahir.
                    </Typography>
                </Grid>
                <Grid item>
                    <Alert severity="error">
                        Wajib
                    </Alert>
                </Grid>
            </Grid>
            <Grid item container direction="column" spacing={3}>
                <Grid item container spacing={3}>
                    <Grid item xs>
                        <TextField
                            fullWidth
                            name="Nama Depan"
                            label="Nama Depan"
                            placeholder="Nama Depan"
                            value={first_name}
                            disabled
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs>
                        <TextField
                            fullWidth
                            name="Nama Belakang"
                            label="Nama Belakang"
                            placeholder="Nama Belakang"
                            value={last_name}
                            disabled
                            variant="outlined"
                        />
                    </Grid>
                </Grid> 
                <Grid item container spacing={3}>
                    <Grid item xs>
                        <TextField
                            fullWidth
                            name="E-mail"
                            label="E-mail"
                            placeholder="E-mail"
                            value={email}
                            disabled
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs>
                        <TextField
                            fullWidth
                            name="No. HP"
                            label="No. HP"
                            placeholder="No. HP"
                            value={phone}
                            disabled
                            variant="outlined"
                        />
                    </Grid>
                </Grid> 
                <Grid item container spacing={3}>
                    <Grid item xs>
                        <Autocomplete
                            options={gender_options}
                            getOptionLabel={(option) => option.name}
                            selectOnFocus
                            clearOnBlur
                            filterSelectedOptions
                            disableClearable
                            onChange={(event, option) => {
                                onSelectGender(option.value)
                            }}
                            value={genderValue ? genderValue : null}
                            isOptionEqualToValue={(option, value) => option.value === value.value}
                            renderInput={(params) => <TextField {...params} 
                                label="Jenis Kelamin" 
                                error={!!error('gender_option_id')} 
                                helperText={error('gender_option_id')}
                                placeholder="Pilih jenis kelamin" variant="outlined" />
                            }
                        />
                    </Grid>
                    <Grid item xs>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DatePicker
                                openTo="year"
                                disableFuture
                                label="Tgl. Lahir"
                                value={date_of_birth}
                                format="dd/MM/yyyy"
                                onChange={onSelectDate}
                                renderInput={(params) => <TextField fullWidth onKeyDown={onKeyDown}  {...params} />}
                            />
                        </LocalizationProvider>
                    </Grid>
                </Grid> 
            </Grid>
        </Grid>
    );
}