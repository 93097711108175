import React from 'react';
import { 
    Grid,
    TextField,
    Button,
    IconButton,
    Box
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { DragIndicatorRounded } from '@mui/icons-material';
import { Draggable } from "react-beautiful-dnd";

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    
    left: 0,
    margin: 0,
    padding: 0,
    // change background colour if dragging
    border: "1px solid transparent",
  
    // styles we need to apply on draggables
    ...draggableStyle
});

export default function Dropdown({dragId, option, config, optIndex, onRemove, onChange, error, name}) {

    return (
        <Draggable draggableId={dragId} index={optIndex}>
            {(provided, snapshot) => 
            <Box
                sx={{marginBottom: '10px !important'}}
                {...provided.draggableProps}
                ref={provided.innerRef}
                style={getItemStyle(
                    snapshot.isDragging,
                    provided.draggableProps.style
                )}
                >
                <Grid container spacing={3}>
                    <Grid item xs={1} >
                        <IconButton sx={{marginTop: '8px'}} color="primary" {...provided.dragHandleProps}>
                            <DragIndicatorRounded />
                        </IconButton>
                    </Grid>
                    <Grid item xs={9}>
                        <TextField
                            fullWidth
                            error={!!error(`${name}.value`)}
                            helperText={error(`${name}.value`)}
                            name="value"
                            placeholder="Nama Opsi"
                            value={option.value}
                            variant="outlined"
                            onChange={onChange}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <DelButton 
                            disabled={config.product_configuration_options.length === 1}
                            fullWidth
                            variant="contained"
                            size="large"
                            disableElevation
                            onClick={onRemove}>
                            Hapus
                        </DelButton>
                    </Grid>
                </Grid>
            </Box>
            }
        </Draggable>
    )
}

const DelButton = styled(Button)(({theme}) => ({
    color: theme.palette.text.primary,
    backgroundColor: "#FFFFFF",
    '&:hover': {
      backgroundColor: "#FFFFFF",
    },
    fontWeight: 500,
    border: `1px solid #ddd`,
    height: 56
}));

