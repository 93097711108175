import React from 'react';
import {Grid, Typography, Box, Divider, Paper, Stack} from '@mui/material';
import { styled } from '@mui/material/styles';
import playImg from '../../../assets/images/google-play.svg';
import { useNavigate } from "react-router-dom";
import { WhatsApp } from '@mui/icons-material';
import footerBg from '../../../assets/images/footer-bg.svg';

export default function Footer() {
    const navigate = useNavigate();
    return (
        <SectionBox>
            <BackgroundBox />
            <ContentBox>
                <Grid container>
                    <Grid item container xs={12} sm={5}>
                        <Box>
                            <Stack spacing={2}>
                                <WhiteTypography fontWeight={700} variant="h5" component="h4" gutterBottom>
                                    Download App
                                </WhiteTypography>
                                <AppsBox component="img" src={playImg} />
                                <Box>
                                    <WhiteTypography fontWeight={700} variant="h5" component="h4" gutterBottom>
                                        PT. Avant Indonesia
                                    </WhiteTypography>
                                    <WhiteTypography variant="h5" component="h4">
                                        <Box fontWeight="fontWeightMedium" fontSize={16}>
                                            Bojong Kulur, Gunung Putri
                                        </Box>
                                    </WhiteTypography>
                                    <WhiteTypography variant="h5" component="h4">
                                        <Box fontWeight="fontWeightMedium" fontSize={16}>
                                            Bogor, Indonesia
                                        </Box>
                                    </WhiteTypography>
                                </Box>
                            </Stack>
                        </Box>
                    </Grid>
                    <LinkGrid item container xs={12} sm={7}>
                        <ListBox>
                            <Stack
                                direction={{ xs: 'column', sm: 'row' }}
                                justifyContent={{ sm: 'space-between'}}
                                spacing={{ xs: 1, sm: 2, md: 4 }}
                            >
                                <Box>
                                    <Stack spacing={2}>
                                        <WhiteTypography fontWeight={700} variant="h5" component="h1" gutterBottom>
                                            Solusi Usaha
                                        </WhiteTypography>
                                        <WhiteTypography fontWeight={500} fontSize={16} variant="h5" component="h4" gutterBottom>
                                            Toko Bangunan
                                        </WhiteTypography>
                                        <WhiteTypography fontWeight={500} fontSize={16} variant="h5" component="h4" gutterBottom>
                                            Toko Aksesoris
                                        </WhiteTypography>
                                        <WhiteTypography fontWeight={500} fontSize={16} variant="h5" component="h4" gutterBottom>
                                            Bengkel
                                        </WhiteTypography>
                                        <WhiteTypography fontWeight={500} fontSize={16} variant="h5" component="h4" gutterBottom>
                                            Klinik
                                        </WhiteTypography>
                                        <WhiteTypography fontWeight={500} fontSize={16} variant="h5" component="h4" gutterBottom>
                                            Minimarket
                                        </WhiteTypography>
                                        <WhiteTypography fontWeight={500} fontSize={16} variant="h5" component="h4" gutterBottom>
                                            Supermarket
                                        </WhiteTypography>
                                        <WhiteTypography fontWeight={500} fontSize={16} variant="h5" component="h4" gutterBottom>
                                            Toko Buah
                                        </WhiteTypography>
                                    </Stack>
                                </Box>
                                <Box>
                                    <Stack spacing={2}>
                                        <WhiteTypography fontWeight={700} variant="h5" component="h4" gutterBottom>
                                            Bantuan
                                        </WhiteTypography>
                                        <WhiteTypography fontWeight={500} fontSize={16} variant="h5" component="h4" gutterBottom>
                                            Harga
                                        </WhiteTypography>
                                        <WhiteTypography fontWeight={500} fontSize={16} variant="h5" component="h4" gutterBottom>
                                            Fitur
                                        </WhiteTypography>
                                        <WhiteTypography fontWeight={500} fontSize={16} variant="h5" component="h4" gutterBottom>
                                            Perangkat
                                        </WhiteTypography>
                                        <WhiteTypography fontWeight={500} fontSize={16} variant="h5" component="h4" gutterBottom>
                                            F.A.Q
                                        </WhiteTypography>
                                    </Stack>
                                </Box>
                                <Box>
                                    <Stack spacing={2}>
                                        <WhiteTypography fontWeight={700} variant="h5" component="h4" gutterBottom>
                                            AliPOS
                                        </WhiteTypography>
                                        <WhiteTypography onClick={() => navigate('/about')} sx={{ cursor: 'pointer'}} fontWeight={500} fontSize={16} variant="h5" component="h4" gutterBottom>
                                            Tentang Kami
                                        </WhiteTypography>
                                        <WhiteTypography fontWeight={500} fontSize={16} variant="h5" component="h4" gutterBottom>
                                            Promo
                                        </WhiteTypography>
                                        <WhiteTypography fontWeight={500} fontSize={16} variant="h5" component="h4" gutterBottom>
                                            Kasir
                                        </WhiteTypography>
                                        <WhiteTypography onClick={() => navigate('/privacy')} sx={{ cursor: 'pointer'}} fontWeight={500} fontSize={16} variant="h5" component="h4" gutterBottom>
                                            Kebijakan & Privasi
                                        </WhiteTypography>
                                        <WhiteTypography onClick={() => navigate('/term')} sx={{ cursor: 'pointer'}} fontWeight={500} fontSize={16} variant="h5" component="h4" gutterBottom>
                                            Syarat & Ketentuan
                                        </WhiteTypography>
                                        <WhiteTypography fontWeight={500} fontSize={16} variant="h5" component="h4" gutterBottom>
                                            Hubungi Kami
                                        </WhiteTypography>
                                    </Stack>
                                </Box>
                            </Stack>
                        </ListBox>
                    </LinkGrid>
                </Grid>
            </ContentBox>
            <FooterDivider />
            <FooterBox>
                <CopyRightTypography variant="body1">
                    © 2020 Avant Inc. All rights reserved
                </CopyRightTypography>
            </FooterBox>
            <MobileFooterBox>
                <CopyRightTypography variant="body1">
                    © 2020 Avant Inc.
                </CopyRightTypography>
            </MobileFooterBox>
            <WhatsAppPaper elevation={6}
                onClick={() => {
                    window.location.assign('https://api.whatsapp.com/send?phone=628176009888&text=Hi,%20%20saya%20ingin%20bertanya%20mengenai%20AliPOS.');
                }} >
                <Grid container spacing={1} alignItems="center" justify="center">
                    <Grid item>
                        <WhatsApp fontSize='large' />
                    </Grid>
                    <Grid item xs container direction="column">
                        <Grid item xs>
                            <Typography variant="body2" component="div">
                                <Box fontWeight={500} fontSize={14}>
                                    Layanan 24 Jam
                                </Box>
                            </Typography>
                            <Typography variant="body2" component="div">
                                <Box fontSize={12}>
                                    Chat Sekarang
                                </Box>
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </WhatsAppPaper>
        </SectionBox>
    )
}

const SectionBox = styled(Box)(({theme}) => ({
    backgroundImage: `url(${footerBg})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center top',
    paddingTop: '40%',
    position: 'relative',
    backgroundColor: 'rgb(109, 75, 255)',
}));

const BackgroundBox = styled(Box)(({theme}) => ({
    // backgroundColor: 'rgb(109, 75, 255)',
    // // backgroundImage: `url(${footerBg})`,
    // // backgroundRepeat: 'no-repeat',
    // // // backgroundSize: 'cover',
    // // backgroundPosition: 'bottom',
    // // position: 'absolute',
    // // top: 0,
    // // left: 0,
    // // right: 0,
    // // height: '75rem',
    // backgroundImage: `url(${footerBg})`,
    // backgroundRepeat: 'no-repeat',
    // backgroundSize: 'cover',
    // backgroundPosition: 'center top',
    // paddingTop: '40%',
}));

const ContentBox = styled(Box)(({theme}) => ({
    zIndex: 99,
    position: 'relative',
    paddingLeft: 75,
    paddingRight: 75,
    [theme.breakpoints.between('xs','sm')]: {
        paddingLeft: 15,
        paddingRight: 15,
        paddingTop: 150
    },
    [theme.breakpoints.between('sm', 'md')]: {
        paddingLeft: 25,
        paddingRight: 25,
        paddingTop: 200
    },
    // [theme.breakpoints.between('sm', 'md')]: {
    //     paddingLeft: 25,
    //     paddingRight: 25,
    //     paddingTop: 200
    // },
    [theme.breakpoints.between('md', 'lg')]: {
        paddingLeft: 25,
        paddingRight: 25,
        paddingTop: 135
    },
    [theme.breakpoints.up('xl')]: {
        paddingLeft: 'calc((100% - 1284px)/2)',
        paddingRight: 'calc((100% - 1284px)/2)'
    },
}));


const AppsBox = styled(Box)(({theme}) => ({
    width: '50%'
}));

const ListBox = styled(Box)(({theme}) => ({
    width: '100%'
}));

const LinkGrid = styled(Grid)(({theme}) => ({
    [theme.breakpoints.between('xs','sm')]: {
        display: 'none'
    },
}));

const FooterDivider = styled(Divider)(({theme}) => ({
    borderColor: '#FFFFFF',
    marginTop: 75,
    [theme.breakpoints.between('xs','md')]: {
        marginTop: 30
    },
}));

const FooterBox = styled(Box)(({theme}) => ({
    paddingLeft: 75,
    paddingRight: 75,
    [theme.breakpoints.between('sm', 'md')]: {
        paddingLeft: 25,
        paddingRight: 25,
    },
    [theme.breakpoints.between('xs','sm')]: {
        display: 'none'
    },
    [theme.breakpoints.between('md', 'lg')]: {
        paddingLeft: 25,
        paddingRight: 25
    },
    [theme.breakpoints.up('xl')]: {
        paddingLeft: 'calc((100% - 1284px)/2)',
        paddingRight: 'calc((100% - 1284px)/2)'
    },
}));

const MobileFooterBox = styled(Box)(({theme}) => ({
    display: 'none',
    paddingLeft: 15,
    paddingRight: 15,
    [theme.breakpoints.between('xs','sm')]: {
        display: 'block'
    },
    [theme.breakpoints.up('lg')]: {
        paddingLeft: 'calc((100% - 1284px)/2)',
        paddingRight: 'calc((100% - 1284px)/2)'
    },
}));


const CopyRightTypography = styled(Typography)(({theme}) => ({
    color: '#FFFFFF',
    padding: '25px 0'
}));


const WhiteTypography = styled(Typography)(({theme}) => ({
    color: '#FFFFFF'
}));

const WhatsAppPaper = styled(Paper)(({theme}) => ({
    color: '#FFFFFF',
    right: 20,
    bottom: 20,
    cursor: 'pointer',
    padding: '11px 25px',
    position: 'fixed',
    minWidth: 155,
    background: '#6200EE',
    borderRadius: 30,
    zIndex: 10,
    [theme.breakpoints.between('xs','sm')]: {
        display: 'none'
    },
}));