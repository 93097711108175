import React, { useRef } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import * as employeeOrganizationActions from '../../../../../../actions/employeeOrganizationActions';
import { useDrag, useDrop } from 'react-dnd'
import { ItemTypes } from './ItemTypes';
import { getDepth } from './utils/tree-data-utils';
import { Paper,
    Grid,
    Button,
    Card,
    CardHeader,
    CardActions,
    Avatar,
    IconButton,
    Typography,
    Collapse,
    CardContent,
    Stack,
    Box,
    Popover,
    List,
    ListItem,
    ListItemButton,
    ListItemText
} from '@mui/material';
import { useNavigate } from "react-router-dom";
import { red } from '@mui/material/colors';
import {AddRounded, RemoveRounded, ExpandMore, MoreVert } from '@mui/icons-material';
import { styled } from '@mui/material/styles';

const style = {
    backgroundColor: 'white',
    cursor: 'move',
};
export default function Node(props) {
    const { 
        name,
        listIndex, 
        node, 
        path,
        treeIndex, 
        depth,
        parentNode,
        startDrag,
        dragHover,
        onDrop,
        maxDepth,
        toggleChildrenVisibility } = props;
    const ref = useRef(null);

    const dispatch = useDispatch();
    let navigate = useNavigate();

    const [expanded, setExpanded] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const onOpen = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const onClose = () => {
      setAnchorEl(null);
    };

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    function getTargetDepth(dropTargetProps, monitor) {
        let dropTargetDepth = 0;
    
        const rowAbove = dropTargetProps.getPrevRow();
        if (rowAbove) {
          let { path } = rowAbove;
          // Limit the length of the path to the deepest possible
          dropTargetDepth = Math.min(path.length, dropTargetProps.path.length);
        }

        
    
        let blocksOffset;
        let dragSourceInitialDepth = (monitor.getItem().path || []).length;
    
        // handle row direction support
        const direction = 1;

        blocksOffset = Math.round(
        (direction * monitor.getDifferenceFromInitialOffset().x) /
            dropTargetProps.scaffoldBlockPxWidth
        );

        let targetDepth = Math.min(
          dropTargetDepth,
          Math.max(0, dragSourceInitialDepth + blocksOffset - 1)
        );
    
       
        // If a maxDepth is defined, constrain the target depth
        if (typeof maxDepth !== 'undefined' && maxDepth !== null) {
          const draggedNode = monitor.getItem().node;
          const draggedChildDepth = getDepth(draggedNode);
    
          targetDepth = Math.max(
            0,
            Math.min(targetDepth, maxDepth - draggedChildDepth - 1)
          );
        }
    
        return targetDepth;
    }

    const [{ handlerId }, drop] = useDrop({
        accept: ItemTypes.CATEGORY,
        drop: (item, monitor) => {
            const result = {
                node: monitor.getItem().node,
                path: monitor.getItem().path,
                treeIndex: monitor.getItem().treeIndex,
                // treeId: this.treeId,
                minimumTreeIndex: props.treeIndex,
                depth: getTargetDepth(props, monitor),
              };
              onDrop(result);
      
              return result;
        },
        
        canDrop: (item, monitor) => {
            if (monitor.isOver({ shallow: true })) {
                const rowAbove = props.getPrevRow();
                const abovePath = rowAbove ? rowAbove.path : [];
                const aboveNode = rowAbove ? rowAbove.node : {};
                const targetDepth = getTargetDepth(props, monitor);

                // Cannot drop if we're adding to the children of the row above and
                //  the row above is a function
                if (
                  targetDepth >= abovePath.length &&
                  typeof aboveNode.children === 'function'
                ) {
                  return false;
                }
            
                return true;
            }
            
    
            return false;
        },
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            };
        },
        hover(item, monitor) {
            if (!ref.current) {
                return;
            }

            const targetDepth = getTargetDepth(
                props,
                monitor
              );
            const draggedNode = item.node;
            const needsRedraw =
            // Redraw if hovered above different nodes
            props.node !== draggedNode ||
            // Or hovered above the same node but at a different depth
            targetDepth !== props.path.length - 1;
            if (!needsRedraw) {
                return;
            }

            dragHover({
                node: draggedNode,
                path: item.path,
                minimumTreeIndex: listIndex,
                depth: targetDepth,
            });
        },
    });
    const [{ isDragging }, drag] = useDrag({
        type: ItemTypes.CATEGORY,
        options: { dropEffect: 'copy' },
        item: () => {
            startDrag(path);
            return { 
                node, 
                path,
                treeIndex,
             };
        },
        collect: (monitor) => {
            return {
                isDragging: monitor.isDragging(),
            };
        },
    });

    function Collapsable() {
        if ( node.children &&
          (node.children.length > 0 || typeof node.children === 'function')) {
            return (
                <CollapseButton onClick={() =>
                    toggleChildrenVisibility({
                        path,
                        treeIndex,
                    })
                    }>
                    {node.expanded ? <RemoveRounded/> : <AddRounded />}
                </CollapseButton>
            )
        } 
  
        return null
    }


    const opacity = isDragging ? 0 : 1;
    drag(drop(ref));

    let block = 84;
    let hasChild = node.children &&
    (node.children.length > 0 || typeof node.children === 'function');

    let hasOneChild = node.children &&
    (node.children.length > 1 || typeof node.children === 'function');
   
    const open = Boolean(anchorEl);
    const id = open ? 'node-popover' : undefined;
    return (
        <NodeBox>
            <Card sx={{ display: 'inline-block' }}>
                <CardHeader
                    action={
                    <IconButton aria-label="settings" aria-describedby={id} onClick={onOpen}>
                        <MoreVert />
                    </IconButton>
                    }
                    title={name}
                />
                { hasChild && <CardActions disableSpacing>
                    <ExpandIcon
                        expand={expanded}
                        onClick={handleExpandClick}
                        aria-expanded={expanded}
                        aria-label="show more"
                        >
                        <ExpandMore />
                    </ExpandIcon>
                </CardActions> }
            </Card>
            {
                expanded && 
                <TreeStack spacing={2}>
                    { 
                        node.children.map((child, index) => {
                            if (hasOneChild) {
                                return (
                                    <TreeBox key={index}>
                                        <Node
                                            name={child.name}
                                            node={child}
                                            parentNode={node}/>
                                    </TreeBox>
                                )
                            }
                            return (
                                <SingleTreeBox key={index}>
                                    <Node
                                        name={child.name}
                                        node={child}
                                        parentNode={node} />
                                </SingleTreeBox>
                            )
                        })
                    }
                </TreeStack>
            }
             <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={onClose}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                }}>
               <List>
                    <ActionItem disablePadding>
                        <ListItemButton onClick={() => {
                            // dispatch(employeeOrganizationActions.onSetParent(node))
                            navigate(`/dashboard/job-positions/new/${node._id}`)
                        }}>
                            <ListItemText primary="+ Subposisi Jabatan" />
                        </ListItemButton>
                    </ActionItem>
                    {!node.is_root && <ActionItem disablePadding>
                        <ListItemButton
                            onClick={() => {
                            window.open(`/dashboard/job-positions/${node._id}/edit`);
                        }}>
                            <ListItemText primary="Ubah" />
                        </ListItemButton>
                    </ActionItem>}
                </List>
            </Popover>
        </NodeBox>
       
            // <Grid container style={{ position: 'relative', paddingLeft: (depth * block) }}>
                
            //     <CollapseGrid item sx={hasChild ? {marginRight: '15px'} : { marginRight: 0 }}>
            //         {Collapsable()}
            //     </CollapseGrid>
            //     <Grid item xs >
            //         <NodePaper ref={ref}  elevation={0} style={{...style, opacity}} data-handler-id={handlerId}>
            //             <Grid container justifyContent="space-between" alignItems="center">
            //                 <Grid item xs>
            //                     {title}
            //                 </Grid>
            //                 {/* <Grid item xs container spacing={1} justifyContent="flex-end">
            //                     <Grid item>
            //                         <ActionButton 
            //                             variant="contained"
            //                             size="large"
            //                             disableElevation
            //                             startIcon={<AddRounded />}
            //                             onClick={() => {
            //                                 // dispatch(categoryActions.onSetParent(node.id))
            //                                 // navigate(`/dashboard/categories/new/${node.id}`)
            //                                 }
            //                             }>
            //                             Subkategori
            //                         </ActionButton>
            //                     </Grid>
            //                     <Grid item>
            //                         <ActionButton 
            //                             variant="contained"
            //                             size="large"
            //                             disableElevation
            //                             onClick={() =>
            //                                 {
            //                                 // if (node.parent_id) {
            //                                 //     dispatch(categoryActions.onSetParent(node.parent_id))
            //                                 // }
            //                                 // navigate(`/dashboard/categories/${node.slug}/edit`)
            //                                 }
            //                             }>
            //                             Ubah
            //                         </ActionButton>
            //                     </Grid>
            //                 </Grid> */}
            //             </Grid>
            //         </NodePaper>
            //     </Grid>
            // </Grid>
    );
};

const ExpandIcon = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    margin: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
}));

const ActionItem = styled(ListItem)(({ theme }) => ({
   minWidth: 200
}));


const NodeBox = styled(Box)(({ theme }) => ({
    flex: 'auto',
    textAlign: 'center'
 }));

const lineHeight = 20;
const TreeStack = styled(Stack)(({theme}) => ({
    paddingTop: lineHeight,
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    ':before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: '50%',
        width: 0,
        borderLeft: '2px solid #ddd',
        height: lineHeight
    },
}));

const TreeBox = styled(Box)(({ theme }) => ({
    padding: `${lineHeight}px 15px 0 15px`,
    textAlign: 'center',
    position: 'relative',
    margin: '0 !important',
    flex: 'auto',
    ':before': {
        content: '""',
        position: 'absolute',
        top: 0,
        right: '50%',
        width: '50%',
        borderTop: '2px solid #ddd',
        height: lineHeight
    },
    ':first-of-type::before': {
        border: '0 none'
    },
    ':last-of-type::before': {
        borderRight: '2px solid #ddd',
        borderRadius: '0 5px 0 0'
    },
    ':after': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: '50%',
        borderLeft: '2px solid #ddd',
        width: '50%',
        borderTop: '2px solid #ddd',
        height: lineHeight,
        right: '50%'
    },
    ':first-of-type::after': {
        borderRadius: '5px 0 0 0'
    },
    ':last-of-type::after': {
        border: '0 none'
    },
}));

const SingleTreeBox = styled(Box)(({ theme }) => ({
    padding: `0px 15px 0 15px`,
    textAlign: 'center',
    position: 'relative',
    margin: '0 !important',
    flex: 'auto',
    ':before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: '50%',
        width: '0',
        borderTop: '2px solid #ddd',
        height: lineHeight
    }
}));

const TreePaper = styled(Paper)(({ theme }) => ({
   padding: 25
}));


const CollapseButton = styled(Button)(({theme}) => ({
    color: theme.palette.text.primary,
    backgroundColor: "#FFFFFF",
    '&:hover': {
      backgroundColor: "#FFFFFF",
    },
    fontWeight: 500,
    height: 69,
    width: 69,
    border: `1px solid #ddd`
}));

const ActionButton = styled(Button)(({theme}) => ({
    color: theme.palette.text.primary,
    backgroundColor: "#FFFFFF",
    '&:hover': {
      backgroundColor: "#FFFFFF",
    },
    fontWeight: 500,
    border: `1px solid #ddd`
}));

const NodePaper = styled(Paper)(({theme}) => ({
    padding: '10px 15px',
    border: '1px solid #ddd',
    minHeight: 47,
    zIndex: 99,
    // position: 'relative',
    // '&:before': {
    //     position: 'absolute',
    //     content: '""',
    //     borderBottom: '2px dashed #ddd',
    //     height: 1,
    //     top: '48%',
    //     left: '-5%',
    //     width: '10%',
        
    // },
    // '&:after': {
    //     position: 'absolute',
    //     content: '""',
    //     borderLeft: '2px dashed #ddd',
    //     height: '100%',
    //     top: '50%',
    //     left: -52,
    //     width: 1,
    //     bottom: 0
        
    // },
}));

const CollapseGrid = styled(Grid)(({theme}) => ({
    // '&:before': {
    //     position: 'absolute',
    //     content: '""',
    //     borderBottom: '2px dashed #ddd',
    //     height: 1,
    //     top: '48%',
    //     left: 34,
    //     width: '10%',
        
    // },
    // '&:after': {
    //     position: 'absolute',
    //     content: '""',
    //     borderLeft: '2px dashed #ddd',
    //     height: '100%',
    //     top: '-50%',
    //     left: 34,
    //     width: 1,
    //     bottom: 0
        
    // },
}));
