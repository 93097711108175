export const product_configuration = (data, errors) => {

    function checkName(array) {
        var object = {};
        var result = [];

        array.forEach(function (item) {
			let value = item.name.toLowerCase();
          	if(!object[value]) {
				object[value] = 0;
			} 	
            object[value] += 1;
        })

        for (var prop in object) {
           if(object[prop] >= 2) {
               result.push(prop);
           }
        }

        return result;

	}
    function checkValue(array) {
        var object = {};
        var result = [];

        array.forEach(function (item) {
			let value = item.value.toLowerCase();
          	if(!object[value]) {
				object[value] = 0;
			} 	
            object[value] += 1;
        })

        for (var prop in object) {
           if(object[prop] >= 2) {
               result.push(prop);
           }
        }

        return result;

    }
    
    function checkSwatch(array) {
        var object = {};
        var result = [];

        array.forEach(function (item) {
			if (item.product_configuration_option_swatch_value) {
				let value = item.product_configuration_option_swatch_value.value.toLowerCase();
				if(!object[value]) {
				  object[value] = 0;
			  } 	
			  object[value] += 1;
			}
        })

        for (var prop in object) {
           if(object[prop] >= 2) {
               result.push(prop);
           }
        }

        return result;

	}

	
	const nameValues = checkName(data)
	for (let j = 0; j < nameValues.length; j++) {
		for (let k = 0; k < data.length; k++) {
			let option = data[k];
			if (option.name.toLowerCase() === nameValues[j].toLowerCase()) {
				errors[`product_configurations[${k}].name`] = ['Nama tidak boleh sama']
			}
		}
	}


	for (let i = 0; i < data.length; i++) {
		const values = checkValue(data[i].product_configuration_options)
		for (let j = 0; j < values.length; j++) {
			for (let k = 0; k < data[i].product_configuration_options.length; k++) {
				let option = data[i].product_configuration_options[k];
				if (option.value.toLowerCase() === values[j].toLowerCase()) {
					errors[`product_configurations[${i}].product_configuration_options[${k}].value`] = ['Duplikasi ditemukan']
				}
			}
        }
        const swatches = checkSwatch(data[i].product_configuration_options)
        for (let j = 0; j < swatches.length; j++) {
			for (let k = 0; k < data[i].product_configuration_options.length; k++) {
				let option = data[i].product_configuration_options[k];
				if (option.product_configuration_option_swatch_attributes.value.toLowerCase() === swatches[j].toLowerCase()) {
					errors[`product_configurations[${i}].product_configuration_options[${k}].product_configuration_option_swatch_value.value`] = ['Duplikasi ditemukan']
				}
			}
        }
	}

	for (let i = 0; i < data.length; i++) {
		let configuration = data[i];
		if (configuration.name === '') {
			errors[`product_configurations[${i}].name`] = ['Nama tidak boleh kosong']
		}

		if (!/^[a-zA-Z]*$/g.test(configuration.name)) {
			errors[`product_configurations[${i}].name`] = ['Nama harus huruf / alphabet']
		}

		if (data[i].product_configuration_options.length === 0) {
			errors[`product_configurations[${i}].product_configuration_options`] = ['Opsi dibutuhkan']
		}

		for (let j = 0; j < data[i].product_configuration_options.length; j++) {
			let option = data[i].product_configuration_options[j];
			if (option.value === '') {
				errors[`product_configurations[${i}].product_configuration_options[${j}].value`] = ['Value tidak boleh kosong']
			}
            
    
			
			if (configuration.input_type === 'vswatch') {
				if (!option.product_configuration_option_swatch_value && !option.product_configuration_option_swatch_images) {
					errors[`product_configurations[${i}].product_configuration_options[${j}].product_configuration_option_swatch_value.value`] = ['Value tidak boleh kosong']
				}
				// if (!option.product_configuration_option_swatch_value && option.product_configuration_option_swatch_images.filter(el => {
				// 	return !el._destroy && el.image && !el.onRemove;
				// }).length === 0) {
				// 	errors[`product_configurations[${i}].product_configuration_options[${j}].product_configuration_option_swatch_value.value`] = ['Value tidak boleh kosong']
				// }

				// if (option.product_configuration_option_swatch_attributes.value === '') {
				// 	errors[`product_configurations[${i}].product_configuration_options[${j}].product_configuration_option_swatch.value`] = ['Value tidak boleh kosong']
				// }
			}
		}
	}

	
	console.log(errors)
	

	
}