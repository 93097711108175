import React, { useEffect } from 'react';
import { 
    Grid,
    FormControlLabel,
    CircularProgress,
    Button,
    Typography,
    Box,
    TextField,
    InputAdornment,
    Switch,
    FormGroup,
    MenuItem,
    Paper,
    List,
    ListSubheader,
    ListItem
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import * as userConfigurationActions from '../../../../actions/userConfigurationActions';
import NumberFormat from 'react-number-format';
import { AddRounded } from '@mui/icons-material';
import Loader from '../../../Helper/Loader';


  
export default function Invoice() {
    const dispatch = useDispatch();
    const { current_user, user_configuration, inProgress, errors } = useSelector(state => ({
        ...state.common,
        ...state.user_configuration
      }), shallowEqual); 


    useEffect(() => {
        dispatch(userConfigurationActions.onLoadUserInvoice(current_user._id));
        return () => {
            dispatch(userConfigurationActions.onUnloadUserConfiguration());
        };
    },[]);

    function onToggle(value, event) {
		const key = event.target.name;
        const newUserConfiguration = {...user_configuration};
        newUserConfiguration[key] = value;
        dispatch(userConfigurationActions.onUpdateUserConfiguration(newUserConfiguration));
    }

    function onUpdateBanks(index, event) {
        const key = event.target.name;
        const value = event.target.value;

        const newUserConfiguration = {...user_configuration};
        const { user_configuration_banks } = newUserConfiguration;
        const newBanks = { ...user_configuration_banks[index], [key]: value};
	    user_configuration_banks.splice(index, 1, newBanks);

        dispatch(userConfigurationActions.onUpdateUserConfiguration(newUserConfiguration));
  	}

      function onAddFamily() {
        const addBanks = [...user_configuration.user_configuration_banks, {bank_name: "", bank_account_holder: "", bank_account_number: ""}]
        const newBanks = addBanks.map((bank, index)=> {
            return {...bank, sort_order: index}
        })
        const newUserConfiguration = {...user_configuration};
        newUserConfiguration.user_configuration_banks = newBanks;
        dispatch(userConfigurationActions.onUpdateUserConfiguration(newUserConfiguration));
    }

    function onRemoveBank(index) {
        const newUserConfiguration = {...user_configuration};
        const { user_configuration_banks } = newUserConfiguration;
		if (user_configuration_banks[index]._id) {
			user_configuration_banks[index]._destroy = true
		} else {
			user_configuration_banks.splice(index, 1)
        }

        const newBanks = user_configuration_banks.map((bank, index)=> {
            return {...bank, sort_order: index}
        })
        dispatch(userConfigurationActions.onUpdateUserConfiguration(newUserConfiguration));
    }



    function onEdit() {
        dispatch(userConfigurationActions.onEditUserConfigurationInvoice(user_configuration._id, user_configuration));
    }

    function error(key) {
        if (errors != null && errors[`user_configuration.${key}`] != null) {
            return errors[`user_configuration.${key}`].msg
        }
        return null;
    }

    if (!user_configuration || !user_configuration.user_configuration_banks) {
        return (
            <Loader />
        )
    }

    const { print_store_address, print_currency, user_configuration_banks } = user_configuration;

    function getDestroyedBanks() {
        return user_configuration_banks.filter(el => {
          return el._destroy !== true;
        }).length;
    }
   
   
    return (
        <React.Fragment>
             {/* <Grid container spacing={4} direction="column">
                <Grid item container>
                    <Grid item xs>
                        <Typography fontWeight={700} component="h6" lineheight="normal">
                            Pengaturan Jabatan
                        </Typography>
                    </Grid>
                    <Grid item>
                    </Grid>
                </Grid>
                <Grid item container direction="column" spacing={3}>
                    <Grid item container spacing={8}>
                        <Grid item xs={4}>
                            <Typography fontSize={16} fontWeight={700} component="div">
                                Jabatan Sopir 
                            </Typography>
                            <Typography variant="body2" gutterBottom color="textSecondary">
                                Cetak mata uang (Rp) pada struk dan nota.
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <FormGroup row>
                                <FormControlLabel
                                    control={
                                    <Switch
                                        checked={print_currency}
                                        onChange={onToggle.bind(this, !print_currency)}
                                        name="print_currency"
                                        color="primary"
                                    />
                                    }
                                />
                            </FormGroup>
                        </Grid> 
                    </Grid>
                    <Grid item container spacing={8}>
                        <Grid item xs={4}>
                            <Typography fontSize={16} fontWeight={700} component="div">
                                Cetak Alamat Cabang
                            </Typography>
                            <Typography variant="body2" gutterBottom color="textSecondary">
                                Cetak alamat cabang yang berbeda-beda pada faktur.
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <FormGroup row>
                                <FormControlLabel
                                    control={
                                    <Switch
                                        checked={print_store_address}
                                        onChange={onToggle.bind(this, !print_store_address)}
                                        name="print_store_address"
                                        color="primary"
                                    />
                                    }
                                />
                            </FormGroup>
                        </Grid> 
                    </Grid>
                    
                </Grid>
                <Grid item container sx={{marginBottom: '15px'}} justifyContent="space-between" spacing={3}>
                    <Grid item>
                    </Grid>
                    <Grid item>
                        <Grid container spacing={3}>
                            <Grid item>
                            </Grid>
                            <Grid item>
                                <Button 
                                    variant="contained"
                                    size="large"
                                    color="primary"
                                    sx={{minWidth: 195}}
                                    onClick={onEdit}
                                    disabled={inProgress}
                                    disableElevation>
                                    {inProgress ? <CircularProgress size={26} /> : 'Simpan'}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>  
            </Grid> */}
        </React.Fragment>
    )
}
