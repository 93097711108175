import React, { useState, useEffect} from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import * as categoryActions from '../../../../../../actions/categoryActions';
import Node from './Node';
import { 
    Box,
    Grid,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
    memoizedGetDescendantCount,
    memoizedGetFlatDataFromTree,
    memoizedInsertNode,
} from './utils/memoized-tree-data-utils';
import {
    changeNodeAtPath,
    find,
    insertNode,
    removeNode,
    toggleExpandedForAll,
    walk,
  } from './utils/tree-data-utils';
import { slideRows } from './utils/generic-utils';

export default function TreeNode(props) {
    const dispatch = useDispatch();
    const [stateData, setStateData] = useState({
        draggingTreeData: null,
        draggedNode: null,
        draggedMinimumTreeIndex: null,
        draggedDepth: null,
        dragging: false,
  
        // props that need to be used in gDSFP or static functions will be stored here
        instanceProps: {
          treeData: props.treeData,
          ignoreOneTreeUpdate: false
        },
    });

    useEffect(() => {
        setStateData((prevState) => ({
            ...prevState,
            draggingTreeData: null,
            draggedNode: null,
            draggedMinimumTreeIndex: null,
            draggedDepth: null,
            dragging: false,
      
            // props that need to be used in gDSFP or static functions will be stored here
            instanceProps: {
              treeData: props.treeData,
              ignoreOneTreeUpdate: false
            },
        }));
    }, [props.treeData]);

    function toggleChildrenVisibility({ path }) {
        const { instanceProps } = stateData;
    
        const newTreeData = changeNodeAtPath({
          treeData: instanceProps.treeData,
          path,
          newNode: ({ node }) => ({ ...node, expanded: !node.expanded }),
          getNodeKey: props.getNodeKey,
        });
    
        setStateData((prevState) => ({
            ...prevState,
            instanceProps: {
                treeData: newTreeData
            }
        }));
    }

    function startDrag(path) {
        setStateData((prevState) => {
            const {
                treeData: draggingTreeData,
                node: draggedNode,
                treeIndex: draggedMinimumTreeIndex,
            } = removeNode({
                treeData: prevState.instanceProps.treeData,
                path,
                getNodeKey: props.getNodeKey,
            });
            return {
                ...prevState,
                draggingTreeData,
                draggedNode,
                draggedDepth: path.length - 1,
                draggedMinimumTreeIndex,
                dragging: true,
            };
        });
    }

    function dragHover({
        node: draggedNode,
        depth: draggedDepth,
        minimumTreeIndex: draggedMinimumTreeIndex,
      }) {
          
        // Ignore this hover if it is at the same position as the last hover
        if (
          stateData.draggedDepth === draggedDepth &&
          stateData.draggedMinimumTreeIndex === draggedMinimumTreeIndex
        ) {
          return;
        }
        
    
        setStateData(({draggingTreeData, instanceProps}) => {
            // Fall back to the tree data if something is being dragged in from
            //  an external element
            const newDraggingTreeData = draggingTreeData || instanceProps.treeData;
        
            const addedResult = memoizedInsertNode({
                treeData: newDraggingTreeData,
                newNode: draggedNode,
                depth: draggedDepth,
                minimumTreeIndex: draggedMinimumTreeIndex,
                expandParent: true,
                getNodeKey: props.getNodeKey,
            });
        
            const rows = getRows(addedResult.treeData);
            const expandedParentPath = rows[addedResult.treeIndex].path;
        
            return {
                draggedNode,
                draggedDepth,
                draggedMinimumTreeIndex,
                draggingTreeData: changeNodeAtPath({
                treeData: newDraggingTreeData,
                path: expandedParentPath.slice(0, -1),
                newNode: ({ node }) => ({ ...node, expanded: true }),
                getNodeKey: props.getNodeKey,
                }),
                dragging: true,
            };
        });
    }

    function onDrop({
        node,
        path: prevPath,
        treeIndex: prevTreeIndex,
        depth,
        minimumTreeIndex,
      }) {
        const {
          treeData,
          treeIndex,
          path,
          parentNode: nextParentNode,
        } = insertNode({
          treeData: stateData.draggingTreeData,
          newNode: node,
          depth,
          minimumTreeIndex,
          expandParent: true,
          getNodeKey: props.getNodeKey,
        });
        
        props.onChange(treeData);
        // const category={parent_id: nextParentNode ? nextParentNode.id : ''};
        // dispatch(categoryActions.onDragEnd(node.id, category));
        // this.props.onUpdate(path[path.length - 1],  path[path.length - 2])
        // this.props.onMoveNode({
        //   treeData,
        //   node,
        //   treeIndex,
        //   path,
        //   nextPath: path,
        //   nextTreeIndex: treeIndex,
        //   prevPath,
        //   prevTreeIndex,
        //   nextParentNode,
        // });
    }

    function getRows(treeData) {
        return memoizedGetFlatDataFromTree({
            ignoreCollapsed: true,
            getNodeKey: props.getNodeKey,
            treeData,
        });
    }

    const { draggingTreeData, draggedNode, draggedMinimumTreeIndex, draggedDepth, instanceProps } = stateData;
    const treeData = draggingTreeData || instanceProps.treeData;

    let rows
    let swapFrom = null;
    let swapLength = null;
    if (draggedNode && draggedMinimumTreeIndex !== null) {
        const addedResult = memoizedInsertNode({
          treeData,
          newNode: draggedNode,
          depth: draggedDepth,
          minimumTreeIndex: draggedMinimumTreeIndex,
          expandParent: true,
          getNodeKey: props.getNodeKey,
        });
        const swapTo = draggedMinimumTreeIndex;
        swapFrom = addedResult.treeIndex;
        swapLength = 1 + memoizedGetDescendantCount({ node: draggedNode });
        rows = slideRows(
        //   getRows(addedResult.treeData),
          addedResult.treeData,
          swapFrom,
          swapTo,
          swapLength
        );
    } else {
        // rows = getRows(treeData);
        rows = treeData;
    }

    if (!rows) {
        return null;
    }
    return (
        <TreeBox>
            {
                rows.map((row, index) => {
                    console.log(row.name)
                    return (
                        <Node 
                            key={index}
                            name={row.name}
                            node={row}
                             />
                    )
                })
            }
            {/* {
                rows.map((row, index) => {
                    const { node, parentNode, path, lowerSiblingCounts, treeIndex } = row;
                    const level = lowerSiblingCounts.length - 1;
                    return (
                        <Box key={node.id} className={`level-${level}`}>
                            <Node
                                listIndex={index} 
                                node={node} 
                                startDrag={startDrag}
                                dragHover={dragHover}
                                onDrop={onDrop}
                                parentNode={parentNode}
                                id={node.id} 
                                treeIndex={treeIndex}
                                path={path}
                                depth={level}
                                title={node.title} 
                                scaffoldBlockPxWidth={79}
                                getPrevRow={ () => rows[index - 1] || null}
                                toggleChildrenVisibility={toggleChildrenVisibility}
                                />
                        </Box>
                    )
                })
            }  */}
        </TreeBox>
    );
   
    
}

const TreeBox = styled(Box)(({theme}) => ({
   display: 'flex',
   justifyContent: 'center',
   alignItems: 'center'
}));