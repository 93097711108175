import React, { useEffect } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { 
    Typography,
    Box,
    DialogContent,
    Grid,
    TextField,
    FormControl,
    RadioGroup,
    Radio,
    FormControlLabel,
    FormHelperText,
    Autocomplete,
    Select,
    MenuItem,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import * as customerActions from '../../../../../../../actions/customerActions';
import { useDispatch } from 'react-redux';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
// import {
//     DatePicker,
//     MuiPickersUtilsProvider
//   } from '@material-ui/pickers';
// import DateFnsUtils from '@date-io/date-fns';

export default function Content() {
    const dispatch = useDispatch();
    const {editCustomer, customer, customer_groups, gender_options, errors} = useSelector(state => ({
        ...state.customer
    }), shallowEqual);

    useEffect(() => {
        dispatch(customerActions.onLoadEdit());
    },[]);


    function onUpdateField(event) {
		const key = event.target.name;
        const value = event.target.value;
        const newEditCustomer = {...editCustomer};
        newEditCustomer[key] = value;
        dispatch(customerActions.onUpdateEdit(newEditCustomer));
    }

    const onSelectDate = (date) => {
        const newEditCustomer = {...editCustomer};
        newEditCustomer.date_of_birth = date;
        dispatch(customerActions.onUpdateEdit(newEditCustomer));
    };

    function onSelectGender(option) {
        const newEditCustomer = {...editCustomer};
        newEditCustomer.gender_option_id = option._id;
        dispatch(customerActions.onUpdateEdit(newEditCustomer));
    }

    function onSelectGroup(option) {
        const newEditCustomer = {...editCustomer};
        newEditCustomer.customer_group_id = option._id;
        dispatch(customerActions.onUpdateEdit(newEditCustomer));
    }

    const onKeyDown = (e) => {
        e.preventDefault();
    };
    

    if (!editCustomer) {
        return null;
    }
    
    function error(key) {
        if (errors != null && errors[key] != null) {
            return errors[key][0]
        }

        return null;
    }
    const { first_name, last_name, date_of_birth, gender_option_id, customer_group_id } = editCustomer;
    
    const genderValue = gender_options.find(opt => opt._id === gender_option_id)
    const groupValue = customer_groups.find(opt => opt._id === customer_group_id)
    return (
            <DialogContent>
                <Grid container direction="column" spacing={2}>
                    <Grid item container spacing={8}>
                        <Grid item xs={6}>
                            <Typography fontSize={16} fontWeight={700} component="div">
                                Nama Depan
                            </Typography>
                            <Typography variant="body2" gutterBottom color="textSecondary">
                                Nama depan pelanggan
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                value={first_name}
                                disabled
                                variant="outlined"
                            />
                        </Grid> 
                    </Grid>
                    <Grid item container spacing={8}>
                        <Grid item xs={6}>
                            <Typography fontSize={16} fontWeight={700} component="div">
                                Nama Belakang
                            </Typography>
                            <Typography variant="body2" gutterBottom color="textSecondary">
                                Nama belakang pelanggan
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                value={last_name}
                                disabled
                                variant="outlined"
                            />
                        </Grid> 
                    </Grid>
                    <Grid item container spacing={8}>
                        <Grid item xs={6}>
                            <Typography fontSize={16} fontWeight={700} component="div">
                                Tanggal Lahir
                            </Typography>
                            <Typography variant="body2" gutterBottom color="textSecondary">
                                Tanggal lahir pelanggan
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DatePicker
                                    openTo="year"
                                    disableFuture
                                    value={date_of_birth}
                                    format="dd/MM/yyyy"
                                    onChange={onSelectDate}
                                    renderInput={(params) => <TextField onKeyDown={onKeyDown} {...params} />}
                                />
                            </LocalizationProvider>
                        </Grid> 
                    </Grid>
                    <Grid item container spacing={8}>
                        <Grid item xs={6}>
                            <Typography fontSize={16} fontWeight={700} component="div">
                                Jenis Kelamin
                            </Typography>
                            <Typography variant="body2" gutterBottom color="textSecondary">
                                Jenis kelamin pelanggan
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Autocomplete
                                options={gender_options.sort((a, b) => -b.name.localeCompare(a.name))}
                                getOptionLabel={(option) => option.name}
                                selectOnFocus
                                clearOnBlur
                                filterSelectedOptions
                                disableClearable
                                onChange={(event, option) => {
                                    onSelectGender(option)
                                }}
                                value={genderValue ? genderValue : null}
                                renderInput={(params) => <TextField {...params} error={!!error('gender_option_id')} helperText={error('gender_option_id')} placeholder="Pilih Jenis Kelamin" variant="outlined" />}
                            />
                        </Grid> 
                    </Grid>
                    <Grid item container spacing={8}>
                        <Grid item xs={6}>
                            <Typography fontSize={16} fontWeight={700} component="div">
                                Grup Pelanggan
                            </Typography>
                            <Typography variant="body2" gutterBottom color="textSecondary">
                                Pilih dan ubah grup pelanggan
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Autocomplete
                                options={customer_groups.sort((a, b) => -b.name.localeCompare(a.name))}
                                getOptionLabel={(option) => option.name}
                                selectOnFocus
                                clearOnBlur
                                filterSelectedOptions
                                disableClearable
                                onChange={(event, option) => {
                                    onSelectGroup(option)
                                }}
                                value={groupValue ? groupValue : null}
                                renderInput={(params) => <TextField {...params} error={!!error('customer_group_id')} helperText={error('customer_group_id')} placeholder="Pilih Group Pelanggan" variant="outlined" />}
                            />
                        </Grid> 
                    </Grid>
                </Grid>
                {/* <Grid container spacing={2}>
                    
    
                    <Grid item container spacing={8} >
                        <Grid item xs={6} container direction="column">
                            <Grid item xs>
                                <Typography lineheight="normal" component="div">
                                    <Box fontSize={16} fontWeight={700}>
                                        Jenis Kelamin
                                    </Box>
                                </Typography>
                                <Typography variant="body2" gutterBottom color="textSecondary">
                                    Jenis kelamin pelanggan
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl
                                fullWidth variant="outlined">
                                <Select
                                labelId="gender-option"
                                value={gender_option_id ? Number(gender_option_id) : ""}
                                name="gender_option_id"
                                onChange={onUpdateField}
                                >
                                <MenuItem value="" disabled>
                                    Pilih Jenis Kelamin
                                </MenuItem>
                                {
                                    gender_options.map(option => {
                                        return(
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.title}
                                            </MenuItem>
                                        )
                                    })
                                }
                                </Select>
                            </FormControl>
                        </Grid> 
                    </Grid>
                    <Grid item container spacing={8} >
                        <Grid item xs={6} container direction="column">
                            <Grid item xs>
                                <Typography lineheight="normal" component="div">
                                    <Box fontSize={16} fontWeight={700}>
                                        Grup Pelanggan
                                    </Box>
                                </Typography>
                                <Typography variant="body2" gutterBottom color="textSecondary">
                                    Pilih dan ubah grup pelanggan
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl 
                                fullWidth
                                variant="outlined">
                                <Select
                                    name="customer_group_id"
                                    value={customer_group_id}
                                    onChange={onUpdateField}
                                    displayEmpty
                                    inputProps={{ 'aria-label': 'Without label' }}>
                                    <MenuItem value="" disabled>
                                        Pilih Grup Pelanggan
                                    </MenuItem>
                                {
                                    customer_groups.map(option => {
                                        return(
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.title}
                                            </MenuItem>
                                        )
                                    })
                                }
                                </Select>
                            </FormControl>
                        </Grid> 
                    </Grid>

                    
                </Grid> */}
            </DialogContent>
    )
}