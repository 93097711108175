import * as types from './actionTypes';
import { axiosClient } from '../api';

export const onLoadInitialize = (id) => {
	const fetch = axiosClient.get(`/initializes/${id}/edit`);
	return {
		type: types.INITIALIZE_LOADED,
		payload: fetch
	}
}

export const onUnloadInitialize = () => {
	return {
		type: types.INITIALIZE_UNLOADED
	}
}

export const onUpdateUser = (user) => {
	return {
        type: types.USER_UPDATED,
        user
	}
}

export const onLoadAreas = (search) => {
	const fetch = axiosClient.get(`/areas`,
	{
		params: {search}
	});
	return {
		type: types.INITIALIZE_AREAS_LOADED,
		payload: fetch
	}
}

export const onUnloadAreas = () => {
	return {
		type: types.INITIALIZE_AREAS_UNLOADED
	}
}

export const onInitialize = (id, user) => {
	const fetch = axiosClient.put(`/initializes/${id}`, {user});
	return {
		type: types.USER_INITIALIZED,
		payload: fetch
	}
}

export const onValidation = (errors) => {	
    return {
        type: types.FORM_VALIDATION,
        errors
    }
	
}