
import * as types from './actionTypes';
import { axiosClient } from '../api';

export const onLoadStores = (query) => {
	const fetch = axiosClient.get(`/stores`,
	{
		params: query
	});
	return {
		type: types.STORES_LOADED,
		payload: fetch
	}
}

export const onStoreNew = () => {
	const fetch = axiosClient.get(`/stores/new`);
	return {
		type: types.NEW_STORE_LOADED,
		payload: fetch
	}
}


export const onStoreEdit = (id) => {
	const fetch = axiosClient.get(`/stores/${id}/edit`);
	return {
		type: types.EDIT_STORE_LOADED,
		payload: fetch
	}
}

export const onUpdateStore = (store) => {
	return {
		type: types.STORE_UPDATED,
		store
	}
}

// export const onUpdateStoreProducts = (store_products) => {
// 	return {
// 		type: types.STORE_PRODUCTS_UPDATED,
// 		store_products
// 	}
// }

export const onCreate = (store) => {
	const fetch = axiosClient.post(`/stores`, {store});
	return {
		type: types.STORE_CREATED, 
		payload: fetch
	}	
}

export const onEdit = (storeId, store) => {
	const fetch = axiosClient.put(`/stores/${storeId}`, {store});
	return {
		type: types.STORE_EDITED, 
		payload: fetch
	}
}

export const onUnloadForm = () => {
	return {
		type: types.STORE_FORM_UNLOADED
	}
}

export const onLoadStore = (id) => {
	const fetch = axiosClient.get(`/stores/${id}`);
	return {
		type: types.STORE_LOADED,
		payload: fetch
	}
}

export const onUnloadStore = () => {
	return {
		type: types.STORE_UNLOADED
	}
}

export const onShowInventories = () => {
	return {
		type: types.STORE_INVENTORIES_SHOWED
	}
}

export const onHideInventories = () => {
	return {
		type: types.STORE_INVENTORIES_HIDED
	}
}

export const onLoadInventories = (id, query) => {
	const fetch = axiosClient.get(`/stores/${id}/inventories`,
	{
		params: query
	});
	return {
		type: types.STORE_INVENTORIES_LOADED,
		payload: fetch
	}
}

// export const onEditLowStock = (id, inventory, store) => {
    
//     const fetch = axiosClient.put(`/inventories/${id}`, {inventory, store});
//     return {
//         type: types.STORE_LOW_STOCK_UPDATED, 
//         payload: fetch
//     }
// }
