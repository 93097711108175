import * as types from './actionTypes';
import { axiosClient } from '../api';


export const onLoadPurchaseOrderReturns = (query) => {
	const fetch = axiosClient.get(`/purchase-order-returns`,
	{
		params: query
	});
	return {
		type: types.PURCHASE_ORDER_RETURNS_LOADED,
		payload: fetch
	}
}


export const onNew = () => {
	const fetch = axiosClient.get(`/purchase-order-returns/new`);
	return {
		type: types.NEW_PURCHASE_ORDER_RETURN_LOADED,
		payload: fetch
	}
}

export const onEdit = (id) => {
	const fetch = axiosClient.get(`/purchase-order-returns/${id}/edit`);
	return {
		type: types.EDIT_PURCHASE_ORDER_RETURN_LOADED,
		payload: fetch
	}
}

export const onUnloadPurchaseOrderReturn = () => {
	return {
		type: types.PURCHASE_ORDER_RETURN_UNLOADED
	}
}

export const onUpdatePurchaseOrderReturn = (purchase_order_return) => {
	return {
		type: types.PURCHASE_ORDER_RETURN_UPDATED,
		purchase_order_return
	}
}

export const onSelectPurchaseOrder = (purchase_order_return) => {
    const fetch = axiosClient.get(`/purchase-order-returns/${purchase_order_return.purchase_order_id}/products`);
	return {
		type: types.PURCHASE_ORDER_RETURN_SELECTED,
        payload: fetch,
		purchase_order_return
	}
}

export const onSelectProduct = (product_id, purchase_order_id) => {
	const fetch = axiosClient.get(`/purchase-order-returns/${purchase_order_id}/product/?product_id=${product_id}`);
	return {
		type: types.PURCHASE_ORDER_RETURN_PRODUCT_SELECTED, 
		payload: fetch
	}
}

export const onSelectedProductOptions = (new_return_items, id) => {
	return {
		type: types.PURCHASE_ORDER_RETURN_ITEMS_SELECTED,
		new_return_items,
		id
	}
}

export const onHandleVariants = (id) => {
	return {
		type: types.PURCHASE_ORDER_RETURN_VARIANTS_SHOWED,
		id
	}
}

export const onHideVariants = () => {
	return {
		type: types.PURCHASE_ORDER_RETURN_VARIANTS_HIDED
	}
}

export const onSelectVariant = (variantProduct, label, value) => {
	return {
		type: types.PURCHASE_ORDER_RETURN_VARIANT_SELECTED,
		variantProduct,
		label,
		value
	}
}

export const onHandleProductVariant = (variantProduct, label, value) => {
	return {
		type: types.PURCHASE_ORDER_RETURN_PRODUCT_VARIANT_SELECTED,
		variantProduct,
		label,
		value
	}
}

export const onRestoreProductOptions = (options, id, sku) => {
	return {
		type: types.RESTORE_RETURN_PRODUCT_OPTIONS_HANDLED,
		options,
		id,
		sku
	}
}

export const onCreate = (purchase_order_return) => {
	const fetch = axiosClient.post(`/purchase-order-returns`, {purchase_order_return});
	return {
		type: types.PURCHASE_ORDER_RETURN_CREATED, 
		payload: fetch
	}	
}

export const onShowConfirm = (action_type) => {
	return {
		type: types.PURCHASE_ORDER_RETURN_CONFIRM_SHOWED,
		action_type
	}
}

export const onHideConfirm = () => {
	return {
		type: types.PURCHASE_ORDER_RETURN_CONFIRM_HIDED
	}
}

export const onApproved = (id, purchase_order_return) => {
	const fetch = axiosClient.put(`/purchase-order-returns/${id}/approved`, {purchase_order_return});
	return {
		type: types.PURCHASE_ORDER_RETURN_EDITED, 
		payload: fetch
	}
}

export const onReturned = (id, purchase_order_return) => {
	const fetch = axiosClient.put(`/purchase-order-returns/${id}/returned`, {purchase_order_return});
	return {
		type: types.PURCHASE_ORDER_RETURN_EDITED, 
		payload: fetch
	}
}

export const onCanceled = (id, purchase_order_return) => {
	const fetch = axiosClient.put(`/purchase-order-returns/${id}/canceled`, {purchase_order_return});
	return {
		type: types.PURCHASE_ORDER_RETURN_EDITED, 
		payload: fetch
	}
}