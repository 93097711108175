import React, { useEffect, useState } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import * as customerActions from '../../../../../../../actions/customerActions';
import * as commonActions from '../../../../../../../actions/commonActions';
import { 
    Typography,
    Box,
    Button,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Grid,
    CircularProgress,
    Autocomplete
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useParams } from "react-router-dom";

function Content({customer_address, areas, errors, areasFetching}) {
    const dispatch = useDispatch();

    const [loaded, setLoaded] = useState(false);
    const [value, setValue] = useState(null);
    const [inputValue, setInputValue] = useState('');
    const [areaOptions, setAreaOptions] = useState([]);

    useEffect(() => {
        function initialize() {
            const { state, city, district, code_area } = customer_address;
            if (!!state && !!city && !!district && !!code_area) {
                const newOptions = [];
                const selectedOption = {value: `${state}, ${city}, ${district}, ${code_area}`, name: `${state}, ${city}, ${district}, ${code_area}`};
                newOptions.push(selectedOption);
                setAreaOptions(newOptions)
                setValue(selectedOption);
            }
        }
        if (!loaded) {
            initialize();
            setLoaded(true);
        }
    },[customer_address, loaded]);

    useEffect(() => {
        setAreaOptions(areas)
    }, [areas]);

    function onUpdateField(event) {
		const key = event.target.name;
        const value = event.target.value;
        const newCustomerAddress = {...customer_address};
        newCustomerAddress[key] = value;
        dispatch(customerActions.onUpdateCustomerAddress(newCustomerAddress));
    }


    function onSelectArea(option) {
        if (!option) {
            return;
        }
        setValue(option);
        const newCustomerAddress = {...customer_address};
        const selectedArea = option.value.split(', ');
        newCustomerAddress.state = selectedArea[0];
        newCustomerAddress.city = selectedArea[1];
        newCustomerAddress.district = selectedArea[2];
        newCustomerAddress.code_area = selectedArea[3];
        dispatch(customerActions.onUpdateCustomerAddress(newCustomerAddress));
    }



    function error(key) {
        if (errors != null && errors[`customer_address.${key}`] != null) {
            return errors[`customer_address.${key}`].msg
        }
        return null;
    }

    const { recipient, phone, address } = customer_address;    
    
    return (
        <Box sx={{minHeight: '450px', marginTop: '25px'}}>
            <Grid container direction="column" spacing={5}>
                <Grid item container spacing={8}>
                    <Grid item xs={4}>
                        <Typography fontSize={16} fontWeight={700} component="div">
                            Nama & No. HP Penerima
                        </Typography>
                        <Typography variant="body2" gutterBottom color="textSecondary">
                            Masukan nama & No. HP penerima
                        </Typography>
                    </Grid>
                    <Grid item xs={8} container spacing={3}>
                        <Grid item xs>
                            <TextField
                                fullWidth
                                error={!!error('recipient')}
                                name="recipient"
                                placeholder="Penerima"
                                value={recipient}
                                helperText={error('recipient')}
                                variant="outlined"
                                onChange={onUpdateField}
                            />
                        </Grid>
                        <Grid item xs>
                            <TextField
                                fullWidth
                                error={!!error('phone')}
                                name="phone"
                                placeholder="No. HP"
                                value={phone}
                                helperText={error('phone')}
                                variant="outlined"
                                onChange={onUpdateField}
                            />
                        </Grid>
                    </Grid> 
                </Grid>
                <Grid item container spacing={8}>
                    <Grid item xs={4}>
                        <Typography fontSize={16} fontWeight={700} component="div">
                            Provinsi, Kota, Kecamatan
                        </Typography>
                        <Typography variant="body2" gutterBottom color="textSecondary">
                            Masukan wilayah pengiriman
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Autocomplete
                            fullWidth
                            onClose={() => {
                                dispatch(commonActions.onUnloadAreas());
                            }}
                            isOptionEqualToValue={(option, value) => true}
                            getOptionLabel={(option) => option.name}
                            options={areaOptions}
                            noOptionsText={!!inputValue && areas.length === 0 && !value ? 'Data tidak ditemukan.' : 'Silahkan masukan kode pos.'}
                            loading={areasFetching}
                            value={value}
                            onChange={(event, newValue) => {
                                onSelectArea(newValue)
                            }}
                            onInputChange={(event, newInputValue) => {
                                setInputValue(newInputValue);
                                if (newInputValue.length < 5 || newInputValue.length > 5) {
                                    return;
                                }
                                dispatch(commonActions.onLoadAreas(newInputValue));
                            }}
                            renderOption={(props, option, { selected }) => (
                                <li {...props} style={{backgroundColor: "#fff"}}>
                                    {option.name}
                                </li>
                            )}
                            inputValue={inputValue}
                            renderInput={(params) => (
                                <TextField
                                {...params}
                                error={!!error('state')}
                                helperText={error('state')}
                                placeholder="Provinsi, Kota, Kecamatan, Kode Pos"
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                    <React.Fragment>
                                        {areasFetching ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                    ),
                                }}
                                />
                            )}/>
                    </Grid> 
                </Grid>
                
                <Grid item container spacing={8}>
                    <Grid item xs={4}>
                        <Typography fontSize={16} fontWeight={700} component="div">
                            Alamat
                        </Typography>
                        <Typography variant="body2" gutterBottom color="textSecondary">
                            Alamat pengiriman
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <TextField
                            fullWidth
                            error={!!error('address')}
                            rows={6}
                            multiline
                            name="address"
                            placeholder="Alamat"
                            value={address}
                            helperText={error('address')}
                            variant="outlined"
                            onChange={onUpdateField}
                        />
                    </Grid> 
                </Grid>
            </Grid>
        </Box>
    )
}

export default function Form() {
    let { id } = useParams();
    const dispatch = useDispatch();
    const {customer_address, errors,  areas, areasFetching, inProgress, address_id} = useSelector(state => ({
        ...state.customer
    }), shallowEqual);

    function onCancel() {
        dispatch(customerActions.onCancelForm());
    }

    function onSubmit() {
        if (customer_address._id) {
            dispatch(customerActions.onEditAddress(id, customer_address));
        } else {
            dispatch(customerActions.onCreateAddress(id, customer_address));
        }
    }

    return (
        <React.Fragment>
            <DialogTitle textAlign="center" style={{textTransform: 'capitalize'}}>
                Alamat Pelanggan
            </DialogTitle>
            <DialogContent>
                <Content
                    customer_address={customer_address}
                    areas={areas}
                    errors={errors}
                    areasFetching={areasFetching} />
            </DialogContent>
            <DialogActions sx={{padding: '25px', justifyContent: 'space-between'}}>
                <BackButton  
                    size="large"
                    disableElevation
                    variant="contained"
                    onClick={onCancel} 
                    disabled={inProgress}
                    color="secondary">
                    Batal
                </BackButton>
                <Button size="large"
                    sx={{minWidth: 195}}
                    disableElevation
                    onClick={onSubmit}
                    disabled={inProgress}
                    variant="contained" color="primary">
                    {inProgress ? <CircularProgress size={26} /> : 'Simpan'}
                </Button>
            </DialogActions>
        </React.Fragment>
    )
}

const BackButton = styled(Button)(({theme}) => ({
    color: theme.palette.text.primary,
    backgroundColor: "#FFFFFF",
    '&:hover': {
    backgroundColor: "#FFFFFF",
    },
    fontWeight: 500,
    border: '1px solid #ddd',
    minWidth: 195
}));