

import * as types from './actionTypes';
import { axiosClient } from '../api';

export const onLoadCustomers = (query) => {
	const fetch = axiosClient.get(`/customers`,
	{
		params: query
	});
	return {
		type: types.CUSTOMERS_LOADED,
		payload: fetch
	}
}


// export const onCustomerNew = () => {
//     const fetch = axiosClient.get(`/customers/new`);
// 	return {
// 		type: types.NEW_CUSTOMER_LOADED,
// 		payload: fetch
// 	}
// }

// export const onUnloadForm = () => {
// 	return {
// 		type: types.CUSTOMER_FORM_UNLOADED
// 	}
// }





// export const onUpdateCustomer = (customer) => {
// 	return {
// 		type: types.CUSTOMER_UPDATED,
// 		customer
// 	}
// }

// export const onCreate = (customer) => {
// 	const fetch = axiosClient.post(`/customers`, {customer});
// 	return {
// 		type: types.CUSTOMER_CREATED, 
// 		payload: fetch
// 	}
	
// }

export const onEdit = (id, customer) => {
	const fetch = axiosClient.put(`/customers/${id}`, {customer});
	return {
		type: types.CUSTOMER_EDITED,
		payload: fetch
	}
}

export const onLoadCustomer = (id) => {
	const fetch = axiosClient.get(`/customers/${id}`);
	return {
		type: types.CUSTOMER_LOADED,
		payload: fetch
	}
}

export const onUnloadCustomer = () => {
	return {
		type: types.CUSTOMER_UNLOADED
	}
}

export const onShowCustomerOrders = () => {
	return {
		type: types.CUSTOMER_ORDERS_SHOWED
	}
}

export const onHideCustomerOrders = () => {
	return {
		type: types.CUSTOMER_ORDERS_HIDED
	}
}


export const onLoadCustomerOrders = (id, query) => {
    const fetch = axiosClient.get(`/customers/${id}/orders`,
    {
		params: query
	});
	return {
		type: types.CUSTOMER_ORDERS_LOADED,
		payload: fetch
	}
}

export const onLoadCustomerOrderItems = (id, query) => {
    const fetch = axiosClient.get(`/customers/${id}/order-items`,
    {
		params: query
	});
	return {
		type: types.CUSTOMER_ORDER_ITEMS_LOADED,
		payload: fetch
	}
}

export const onShowAddresses = () => {
	return {
		type: types.CUSTOMER_ADDRESSES_SHOWED
	}
}



export const onHideAddresses = () => {
	return {
		type: types.CUSTOMER_ADDRESSES_HIDED
	}
}

export const onLoadCustomerAddresses = (id) => {
    const fetch = axiosClient.get(`/customers/${id}/addresses`);
	return {
		type: types.CUSTOMER_ADDRESSES_LOADED,
		payload: fetch
	}
}

export const onUnloadCustomerAddresses = () => {
	return {
		type: types.CUSTOMER_ADDRESSES_UNLOADED
	}
}

export const onNewAddresses = () => {
	return {
		type: types.NEW_CUSTOMER_ADDRESS_LOADED
	}
}

export const onEditAddresses = (address) => {
	return {
        type: types.EDIT_CUSTOMER_ADDRESS_LOADED,
        address
	}
}

export const onCancelForm = () => {
	return {
		type: types.CUSTOMER_ADDRESSES_FORM_CANCELED
	}
}

export const onUpdateCustomerAddress = (customer_address) => {
	return {
		type: types.CUSTOMER_ADDRESS_UPDATED,
		customer_address
	}
}

export const onCreateAddress = (id, customer_address) => {
	const fetch = axiosClient.post(`/customer-addresses`, {id, customer_address});
	return {
		type: types.CUSTOMER_ADDRESS_CREATED, 
		payload: fetch
	}
}


export const onEditAddress = (id, customer_address) => {
	const fetch = axiosClient.put(`/customer-addresses/${id}`, {customer_address});
	return {
		type: types.CUSTOMER_ADDRESS_EDITED, 
		payload: fetch
	}
}

export const onShowEdit = () => {
	return {
		type: types.CUSTOMER_EDIT_SHOWED
	}
}

export const onHideEdit = () => {
	return {
		type: types.CUSTOMER_EDIT_HIDED
	}
}

export const onLoadEdit = () => {
	return {
		type: types.CUSTOMER_EDIT_LOADED
	}
}


export const onUpdateEdit = (customer) => {
	return {
		type: types.EDIT_CUSTOMER_UPDATED,
		customer
	}
}