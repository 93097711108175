let axios = require('axios');


const baseURL = '/api';

export const axiosClient = axios.create({
	baseURL
});

export const setAuthorizationToken = (token) => {
	if (token) {
		axiosClient.defaults.headers.common['Authorization'] = `Bearer ${token}`;
	} else {
		delete axios.defaults.headers.common['Authorization'];
	}
}
