import React, {useEffect} from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import * as employeeActions from '../../../../../../../../actions/employeeActions';
import { 
    Typography,
    Grid,
    Paper,
    Button,
    TextField,
    CircularProgress,
    ListSubheader,
    List,
    ListItem,
    Autocomplete,
    Box,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import NumberFormat from 'react-number-format';
import { styled } from '@mui/material/styles';
import { useParams } from "react-router-dom";
import { AddRounded } from '@mui/icons-material';

const CustomFormat = React.forwardRef((props, ref) => (
    <NumberFormat
        {...props.other}
        onValueChange={(values) => {
          props.onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        customInput={TextField}
        placeholder={props.placeholder}
        value={props.value}
        helperText={props.helperText}
        error={props.error}
        fullWidth={props.fullWidth}
        allowLeadingZeros={true}
        allowNegative={false}
        allowedDecimalSeparators={false}
        isNumericString
      />
));

export default function Edit() {
    let { id } = useParams();
    const dispatch = useDispatch();
    const { editEmployee, gender_options, family_relationships, religion_options, errors, inProgress } = useSelector(state => ({
        ...state.employee
    }), shallowEqual);
    
    const { employee_families } = editEmployee;

    useEffect(() => {
        if (employee_families.length === 0) {
            const addFamilies = [...employee_families, {full_name: "", family_relationship_id: "", identity_number: "", gender_option_id: "", place_of_birth: "", date_of_birth: new Date(), religion_option_id: "", job: "" }]
            const newFamilies = addFamilies.map((family, index)=> {
                return {...family, sort_order: index}
            })
            dispatch(employeeActions.onUpdateFamilies(newFamilies));
        }
        return () => {
            dispatch(employeeActions.onUnloadEditEmployee());
        };
    },[]);

    function onUpdateFamilies(index, event) {
        const key = event.target.name;
        const value = event.target.value;
	    const newFamilies = { ...employee_families[index], [key]: value}
	    employee_families.splice(index, 1, newFamilies)
        dispatch(employeeActions.onUpdateFamilies(employee_families));
  	}

    function onSelectFamilyRelationship(index, option) {
        const newFamilies = { ...employee_families[index], family_relationship_id: option._id};
        employee_families.splice(index, 1, newFamilies);
        dispatch(employeeActions.onUpdateFamilies(employee_families));
    }

    function onSelectGender(index, option) {
        const newFamilies = { ...employee_families[index], gender_option_id: option._id};
        employee_families.splice(index, 1, newFamilies);
        dispatch(employeeActions.onUpdateFamilies(employee_families));
    }

    function onSelectDate(index, date) {
        const newFamilies = { ...employee_families[index], date_of_birth: date};
        employee_families.splice(index, 1, newFamilies);
        dispatch(employeeActions.onUpdateFamilies(employee_families));
    };

    function onSelectReligion(index, option) {
        const newFamilies = { ...employee_families[index], religion_option_id: option._id};
        employee_families.splice(index, 1, newFamilies);
        dispatch(employeeActions.onUpdateFamilies(employee_families));
    }

    function onAddFamily() {
        const addFamilies = [...employee_families, {full_name: "", family_relationship_id: "", identity_number: "", gender_option_id: "", place_of_birth: "", date_of_birth: new Date(), religion_option_id: "", job: "" }]
        const newFamilies = addFamilies.map((family, index)=> {
            return {...family, sort_order: index}
        })
        dispatch(employeeActions.onUpdateFamilies(newFamilies));
    }

    function onRemoveFamily(index) {
		if (employee_families[index]._id) {
			employee_families[index]._destroy = true
		} else {
			employee_families.splice(index, 1)
        }

        const newFamilies = employee_families.map((family, index)=> {
            return {...family, sort_order: index}
        })
        
        dispatch(employeeActions.onUpdateFamilies(newFamilies));
    }
    
    function onCancel() {
        dispatch(employeeActions.onUnloadEditEmployee())
    };

    function onSubmit() {
        dispatch(employeeActions.onEditFamilies(id, editEmployee));
    };

    const onKeyDown = (e) => {
        e.preventDefault();
    };
    
    function error(key) {
        if (errors != null && errors[`employee.${key}`] != null) {
            return errors[`employee.${key}`].msg
        }
        return null;
    }
    return (
        <Box>
            <Grid container spacing={4} direction="column">
                <Grid item container direction="column" spacing={3}>
                    <Grid item xs>
                        <Typography fontWeight={700} component="h6" lineheight="normal">
                            Edit Family
                        </Typography>
                    </Grid>
                    <Grid item xs>
                        <Paper sx={{ overflowX: 'scroll', border: "1px solid #ddd", position: 'relative', zIndex: 9, padding: '0 25px'}}>
                            <List component="div" sx={{width: '250%', marginBottom: '15px'}}>
                                <ListSubheader sx={{display: 'flex', padding: 0}} component="div">
                                    <Grid container spacing={2}>
                                        <Grid item xs={2}>
                                            Nama Lengkap
                                        </Grid>
                                        <Grid item xs>
                                            Hubungan
                                        </Grid>
                                        <Grid item xs>
                                            NIK / Passpor
                                        </Grid>
                                        <Grid item xs>
                                            Jenis Kelamin
                                        </Grid>
                                        <Grid item xs>
                                            Tempat Lahir
                                        </Grid>
                                        <Grid item xs>
                                            Tgl. Lahir
                                        </Grid>
                                        <Grid item xs>
                                            Agama
                                        </Grid>
                                        <Grid item xs>
                                            Pekerjaan
                                        </Grid>
                                        <Grid item xs={1}>
                                        </Grid>
                                    </Grid>
                                </ListSubheader>
                                {
                                    employee_families.map((row, index) => {
                                        if (row._destroy) {
                                            return null;
                                        }
                                        const familyRelationshipValue = family_relationships.find(opt => opt._id === row.family_relationship_id);
                                        const genderValue = gender_options.find(opt => opt._id === row.gender_option_id);
                                        const religionValue = religion_options.find(opt => opt._id === row.religion_option_id);
                                        return (
                                            <ListItem key={index} sx={{alignItems: "normal", padding: '15px 0'}} disableGutters divider={index !== employee_families.length - 1}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={2}>
                                                        <TextField
                                                            fullWidth
                                                            error={!!error(`employee_families[${index}].full_name`)}
                                                            name="full_name"
                                                            placeholder="Full Name"
                                                            value={row.full_name}
                                                            variant="outlined"
                                                            helperText={error(`employee_families[${index}].full_name`)}
                                                            onChange={onUpdateFamilies.bind(this, index)}
                                                        />
                                                    </Grid>
                                                    <Grid item xs>
                                                        <Autocomplete
                                                            options={family_relationships.sort((a, b) => -b.name.localeCompare(a.name))}
                                                            getOptionLabel={(option) => option.name}
                                                            selectOnFocus
                                                            clearOnBlur
                                                            filterSelectedOptions
                                                            disableClearable
                                                            onChange={(event, option) => {
                                                                onSelectFamilyRelationship(index, option)
                                                            }}
                                                            value={familyRelationshipValue ? familyRelationshipValue : null}
                                                            renderInput={(params) => 
                                                                <TextField {...params} error={!!error(`employee_families[${index}].family_relationship_id`)} 
                                                                helperText={error(`employee_families[${index}].family_relationship_id`)}
                                                                placeholder="Relationship" variant="outlined" />
                                                            }
                                                        />
                                                    </Grid>
                                                    <Grid item xs>
                                                        <CustomFormat
                                                            fullWidth
                                                            error={!!error(`employee_families[${index}].identity_number`)} 
                                                            name="identity_number"
                                                            placeholder="Identity Number"
                                                            value={row.identity_number}
                                                            helperText={error(`employee_families[${index}].identity_number`)} 
                                                            variant="outlined"
                                                            onChange={onUpdateFamilies.bind(this, index)}
                                                        />
                                                    </Grid>
                                                    <Grid item xs>
                                                        <Autocomplete
                                                            options={gender_options.sort((a, b) => -b.name.localeCompare(a.name))}
                                                            getOptionLabel={(option) => option.name}
                                                            selectOnFocus
                                                            clearOnBlur
                                                            filterSelectedOptions
                                                            disableClearable
                                                            onChange={(event, option) => {
                                                                onSelectGender(index, option)
                                                            }}
                                                            value={genderValue ? genderValue : null}
                                                            renderInput={(params) => <TextField {...params} error={!!error(`employee_families[${index}].gender_option_id`)} helperText={error(`employee_families[${index}].gender_option_id`)} placeholder="Gender" variant="outlined" />}
                                                        />
                                                    </Grid>
                                                    <Grid item xs>
                                                        <TextField
                                                            fullWidth
                                                            error={!!error(`employee_families[${index}].place_of_birth`)}
                                                            name="place_of_birth"
                                                            placeholder="Place of Birth"
                                                            value={row.place_of_birth}
                                                            helperText={error(`employee_families[${index}].place_of_birth`)}
                                                            variant="outlined"
                                                            onChange={onUpdateFamilies.bind(this, index)}
                                                        />
                                                    </Grid>
                                                    <Grid item xs>
                                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                                            <DatePicker
                                                                openTo="year"
                                                                disableFuture
                                                                value={row.date_of_birth}
                                                                format="dd/MM/yyyy"
                                                                onChange={onSelectDate.bind(this, index)}
                                                                renderInput={(params) => <TextField onKeyDown={onKeyDown} {...params} />}
                                                            />
                                                        </LocalizationProvider>
                                                    </Grid>
                                                    <Grid item xs>
                                                        <Autocomplete
                                                            options={religion_options.sort((a, b) => -b.name.localeCompare(a.name))}
                                                            getOptionLabel={(option) => option.name}
                                                            selectOnFocus
                                                            clearOnBlur
                                                            filterSelectedOptions
                                                            disableClearable
                                                            onChange={(event, option) => {
                                                                onSelectReligion(index, option)
                                                            }}
                                                            value={religionValue ? religionValue : null}
                                                            renderInput={(params) => <TextField {...params} error={!!error(`employee_families[${index}].religion_option_id`)} helperText={error(`employee_families[${index}].religion_option_id`)} placeholder="Religion" variant="outlined" />}
                                                        />
                                                    </Grid>
                                                    <Grid item xs>
                                                        <TextField
                                                            fullWidth
                                                            error={!!error(`employee_families[${index}].job`)}
                                                            name="job"
                                                            placeholder="Job"
                                                            value={row.job}
                                                            helperText={error(`employee_families[${index}].job`)}
                                                            variant="outlined"
                                                            onChange={onUpdateFamilies.bind(this, index)}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={1}>
                                                        <DelButton 
                                                            variant="contained"
                                                            size="large"
                                                            fullWidth
                                                            disableElevation
                                                            onClick={onRemoveFamily.bind(this, index)} >
                                                            Hapus
                                                        </DelButton>
                                                    </Grid>
                                                </Grid>
                                            </ListItem>
                                        )
                                    })
                                }
                            </List>
                        </Paper>
                    </Grid>
                    <Grid item container sx={{marginBottom: '15px'}} justifyContent="space-between" spacing={3}>
                        <Grid item>
                            <AddButton 
                                variant="contained"
                                size="large"
                                disableElevation
                                startIcon={<AddRounded />}
                                onClick={onAddFamily}
                                >
                                Tambah
                            </AddButton>
                        </Grid>
                        <Grid item>
                            <Grid container spacing={3}>
                                <Grid item>
                                    <BackButton 
                                        variant="contained"
                                        size="large"
                                        disableElevation
                                        disabled={inProgress}
                                        onClick={onCancel}
                                        >
                                        Batal
                                    </BackButton>
                                </Grid>
                                <Grid item>
                                    <Button 
                                        variant="contained"
                                        size="large"
                                        color="primary"
                                        sx={{minWidth: 195}}
                                        onClick={onSubmit}
                                        disabled={inProgress}
                                        disableElevation>
                                        {inProgress ? <CircularProgress size={26} /> : 'Simpan'}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    )
}


const BackButton = styled(Button)(({theme}) => ({
    color: theme.palette.text.primary,
    backgroundColor: "#FFFFFF",
    '&:hover': {
    backgroundColor: "#FFFFFF",
    },
    fontWeight: 500,
    border: '1px solid #ddd',
    minWidth: 195
}));

const AddButton = styled(Button)(({theme}) => ({
    color: theme.palette.primary.main,
    backgroundColor: "#FFFFFF",
    '&:hover': {
      backgroundColor: "#FFFFFF",
    },
    fontWeight: 500,
    border: `1px solid ${theme.palette.primary.main}`
}));


const DelButton = styled(Button)(({theme}) => ({
    color: theme.palette.text.primary,
    backgroundColor: "#FFFFFF",
    '&:hover': {
    backgroundColor: "#FFFFFF",
    },
    fontWeight: 500,
    border: `1px solid #ddd`,
    height: 56
}));