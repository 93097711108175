import React, { useEffect } from 'react';
import { 
    Box, 
    Typography,
    Paper
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {MailTwoTone, CommentTwoTone} from '@mui/icons-material';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import * as userVerificationActions from '../../../../actions/userVerificationActions';
import OtpInput from './OtpInput';

export default function Content({verifyType, setDialogText, setDialogTitle}) {
    const dispatch = useDispatch();
    const { user, onVerification } = useSelector(state => ({
        // ...state.register,
        // ...state.reset,
        ...state.common,
    }), shallowEqual);

    useEffect(() => {
       if (verifyType == 'register') {
            user.verify_type = "register"
       }
       if (verifyType == 'reset-password') {
        user.verify_type = "reset-password"
   }
    },[]);


    useEffect(() => {
        if (onVerification) {
            if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(user.email_phone)) {
                setDialogTitle("Masukan Kode Verifikasi");
                setDialogText(`Kode verifikasi telah dikirim melalui e-mail ke ${user.email_phone}`);
            } else {
                setDialogTitle("Masukan Kode Verifikasi");
                setDialogText(`Kode verifikasi telah dikirim melalui SMS ke ${user.email_phone}`);
            } 
        }
    },[onVerification]);

    function onSendMail() {
        dispatch(userVerificationActions.onEmailVerification(user))
    }

    function onSendSMS() {
        dispatch(userVerificationActions.onMessageVerification(user))
    }

    function VerificationOption() {
        if ((/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(user.email_phone))) {
            return (
                <OptionPaper onClick={onSendMail} elevation={4}>
                    <Box display="flex" flexDirection="row" alignItems="center">
                        <MailTwoTone fontSize="large" color="primary" />
                        <Box sx={{marginLeft: '15px'}}>
                            <Typography variant="body1" fontWeight={700}>
                                E-mail ke
                            </Typography>
                            <Typography variant="body1">
                                {user.email_phone}
                            </Typography>
                        </Box>
                    </Box>
                </OptionPaper>
            ) 
        } else if (/^(\+?62|0)8(1[123456789]|2[1238]|3[1238]|5[12356789]|7[78]|9[56789]|8[123456789])([\s?|\d]{5,11})$/.test(user.email_phone)) {
            return (
                <OptionPaper onClick={onSendSMS} elevation={4}>
                    <Box display="flex" flexDirection="row" alignItems="center">
                        <CommentTwoTone fontSize="large" color="primary" />
                        <Box sx={{marginLeft: '15px'}}>
                            <Typography variant="body1" fontWeight={700}>
                                SMS ke
                            </Typography>
                            <Typography variant="body1">
                                {user.email_phone}
                            </Typography>
                        </Box>
                    </Box>
                </OptionPaper>
            )
        } else {
            return null;
        }
    }

   
    if (onVerification) {
        return <OtpInput
                    digit={6} />
    }

    return <VerificationOption />
}

const OptionPaper = styled(Paper)(({theme}) => ({
    padding: 15,
    marginBottom: 15,
    cursor: "pointer"
}));