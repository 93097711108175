import React, { useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import * as purchaseOrderReturnActions from '../../../../../../actions/purchaseOrderReturnActions';
import { 
        Typography,
        Box,
        Grid,
        CircularProgress,
        Paper,
        Button
} from '@mui/material';
import { styled } from '@mui/material/styles';
import moment from 'moment';
import 'moment/locale/id' 

export default function Information() { 
    const {purchase_order_return, errors} = useSelector(state => ({
        ...state.purchase_order_return
    }), shallowEqual); 

  
    const { status, increment_id, purchase_order_id, status_title, created_at } = purchase_order_return;

    return (
        <ContentPaper>
            <Grid container direction="column">
                <Grid item container direction="column" spacing={1}>
                    <Grid item container spacing={2}>
                        <Grid item xs>
                            <Typography component="div">
                                <Box fontSize={12} fontWeight={500}>
                                    ID Pengembalian
                                </Box>
                            </Typography>
                        </Grid>
                        <Grid item xs>
                            <Typography component="div">
                                <Box fontSize={12} fontWeight={500}>
                                    ID Pembelian
                                </Box>
                            </Typography>
                        </Grid>
                        <Grid item xs>
                            <Typography component="div">
                                <Box fontSize={12} fontWeight={500}>
                                    Status
                                </Box>
                            </Typography>
                        </Grid>
                        <Grid item xs>
                            <Typography component="div">
                                <Box fontSize={12} fontWeight={500}>
                                    Tgl. Pembuatan
                                </Box>
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item container spacing={2}>
                        <Grid item xs>
                            <Typography component="div">
                                <Box fontSize={14} fontWeight={700}>
                                    {increment_id}
                                </Box>
                            </Typography>
                        </Grid>
                        <Grid item xs>
                            <Typography component="div">
                                <Box fontSize={14} fontWeight={700}>
                                    {purchase_order_id}
                                </Box>
                            </Typography>
                        </Grid>
                        <Grid item xs>
                            <Typography component="div">
                                <Box fontSize={14} fontWeight={700}>
                                    {status_title}
                                </Box>
                            </Typography>
                        </Grid>
                        <Grid item xs>
                            <Typography component="div">
                                <Box fontSize={14} fontWeight={700}>
                                    {moment(created_at).format('LL')}
                                </Box>
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </ContentPaper>
    )
}

const ContentPaper = styled(Paper)(({theme}) => ({
    padding: 35,
    paddingTop: 32,
    marginBottom: 25
}));