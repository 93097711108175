import React, { useEffect, useState } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import * as loginActions from '../../../actions/loginActions';
import { 
    Box, 
    Container,
    Divider,
    Paper,
    Typography, 
    TextField,
    Button,
    InputAdornment,
    FormControl,
    InputLabel,
    OutlinedInput,
    FormHelperText,
    } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { styled } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";
import { WhatsApp } from '@mui/icons-material';
import NewUser from '../Common/UserExist/NewUser';
import logo from '../../../assets/images/logo-pp.png';

export default function SignIn() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { user, userExist, inProgress, errors } = useSelector(state => ({
        ...state.common,
        ...state.login
    }), shallowEqual);
    const [validated, setValidated] = useState(false);

    useEffect(() => {
        if (!userExist) {
            dispatch(loginActions.onLoadLogin());
        }
        return () => {
            dispatch(loginActions.onUnloadLogin());
        };
    },[dispatch]);

    useEffect(() => {
        if (user && user.email_phone) {
            if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(user.email_phone)) {
                setValidated(true);
            } else if (/^(\+?62|0)8(1[123456789]|2[1238]|3[1238]|5[12356789]|7[78]|9[56789]|8[123456789])([\s?|\d]{5,11})$/.test(user.email_phone)) {
                setValidated(true);
            } else {
                setValidated(false);
            }
        }
    },[user]);

    function onUpdateValidationField(event) {
		const key = event.target.name;
        const value = event.target.value;
        dispatch(loginActions.onUpdateValidationField(key, value));
	}

    function onUpdateField(event) {
		const key = event.target.name;
        const value = event.target.value;
        dispatch(loginActions.onUpdateField(key, value));
	}

    function onCancel() {
		dispatch(loginActions.onCancelUser());
	}

    function onNext() {
		dispatch(loginActions.onValidateUser(user));
	}

    function onLogin() {
		dispatch(loginActions.onSignIn(user));
	}

    function onEnter(event) {
        if (event.key === 'Enter') {
            if (userExist) {
               onLogin()
            } else {
               onNext()
            }
        }
    }

    function ActionButton() {
        if (inProgress) {
            return (
                <LoadingButton loading fullWidth size="large" variant="contained">Selanjutnya</LoadingButton>
            )
        } else if (userExist) {
            return (
                <Button disabled={!validated} onClick={onLogin} fullWidth size="large" variant="contained">Masuk</Button>
            )
        } else {
            return (
                <Button disabled={!validated} onClick={onNext} fullWidth size="large" variant="contained">Selanjutnya</Button>
            )
        }
    }

    if (!user) {
        return null;
    }


    
    const { password, email_phone } = user;

    function error(key) {
        if (errors != null && errors[`user.${key}`] != null) {
            return errors[`user.${key}`].msg
        }
        return null;
    }

    return (
        <WrapperBox>
            <NewUser />
            <ContentBox>
                <ContentContainer maxWidth="sm">
                    <LogoBox onClick={() => navigate('/')} sx={{ cursor: 'pointer'}} component="img" src={logo} />
                    <FormPaper elevation={3}>
                        <HeaderBox display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                            <Typography fontWeight={700} variant="h5" gutterBottom>
                                Masuk
                            </Typography> 
                            <Box display="flex">
                                <Typography fontWeight={500} variant="body1" gutterBottom>
                                    Belum memiliki akun? <LinkBox component="a" onClick={() => navigate('/sign-up')}>Daftar</LinkBox>
                                </Typography>
                            </Box>
                        </HeaderBox>
                        <Box>
                            <FieldBox>
                                <FormControl fullWidth>
                                    <InputLabel htmlFor="outlined-email-password">Email / No. HP</InputLabel>
                                    <OutlinedInput
                                        name="email_phone"
                                        autoFocus
                                        onChange={onUpdateValidationField}
                                        value={email_phone}
                                        disabled={userExist}
                                        error={!!error('email_phone')}
                                        onKeyDown={onEnter}
                                        endAdornment={
                                           userExist ?  
                                            <InputAdornment position="end">
                                                <LinkBox onClick={onCancel}>
                                                    <Typography fontWeight={500} variant="body1">
                                                        Ubah
                                                    </Typography> 
                                                </LinkBox>
                                            </InputAdornment> : null
                                            
                                        }
                                        label="Email / No. HP"
                                    />
                                    {userExist ? null : <FormHelperText error={!!error('email_phone')}>{!!error('email_phone') ? error('email_phone') : "Contoh: email@livin.com"}</FormHelperText>}
                                </FormControl>
                            </FieldBox>
                            { userExist && 
                                <FieldBox>
                                     <TextField 
                                        fullWidth 
                                        name="password"
                                        label="Password"
                                        variant="outlined"
                                        value={password}
                                        autoComplete="off"
                                        type="password"
                                        error={!!error('password')}
                                        helperText={error('password')}
                                        onKeyDown={onEnter}
                                        onChange={onUpdateField} />
                                </FieldBox>
                            }
                        </Box>
                        <ForgotBox>
                            <Typography color="primary" textAlign="right" fontWeight={500} variant="body1" gutterBottom>
                                <LinkBox component="a" onClick={() => navigate('/reset-password')}>Lupa password?</LinkBox>
                            </Typography>
                        </ForgotBox>
                        
                        <ActionButton />

                        <AuthDivider>
                            Butuh bantuan?
                        </AuthDivider>

                        <Button fullWidth size="large"  onClick={() => {
                                window.location.assign('https://api.whatsapp.com/send?phone=628176009888&text=Hi,%20%20saya%20ingin%20bertanya%20mengenai%20AliPOS.');
                            }}
                            variant="outlined" startIcon={<WhatsApp />} >
                            Hubungi kami
                        </Button>
                        
                    </FormPaper>
                </ContentContainer>
            </ContentBox>
        </WrapperBox>

    )
}

const WrapperBox = styled(Box)(({theme}) => ({
    background: "#f4f3fc",
    height: '100%'
}));

const ContentBox = styled(Box)(({theme}) => ({
    padding: 35
}));


const ContentContainer = styled(Container)(({theme}) => ({
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center'
}));

const FormPaper = styled(Paper)(({theme}) => ({
    backgroundColor: 'rgb(255, 255, 255)',
    color: 'rgb(34, 51, 84)',
    transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    borderRadius: '8px',
    boxShadow: 'rgb(159 162 191 / 18%) 0px 9px 16px, rgb(159 162 191 / 32%) 0px 2px 2px',
    overflow: 'hidden',
    marginTop: 55,
    padding: 35,
    width: '100%',
    [theme.breakpoints.between('xs','sm')]: {
        padding: 20
    },
}));

const HeaderBox = styled(Box)(({theme}) => ({
    marginBottom: 45
}));

const FieldBox = styled(Box)(({theme}) => ({
    marginTop: 20
}));

const ForgotBox = styled(Box)(({theme}) => ({
    marginTop: 25,
    marginBottom: 15
}));

const LogoBox = styled(Box)(({theme}) => ({
    width: 175
}));

const AuthDivider = styled(Divider)(({theme}) => ({
    marginTop: 35,
    marginBottom: 35
}));


const LinkBox = styled(Box)(({theme}) => ({
    cursor: 'pointer',
    color: theme.palette.primary.main
}));

