import * as types from '../actions/actionTypes';

const defaultState = {
}

export default (state = defaultState, action) => {
	switch(action.type) {
        case types.USER_CONFIGURATION_LOADED:
            return {
                ...state,
                user_configuration: action.payload ? action.payload.user_configuration : null
            };
        case types.USER_CONFIGURATION_UPDATED:
            return {
                ...state,
                user_configuration: action.user_configuration
            };
        case types.USER_CONFIGURATION_UNLOADED:
            return {
                ...state,
                user_configuration: null
            };
        case types.USER_CONFIGURATION_EDITED:
			return {
				...state,
				inProgress: false,
				errors: action.error ? action.payload.errors : null,
                user_configuration: action.error ? state.user_configuration : action.payload.user_configuration,
            };
        case types.ASYNC_START:
            if ( action.subtype === types.USER_CONFIGURATION_EDITED ) {
                return { 
                    ...state, 
                    inProgress: true };
            };
            return state;
		default:
			return state;
	}
}