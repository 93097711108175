import * as types from './actionTypes';
import { axiosClient } from '../api';

export const onOpenNav = (menu) => {
	return {
		type: types.NAV_OPENED,
		currentMenu: menu
	}
}

export const onCloseNav = () => {
	return {
		type: types.NAV_CLOSED
	}
}

export const onCancelVerification = () => {
	return {
		type: types.USER_VERIFICATION_CANCELED
	}
}

export const onOpenMobileNav = () => {
	return {
		type: types.MOBILE_NAV_OPENED
	}
}

export const onCloseMobileNav = () => {
	return {
		type: types.MOBILE_NAV_CLOSED
	}
}



export const onRedirect = () => {
	return {
		type: types.REDIRECT
	}
}


export const onLogout = () => {
	return {
		type: types.USER_LOGGED_OUT
	}
}

export const onLoadAreas = (search) => {
	const fetch = axiosClient.get(`/areas`,
	{
		params: {search}
	});
	return {
		type: types.AREAS_LOADED,
		payload: fetch
	}
}

export const onUnloadAreas = () => {
	return {
		type: types.AREAS_UNLOADED
	}
}

export const onCloseNotice = () => {
	return {
		type: types.NOTICE_HIDED
	}
}

export const onHideNotice = () => {
	return {
		type: types.NOTICE_HIDED
	}
}
