import * as types from '../actions/actionTypes';

const defaultState = {
}

export default function (state = defaultState, action) {
	switch(action.type) {
		case types.ORDER_DELIVERIES_LOADED:
			return {
				...state,
				stores: action.payload ? action.payload.stores : null,
				order_deliveries: action.payload ? action.payload.order_deliveries : null,
				order_deliveries_count: action.payload ? action.payload.order_deliveries_count : null,
            };
			case types.ORDER_DELIVERY_LOADED:
				return {
					...state,
					order_delivery: action.payload ? action.payload.order_delivery : null,
                    onOrderDeliveryConfirm: false
				};
			case types.ORDER_DELIVERY_UNLOADED:
				return {
					...state,
					order_delivery: null
				};
            case types.ORDER_DELIVERY_UPDATED:
                return {
                    ...state,
                    order_delivery: action.order_delivery
                };
            case types.ORDER_DELIVERY_CONFIRM_SHOWED:
                return {
                    ...state,
                    onOrderDeliveryConfirm: true,
                    actionType: action.action_type
                };
            case types.ORDER_DELIVERY_CONFIRM_HIDED:
                return {
                    ...state,
                    onOrderDeliveryConfirm: false,
                    actionType: null
                };
            case types.ORDER_DELIVERY_EDITED:
                return { 
                    ...state,
                    inProgress: false,
                    onOrderDeliveryConfirm:  action.error ? false : state.onOrderDeliveryConfirm,
                    errors: action.error ? action.payload.errors : null,
                };
            case types.ASYNC_START:
			if ( action.subtype === types.ORDER_DELIVERY_EDITED ) {
				return { 
					...state, 
					inProgress: true };
			};
			return state;
		default:
			return state;
	}
}