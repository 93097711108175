import React from 'react';
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import TreeNode from './TreeNode';

export default function Tree(props) {
    return (
        <DndProvider backend={HTML5Backend}>
            <TreeNode {...props} />
        </DndProvider>
    )
}