import React, { useState, useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import * as customerActions from '../../../../../actions/customerActions';
import { TableContainer,
         Table,
         TableHead,
         TableRow,
         TableCell,
         TableBody,
         Typography,
         TableSortLabel,
         Grid,
         Box,
         InputBase,
         TablePagination,
         TextField
} from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import { visuallyHidden } from '@mui/utils';
import { useParams } from "react-router-dom";
import { Search } from '@mui/icons-material';
import Loader from '../../../../Helper/Loader';
import DataEmpty from '../../../../Helper/DataEmpty';
import NumberFormat from 'react-number-format';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import 'moment/locale/id' 

const headCells = [
    { id: 'name', numeric: false, sortable: false, disablePadding: false, label: 'Produk' },
    { id: 'sku', numeric: false, sortable: false, disablePadding: false, label: 'SKU' },
    { id: 'store', numeric: false, sortable: false, disablePadding: false, label: 'Cabang' },
    { id: 'increment_id', numeric: false, sortable: false, disablePadding: false, label: 'ID Pembelian' },
    { id: 'unit_price', numeric: true, sortable: false, disablePadding: false, label: 'Harga Pokok' },
    { id: 'discount', numeric: true, sortable: false, disablePadding: false, label: 'Diskon' },
    { id: 'net_price', numeric: true, sortable: false, disablePadding: false, label: 'Harga Netto' },
    { id: 'updated_at', numeric: true, sortable: false, disablePadding: false, label: 'Tanggal' },

];
 
 
  
function EnhancedTableHead({ order, orderBy, onRequestSort }) {
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => {
                    if (headCell.sortable) {
                        return (
                            <TableCell
                                key={headCell.id}
                                sx={{fontWeight: 700}}
                                align={headCell.numeric ? 'right' : 'left'}
                                padding={headCell.disablePadding ? 'none' : 'normal'}
                                sortDirection={orderBy === headCell.id ? order : false}
                            >
                                <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                                >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                ) : null}
                                </TableSortLabel>
                            </TableCell>
                        )
                    }
                    return (
                        <TableCell
                        key={headCell.id}
                        sx={{fontWeight: 700}}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        >
                            {headCell.label}
                        </TableCell>
                    )
                })}
            </TableRow>
        </TableHead>
    );
}


export default function Order() {
    const dispatch = useDispatch();
    let { id } = useParams();
    const { order_items, order_items_count } = useSelector(state => ({
        ...state.customer
    }), shallowEqual);

    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('name');
    const [query, setQuery] = useState({ page: 0, limit: 25, search: '', start_date: moment().startOf('month').format('YYYY[-]MM[-]DD'), end_date: moment().format('YYYY[-]MM[-]DD') });
    const [search, setSearch] = useState(query.search);

    useEffect(() => {
        dispatch(customerActions.onLoadCustomerOrderItems(id, query))
    },[query]);

    

    function onSearch(e) {
        var enterKey = 13;
        if (e.which === enterKey) {
            e.target.blur(); 
            const newQuery = {...query};
            newQuery.page = 0;
            newQuery.search = search;
            setQuery(newQuery)
        }
    }

    const onRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
        if (isAsc) {
          const newQuery = {...query};
          delete newQuery.sort_asc;
          newQuery.sort_desc = property;
          setQuery(newQuery)
        } else {
          const newQuery = {...query};
          delete newQuery.sort_desc;
          newQuery.sort_asc = property;
          setQuery(newQuery)
        }
    };

  
    const onChangePage = (event, newPage) => {
        const newQuery = {...query};
        newQuery.page = newPage;
        setQuery(newQuery)
    };

    function onSelectStore(event) {
        const value = event.target.value;
        const newQuery = {...query};
        newQuery.store_id = value;
        setQuery(newQuery)
    }

    const onSelectStatus = (status, event) => {
        const newQuery = {...query};
        newQuery.status = status;
        setQuery(newQuery)
    };

    const onSelectStartDate = (date) => {
        const newQuery = {...query};
        newQuery.start_date = moment(date).format('YYYY[-]MM[-]DD');
        setQuery(newQuery)
    };

    const onSelectEndDate = (date) => {
        const newQuery = {...query};
        newQuery.end_date = moment(date).format('YYYY[-]MM[-]DD');
        setQuery(newQuery)
    };
  

    const onChangeRowsPerPage = (event) => {
        const newQuery = {...query};
        newQuery.limit = parseInt(event.target.value, 10);
        newQuery.page = 0;
        setQuery(newQuery)
    };


    if (!order_items) {
        return <Loader />
    }

    function Data() {
        const {page, limit} = query;

        if (order_items.length === 0) {
            return (
                <DataEmpty />
            )
        }
        return (
            <Box>
                <TableContainer>
                    <Table>
                        <EnhancedTableHead
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={onRequestSort}
                        />
                        <TableBody>
                        {
                            order_items.map((order_item) => {
                                const { _id, name, sku, store, increment_id, unit_price, uom, discount_percent, discount_amount, discount_type, net_price, updated_at } = order_item;
                                return (
                                    <TableRow
                                        hover
                                        onClick={() => {
                                            window.open(`/dashboard/purchase-orders/${_id}/edit`);
                                        }}
                                        tabIndex={-1}
                                        key={_id}
                                    >
                                        <ItemTableCell component="th" scope="row">{name}</ItemTableCell>
                                        <ItemTableCell component="th" scope="row">{sku}</ItemTableCell>
                                        <ItemTableCell component="th" scope="row">{store}</ItemTableCell>
                                        <ItemTableCell component="th" scope="row">{increment_id}</ItemTableCell>
                                        <ItemTableCell align="right">
                                            <NumberFormat
                                                value={unit_price}
                                                displayType="text"
                                                thousandSeparator={"."}
                                                decimalSeparator={","}
                                                prefix="Rp "
                                                isNumericString
                                                decimalScale={2}
                                                renderText={(value) => <Typography fontSize="0.875rem">
                                                {value} / {uom}
                                                </Typography>}
                                            />
                                        </ItemTableCell>
                                        <ItemTableCell align="right">
                                            <NumberFormat
                                                value={discount_type === 'percent' ? discount_percent : discount_amount}
                                                displayType="text"
                                                thousandSeparator={"."}
                                                decimalSeparator={","}
                                                prefix={discount_type === 'percent' ? "" : "Rp "}
                                                suffix={discount_type === 'percent' ? "%" : ""}
                                                isNumericString
                                                decimalScale={2}
                                            />
                                        </ItemTableCell>
                                        <ItemTableCell align="right">
                                            <NumberFormat
                                                value={net_price}
                                                displayType="text"
                                                thousandSeparator={"."}
                                                decimalSeparator={","}
                                                prefix="Rp "
                                                isNumericString
                                                decimalScale={2}
                                                renderText={(value) => <Typography fontSize="0.875rem">
                                                {value} / {uom}
                                                </Typography>}
                                            />
                                        </ItemTableCell>
                                        <ItemTableCell align="right">
                                           {moment(updated_at).format('LL')}
                                        </ItemTableCell>
                                    
                                    </TableRow>
                                );
                            })
                        }
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[]}
                    component="div"
                    count={order_items_count}
                    rowsPerPage={limit}
                    page={page}
                    onPageChange={onChangePage}
                    onRowsPerPageChange={onChangeRowsPerPage}
                />
            </Box>
        )
    }

    return(
        <Grid container direction="column" spacing={2}>
            <Grid item>
                <Box sx={{padding: '25px 15px 15px 15px'}}>
                    <Grid container spacing={5} alignItems="center">
                        <Grid item container justifyContent="space-between">
                            <Grid item>
                                <SearchField
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                    onKeyDown={onSearch}>
                                    <SearchIconWrapper>
                                        <Search />
                                    </SearchIconWrapper>
                                    <StyledInputBase
                                    placeholder="Cari…"/>
                                </SearchField>
                            </Grid>
                            <Grid item xs={6} container spacing={3}>
                                <Grid item xs>
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <DatePicker
                                            openTo="year"
                                            value={Date.parse(query.start_date)}
                                            format="dd/MM/yyyy"
                                            onChange={onSelectStartDate}
                                            label="Tgl. Awal"
                                            renderInput={(params) => <TextField variant="standard" size="small" fullWidth {...params} />}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item xs>
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <DatePicker
                                            openTo="year"
                                            value={Date.parse(query.end_date)}
                                            format="dd/MM/yyyy"
                                            onChange={onSelectEndDate}
                                            label="Tgl. Akhir"
                                            renderInput={(params) => <TextField variant="standard" size="small" fullWidth {...params} />}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
            <Grid item>
                {Data()}
            </Grid>
        </Grid>
    )  
}


const ItemTableCell = styled(TableCell)(({theme}) => ({
    padding: '15px 16px'
}));

const SearchField = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.black, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.black, 0.25),
    },
    marginLeft: 0,
    // [theme.breakpoints.up('sm')]: {
    //   marginLeft: theme.spacing(1),
    //   width: 'auto',
    // },
}));
  
const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));
  
const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: '12ch',
        '&:focus': {
          width: '20ch',
        },
      },
    },
  }));