import React from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { onHideConfirm, onApproved, onCompleted, onTransfered, onCanceled } from '../../../../../../../actions/storeTransferActions';
import { 
    Dialog,
    Slide,
    CircularProgress,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useParams } from "react-router-dom";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function Confirm() {
    let { id } = useParams();
    const dispatch = useDispatch();
    const {onStoreTransferConfirm, store_transfer, actionType, inProgress} = useSelector(state => ({
        ...state.store_transfer
    }), shallowEqual);
   

    function onHide() {
        dispatch(onHideConfirm());
    }

   
    function Title() {
        if (actionType === 'canceled') {
            return (
                <DialogTitle>{"Batalkan Transfer?"}</DialogTitle>
            )
        }
      
        if (actionType === 'approved') {
            return (
                <DialogTitle>{"Menyetujui Transfer?"}</DialogTitle>
            )
        }

        if (actionType === 'transfered') {
            return (
                <DialogTitle>{"Transfer Persediaan?"}</DialogTitle>
            )
        }

        if (actionType === 'completed') {
            return (
                <DialogTitle>{"Selesaikan Transfer?"}</DialogTitle>
            )
        }
        return null;
    }

    function ContentText() {
        if (actionType === 'canceled') {
            return (
                <DialogContentText>
                   Apakah kamu yakin ingin membatalkan Transfer dengan ID <span style={{fontWeight: 'bold'}}>#{store_transfer.increment_id}</span>.
                </DialogContentText>
            )
        }
      
        if (actionType === 'approved') {
            return (
                <DialogContentText>
                    Transfer dengan ID <span style={{fontWeight: 'bold'}}>#{store_transfer.increment_id}</span> telah disetujui dengan kuantitas dan harga yang benar.
                </DialogContentText>
            )
        }

        if (actionType === 'transfered') {
            return (
                <DialogContentText>
                    Transfer dengan ID <span style={{fontWeight: 'bold'}}>#{store_transfer.increment_id}</span> telah ditransfer dengan kuantitas dan harga yang benar.
                </DialogContentText>
            )
        }

        if (actionType === 'completed') {
            return (
                <DialogContentText>
                    Transfer dengan ID <span style={{fontWeight: 'bold'}}>#{store_transfer.increment_id}</span> telah dibayar dengan nominal yang benar.
                </DialogContentText>
            )
        }
        return null;
    }

    function onSubmit() {
        if (actionType === 'canceled') {
            dispatch(onCanceled(id, store_transfer));
        }

        if (actionType === 'approved') {
            dispatch(onApproved(id, store_transfer));
        }

        if (actionType === 'transfered') {
            dispatch(onTransfered(id, store_transfer));
        }

        if (actionType === 'completed') {
            dispatch(onCompleted(id, store_transfer));
        }

    }

    return (
        <Dialog
            open={onStoreTransferConfirm}
            maxWidth="xs"
            TransitionComponent={Transition}
            scroll={'paper'}>
            {Title()}
            <DialogContent>
                {ContentText()}
            </DialogContent>
            <DialogActions style={{padding: 25, justifyContent: 'space-between'}}>
                <CancelButton size="large" variant="contained" disabled={inProgress} disableElevation onClick={onHide}>
                    Batal
                </CancelButton>
                <Button sx={{minWidth: '165px'}} size="large" variant="contained" disabled={inProgress} disableElevation onClick={onSubmit} color="primary">
                    {inProgress ? <CircularProgress size={26} /> : 'Proses'}
                </Button>
            </DialogActions>    
        </Dialog>
    )
}

const CancelButton = styled(Button)(({theme}) => ({
    color: theme.palette.text.primary,
    backgroundColor: "#FFFFFF",
    '&:hover': {
    backgroundColor: "#FFFFFF",
    },
    fontWeight: 500,
    minWidth: 165,
    border: `1px solid #ddd`
}));
