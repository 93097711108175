import * as types from '../actions/actionTypes';

const defaultState = {
}

export default function employee_organization (state = defaultState, action) {
	switch(action.type) {
		case types.EMPLOYEE_ORGANIZATIONS_LOADED:
			return {
				...state,
				employee_organizations: action.error ? null : action.payload.employee_organizations
			};
		// case types.EMPLOYEE_ORGANIZATION_PARENT_SETED:
		// 	return {
		// 		...state,
		// 		parentOrganization: action.parent
		// 	};
		case types.NEW_EMPLOYEE_ORGANIZATION_LOADED:
			return {
				...state,
				employee_organization: action.payload ? action.payload.employee_organization : null,
				parentOrganization:  action.payload ? action.payload.parent_organization : null, 
			};
		case types.EMPLOYEE_ORGANIZATION_FIELD_UPDATED:
			return {
				...state,
				employee_organization: {...state.employee_organization, [action.key]: action.value}
			};
		case types.EDIT_EMPLOYEE_ORGANIZATION_LOADED:
			return {
				...state,
				employee_organization: action.error ? null : action.payload.employee_organization,
				parentOrganization: action.payload ? action.payload.parent_organization : null, 
			};
		// case types.EMPLOYEE_ORGANIZATION_UPDATED:
		// 	return {
		// 		...state,
		// 		employee_organization: action.employee_organization
		// 	};
		case types.EMPLOYEE_ORGANIZATION_FORM_UNLOADED:
			return {
				...state,
				employee_organization: null,
				errors: [],
				inProgress: false,
				parent_id: null
			};
		case types.EMPLOYEE_ORGANIZATION_CREATED:
		case types.EMPLOYEE_ORGANIZATION_EDITED:
			return {
				...state,
				inProgress: false,
				errors: action.error ? action.payload.errors : null
			};
		case types.ASYNC_START:
			if ( action.subtype === types.EMPLOYEE_ORGANIZATION_CREATED ||
					action.subtype === types.EMPLOYEE_ORGANIZATION_EDITED
				) {
				return { 
					...state, 
					inProgress: true };
			};
			return state;
		default:
			return state;
	}
}