import React from 'react';
import { 
    Box,
    Tabs,
    Tab,
} from '@mui/material';
import PersonalInformation from './PersonalInformation';
import Family from './Family';
import EmergencyContact from './EmergencyContact';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <Box>
        {value === index && (
          <Box sx={{ paddingTop: '35px'}}>
            {children}
          </Box>
        )}
      </Box>
    );
}

function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
}

  
export default function Personal() {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
    return (
        <Box>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} >
                    <Tab label="Informasi Personal" {...a11yProps(0)} />
                    <Tab label="Keluarga" {...a11yProps(1)} />
                    <Tab label="Kontak Darurat" {...a11yProps(2)} />
                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
               <PersonalInformation />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <Family />
            </TabPanel>
            <TabPanel value={value} index={2}>
                <EmergencyContact />
            </TabPanel>
        </Box>
    )
}