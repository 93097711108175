import React from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { onShowActivities } from '../../../../../../actions/storeAdjustmentActions';
import { 
        Typography,
        Paper,
        Grid,
} from '@mui/material';
import { styled } from '@mui/material/styles';

export default function Information() {    
    const dispatch = useDispatch();
    const {store_adjustment} = useSelector(state => ({
        ...state.store_adjustment
    }), shallowEqual); 

    const { increment_id, 
            total_adjustment_qty,
            items_type,
            store_name,
            created_date, 
            status,
            status_title } = store_adjustment;
            
    return (
        <ContentPaper elevation={3}>
            <Grid container direction={"column"} spacing={3}>
                <Grid xs item container spacing={3}>
                    <Grid item xs container direction="column">
                        <Grid item>
                            <Typography fontWeight={500} fontSize={13} variant="body1">
                                Adjustment ID
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography fontWeight={700} fontSize={16} variant="h6">
                                #{increment_id}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs container direction="column">
                        <Grid item>
                            <Typography fontWeight={500} fontSize={13} variant="body1">
                                Store
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography fontWeight={700} fontSize={16} variant="h6">
                                {store_name}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs container direction="column">
                        <Grid item>
                            <Typography fontWeight={500} fontSize={13} variant="body1">
                                Gain / Loss
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography fontWeight={700} fontSize={16} variant="h6">
                                {/* {to_store_name} */}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs container direction="column">
                        <Grid item>
                            <Typography fontWeight={500} fontSize={13} variant="body1">
                                Status
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography fontWeight={700} fontSize={16} variant="h6">
                                {status_title}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid xs item container spacing={3}>
                    <Grid item xs container direction="column">
                        <Grid item>
                            <Typography fontWeight={500} fontSize={13} variant="body1">
                                Total Adjustment Qty
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography fontWeight={700} fontSize={16} variant="h6">
                                {total_adjustment_qty}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs container direction="column">
                        <Grid item>
                            <Typography fontWeight={500} fontSize={13} variant="body1">
                                Items Type
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography fontWeight={700} fontSize={16} variant="h6">
                                {items_type}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs container direction="column">
                        <Grid item>
                            <Typography fontWeight={500} fontSize={13} variant="body1">
                                Created Date
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography fontWeight={700} fontSize={16} variant="h6">
                                {created_date}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs container direction="column">
                        <Grid item>
                            <Typography fontWeight={500} fontSize={13} variant="body1">
                                History
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography gutterBottom variant="h6" color="primary" fontSize={14} style={{textTransform: 'capitalize', cursor: 'pointer'}} onClick={ () => { dispatch(onShowActivities())}}>
                                View Activities
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </ContentPaper>
    )
}

const ContentPaper = styled(Paper)(({theme}) => ({
    padding: 35,
    paddingTop: 32,
    marginBottom: 25
}));