import React from 'react';
import { useSelector, shallowEqual} from 'react-redux';
import { 
    Typography,
    Button,
    Box,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { keyframes } from '@emotion/react'
import moment from 'moment';
import 'moment/locale/id' 


export default function JobHistory() {
    const { employee } = useSelector(state => ({
        ...state.employee
    }), shallowEqual);

    const { current_job, employee_job_histories } = employee;
    const { job_position, started_date } = current_job;
    return (
        <Box sx={{padding: '25px 0', overflow: 'auto', maxHeight: '800px'}}>
            <TimelineBox>
                <DateBox>
                    <Typography fontSize={16} fontWeight={700} component="div">
                        {job_position}
                    </Typography>
                </DateBox>
                <LineWrapperBox>
                    <CurrentCircleBox />
                    {employee_job_histories.length >= 1 && <LineBox />}
                </LineWrapperBox>
                <Box>
                    <Typography fontSize={16} fontWeight={700} component="div">
                        {moment(started_date).format('YYYY')} - Sekarang
                    </Typography>
                </Box>
            </TimelineBox>
            {
                employee_job_histories.map((history, index) => {
                    return (
                        <TimelineBox key={index}>
                            <DateBox>
                                <Typography fontSize={16} fontWeight={700} component="div">
                                    {history.job_position}
                                </Typography>
                            </DateBox>
                            <LineWrapperBox>
                                <CircleBox />
                                <LineBox last={true} />
                            </LineWrapperBox>
                            <Box>
                                <Typography fontSize={16} fontWeight={700} component="div">
                                    {moment(history.started_date).format('YYYY')} -  {moment(history.ended_date).format('YYYY')}
                                </Typography>
                            </Box>
                        </TimelineBox>
                    )
                })
            }
        </Box>
    )
}

const TimelineBox = styled(Box)(({theme}) => ({
    display: 'flex',
    minHeight: 60
 }));

const DateBox = styled(Box)(({theme}) => ({
    width: '20%',
    textAlign: 'right'
}));

const LineWrapperBox = styled(Box)(({theme}) => ({
   margin: '5px 16px',
   alignItems: 'center',
   flexDirection: 'column',
   display: 'flex'
}));

const grow = keyframes`
    from {
        opacity: 1;
        transform: scale(0);
    }
    to {
        opacity: 0;
        transform: scale(2);
    }
`;

const CurrentCircleBox = styled(Box)(({theme}) => ({
    width: '15px',
    height: '15px',
    borderRadius: '50%',
    position: 'relative',
    marginBottom: 'auto',
    '&:before': {
        width: 15,
        position: 'absolute',
        height: 15,
        borderRadius: '50%',
        backgroundColor: `var(--G300,#4B00D1)`,
        animationName:  `${grow}`,
        animationDuration: '1400ms',
        animationTimingFunction: 'ease-out',
        animationIterationCount: 'infinite',
        animationFillMode: 'none',
        animationPlayState: 'running',
        content: '""'
    }, 
    '&:after': {
        borderRadius: '50%',
        backgroundColor: 'var(--G500,#6200EE)',
        content: '""',
        position: 'absolute',
        width: 11,
        height: 11,
        left: 2,
        top: 2
    },
 }));


const CircleBox = styled(Box)(({theme}) => ({
    width: '15px',
    height: '15px',
    borderRadius: '50%',
    position: 'relative',
    marginBottom: 'auto',
    backgroundColor: 'var(--N100,#DBDEE2)',
    '&:after': {
        borderRadius: '50%',
        backgroundColor: 'var(--N200,#9FA6B0)',
        content: '""',
        position: 'absolute',
        width: '9px',
        height: '9px',
        left: '3px',
        top: '3px'
    },
 }));

const LineBox = styled(Box, {
    shouldForwardProp: (prop) => prop !== "last",
  })(({ theme, last }) => ({
    display: last ? 'none' : 'block',
    width: 1,
    height: 'calc(100% - 9px)',
    marginBottom: -6,
    backgroundImage: 'linear-gradient(to bottom,var(--N100,#DBDEE2) 55%,rgba(255,255,255,0) 55%)',
    backgroundPosition: 'left center',
    backgroundSize: '1px 12px',
    backgroundRepeat: 'repeat-y'
 }));

const BackButton = styled(Button)(({theme}) => ({
    color: theme.palette.text.primary,
    backgroundColor: "#FFFFFF",
    '&:hover': {
    backgroundColor: "#FFFFFF",
    },
    fontWeight: 500,
    border: '1px solid #ddd',
    minWidth: 195
}));