import React from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import * as productActions from '../../../../../../../actions/productActions';
import { 
        Typography,
        Paper,
        Grid,
        Button,
        Switch,
        FormControlLabel,
        FormGroup,
        FormControl,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {AddRounded} from '@mui/icons-material';
import ProductOption from './ProductOption';
import ProductVariant from './ProductVariant';

function pad(data, size) {
    var s = String(data);
    while (s.length < (size || 2)) {s = "0" + s;}
    return s;
}

function validEANBarcode(barcode){
    let s = 0;
    for (let i = 0; i < 12; i++) {
        let c = parseInt(barcode.charAt(i));
        s += c * ( i%2 == 0? 1: 3);
    }
    s = (10 - s % 10) % 10;
    return barcode.slice(0, -1) + s;
}


export default function Variant({error}) {
    const dispatch = useDispatch();
    const {product, autoSKU, onConfiguration} = useSelector(state => ({
        ...state.product
    }), shallowEqual);
    const { product_options, product_variants } = product;

	function onAddOption() {

        var generateSKU;
        let currentDate = new Date();
        let date = currentDate.getDate().toString().padStart(2, "0");
        let month = currentDate.getMonth().toString().padStart(2, "0");
        let year = currentDate.getUTCFullYear().toString().substr(-2);
        let dateSKU = date + month + year;
        let minutes = currentDate.getMinutes().toString().padStart(2, "0");
        let seconds = currentDate.getSeconds().toString().padStart(2, "0");
        let milliSeconds = currentDate.getMilliseconds().toString().padStart(3, "0");
        let timeSKU = minutes + seconds + milliSeconds;

        
        generateSKU = dateSKU.concat(timeSKU);
        var optionSKU;
        if (!autoSKU) {
            optionSKU = "";
        } else {
            optionSKU = validEANBarcode(generateSKU);
        }
		const newOptions = [...product_options , {name: '', sku: optionSKU, price: '', unit: '', product_type: "option_item", weight: '', status: true, sort_order: 0 } ]
        dispatch(productActions.onAddOptions(newOptions));
    }

    function onShowConfiguration() {
        dispatch(productActions.onShowConfiguration(product));
    }

    if (getDestroyedOptions() !== 0) {
        return <ProductOption product={product} autoSKU={autoSKU} error={error} />
    }

    if (getDestroyedVariants() !== 0) {
        return <ProductVariant product={product} autoSKU={autoSKU} error={error} />
    }

    function getDestroyedOptions() {
		return product_options.filter(el => {
	    	return !el._destroy;
	  	}).length;
	}

    function getDestroyedVariants() {
        return product_variants.filter(el => {
          return el._destroy !== true;
        }).length;
    }

  
    return (
        <ContentPaper elevation={3}>
            <Grid container spacing={6} direction="column">
                <Grid item container>
                    <Grid item xs>
                        <Typography fontWeight={700} variant="h6" lineheight="normal">
                            Opsi / Varian Produk
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                            Tambah opsi produk dengan klik "Tambah Opsi " di bawah, atau switch pada kanan kolom untuk menambakan varian produk.
                            Opsi produk dan varian produk adalah jenis produk yang berbeda.
                        </Typography>
                    </Grid>
                    <Grid item>
                        <FormControl component="fieldset">
                            <FormGroup row>
                                <FormControlLabel
                                    control={<Switch color="primary" checked={onConfiguration} />}
                                    label="Varian Produk"
                                    labelPlacement="start"
                                    onChange={onShowConfiguration}
                                />
                            </FormGroup>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid item >
                    <OptionButton 
                            variant="contained"
                            size="large"
                            disableElevation
                            startIcon={<AddRounded />}
                            onClick={onAddOption}>
                            Opsi Produk
                        </OptionButton>
                </Grid>
            </Grid>
        </ContentPaper>
    )
}

const ContentPaper = styled(Paper)(({theme}) => ({
    padding: 35,
    paddingTop: 32,
    marginBottom: 25
}));

const OptionButton = styled(Button)(({theme}) => ({
    color: theme.palette.primary.main,
    backgroundColor: "#FFFFFF",
    '&:hover': {
    backgroundColor: "#FFFFFF",
    },
    fontWeight: 500,
    border: `1px solid ${theme.palette.primary.main}`
}));
