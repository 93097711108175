import React, {useEffect} from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import * as employeeActions from '../../../../../../../actions/employeeActions';
import { 
    Typography,
    Grid,
    Button,
    Autocomplete,
    TextField,
    CircularProgress,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useParams } from "react-router-dom";
  
export default function Edit() {
    let { id } = useParams();
    const dispatch = useDispatch();
    const { editEmployee, employee_accesses, errors, inProgress } = useSelector(state => ({
        ...state.employee
    }), shallowEqual);


    const { employee_access_id } = editEmployee;

    useEffect(() => {
        return () => {
            dispatch(employeeActions.onUnloadEditEmployee());
        };
    },[dispatch]);

    function onSelectAccess(option) {
        const newEmployee = {...editEmployee};
        newEmployee.employee_access_id = option._id;
        dispatch(employeeActions.onUpdateEditEmployee(newEmployee));
    }
    function onCancel() {
        dispatch(employeeActions.onUnloadEditEmployee());
    };

    function onSubmit() {
        dispatch(employeeActions.onEditAccesses(id, editEmployee));
    };

    function error(key) {
        if (errors != null && errors[`employee.${key}`] != null) {
            return errors[`employee.${key}`].msg
        }
        return null;
    }
 
    const accessValue = employee_accesses.find(opt => opt._id === employee_access_id);
    return (
        <Grid container spacing={4} direction="column">
            <Grid item container>
                <Grid item xs>
                    <Typography fontWeight={700} component="h6" lineheight="normal">
                        Ubah Akses
                    </Typography>
                </Grid>
                <Grid item>
                </Grid>
            </Grid>
            <Grid item container direction="column" spacing={3}>
                <Grid item container spacing={8}>
                    <Grid item xs={4}>
                        <Typography fontSize={16} fontWeight={700} component="div">
                            Pembatasan Tugas dan Akses
                        </Typography>
                        <Typography variant="body2" gutterBottom color="textSecondary">
                            Pilih hak akses karyawan.
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Autocomplete
                            options={employee_accesses.sort((a, b) => -b.name.localeCompare(a.name))}
                            groupBy={(option) => option.firstLetter}
                            getOptionLabel={(option) => option.name}
                            blurOnSelect
                            filterSelectedOptions
                            disableClearable
                            onChange={(event, option) => {
                                onSelectAccess(option)
                            }}
                            value={accessValue ? accessValue : null}
                            renderInput={(params) => <TextField {...params} error={!!error('employee_access_id')} helperText={error('employee_access_id')} placeholder="Pilih akses karyawan" variant="outlined" />}
                        />
                    </Grid> 
                </Grid>
            </Grid>
            <Grid item container sx={{marginBottom: '15px'}} justifyContent="space-between" spacing={3}>
                <Grid item>
                </Grid>
                <Grid item>
                    <Grid container spacing={3}>
                        <Grid item>
                            <BackButton 
                                variant="contained"
                                size="large"
                                disableElevation
                                disabled={inProgress}
                                onClick={onCancel}>
                                Batal
                            </BackButton>
                        </Grid>
                        <Grid item>
                            <Button 
                                variant="contained"
                                size="large"
                                color="primary"
                                sx={{minWidth: 195}}
                                onClick={onSubmit}
                                disabled={inProgress}
                                disableElevation>
                                {inProgress ? <CircularProgress size={26} /> : 'Simpan'}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>  
        </Grid>
    )
}

const BackButton = styled(Button)(({theme}) => ({
    color: theme.palette.text.primary,
    backgroundColor: "#FFFFFF",
    '&:hover': {
    backgroundColor: "#FFFFFF",
    },
    fontWeight: 500,
    border: '1px solid #ddd',
    minWidth: 195
}));
