
import * as types from './actionTypes';
import { axiosClient } from '../api';


export const onLoadCategories = () => {
	const fetch = axiosClient.get(`/categories`);
	return {
		type: types.CATEGORIES_LOADED,
		payload: fetch
	}
}


export const onDragEnd = (categoryId, category) => {
	const fetch = axiosClient.put(`/categories/${categoryId}/sort`, {category});
	return {
		type: types.CATEGORIES_SORTED,
		payload: fetch
	}
}


export const onSetTreeOpenIds = (newOpenIds) => {
	return {
		type: types.CATEGORIES_SET_TREE_IDS,
		newOpenIds
	}
}


export const onCategoryNew = (parent_id) => {
	return {
		type: types.NEW_CATEGORY_LOADED,
		parent_id
	}
}

export const onCategoryEdit = (parent_id, id) => {
	const fetch = axiosClient.get(`/categories/${id}/edit`, {
		params: {parent_id}
	});
	return {
		type: types.EDIT_CATEGORY_LOADED,
		payload: fetch
	}
}

export const onSetParent = (parent_id) => {
	return {
		type: types.SET_CATEGORY_PARENT_ID,
		parent_id
	}
}

export const onUpdateCategory = (category) => {
	return {
		type: types.CATEGORY_UPDATED,
		category
	}
}

export const onCreate = (category) => {
	const fetch = axiosClient.post(`/categories`, {category});
	return {
		type: types.CATEGORY_CREATED, 
		payload: fetch
	}
	
}


export const onEdit = (parent_id, categoryId, category) => {
	const fetch = axiosClient.put(`/categories/${categoryId}`, {parent_id, category});
	return {
		type: types.CATEGORY_EDITED, 
		payload: fetch
	}
}


export const onUnloadForm = () => {
	return {
		type: types.CATEGORY_FORM_UNLOADED
	}
}

export const onUploadRequest = (image) => {
	return {
		type: types.CATEGORY_IMAGE_UPLOAD_REQUESTED,
		image
	}
}


export const onUpload = (file, index, progressCallback) => {
	const fetch = axiosClient.post(`/categories/upload`, {file}, {onUploadProgress: progressCallback});

	return {
		type: types.CATEGORY_IMAGE_UPLOADED,
		payload: fetch,
		index
		
	}
	
}

export const onUploading = (image, index) => {
	return {
		type: types.CATEGORY_IMAGE_UPLOADING,
		image,
		index
		
	}
	
}

export const onRemoveRequest = (image, index) => {
	return {
		type: types.CATEGORY_IMAGE_REMOVE_REQUESTED,
		image,
		index
	}
}

export const onRemove = (file, index) => {
	const fetch = axiosClient.post(`/categories/remove`, {file});
	return {
		type: types.CATEGORY_IMAGE_REMOVED,
		payload: fetch,
		index
	}
}