import * as types from '../actions/actionTypes';

const defaultState = {
}

export default function (state = defaultState, action) {
	switch(action.type) {
		case types.ORDER_CANCELEDS_LOADED:
			return {
				...state,
				stores: action.payload ? action.payload.stores : null,
				order_canceleds: action.payload ? action.payload.order_canceleds : null,
				order_canceleds_count: action.payload ? action.payload.order_canceleds_count : null,
            };
			case types.ORDER_CANCELED_LOADED:
				return {
					...state,
					order_canceled: action.payload ? action.payload.order_canceled : null,
                    onOrderCanceledConfirm: false
				};
			case types.ORDER_CANCELED_UNLOADED:
				return {
					...state,
					order_canceled: null
				};
            case types.ORDER_CANCELED_UPDATED:
                return {
                    ...state,
                    order_canceled: action.order_canceled
                };
            case types.ORDER_CANCELED_CONFIRM_SHOWED:
                return {
                    ...state,
                    onOrderCanceledConfirm: true,
                    actionType: action.action_type
                };
            case types.ORDER_CANCELED_CONFIRM_HIDED:
                return {
                    ...state,
                    onOrderCanceledConfirm: false,
                    actionType: null
                };
            case types.ORDER_CANCELED_EDITED:
                return { 
                    ...state,
                    inProgress: false,
                    onOrderCanceledConfirm:  action.error ? false : state.onOrderCanceledConfirm,
                    errors: action.error ? action.payload.errors : null,
                };
            case types.ASYNC_START:
			if ( action.subtype === types.ORDER_CANCELED_EDITED ) {
				return { 
					...state, 
					inProgress: true };
			};
			return state;
		default:
			return state;
	}
}