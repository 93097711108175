import * as types from '../actions/actionTypes';

const defaultState = {
	
}

export default (state = defaultState, action) => {
	switch(action.type) {
        case types.CUSTOMER_GROUPS_LOADED:
            return {
                ...state,
                customer_groups: action.payload ? action.payload.customer_groups : null,
                customer_groups_count: action.error ? null : action.payload.customer_groups_count,
            };
		case types.NEW_CUSTOMER_GROUP_LOADED:
            return {
                ...state,
                customer_group: {
                    name: "",
                    status: true
                },
                errors: null
            };
        case types.EDIT_CUSTOMER_GROUP_LOADED:
            return {
                ...state,
                customer_group: action.payload ? action.payload.customer_group : null,
                errors: null
            };
        case types.CUSTOMER_GROUP_UPDATED: 
            return {
                ...state,
                customer_group: action.customer_group
            };
        case types.CUSTOMER_GROUP_FORM_UNLOADED:
            return {
                ...state,
                customer_group: null,
                errors: [],
                inProgress: false,
            };
        case types.CUSTOMER_GROUP_CREATED:
        case types.CUSTOMER_GROUP_EDITED:
            return {
                ...state,
                errors: action.error ? action.payload.errors : null,
                inProgress: false
            };
		case types.ASYNC_START:
			if ( action.subtype === types.CUSTOMER_GROUP_CREATED ||
                 action.subtype === types.CUSTOMER_GROUP_EDITED
				) {
				return { 
					...state, 
					inProgress: true };
			};
			return state;
		default:
			return state;
	}
}