import * as types from '../actions/actionTypes';

const defaultState = {
	
}

export default (state = defaultState, action) => {
    function checkAvailableVariant(variants, compareAttributes) {
        if (Object.keys(compareAttributes).length !== 0) {
            let filterVariants = [];
            Object.keys(compareAttributes).map((selectKey) => {
                return filterVariants = variants.filter( v => v.variant_attributes[selectKey] === compareAttributes[selectKey])
            })
            delete compareAttributes[Object.keys(compareAttributes)[0]]

            return eliminateAttribute(filterVariants, compareAttributes)
        } else {
            
          return variants
        }
    }

     
    function eliminateAttribute(variants, compareAttributes) {
        return checkAvailableVariant(variants, compareAttributes)
    }
	switch(action.type) {
        case types.PURCHASE_ORDER_RETURNS_LOADED:
			return {
				...state,
				stores: action.payload ? action.payload.stores : null,
				purchase_order_returns: action.error ? null : action.payload.purchase_order_returns,
				purchase_order_returns_count: action.error ? null : action.payload.purchase_order_returns_count,
            };
        case types.NEW_PURCHASE_ORDER_RETURN_LOADED:
            return {
                ...state,
                purchase_orders: action.error ? null : action.payload.purchase_orders,
                products: [],
                initial_products: [],
                inventories: [],
                purchase_order_return: {
                    purchase_order_id: '',
                    supplier_id: '',
                    store_id: '',
                    note: '',
                    reason: '',
                    purchase_order_return_items: [{ product_id: '', name: '', sku: '', in_stock: 0, requested_qty: 0 }],
                }
            };
        case types.EDIT_PURCHASE_ORDER_RETURN_LOADED:
            return {
                ...state,
                purchase_order_return: action.payload ? action.payload.purchase_order_return : null,
                onPurchaseOrderReturnConfirm: false,
                confirmAction: false,
            }; 
        case types.PURCHASE_ORDER_RETURN_UNLOADED:
            return {
                ...state,
                purchase_order_return: null,
                errors: [],
                inProgress: false,
            };
        case types.PURCHASE_ORDER_RETURN_UPDATED:
            return {
                ...state,
                purchase_order_return: action.purchase_order_return
            };
        case types.PURCHASE_ORDER_RETURN_SELECTED:
            return {
                ...state,
                productsFetching: false,
                inventories: action.payload ? action.payload.inventories : null,
                products: action.payload ? action.payload.products : null,
                initial_products: action.payload ? action.payload.products : null,
                purchase_order_return: action.payload ? action.payload.purchase_order_return : null,
            };
        case types.PURCHASE_ORDER_RETURN_PRODUCT_SELECTED:
            const selectedProductIndex = state.products.findIndex(product => product._id === action.payload.product._id); 
            const selectedInitialProductIndex = state.initial_products.findIndex(product => product._id === action.payload.product._id); 
            const newProducts = [...state.products];
            const newInitialProducts = [...state.initial_products];
            const newProduct = {...action.payload.product};
            newProduct.loaded = true;
            newProducts[selectedProductIndex] = newProduct;
            newInitialProducts[selectedInitialProductIndex] = newProduct;
            return {
                ...state,
                productsFetching: false,
                selectedProduct: action.payload ? action.payload.product : null,
                products: action.payload ? newProducts : state.products,
                initial_products: action.payload ? newInitialProducts : state.initial_products,
            };
        case types.PURCHASE_ORDER_RETURN_ITEMS_SELECTED:
            return {
                ...state,
                purchase_order_return: { ...state.purchase_order_return, purchase_order_return_items: action.new_return_items },
                selectedProduct: null,
                products: state.products.filter(product => product._id !== action.id)
            };
        case types.PURCHASE_ORDER_RETURN_VARIANTS_SHOWED:
            return {
                ...state,
                onVariant: true,
                variantPage: 1,
                selectedAttributes: {},
                variantProduct: state.products.find(product => product._id === action.id)
            };
        case types.PURCHASE_ORDER_RETURN_VARIANTS_HIDED:
            return {
                ...state,
                onVariant: false,
                variantPage: 1,
                selectedAttributes: {},
                variantProduct: null
            };
        case types.PURCHASE_ORDER_RETURN_VARIANT_SELECTED:
            const { variantProduct, selectedAttributes } = state;
            selectedAttributes[action.label.toLowerCase()] = action.value.toLowerCase();
            const eliminatedAttributes = {}
            eliminatedAttributes[action.label.toLowerCase()] = action.value.toLowerCase();
            const newVariants = checkAvailableVariant(variantProduct.product_variants, eliminatedAttributes)
            
            const selectEnabledVariants = [];
            const selectDisabledVariants = [];

            for(var i = 0; i < newVariants.length; i++) {
                const variant = newVariants[i]
                if (variant.status) {
                    selectEnabledVariants.push(variant)
                } else {
                    selectDisabledVariants.push(variant)
                }  
            }
            
            const enabled_variant_data = {}
            if (selectEnabledVariants.length !== 0) {
                Object.keys(selectEnabledVariants[0].variant_attributes).map((key) => {
                    return enabled_variant_data[key] = []
                })

                selectEnabledVariants.map(v => {
                    return Object.keys(v.variant_attributes).map((key) => {
                        if (!enabled_variant_data[key].includes(v.variant_attributes[key])) {
                            return enabled_variant_data[key].push(v.variant_attributes[key]);
                        }
                        return null;  
                    })
                })
                
            }

            
            const disabled_variant_data = {}
            if (selectDisabledVariants.length !== 0) {
                
                Object.keys(selectDisabledVariants[0].variant_attributes).map((key) => {
                    return disabled_variant_data[key] = []
                })

                selectDisabledVariants.map(v => {
                    return Object.keys(v.variant_attributes).map((key) => {
                        if (!disabled_variant_data[key].includes(v.variant_attributes[key])) {
                            return disabled_variant_data[key].push(v.variant_attributes[key]);
                        }
                        return null;
                    })
                })
            }
            
            variantProduct.product_configurations.map((config) => {
                Object.keys(disabled_variant_data).map((key) => {
                    if (config.name.toLowerCase() === key) {
                        disabled_variant_data[config.name.toLowerCase()].map(attr => {
                            const option = config.product_configuration_options.find(c => c.value.toLowerCase() === attr.toLowerCase())
                            const optionIndex = config.product_configuration_options.findIndex(c => c.value.toLowerCase() === attr.toLowerCase())
                            if (option) {
                                const updateOption = { ...config.product_configuration_options[optionIndex], status: false}
                                config.product_configuration_options.splice(optionIndex, 1, updateOption)
                            }
                            return attr;
                        })
                        
                    }
                    return null;
                })

                Object.keys(enabled_variant_data).map((key) => {
                    if (config.name.toLowerCase() === key) {
                        enabled_variant_data[config.name.toLowerCase()].map(attr => {
                            const option = config.product_configuration_options.find(c => c.value.toLowerCase() === attr.toLowerCase())
                            const optionIndex = config.product_configuration_options.findIndex(c => c.value.toLowerCase() === attr.toLowerCase())
                            if (option) {
                                const updateOption = { ...config.product_configuration_options[optionIndex], status: true}
                                config.product_configuration_options.splice(optionIndex, 1, updateOption)
                                
                            }
                            return attr;
                        })
                        
                    }
                    return null;
                })
                return config;
            })
        
            return {
                ...state,
                selectedAttributes: selectedAttributes,
                variantProduct: { ...state.variantProduct, product_variants: newVariants },
                variantPage: state.variantPage + 1,
            };
        case types.PURCHASE_ORDER_RETURN_PRODUCT_VARIANT_SELECTED:
            state.selectedAttributes[action.label.toLowerCase()] = action.value.toLowerCase();

            const { purchase_order_return, products } = state;
            const { purchase_order_return_items } = purchase_order_return;
           
            const selectedProduct = products.find(product => product._id === action.variantProduct._id);
            const selectedVariant = selectedProduct.product_variants.find(variant => JSON.stringify(variant.variant_attributes) === JSON.stringify(state.selectedAttributes));
            
            const inventory_product =  state.inventories.find(i => i.sku.toString() === selectedVariant.sku.toString())
            if (inventory_product) {
                if (purchase_order_return_items[0].product_id && purchase_order_return_items.length >= 1) {
                    purchase_order_return_items.push({product_id: selectedProduct._id, name: selectedVariant.name, sku: selectedVariant.sku, uom: selectedVariant.uom, in_stock: inventory_product.in_stock, requested_qty: 0})
                } else {
                    const newOrderItems = { ...purchase_order_return_items[0], product_id: selectedProduct._id, name: selectedVariant.name, sku: selectedVariant.sku, uom: selectedVariant.uom, in_stock: inventory_product.in_stock, requested_qty: 0}
                    purchase_order_return_items.splice(0, 1, newOrderItems)
                }
            } else {
                if (purchase_order_return_items[0].product_id && purchase_order_return_items.length >= 1) {
                    purchase_order_return_items.push({product_id: selectedProduct._id, name: selectedVariant.name, sku: selectedVariant.sku, uom: selectedVariant.uom, in_stock: 0, requested_qty: 0})
                } else {
                    const newOrderItems = { ...purchase_order_return_items[0], product_id: selectedProduct._id, name: selectedVariant.name, sku: selectedVariant.sku, uom: selectedVariant.uom, in_stock: 0, requested_qty: 0}
                    purchase_order_return_items.splice(0, 1, newOrderItems)
                }
            }
        
            
            const productIndex = products.findIndex(product => product._id === action.variantProduct._id)
            const productVariantIndex = products[productIndex].product_variants.findIndex(variant => JSON.stringify(variant.variant_attributes) === JSON.stringify(state.selectedAttributes))
            products.map(product => {
                if (product._id === selectedProduct._id) {
                    const newVariants = { ...product.product_variants[productVariantIndex], status: false }
                    product.product_variants.splice(productVariantIndex, 1, newVariants)
                }
                return product;
            })

           

            const enabledVariants = [];
            const disabledVariants = [];
            const newProductVariants = []
            for(var m = 0; m < selectedProduct.product_variants.length; m++) {
                const variant = selectedProduct.product_variants[m]
                if (variant.status) {
                    enabledVariants.push(variant)
                } else {
                    disabledVariants.push(variant)
                }
                newProductVariants.push(variant)
                
            }


            const newProductsWithVariants = []
            if (enabledVariants.length === 0) {
                products.filter(product => product._id !== selectedProduct._id).map(product => {
                    return newProductsWithVariants.push(product)
                })
            } else {
               
                const enabled_variant_data = {}
                Object.keys(enabledVariants[0].variant_attributes).map((key) => {
                    return enabled_variant_data[key] = []
                })

                enabledVariants.map(v => {
                    return Object.keys(v.variant_attributes).map((key) => {
                        if (!enabled_variant_data[key].includes(v.variant_attributes[key])) {
                            return enabled_variant_data[key].push(v.variant_attributes[key]);
                        }
                        return null;
                    })
                })

                

                const disabled_variant_data = {}
                Object.keys(disabledVariants[0].variant_attributes).map((key) => {
                    return disabled_variant_data[key] = []
                })

                disabledVariants.map(v => {
                    return Object.keys(v.variant_attributes).map((key) => {
                        if (!disabled_variant_data[key].includes(v.variant_attributes[key])) {
                            return disabled_variant_data[key].push(v.variant_attributes[key]);
                        }
                        return null;
                    })
                })
                
                selectedProduct.product_configurations.map((config) => {
                    Object.keys(disabled_variant_data).map((key) => {
                        if (config.name.toLowerCase() === key) {
                            disabled_variant_data[config.name.toLowerCase()].map(attr => {
                                const option = config.product_configuration_options.find(c => c.value.toLowerCase() === attr.toLowerCase())
                                const optionIndex = config.product_configuration_options.findIndex(c => c.value.toLowerCase() === attr.toLowerCase())
                                if (option) {
                                    const updateOption = { ...config.product_configuration_options[optionIndex], status: false}
                                    return config.product_configuration_options.splice(optionIndex, 1, updateOption)
                                }
                                return attr;
                            })
                            
                        }
                        return null;
                    })

                    Object.keys(enabled_variant_data).map((key) => {
                        if (config.name.toLowerCase() === key) {
                            enabled_variant_data[config.name.toLowerCase()].map(attr => {
                                const option = config.product_configuration_options.find(c => c.value.toLowerCase() === attr.toLowerCase())
                                const optionIndex = config.product_configuration_options.findIndex(c => c.value.toLowerCase() === attr.toLowerCase())
                                if (option) {
                                    const updateOption = { ...config.product_configuration_options[optionIndex], status: true}
                                    config.product_configuration_options.splice(optionIndex, 1, updateOption)
                                    
                                }
                                return attr;
                            })
                            
                        }
                        return null;
                    })
                    return config;
                })

               
                
                for (let i = 0; i < products.length ; i++) {
                    if (products[i]._id === selectedProduct._id) {
                        newProductsWithVariants.push({...products[i], product_variants: newProductVariants, product_configurations: selectedProduct.product_configurations})
                    } else {
                        newProductsWithVariants.push(products[i])
                    }
                };

                
            }
            return {
                ...state,
                purchase_order_return: { ...state.purchase_order_return, purchase_order_return_items: purchase_order_return_items },          
                onVariant: false,
                selectedProduct: null,
                products: newProductsWithVariants
            };
        case types.RESTORE_RETURN_PRODUCT_OPTIONS_HANDLED:
            const restoredProduct = state.initial_products.find(product => product._id === action.id);
            let updatedProducts = [];
            if (restoredProduct.product_variants) {
                const hasProduct = state.products.find(product => product._id === action.id);
                if (hasProduct) {
                    hasProduct.product_variants.map((v, i) => {
                        if (v.sku === action.sku) {
                            return v['status'] = true;
                        }
                        return null;
                    })

                    const enabledVariants = [];
                    const disabledVariants = [];
                    for(var k = 0; k < hasProduct.product_variants.length; k++) {
                        const variant = hasProduct.product_variants[k]
                        if (variant.status) {
                            enabledVariants.push(variant);
                        } else {
                            disabledVariants.push(variant);
                        }
                        
                    }

                    const enabled_variant_data = {}
                    if (enabledVariants.length !== 0) {
                        Object.keys(enabledVariants[0].variant_attributes).map((key) => {
                            return enabled_variant_data[key] = []
                        })
                        enabledVariants.map(v => {
                            return Object.keys(v.variant_attributes).map((key) => {
                                if (!enabled_variant_data[key].includes(v.variant_attributes[key])) {
                                    return enabled_variant_data[key].push(v.variant_attributes[key]);
                                }
                                return null;
                            })
                        })
                    }

                    

                    const disabled_variant_data = {}
                    if (disabledVariants.length !== 0) {
                        Object.keys(disabledVariants[0].variant_attributes).map((key) => {
                            return disabled_variant_data[key] = []
                        })

                        disabledVariants.map(v => {
                            return Object.keys(v.variant_attributes).map((key) => {
                                if (!disabled_variant_data[key].includes(v.variant_attributes[key])) {
                                    return disabled_variant_data[key].push(v.variant_attributes[key]);
                                }
                                return null;
                            })
                        })
                    }
                    
                    hasProduct.product_configurations.map((config) => {
                        Object.keys(disabled_variant_data).map((key) => {
                            if (config.name.toLowerCase() === key) {
                                disabled_variant_data[config.name.toLowerCase()].map(attr => {
                                    const option = config.product_configuration_options.find(c => c.value.toLowerCase() === attr.toLowerCase())
                                    const optionIndex = config.product_configuration_options.findIndex(c => c.value.toLowerCase() === attr.toLowerCase())
                                    if (option) {
                                        const updateOption = { ...config.product_configuration_options[optionIndex], status: false};
                                        config.product_configuration_options.splice(optionIndex, 1, updateOption);
                                    }
                                    return attr;
                                })
                                
                            }
                            return null;
                        })

                        Object.keys(enabled_variant_data).map((key) => {
                            if (config.name.toLowerCase() === key) {
                                enabled_variant_data[config.name.toLowerCase()].map(attr => {
                                    const option = config.product_configuration_options.find(c => c.value.toLowerCase() === attr.toLowerCase())
                                    const optionIndex = config.product_configuration_options.findIndex(c => c.value.toLowerCase() === attr.toLowerCase())
                                    if (option) {
                                        const updateOption = { ...config.product_configuration_options[optionIndex], status: true};
                                        return config.product_configuration_options.splice(optionIndex, 1, updateOption);
                                        
                                    }
                                    return attr;
                                })
                                
                            }
                            return null;
                        })
                        return config;
                    })

                    
                    state.products.map( p => {
                        if (p._id === hasProduct._id) {
                            return updatedProducts.push(hasProduct);
                        } else {
                            return updatedProducts.push(p);
                        }
                    });
                } else {
                    const restoredProducts = [ ...state.products, restoredProduct ];
                    restoredProduct.product_variants.map((v, i) => {
                        if (v.sku === action.sku) {
                            return v['status'] = true;
                        } else {
                            return v['status'] = false;
                        }
                    })

                    const enabledVariants = [];
                    const disabledVariants = [];
                    for(var j = 0; j < restoredProduct.product_variants.length; j++) {
                        const variant = restoredProduct.product_variants[j]
                        if (variant.status) {
                            enabledVariants.push(variant);
                        } else {
                            disabledVariants.push(variant);
                        }
                        
                    }

                    const enabled_variant_data = {}
                    if (enabledVariants.length !== 0) {
                        Object.keys(enabledVariants[0].variant_attributes).map((key) => {
                            return enabled_variant_data[key] = []
                        })
                        enabledVariants.map(v => {
                            return Object.keys(v.variant_attributes).map((key) => {
                                if (!enabled_variant_data[key].includes(v.variant_attributes[key])) {
                                    return enabled_variant_data[key].push(v.variant_attributes[key]);
                                }
                                return null;
                            })
                        })
                    }

                    

                    const disabled_variant_data = {}
                    if (disabledVariants.length !== 0) {
                        Object.keys(disabledVariants[0].variant_attributes).map((key) => {
                            return disabled_variant_data[key] = []
                        })
                        disabledVariants.map(v => {
                            return Object.keys(v.variant_attributes).map((key) => {
                                if (!disabled_variant_data[key].includes(v.variant_attributes[key])) {
                                    return disabled_variant_data[key].push(v.variant_attributes[key]);
                                }
                                return null;
                            })
                        })
                        
                    }
                    
                    restoredProduct.product_configurations.map((config) => {
                        Object.keys(disabled_variant_data).map((key) => {
                            if (config.name.toLowerCase() === key) {
                                disabled_variant_data[config.name.toLowerCase()].map(attr => {
                                    const option = config.product_configuration_options.find(c => c.value.toLowerCase() === attr.toLowerCase())
                                    const optionIndex = config.product_configuration_options.findIndex(c => c.value.toLowerCase() === attr.toLowerCase())
                                    if (option) {
                                        const updateOption = { ...config.product_configuration_options[optionIndex], status: false}
                                        config.product_configuration_options.splice(optionIndex, 1, updateOption)
                                    }
                                    return attr;
                                })
                            }
                            return null;
                        })

                        Object.keys(enabled_variant_data).map((key) => {
                            if (config.name.toLowerCase() === key) {
                                enabled_variant_data[config.name.toLowerCase()].map(attr => {
                                    const option = config.product_configuration_options.find(c => c.value.toLowerCase() === attr.toLowerCase())
                                    const optionIndex = config.product_configuration_options.findIndex(c => c.value.toLowerCase() === attr.toLowerCase())
                                    if (option) {
                                        const updateOption = { ...config.product_configuration_options[optionIndex], status: true}
                                        config.product_configuration_options.splice(optionIndex, 1, updateOption)
                                        
                                    }
                                    return attr;
                                })
                            }
                            return null;
                        })
                        return config;
                    })

                    
                    for (let i = 0; i < state.initial_products.length ; i++) {
                        for (let j = 0; j < restoredProducts.length ; j++) {
                            if (state.initial_products[i]._id === restoredProducts[j]._id) {
                                updatedProducts.push(restoredProducts[j]);
                            }
                        };
                    };
                }
            } else {
                const restoredProducts = [ ...state.products, restoredProduct ]
                for (let i = 0; i < state.initial_products.length ; i++) {
                    for (let j = 0; j < restoredProducts.length ; j++) {
                        if (state.initial_products[i]._id === restoredProducts[j]._id) {
                            updatedProducts.push(restoredProducts[j]);
                        }
                    };
                };
            }
            return {
                ...state,
                products: updatedProducts
            }; 
        case types.PURCHASE_ORDER_RETURN_CREATED:
            return {
                ...state,
                errors: action.error ? action.payload.errors : null,
                inProgress: false
            }; 
        case types.PURCHASE_ORDER_RETURN_CONFIRM_SHOWED:
            return {
                ...state,
                onPurchaseOrderReturnConfirm: true,
                actionType: action.action_type
            };
        case types.PURCHASE_ORDER_RETURN_CONFIRM_HIDED:
            return {
                ...state,
                onPurchaseOrderReturnConfirm: false,
                actionType: null
            };
		case types.ASYNC_START:
            if ( action.subtype === types.PURCHASE_ORDER_RETURN_SELECTED ||
                action.subtype === types.PURCHASE_ORDER_RETURN_PRODUCT_SELECTED
            ) {
                return { 
                    ...state, 
                    productsFetching: true };
            };
			if ( action.subtype === types.PURCHASE_ORDER_RETURN_CREATED ||
					action.subtype === types.PURCHASE_ORDER_RETURN_EDITED
				) {
				return { 
					...state, 
					inProgress: true };
			};
			return state;
		default:
			return state;
	}
}