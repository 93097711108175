
import * as types from './actionTypes';
import { axiosClient } from '../api';

export const onLoadStoreTransfers = (query) => {
	const fetch = axiosClient.get(`/store-transfers`,
	{
		params: query
	});
	return {
		type: types.STORE_TRANSFERS_LOADED,
		payload: fetch
	}
}


export const onNew = () => {
	const fetch = axiosClient.get(`/store-transfers/new`);
	return {
		type: types.NEW_STORE_TRANSFER_LOADED,
		payload: fetch
	}
}

export const onEdit = (id) => {
	const fetch = axiosClient.get(`/store-transfers/${id}/edit`);
	return {
		type: types.EDIT_STORE_TRANSFER_LOADED,
		payload: fetch
	}
}

export const onStoreFrom = (id) => {
	const fetch = axiosClient.get(`/store-transfers/${id}/products`);
	return {
		type: types.STORE_TRANSFER_FROM_SELECTED,
        payload: fetch,
        id
	}
}

export const onStoreTo = (id) => {
	return {
		type: types.STORE_TRANSFER_TO_SELECTED,
        id
	}
}

export const onUpdateStoreTransfer = (store_transfer) => {
	return {
		type: types.STORE_TRANSFER_UPDATED,
		store_transfer
	}
}


export const onSelectProduct = (product_id, from_store_id, to_store_id) => {
	const fetch = axiosClient.get(`/store-transfers/${product_id}/product?from_store_id=${from_store_id}&to_store_id=${to_store_id}`);
	return {
		type: types.STORE_TRANSFER_PRODUCT_SELECTED, 
		payload: fetch
	}
}

export const onSelectedProductOptions = (new_transfer_items, id) => {
	return {
		type: types.STORE_TRANSFER_ITEMS_SELECTED,
		new_transfer_items,
		id
	}
}

export const onRestoreProductOptions = (options, id, sku) => {
	return {
		type: types.RESTORE_TRANSFER_PRODUCT_OPTIONS_HANDLED,
		options,
		id,
		sku
	}
}

export const onHandleVariants = (id) => {
	return {
		type: types.STORE_TRANSFER_VARIANTS_SHOWED,
		id
	}
}

export const onHideVariants = () => {
	return {
		type: types.STORE_TRANSFER_VARIANTS_HIDED
	}
}

export const onSelectVariant = (variantProduct, label, value) => {
	return {
		type: types.STORE_TRANSFER_VARIANT_SELECTED,
		variantProduct,
		label,
		value
	}
}

export const onHandleProductVariant = (variantProduct, label, value) => {
	return {
		type: types.STORE_TRANSFER_PRODUCT_VARIANT_SELECTED,
		variantProduct,
		label,
		value
	}
}

export const onCreate = (store_transfer) => {
	const fetch = axiosClient.post(`/store-transfers`, {store_transfer});
	return {
		type: types.STORE_TRANSFER_CREATED, 
		payload: fetch
	}
}

export const onUnload = () => {
	return {
		type: types.STORE_TRANSFER_UNLOADED
	}
}

export const onShowActivities = () => {
	return {
		type: types.STORE_TRANSFER_ACTIVITIES_SHOWED
	}
}

export const onHideActivities = () => {
	return {
		type: types.STORE_TRANSFER_ACTIVITIES_HIDED
	}
}

export const onShowConfirm = (action_type) => {
	return {
		type: types.STORE_TRANSFER_SHOWED,
		action_type
	}
}

export const onHideConfirm = () => {
	return {
		type: types.STORE_TRANSFER_HIDED
	}
}

export const onApproved = (id, store_transfer) => {
	const fetch = axiosClient.put(`/store-transfers/${id}/approved`, {store_transfer});
	return {
		type: types.STORE_TRANSFER_EDITED, 
		payload: fetch
	}
}

export const onTransfered = (id, store_transfer) => {
	const fetch = axiosClient.put(`/store-transfers/${id}/transfered`, {store_transfer});
	return {
		type: types.STORE_TRANSFER_EDITED, 
		payload: fetch
	}
}

export const onCompleted = (id, store_transfer) => {
	const fetch = axiosClient.put(`/store-transfers/${id}/completed`, {store_transfer});
	return {
		type: types.STORE_TRANSFER_EDITED, 
		payload: fetch
	}
}

export const onCanceled = (id, store_transfer) => {
	const fetch = axiosClient.put(`/store-transfers/${id}/canceled`, {store_transfer});
	return {
		type: types.STORE_TRANSFER_EDITED, 
		payload: fetch
	}
}

// export const onChecked = (id, store_transfer) => {
// 	const fetch = axiosClient.put(`/store_transfers/${id}/checked`, {store_transfer});
// 	return {
// 		type: types.STORE_TRANSFER_EDITED, 
// 		payload: fetch
// 	}
// }