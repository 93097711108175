import * as types from './actionTypes';
import { axiosClient } from '../api';


export const onLoadReset = () => {
	return {
		type: types.USER_RESET_LOADED
	}
}

export const onUnloadReset = () => {
	return {
		type: types.USER_RESET_UNLOADED
	}
}

export const onUpdateValidationField = (key, value) => {
	return {
        type: types.USER_RESET_VALIDATION_FIELD_UPDATED,
        key,
        value
	}
}

export const onUpdateField = (key, value) => {
	return {
        type: types.USER_RESET_FIELD_UPDATED,
        key,
        value
	}
}

export const onValidateUser = (user) => {
	const fetch = axiosClient.post(`/user-reset-validations`, {user});
	return {
		type: types.USER_RESET_VALIDATION,
		payload: fetch
	}
}

export const onUpdate = (user) => {
	const fetch = axiosClient.put(`/user-reset-passwords`, {user});
	return {
		type: types.USER_RESET_EDITED,
		payload: fetch
	}
}