import * as types from '../actions/actionTypes';

const defaultState = {
}

export default (state = defaultState, action) => {

	switch(action.type) {
		case types.STORES_LOADED:
			return {
				...state,
				stores: action.error ? null : action.payload.stores,
				stores_count: action.error ? null : action.payload.stores_count
			};
		case types.NEW_STORE_LOADED:
			return {
				...state,
				store_types: action.payload ? action.payload.store_types : null,
				store: action.payload ? action.payload.store : null,
				areas: []
			};
		case types.AREAS_LOADED:
			return {
				...state,
				areas: action.payload ? action.payload.areas : null,
				areasFetching: false
			};
		case types.EDIT_STORE_LOADED:
			return {
				...state,
				store: action.payload ? action.payload.store : null,
				store_types: action.payload ? action.payload.store_types : null,
				areas: []
			};
		case types.STORE_UPDATED:
			return {
				...state,
				store: action.store
			};
		case types.STORE_CREATED:
		case types.STORE_EDITED:
			return {
				...state,
				inProgress: false,
				errors: action.error ? action.payload.errors : null
			};
		case types.STORE_FORM_UNLOADED:
            return {
                ...state,
                store: null,
				errors: null,
				areas: null,
				store_types: null,
				inProgress: false,
			};
		case types.STORE_LOADED:
			return {
				...state,
				store: action.error ? null : action.payload.store,
				onStoreInventories: false
			};
		case types.STORE_UNLOADED:
			return {
				...state,
				store: null,
				onStoreInventories: false
			};
		case types.STORE_INVENTORIES_SHOWED:
			return {
				...state,
				onStoreInventories: true
			};
        case types.STORE_INVENTORIES_HIDED:
			return {
				...state,
				onStoreInventories: false
			};
		case types.STORE_INVENTORIES_LOADED:
			return {
				...state,
				inventories: action.payload ? action.payload.inventories : null
			};
		case types.ASYNC_START:
            if ( action.subtype === types.STORE_CREATED ||
                 action.subtype === types.STORE_EDITED
                ) {
                return { 
                    ...state, 
                    inProgress: true };
			};
			if ( action.subtype === types.AREAS_LOADED ) {
                return { 
                    ...state, 
                    areasFetching: true
                };
            };
            return state;
		default:
			return state;
	}
}