import * as types from './actionTypes';
import { axiosClient } from '../api';
import { validate } from '../components/Helper/Validation';

export const onLoadInventories = (query) => {
	const fetch = axiosClient.get(`/inventories`,
	{
		params: query
	});
	return {
		type: types.INVENTORIES_LOADED,
		payload: fetch
	}
}

export const onCollapseInventories = (inventories) => {
	return {
		type: types.INVENTORIES_COLLAPSED, 
		inventories
	}
}

export const onShowHistories = (inventory) => {
	return {
		type: types.INVENTORY_HISTORIES_SHOWED,
		inventory
	}
}

export const onHideHistories = () => {
	return {
		type: types.INVENTORY_HISTORIES_HIDED
	}
}

export const onLoadHistories = (id, query) => {
	const fetch = axiosClient.get(`/inventories/${id}/histories`,
	{
		params: query
	});
	return {
		type: types.INVENTORY_HISTORIES_LOADED, 
		payload: fetch
	}
}