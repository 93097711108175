import React from 'react';
import { Box,
    CircularProgress
} from '@mui/material';
import { styled } from '@mui/material/styles';
export default function Loader() {
    return (
        <ProgressBox>
            <CircularProgress />
        </ProgressBox>
    )
}

const ProgressBox = styled(Box)(({theme}) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%'
 }));