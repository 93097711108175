import * as types from './actionTypes';
import { axiosClient } from '../api';
import { validate } from '../components/Helper/Validation';

export const onLoadOrderCanceleds = (query) => {
	const fetch = axiosClient.get(`/order-canceleds`,
	{
		params: query
	});
	return {
		type: types.ORDER_CANCELEDS_LOADED,
		payload: fetch
	}
}

export const onLoadOrderCanceled = (id) => {
	const fetch = axiosClient.get(`/order-canceleds/${id}`);
	return {
		type: types.ORDER_CANCELED_LOADED,
		payload: fetch
	}
}


export const onUnloadOrderCanceled = (id) => {
	return {
		type: types.ORDER_CANCELED_UNLOADED
	}
}

export const onUpdateOrderCanceled = (order_canceled) => {
	return {
		type: types.ORDER_CANCELED_UPDATED,
		order_canceled
	}
}

export const onShowConfirm = (action_type) => {
	return {
		type: types.ORDER_CANCELED_CONFIRM_SHOWED,
		action_type
	}
}

export const onHideConfirm = () => {
	return {
		type: types.ORDER_CANCELED_CONFIRM_HIDED
	}
}

export const onCanceled = (id, order_canceled) => {
	const fetch = axiosClient.put(`/order-canceleds/${id}/canceled`, {order_canceled});
	return {
		type: types.ORDER_CANCELED_EDITED, 
		payload: fetch
	}
}

export const onReceived = (id, order_canceled) => {
	const fetch = axiosClient.put(`/order-canceleds/${id}/received`, {order_canceled});
	return {
		type: types.ORDER_CANCELED_EDITED, 
		payload: fetch
	}
}

// export const onShowPayments = (id) => {
// 	return {
// 		type: types.ORDER_CANCELED_PAYMENTS_SHOWED
// 	}
// }

// export const onHidePayments = () => {
// 	return {
// 		type: types.ORDER_CANCELED_PAYMENTS_HIDED
// 	}
// }

// export const onLoadPayments = (id) => {
// 	const fetch = axiosClient.get(`/order-canceleds/${id}/payments`);
// 	return {
// 		type: types.ORDER_CANCELED_PAYMENTS_LOADED, 
// 		payload: fetch
// 	}
// }

// export const onCollapsePayments = (payments) => {
// 	return {
// 		type: types.ORDER_CANCELED_PAYMENTS_COLLAPSED, 
// 		payments
// 	}
// }

// export const onShowReturns = () => {
// 	return {
// 		type: types.ORDER_CANCELED_RETURNS_SHOWED
// 	}
// }

// export const onHideReturns = () => {
// 	return {
// 		type: types.ORDER_CANCELED_RETURNS_HIDED
// 	}
// }

// export const onLoadReturns = (id) => {
// 	const fetch = axiosClient.get(`/order-canceleds/${id}/returns`);
// 	return {
// 		type: types.ORDER_CANCELED_RETURNS_LOADED, 
// 		payload: fetch
// 	}
// }

// export const onCollapseReturns = (returns) => {
// 	return {
// 		type: types.ORDER_CANCELED_RETURNS_COLLAPSED, 
// 		returns
// 	}
// }

// export const onShowOrderCanceledDeliveries = () => {
// 	return {
// 		type: types.ORDER_CANCELED_DELIVERIES_SHOWED
// 	}
// }

// export const onHideOrderCanceledDeliveries = () => {
// 	return {
// 		type: types.ORDER_CANCELED_DELIVERIES_HIDED
// 	}
// }

// export const onLoadOrderCanceledDeliveries = (id) => {
// 	const fetch = axiosClient.get(`/order-canceleds/${id}/deliveries`);
// 	return {
// 		type: types.ORDER_CANCELED_DELIVERIES_LOADED, 
// 		payload: fetch
// 	}
// }

// export const onCollapseOrderCanceledDeliveries = (order_deliveries) => {
// 	return {
// 		type: types.ORDER_CANCELED_DELIVERIES_COLLAPSED, 
// 		order_deliveries
// 	}
// }