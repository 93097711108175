import React, {useEffect, useState} from "react";
import { 
    AppBar, 
    Toolbar, 
    Box, 
    Typography, 
    useScrollTrigger, 
    IconButton,
    Button, 
    Stack,
    } from '@mui/material';
import { styled } from '@mui/material/styles';
import { MenuOutlined } from '@mui/icons-material';
import { Link,  useLocation, useNavigate } from "react-router-dom";
import logoPP from '../../../assets/images/logo-pp.png';
import logoWH from '../../../assets/images/logo-wh.png';

export default function Nav() {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const [logo, setLogo] = useState();
    const [linkColor, setLinkColor] = useState();

    useEffect(() => {
            if (pathname === '/' || pathname === '/privacy' || pathname === '/term') {
                setLogo(logoWH)
                setLinkColor("#FFF")
            } else {
                setLogo(logoPP)
                setLinkColor("#212141")
            }

      
    },[pathname]);

    function Navbar(props) {
        const { children, window } = props;
        // Note that you normally won't need to set the window ref as useScrollTrigger
        // will default to window.
        // This is only being set here because the demo is in an iframe.
        const trigger = useScrollTrigger({
          target: window ? window() : undefined,
          disableHysteresis: true,
          threshold: 3,
        });

        
      
        if (trigger) {
            if (pathname === '/' || pathname === '/privacy' || pathname === '/term') {
                return React.cloneElement(
                    children, {
                        elevation: 4,
                        style: {
                            backgroundColor: "#6D4BFF",
                            transition: 'background-color 300ms ease 0s'
                        }
                    }
                );
               
            } else {
                return React.cloneElement(
                        children, {
                        elevation: 4,
                        style: {
                            backgroundColor: "#FFF",
                            transition: 'background-color 300ms ease 0s'
                        }
                    }
                );
            }
        } else {
            return React.cloneElement(children, {
                elevation: 0,
                style: {
                    backgroundColor: 'transparent',
                    transition: 'background-color 300ms ease 0s'
                }
            });
        }
    }

    return (
        <Navbar>
            <HeaderBar position="fixed" elevation={0}>
                <Toolbar>    
                    <NavContentBox>
                        <LogoBox onClick={() => navigate('/')} sx={{ cursor: 'pointer'}} component="img" src={logo} />
                        <NavBox>
                            <Stack alignItems="center" direction="row" spacing={4}>
                                <LinkBox fontWeight="fontWeightBold">
                                    <LinkText onClick={() => navigate('/sign-in')} color={linkColor} variant="body1">
                                        Masuk
                                    </LinkText>
                                </LinkBox>
                                <LinkBox fontWeight="fontWeightBold">
                                    <Link to="/sign-up" className="link">
                                        <LoginButton variant="contained" size="medium">
                                            COBA GRATIS
                                        </LoginButton>
                                    </Link>
                                </LinkBox>
                            </Stack>
                        </NavBox>
                        <MobileNavBox>
                            <IconButton sx={{ color: linkColor }}>
                                <MenuOutlined />
                            </IconButton>
                        </MobileNavBox>
                    </NavContentBox>
                </Toolbar>
            </HeaderBar>
        </Navbar>
    )
}


const HeaderBar = styled(AppBar)(({theme}) => ({
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 50,
    paddingRight: 50,
    [theme.breakpoints.between('xs','sm')]: {
        paddingLeft: 0,
        paddingRight: 0,
    },
    [theme.breakpoints.between('sm', 'lg')]: {
        paddingLeft: 5,
        paddingRight: 5,
    },
}));

const NavContentBox = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-between'
}));

const LogoBox = styled(Box)(({theme}) => ({
    width: '150px',
    [theme.breakpoints.between('xs', 'md')]: {
        width: '100px'
    },
}));

const NavBox = styled(Box)(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.between('xs', 'md')]: {
        display: 'none',
    },
}));

const MobileNavBox = styled(Box)(({theme}) => ({
    display: 'none',
    [theme.breakpoints.between('xs', 'md')]: {
        display: 'block',
    }
}));

const LinkBox = styled(Box)(() => ({
    marginLeft: 32
}));

const LinkText = styled(Typography)(() => ({
    cursor: 'pointer',
    fontSize: '18px',
    fontWeight: '500'
}));


const LoginButton = styled(Button)(({ theme }) => ({
    backgroundColor: "#6200EE",
    '&:hover': {
        backgroundColor: "#4B00D1",
    }
}));
