import React from 'react';
import {Box, 
    Typography
    } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import Nav from '../Common/Nav';
import Footer from '../Common/Footer';
import header from '../../../assets/images/header.png';


export default function Privacy() {
    

    return (
        <Box>
            <Nav />
            <HeaderBox justifyContent="center" alignItems="center">
                <WhiteTypography fontWeight={500} variant="h4" gutterBottom>
                    Kebijakan Privasi
                </WhiteTypography>
            </HeaderBox>
            <ContentBox>
                <ItemBox>
                    <Typography gutterBottom variant="h5" color="primary">
                        Kebijakan Privasi AliPOS
                    </Typography>
                    <br />
                    <Typography textAlign="justify" variant="body2" gutterBottom>
                        Kami adalah pemilik konten dan operator situs website https://www.alipos.id, situs terkait dan microsites yang diakses melalui situs web tersebut (masing-masing disebut "Situs" dan secara kolektif disebut "Situs-Situs") ("AliPOS", dan/atau "Kami"). Harap perhatikan bahwa AliPOS tidak bertanggung jawab atas situs web lain yang dikelola oleh perusahaan lain dari grup Kami atau tautan ke situs atau konten lain di internet yang dimiliki atau dioperasikan oleh pihak ketiga. Situs atau konten terkait tidak berada di bawah kendali kami dan AliPOS tidak bertanggung jawab atas kesalahan, kelalaian, penundaan, pencemaran nama baik, fitnah, kebohongan, pornografi, konten asusila, ketidakakuratan atau materi lain yang terkandung dalam konten, atau konsekuensi dari mengakses situs web terkait.
                    </Typography>
                </ItemBox>
                <ItemBox>
                    <Typography gutterBottom variant="h5" color="primary">
                        Pernyataan Privasi
                    </Typography>
                    <br />
                    <Typography textAlign="justify"variant="body2" gutterBottom>
                        Melindungi privasi Anda sangat penting bagi AliPOS. Kami menghargai privasi Anda dan kami berkomitmen untuk melindungi setiap dan semua informasi pribadi yang mungkin diberikan oleh Anda dan oleh karena itu Kami akan memastikan perlakuan yang adil, keandalan, kerahasiaan, dan keamanan informasi pribadi Anda. Kami merancang Kebijakan Privasi ini untuk menjelaskan kepada Anda bagaimana kami mengumpulkan, menggunakan, dan membagikan informasi pribadi Anda. "Informasi Pribadi" berarti informasi apapun yang dapat digunakan untuk mengindentifikasi Anda secara pribadi (tidak termasuk informasi apapun yang telah dikumpulkan atau dibuat anonim) dan sebagaimana ditafsirkan berdasarkan Peraturan Menteri Komunikasi dan Teknologi Informasi No. 20 tahun 2016 tentang Perlindungan Data Pribadi dalam Sistem Elektronik.
                        Informasi yang terdapat dalam Kebijakan Privasi ini telah dipersiapkan sehubungan dan sesuai dengan peraturan yang berlaku dan untuk digunakan khusus oleh AliPOS dan afiliasinya dan/atau penerima pengalihannya.
                    </Typography>
                    <Typography textAlign="justify" variant="body2" gutterBottom>
                        Kebijakan Privasi ini (beserta syarat-syarat penggunaan dari situs Kami sebagaimana tercantum dalam Syarat & Ketentuan dan informasi lain yang tercantum di Situs) menetapkan dasar atas perolehan, pengumpulan, pengolahan, penganalisisan, penampilan, pengiriman, pembukaan, penyimpanan, perubahan, penghapusan dan/atau segala bentuk pengelolaan yang terkait dengan data atau informasi yang mengidentifikasikan atau dapat digunakan untuk mengidentifikasi Pengguna, yang diberikan oleh Pengguna kepada Kami atau yang AliPOS kumpulkan dari Pengguna maupun pihak ketiga.
                    </Typography>
                </ItemBox>
                <ItemBox>
                    <Typography gutterBottom variant="h5" color="primary">
                        Penerimaan dan Persetujuan Anda
                    </Typography>
                    <br />
                    <Typography textAlign="justify" variant="body2" gutterBottom>
                        Anda wajib membaca Kebijakan Privasi Kami dari waktu ke waktu. Dengan mengakses atau menggunakan layanan atau produk Kami dan/atau Situs Kami, hal tersebut akan dianggap sebagai persetujuan dan penerimaan Anda terhadap Kebijakan Privasi Kami, dengan demikian, Anda setuju untuk terikat pada setiap ketentuan yang tercantum dalam Kebijakan Privasi kami.
                        Jika Anda tidak setuju dengan ketentuan atau ketentuan apapun dalam Kebijakan Privasi ini, Anda dipersilahkan untuk tidak menggunakan layanan atau produk kami dan/atau Situs Web Kami. Dengan dan/atau tidak mengirimkan pemberitahuan ke email Anda, Kami memiliki hak untuk merevisi dan/atau memperbaharui Kebijakan Privasi Kami dari waktu ke waktu dan Anda setuju untuk terikat dengan Kebijakan Privasi yang direvisi dan/atau diperbarui tersebut. Jika Anda tidak setuju dengan kentuan Kami yang direvisi dan/atau diperbarui dalam Kebijakan Privasi Kami, Anda dipersilahkan untuk berhenti mengakses layanan atau produk dan/atau Situs Web Kami.
                    </Typography>
                    <Typography textAlign="justify" variant="body2" gutterBottom>
                        Penerimaan dan persetujuan Anda terhadap Kebijakan Privasi ini juga akan berkaitan dengan ketentuan yang tercantum berdasarkan <Link to="/term-and-condition">Syarat dan Ketentuan</Link> dan tidak dapat dipisahkan, yang merupakan satu perjanjian yang mengatur hak dan kewajiban Kami kepada Anda.
                    </Typography>
                    <Typography  textAlign="justify" variant="body2" gutterBottom>
                        Dengan mengklik “Daftar” (Register) atau pernyataan serupa yang tersedia di laman pendaftaran Situs, Pengguna menyatakan bahwa setiap data pribadi Pengguna merupakan data yang benar dan sah, Pengguna mengakui bahwa Anda telah diberitahukan dan memahami ketentuan Kebijakan Privasi ini serta Pengguna memberikan persetujuan kepada AliPOS untuk memperoleh, mengumpulkan, mengolah, menganalisis, menampilkan, mengirimkan, membuka, menyimpan, mengubah, menghapus, mengelola dan/atau mempergunakan data tersebut untuk tujuan sebagaimana tercantum dalam Kebijakan Privasi.
                    </Typography>
                </ItemBox>
                <ItemBox>
                    <Typography gutterBottom variant="h5" color="primary">
                        Pengumpulan Informasi Pribadi Anda
                    </Typography>
                    <br />
                    <Typography textAlign="justify" variant="body2" gutterBottom>
                        Kami mengumpulkan dan mengelola informasi pribadi Anda ketika Anda mendaftar pada situs web Kami sebagai bagian dari proses verifikasi identitas Pengguna, dalam proses transaksi, mengelola, memperlancar proses, dan/atau ketika Anda meminta tanda terima digital, serta  tujuan-tujuan lainnya selama diizinkan oleh peraturan perundang-undangan yang berlaku.. Kami dapat mengumpulkan dan mengelola, tetapi tidak terbatas pada pengumpulan dan pengelolaan, sebagai berikut:
                    </Typography>
                    <Box>
                        <ol>
                            <li>
                                <Typography textAlign="justify" variant="body2" gutterBottom>
                                    Informasi dasar pengguna, seperti nama lengkap, alamat email, username, nomor kontak, alamat tempat tinggal dan tempat tinggal tetap, nomor telepon, tanggal lahir, usia, foto ktp dan foto diri, dan informasi transaksi dan/atau rincian rekening bank Anda;
                                </Typography>
                            </li>
                            <li>
                                <Typography textAlign="justify" variant="body2" gutterBottom>
                                    Lokasi riil atau perkiraannya seperti alamat IP, lokasi Wi-Fi dan geo-location;
                                </Typography>
                            </li>
                            <li>
                                <Typography textAlign="justify" variant="body2" gutterBottom>
                                    Data perangkat, diantaranya jenis perangkat yang digunakan untuk mengakses Situs, termasuk model perangkat keras, sistem operasi dan versinya, perangkat lunak, nomor IMEI, nama file dan versinya, pilihan bahasa, pengenal perangkat unik, nomor seri, informasi gerakan perangkat, dan/atau informasi jaringan seluler;
                                </Typography>
                            </li>
                            <li>
                                <Typography textAlign="justify" variant="body2" gutterBottom>
                                    Informasi pekerjaan, kartu kredit, dan/atau informasi rekening keuangan;
                                </Typography>
                            </li>
                            <li>
                                <Typography textAlign="justify" variant="body2" gutterBottom>
                                    Informasi apapun yang diunggah oleh Anda atau informasi apapun tentang produk dan layanan Anda;
                                </Typography>
                            </li>
                            <li>
                                <Typography textAlign="justify" variant="body2" gutterBottom>
                                    Informasi yang melekat pada Anda saat Anda mengakses Situs Kami dan/atau menggunakan Aplikasi/Platform AliPOS;
                                </Typography>
                            </li>
                            <li>
                                <Typography textAlign="justify" variant="body2" gutterBottom>
                                    Informasi apapun di komputer, ponsel, atau perangkat lain tempat Anda memasang aplikasi Kami atau mengakses Layanan Kami, yang akan bergantung pada izin yang Anda berikan;
                                </Typography>
                            </li>
                            <li>
                                <Typography textAlign="justify" variant="body2" gutterBottom>
                                    Informasi pribadi yang berasal dari pihak ketiga yang bekerja sama dengan Kami seperti vendor, agen, penasihat, profesional, konsultan, dan pihak lain yang dapat Kami kumpulkan sehubungan dengan tujuan dari keterlibatan pihak ketiga;
                                </Typography>
                            </li>
                            <li>
                                <Typography textAlign="justify" variant="body2" gutterBottom>
                                    Setiap informasi yang dikumpulkan oleh cookies, suar web, serta informasi log umum;
                                </Typography>
                            </li>
                        </ol>
                    </Box>
                    <Typography textAlign="justify" variant="body2" gutterBottom>
                        Kami berhak, dari waktu ke waktu, melakukan verifikasi terhadap informasi pribadi yang Anda berikan kepada kami, sehingga data dan informasi Pengguna akurat, lengkap, dan terbaru, termasuk memberhentikan Layanan sementara dan/atau memberhentikan Layanan secara penuh, dalam hal Pengguna belum melakukan verifikasi dan/atau pemukhtahiran data pribadi Pengguna, yang dapat dilakukan dengan mengirimkan surat verifikasi, e-mail, atau mengharuskan Anda untuk mengirimkan dokumentasi pendukung, atau cara apapun, sebagaimana yang diminta.
                    </Typography>
                    <Typography  textAlign="justify" variant="body2" gutterBottom>
                        Anda dengan ini setuju bahwa Informasi Pribadi Anda dapat diungkapkan kepada pihak-pihak sebagai berikut:
                    </Typography>
                    <Box>
                        <ol>
                            <li>
                                <Typography textAlign="justify" variant="body2" gutterBottom>
                                    Afiliasi dan anak perusahaan, agen dan subkontraktor perusahaan;
                                </Typography>
                            </li>
                            <li>
                                <Typography textAlign="justify" variant="body2" gutterBottom>
                                    Pemerintah, badan pengatur, dan lembaga pencegah penipuan untuk tujuan mengidentifikasi, mencegah, mendeteksi, atau menanggulangi penipuan, pencucian uang, atau kejahatan lainnya, dan untuk tujuan yang sah lainnya;
                                </Typography>
                            </li>
                            <li>
                                <Typography textAlign="justify" variant="body2" gutterBottom>
                                    Kepada pihak ketiga untuk tujuan manajemen risiko;  
                                </Typography>
                            </li>
                            <li>
                                <Typography textAlign="justify" variant="body2" gutterBottom>
                                    Entitas lain yang mungkin diwajibkan oleh hukum atau sebagai kepentingan; dan
                                </Typography>
                            </li>
                            <li>
                                <Typography textAlign="justify" variant="body2" gutterBottom>
                                    Pembeli pada saat terjadinya akuisisi. Anda memahami dan menyadari bahwa pengungkapan tersebut harus dibuat dan setuju untuk mengesampingkan hak Anda untuk mengajukan klaim ganti rugi, sejauh yang diizinkan oleh undang-undang;
                                </Typography>
                            </li>
                        </ol>
                    </Box>
                </ItemBox>
                <ItemBox>
                    <Typography gutterBottom variant="h5" color="primary">
                        Penggunaan Informasi
                    </Typography>
                    <br />
                    <Typography textAlign="justify" variant="body2" gutterBottom>
                        AliPOS dapat menggunakan informasi yang diperoleh dan dikumpulkan dari Pengguna sebagaimana yang disebutkan dalam bagian sebelumnya untuk hal-hal sebagai berikut:
                    </Typography>
                    <Box>
                        <ol>
                            <li>
                                <Typography textAlign="justify" variant="body2" gutterBottom>
                                    Memproses segala bentuk permintaan, aktivitas maupun transaksi yang dilakukan oleh Pengguna melalui Plaform AliPOS, termasuk transaksi antara Pengguna dengan pelanggan Pengguna;
                                </Typography>
                            </li>
                            <li>
                                <Typography textAlign="justify" variant="body2" gutterBottom>
                                    Mengirimkan berita dan informasi tentang layanan Kami dan untuk berkomunikasi dengan Anda tentang produk, layanan, promosi, dan pembaruan yang ditawarkan oleh Kami baik kepada Anda, dan/atau kepada mitra atau Pengguna tertentu yang dibatasi dan dipilih oleh Kami;
                                </Typography>
                            </li>
                            <li>
                                <Typography textAlign="justify" variant="body2" gutterBottom>
                                    Verifikasi data Pengguna;
                                </Typography>
                            </li>
                            <li>
                                <Typography textAlign="justify" variant="body2" gutterBottom>
                                    Monitoring atas transaksi ataupun investigasi terhadap transaksi-transaksi mencurigakan atau transaksi yang terindikasi mengandung unsur kecurangan atau pelanggaran terhadap Syarat dan Ketentuan atau ketentuan hukum yang berlaku, serta melakukan tindakan-tindakan yang diperlukan sebagai tindak lanjut dari hasil monitoring atau investigasi transaksi tersebut;
                                </Typography>
                            </li>
                            <li>
                                <Typography textAlign="justify" variant="body2" gutterBottom>
                                    Meningkatkan produk, layanan, dan/atau situs Kami dalam memenuhi permintaan Anda;
                                </Typography>
                            </li>
                            <li>
                                <Typography textAlign="justify" variant="body2" gutterBottom>
                                    Memelihara catatan internal;
                                </Typography>
                            </li>
                            <li>
                                <Typography textAlign="justify" variant="body2" gutterBottom>
                                    Menanggapi pertanyaan dan komentar Anda, untuk memberi Anda akses ke produk atau layanan Kami dan untuk berkomunikasi dengan Anda tentang produk atau layanan Kami;
                                </Typography>
                            </li>
                            <li>
                                <Typography textAlign="justify" variant="body2" gutterBottom>
                                    Menyelidiki dugaan kecurangan, pelecehan, ancaman fisik, atau pelanggaran lain terhadap hukum, aturan atau peraturan apapun, aturan atau kebijakan situs, atau hak pihak ketiga, dan/atau untuk menyelidiki segala perilaku yang dicurigai yang kami anggap tidak patut;
                                </Typography>
                            </li>
                            <li>
                                <Typography textAlign="justify" variant="body2" gutterBottom>
                                    Untuk tujuan internal kami seperti audit, analisis data, dan penelitian untuk meningkatkan produk, layanan, dan komunikasi pelanggan Kami;
                                </Typography>
                            </li>
                            <li>
                                <Typography textAlign="justify" variant="body2" gutterBottom>
                                    Untuk pengungkapan yang disyaratkan oleh hukum, peraturan, atau perintah pengadilan; 
                                </Typography>
                            </li>
                            <li>
                                <Typography textAlign="justify" variant="body2" gutterBottom>
                                    Untuk tujuan atau sehubungan dengan proses hukum atau diperlukan untuk menetapkan, membela, atau melaksanakan hak hukum; dan
                                </Typography>
                            </li>
                            <li>
                                <Typography textAlign="justify" variant="body2" gutterBottom>
                                    Tujuan lain yang disampaikan dari waktu ke waktu kepada Pengguna, serta dizinkan atau diwajibkan oleh hukum dan peraturan yang berlaku;
                                </Typography>
                            </li>
                        </ol>
                    </Box>
                    <Typography textAlign="justify" variant="body2" gutterBottom>
                        Kami dapat menggunakan informasi tentang Anda untuk meningkatkan, menyesuaikan, dan memfasilitasi penggunaan layanan dan produk Kami untuk memberikan layanan yang lebih baik.
                    </Typography>
                    <Typography textAlign="justify" variant="body2" gutterBottom>
                        AliPOS, afiliasi PT Avant Indonesia, perusahaan grup, dan anak perusahaan PT Avant Indonesia, termasuk perwakilan PT Avant Indonesia, dan pihak ketiga yang bekerja sama dengan PT Avant Indonesia, dalam menyediakan Layanan berhak memanfaatkan dan mengolah data dan informasi pribadi Pengguna untuk memenuhi tujuan Layanan, tujuan Kebijakan Privasi ini, tujuan peraturan perundang-undangan dan hukum yang berlaku. Pemanfaatan dan pengolahan data tersebut dicakup oleh perjanjian dan memuat klausul kerahasiaan yang mengikat para pihak. Dalam hal ini, termasuk Perwakilan AliPOS.
                    </Typography>
                    <Typography textAlign="justify" variant="body2" gutterBottom>
                     Kami dapat memindahkan Informasi Data Pribadi Pengguna ke platform afiliasi atau penyedia layanan AliPOS atau kepada penerima pihak ketiga. Oleh karena itu, dengan menyetujui Kebijakan Privasi ini, Pengguna juga memberikan persetujuan untuk memindahkan Informasi Data Pribadi ke platform afiliasi atau penyedia layanan AliPOS atau kepada penerima data pihak ketiga untuk pengembangan layanan baik yang bersifat teknis, komersial dan lainnya. Dimana pun Informasi Data Pribadi Pengguna dipindahkan, disimpan atau diproses oleh Kami atau platform afiliasi atau pihak ketiga, atau pihak lain penyedia layanan AliPOS, Kami akan mengambil langkah yang wajar untuk melindungi privasi Informasi Data Pribadi Pengguna.
                    </Typography>
                </ItemBox>
                <ItemBox>
                    <Typography gutterBottom variant="h5" color="primary">
                        Berbagi Informasi Pribadi
                    </Typography>
                    <br />
                    <Typography textAlign="justify" variant="body2" gutterBottom>
                        Anda dengan ini setuju bahwa Informasi Pribadi Anda dapat dibagikan kepada afiliasi atau vendor Kami dan/atau pihak ketiga lainnya, untuk tujuan pemrosesan yang mungkin diperlukan dalam pemeliharaan dan pelaksanaan tugas dan tanggung jawab layanan Kami, sesuai dengan hukum, aturan, dan peraturan yang berlaku. Kami mungkin, jika perlu, melibatkan subkontraktor atau pemroses data dalam setiap keadaan sehubungan dengan tujuan yang disebutkan di atas.
                    </Typography>
                    <Typography textAlign="justify" variant="body2" gutterBottom>
                        Semua perjanjian pemrosesan, subkontrak atau pembagian data akan sesuai dengan persyaratan berdasarkan Peraturan No. 20/2016, atau undang-undang dan peraturan yang berlaku dan diimplementasikan. Sejauh pemrosesan, subkontrak, atau pembagian data membutuhkan pemberitahuan atau persetujuan, kami akan memberikan atau meminta pemberitahuan dan/atau persetujuan tersebut, dalam setiap keadaan, sebelum pemrosesan atau pembagian Informasi Pribadi Anda (jika berlaku). 
                    </Typography>
                    <Typography textAlign="justify" variant="body2" gutterBottom>
                        Kecuali sebagaimana yang ditetapkan di sini, kami tidak akan memberikan Informasi Pribadi Anda kepada pihak ketiga tanpa persetujuan khusus Anda.AliPOS dapat menggunakan informasi yang diperoleh dan dikumpulkan dari Pengguna sebagaimana yang disebutkan dalam bagian sebelumnya untuk hal-hal sebagai berikut:
                    </Typography>
                </ItemBox>
                <ItemBox>
                    <Typography gutterBottom variant="h5" color="primary">
                        Retensi Informasi Pribadi Anda
                    </Typography>
                    <br />
                    <Typography textAlign="justify" variant="body2" gutterBottom>
                        Sejauh diizinkan oleh undang-undang, Informasi Pribadi akan disimpan oleh Kami untuk periode yang diperlukan untuk memenuhi tujuan yang diuraikan dalam Kebijakan Privasi ini.
                    </Typography>
                </ItemBox>
                <ItemBox>
                    <Typography gutterBottom variant="h5" color="primary">
                        Keamanan Informasi Pribadi Anda
                    </Typography>
                    <br />
                    <Typography textAlign="justify" variant="body2" gutterBottom>
                        Kami menghargai Informasi Pribadi yang dikumpulkan, digunakan, dan disimpan baik melalui situs Kami dan/atau aplikasi AliPOS, dan akan mengambil tindakan yang wajar termasuk perlindungan administratif, fisik dan teknis, untuk melindungi data tersebut dari akses, penggunaan, modifikasi, pengambilan dan/atau pengungkapan tidak sah dengan menggunakan sejumlah tindakan dan prosedur keamanan, termasuk kata sandi dan kode <span style={{fontStyle: "italic"}}>One Time Password</span> (OTP) Pengguna. 
                    </Typography>
                    <Typography textAlign="justify" variant="body2" gutterBottom>
                        Kami akan menggunakan upaya terbaik untuk mengamankan dan melindungi informasi Pengguna, perlu diketahui bahwa pengiriman data melalui Internet tidak pernah sepenuhnya aman. Dengan demikian, Kami tidak menjamin bahwa pihak ketiga yang tidak sah tidak akan dapat mengalahkan upaya keamanan kami atau menggunakan informasi pribadi Anda untuk tujuan yang tidak benar. Jika informasi dikompromikan sebagai akibat dari pelanggaran keamanan, Kami akan mengambil langkah-langkah yang wajar untuk menyelidiki situasi dan jika perlu, memberi tahu individu-individu dalam hal ini pengguna yang dirugikan, yang informasinya mungkin telah disalahgunakan dan mengambil langkah-langkah lain sesuai dengan undang-undang atau peraturan yang berlaku. 
                    </Typography>
                    <Typography textAlign="justify" variant="body2" gutterBottom>
                        Sejauh diizinkan oleh undang-undang, Kami tidak bertanggung jawab dan tidak berkewajiban atas segala kerusakan, kerugian, dan/atau klaim langsung, tidak langsung, konsekuensial, atau hukuman, yang mungkin Anda derita karena kehilangan, akses tidak sah, penyalahgunaan, atau perubahan informasi apa pun yang Anda kirimkan ke Situs. 
                    </Typography>
                </ItemBox>
                <ItemBox>
                    <Typography gutterBottom variant="h5" color="primary">
                        Kerahasiaan Akun Anda
                    </Typography>
                    <br />
                    <Typography textAlign="justify" variant="body2" gutterBottom>
                        Anda harus bertanggung jawab untuk menjaga kerahasiaan akun dan kata sandi Anda sendiri, serta setiap dan semua aplikasi yang diajukan, kewajiban yang disetujui atau dimasukkan ke dalam dan semua kegiatan lain yang dilakukan berdasarkan akun tersebut. Anda setuju untuk segera memberi tahu Kami tentang penggunaan atau pengungkapan yang tidak sah atas akun atau kata sandi Anda, setiap kegiatan tidak sah di bawah akun Anda atau pelanggaran keamanan lainnya. 
                    </Typography>
                    <Typography textAlign="justify" variant="body2" gutterBottom>
                        Kami tidak akan bertanggung jawab atas kerugian apa pun yang mungkin Anda alami akibat penggunaan pihak ketiga atas akun atau kata sandi Anda, baik dengan atau tanpa sepengetahuan Anda, baik dengan atau tanpa kesalahan atau kelalaian dari pihak Kami.  
                    </Typography>
                </ItemBox>
                <ItemBox>
                    <Typography gutterBottom variant="h5" color="primary">
                        Pengalihan Aset
                    </Typography>
                    <br />
                    <Typography textAlign="justify" variant="body2" gutterBottom>
                        Selama menjalankan bisnis, Kami dapat menjual atau membeli aset. Jika entitas lain mengakuisisi Kami atau semua atau secara substansial semua Aset Kami, Informasi yang Kami kumpulkan tentang Anda dapat ditransfer ke entitas tersebut. Juga, jika terdapat proses kepailitan atau perubahan organisasi yang dilakukan oleh atau terhadap Kami, informasi tersebut dapat dianggap sebagai aset milik Kami dan dapat dijual atau dialihkan kepada pihak ketiga. Jika hal-hal di atas terjadi, Anda setuju untuk memberikan Kami izin Anda dan hak untuk kami mentransfer informasi Anda ke pihak ketiga tanpa pemberitahuan lebih lanjut, sejauh diizinkan oleh undang-undang dan peraturan yang berlaku.  
                    </Typography>
                </ItemBox>
                <ItemBox>
                    <Typography gutterBottom variant="h5" color="primary">
                        Penyimpanan dan Penghapusan Informasi
                    </Typography>
                    <br />
                    <Typography textAlign="justify" variant="body2" gutterBottom>
                        AliPOS akan menyimpan informasi selama akun Pengguna tetap aktif dan dapat melakukan penghapusan sesuai dengan ketentuan peraturan hukum yang berlaku.
                    </Typography>
                </ItemBox>
                <ItemBox>
                    <Typography gutterBottom variant="h5" color="primary">
                        Hukum Yang Berlaku
                    </Typography>
                    <br />
                    <Typography textAlign="justify" variant="body2" gutterBottom>
                        Setiap referensi terhadap hukum dan peraturan yang berlaku berdasarkan Kebijakan Privasi ini harus ditafsirkan sesuai dengan ketentuan yang tercantum di bawah hukum dan peraturan Republik Indonesia.
                    </Typography>
                </ItemBox>
                <ItemBox>
                    <Typography gutterBottom variant="h5" color="primary">
                        Keterpisahan
                    </Typography>
                    <br />
                    <Typography textAlign="justify" variant="body2" gutterBottom>
                        Jika ada ketentuan atau ketentuan dari Kebijakan Privasi ini akan diadakan atau dinyatakan tidak sah, tidak berdasar atau tidak dapat diberlakukan untuk alasan apapun oleh aturan hukum atau kebijakan publik, ketentuan tersebut harus dicabut sejauh tidak sah atau tidak dapat dilaksanakan dan ketentuan lainnya akan terus berlanjut dengan kekuatan dan keberlakuan yang penuh.
                    </Typography>
                </ItemBox>
                <ItemBox>
                    <Typography gutterBottom variant="h5" color="primary">
                        Hubungi Kami
                    </Typography>
                    <br />
                    <Typography textAlign="justify" variant="body2" gutterBottom>
                        Untuk pertanyaan, kekhawatiran atau klarifikasi apapun tentang Kebijakan Privasi Kami, Anda dapat menghubungi Kami melalui WA pada 08176009888 dan/atau email ke privacy@alipos.id. 
                    </Typography>
                </ItemBox>
                <ItemBox>
                    <Typography textAlign="justify" variant="body2" component="div" color="primary" gutterBottom>
                        Diperbarui pada 2 Nov 2021
                    </Typography>
                </ItemBox>
            </ContentBox>
            <Footer />
        </Box>
    )

    
}


const HeaderBox = styled(Box)(({theme}) => ({
    backgroundImage: `url(${header})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center top",
    display: "flex",
    flexDirection: "column",
    padding: 75,
    paddingTop: 170,
    paddingBottom: 145,
    [theme.breakpoints.between('xs','sm')]: {
        paddingLeft: 15,
        paddingRight: 15
    },
    [theme.breakpoints.between('sm', 'lg')]: {
        paddingLeft: 25,
        paddingRight: 25
    }
}));

const ContentBox = styled(Box)(({theme}) => ({
    padding: 75,
    marginBottom: '-25%',
    [theme.breakpoints.between('xs','sm')]: {
        paddingLeft: 15,
        paddingRight: 15
    },
    [theme.breakpoints.between('sm', 'lg')]: {
        paddingLeft: 25,
        paddingRight: 25
    }
}));

const ItemBox = styled(Box)(({theme}) => ({
    marginBottom: 45
}));

const WhiteTypography = styled(Typography)(({theme}) => ({
    color: '#FFF'
}));
