import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { 
    Dialog,
    Slide,
} from '@mui/material';
import Content from './Content';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function Product() {
    const {onOrderDeliveries} = useSelector(state => ({
        ...state.order
    }), shallowEqual);   

    return (
        <Dialog
            open={onOrderDeliveries}
            fullScreen
            TransitionComponent={Transition}
            scroll={'paper'}
            style={{zIndex: 9999}}
            >
            <Content />
        </Dialog>
    )
}

