import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import List from './List';
import New from './Form';
import { 
    Dialog,
    Slide,
} from '@mui/material';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


export default function Addresses() {
    const {onAddresses, onFormAddresses} = useSelector(state => ({
        ...state.customer
    }), shallowEqual);
  
    function Content() {
        if (onFormAddresses) {
            return <New />
        }
        
        return <List />
    }

    return (
        <Dialog
            open={onAddresses}
            maxWidth="md"
            fullWidth={true}
            TransitionComponent={Transition}
            scroll={'paper'}>
            {Content()}
        </Dialog>
    )
}

