import React, { useState, useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import * as productActions from '../../../../../../../../actions/productActions';
import { 
    Dialog,
    DialogActions,
    Slide,
    Button,
    Grid
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Content from './Content';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
export default function AdvancePricing() {
    const dispatch = useDispatch();
    const {onAdvancedPrice} = useSelector(state => ({
        ...state.product
    }), shallowEqual);

    const [productPrices, setProductPrices] = useState([]);

    function onChange(productPrices) {
        setProductPrices(productPrices);
    }

    function onHide() {
        dispatch(productActions.onHideAdvancedPrice());
        setProductPrices([])
    }

    function onDone() {
        dispatch(productActions.onDoneAdvancedPrice(productPrices));
    }
    
    return (
        <Dialog
            open={onAdvancedPrice}
            maxWidth="md"
            fullWidth={true}
            TransitionComponent={Transition}
            scroll={'paper'}
            sx={{ overflowY: 'unset'}}
            >
            <Content
                onChange={onChange.bind(this)}
                productPrices={productPrices} />
            <WrapperActions >
                <Grid container spacing={3} justifyContent="flex-end">
                    <Grid item>
                        <CancelButton  
                            size="large"
                            disableElevation
                            variant="contained"
                            onClick={onHide} 
                            color="secondary">
                            Batal
                        </CancelButton>
                    </Grid>
                    <Grid item>
                        <Button size="large"
                            sx={{minWidth: '165px'}}
                            disableElevation
                            onClick={onDone}
                            variant="contained" color="primary">
                            Selesai
                        </Button>
                    </Grid>
                </Grid>
                
           
            </WrapperActions>
        </Dialog>
    )
}

const WrapperActions = styled(DialogActions)(({theme}) => ({
    padding: '15px 45px 45px 45px !important'
}));

const CancelButton = styled(Button)(({theme}) => ({
    color: theme.palette.text.primary,
    backgroundColor: "#FFFFFF",
    '&:hover': {
      backgroundColor: "#FFFFFF",
    },
    fontWeight: 500,
    minWidth: 165,
    border: `1px solid #ddd`
}));
