import React, { useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import * as customerActions from '../../../../../actions/customerActions';
import { 
        Typography,
        Box,
        Grid,
        Tabs,
        Tab,
        Paper
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate, useParams } from "react-router-dom";
import Loader from '../../../../Helper/Loader';
import Profile from './Profile';
import Account from './Account';
import Address from './Address';
import Payment from './Payment';
import LineChart from './LineChart';
import Order from './Order';
import OrderItem from './OrderItem';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <Box
        sx={{width: '100%'}}
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            {children}
          </Box>
        )}
      </Box>
    );
  }

function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
  }

export default function Show() {    
    let navigate = useNavigate();
    let { id } = useParams();
    const dispatch = useDispatch();
    const {customer, errors} = useSelector(state => ({
        ...state.customer
    }), shallowEqual); 

    const [value, setValue] = React.useState(0);
  

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
    },[errors]);

    useEffect(() => {
        dispatch(customerActions.onLoadCustomer(id));
        return () => {
            dispatch(customerActions.onUnloadCustomer())
        };
    },[]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
      };

    if (!customer) {
        return (
            <Loader />
        )
    }

    return (
        <ContentBox>
            <HeaderBox>
                <Typography fontWeight={700} variant="h6">
                    Informasi Pelanggan
                </Typography>
            </HeaderBox>
            <Content>
                <Grid container spacing={3}>
                    <Grid item container spacing={3}>
                        <Grid item xs container>
                            <Profile />
                        </Grid>
                        <Grid item xs container direction="column" spacing={3}>
                            <Grid item xs container>
                                <Account />
                            </Grid>
                            <Grid item xs container>
                                <Address />
                            </Grid>
                        </Grid>
                        <Grid item xs container>
                            <Payment />
                        </Grid>
                    </Grid>
                    <Grid item container>
                    <Paper elevation={3} 
                        sx={{flexGrow: 1, bgcolor: 'background.paper', display: 'flex' }}>
                        <Tabs
                            orientation="vertical"
                            variant="scrollable"
                            value={value}
                            onChange={handleChange}
                            sx={{ minWidth: '225px', borderRight: 1, borderColor: 'divider' }}>
                            <Tab label="Statistik Pesanan" {...a11yProps(0)} />
                            <Tab label="Riwayat Pesanan" {...a11yProps(1)} />
                            <Tab label="Riwayat Barang" {...a11yProps(2)} />
                        </Tabs>
                        <TabPanel value={value} index={0}>
                            <LineChart />
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <Order />
                        </TabPanel>
                        <TabPanel value={value} index={2}>
                            <OrderItem />
                        </TabPanel>
                    </Paper>
                    {/* <Paper elevation={3} style={{flexGrow: 1, width: '100%'}}>
                        <Grid container>
                            <Grid item xs={2}>
                                <Tabs
                                    orientation="vertical"
                                    variant="scrollable"
                                    value={value}
                                    onChange={handleChange}
                                    aria-label="Vertical tabs example"
                                    sx={{ borderRight: 1, borderColor: 'divider' }}
                                >
                                    <Tab label="Laporan Penjualan" {...a11yProps(0)} />
                                    <Tab label="Riwayat Pesanan" {...a11yProps(1)} />
                                    <Tab label="Riwayat Barang" {...a11yProps(2)} />
                                </Tabs>
                            </Grid>
                            <Grid item xs={10}>
                                <TabPanel value={value} index={0}>
                                    <LineChart />
                                </TabPanel>
                                <TabPanel value={value} index={1}>
                                    Item Two
                                </TabPanel>
                                <TabPanel value={value} index={2}>
                                    Item Three
                                </TabPanel>
                            </Grid>
                        </Grid>
                        </Paper>
                         */}
                    </Grid>
                </Grid>
            </Content>
        </ContentBox>
    )
}

const ContentBox = styled(Box)(({theme}) => ({
    padding: '25px 0',
    [theme.breakpoints.down('sm')]: {
    padding: '15px 15px 0 15px',
    },
    [theme.breakpoints.down('md')]: {
    padding: '15px 15px 0 15px',
    },
    height: '100%'
}));


const HeaderBox = styled(Box)(({theme}) => ({
    paddingLeft: 0,
    marginBottom: 25,
    [theme.breakpoints.down('sm')]: {
        paddingLeft: 55,
    },
}));

const Content = styled(Box)(({theme}) => ({
    paddingBottom: 15
}));