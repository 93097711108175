import React, { useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import * as resetActions from '../../../actions/resetActions';
import { 
    Box, 
    Container,
    Paper,
    Typography, 
    TextField,
    Button,
    Grid
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { styled } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";
import logo from '../../../assets/images/logo-pp.png';

export default function Form() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { verified_user, inProgress, errors } = useSelector(state => ({
        ...state.common,
        ...state.reset,
    }), shallowEqual);

    // useEffect(() => {
    //     dispatch(registerActions.onLoadRegister())
    // },[dispatch]);

    function onUpdateField(event) {
		const key = event.target.name;
        const value = event.target.value;
        dispatch(resetActions.onUpdateField(key, value));
	}

    function onUpdate() {
        dispatch(resetActions.onUpdate(verified_user));
	}

    function ActionButton() {
        if (inProgress) {
            return (
                <LoadingButton loading fullWidth size="large" variant="contained">Selanjutnya</LoadingButton>
            )
        } else {
            return (
                <Button onClick={onUpdate} fullWidth size="large" variant="contained">Simpan</Button>
            )
        }
    }

    if (!verified_user) {
        return null;
    }

    const { email_phone, password, password_confirmation  } = verified_user;

    function error(key) {
        if (errors != null && errors[`user.${key}`] != null) {
            return errors[`user.${key}`].msg
        }
        return null;
    }

    return (
        <SectionBox>
            <ContentBox>
                <ContentContainer maxWidth="sm">
                    <LogoBox onClick={() => navigate('/')} sx={{ cursor: 'pointer'}} component="img" src={logo} />
                    <FormPaper elevation={3}>
                        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                            <Typography fontWeight={700} variant="h5" gutterBottom>
                                Reset Password
                            </Typography> 
                            <Box display="flex" sx={{textAlign: 'center'}}>
                                <Typography fontWeight={500} variant="body1" gutterBottom>
                                    Masukan password baru Anda.
                                </Typography>
                            </Box>
                        </Box>
                        <FieldWrapperBox>
                            <FieldBox>
                                <TextField 
                                fullWidth 
                                name="email_phone"
                                label="Email / No. HP" 
                                variant="outlined"
                                disabled
                                helperText="Contoh: email@alipos.com"
                                value={email_phone} />
                            </FieldBox>
                            <FieldBox>
                                <TextField 
                                    fullWidth 
                                    name="password"
                                    label="Password" 
                                    variant="outlined"
                                    type="password"
                                    value={password}
                                    onChange={onUpdateField}
                                    error={!!error('password')}
                                    helperText={error('password')} 
                                    />
                            </FieldBox>
                            <FieldBox>
                                <TextField 
                                    fullWidth 
                                    name="password_confirmation"
                                    label="Konfirmasi Password" 
                                    variant="outlined"
                                    type="password"
                                    value={password_confirmation}
                                    onChange={onUpdateField}
                                    error={!!error('password_confirmation')}
                                    helperText={error('password_confirmation')} 
                                    />
                            </FieldBox>
                        </FieldWrapperBox>
                        <ActionButton />
                    </FormPaper>
                </ContentContainer>
            </ContentBox>
        </SectionBox>

    )
}

const SectionBox = styled(Box)(({theme}) => ({
    background: '#f4f3fc',
}));

const ContentBox = styled(Box)(({theme}) => ({
    padding: 35
}));


const ContentContainer = styled(Container)(({theme}) => ({
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center'
}));

const FormPaper = styled(Paper)(({theme}) => ({
    backgroundColor: 'rgb(255, 255, 255)',
    color: 'rgb(34, 51, 84)',
    transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    borderRadius: '8px',
    boxShadow: 'rgb(159 162 191 / 18%) 0px 9px 16px, rgb(159 162 191 / 32%) 0px 2px 2px',
    overflow: 'hidden',
    marginTop: 55,
    padding: 35,
    width: '100%',
    [theme.breakpoints.between('xs','sm')]: {
        padding: 20
    },
}));

const FieldWrapperBox = styled(Box)(({theme}) => ({
    marginTop: 45,
    marginBottom: 35
}));

const FieldBox = styled(Box)(({theme}) => ({
    marginBottom: 20
}));

const LogoBox = styled(Box)(({theme}) => ({
    width: 175
}));

const BottomBox = styled(Box)(({theme}) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 20
}));

const LinkBox = styled(Box)(({theme}) => ({
    cursor: 'pointer',
    color: theme.palette.primary.main
}));
