import React, {useState, useEffect, useRef} from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { 
    Box, 
    FormControl,
    OutlinedInput,
    Grid,
    Typography
} from '@mui/material';
import * as userVerificationActions from '../../../../actions/userVerificationActions';
import { styled } from '@mui/material/styles';

export default function OtpInput(props) {
    const dispatch = useDispatch();
    const { user, unVerified} = useSelector(state => ({
        // ...state.register,
        // ...state.reset,
        ...state.common,
    }), shallowEqual);
    const inputRef = useRef([]);
    let intervalRef = useRef();

    let seconds = 30;
    const [otpInputs, setOtpInputs] = useState([]);
    const [resendTimer, setResendTimer] = useState(seconds);

    useEffect(() => {
        createInput();
        intervalRef.current = setInterval(() => {
            setResendTimer(resendTimer => resendTimer - 1)
         }, 1000);
         return () => clearInterval(intervalRef.current);
    }, []);

 
    useEffect(() => {
        if (resendTimer === 0) {
            clearInterval(intervalRef.current); 
        }
    }, [resendTimer]);

    function onResend() {
        createInput()
        setResendTimer(seconds)
        intervalRef.current = setInterval(() => {
            setResendTimer(resendTimer => resendTimer - 1)
        }, 1000);
        
        if ((/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(user.email_phone))) {
            dispatch(userVerificationActions.onEmailVerification(user))
        } else if (/^(\+?62|0)8(1[123456789]|2[1238]|3[1238]|5[12356789]|7[78]|9[56789]|8[123456789])([\s?|\d]{5,11})$/.test(user.email_phone)) {
            dispatch(userVerificationActions.onMessageVerification(user))
        }
    }

    function onChange(index, event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        const newPinInputs = [...otpInputs];
        newPinInputs[index][name] = value;
        setOtpInputs(newPinInputs);
        onNextFocus(index);
        if (getEmptyPins() === 0) {
            const otp = []
            for (let i = 0; i < otpInputs.length ; i++) {
                otp.push(otpInputs[i].otp);
            }
            user.otp_token = otp.join('');
            dispatch(userVerificationActions.onUserVerification(user));
        }
    }

    function onKeyPress(event) {
        const keyCode = event.keyCode || event.which;
        const keyValue = String.fromCharCode(keyCode);
        if (!/^\d+$/.test(keyValue)) {
           event.preventDefault();
        }
    }

    function onKeyDown(index, event) {
        const target = event.target;
        const name = target.name;
        const keyCode = event.keyCode || event.which;
        const value = inputRef.current[index].value
        if (Number(keyCode) == 8 || Number(keyCode) == 46) {
            if (value === '') {
                setOtpInputs(otpInputs.map( (c,i) => i === index - 1 ? {...c, [name]: ''} : c));
                onPrevFocus(index)
            }
        } 
    }

    function onKeyUp(index) {
        const value = inputRef.current[index].value;
        if (value && Number(otpInputs[index].otp) === Number(value) && index !== otpInputs.length - 1) {
            inputRef.current[index + 1].focus();
        }
    }

    function onFocus(index) {
        inputRef.current[index].select();
    }

    function onNextFocus(index) {
        const value = inputRef.current[index].value
        if (index !== otpInputs.length - 1 && Number(otpInputs[index].otp) !== Number(value)) {
            inputRef.current[index + 1].focus();
        }
    }

    function onPrevFocus(index) {
        if (index !== 0) { 
            inputRef.current[index - 1].focus();
        }
    }


    function createInput() {
        const inputs = []
        for (let i = 0; i < props.digit ; i++) {
            inputs.push(
                {
                    ['otp']: ''
                }
            )
        }
        setOtpInputs(inputs)
    }

    function getEmptyPins() {
		return otpInputs.filter(el => {
	    	return el.otp === '';
	  	}).length;
	}

    function resendContent() {
        if (resendTimer === 0) {
            return (
                <ResendBox>
                    <Typography fontSize={12} sx={{color: "#707070"}} variant="body1">
                        Tidak menerima OTP Anda?
                    </Typography>
                    <Typography onClick={onResend} sx={{marginLeft: '3px', cursor: 'pointer'}} fontWeight={700} fontSize={12} color="primary" variant="body1">
                        Kirim Ulang
                    </Typography>
                </ResendBox>
            )
        }

        return (
            <ResendBox>
                <Typography fontSize={12} sx={{color: "#707070"}} variant="body1">
                    Mohon tunggu dalam <b>{resendTimer} detik</b> untuk kirim ulang.
                </Typography>
            </ResendBox>
        )
    }

    return (
        <Box>
            <Box>
                <Grid container columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
                    {
                        otpInputs.map( (p, i) => {
                            return (
                                <Grid key={i} item xs={2} md={2}>
                                    <FormControl variant="outlined">
                                        <PinTextField
                                            name="otp"
                                            value={p.otp}
                                            error={unVerified}
                                            onFocus={onFocus.bind(this, i)}
                                            onChange={onChange.bind(this, i)}
                                            onKeyDown={onKeyDown.bind(this, i)}
                                            onKeyUp={onKeyUp.bind(this, i)}
                                            onKeyPress={onKeyPress.bind(this)}
                                            inputRef={el => inputRef.current[i] = el}
                                            inputProps={{ maxLength: 1 }} />
                                    </FormControl>
                                </Grid>
                            )
                        })
                    }
                </Grid>
            </Box>
            {resendContent()}
        </Box>
    )
}

const PinTextField = styled(OutlinedInput)(({theme}) => ({
    textAlign: "center",
    "& input": {
        textAlign: "center",
        fontSize: "1.45rem",
        padding: '12px 10px',
        [theme.breakpoints.between('xs','sm')]: {
            fontSize: "1.1rem",
        },
    },
    // borderColor: theme.palette.primary.main
}));

const ResendBox = styled(Box)(({theme}) => ({
    display: 'flex',
    justifyContent: 'center',
    marginTop: '15%',
    flexDirection: 'column',
    textAlign: 'center'
}));
