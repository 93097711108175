import React from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import * as employeeActions from '../../../../../../../actions/employeeActions';
import { 
    Typography,
    Grid,
    Button,
    Box,
    CircularProgress
} from '@mui/material';
import { useParams } from "react-router-dom";
import Edit from './Edit';
import Loader from '../../../../../../Helper/Loader';
import moment from 'moment';
import 'moment/locale/id' 

export default function Employment() {
    let { id } = useParams();
    const dispatch = useDispatch();
    const { employee, editEmployment, onEditing, inProgress } = useSelector(state => ({
        ...state.employee
    }), shallowEqual);

    const { store, increment_id, employee_organization, employee_job_position, approval, employee_status, join_date, store_id} = employee;

    function onEdit() {
        dispatch(employeeActions.onEditEmployment(id))
    };

    function onSendPin() {
        dispatch(employeeActions.onSendPin(id))
    };

    if (onEditing) {
        return (
            <Box sx={{ minHeight: '415px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Loader />
            </Box>
        )
    }

    if (editEmployment) {
        return <Edit />
    }
    return (
        <Grid container spacing={4} direction="column">
            <Grid item container>
                <Grid item xs>
                    <Typography fontWeight={700} component="h6" lineheight="normal">
                        Informasi Karyawan
                    </Typography>
                </Grid>
                <Grid item>
                    <Button onClick={onEdit}>
                        Ubah
                    </Button>
                </Grid>
            </Grid>
            <Grid item container direction="column" spacing={3}>
                <Grid item container spacing={8}>
                    <Grid item xs={4}>
                        <Typography fontSize={16} fontWeight={700} component="div">
                            ID Cabang
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography fontSize={16} fontWeight={700} component="div">
                            {store_id}
                        </Typography>
                    </Grid> 
                </Grid>
                <Grid item container spacing={8}>
                    <Grid item xs={4}>
                        <Typography fontSize={16} fontWeight={700} component="div">
                            Cabang
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography fontSize={16} fontWeight={700} component="div">
                            {store}
                        </Typography>
                    </Grid> 
                </Grid>
                <Grid item container spacing={8}>
                    <Grid item xs={4}>
                        <Typography fontSize={16} fontWeight={700} component="div">
                           ID Karyawan
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography fontSize={16} fontWeight={700} component="div">
                            {increment_id}
                        </Typography>
                    </Grid> 
                </Grid>
                <Grid item container spacing={8}>
                    <Grid item xs={4}>
                        <Typography fontSize={16} fontWeight={700} component="div">
                            Organisasi
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography fontSize={16} fontWeight={700} component="div">
                            {employee_organization}
                        </Typography>
                    </Grid> 
                </Grid>
                <Grid item container spacing={8}>
                    <Grid item xs={4}>
                        <Typography fontSize={16} fontWeight={700} component="div">
                            Jabatan
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography fontSize={16} fontWeight={700} component="div">
                            {employee_job_position}
                        </Typography>
                    </Grid> 
                </Grid>
                <Grid item container spacing={8}>
                    <Grid item xs={4}>
                        <Typography fontSize={16} fontWeight={700} component="div">
                           Tgl. Bergabung
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography fontSize={16} fontWeight={700} component="div">
                            {moment(join_date).format('LL')}
                        </Typography>
                    </Grid> 
                </Grid>
                <Grid item container spacing={8}>
                    <Grid item xs={4}>
                        <Typography fontSize={16} fontWeight={700} component="div">
                           Atasan
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography fontSize={16} fontWeight={700} component="div">
                            {approval}
                        </Typography>
                    </Grid> 
                </Grid>
                <Grid item container spacing={8}>
                    <Grid item xs={4}>
                        <Typography fontSize={16} fontWeight={700} component="div">
                            Status
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography fontSize={16} fontWeight={700} component="div">
                            {employee_status}
                        </Typography>
                    </Grid> 
                </Grid>
                <Grid item container spacing={8}>
                    <Grid item xs={4}>
                        <Typography fontSize={16} fontWeight={700} component="div">
                            Reset PIN
                        </Typography>
                        <Typography variant="body2" gutterBottom color="textSecondary">
                            Reset pin karyawan yang digunakan untuk mengakses applikasi dan back office.
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Button 
                            variant="contained"
                            color="primary"
                            sx={{maxWidth: 225, minWidth: 145}}
                            onClick={onSendPin}
                            disabled={inProgress}
                            disableElevation>
                            {inProgress ? <CircularProgress size={26} /> : 'Reset PIN'}
                        </Button>
                    </Grid> 
                </Grid>
            </Grid>
        </Grid>
    )
}