import React, { useEffect } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { 
    Typography,
    Box,
    Grid,
} from '@mui/material';
import Chart from 'chart.js/auto';
import NumberFormat from 'react-number-format';

export default function AverageOrder() {
    const { customer } = useSelector(state => ({
        ...state.customer
    }), shallowEqual);
    const { customer_chart } = customer;
    const { data, orders_on_due, orders_completed, orders_canceled, orders_refunded, orders_averaged } = customer_chart;
    useEffect(() => {
        new Chart(document.getElementById("customer-chart"), {
            type: 'line',
            data: {
              labels: data.map(d => d.label),
              datasets: [{ 
                    data: data.map(d => d.monthly_orders_on_due),
                    label: "Total Utang",
                    borderColor: "#4B00D1",
                    backgroundColor: "#4B00D1",
                    fill: false
                },{ 
                    data: data.map(d => d.monthly_orders_completed),
                    label: "Total Transaksi",
                    borderColor: "#279F51",
                    backgroundColor: "#279F51",
                    fill: false
                }, { 
                    data: data.map(d => d.monthly_orders_canceled),
                    label: "Total Transaksi Batal",
                    borderColor: "#D6001C",
                    backgroundColor: "#D6001C",
                    fill: false
                }, { 
                    data: data.map(d => d.monthly_orders_refunded),
                    label: "Total Pengembalian",
                    borderColor: "#FF8432",
                    backgroundColor: "#FF8432",
                    fill: false
                }, { 
                    data: data.map(d => d.monthly_orders_averaged),
                    label: "Rata - Rata Transaksi",
                    borderColor: "#00B2C6",
                    backgroundColor: "#00B2C6",
                    fill: false
                }
              ]
            },
            options: {
                interaction: {
                    mode: 'index'
                },
                scales: {
                    y: {
                        ticks: {
                            // Include a dollar sign in the ticks
                            callback: function(value, index, ticks) {
                                return "Rp " + Number(value).toFixed().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.");
                            }
                        }
                    }
                },
                plugins: {
                    legend: {
                        display: false,
                        labels: {
                            color: 'rgb(255, 99, 132)'
                        }
                    },
                    tooltip: {
                        enabled: true,
                        intersect: false,
                        mode: 'index',
                        position: 'nearest',
                        callbacks: {
                            title: function(context) {
                                return null
                            },
                            label: function(context) {
                                let label = context.dataset.label || '';
                                if (label) {
                                    label += ': ';
                                }
                                if (context.parsed !== null) {
                                    label += new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(context.parsed.y);
                                }
                                return label;
                            }
                        }
                    },
                    elements: {
                        point:{
                            radius: 2
                        }
                    },
                }
            }
        });
    },[]);

    return (
        <Grid container direction="column" spacing={3}>
            <Grid item xs container>
                <Grid item xs container direction="column" spacing={1}>
                    <Grid item xs>
                        <Typography variant="h6" color="primary" fontSize={16} fontWeight={700}>
                            <NumberFormat
                                value={orders_on_due}
                                displayType="text"
                                thousandSeparator={"."}
                                decimalSeparator={","}
                                prefix="Rp "
                                isNumericString
                                decimalScale={2}
                            />
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography component="div" variant="body2" color="textSecondary">
                            <Box fontSize={14} fontWeight={500} display="flex" alignItems="center">
                                Total Utang <span style={{display: "block", borderRadius: 15, width: 15, height: 5, marginLeft: 10, backgroundColor: "#4B00D1"}} />
                            </Box>
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item xs container direction="column" spacing={1}>
                    <Grid item xs>
                        <Typography variant="h6" fontSize={16} fontWeight={700}>
                            <NumberFormat
                                value={orders_completed}
                                displayType="text"
                                thousandSeparator={"."}
                                decimalSeparator={","}
                                prefix="Rp "
                                isNumericString
                                decimalScale={2}
                            />
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography component="div" variant="body2" color="textSecondary">
                            <Box fontSize={14} fontWeight={500} display="flex" alignItems="center">
                            Total Transaksi <span style={{display: "block", borderRadius: 15, width: 15, height: 5, marginLeft: 10, backgroundColor: "#279F51"}} />
                            </Box>
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item xs container direction="column" spacing={1}>
                    <Grid item xs>
                        <Typography variant="h6" fontSize={16} fontWeight={700}>
                            <NumberFormat
                                value={orders_canceled}
                                displayType="text"
                                thousandSeparator={"."}
                                decimalSeparator={","}
                                prefix="Rp "
                                isNumericString
                                decimalScale={2}
                            />
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography component="div" variant="body2" color="textSecondary">
                            <Box fontSize={14} fontWeight={500} display="flex" alignItems="center">
                                Total Transaksi Batal <span style={{display: "block", borderRadius: 15, width: 15, height: 5, marginLeft: 10, backgroundColor: "#D6001C"}} />
                            </Box>
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item xs container direction="column" spacing={1}>
                    <Grid item xs>
                        <Typography variant="h6" fontSize={16} fontWeight={700}>
                            <NumberFormat
                                value={orders_refunded}
                                displayType="text"
                                thousandSeparator={"."}
                                decimalSeparator={","}
                                prefix="Rp "
                                isNumericString
                                decimalScale={2}
                            />
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography component="div" variant="body2" color="textSecondary">
                            <Box fontSize={14} fontWeight={500} display="flex" alignItems="center">
                                Total Pengembalian <span style={{display: "block", borderRadius: 15, width: 15, height: 5, marginLeft: 10, backgroundColor: "#FF8432"}} />
                            </Box>
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item xs container direction="column" spacing={1}>
                    <Grid item xs>
                        <Typography variant="h6" fontSize={16} fontWeight={700}>
                            <NumberFormat
                                value={orders_averaged}
                                displayType="text"
                                thousandSeparator={"."}
                                decimalSeparator={","}
                                prefix="Rp "
                                isNumericString
                                decimalScale={2}
                            />
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography component="div" variant="body2" color="textSecondary">
                            <Box fontSize={14} fontWeight={500} display="flex" alignItems="center">
                                Rata - Rata Transaksi <span style={{display: "block", borderRadius: 15, width: 15, height: 5, marginLeft: 10, backgroundColor: "#00B2C6"}} />
                            </Box>
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs container>
                <canvas id="customer-chart" width="100%"></canvas>
            </Grid>
        </Grid>
    )
}