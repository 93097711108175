import React, { useEffect} from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { onUpdateCategory, onCategoryNew, onCategoryEdit, onUnloadForm, onEdit, onCreate, onUpload, onUploading, onRemove, onRemoveRequest, onUploadRequest } from '../../../../../actions/categoryActions';
import { 
        CircularProgress,
        Button,
        Typography,
        Paper,
        Box,
        Grid,
        TextField,
        FormGroup,
        FormControlLabel,
        Switch,
        IconButton,
        Alert,
        LinearProgress,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate, useParams } from "react-router-dom";
import { ImageRounded, DeleteForeverRounded } from '@mui/icons-material';
import Loader from '../../../../Helper/Loader';

export default function Form() {
    let navigate = useNavigate();
    let { id, parent_id } = useParams();
    const dispatch = useDispatch();
    const {category, errors, inProgress} = useSelector(state => ({
        ...state.category
    }), shallowEqual); 



    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
    },[errors]);

    useEffect(() => {
        if (id) {
			dispatch(onCategoryEdit(id));
		} else {
			dispatch(onCategoryNew(parent_id));
		}
        return () => {
            dispatch(onUnloadForm())
        };
    },[dispatch, id, parent_id]);

    useEffect(() => {
       if (category && category.category_images) {
            category.category_images.map((image, index) => {
                if (image.onUpload && !image.uploading) {
                    dispatch(onUpload(image, index, (event) => {
                        image.progress = Math.round((event.loaded * 100) / event.total);
                        image.uploading = true;
                        dispatch(onUploading(image, index))
                    }));
                }
            })

            category.category_images.map((image, index) => {
                if (image.onRemove && !image._destroy) {
                    dispatch(onRemove(image, index));
                }                
            })
       }
    },[dispatch, category]);

    function onUpdateField(event) {
		const key = event.target.name;
        const value = event.target.value;
        const newCategory = {...category};
        newCategory[key] = value;
        dispatch(onUpdateCategory(newCategory));
    }

    function onToggleStatus(value, event) {
        const key = event.target.name;
        const newCategory = {...category};
        newCategory[key] = value;
        dispatch(onUpdateCategory(newCategory));
    }


    function onSelectImage(event) {
        event.preventDefault();
        const files = event.target.files;
        
        let file = files[0]
        const reader = new FileReader();
        reader.onloadend = () => {
            dispatch(onUploadRequest(reader.result));
        }
        reader.readAsDataURL(file);
    }

    function onRemoveImage(image, index, event) {
        event.preventDefault();
        dispatch(onRemoveRequest(image, index));
    }

    function onSubmit() {
		if (id) {
            dispatch(onEdit(parent_id, id, category));
		} else {
            dispatch(onCreate(category));
		}
    }

    function getDestroyedImages() {
		return category.category_images.filter(el => {
	    	return el._destroy === true;
	  	}).length;
	}

    if (!category) {
        return (
            <Loader />
        )
    }


    const { name, status, category_images } = category;

    // let imgClass = 'imageEmpty';
    // let error_class = 'hasError';
    // if (!!error('category_media_galleries.image')) {
    //     imgClass = imgClass.concat(' ', error_class)
    // }
    function error(key) {
        if (errors != null && errors[`category.${key}`] != null) {
            return errors[`category.${key}`].msg
        }
        return null;
    }

  
    return (
        <ContentBox>
            <ContentPaper elevation={3}>
                <Grid container spacing={6} direction="column">
                    <Grid item container>
                        <Grid item xs>
                            <Typography variant="h6" fontWeight={700}>
                                Informasi Kategori
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Alert severity="error">
                                Wajib
                            </Alert>
                        </Grid>
                    </Grid>
                    <Grid item container direction="column" spacing={5}>
                        <Grid item container spacing={8}>
                            <Grid item xs={4} container>
                                <Grid item xs>
                                    <Typography fontSize={16} fontWeight={700} component="div">
                                        Gambar Kategori
                                    </Typography>
                                    <Typography variant="body2" gutterBottom color="textSecondary">
                                        Masukan gambar untuk kategori. *dibutuhkan untuk kategori utama.
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs={8}>
                                { 
                                    category_images.map((category_image, index) => {
                                        if (category_image._destroy) {
                                            return null;
                                        }
                                        if (category_image.image && category_image.onUpload && category_image.progress !== 100) {
                                            return (
                                                <ImageAttachedPaper key={index} >
                                                    <ImagePreviewBox>
                                                        <Box component="img" alt={category_image.image} src={category_image.image} />
                                                        <Box sx={{ width: '80%', position: 'absolute', bottom: '15px' }}>
                                                            <LinearProgress sx={{height: '8px', borderRadius: '35px'}} variant="determinate" value={category_image.progress || 0} />
                                                        </Box>
                                                    </ImagePreviewBox>
                                                </ImageAttachedPaper>
                                            )
                                        }
                                        if (category_image.image && category_image.onRemove) {
                                            return (
                                                <ImageAttachedPaper key={index} >
                                                    <ImagePreviewBox>
                                                        <Box component="img" alt={category_image.image} src={category_image.image} />
                                                        <Box sx={{
                                                            position: 'absolute', 
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            width: '100%',
                                                            height: '100%'}}>
                                                            <Box sx={{
                                                                position: 'absolute',
                                                                background: '#fff',
                                                                opacity: 0.45,
                                                                width: '100%',
                                                                height: '100%'
                                                             }} />
                                                            <Box sx={{ display: 'flex' }}>
                                                                <CircularProgress />
                                                            </Box>
                                                        </Box>
                                                    </ImagePreviewBox>
                                                </ImageAttachedPaper>
                                            )
                                        }
                                        if (category_image.image) {
                                            return (
                                                <ImageAttachedPaper key={index} >
                                                    <ImagePreviewBox>
                                                        <DeleteIconButton size="small" 
                                                            color="inherit" aria-label="menu" onClick={onRemoveImage.bind(this, category_image, index)} >
                                                            <DeleteForeverRounded style={{ color: '#999' }} />
                                                        </DeleteIconButton>
                                                        <Box component="img" alt={category_image.image} src={category_image.image} />
                                                    </ImagePreviewBox>
                                                </ImageAttachedPaper>
                                            )
                                        }
                                        return (
                                            <ImagePaper key={index}>
                                                <ImageEmptyBox>
                                                    <ImageRounded
                                                        style={{ color: '#999', fontSize: 50 }} />
                                                        <input type="file" multiple={true} accept="image/*" onChange={onSelectImage.bind(this)}/>
                                                </ImageEmptyBox>
                                            </ImagePaper>
                                        )
                                    })
                                }
                            </Grid> 
                        </Grid>
                        <Grid item container spacing={8}>
                            <Grid item xs={4} container>
                                <Grid item xs>
                                    <Typography fontSize={16} fontWeight={700} component="div">
                                        Nama Kategori
                                    </Typography>
                                    <Typography variant="body2" gutterBottom color="textSecondary">
                                        Masukan gambar untuk kategori. *dibutuhkan untuk kategori utama.
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs={8}>
                                <TextField
                                    fullWidth
                                    error={!!error('name')}
                                    name="name"
                                    placeholder="Nama Kategori"
                                    value={name}
                                    helperText={error('name')}
                                    variant="outlined"
                                    onChange={onUpdateField}
                                />
                            </Grid> 
                        </Grid>
                        <Grid item container spacing={8}>
                            <Grid item xs={4} container>
                                <Grid item xs>
                                    <Typography fontSize={16} fontWeight={700} component="div">
                                        Status Kategori
                                    </Typography>
                                    <Typography variant="body2" gutterBottom color="textSecondary">
                                        Aktifkan kategori produk agar produk dapat ditemukan.
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs={8}>
                                <FormGroup row>
                                    <FormControlLabel
                                        control={
                                        <Switch
                                            checked={status}
                                            onChange={onToggleStatus.bind(this, !category.status)}
                                            name="status"
                                            color="primary"
                                        />
                                        }
                                        label={status ? 'Aktif' : 'Tidak Aktif'}
                                    />
                                </FormGroup>
                            </Grid> 
                        </Grid>
                    </Grid>
                </Grid>
            </ContentPaper>
            <Box>
                <Grid container sx={{marginBottom: '55px'}} justifyContent="space-between" spacing={3}>
                    <Grid item>
                    </Grid>
                    <Grid item>
                        <Grid container spacing={3}>
                            <Grid item>
                                <BackButton 
                                    variant="contained"
                                    size="large"
                                    disableElevation
                                    disabled={inProgress}
                                    onClick={ () => {navigate(`/dashboard/categories`)}}>
                                    Batal
                                </BackButton>
                            </Grid>
                            <Grid item>
                                <Button 
                                    variant="contained"
                                    size="large"
                                    color="primary"
                                    sx={{minWidth: 195}}
                                    onClick={onSubmit}
                                    disabled={inProgress}
                                    disableElevation>
                                    {inProgress ? <CircularProgress size={26} /> : 'Simpan'}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>  
            </Box>
        </ContentBox>
    )
}

const BackButton = styled(Button)(({theme}) => ({
    color: theme.palette.text.primary,
    backgroundColor: "#FFFFFF",
    '&:hover': {
    backgroundColor: "#FFFFFF",
    },
    fontWeight: 500,
    border: '1px solid #ddd',
    minWidth: 195
}));

const ContentBox = styled(Box)(({theme}) => ({
    paddingBottom: 15
}));


const ContentPaper = styled(Paper)(({theme}) => ({
    padding: 35,
    paddingTop: 32,
    marginBottom: 25
}));

const ImagePaper = styled(Paper)(({theme}) => ({
    padding: 10,
    width: 127,
    height: 127,
    position: 'relative'
}));

const ImageEmptyBox = styled(Box)(({theme}) => ({
    border: '1px dashed #999',
    height: 125,
    width: 125,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 3,
    position: 'relative',
    "& input[type='file']": {
        bottom: 0,
        cursor: 'pointer',
        fontSize: '10em',
        height: '100%',
        left: 0,
        opacity: 0,
        position: 'absolute',
        right: 0,
        top: 0,
        width: '100%',
        zIndex: 3
    },

}));

const ImageAttachedPaper = styled(Paper)(({theme}) => ({
    width: 141,
    height: 141,
    padding: 3,
    position: 'relative',
    '&:hover': {
        "& button": {
            visibility: 'visible'
        }
    }
}));

const DeleteIconButton = styled(IconButton)(({theme}) => ({
    background: '#ddd',
    position: 'absolute',
    right: 10,
    top: 10,
    visibility: 'hidden'
}));

const ImagePreviewBox = styled(Box)(({theme}) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    position: 'relative',
    '& img': {
        width: '100%'
    }
}));


// const useStyles = makeStyles((theme) => ({
//    
//     paperHasImg: {
//         width: 141,
//         height: 141,
//         padding: 3,
//         position: 'relative',
//         '&:hover': {
//             "& button": {
//                 visibility: 'visible'
//             }
//         }
//     },
//     delImage: {
//         background: '#ddd',
//         position: 'absolute',
//         right: 10,
//         top: 10,
//         visibility: 'hidden'
//     },
//     field: {
//         marginTop: 10
//     },
//     paperImg: {
//         padding: 10,
//         width: 127,
//         height: 127,
//         position: 'relative'
//     },
// }));