import React, { useState, useEffect, useRef } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import * as storeActions from '../../../../../../../actions/storeActions';
import { TableContainer,
         Table,
         TableHead,
         TableRow,
         TableCell,
         TableBody,
         Typography,
         TableSortLabel,
         IconButton,
         AppBar,
         Toolbar,
         Box,
         Button,
         Card,
         CardMedia,
         CardContent
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { visuallyHidden } from '@mui/utils';
import { useParams } from "react-router-dom";
import { ReceiptTwoTone, Close } from '@mui/icons-material';
import Loader from '../../../../../../Helper/Loader';
import NumberFormat from 'react-number-format';

const headCells = [
    { id: 'name', numeric: false, sortable: true, disablePadding: false, label: 'Name' },
    { id: 'quantity', numeric: true, sortable: true, disablePadding: false, label: 'In Stock' },
    { id: 'average_price', numeric: true, sortable: false, disablePadding: false, label: 'Average Price' },
    { id: 'unit_price', numeric: true, sortable: false, disablePadding: false, label: 'Unit Price' },
    { id: 'low_stock_notify', numeric: true, sortable: false, disablePadding: false, label: 'Low Stock Notify' },
    { id: 'status', numeric: true, sortable: false, disablePadding: false, label: 'Status Title' },
];
 
  
function EnhancedTableHead({ order, orderBy, onRequestSort, onSelectAll, numSelected, rowCount }) {
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => {
                    if (headCell.sortable) {
                        return (
                            <ItemTableCell
                                key={headCell.id}
                                sx={{fontWeight: 700}}
                                align={headCell.numeric ? 'right' : 'left'}
                                padding={headCell.disablePadding ? 'none' : 'normal'}
                                sortDirection={orderBy === headCell.id ? order : false}
                            >
                                <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                                >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                ) : null}
                                </TableSortLabel>
                            </ItemTableCell>
                        )
                    }
                    return (
                        <ItemTableCell
                        key={headCell.id}
                        sx={{fontWeight: 700}}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        >
                            {headCell.label}
                        </ItemTableCell>
                    )
                })}
            </TableRow>
        </TableHead>
    );
}

export default function Content() {
    const dispatch = useDispatch();
    let { id } = useParams();
    const { inventories } = useSelector(state => ({
        ...state.outlet
    }), shallowEqual);

    const [open, setOpen] = useState(false);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('name');
    const [query, setQuery] = useState({ page: 0, limit: 5, search: '' });

    useEffect(() => {
        dispatch(storeActions.onLoadInventories(id))
    },[dispatch]);

    const onRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
        if (isAsc) {
          const newQuery = {...query};
          delete newQuery.sort_asc;
          newQuery.sort_desc = property;
          setQuery(newQuery)
        } else {
          const newQuery = {...query};
          delete newQuery.sort_desc;
          newQuery.sort_asc = property;
          setQuery(newQuery)
        }
    };

    function onHide() {
        dispatch(storeActions.onHideInventories());
    }

    if (!inventories) {
        return <Loader />
    }

    return(
        <Box sx={{height: '100%'}}>
             <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton
                    edge="start"
                    color="inherit"
                    onClick={onHide} 
                    aria-label="close"
                    >
                    <Close />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        Store Inventories
                    </Typography>
                </Toolbar>
            </AppBar>
            <TableContainer>
                <Table sx={{width: '100%'}}>
                    <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={onRequestSort}
                    />
                    <TableBody>
                    {
                        inventories.map((item, index) => {
                            const { id, name, sku, image, in_stock, average_price, unit_price, low_stock_notify, status_title } = item;
                            
                            return (
                                <TableRow
                                    hover
                                    tabIndex={-1}
                                    key={id} >
                                    <ItemTableCell component="th" scope="row">
                                        <Card variant="outlined" sx={{ display: 'flex', alignItems: 'center', background: "none", border: 0 }}>
                                            <CardMedia
                                                component="img"
                                                sx={{ width: '45px', height: '45px' }}
                                                image={image}
                                                alt={name}
                                            />
                                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                                <CardContent sx={{ flex: '1 0 auto', padding: '10px !important' }}>
                                                    <Typography fontWeight={500} variant="subtitle1">
                                                        {name}
                                                    </Typography>
                                                    <Typography fontSize={9} variant="body2" color="textSecondary" component='div' gutterBottom>
                                                        SKU: {sku}
                                                    </Typography>
                                                </CardContent>
                                            </Box>
                                            
                                        </Card>
                                    </ItemTableCell>
                                    <ItemTableCell align="right">
                                        <NumberFormat
                                            value={in_stock}
                                            displayType="text"
                                            thousandSeparator={"."}
                                            decimalSeparator={","}
                                            isNumericString
                                            decimalScale={2}
                                        />
                                    </ItemTableCell>
                                    <ItemTableCell align="right">
                                        <NumberFormat
                                            value={average_price}
                                            displayType="text"
                                            thousandSeparator={"."}
                                            decimalSeparator={","}
                                            prefix="Rp "
                                            isNumericString
                                            decimalScale={2}
                                        />
                                    </ItemTableCell>
                                    <ItemTableCell align="right">
                                        <NumberFormat
                                            value={unit_price}
                                            displayType="text"
                                            thousandSeparator={"."}
                                            decimalSeparator={","}
                                            prefix="Rp "
                                            isNumericString
                                            decimalScale={2}
                                        />
                                    </ItemTableCell>
                                    <ItemTableCell align="right">
                                        <NumberFormat
                                            value={low_stock_notify}
                                            displayType="text"
                                            thousandSeparator={"."}
                                            decimalSeparator={","}
                                            isNumericString
                                            decimalScale={2}
                                        />
                                    </ItemTableCell>
                                    <ItemTableCell align="right">
                                        {status_title}
                                    </ItemTableCell>
                                </TableRow>
                            );
                        })
                    }
                    </TableBody>
                </Table>
            </TableContainer>
        
        </Box>
    )  
}


const ItemTableCell = styled(TableCell)(({theme}) => ({
    padding: '15px 35px'
}));

const WhiteButton = styled(Button)(({theme}) => ({
    color: theme.palette.text.primary,
    backgroundColor: "#FFFFFF",
    '&:hover': {
    backgroundColor: "#FFFFFF",
    },
    fontWeight: 500,
    border: '1px solid #ddd',
    minWidth: 195
}));