import React, { useState, useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import * as storeTransferActions from '../../../../../../../../actions/storeTransferActions';
import { 
    DialogContent,
    TextField,
    Grid,
    Button
} from '@mui/material';
import { styled } from '@mui/material/styles';

export default function Content({label, options}) {
    const dispatch = useDispatch();
    const {variantProduct, variantPage} = useSelector(state => ({
        ...state.store_transfer
    }), shallowEqual);
    const { product_configurations } = variantProduct;
    const [initialVariantOptions, setInitialVariantOptions] = useState([]);
    const [variantOptions, setVariantOptions] = useState([]);

    useEffect(() => {
        setVariantOptions(options)
        setInitialVariantOptions(options)
    },[options]);
    
    function onSearch(event) {
        let filteredOptions = initialVariantOptions;
        filteredOptions = filteredOptions.filter((option) => {
            let optionTitle = option.label.toLowerCase()
            return optionTitle.indexOf(
            event.target.value.toLowerCase()) !== -1
        })
        setVariantOptions(filteredOptions);
    }

    function onSelectVariant(value) {
        if (variantPage === product_configurations.length) {
            dispatch(storeTransferActions.onHandleProductVariant(variantProduct, label, value));
        } else {
            dispatch(storeTransferActions.onSelectVariant(variantProduct, label, value));
        }
    }


    return (
        <ContentWrapper>
            <Grid container direction="column" spacing={3}>
                <Grid item xs>
                    <TextField
                        fullWidth
                        name="search"
                        placeholder={"Pencarian"}
                        variant="outlined"
                        onChange={onSearch}
                    />
                </Grid>
                <Grid item container spacing={3}>
                    {
                        variantOptions.sort((a, b) => a.sort_order - b.sort_order).map((option, index) => {
                            return (
                                <Grid key={index} item xs={4}>
                                    <VariantOption
                                        size="large"
                                        disableElevation
                                        variant="contained"
                                        disabled={!option.status}
                                        onClick={onSelectVariant.bind(this, option.value)}
                                        color="secondary">
                                        {option.value}
                                    </VariantOption>
                                </Grid>
                            )
                        })
                    }
                </Grid>
            </Grid>
        </ContentWrapper>
    );
}

const ContentWrapper = styled(DialogContent)(({theme}) => ({
    padding: '10px 45px 45px !important',
    minHeight: 400,
}));

const VariantOption = styled(Button)(({theme}) => ({
    color: theme.palette.text.primary,
    backgroundColor: "#FFFFFF",
    '&:hover': {
      backgroundColor: "#FFFFFF",
    },
    height: '100%',
    fontWeight: 500,
    width: '100%',
    border: `1px solid #ddd`
}));