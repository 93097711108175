export const product_price = (data, errors) => {
    for (let i = 0; i < data.length; i++) {
        let price = data[i]
		if (price.amount === '') {
            errors[`product_prices[${i}].amount`] = ['Dibutuhkan']
        }
        if (price.quantity === '') {
            errors[`product_prices[${i}].quantity`] = ['Min. 1']
        }
	}
}