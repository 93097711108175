import React, { useEffect, useState } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import * as orderDeliveryActions from '../../../../../../actions/orderDeliveryActions';
import { 
        Typography,
        Box,
        Grid,
        Paper,
        Divider,
        List,
        ListSubheader,
        TableRow,
        TableCell,
        TableHead,
        TableSortLabel,
        InputAdornment,
        ListItem,
        Tooltip,
        TextField,
        Button,
        InputBase
} from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import { useNavigate, useParams } from "react-router-dom";
import Loader from '../../../../../Helper/Loader';
import { StickyNote2TwoTone, PublishedWithChangesTwoTone, CurrencyExchangeTwoTone, PaymentsTwoTone, AssignmentIndTwoTone, ReceiptTwoTone, MoveDownTwoTone, HelpTwoTone, AccessTimeTwoTone, LocalShippingTwoTone } from '@mui/icons-material';
import { green, red, grey } from '@mui/material/colors';
import { visuallyHidden } from '@mui/utils';
import { tooltipClasses } from '@mui/material/Tooltip';
import { keyframes } from '@emotion/react'
import NumberFormat from 'react-number-format';
import moment from 'moment';
import 'moment/locale/id';
import Confirm from './Confirm';

const QuantityFormat = React.forwardRef((props, ref) => (
    <NumberFormat
        {...props.other}
        onValueChange={(values) => {
          props.onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        disabled={props.disabled}
        customInput={TextField}
        placeholder={props.placeholder}
        value={props.value}
        helperText={props.helperText}
        error={props.error}
        onFocus={props.onFocus}
        decimalScale={2}
        fullWidth={props.fullWidth}
        onBlur={props.onBlur}
        thousandSeparator={"."}
        decimalSeparator={","}
        allowNegative={false}
        allowedDecimalSeparators={false}
        isNumericString
        InputProps={props.InputProps}
      />
));

export default function Show() {    
    let navigate = useNavigate();
    let { id } = useParams();
    const dispatch = useDispatch();
    const {order_delivery, errors} = useSelector(state => ({
        ...state.order_delivery
    }), shallowEqual); 

    const [currentFocus, setCurrentFocus] = useState("");

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
    },[errors]);

    useEffect(() => {
        dispatch(orderDeliveryActions.onLoadOrderDelivery(id));
        return () => {
            dispatch(orderDeliveryActions.onUnloadOrderDelivery())
        };
    },[]);

    function onUpdateField(index, event) {
        if (currentFocus === "delivered") {
            const key = event.target.name;
            const value = event.target.value;
            const newOrderDelivery = {...order_delivery};
            const currentItem = newOrderDelivery.order_delivery_items[index];
            currentItem[key] = value;
            dispatch(orderDeliveryActions.onUpdateOrderDelivery(newOrderDelivery));
        }
    }

    function onUpdatePlateField(event) {
        if (currentFocus === "plate") {
            const key = event.target.name;
            const value = event.target.value;
            const newOrderDelivery = {...order_delivery};
            newOrderDelivery[key] = value;
            dispatch(orderDeliveryActions.onUpdateOrderDelivery(newOrderDelivery));
        }
    }

    function onUpdateNoteField(event) {
		// const key = event.target.name;
        // const value = event.target.value;
        // const newPurchaseOrder = {...purchase_order};
        // newPurchaseOrder[key] = value;
        // dispatch(purchaseOrderActions.onUpdatePurchaseOrder(newPurchaseOrder));
    }


    function onFocus(key) {
        setCurrentFocus(key)
    }

    function onBlur(key) {
        setCurrentFocus(null)
    }

    function onSubmit() {
        const { status } = order_delivery;
        if (status === 'on_delivery') {
            dispatch(orderDeliveryActions.onShowConfirm("delivered"))
        }
        // if (status === 'ordered') {
        //     dispatch(purchaseOrderActions.onShowConfirm("received"))
        // }
        // if (status === 'received') {
        //     dispatch(purchaseOrderActions.onShowConfirm("approved"))
        // }
        // if (status === 'approved') {
        //     dispatch(purchaseOrderActions.onShowConfirm("updated"))
        // }
    }

    function error(key) {
        if (errors != null && errors[`order_delivery.${key}`] != null) {
            return errors[`order_delivery.${key}`].msg
        }
        return null;
    }

   
    if (!order_delivery) {
        return (
            <Loader />
        )
    }
    const { status, 
        increment_id, 
        store, 
        created_at, 
        status_title,
        note,
        driver,
        license_plate,
        order_delivery_items, 
    } = order_delivery;

    function Requested(item) {
        if (status === 'on_delivery') {
            return (
                <QuantityFormat
                    fullWidth
                    name="quantity"
                    value={item.quantity}
                    disabled
                    variant="outlined"
                    InputProps={{
                        endAdornment: <InputAdornment position="end">{item.uom}</InputAdornment>,
                    }}
                />
            )
        }

        return (
            <NumberFormat
                value={item.quantity}
                displayType="text"
                thousandSeparator={"."}
                decimalSeparator={","}
                isNumericString
                decimalScale={2}
                renderText={(value) => <Typography textAlign="right" fontSize={16} fontWeight={500}>
                {value} {item.uom}
                </Typography>}
            />
        )
    }


    function Delivered(item, index) {
        if (status === 'on_delivery') {
            return (
                <QuantityFormat
                    fullWidth
                    error={!!error(`order_delivery_items[${index}]`)}
                    // helperText={error(`order_delivery_items[${index}]`)}
                    name="delivered_qty"
                    value={item.delivered_qty}
                    onFocus={onFocus.bind(this, "delivered")}
                    onBlur={onBlur.bind(this)}
                    variant="outlined"
                    onChange={onUpdateField.bind(this, index)}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">{item.uom}</InputAdornment>,
                    }}
                />
            )
        }

        return (
            <NumberFormat
                value={item.delivered_qty}
                displayType="text"
                thousandSeparator={"."}
                decimalSeparator={","}
                isNumericString
                decimalScale={2}
                renderText={(value) => <Typography textAlign="right" fontSize={16} fontWeight={500}>
                    {value ? value : 0} {item.uom}
                </Typography>}
            />
        )
    }

    function Driver() {
        if (status === 'on_delivery') {
            return (
                <TextField
                    fullWidth
                    error={!!error('driver')}
                    helperText={error('driver')}
                    name="driver"
                    value={driver}
                    variant="outlined"
                    placeholder="Pengemudi"
                    onChange={onUpdateField}
                />
            )
        }

        return (
            <Box>
                {/* {invoice_id ? invoice_id : "-"} */}
            </Box>
          
        )
    }

    function Plate() {
        if (status === 'on_delivery') {
            return (
                <TextField
                    fullWidth
                    error={!!error('license_plate')}
                    helperText={error('license_plate')}
                    name="license_plate"
                    value={license_plate}
                    variant="outlined"
                    placeholder="Plat No."
                    onFocus={onFocus.bind(this, "plate")}
                    onBlur={onBlur.bind(this)}
                    onChange={onUpdatePlateField}
                />
            )
        }

        return (
            <Box>
                {/* {invoice_id ? invoice_id : "-"} */}
            </Box>
          
        )
    }

    function Cancel() {
        if (status === 'on_delivery') {
            return (
                <Button 
                    variant="contained"
                    size="large"
                    disableElevation
                    onClick={ () => {dispatch(orderDeliveryActions.onShowConfirm("canceled"))}}
                    >
                    Batalkan
                </Button>
            )
        }

        return null;
    }

    let titleAlign = 'right'
    if (status === 'on_delivery') {
        titleAlign = 'left'
    }

    return (
        <ContentBox>
            <HeaderBox>
                <Typography fontWeight={700} variant="h6">
                    Informasi Pengiriman
                </Typography>
            </HeaderBox>
            <Content>
                <ContentPaper elevation={3}>
                    <Box sx={{display: 'flex', padding: '35px', paddingTop: '25px', paddingBottom: '25px'}}>
                        <Typography fontWeight={700} sx={{display: 'flex'}}>
                            <PublishedWithChangesTwoTone style={{ fontSize: 18, marginRight: '5px', marginTop: '3px' }} color="primary" /> {status_title}
                        </Typography>
                        <Slash>
                            /
                        </Slash>
                        <Typography fontWeight={700} color="primary">
                            {increment_id}
                        </Typography>
                        {/* {customer_name && [<Slash key={1}>
                            /
                        </Slash>,
                        <Typography key={2} fontWeight={700} sx={{display: 'flex'}}>
                            <AssignmentIndTwoTone style={{ fontSize: 18, marginRight: '5px', marginTop: '3px', color: green[600] }} /> {customer_name}
                        </Typography>]} */}
                        <Slash>
                            /
                        </Slash>
                        <Typography sx={{display: 'flex'}}>
                            <AccessTimeTwoTone style={{ fontSize: 18, marginRight: '5px', marginTop: '3px' }} color="primary" /> {moment(created_at).format('LL')}, {moment(created_at).format('LT')} WIB
                        </Typography>
                    </Box>
                    <Box>
                        <Divider />
                    </Box>
                    <Box sx={{padding: '35px'}}>
                        <Grid container spacing={3}>
                            <Grid item xs={3} container direction="column">
                                <Grid item>
                                    <Typography color={grey[500]} fontWeight={500} fontSize={13} variant="body1">
                                        Pengemudi
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography fontWeight={700} fontSize={16} variant="h6">
                                        {Driver()}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs={3} container direction="column">
                                <Grid item>
                                    <Typography color={grey[500]} fontWeight={500} fontSize={13} variant="body1">
                                        Plat No.
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography fontWeight={700} fontSize={16} variant="h6">
                                        {Plate()}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs={3} container direction="column">
                                <Grid item>
                                    <Typography color={grey[500]} fontWeight={500} fontSize={13} variant="body1">
                                        Cabang
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography fontWeight={700} sx={{display: 'flex'}}>
                                        {store}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs={3} container direction="column">
                                <Grid item>
                                    <Typography color={grey[500]} fontWeight={500} fontSize={13} variant="body1">
                                        Aktivitas
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography gutterBottom variant="h6" color="primary" fontSize={14} style={{textTransform: 'capitalize', cursor: 'pointer'}}>
                                        Lihat Aktivitas
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </ContentPaper>
                <ContentPaper elevation={3}>
                    <Box sx={{display: 'flex', padding: '35px', justifyContent:"space-between", paddingBottom: 0}}>
                        <Typography fontWeight={700}>
                            Informasi Produk
                        </Typography>
                    </Box>
                    <Box sx={{overflowX: 'scroll', padding: '35px', paddingBottom: 0, paddingTop: '15px'}}>
                        <List component="div">
                            <ListSubheader sx={{display: 'flex', padding: 0}} component="div">
                                <Box sx={{width: '60%', marginRight: '25px'}}>
                                    Nama Barang
                                </Box>
                                <Box textAlign={titleAlign} sx={{width:  '20%', marginRight: '25px'}}>
                                    Diajukan
                                </Box>
                                <Box textAlign={titleAlign} sx={{width:  '20%'}}>
                                    Terkirim
                                </Box>
                            </ListSubheader>
                            {
                                order_delivery_items.map((item, index) => {
                                    return (
                                        <ListItem key={index} disableGutters divider={index !== order_delivery_items.length - 1}>
                                            <Box sx={{width: '60%', marginRight: '25px'}}>
                                                <Typography sx={{display:'flex', position: 'relative'}} lineheight="normal" fontSize={16} fontWeight={700}>
                                                    {item.name}
                                                </Typography>
                                                <Typography lineheight="normal" fontSize={11} style={{color: '#999'}}>
                                                    SKU #{item.sku}
                                                </Typography>
                                            </Box>
                                            <Box sx={{width:  '20%', marginRight: '25px'}}>
                                                {Requested(item)}
                                            </Box>
                                            <Box sx={{width:  '20%'}}>
                                                {Delivered(item, index)}
                                            </Box>
                                        </ListItem>
                                    )
                                })
                            }
                        </List>
                    </Box>
                    <Box sx={{display: 'flex', padding: '35px'}}>
                        <Grid container spacing={3}>
                            <Grid item>
                                <CommentField
                                    value={note}
                                    onChange={onUpdateNoteField}
                                    >
                                    <IconWrapper>
                                        <StickyNote2TwoTone />
                                    </IconWrapper>
                                    <StyledInputBase
                                        name="note"
                                        disabled={status === 'canceled' || status === 'completed'}
                                        placeholder="Catatan…"/>
                                </CommentField>
                            </Grid>
                        </Grid>
                    </Box>
                </ContentPaper>
            </Content>
            <Box>
                <Grid container sx={{marginBottom: '55px'}} justifyContent="space-between" spacing={3}>
                    <Grid item>
                        {Cancel()}
                    </Grid>
                    <Grid item>
                        <Grid container spacing={3}>
                            <Grid item>
                                <BackButton 
                                    variant="contained"
                                    size="large"
                                    disableElevation
                                    onClick={ () => {navigate(`/dashboard/order-deliveries`)}}>
                                    Kembali
                                </BackButton>
                            </Grid>
                            <Grid item>
                                <Button 
                                    variant="contained"
                                    size="large"
                                    color="primary"
                                    sx={{minWidth: 195}}
                                    onClick={onSubmit}
                                    disabled={status === 'delivered' || status === 'completed'}
                                    disableElevation>
                                    Simpan
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>  
            </Box>
            <Confirm />
        </ContentBox>
    )
}

const ContentPaper = styled(Paper)(({theme}) => ({
    marginBottom: 25
}));

const ContentBox = styled(Box)(({theme}) => ({
    padding: '25px 0',
    [theme.breakpoints.down('sm')]: {
    padding: '15px 15px 0 15px',
    },
    [theme.breakpoints.down('md')]: {
    padding: '15px 15px 0 15px',
    },
    height: '100%'
}));


const HeaderBox = styled(Box)(({theme}) => ({
    paddingLeft: 0,
    marginBottom: 25,
    [theme.breakpoints.down('sm')]: {
        paddingLeft: 55,
    },
}));

const Content = styled(Box)(({theme}) => ({
    paddingBottom: 15
}));

const Slash = styled(Typography)(({theme}) => ({
    margin: '0 6px',
    color: 'var(--N75, #E5E7E9)'
}));


const TimelineBox = styled(Box)(({theme}) => ({
    display: 'flex',
    minHeight: 60
 }));

const DateBox = styled(Box)(({theme}) => ({
    width: '20%',
    textAlign: 'right'
}));

const LineWrapperBox = styled(Box)(({theme}) => ({
   margin: '5px 16px',
   alignItems: 'center',
   flexDirection: 'column',
   display: 'flex'
}));

const grow = keyframes`
    from {
        opacity: 1;
        transform: scale(0);
    }
    to {
        opacity: 0;
        transform: scale(2);
    }
`;

const CurrentCircleBox = styled(Box)(({theme}) => ({
    width: '15px',
    height: '15px',
    borderRadius: '50%',
    position: 'relative',
    marginBottom: 'auto',
    '&:before': {
        width: 15,
        position: 'absolute',
        height: 15,
        borderRadius: '50%',
        backgroundColor: `var(--G300,#4B00D1)`,
        animationName:  `${grow}`,
        animationDuration: '1400ms',
        animationTimingFunction: 'ease-out',
        animationIterationCount: 'infinite',
        animationFillMode: 'none',
        animationPlayState: 'running',
        content: '""'
    }, 
    '&:after': {
        borderRadius: '50%',
        backgroundColor: 'var(--G500,#6200EE)',
        content: '""',
        position: 'absolute',
        width: 11,
        height: 11,
        left: 2,
        top: 2
    },
 }));


const CircleBox = styled(Box)(({theme}) => ({
    width: '15px',
    height: '15px',
    borderRadius: '50%',
    position: 'relative',
    marginBottom: 'auto',
    backgroundColor: 'var(--N100,#DBDEE2)',
    '&:after': {
        borderRadius: '50%',
        backgroundColor: 'var(--N200,#9FA6B0)',
        content: '""',
        position: 'absolute',
        width: '9px',
        height: '9px',
        left: '3px',
        top: '3px'
    },
 }));

const LineBox = styled(Box, {
    shouldForwardProp: (prop) => prop !== "last",
  })(({ theme, last }) => ({
    display: last ? 'none' : 'block',
    width: 1,
    height: 'calc(100% - 9px)',
    marginBottom: -6,
    backgroundImage: 'linear-gradient(to bottom,var(--N100,#DBDEE2) 55%,rgba(255,255,255,0) 55%)',
    backgroundPosition: 'left center',
    backgroundSize: '1px 12px',
    backgroundRepeat: 'repeat-y'
 }));

 const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} sx={{marginLeft: '5px'}} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      width: 325,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 11,
      padding: 15
    },
  }));


const BackButton = styled(Button)(({theme}) => ({
    color: theme.palette.text.primary,
    backgroundColor: "#FFFFFF",
    '&:hover': {
    backgroundColor: "#FFFFFF",
    },
    fontWeight: 500,
    border: '1px solid #ddd',
    minWidth: 195
}));


const CommentField = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.black, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.black, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    // [theme.breakpoints.up('sm')]: {
    //   marginLeft: theme.spacing(1),
    //   width: 'auto',
    // },
}));

const IconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.primary.main
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: '12ch',
        '&:focus': {
          width: '20ch',
        },
      },
    },
  }));