import React from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import * as employeeActions from '../../../../../../../../actions/employeeActions';
import { 
    Typography,
    Grid,
    Paper,
    List,
    ListSubheader,
    ListItem,
    Button,
    Box
} from '@mui/material';
import { useParams } from "react-router-dom";
import { AssessmentTwoTone } from '@mui/icons-material';
import Edit from './Edit';
import Loader from '../../../../../../../Helper/Loader';
import moment from 'moment';
import 'moment/locale/id' 
  
export default function Family() {
    let { id } = useParams();
    const dispatch = useDispatch();
    const { employee, editFamily, onEditing } = useSelector(state => ({
        ...state.employee
    }), shallowEqual);

    const { employee_families } = employee;

    function onEdit() {
        dispatch(employeeActions.onEditFamily(id))
    };

    function Content() {
        if (employee_families.length === 0) {
            return (
                <Box>
                    <Grid container direction="column" justify="center" alignItems="center" spacing={2}>
                        <Grid item xs>
                            <AssessmentTwoTone color="primary" style={{fontSize: 120}} />
                        </Grid>
                        <Grid item>
                            <Typography fontWeight={700} variant="h6" lineheight="normal">
                                Tidak ada data.
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
            )
        }

        return (
            <Paper sx={{ overflowX: 'scroll', border: "1px solid #ddd", position: 'relative', zIndex: 9, padding: '0 25px'}}>
                <List component="div" sx={{width: '200%', marginBottom: '15px'}}>
                    <ListSubheader sx={{display: 'flex', padding: 0}} component="div">
                        <Grid container spacing={2}>
                            <Grid item xs={2}>
                                Nama Lengkap
                            </Grid>
                            <Grid item xs>
                                Hubungan
                            </Grid>
                            <Grid item xs={2}>
                                NIK / Passpor
                            </Grid>
                            <Grid item xs>
                                Jenis Kelamin
                            </Grid>
                            <Grid item xs>
                                Tempat Lahir
                            </Grid>
                            <Grid item xs={2}>
                                Tgl. Lahir
                            </Grid>
                            <Grid item xs>
                                Agama
                            </Grid>
                            <Grid item xs>
                                Pekerjaan
                            </Grid>
                        </Grid>
                    </ListSubheader>
                    {
                        employee_families.map((row, index) => {

                            return (
                                <ListItem key={index} sx={{alignItems: "normal", padding: '15px 0'}} disableGutters divider={index !== employee_families.length - 1}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={2}>
                                            {row.full_name}
                                        </Grid>
                                        <Grid item xs>
                                            {row.family_relationship}
                                        </Grid>
                                        <Grid item xs={2}>
                                            {row.identity_number}
                                        </Grid>
                                        <Grid item xs>
                                            {row.gender}
                                        </Grid>
                                        <Grid item xs>
                                            {row.place_of_birth}
                                        </Grid>
                                        <Grid item xs={2}>
                                            {moment(row.date_of_birth).format('LL')}
                                        </Grid>
                                        <Grid item xs>
                                            {row.religion}
                                        </Grid>
                                        <Grid item xs>
                                            {row.job}
                                        </Grid>
                                    </Grid>
                                </ListItem>
                            )
                        })
                    }
                </List>
            </Paper>
        )
    }

    if (onEditing) {
        return (
            <Box sx={{ minHeight: '300px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Loader />
            </Box>
        )
    }
      
    if (editFamily) {
        return <Edit />;
    }

    return (
        <Grid container spacing={4} direction="column">
            <Grid item container>
                <Grid item xs>
                    <Typography fontWeight={700} component="h6" lineheight="normal">
                        Keluarga
                    </Typography>
                </Grid>
                <Grid item>
                    <Button onClick={onEdit}>
                        {employee_families.length === 0 ? "Tambah" : "Ubah"}
                    </Button>
                </Grid>
            </Grid>
            <Grid item>
                {Content()}
            </Grid>
        </Grid>
    )
}
