import React, { useState } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import * as productActions from '../../../../../../../actions/productActions';
import { 
        Typography,
        Paper,
        Box,
        Grid,
        Button,
        TextField,
        InputAdornment,
        FormGroup,
        FormControlLabel,
        Switch,
        FormControl,
        Chip,
        InputLabel,
        IconButton,
        OutlinedInput,
        Alert
} from '@mui/material';
import { styled } from '@mui/material/styles';
import NumberFormat from 'react-number-format';
import {AddRounded, RemoveRounded ,LaunchRounded} from '@mui/icons-material';
import AdvancedPricing from './AdvancedPricing';

const CurrencyFormat = React.forwardRef((props, ref) => (
    <NumberFormat
        {...props.other}
        onValueChange={(values) => {
          props.onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        customInput={TextField}
        InputProps={{
            startAdornment: <InputAdornment position="start">
                <Box fontWeight={700}>
                    Rp
                </Box>
            </InputAdornment>,
        }}
        placeholder="0"
        helperText={props.helperText}
        error={props.error}
        value={props.value}
        thousandSeparator={"."}
        decimalSeparator={","}
        allowNegative={false}
        decimalScale={0}
        isNumericString
      />
));
export default function Price({product, onChange, error}) {
    const dispatch = useDispatch();
    const {onModifier} = useSelector(state => ({
        ...state.product
    }), shallowEqual);

    const { price, product_price_modifiers, product_options, product_variants, product_prices, has_histories } = product;


    const [modifier, setModifier] = useState([]);


    function onShowAdvancedPrice() {
        dispatch(productActions.onShowAdvancedPrice());
    }

    function onToggleModifier() {
        dispatch(productActions.onToggleModifier());
    }

    function onDeleteModifiers() {
        dispatch(productActions.onDeleteModifiers());
    }

    function onChangeModifier(event) {
        const value = event.target.value;
        setModifier(value);
    }

    function onPressEnter(event) {
        if(event.keyCode == 13 && modifier.length >= 1){
            setModifier("");
            const newProductPriceModifiers = [...product_price_modifiers, { key: capitalize(modifier) }]
            newProductPriceModifiers.map((item, index)=> {
                return item.sort_order = index
            })
            dispatch(productActions.onUpdateProductModifier(newProductPriceModifiers));
        }
    }

    function onBlur() {
        if (modifier.length >= 1) {
            setModifier("");
            const newProductPriceModifiers = [...product_price_modifiers, { key: capitalize(modifier) }]
            newProductPriceModifiers.map((item, index)=> {
                return item.sort_order = index
            })
            dispatch(productActions.onUpdateProductModifier(newProductPriceModifiers));
        }
    }

    function onDeleteModifier(index) {
        const item = product_price_modifiers[index];
        if (item._id) {
			product_price_modifiers[index]._destroy = true
		} else {
			product_price_modifiers.splice(index, 1)
        }
        const newProductPriceModifiers = [...product_price_modifiers];
        newProductPriceModifiers.filter(el => {return !el._destroy}).map((item, index) => {
            return item.sort_order = index
        })

        dispatch(productActions.onUpdateProductModifier(newProductPriceModifiers));

    }

    function getDestroyedModifiers() {
		return product_price_modifiers.filter(el => {
	    	return el._destroy !== true;
	  	}).length;
	}

    function getDestroyedOptions() {
		return product_options.filter(el => {
	    	return el._destroy !== true;
	  	}).length;
	}

    function getDestroyedVariants() {
		return product_variants.filter(el => {
	    	return el._destroy !== true;
	  	}).length;
	}


    function capitalize(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
      }


    function productPrice() {
        if (getDestroyedOptions() !== 0 || getDestroyedVariants() !== 0) {
            return null;
        }

        return (
            <Grid item container spacing={8}>
                <Grid item xs={4} container direction="column">
                    <Grid item xs>
                        <Typography lineheight="normal" component="div">
                            <Box fontSize={16} fontWeight={700}>
                                Harga Satuan
                            </Box>
                        </Typography>
                        <Typography variant="body2" gutterBottom color="textSecondary">
                            Masukan harga satuan. Kosongkan apabila produk opsi atau product variant.
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item xs={8}>
                    <CurrencyFormat
                        error={!!error('price')}
                        name="price"
                        value={price}
                        helperText={error('price')}
                        variant="outlined"
                        onChange={onChange}
                    />
                </Grid> 
            </Grid>
        )
    }


    function PriceModifier() {
        if (onModifier || (getDestroyedModifiers() !== 0 && product_price_modifiers.length > 0)) {
            return (
                <Grid item container spacing={8}>
                    <Grid item xs={4}>
                        <Typography fontSize={16} fontWeight={700} component="div">
                            Modifier Harga
                        </Typography>
                        <Typography variant="body2" gutterBottom color="textSecondary">
                            Modifier harga untuk barang dengan harga yang berbeda-beda.
                        </Typography>
                    </Grid>
                    <Grid item xs={8} container alignItems="center">
                        <Grid item xs>
                            <FormControl
                                fullWidth>
                                <OutlinedInput
                                    onChange={onChangeModifier}
                                    onKeyDown={onPressEnter}
                                    onBlur={onBlur}
                                    value={modifier}
                                    startAdornment={
                                        <InputAdornment position="start">
                                            {
                                                product_price_modifiers.map((item, index) => {
                                                    if (item._destroy) {
                                                        return null;
                                                    }
                                                    return (
                                                        <Chip
                                                            key={index}
                                                            tabIndex={-1}
                                                            label={item.key}
                                                            sx={{marginRight: '5px'}}
                                                            onDelete={onDeleteModifier.bind(this, index)}
                                                            variant="outlined"
                                                            />
                                                    )
                                                })
                                            }
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                        </Grid>
                        <Grid item>
                            <DeleteButton 
                                variant="contained"
                                size="large"
                                disableElevation
                                onClick={onDeleteModifiers}
                                >
                                Hapus Semua
                            </DeleteButton> 
                        </Grid>
                    </Grid>
                </Grid>
            )
        }
        return (
            <Grid item container spacing={8}>
                <Grid item xs={4}>
                    <Typography fontSize={16} fontWeight={700} component="div">
                        Modifier Harga
                    </Typography>
                    <Typography variant="body2" gutterBottom color="textSecondary">
                        Aktifkan modifier harga untuk barang dengan harga yang berbeda-beda.
                    </Typography>
                </Grid>
                <Grid item xs={8}>
                    <Switch
                        checked={onModifier}
                        onChange={onToggleModifier}
                        />
                </Grid>
            </Grid>
        )
    }

    function prices() {
        if (product_prices.filter(el => {return !el._destroy}).length === 0) {
            return (
                <ActionButton
                    variant="contained"
                    size="large"
                    disableElevation
                    startIcon={<AddRounded />}
                    onClick={onShowAdvancedPrice}
                    >
                    Harga Khusus
                </ActionButton>
            )
        }

        return (
            <ActionButton
                variant="contained"
                size="large"
                disableElevation
                onClick={onShowAdvancedPrice}
                >
                {product_prices.filter(el => {return !el._destroy}).length}+ Group Pelanggan
            </ActionButton>
        )
    }
    return (
        <ContentPaper elevation={3}>
            <Grid container spacing={6} direction="column">
                <Grid item container>
                    <Grid item xs container spacing={1}>
                        <Grid item>
                            <Typography variant="h6" fontWeight={700}>
                                Harga
                            </Typography>
                        </Grid>
                        <Grid item>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Alert severity="error">
                            Wajib
                        </Alert>
                    </Grid>     
                </Grid>
                <Grid item container direction="column" spacing={5}>
                    {PriceModifier()}
                    {productPrice()}
                    <Grid item container spacing={8}>
                        <Grid item xs={4}>
                            <Typography fontSize={16} fontWeight={700} component="div">
                                Grup Pelanggan
                            </Typography>
                            <Typography variant="body2" gutterBottom color="textSecondary">
                                Berikan harga khusus untuk setiap grup pelanggan.
                            </Typography>
                        </Grid>
                        <Grid item xs={8} container direction="column">
                            <Grid item>
                                {prices()}
                            </Grid>
                        </Grid>
                    </Grid> 
                </Grid>
            </Grid>
            <AdvancedPricing />
        </ContentPaper>
    )
}

const ContentPaper = styled(Paper)(({theme}) => ({
    padding: 35,
    paddingTop: 32,
    marginBottom: 25
}));


const ActionButton = styled(Button)(({theme}) => ({
    color: theme.palette.primary.main,
    backgroundColor: "#FFFFFF",
    '&:hover': {
    backgroundColor: "#FFFFFF",
    },
    fontWeight: 500,
    border: '1px solid #ddd',
}));

const DeleteButton = styled(Button)(({theme}) => ({
    color: theme.palette.primary.main,
    backgroundColor: "#FFFFFF",
    '&:hover': {
    backgroundColor: "#FFFFFF",
    },
    fontWeight: 500,
    border: '1px solid #transparent',
}));


