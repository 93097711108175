import React from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { onShowActivities } from '../../../../../../actions/storeTransferActions';
import { 
        Typography,
        Paper,
        Grid,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import 'moment/locale/id' 

export default function Information() {    
    const dispatch = useDispatch();
    const {store_transfer} = useSelector(state => ({
        ...state.store_transfer
    }), shallowEqual); 

    const { increment_id, 
            items_count,
            item_types_count,
            from_store_name, 
            to_store_name,
            created_at, 
            status,
            status_title } = store_transfer;
            
    return (
        <ContentPaper elevation={3}>
            <Grid container direction={"column"} spacing={3}>
                <Grid xs item container spacing={3}>
                    <Grid item xs container direction="column">
                        <Grid item>
                            <Typography fontWeight={500} fontSize={13} variant="body1">
                                ID Transfer
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography fontWeight={700} fontSize={16} variant="h6">
                                #{increment_id}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs container direction="column">
                        <Grid item>
                            <Typography fontWeight={500} fontSize={13} variant="body1">
                                Cabang Asal
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography fontWeight={700} fontSize={16} variant="h6">
                                {from_store_name}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs container direction="column">
                        <Grid item>
                            <Typography fontWeight={500} fontSize={13} variant="body1">
                                Cabang Tujuan
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography fontWeight={700} fontSize={16} variant="h6">
                                {to_store_name}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs container direction="column">
                        <Grid item>
                            <Typography fontWeight={500} fontSize={13} variant="body1">
                                Status
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography fontWeight={700} fontSize={16} variant="h6">
                                {status_title}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid xs item container spacing={3}>
                    <Grid item xs container direction="column">
                        <Grid item>
                            <Typography fontWeight={500} fontSize={13} variant="body1">
                                Jumlah Barang
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography fontWeight={700} fontSize={16} variant="h6">
                                {status === 'transfered' || status === 'completed' ? items_count : "-"}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs container direction="column">
                        <Grid item>
                            <Typography fontWeight={500} fontSize={13} variant="body1">
                                Jumlah Tipe
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography fontWeight={700} fontSize={16} variant="h6">
                                {item_types_count}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs container direction="column">
                        <Grid item>
                            <Typography fontWeight={500} fontSize={13} variant="body1">
                                Tgl. Pembuatan
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography fontWeight={700} fontSize={16} variant="h6">
                                {moment(created_at).format('LL')}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs container direction="column">
                        <Grid item>
                            <Typography fontWeight={500} fontSize={13} variant="body1">
                                Aktivitas
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography gutterBottom variant="h6" color="primary" fontSize={14} style={{textTransform: 'capitalize', cursor: 'pointer'}} onClick={ () => { dispatch(onShowActivities())}}>
                                Lihat Aktivitas
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </ContentPaper>
    )
}

const ContentPaper = styled(Paper)(({theme}) => ({
    padding: 35,
    paddingTop: 32,
    marginBottom: 25
}));