import React from 'react';
import { 
        Typography,
        Box,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Form from './Form';

export default function Edit() {    
    return (
        <ContentBox>
            <HeaderBox>
                <Typography fontWeight={700} variant="h6">
                    Edit Customer Display
                </Typography>
            </HeaderBox>
            <Form />
        </ContentBox>
            
            
    )
}

const ContentBox = styled(Box)(({theme}) => ({
    padding: '25px 0',
    [theme.breakpoints.down('sm')]: {
        padding: '15px 15px 0 15px',
    },
    [theme.breakpoints.down('md')]: {
        padding: '15px 15px 0 15px',
    },
}));

const HeaderBox = styled(Box)(({theme}) => ({
    paddingLeft: 0,
    marginBottom: 25,
    [theme.breakpoints.down('sm')]: {
        paddingLeft: 55,
    },
}));

