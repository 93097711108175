import React from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import * as orderDeliveryReturnActions from '../../../../../../../actions/orderDeliveryReturnActions';
import { 
    Dialog,
    Slide,
    CircularProgress,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useHistory, useParams } from "react-router-dom";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function Confirm() {
    let { id } = useParams();
    const dispatch = useDispatch();
    const {onOrderDeliveryReturnConfirm, order_delivery_return, actionType, inProgress} = useSelector(state => ({
        ...state.order_delivery_return
    }), shallowEqual);
   

    function onHide() {
        dispatch(orderDeliveryReturnActions.onHideConfirm());
    }

   
    function Title() {
        if (actionType === 'canceled') {
            return (
                <DialogTitle>{"Batalkan Pengembalian?"}</DialogTitle>
            )
        }

        // if (actionType === 'ordered') {
        //     return (
        //         <DialogTitle>{"Proses PO?"}</DialogTitle>
        //     )
        // }

        if (actionType === 'returned') {
            return (
                <DialogTitle>{"Konfirmasi Pengembalian?"}</DialogTitle>
            )
        }

        // if (actionType === 'approved') {
        //     return (
        //         <DialogTitle>{"Validasi PO?"}</DialogTitle>
        //     )
        // }

        // if (actionType === 'updated') {
        //     return (
        //         <DialogTitle>{"Perbaharui PO?"}</DialogTitle>
        //     )
        // }

        // if (actionType === 'paid') {
        //     return (
        //         <DialogTitle>{"Process Payment?"}</DialogTitle>
        //     )
        // }
        return null;
    }

    function ContentText() {
        if (actionType === 'canceled') {
            return (
                <DialogContentText>
                    Apakah kamu yakin ingin membatalkan Pengembalian dengan ID <span style={{fontWeight: 'bold'}}>#{order_delivery_return.increment_id}</span>.
                </DialogContentText>
            )
        }

        // if (actionType === 'ordered') {
        //     return (
        //         <DialogContentText>
        //             PO dengan ID <span style={{fontWeight: 'bold'}}>#{order_delivery_return.increment_id}</span> telah dipesan dengan kuantitas yang benar..
        //         </DialogContentText>
        //     )
        // }

        if (actionType === 'returned') {
            return (
                <DialogContentText>
                    Pengembalian dengan ID  <span style={{fontWeight: 'bold'}}>#{order_delivery_return.increment_id}</span> telah diterima dengan kuantitas yang benar.
                </DialogContentText>
            )
        }
        // if (actionType === 'approved') {
        //     return (
        //         <DialogContentText>
        //             PO dengan ID <span style={{fontWeight: 'bold'}}>#{order_delivery_return.increment_id}</span> telah disetujui dengan kuantitas dan harga yang benar..
        //         </DialogContentText>
        //     )
        // }
        // if (actionType === 'updated') {
        //     return (
        //         <DialogContentText>
        //              PO dengan ID <span style={{fontWeight: 'bold'}}>#{order_delivery_return.increment_id}</span> diperbaharui.
        //         </DialogContentText>
        //     )
        // }
        // if (actionType === 'paid') {
        //     return (
        //         <DialogContentText>
        //              PO dengan ID  <span style={{fontWeight: 'bold'}}>#{order_delivery_return.increment_id}</span> has been paid according to the bill.
        //         </DialogContentText>
        //     )
        // }
        return null;
    }

    function onSubmit() {
        if (actionType === 'canceled') {
            dispatch(orderDeliveryReturnActions.onCanceled(id, order_delivery_return));
        }

        if (actionType === 'returned') {
            dispatch(orderDeliveryReturnActions.onDelivered(id, order_delivery_return));
        }

        // if (actionType === 'ordered') {
        //     dispatch(orderDeliveryReturnActions.onOrdered(id, order_delivery_return));
        // }

        // if (actionType === 'received') {
        //     dispatch(orderDeliveryReturnActions.onReceived(id, order_delivery_return));
        // }

        // if (actionType === 'approved') {
        //     dispatch(orderDeliveryReturnActions.onApproved(id, order_delivery_return));
        // }

        // if (actionType === 'updated') {
        //     dispatch(orderDeliveryReturnActions.onUpdate(id, order_delivery_return));
        // }
    }

    return (
        <Dialog
            open={onOrderDeliveryReturnConfirm}
            maxWidth="xs"
            TransitionComponent={Transition}
            scroll={'paper'}>
            {Title()}
            <DialogContent>
                {ContentText()}
            </DialogContent>
            <DialogActions style={{padding: 25, justifyContent: 'space-between'}}>
                <CancelButton size="large" variant="contained" disabled={inProgress} disableElevation onClick={onHide}>
                    Batal
                </CancelButton>
                <Button sx={{minWidth: '165px'}} size="large" variant="contained" disabled={inProgress} disableElevation onClick={onSubmit} color="primary">
                    {inProgress ? <CircularProgress size={26} /> : 'Proses'}
                </Button>
            </DialogActions>    
        </Dialog>
    )
}

const CancelButton = styled(Button)(({theme}) => ({
    color: theme.palette.text.primary,
    backgroundColor: "#FFFFFF",
    '&:hover': {
    backgroundColor: "#FFFFFF",
    },
    fontWeight: 500,
    minWidth: 165,
    border: `1px solid #ddd`
}));
