import React, { useState, useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import * as employeeAccessActions from '../../../../../actions/employeeAccessActions';
import { 
    Typography,
    Paper,
    Box,
    Grid,
    TextField,
    MenuItem,
    Alert,
    OutlinedInput,
    Checkbox,
    Collapse,
    FormGroup,
    FormControlLabel,
    Button,
    CircularProgress
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate, useParams } from "react-router-dom";
import Loader from '../../../../Helper/Loader';

export default function Form() {
    let navigate = useNavigate();
    let { id } = useParams();
    const dispatch = useDispatch();
    const { employee_access, inProgress, errors} = useSelector(state => ({
        ...state.employee_access
    }), shallowEqual);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
    },[errors]);

    useEffect(() => {
        if (id) {
			dispatch(employeeAccessActions.onEmployeeAccessEdit(id));
		} else {
			dispatch(employeeAccessActions.onEmployeeAccessNew());
		}
        return () => {
            dispatch(employeeAccessActions.onUnloadForm())
        };
    },[dispatch, id]);

    function onUpdateField(event) {
		const key = event.target.name;
        const value = event.target.value;
        dispatch(employeeAccessActions.onUpdateField(key, value));
    }

    function selectAccesses(value) {
        var newAccesses = [...accesses];
        if (!accesses.includes(value)) {
            newAccesses.push(value);
        } else if (accesses.includes(value)) {
            const itemIndex = newAccesses.findIndex(o => o === value)
            newAccesses.splice(itemIndex, 1);
        }    
        const newEmployeeAccess = {...employee_access};
        newEmployeeAccess.accesses = newAccesses;
        dispatch(employeeAccessActions.onUpdateEmployeeAccess(newEmployeeAccess));
    }

    function selectPermission(value) {
        var newPermissions = [...permissions];
        if (!permissions.includes(value)) {
            newPermissions.push(value);
        } else if (permissions.includes(value)) {
            const itemIndex = newPermissions.findIndex(o => o === value)
            newPermissions.splice(itemIndex, 1);
        }    
        const newEmployeeAccess = {...employee_access};
        newEmployeeAccess.permissions = newPermissions;
        dispatch(employeeAccessActions.onUpdateEmployeeAccess(newEmployeeAccess));
    }

    function onSubmit() {
        if (id) {
            dispatch(employeeAccessActions.onEdit(id, employee_access));
        } else {
            dispatch(employeeAccessActions.onCreate(employee_access));
        }
    }
    
    if (!employee_access) {
        return (
            <Loader />
        )
    }

    function error(key) {
        if (errors != null && errors[`employee_access.${key}`] != null) {
            return errors[`employee_access.${key}`].msg
        }
        return null;
    }


    const {name, accesses, permissions} = employee_access;


    const accessApp = accesses.includes('App');
    const accessBackoffice = accesses.includes('Backoffice');
    return (
        <Box>
            <ContentPaper elevation={3}>
                <Grid container spacing={6} direction="column">
                    <Grid item container>
                        <Grid item xs>
                            <Typography fontWeight={700} variant="h6" lineheight="normal">
                                Role Information
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Alert severity="error">
                                Required
                            </Alert>
                        </Grid>
                    </Grid>
                    <Grid item container direction="column" spacing={5}>
                        <Grid item container spacing={8}>

                            <Grid item xs={4}>
                                <Typography fontSize={16} fontWeight={700} component="div">
                                    Role Name
                                </Typography>
                                <Typography variant="body2" gutterBottom color="textSecondary">
                                    Enter employee access Role Name.
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <TextField
                                    fullWidth
                                    error={!!error('name')}
                                    name="name"
                                    placeholder="Role Name"
                                    value={name}
                                    helperText={error('name')}
                                    variant="outlined"
                                    onChange={onUpdateField}
                                />
                            </Grid> 
                        </Grid>
                    </Grid>
                </Grid>
            </ContentPaper>
            <ContentPaper elevation={3}>
                <Grid container spacing={2} direction="column">
                    <Grid item container alignItems="center">
                        <Grid item>
                            <Checkbox
                                color="primary"
                                onChange={selectAccesses.bind(this, "Backoffice")}
                                checked={accessBackoffice}
                            />
                        </Grid>
                        <Grid item xs>
                            <Typography fontWeight={700} variant="h6" lineheight="normal">
                                Backoffice Permissions
                            </Typography>
                        </Grid>
                        <Grid item>
                        </Grid>
                    </Grid>
                    <Grid sx={{marginBottom: '35px'}} item>
                        <Typography variant="body2" gutterBottom color="textSecondary">
                            Allow employees to access and manage your backoffice.
                        </Typography>
                    </Grid>
                </Grid>
                <Collapse in={accessBackoffice} timeout="auto" unmountOnExit>
                    <Grid container direction="column" spacing={3}>
                        <Grid item container spacing={8}>
                            <Grid item xs={6}>
                                <Typography fontSize={16} fontWeight={700} component="div">
                                    Dashboard
                                </Typography>
                                <Typography variant="body2" gutterBottom color="textSecondary">
                                    Allow employee to access dashboard.
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <FormGroup>
                                    <FormControlLabel control={<Checkbox disabled checked />} label="Create" />
                                </FormGroup>
                            </Grid> 
                            <Grid item xs={2}>
                                <FormGroup>
                                    <FormControlLabel control={<Checkbox disabled checked />} label="Edit" />
                                </FormGroup>
                            </Grid>
                            <Grid item xs={2}>
                                <FormGroup>
                                    <FormControlLabel control={<Checkbox checked={permissions.includes('view_dashboard')} onChange={selectPermission.bind(this, 'view_dashboard')} />} label="View" />
                                </FormGroup>
                            </Grid>  
                        </Grid>
                        <Grid item container spacing={8}>
                            <Grid item xs={6}>
                                <Typography fontSize={16} fontWeight={700} component="div">
                                    Product
                                </Typography>
                                <Typography variant="body2" gutterBottom color="textSecondary">
                                    Allow employee to access Products.
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <FormGroup>
                                    <FormControlLabel control={<Checkbox checked={permissions.includes('create_product')} onChange={selectPermission.bind(this, 'create_product')} />} label="Create" />
                                </FormGroup>
                            </Grid> 
                            <Grid item xs={2}>
                                <FormGroup>
                                    <FormControlLabel control={<Checkbox checked={permissions.includes('edit_product')} onChange={selectPermission.bind(this, 'edit_product')} />} label="Edit" />
                                </FormGroup>
                            </Grid>
                            <Grid item xs={2}>
                                <FormGroup>
                                    <FormControlLabel control={<Checkbox checked={permissions.includes('view_product')} onChange={selectPermission.bind(this, 'view_product')} />} label="View" />
                                </FormGroup>
                            </Grid>  
                        </Grid>
                        <Grid item container spacing={8}>
                            <Grid item xs={6}>
                                <Typography fontSize={16} fontWeight={700} component="div">
                                    Category
                                </Typography>
                                <Typography variant="body2" gutterBottom color="textSecondary">
                                    Allow employee to access category.
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <FormGroup>
                                    <FormControlLabel control={<Checkbox checked={permissions.includes('create_category')} onChange={selectPermission.bind(this, 'create_category')} />} label="Create" />
                                </FormGroup>
                            </Grid> 
                            <Grid item xs={2}>
                                <FormGroup>
                                    <FormControlLabel control={<Checkbox checked={permissions.includes('edit_category')} onChange={selectPermission.bind(this, 'edit_category')} />} label="Edit" />
                                </FormGroup>
                            </Grid>
                            <Grid item xs={2}>
                                <FormGroup>
                                    <FormControlLabel control={<Checkbox checked={permissions.includes('view_category')} onChange={selectPermission.bind(this, 'view_category')} />} label="View" />
                                </FormGroup>
                            </Grid>  
                        </Grid>
                    </Grid>
                </Collapse>
            </ContentPaper>
            <ContentPaper elevation={3}>
                <Grid container spacing={2} direction="column">
                    <Grid item container alignItems="center">
                        <Grid item>
                            <Checkbox
                                color="primary"
                                onChange={selectAccesses.bind(this, "App")}
                                checked={accesses.includes('App')}
                            />
                        </Grid>
                        <Grid item xs>
                            <Typography fontWeight={700} variant="h6" lineheight="normal">
                                App Permissions
                            </Typography>
                        </Grid>
                        <Grid item>
                        </Grid>
                    </Grid>
                    <Grid sx={{marginBottom: '35px'}} item>
                        <Typography variant="body2" gutterBottom color="textSecondary">
                            Allow employees to access and manage your app.
                        </Typography>
                    </Grid>
                </Grid>
                <Collapse in={accessApp} timeout="auto" unmountOnExit>
                    <Grid container direction="column" spacing={3}>
                        <Grid item container spacing={8}>
                            <Grid item xs={6}>
                                <Typography fontSize={16} fontWeight={700} component="div">
                                    Order
                                </Typography>
                                <Typography variant="body2" gutterBottom color="textSecondary">
                                    Allow employee to access order.
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <FormGroup>
                                    <FormControlLabel control={<Checkbox checked={permissions.includes('create_order')} onChange={selectPermission.bind(this, 'create_order')} />} label="Create" />
                                </FormGroup>
                            </Grid> 
                            <Grid item xs={2}>
                                <FormGroup>
                                    <FormControlLabel control={<Checkbox checked={permissions.includes('edit_order')} onChange={selectPermission.bind(this, 'edit_order')} />} label="Edit" />
                                </FormGroup>
                            </Grid>
                            <Grid item xs={2}>
                                <FormGroup>
                                    <FormControlLabel control={<Checkbox checked={permissions.includes('view_order')} onChange={selectPermission.bind(this, 'view_order')} />} label="View" />
                                </FormGroup>
                            </Grid>  
                        </Grid>
                    </Grid>
                </Collapse>
            </ContentPaper>
            <Box>
                <Grid container sx={{marginBottom: '55px'}} justifyContent="space-between" spacing={3}>
                    <Grid item>
                    </Grid>
                    <Grid item>
                        <Grid container spacing={3}>
                            <Grid item>
                                <BackButton 
                                    variant="contained"
                                    size="large"
                                    disableElevation
                                    disabled={inProgress}
                                    onClick={ () => {navigate(`/dashboard/roles`)}}>
                                    Cancel
                                </BackButton>
                            </Grid>
                            <Grid item>
                                <Button 
                                    variant="contained"
                                    size="large"
                                    color="primary"
                                    sx={{minWidth: 195}}
                                    onClick={onSubmit}
                                    disabled={inProgress}
                                    disableElevation>
                                    {inProgress ? <CircularProgress size={26} /> : 'Save'}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>  
            </Box>
        </Box>
    )
}

const ContentPaper = styled(Paper)(({theme}) => ({
    padding: 35,
    paddingTop: 32,
    marginBottom: 25
}));


const BackButton = styled(Button)(({theme}) => ({
    color: theme.palette.text.primary,
    backgroundColor: "#FFFFFF",
    '&:hover': {
    backgroundColor: "#FFFFFF",
    },
    fontWeight: 500,
    border: '1px solid #ddd',
    minWidth: 195
}));
