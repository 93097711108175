import React from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import * as customerActions from '../../../../../../../actions/customerActions';
import { 
    Dialog,
    Slide,
    Typography,
    Box,
    DialogTitle,
    IconButton,
    Button,
    DialogActions,
    CircularProgress,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Close } from '@mui/icons-material';
import { useNavigate, useParams } from "react-router-dom";
import Content from './Content';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function Edit() {
    const dispatch = useDispatch();
    let { id } = useParams();
    const {editCustomer, onEdit, inProgress} = useSelector(state => ({
        ...state.customer
    }), shallowEqual);

    function onCancel() {
        dispatch(customerActions.onHideEdit());
    }
    
    function onSubmit() {
        dispatch(customerActions.onEdit(id, editCustomer));
    }

    return (
        <Dialog
            open={onEdit}
            maxWidth="sm"
            fullWidth={true}
            TransitionComponent={Transition}
            scroll={'paper'}
            >
            <DialogTitle textAlign="center" style={{textTransform: 'capitalize'}}>
                Alamat Pelanggan
            </DialogTitle>
            <Content />
            <DialogActions sx={{padding: '25px', justifyContent: 'space-between'}}>
                <BackButton  
                    size="large"
                    disableElevation
                    variant="contained"
                    onClick={onCancel} 
                    disabled={inProgress}
                    color="secondary">
                    Batal
                </BackButton>
                <Button size="large"
                    sx={{minWidth: 195}}
                    disableElevation
                    onClick={onSubmit}
                    disabled={inProgress}
                    variant="contained" color="primary">
                    {inProgress ? <CircularProgress size={26} /> : 'Simpan'}
                </Button>
            </DialogActions>
        </Dialog>
        // <Dialog
        //     open={onEdit}
        //     maxWidth="sm"
        //     fullWidth={true}
        //     TransitionComponent={Transition}
        //     scroll={'paper'}
        //     classes={{paper: classes.dialog}}
        //     >
        //     <Title onClose={onHide}>
        //         Akun Pelanggan
        //     </Title>
        //     <Content />
        //     <DialogActions className={classes.actions}>
        //         <WhiteButton  
        //             size="large"
        //             disableElevation
        //             variant="contained"
        //             onClick={onCancel} 
        //             color="secondary">
        //             Batal
        //         </WhiteButton>
        //         <div className={classes.spacer} />
        //         <Button size="large"
        //             className={classes.primary}
        //             disableElevation
        //             onClick={onSubmit}
        //             disabled={inProgress}
        //             variant="contained" color="primary">
        //             {inProgress ? <CircularProgress size={26} /> : 'Simpan'}
        //         </Button>
        //     </DialogActions>
        // </Dialog>
    )
}

const BackButton = styled(Button)(({theme}) => ({
    color: theme.palette.text.primary,
    backgroundColor: "#FFFFFF",
    '&:hover': {
    backgroundColor: "#FFFFFF",
    },
    fontWeight: 500,
    border: '1px solid #ddd',
    minWidth: 195
}));

// const useStyles = makeStyles((theme) => ({
//     dialog: {
//         overflowY: 'unset'
//     },
//     content: {
//         padding: '15px 45px 15px 45px !important'
//     },
//     actions: {
//         padding: '15px 25px'
//     },
//     spacer: {
//         width: 5
//     },
//     primary: {
//         minWidth: 165
//     },
//     closeButton: {
        // position: 'absolute',
        // right: theme.spacing(1),
        // top: theme.spacing(1),
        // color: theme.palette.grey[500],
//     },
// }));

// const WhiteButton = withStyles((theme) => ({
//     root: {
//       color: theme.palette.text.primary,
//       backgroundColor: "#FFFFFF",
//       '&:hover': {
//         backgroundColor: "#FFFFFF",
//       },
//       fontWeight: 500,
//       minWidth: 165,
//       border: `1px solid #ddd`
//     },
// }))(Button);
