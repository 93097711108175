import React, { useState, useEffect } from 'react';
import {
    Typography, 
    Grid,
    TextField,
    CircularProgress,
    Alert,
    Autocomplete
} from '@mui/material';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import * as initializeActions from '../../../actions/initializeActions';
import NumberFormat from 'react-number-format';

const PhoneFormat = React.forwardRef((props, ref) => (
    <NumberFormat
        {...props.other}
        onValueChange={(values) => {
          props.onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        customInput={TextField}
        placeholder={props.placeholder}
        value={props.value}
        helperText={props.helperText}
        error={props.error}
        fullWidth={props.fullWidth}
        allowLeadingZeros={true}
        allowNegative={false}
        allowedDecimalSeparators={false}
        isNumericString
      />
));


export default function BusinessInformation() {
    const dispatch = useDispatch();
    const { initialize_user, business_types, areas, areasFetching, errors } = useSelector(state => ({
        ...state.initialize
    }), shallowEqual); 

    const [loaded, setLoaded] = useState(false);
    const [value, setValue] = useState(null);
    const [inputValue, setInputValue] = useState('');
    const [areaOptions, setAreaOptions] = useState([]);

    useEffect(() => {
        function initialize() {
            const { state, city, district, code_area } = initialize_user.business_information;
            if (!!state && !!city && !!district && !!code_area) {
                const newOptions = [];
                const selectedOption = {value: `${state}, ${city}, ${district}, ${code_area}`, name: `${state}, ${city}, ${district}, ${code_area}`};
                newOptions.push(selectedOption);
                setAreaOptions(newOptions)
                setValue(selectedOption);
            }
        }
        if (!loaded) {
            initialize();
            setLoaded(true);
        }
    }, [initialize_user, loaded]);

    useEffect(() => {
        setAreaOptions(areas)
    }, [areas]);


    function onUpdateField(event) {
		const key = event.target.name;
        const value = event.target.value;
        const newUser = {...initialize_user};
        newUser.business_information[key] = value;
        dispatch(initializeActions.onUpdateUser(newUser));
    }

    function onSelectBusiness(value) {
        const newUser = {...initialize_user};
        newUser.business_information.business_type_id = value;
        dispatch(initializeActions.onUpdateUser(newUser));
    }

    function onSelectArea(option) {
        if (!option) {
            return;
        }
        setValue(option);
        const newUser = {...initialize_user};
        const selectedArea = option.value.split(', ');
        newUser.business_information.state = selectedArea[0];
        newUser.business_information.city = selectedArea[1];
        newUser.business_information.district = selectedArea[2];
        newUser.business_information.code_area = selectedArea[3];
        dispatch(initializeActions.onUpdateUser(newUser));
    }


    function error(key) {
        if (errors != null && errors[key] != null) {
            return errors[key][0]
        }

        return null;
    }

    const { business_information } = initialize_user;

    const { name, telephone, fax, address, business_type_id } = business_information;

    const businessValue = business_types.find(b => b._id === business_type_id)
  
    return (
        <Grid container spacing={6} direction="column">
            <Grid item container spacing={3}>
                <Grid item xs>
                    <Typography fontWeight={700} variant="h6" lineheight="normal">
                        Informasi Bisnis
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                        Masukan informasi bisnis anda dari nama bisnis hingga alamat bisnis berada, informasi dapat berupa kantor pusat / toko anda.
                    </Typography>
                </Grid>
                <Grid item>
                    <Alert severity="error">
                        Wajib
                    </Alert>
                </Grid>
            </Grid>
            <Grid item container direction="column" spacing={3}>
                <Grid item container spacing={3}>
                    <Grid item xs>
                        <TextField
                            error={!!error('business_information.name')}
                            fullWidth
                            name="name"
                            label="Nama Bisnis"
                            placeholder="Nama Bisnis"
                            value={name}
                            helperText={error('business_information.name')}
                            variant="outlined"
                            onChange={onUpdateField} 
                        />
                    </Grid>
                    <Grid item xs>
                        <Autocomplete
                            options={business_types.sort((a, b) => -b.group.localeCompare(a.group))}
                            groupBy={(option) => option.group}
                            getOptionLabel={(option) => option.name}
                            selectOnFocus
                            clearOnBlur
                            filterSelectedOptions
                            disableClearable
                            onChange={(event, option) => {
                                onSelectBusiness(option.value)
                            }}
                            value={businessValue ? businessValue : null}
                            renderInput={(params) => <TextField {...params} label="Jenis Bisnis" error={!!error('business_information.business_type_id')} helperText={error('business_information.business_type_id')} placeholder="Pilih jenis bisnis" variant="outlined" />}
                        />
                    </Grid>
                </Grid> 
                <Grid item container spacing={3}>
                    <Grid item xs>
                        <PhoneFormat
                            fullWidth
                            error={!!error('business_information.telephone')}
                            name="telephone"
                            label="No. Telp"
                            placeholder="No. Telp"
                            value={telephone}
                            helperText={error('business_information.telephone')}
                            variant="outlined"
                            onChange={onUpdateField}
                        />
                    </Grid>
                    <Grid item xs>
                        <PhoneFormat
                            fullWidth
                            error={!!error('business_information.fax')}
                            name="fax"
                            label="No. FAX"
                            placeholder="No. FAX"
                            value={fax}
                            helperText={error('business_information.fax')}
                            variant="outlined"
                            onChange={onUpdateField}
                        />
                    </Grid>
                </Grid> 
                <Grid item>
                    <Autocomplete
                        fullWidth
                        onClose={() => {
                            dispatch(initializeActions.onUnloadAreas());
                        }}
                        isOptionEqualToValue={(option, value) => true}
                        getOptionLabel={(option) => option.name}
                        options={areaOptions}
                        noOptionsText={!!inputValue && areas.length === 0 && !value ? 'Data tidak ditemukan.' : 'Silahkan masukan kode pos.'}
                        loading={areasFetching}
                        value={value}
                        onChange={(event, newValue) => {
                            onSelectArea(newValue)
                        }}
                        onInputChange={(event, newInputValue) => {
                            setInputValue(newInputValue);
                            if (newInputValue.length < 5 || newInputValue.length > 5) {
                                return;
                            }
                            dispatch(initializeActions.onLoadAreas(newInputValue));
                        }}
                        inputValue={inputValue}
                        renderOption={(props, option, { selected }) => (
                            <li {...props} style={{backgroundColor: "#fff"}}>
                                {option.name}
                            </li>
                        )}
                        renderInput={(params) => (
                            <TextField
                            {...params}
                            error={!!error('business_information.state')}
                            helperText={error('business_information.state')}
                            label="Provinsi, Kota, Kecamatan, Kode Pos"
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                <React.Fragment>
                                    {areasFetching ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                                ),
                            }}
                            />
                        )}
                        />
                </Grid>
                <Grid item>
                    <TextField
                        fullWidth
                        error={!!error('business_information.address')}
                        helperText={error('business_information.address')}
                        rows={5}
                        multiline
                        name="address"
                        placeholder="Alamat"
                        value={address}
                        variant="outlined"
                        onChange={onUpdateField}
                    />
                </Grid> 
            </Grid>

        </Grid>
    )
}