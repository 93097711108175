import * as types from './actionTypes';
import { axiosClient } from '../api';
import { validate } from '../components/Helper/Validation';

export const onLoadOrders = (query) => {
	const fetch = axiosClient.get(`/orders`,
	{
		params: query
	});
	return {
		type: types.ORDERS_LOADED,
		payload: fetch
	}
}

export const onLoadOrder = (id) => {
	// const fetch = axiosClient.get(`/orders/${"6561ed961e769e12e9dbb63e"}/schedule-on-due`);
	const fetch = axiosClient.get(`/orders/${id}`);
	return {
		type: types.ORDER_LOADED,
		payload: fetch
	}
}

export const onUnloadOrder = (id) => {
	return {
		type: types.ORDER_UNLOADED
	}
}

export const onShowPayments = (id) => {
	return {
		type: types.ORDER_PAYMENTS_SHOWED
	}
}

export const onHidePayments = () => {
	return {
		type: types.ORDER_PAYMENTS_HIDED
	}
}

export const onLoadPayments = (id) => {
	const fetch = axiosClient.get(`/orders/${id}/payments`);
	return {
		type: types.ORDER_PAYMENTS_LOADED, 
		payload: fetch
	}
}

export const onCollapsePayments = (payments) => {
	return {
		type: types.ORDER_PAYMENTS_COLLAPSED, 
		payments
	}
}

export const onShowCanceleds = () => {
	return {
		type: types.ORDER_CANCELEDS_SHOWED
	}
}

export const onHideCanceleds = () => {
	return {
		type: types.ORDER_CANCELEDS_HIDED
	}
}

export const onLoadCanceleds = (id) => {
	const fetch = axiosClient.get(`/orders/${id}/canceleds`);
	return {
		type: types.ORDER_CANCELEDS_LOADED, 
		payload: fetch
	}
}

export const onCollapseCanceleds = (order_canceleds) => {
	return {
		type: types.ORDER_CANCELEDS_COLLAPSED, 
		order_canceleds
	}
}

export const onShowOrderDeliveries = () => {
	return {
		type: types.ORDER_DELIVERIES_SHOWED
	}
}

export const onHideOrderDeliveries = () => {
	return {
		type: types.ORDER_DELIVERIES_HIDED
	}
}

export const onLoadOrderDeliveries = (id) => {
	const fetch = axiosClient.get(`/orders/${id}/deliveries`);
	return {
		type: types.ORDER_DELIVERIES_LOADED, 
		payload: fetch
	}
}

export const onCollapseOrderDeliveries = (order_deliveries) => {
	return {
		type: types.ORDER_DELIVERIES_COLLAPSED, 
		order_deliveries
	}
}