import * as types from './actionTypes';
import { axiosClient } from '../api';
import { validate } from '../components/Helper/Validation';

export const onLoadOrderDeliveries = (query) => {
	const fetch = axiosClient.get(`/order-deliveries`,
	{
		params: query
	});
	return {
		type: types.ORDER_DELIVERIES_LOADED,
		payload: fetch
	}
}

export const onLoadOrderDelivery = (id) => {
	const fetch = axiosClient.get(`/order-deliveries/${id}`);
	return {
		type: types.ORDER_DELIVERY_LOADED,
		payload: fetch
	}
}


export const onUnloadOrderDelivery = (id) => {
	return {
		type: types.ORDER_DELIVERY_UNLOADED
	}
}

export const onUpdateOrderDelivery = (order_delivery) => {
	return {
		type: types.ORDER_DELIVERY_UPDATED,
		order_delivery
	}
}

export const onShowConfirm = (action_type) => {
	return {
		type: types.ORDER_DELIVERY_CONFIRM_SHOWED,
		action_type
	}
}

export const onHideConfirm = () => {
	return {
		type: types.ORDER_DELIVERY_CONFIRM_HIDED
	}
}

export const onCanceled = (id, order_delivery) => {
	const fetch = axiosClient.put(`/order-deliveries/${id}/canceled`, {order_delivery});
	return {
		type: types.ORDER_DELIVERY_EDITED, 
		payload: fetch
	}
}

export const onDelivered = (id, order_delivery) => {
	const fetch = axiosClient.put(`/order-deliveries/${id}/delivered`, {order_delivery});
	return {
		type: types.ORDER_DELIVERY_EDITED, 
		payload: fetch
	}
}