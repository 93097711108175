import React from 'react';
import {Box, 
        Grid,
        Typography} from '@mui/material';
import { styled } from '@mui/material/styles';
import deviceImg from '../../../assets/images/home/features/device.png';

export default function Hardware() {
   
    return (
        <SectionBox>
            <Grid container alignItems="center" spacing={6}>
                <Grid item container xs={12} sm={7}>
                    <Box>
                        <ImageWrapperBox>
                            <ImageBox component="img" src={deviceImg} />
                        </ImageWrapperBox>
                    </Box>
                </Grid>
                <Grid item container xs={12} sm={5}>
                    <TextBox>
                        <Typography
                            fontWeight={500}
                            variant="h5">
                            Dukungan Perangkat
                        </Typography>
                        <H3Typography
                            fontWeight={500}
                            variant="h3"
                            color="primary">
                            Konektivitas tanpa batas.
                        </H3Typography>
                        <Typography
                            fontWeight={500}
                            variant="h6">
                            Livin POS memberikan solusi untuk kebutuhan bisnis Anda. Hubungi kami untuk menghubungkan perangkat yang Anda inginkan.
                        </Typography>
                    </TextBox>
                </Grid>
            </Grid>
        </SectionBox>
    )

    
}

const SectionBox = styled(Box)(({theme}) => ({
    paddingLeft: 75,
    paddingRight: 75,
    paddingTop: 135,
    marginBottom: -175,
    [theme.breakpoints.only('xs')]: {
        paddingLeft: 15,
        paddingRight: 15,
        paddingTop: 45,
        marginBottom: '-20%'
    },
    [theme.breakpoints.only('sm')]: {
        paddingLeft: 15,
        paddingRight: 15,
        marginBottom: '-20%'
    },
    [theme.breakpoints.between('sm', 'lg')]: {
        paddingLeft: 25,
        paddingRight: 25,
        paddingTop: 35,
        marginBottom: '-20%'
    },
    [theme.breakpoints.up('xl')]: {
        paddingLeft: 'calc((100% - 1284px)/2)',
        paddingRight: 'calc((100% - 1284px)/2)'
    },
    position: 'relative',
    zIndex: 99
}));


const ImageWrapperBox = styled(Box)(({theme}) => ({
    padding: 30,
    backgroundColor: 'rgb(255, 255, 255)',
    borderRadius: 8,
    boxShadow: 'rgb(140 152 164 / 13%) 0px 6px 24px 0px',
    [theme.breakpoints.between('xs','sm')]: {
       padding: 15,
       marginBottom: 15
    },
}));


const H3Typography = styled(Typography)(({theme}) => ({
    marginTop: 35,
    marginBottom: 20
}));



const ImageBox = styled(Box)(({theme}) => ({
    width: '100%'
}));

const TextBox = styled(Box)(({theme}) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
}));
