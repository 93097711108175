import React from 'react';
import { 
    Typography,
    Box,
    Grid,
    Paper,
    IconButton,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from 'react-redux';
import * as customerActions from '../../../../../../actions/customerActions';
import { useSelector, shallowEqual } from 'react-redux';
import { LaunchRounded } from '@mui/icons-material';
import moment from 'moment';
import 'moment/locale/id' 
import Edit from './Edit';

export default function Account() {
    const dispatch = useDispatch();
    const {customer} = useSelector(state => ({
        ...state.customer
    }), shallowEqual); 

    function onEdit() {
        dispatch(customerActions.onShowEdit());
    }

    const { first_name, last_name, date_of_birth, gender, customer_group } = customer;
    
    return (
        <Paper elevation={3} style={{padding: 25, width: '100%'}}>
             <Grid container direction="column" spacing={3}>
                <Grid item xs container>
                    <Grid item xs>
                        <Typography variant="h6" fontSize={16} fontWeight={700}>
                            Akun Pelanggan
                        </Typography>
                    </Grid>
                    <Grid item>
                        <IconButton size="small" onClick={onEdit}>
                            <LaunchRounded fontSize="small" color="primary" />
                        </IconButton>
                    </Grid>
                </Grid>
                <Grid item container direction="column" spacing={1}>
                    <Grid item container justifyContent="space-between">
                        <Typography variant="body2" color="textSecondary">
                            Nama Depan
                        </Typography>
                        <Typography component="div" fontWeight={500} variant="body2">
                            {first_name}
                        </Typography>
                    </Grid>
                    <Grid item container justifyContent="space-between">
                        <Typography variant="body2" color="textSecondary">
                            Nama Belakang
                        </Typography>
                        <Typography component="div" fontWeight={500} variant="body2">
                            {last_name}
                        </Typography>
                    </Grid>
                    <Grid item container justifyContent="space-between">
                        <Typography variant="body2" color="textSecondary">
                            Tanggal Lahir
                        </Typography>
                        <Typography component="div" fontWeight={500} variant="body2">
                            {moment(date_of_birth).format('LL')}
                        </Typography>
                    </Grid>
                    <Grid item container justifyContent="space-between">
                        <Typography variant="body2" color="textSecondary">
                            Jenis Kelamin
                        </Typography>
                        <Typography component="div" fontWeight={500} variant="body2">
                            {gender}
                        </Typography>
                    </Grid>
                    <Grid item container justifyContent="space-between">
                        <Typography variant="body2" color="textSecondary">
                            Grup Pelanggan
                        </Typography>
                        <Typography component="div" fontWeight={500} variant="body2">
                            {customer_group}
                        </Typography>
                    </Grid>
                </Grid>
             </Grid>
             <Edit />
        </Paper>
    )
}
