import React, { useState, useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import * as employeeActions from '../../../../../../actions/employeeActions';
import * as commonActions from '../../../../../../actions/commonActions';
import { 
    Alert,
    Paper,
    Grid,
    Typography,
    Box,
    TextField,
    FormControl,
    FormHelperText,
    IconButton,
    RadioGroup,
    Radio,
    FormControlLabel,
    Autocomplete,
    CircularProgress,
    LinearProgress,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import NumberFormat from 'react-number-format';
import {ImageRounded, DeleteForeverRounded} from '@mui/icons-material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';


const CustomFormat = React.forwardRef((props, ref) => (
    <NumberFormat
        {...props.other}
        onValueChange={(values) => {
          props.onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        customInput={TextField}
        placeholder={props.placeholder}
        value={props.value}
        helperText={props.helperText}
        error={props.error}
        fullWidth={props.fullWidth}
        allowLeadingZeros={true}
        allowNegative={false}
        allowedDecimalSeparators={false}
        isNumericString
      />
));
export default function Personal() {
    const dispatch = useDispatch();
    const {employee, gender_options, marital_statuses, blood_types, religion_options, errors, areas, areasFetching} = useSelector(state => ({
        ...state.employee,
    }), shallowEqual); 

    const [loaded, setLoaded] = useState(false);
    const [value, setValue] = useState(null);
    const [inputValue, setInputValue] = useState('');
    const [areaOptions, setAreaOptions] = useState([]);

    
    useEffect(() => {
        if (employee && employee.employee_profiles) {
                employee.employee_profiles.map((image, index) => {
                    if (image.onUpload && !image.uploading) {
                        dispatch(employeeActions.onUpload(image, index, (event) => {
                            image.progress = Math.round((event.loaded * 100) / event.total);
                            image.uploading = true;
                            dispatch(employeeActions.onUploading(image, index))
                        }));
                    }
                })
    
                employee.employee_profiles.map((image, index) => {
                    if (image.onRemove && !image._destroy) {
                        dispatch(employeeActions.onRemove(image, index));
                    }                
                })
        }

        if (employee && employee.employee_identities) {
            employee.employee_identities.map((image, index) => {
                if (image.onUpload && !image.uploading) {
                    dispatch(employeeActions.onIdentityUpload(image, index, (event) => {
                        image.progress = Math.round((event.loaded * 100) / event.total);
                        image.uploading = true;
                        dispatch(employeeActions.onIdentityUploading(image, index))
                    }));
                }
            })

            employee.employee_identities.map((image, index) => {
                if (image.onRemove && !image._destroy) {
                    dispatch(employeeActions.onIdentityRemove(image, index));
                }                
            })
        }

        function initialize() {
            const { state, city, district, code_area } = employee;
            if (!!state && !!city && !!district && !!code_area) {
                const newOptions = [];
                const selectedOption = {value: `${state}, ${city}, ${district}, ${code_area}`, name: `${state}, ${city}, ${district}, ${code_area}`};
                newOptions.push(selectedOption);
                setAreaOptions(newOptions)
                setValue(selectedOption);
            }
        }
        if (!loaded) {
            initialize();
            setLoaded(true);
        }
    },[employee, loaded]);
    
    useEffect(() => {
        setAreaOptions(areas)
    }, [areas]);
    
    function onUpdateField(event) {
		const key = event.target.name;
        const value = event.target.value;
        const newEmployee = {...employee};
        newEmployee[key] = value;
        dispatch(employeeActions.onUpdateEmployee(newEmployee));
    }

    function onSelectArea(option) {
        if (!option) {
            return;
        }
        setValue(option);
        const newEmployee = {...employee};
        const selectedArea = option.value.split(', ');
        newEmployee.state = selectedArea[0];
        newEmployee.city = selectedArea[1];
        newEmployee.district = selectedArea[2];
        newEmployee.code_area = selectedArea[3];
        dispatch(employeeActions.onUpdateEmployee(newEmployee));
    }



    function onSelectImage(event) {
        event.preventDefault();
        const files = event.target.files;
        
        let file = files[0]
        const reader = new FileReader();
        reader.onloadend = () => {
            dispatch(employeeActions.onUploadRequest(reader.result));
        }
        reader.readAsDataURL(file);
    }

    function onRemoveImage(image, index, event) {
        event.preventDefault();
        dispatch(employeeActions.onRemoveRequest(image, index));
    }

    function onSelectIdentity(event) {
        event.preventDefault();
        const files = event.target.files;
        
        let file = files[0]
        const reader = new FileReader();
        reader.onloadend = () => {
            dispatch(employeeActions.onIdentityUploadRequest(reader.result));
        }
        reader.readAsDataURL(file);
    }

    function onRemoveIdentity(image, index, event) {
        event.preventDefault();
        dispatch(employeeActions.onIdentityRemoveRequest(image, index));
    }

    const onSelectDate = (date) => {
        const newEmployee = {...employee};
        newEmployee.date_of_birth = date;
        dispatch(employeeActions.onUpdateEmployee(newEmployee));
    };

    function onSelectMarital(option) {
        const newEmployee = {...employee};
        newEmployee.marital_status_id = option._id;
        dispatch(employeeActions.onUpdateEmployee(newEmployee));
    }

    function onSelectBloodType(option) {
        const newEmployee = {...employee};
        newEmployee.blood_type_id = option._id;
        dispatch(employeeActions.onUpdateEmployee(newEmployee));
    }

    function onSelectReligion(option) {
        const newEmployee = {...employee};
        newEmployee.religion_option_id = option._id;
        dispatch(employeeActions.onUpdateEmployee(newEmployee));
    }

    const onKeyDown = (e) => {
        e.preventDefault();
    };

    function error(key) {
        if (errors != null && errors[key] != null) {
            return errors[key][0]
        }

        return null;
    }

    const { employee_profiles, email, first_name, last_name, phone, date_of_birth, gender_option_id, address, employee_identities, identity_number, marital_status_id, religion_option_id, blood_type_id } = employee;

    const maritalValue = marital_statuses.find(opt => opt._id === marital_status_id)
    const bloodValue = blood_types.find(opt => opt._id === blood_type_id)
    const religionValue = religion_options.find(opt => opt._id === religion_option_id)

    return (
       
        <Grid container spacing={6} direction="column">
            <Grid item container>
                <Grid item xs>
                    <Typography fontWeight={700} variant="h6" lineheight="normal">
                        Informasi Pengguna
                    </Typography>
                </Grid>
                <Grid item>
                    <Alert severity="error">
                        Wajib
                    </Alert>
                </Grid>
            </Grid>
            <Grid item container direction="column" spacing={5}>
                <Grid item container spacing={8}>
                    <Grid item xs={4}>
                        <Typography fontSize={16} fontWeight={700} component="div">
                            Foto Pengguna
                        </Typography>
                        <Typography variant="body2" gutterBottom color="textSecondary">
                            Masukan gambar pengguna, foto pengguna harus dan menghadap depan dan terlihat jelas.
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        { 
                            employee_profiles.map((profile, index) => {
                                if (profile._destroy) {
                                    return null;
                                }
                                if (profile.image && profile.onUpload && profile.progress !== 100) {
                                    return (
                                        <ImageAttachedPaper key={index} >
                                            <ImagePreviewBox>
                                                <Box component="img" alt={profile.image} src={profile.image} />
                                                <Box sx={{ width: '80%', position: 'absolute', bottom: '15px' }}>
                                                    <LinearProgress sx={{height: '8px', borderRadius: '35px'}} variant="determinate" value={profile.progress || 0} />
                                                </Box>
                                            </ImagePreviewBox>
                                        </ImageAttachedPaper>
                                    )
                                }
                                if (profile.image && profile.onRemove) {
                                    return (
                                        <ImageAttachedPaper key={index} >
                                            <ImagePreviewBox>
                                                <Box component="img" alt={profile.image} src={profile.image} />
                                                <Box sx={{
                                                    position: 'absolute', 
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    width: '100%',
                                                    height: '100%'}}>
                                                    <Box sx={{
                                                        position: 'absolute',
                                                        background: '#fff',
                                                        opacity: 0.45,
                                                        width: '100%',
                                                        height: '100%'
                                                     }} />
                                                    <Box sx={{ display: 'flex' }}>
                                                        <CircularProgress />
                                                    </Box>
                                                </Box>
                                            </ImagePreviewBox>
                                        </ImageAttachedPaper>
                                    )
                                }
                                if (profile.image) {
                                    return (
                                        <ImageAttachedPaper key={index} >
                                            <ImagePreviewBox>
                                                <DeleteIconButton size="small" 
                                                     color="inherit" aria-label="menu" onClick={onRemoveImage.bind(this, profile, index)} >
                                                    <DeleteForeverRounded style={{ color: '#999' }} />
                                                </DeleteIconButton>
                                                <Box component="img" alt={profile.image} src={profile.image} />
                                            </ImagePreviewBox>
                                        </ImageAttachedPaper>
                                    )
                                }
                                return (
                                    <ImagePaper key={index}>
                                        <ImageEmptyBox error={!!error('employee_profiles.image')}>
                                            <ImageRounded
                                                style={{ color: '#999', fontSize: 50 }} />
                                                <input type="file" multiple={true} accept="image/*" onChange={onSelectImage.bind(this)}/>
                                        </ImageEmptyBox>
                                    </ImagePaper>
                                )
                            })
                        }
                    </Grid> 
                </Grid>
               <Grid item container spacing={8}>
                    <Grid item xs={4}>
                        <Typography fontSize={16} fontWeight={700} component="div">
                            Email
                        </Typography>
                        <Typography variant="body2" gutterBottom color="textSecondary">
                            Masukan email karyawan.
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <TextField
                            fullWidth
                            error={!!error('email')}
                            name="email"
                            placeholder="Email"
                            value={email}
                            helperText={error('email')}
                            variant="outlined"
                            onChange={onUpdateField}
                        />
                    </Grid> 
                </Grid>
                <Grid item container spacing={8}>
                    <Grid item xs={4}>
                        <Typography fontSize={16} fontWeight={700} component="div">
                            Nama Pengguna
                        </Typography>
                        <Typography variant="body2" gutterBottom color="textSecondary">
                            Masukan nama depan dan nama belakang pengguna.
                        </Typography>
                    </Grid>
                    <Grid item xs={8} container spacing={3}>
                        <Grid item xs>
                            <TextField
                                fullWidth
                                error={!!error('first_name')}
                                name="first_name"
                                placeholder="Nama Depan"
                                value={first_name}
                                helperText={error('first_name')}
                                variant="outlined"
                                onChange={onUpdateField}
                            />
                        </Grid>
                        <Grid item xs>
                            <TextField
                                fullWidth
                                error={!!error('last_name')}
                                name="last_name"
                                placeholder="Nama Belakang"
                                value={last_name}
                                helperText={error('last_name')}
                                variant="outlined"
                                onChange={onUpdateField}
                            />
                        </Grid>
                    </Grid> 
                </Grid>
                <Grid item container spacing={8}>
                    <Grid item xs={4}>
                        <Typography fontSize={16} fontWeight={700} component="div">
                            No. HP
                        </Typography>
                        <Typography variant="body2" gutterBottom color="textSecondary">
                            Masukan kontak / No. HP karyawan.
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <CustomFormat
                            fullWidth
                            error={!!error('phone')}
                            name="phone"
                            placeholder="No. HP"
                            value={phone}
                            helperText={error('phone')}
                            variant="outlined"
                            onChange={onUpdateField}
                        />
                    </Grid> 
                </Grid>
                <Grid item container spacing={8}>
                    <Grid item xs={4}>
                        <Typography fontSize={16} fontWeight={700} component="div">
                            Foto KTP / Identitas
                        </Typography>
                        <Typography variant="body2" gutterBottom color="textSecondary">
                            Masukan gambar indentitas pengguna.
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        { 
                            employee_identities.map((identity, index) => {
                                if (identity._destroy) {
                                    return null;
                                }
                                if (identity.image && identity.onUpload && identity.progress !== 100) {
                                    return (
                                        <ImageAttachedPaper key={index} >
                                            <ImagePreviewBox>
                                                <Box component="img" alt={identity.image} src={identity.image} />
                                                <Box sx={{ width: '80%', position: 'absolute', bottom: '15px' }}>
                                                    <LinearProgress sx={{height: '8px', borderRadius: '35px'}} variant="determinate" value={identity.progress || 0} />
                                                </Box>
                                            </ImagePreviewBox>
                                        </ImageAttachedPaper>
                                    )
                                }
                                if (identity.image && identity.onRemove) {
                                    return (
                                        <ImageAttachedPaper key={index} >
                                            <ImagePreviewBox>
                                                <Box component="img" alt={identity.image} src={identity.image} />
                                                <Box sx={{
                                                    position: 'absolute', 
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    width: '100%',
                                                    height: '100%'}}>
                                                    <Box sx={{
                                                        position: 'absolute',
                                                        background: '#fff',
                                                        opacity: 0.45,
                                                        width: '100%',
                                                        height: '100%'
                                                     }} />
                                                    <Box sx={{ display: 'flex' }}>
                                                        <CircularProgress />
                                                    </Box>
                                                </Box>
                                            </ImagePreviewBox>
                                        </ImageAttachedPaper>
                                    )
                                }
                                if (identity.image) {
                                    return (
                                        <ImageAttachedPaper key={index} >
                                            <ImagePreviewBox>
                                                <DeleteIconButton size="small" 
                                                    color="inherit" aria-label="menu" onClick={onRemoveIdentity.bind(this, identity, index)} >
                                                    <DeleteForeverRounded style={{ color: '#999' }} />
                                                </DeleteIconButton>
                                                <Box component="img" alt={identity.image} src={identity.image} />
                                            </ImagePreviewBox>
                                        </ImageAttachedPaper>
                                    )
                                }
                                return (
                                    <ImagePaper key={index}>
                                         <ImageEmptyBox error={!!error('employee_identities.image')}>
                                            <ImageRounded
                                                style={{ color: '#999', fontSize: 50 }} />
                                                <input type="file" multiple={true} accept="image/*" onChange={onSelectIdentity.bind(this)}/>
                                        </ImageEmptyBox>
                                    </ImagePaper>
                                )
                            })
                        }
                    </Grid> 
                </Grid>
                <Grid item container spacing={8}>
                    <Grid item xs={4}>
                        <Typography fontSize={16} fontWeight={700} component="div">
                            No. KTP / Identitas
                        </Typography>
                        <Typography variant="body2" gutterBottom color="textSecondary">
                            Masukan nomor KTP / Identitas yang sesuai dengan foto KTP / Identitas.
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <CustomFormat
                            fullWidth
                            error={!!error('identity_number')}
                            name="identity_number"
                            placeholder="No. KTP / Identitas"
                            value={identity_number}
                            helperText={error('identity_number')}
                            variant="outlined"
                            onChange={onUpdateField}
                        />
                    </Grid> 
                </Grid>
                <Grid item container spacing={8}>
                    <Grid item xs={4}>
                        <Typography fontSize={16} fontWeight={700} component="div">
                            Tanggal Lahir
                        </Typography>
                        <Typography variant="body2" gutterBottom color="textSecondary">
                            Masukan tanggal lahir pengguna.
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DatePicker
                                openTo="year"
                                disableFuture
                                value={date_of_birth}
                                format="dd/MM/yyyy"
                                onChange={onSelectDate}
                                renderInput={(params) => <TextField onKeyDown={onKeyDown} {...params} />}
                            />
                        </LocalizationProvider>
                    </Grid> 
                </Grid>
                <Grid item container spacing={8}>
                    <Grid item xs={4}>
                        <Typography fontSize={16} fontWeight={700} component="div">
                            Jenis Kelamin
                        </Typography>
                        <Typography variant="body2" gutterBottom color="textSecondary">
                            Pilih jenis kelamin pengguna.
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <FormControl component="fieldset" error={!!error('gender_option_id')}>
                            <RadioGroup row name="gender_option_id" value={gender_option_id} onChange={onUpdateField}>
                                {
                                    gender_options.map((option, index) => {
                                        return (
                                            <FormControlLabel key={index} value={option._id} control={<Radio />} label={option.name} />
                                        )
                                    })
                                }
                            </RadioGroup>
                            {!!error('gender_option_id') && <FormHelperText>{error('gender_option_id')}</FormHelperText> }
                        </FormControl>
                    </Grid> 
                </Grid>
                <Grid item container spacing={8}>
                    <Grid item xs={4}>
                        <Typography fontSize={16} fontWeight={700} component="div">
                            Status Perkawinan
                        </Typography>
                        <Typography variant="body2" gutterBottom color="textSecondary">
                            Pilih status perkawinan.
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Autocomplete
                            options={marital_statuses.sort((a, b) => -b.name.localeCompare(a.name))}
                            getOptionLabel={(option) => option.name}
                            selectOnFocus
                            clearOnBlur
                            filterSelectedOptions
                            disableClearable
                            onChange={(event, option) => {
                                onSelectMarital(option)
                            }}
                            value={maritalValue ? maritalValue : null}
                            renderInput={(params) => <TextField {...params} error={!!error('marital_status_id')} helperText={error('marital_status_id')} placeholder="Pilih Status Perkawinan" variant="outlined" />}
                        />
                    </Grid> 
                </Grid>
                <Grid item container spacing={8}>
                    <Grid item xs={4}>
                        <Typography fontSize={16} fontWeight={700} component="div">
                            Golongan Darah
                        </Typography>
                        <Typography variant="body2" gutterBottom color="textSecondary">
                            Pilih golongan darah.
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Autocomplete
                            options={blood_types.sort((a, b) => -b.name.localeCompare(a.name))}
                            getOptionLabel={(option) => option.name}
                            selectOnFocus
                            clearOnBlur
                            filterSelectedOptions
                            disableClearable
                            onChange={(event, option) => {
                                onSelectBloodType(option)
                            }}
                            value={bloodValue ? bloodValue : null}
                            renderInput={(params) => <TextField {...params} error={!!error('blood_type_id')} helperText={error('blood_type_id')} placeholder="Pilih Golongan Darah" variant="outlined" />}
                        />
                    </Grid> 
                </Grid>
               <Grid item container spacing={8}>
                    <Grid item xs={4}>
                        <Typography fontSize={16} fontWeight={700} component="div">
                            Agama
                        </Typography>
                        <Typography variant="body2" gutterBottom color="textSecondary">
                            Pilih agama.
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Autocomplete
                            options={religion_options.sort((a, b) => -b.name.localeCompare(a.name))}
                            getOptionLabel={(option) => option.name}
                            selectOnFocus
                            clearOnBlur
                            filterSelectedOptions
                            disableClearable
                            onChange={(event, option) => {
                                onSelectReligion(option)
                            }}
                            value={religionValue ? religionValue : null}
                            renderInput={(params) => <TextField {...params} error={!!error('religion_option_id')} helperText={error('religion_option_id')} placeholder="Pilih Agama" variant="outlined" />}
                        />
                    </Grid> 
                </Grid>
                <Grid item container spacing={8}>
                    <Grid item xs={4}>
                        <Typography fontSize={16} fontWeight={700} component="div">
                            Provinsi, Kota, Kecamatan
                        </Typography>
                        <Typography variant="body2" gutterBottom color="textSecondary">
                            Masukan wilayah supplier beserta kodepos.
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Autocomplete
                            fullWidth
                            onClose={() => {
                                dispatch(commonActions.onUnloadAreas());
                            }}
                            isOptionEqualToValue={(option, value) => true}
                            getOptionLabel={(option) => option.name}
                            options={areaOptions}
                            noOptionsText={!!inputValue && areas.length === 0 && !value ? 'Data tidak ditemukan.' : 'Silahkan masukan kode pos.'}
                            loading={areasFetching}
                            value={value}
                            onChange={(event, newValue) => {
                                onSelectArea(newValue)
                            }}
                            onInputChange={(event, newInputValue) => {
                                setInputValue(newInputValue);
                                if (newInputValue.length < 5 || newInputValue.length > 5) {
                                    return;
                                }
                                dispatch(commonActions.onLoadAreas(newInputValue));
                            }}
                            renderOption={(props, option, { selected }) => (
                                <li {...props} style={{backgroundColor: "#fff"}}>
                                    {option.name}
                                </li>
                            )}
                            inputValue={inputValue}
                            renderInput={(params) => (
                                <TextField
                                {...params}
                                error={!!error('state')}
                                helperText={error('state')}
                                placeholder="Provinsi, Kota, Kecamatan, Kode Pos"
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                    <React.Fragment>
                                        {areasFetching ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                    ),
                                }}
                                />
                            )}/>
                    </Grid> 
                </Grid>
                <Grid item container spacing={8}>
                    <Grid item xs={4}>
                        <Typography fontSize={16} fontWeight={700} component="div">
                            Alamat
                        </Typography>
                        <Typography variant="body2" gutterBottom color="textSecondary">
                            Masukan alamat supplier.
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <TextField
                            fullWidth
                            error={!!error('address')}
                            rows={6}
                            multiline
                            name="address"
                            placeholder="Alamat"
                            value={address}
                            helperText={error('address')}
                            variant="outlined"
                            onChange={onUpdateField}
                        />
                    </Grid> 
                </Grid>
            </Grid>
        </Grid>
    )
}


const ImageAttachedPaper = styled(Paper)(({theme}) => ({
    width: 141,
    height: 141,
    padding: 3,
    position: 'relative',
    '&:hover': {
        "& button": {
            visibility: 'visible'
        }
    }
}));

const ImagePaper = styled(Paper)(({theme}) => ({
    padding: 10,
    width: 127,
    height: 127,
    position: 'relative'
}));

const DeleteIconButton = styled(IconButton)(({theme}) => ({
    background: '#ddd',
    position: 'absolute',
    right: 10,
    top: 10,
    width: 30,
    height: 30,
    visibility: 'hidden'
}));

const ImagePreviewBox = styled(Box)(({theme}) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    '& img': {
        width: '100%'
    }
}));

const ImageEmptyBox = styled(Box, {
    shouldForwardProp: (prop) => prop !== "error",
  })(({ theme, error }) => ({
    border: error ? '1px dashed #f44336' : '1px dashed #999',
    height: 125,
    width: 125,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 3,
    position: 'relative',
    "& input[type='file']": {
        bottom: 0,
        cursor: 'pointer',
        fontSize: '10em',
        height: '100%',
        left: 0,
        opacity: 0,
        position: 'absolute',
        right: 0,
        top: 0,
        width: '100%',
        zIndex: 3
    },

}));

  