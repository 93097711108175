import * as types from '../actions/actionTypes';

const defaultState = {
}

export default function (state = defaultState, action) {
	switch(action.type) {
		case types.INVENTORIES_LOADED:
			return {
				...state,
				stores: action.payload ? action.payload.stores : null,
				inventories: action.payload ? action.payload.inventories : null,
				inventories_summary: action.payload ? action.payload.inventories_summary : null,
				inventories_count: action.payload ? action.payload.inventories_count : null,
				onInventoryHistories: false,
            };
		case types.INVENTORIES_COLLAPSED:
			return {
				...state,
				inventories: action.inventories
			}; 
		case types.INVENTORY_HISTORIES_SHOWED:
			return {
				...state,
				onInventoryHistories: true,
				inventory_histories: null,
				inventory: action.inventory
			};
		case types.INVENTORY_HISTORIES_HIDED:
			return {
				...state,
				onInventoryHistories: false,
				inventory_histories: null,
				inventory: null
			}; 
		case types.INVENTORY_HISTORIES_LOADED:
			return {
				...state,
				// stores: action.payload ? action.payload.stores : null,
				inventory_histories: action.payload ? action.payload.inventory_histories : null,
				inventory_histories_count: action.payload ? action.payload.inventory_histories_count : null,				
            };
		default:
			return state;
	}
}