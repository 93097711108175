import * as types from '../actions/actionTypes';

const defaultState = {
    createNewUser: false
}

export default function login (state = defaultState, action) {
	switch(action.type) {
		case types.USER_LOGIN_LOADED:
            return {
                ...state,
                user: {
                    email_phone: "",
                    password: ""
                },
                createNewUser: false,
			};
        case types.USER_LOGIN_UNLOADED:
            return {
                ...state,
                user: {
                    email_phone: "",
                    password: ""
                },
                userExist: false,
                createNewUser: false,
                errors: null
			};
        case types.USER_LOGIN_CANCELED:
            return {
                ...state,
                userExist: false
			};
        case types.USER_LOGIN_VALIDATION_FIELD_UPDATED:
            return {
                ...state,
                user: { ...state.user_validation, [action.key]: action.value }
			};
        case types.USER_LOGIN_FIELD_UPDATED:
            return {
                ...state,
                user: { ...state.user, [action.key]: action.value }
            };
        case types.USER_LOGIN_VALIDATION:
            return {
                ...state,
                createNewUser: action.error ? false : !action.payload.user_exist,
                userExist: action.payload.user_exist,
                errors: action.error ? action.payload.errors : null,
                user: action.error ? state.user : {...action.payload.user, password: ''},
                inProgress: false
			};
        case types.NEW_USER_CANCELED:
            return {
                ...state,
                createNewUser: false
			};
        case types.USER_LOGGED_IN:
            return {
                ...state,
                user_token: action.payload.user_token,
                errors: action.error ? action.payload.errors : null,
                inProgress: false
			};
        case types.USER_LOGIN_EXISTED:
            return {
                ...state,
                user: {...action.user, password: ''},
                userExist: true,
			};
        case types.ASYNC_START:
            if ( action.subtype === types.USER_LOGIN_VALIDATION || action.subtype === types.USER_LOGGED_IN
                ) {
                return { 
                    ...state, 
                    inProgress: true };
            };
            return state;
		default:
			return state;
	}
}