import React from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import * as employeeActions from '../../../../../../../../actions/employeeActions';
import { 
    Typography,
    Grid,
    Button,
    Dialog,
    DialogContent,
    Box
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useParams } from "react-router-dom";
import Edit from './Edit';
import Loader from '../../../../../../../Helper/Loader';
import moment from 'moment';
import 'moment/locale/id' 

  
export default function PersonalInformation() {
    let { id } = useParams();
    const dispatch = useDispatch();
    const { employee, editPersonalInformation, onEditing } = useSelector(state => ({
        ...state.employee
    }), shallowEqual);
    const [open, setOpen] = React.useState(false);

    const handleView = () => {
        setOpen(true);
      };
    
    const handleClose = () => {
        setOpen(false);
    };
    

    const {email, full_name, phone, identity, identity_number, date_of_birth, gender, marital_status, blood_type, religion, address, state } = employee;

    function onEdit() {
        dispatch(employeeActions.onEditPersonalInformation(id))
    };

    if (onEditing) {
        return (
            <Box sx={{ minHeight: '500px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Loader />
            </Box>
        )
    }

    if (editPersonalInformation) {
        return <Edit />;
    }
    return (
        <Grid container spacing={4} direction="column">
            <Grid item container>
                <Grid item xs>
                    <Typography fontWeight={700} component="h6" lineheight="normal">
                        Informasi Pribadi
                    </Typography>
                </Grid>
                <Grid item>
                    <Button onClick={onEdit}>
                        Ubah
                    </Button>
                </Grid>
            </Grid>
            <Grid item container direction="column" alignItems="center" spacing={3}>
                <Grid item container spacing={8}>
                    <Grid item xs={4}>
                        <Typography fontSize={16} fontWeight={700} component="div">
                            Email
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography fontSize={16} fontWeight={700} component="div">
                            {email}
                        </Typography>
                    </Grid> 
                </Grid>
                <Grid item container spacing={8}>
                    <Grid item xs={4}>
                        <Typography fontSize={16} fontWeight={700} component="div">
                            Nama Lengkap
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography fontSize={16} fontWeight={700} component="div">
                           {full_name}
                        </Typography>
                    </Grid> 
                </Grid>
                <Grid item container spacing={8}>
                    <Grid item xs={4}>
                        <Typography fontSize={16} fontWeight={700} component="div">
                            No. HP
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography fontSize={16} fontWeight={700} component="div">
                            {phone}
                        </Typography>
                    </Grid> 
                </Grid>
                <Grid item container alignItems="center" spacing={8}>
                    <Grid item xs={4}>
                        <Typography fontSize={16} fontWeight={700} component="div">
                            No. KTP/Passpor
                        </Typography>
                    </Grid>
                    <Grid item xs={8} alignItems="center" container spacing={3}>
                        <Grid item>
                            <Typography fontSize={16} fontWeight={700} component="div">
                                {identity_number}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Button onClick={handleView}>
                                Lihat
                            </Button>
                        </Grid>
                    </Grid> 
                    <Dialog
                        fullWidth
                        maxWidth="xs"
                        open={open}
                        onClose={handleClose}>
                       
                        <DialogContent sx={{padding: 0}}>
                            <ImageBox component="img" src={identity} />
                        </DialogContent>
                    </Dialog>
                </Grid>
                <Grid item container spacing={8}>
                    <Grid item xs={4}>
                        <Typography fontSize={16} fontWeight={700} component="div">
                            Tanggal Lahir
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography fontSize={16} fontWeight={700} component="div">
                            {moment(date_of_birth).format('LL')}
                        </Typography>
                    </Grid> 
                </Grid>
                <Grid item container spacing={8}>
                    <Grid item xs={4}>
                        <Typography fontSize={16} fontWeight={700} component="div">
                            Jenis Kelamin
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography fontSize={16} fontWeight={700} component="div">
                            {gender}
                        </Typography>
                    </Grid> 
                </Grid>
                <Grid item container spacing={8}>
                    <Grid item xs={4}>
                        <Typography fontSize={16} fontWeight={700} component="div">
                            Status Pernikahan
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography fontSize={16} fontWeight={700} component="div">
                            {marital_status}
                        </Typography>
                    </Grid> 
                </Grid>
                <Grid item container spacing={8}>
                    <Grid item xs={4}>
                        <Typography fontSize={16} fontWeight={700} component="div">
                            Gol. Darah
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography fontSize={16} fontWeight={700} component="div">
                            {blood_type}
                        </Typography>
                    </Grid> 
                </Grid>
                <Grid item container spacing={8}>
                    <Grid item xs={4}>
                        <Typography fontSize={16} fontWeight={700} component="div">
                            Agama
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography fontSize={16} fontWeight={700} component="div">
                            {religion}
                        </Typography>
                    </Grid> 
                </Grid>
                <Grid item container spacing={8}>
                    <Grid item xs={4}>
                        <Typography fontSize={16} fontWeight={700} component="div">
                            Alamat
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Box sx={{display: 'flex', flexDirection: 'column'}}>
                            <Typography fontSize={16} fontWeight={700} component="div">
                                {address}
                            </Typography>
                            <Typography fontSize={16} fontWeight={700} component="div">
                                {state}
                            </Typography>
                        </Box>
                    </Grid> 
                </Grid>
            </Grid>
        </Grid>
    )
}

const ImageBox = styled(Box)(({theme}) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    maxHeight: '400px',
    width: '100%'
}));