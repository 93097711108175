import React, { useState, useEffect } from 'react';
import {
    Stepper,
    Step,
    StepConnector,
    StepLabel,
    Button,
    Paper,
    Grid,
    Box,
    Stack,
    CircularProgress
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { stepConnectorClasses } from '@mui/material/StepConnector';
import * as initializeActions from '../../../actions/initializeActions';
import { Check } from '@mui/icons-material';
import UserInformation from './UserInformation';
import BusinessInformation from './BusinessInformation';
import StoreInformation from './StoreInformation';

const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 10,
      left: 'calc(-50% + 16px)',
      right: 'calc(50% + 16px)',
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: '#784af4',
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: '#784af4',
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
      borderTopWidth: 3,
      borderRadius: 1,
    },
}));

const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    ...(ownerState.active && {
      color: '#784af4',
    }),
    '& .QontoStepIcon-completedIcon': {
      color: '#784af4',
      zIndex: 1,
      fontSize: 18,
    },
    '& .QontoStepIcon-circle': {
      width: 8,
      height: 8,
      borderRadius: '50%',
      backgroundColor: 'currentColor',
    },
}));


function QontoStepIcon(props) {
    const { active, completed, className } = props;
  
    return (
      <QontoStepIconRoot ownerState={{ active }} className={className}>
        {completed ? (
          <Check className="QontoStepIcon-completedIcon" />
        ) : (
          <div className="QontoStepIcon-circle" />
        )}
      </QontoStepIconRoot>
    );
}
  
function getSteps() {
    return ['Informasi Pengguna', 'Informasi Bisnis', 'Informasi Cabang'];
}
  
function getStepContent(step) {
    switch (step) {
      case 0:
        return <UserInformation />;
      case 1:
        return <BusinessInformation />;
      case 2:
        return <StoreInformation />;
      default:
        return 'Error!';
    }
}

export default function Initialize() {
    const dispatch = useDispatch();
    const { current_user, initialize_user, errors, inProgress } = useSelector(state => ({
        ...state.initialize,
        ...state.common
      }), shallowEqual); 

    const [activeStep, setActiveStep] = useState(0);
    const steps = getSteps();

    useEffect(() => {
        dispatch(initializeActions.onLoadInitialize(current_user._id));
        return () => {
            dispatch(initializeActions.onUnloadInitialize());
        };
    },[]);

    useEffect(() => {
      if (errors != null) {
          if (errors['gender'] != null) {
              return setActiveStep(0);
          }
          if (errors['business_information.address'] != null ||
              errors['business_information.business_type'] != null ||
              errors['business_information.city'] != null ||
              errors['business_information.code_area'] != null ||
              errors['business_information.distric'] != null ||
              errors['business_information.name'] != null ||
              errors['business_information.state'] != null ||
              errors['business_information.telephone'] != null ) {
              return setActiveStep(1);
          }
      } 
    },[errors]);
    
    const onNext = () => {
        let newErrors = {}
        if (activeStep === 0) {
            if (!initialize_user.gender_option_id || initialize_user.gender_option_id === '') {
              newErrors.gender_option_id = ['Jenis kelamin dibutuhkan']
            } else {
              delete newErrors[`gender_option_id`];
            }

            if (!initialize_user.date_of_birth) {
              newErrors.date_of_birth = ['Tgl. lahir dibutuhkan']
            } else {
              delete newErrors[`date_of_birth`];
            }

        }

        if (activeStep === 1) {
            if (initialize_user.business_information.name === '') {
              newErrors[`business_information.name`] = ['Nama dibutuhkan']
            }  else {
              delete newErrors[`business_information.name`];
            }
            if (!initialize_user.business_information.business_type_id || initialize_user.business_information.business_type_id === '') {
              newErrors[`business_information.business_type_id`] = ['Jenis usaha dibutuhkan']
            } else {
              delete newErrors[`business_information.business_type_id`];
            }
            if (initialize_user.business_information.telephone === '') {
              newErrors[`business_information.telephone`] = ['No.telp dibutuhkan']
            } else {
              delete newErrors[`business_information.telephone`];
            }
            if (initialize_user.business_information.state === '') {
              newErrors[`business_information.state`] = ['Provinsi, kota, kecamatan, dan kode pos dibutuhkan']
            } else {
              delete newErrors[`business_information.state`];
            }
            if (initialize_user.business_information.address === '') {
              newErrors[`business_information.address`] = ['Alamat usaha dibutuhkan']
            } else {
              delete newErrors[`business_information.address`];
            }
        }

        if (activeStep === 2) {
            if (initialize_user.store.name === '') {
              newErrors[`store.name`] = ['Nama dibutuhkan']
            } else {
              delete newErrors[`store.name`];
            }
            if (initialize_user.store.phone === '') {
              newErrors[`store.phone`] = ['No.hp dibutuhkan']
            }else {
              delete newErrors[`store.phone`];
            }
            if (initialize_user.store.telephone === '') {
              newErrors[`store.telephone`] = ['No.telp dibutuhkan']
            } else {
              delete newErrors[`store.telephone`];
            }
            if (initialize_user.store.state === '') {
              newErrors[`store.state`] = ['Provinsi, kota, kecamatan, dan kode pos dibutuhkan']
            } else {
              delete newErrors[`store.state`];
            }
            if (initialize_user.store.address === '') {
              newErrors[`store.address`] = ['Alamat cabang dibutuhkan']
            } else {
              delete newErrors[`store.address`];
            }
        }

        if (Object.keys(newErrors).length !== 0) {
          dispatch(initializeActions.onValidation(newErrors));
        } else if (activeStep === steps.length - 1) {
          dispatch(initializeActions.onInitialize(current_user._id, initialize_user));
        } else {
          dispatch(initializeActions.onValidation(newErrors));
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
       
    };

    const onBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    function Content() {
        if (!initialize_user) {
            return (
                <ContentPaper style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}} elevation={3}>
                    <CircularProgress />
                </ContentPaper>
            )
        }
        return (
            <ContentPaper elevation={3}>
                {getStepContent(activeStep)}
            </ContentPaper>
        )
    }
    return (
        <Box sx={{padding: '45px'}}>
            <Stack>
                <Stepper alternativeLabel activeStep={activeStep} connector={<QontoConnector />}>
                    {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
                    </Step>
                    ))}
                </Stepper>
            </Stack>
            <Stack justifyContent="center" alignItems="center" sx={{marginTop: '35px'}}>
                {Content()}
                <Box sx={{maxWidth: '745px', width: '100%', marginTop: '15px'}}>
                    <Grid container spacing={3} justifyContent="space-between">
                        <Grid item>
                            <BackButton 
                                variant="contained"
                                size="large"
                                disableElevation
                                disabled={activeStep === 0} 
                                onClick={onBack}
                                >
                                Kembali
                            </BackButton>
                        </Grid>
                        <Grid item>
                            <Button 
                                variant="contained"
                                size="large"
                                color="primary"
                                sx={{minWidth: 195}}
                                disabled={!initialize_user} 
                                onClick={onNext}
                                disableElevation>
                                  {inProgress ? <CircularProgress size={26} /> : activeStep === steps.length - 1 ? 'Selesai' : 'Lanjut'}  
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Stack>
        </Box>
    );
}

const ContentPaper = styled(Paper)(({theme}) => ({
    width: 695,
    padding: 25,
    height: 'calc(100vh - 315px)',
    marginBottom: 25,
    overflowX: 'auto'
}));

const BackButton = styled(Button)(({theme}) => ({
    color: theme.palette.text.primary,
    backgroundColor: "#FFFFFF",
    '&:hover': {
    backgroundColor: "#FFFFFF",
    },
    fontWeight: 500,
    border: '1px solid #ddd',
    minWidth: 195
}));