import * as types from './actionTypes';
import { axiosClient } from '../api';

export const onEmailVerification = (user) => {
	const fetch = axiosClient.post(`/user-verifications/email`, {user});
	return {
		type: types.USER_EMAIL_VERIFICATION,
		payload: fetch
	}
}

export const onMessageVerification = (user) => {
	const fetch = axiosClient.post(`/user-verifications/message`, {user});
	return {
		type: types.USER_MESSAGE_VERIFICATION,
		payload: fetch
	}
}

export const onUserVerification = (user) => {
	const fetch = axiosClient.post(`/user-verifications/verification`, {user});
	return {
		type: types.USER_VERIFICATION,
		payload: fetch,
		
	}
}