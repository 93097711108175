import React, { useState } from 'react';
import { 
    Box, 
    Fade,
    Button,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions,
    DialogContentText,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import * as commonActions from '../../../../actions/commonActions';
import Content from './Content';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Fade direction="up" ref={ref} {...props} />;
});

export default function Verification({verifyType, open}) {
    const dispatch = useDispatch();
    const { dialogInProgress } = useSelector(state => ({
        ...state.common,
    }), shallowEqual);


    const [dialogTitle, setDialogTitle] = useState("Metode Verifikasi");
    const [dialogText, setDialogText] = useState("Pilih salah satu metode untuk mendapatkan kode verifikasi.");

    function onClose() {
        setDialogTitle("Metode Verifikasi");
        setDialogText("Pilih salah satu metode untuk mendapatkan kode verifikasi.");
        dispatch(commonActions.onCancelVerification());
    }

    function onProgress() {
        if (dialogInProgress) {
            return (
                <ProgressBox>
                    <CircularProgress />
                </ProgressBox>
            )
        }

        return null;
    }
    
    return(
        <Dialog
            open={open}
            fullWidth
            maxWidth="xs"
            PaperProps={{ sx: { minHeight: 395 } }}
            TransitionComponent={Transition}
            scroll={'paper'}
            >
            {onProgress()}
            <DialogTitle sx={{ textAlign: "center"}}>{dialogTitle}</DialogTitle>
            <VerificationDialogContent>
               
                <DialogContentText sx={{ textAlign: "center", marginBottom: 5}}>
                    {dialogText}
                </DialogContentText>
                <OptionsBox>
                    <Content
                        verifyType={verifyType}
                        setDialogText={setDialogText}
                        setDialogTitle={setDialogTitle} />
                </OptionsBox>
            </VerificationDialogContent>    
            <DialogActions sx={{marginTop: '15px'}}>
                <Button onClick={onClose}>Batal</Button>
            </DialogActions>
        </Dialog>
    )
}

const OptionsBox = styled(Box)(({theme}) => ({
    marginTop: 25
}));

const ProgressBox = styled(Box)(({theme}) => ({
    position: 'absolute',
    background: "#ffffff",
    width: "100%",
    height: "100%",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    zIndex: 55,
    opacity: 0.75,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
}));

const VerificationDialogContent = styled(DialogContent)(({theme}) => ({
    [theme.breakpoints.between('xs','sm')]: {
        paddingLeft: 10,
        paddingRight: 10,
    },
}));


