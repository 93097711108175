import React, { useState } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import * as supplierActions from '../../../../../../../../actions/supplierActions';
import { Typography,
         TextField,
         InputAdornment,
         Grid,
         Box,
         Dialog,
         DialogTitle,
         DialogContent,
         DialogActions,
         IconButton,
         Button,
         Switch,
         Autocomplete,
         Popper
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useParams } from "react-router-dom";
import { CloseTwoTone, PercentTwoTone, NumbersTwoTone } from '@mui/icons-material';
import NumberFormat from 'react-number-format';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import zIndex from '@mui/material/styles/zIndex';

const CurrencyFormat = React.forwardRef((props, ref) => (
    <NumberFormat
        {...props.other}
        onValueChange={(values) => {
          props.onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        customInput={TextField}
        InputProps={{
            startAdornment: <InputAdornment position="start">
                <Box fontWeight={700}>
                    Rp
                </Box>
            </InputAdornment>
        }}
        placeholder="0"
        disabled={props.disabled}
        helperText={props.helperText}
        error={props.error}
        value={props.value}
        onFocus={props.onFocus}
        decimalScale={2}
        fullWidth={props.fullWidth}
        onBlur={props.onBlur}
        thousandSeparator={"."}
        decimalSeparator={","}
        allowNegative={true}
        allowedDecimalSeparators={false}
        isNumericString
      />
));

const PercentageFormat = React.forwardRef((props, ref) => (
    <NumberFormat
        {...props.other}
        onValueChange={(values) => {
          props.onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        customInput={TextField}
        InputProps={{
            startAdornment: <InputAdornment position="start">
                <Box fontWeight={700}>
                    {props.checked ? '%' : 'Rp' }
                </Box>
            </InputAdornment>,
            endAdornment: 
                <InputAdornment position="end">
                    <MaterialUISwitch
                        checkedIcon={
                            <SwitchBox>
                                <PercentTwoTone fontSize="12px" color="#fffff" />
                            </SwitchBox>
                        }
                        icon={
                            <SwitchBox>
                                <NumbersTwoTone fontSize="12px" color="#fffff" />
                            </SwitchBox>
                        }
                        checked={props.checked}
                        onChange={props.onToggle}
                        color="primary"
                    />
                </InputAdornment>,
            style: {
                paddingRight: '5px'
            }
        }}
        placeholder="0"
        fullWidth={props.fullWidth}
        helperText={props.helperText}
        error={props.error}
        value={props.value}
        onFocus={props.onFocus}
        decimalScale={2}
        onBlur={props.onBlur}
        thousandSeparator={"."}
        decimalSeparator={","}
        allowNegative={false}
        allowedDecimalSeparators={false}
        isNumericString
      />
));

export default function Payment() {
    const dispatch = useDispatch();
    let { id } = useParams();
    const { purchase_orders, purchase_order_payment, onPayment, errors, inProgress } = useSelector(state => ({
        ...state.supplier
    }), shallowEqual);

    
    const { payment_method, payment_date, subtotal, discount_amount, discount_percent, discount_type, payment_amount, note } = purchase_order_payment;

    const [paymentOptions, setPaymentOptions] = useState([
        {id: 1, title: 'Bank Transfer', value: 'bank_transfer'},
        {id: 2, title: 'Cash', value: 'cash'},
        {id: 3, title: 'Giro', value: 'giro'}
    ]);
    const [currentFocus, setCurrentFocus] = useState("");

    function onClose() {
        dispatch(supplierActions.onHidePayment());
    } 

    function onSelectPayment(option) {
        const newPurchaseOrderPayment = {...purchase_order_payment};
        newPurchaseOrderPayment.payment_method = option.value;
        newPurchaseOrderPayment.payment_title = option.title;
        dispatch(supplierActions.onUpdatePurchaseOrderPayment(newPurchaseOrderPayment));
    }
    

    const onSelectDate = (date) => {
        const newPurchaseOrderPayment = {...purchase_order_payment};
        newPurchaseOrderPayment.payment_date = date;
        dispatch(supplierActions.onUpdatePurchaseOrderPayment(newPurchaseOrderPayment));
    };

    function onToggleDiscount(event) {
        const newPurchaseOrderPayment = {...purchase_order_payment};
        if (newPurchaseOrderPayment.discount_type === "percent") {
            newPurchaseOrderPayment.discount_type = "amount";
            newPurchaseOrderPayment.discount_amount = Number(newPurchaseOrderPayment.discount_percent);
            newPurchaseOrderPayment.discount_percent = 0;
            newPurchaseOrderPayment.grand_total = newPurchaseOrderPayment.subtotal - newPurchaseOrderPayment.discount_amount;
            newPurchaseOrderPayment.payment_amount = newPurchaseOrderPayment.grand_total;
        } else {
            newPurchaseOrderPayment.discount_type = "percent"
            newPurchaseOrderPayment.discount_percent = Number(newPurchaseOrderPayment.discount_amount);
            newPurchaseOrderPayment.discount_amount = newPurchaseOrderPayment.subtotal * (Number(newPurchaseOrderPayment.discount_percent / 100));
            newPurchaseOrderPayment.grand_total = newPurchaseOrderPayment.subtotal - newPurchaseOrderPayment.discount_amount;
            newPurchaseOrderPayment.payment_amount = newPurchaseOrderPayment.grand_total;
        }
        dispatch(supplierActions.onUpdatePurchaseOrderPayment(newPurchaseOrderPayment));
    }

    function onUpdateDiscount(event) {
        if (currentFocus === 'discount') {
            const value = event.target.value;
            const newPurchaseOrderPayment = {...purchase_order_payment};
            if (newPurchaseOrderPayment.discount_type === "percent") {
                newPurchaseOrderPayment.discount_percent = value;
                newPurchaseOrderPayment.discount_amount = newPurchaseOrderPayment.subtotal * (Number(newPurchaseOrderPayment.discount_percent / 100));
                newPurchaseOrderPayment.grand_total = newPurchaseOrderPayment.subtotal - newPurchaseOrderPayment.discount_amount;
                newPurchaseOrderPayment.payment_amount = newPurchaseOrderPayment.grand_total;
            } else {
                newPurchaseOrderPayment.discount_amount = value;
                newPurchaseOrderPayment.grand_total = newPurchaseOrderPayment.subtotal - newPurchaseOrderPayment.discount_amount;
                newPurchaseOrderPayment.payment_amount = newPurchaseOrderPayment.grand_total;
            }
            dispatch(supplierActions.onUpdatePurchaseOrderPayment(newPurchaseOrderPayment));
        }
    }

    function onUpdatePayment(event) {
        if (currentFocus === 'payment') {
            const value = event.target.value;
            const newPurchaseOrderPayment = {...purchase_order_payment};
            newPurchaseOrderPayment.payment_amount = value;
            dispatch(supplierActions.onUpdatePurchaseOrderPayment(newPurchaseOrderPayment));
        }
    }

    function onUpdateField(event) {
		const key = event.target.name;
        const value = event.target.value;
        const newPurchaseOrderPayment = {...purchase_order_payment};
        newPurchaseOrderPayment[key] = value;
        dispatch(supplierActions.onUpdatePurchaseOrderPayment(newPurchaseOrderPayment));
    }

    function onFocus(key) {
        setCurrentFocus(key)
    }

    function onBlur(key) {
        setCurrentFocus(null)
    }

    function onCreate() {
        dispatch(supplierActions.onCreatePurchaseOrderPayment(purchase_order_payment));
    }

    function PopperComponent({ disablePortal, anchorEl, open, ...other }) {
        return <StyledAutocompletePopper disablePortal={true} open={open} anchorEl={anchorEl} {...other} />;
    }


    const onKeyDown = (e) => {
        e.preventDefault();
    };


    function error(key) {
        if (errors != null && errors[`purchase_order_payment.${key}`] != null) {
            return errors[`purchase_order_payment.${key}`].msg
        }
        return null;
    }

    const paymentValue = paymentOptions.find(t => t.value === payment_method);
    return(
        <Dialog sx={{zIndex: 9999}} fullWidth maxWidth="xs" open={onPayment} onClose={onClose}>
            <DialogTitle sx={{padding: '25px', display: 'flex', justifyContent: 'center'}} >
                <NumberFormat
                value={subtotal}
                displayType="text"
                thousandSeparator={"."}
                decimalSeparator={","}
                prefix="Rp "
                isNumericString
                decimalScale={2}
                renderText={(value) => <Typography fontWeight={700}>
                Tagihan {value}
                </Typography>}
            />
                <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                    position: 'absolute',
                    right: 25,
                    top: 20,
                    color: (theme) => theme.palette.grey[500],
                }}
                >
                    <CloseTwoTone />
                </IconButton>
            </DialogTitle>
            <DialogContent sx={{padding: '35px'}}>
                <Grid container direction="column" spacing={2}>
                    <Grid item container direction="column" spacing={1}>
                        <Grid item xs={4}>
                            <Typography fontSize={16} fontWeight={700} component="div">
                                Metode Pembayaran
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Autocomplete
                                options={paymentOptions.sort((a, b) => -b.title.localeCompare(a.title))}
                                getOptionLabel={(option) => option.title}
                                selectOnFocus
                                clearOnBlur
                                filterSelectedOptions
                                disableClearable
                                PopperComponent={PopperComponent}
                                onChange={(event, option) => {
                                    onSelectPayment(option)
                                }}
                                value={paymentValue ? paymentValue : null}
                                renderInput={(params) => <TextField {...params} error={!!error('payment_method')} helperText={error('payment_method')} placeholder="Select Payment" variant="outlined" />}
                            />
                        </Grid> 
                    </Grid>
                    <Grid item container direction="column" spacing={1}>
                        <Grid item xs={4}>
                            <Typography fontSize={16} fontWeight={700} component="div">
                                Tgl. Pembayaran
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DatePicker
                                    openTo="year"
                                    disableFuture
                                    value={payment_date}
                                    format="dd/MM/yyyy"
                                    PopperProps={{
                                        disablePortal: true,
                                     }}
                                    onChange={onSelectDate}
                                    renderInput={(params) => <TextField fullWidth onKeyDown={onKeyDown} {...params} />}
                                />
                            </LocalizationProvider>
                        </Grid> 
                    </Grid>
                    <Grid item container direction="column" spacing={1}>
                        <Grid item xs={4}>
                            <Typography fontSize={16} fontWeight={700} component="div">
                                Diskon
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <PercentageFormat
                                fullWidth
                                value={discount_type === 'percent' ? discount_percent : discount_amount}
                                inputProps={{min: 0, style: { textAlign: 'right' }}}
                                variant="outlined"
                                onFocus={onFocus.bind(this, "discount")}
                                onBlur={onBlur.bind(this)}
                                onToggle={onToggleDiscount}
                                checked={discount_type === 'percent'}
                                onChange={onUpdateDiscount}
                            />
                        </Grid> 
                    </Grid>
                    <Grid item container direction="column" spacing={1}>
                        <Grid item xs={4}>
                            <Typography fontSize={16} fontWeight={700} component="div">
                                Jumlah Pembayaran
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <CurrencyFormat
                                fullWidth
                                error={!!error(`payment_amount`)}
                                helperText={error(`payment_amount`)}
                                value={payment_amount}
                                inputProps={{min: 0, style: { textAlign: 'right' }}}
                                variant="outlined"
                                onFocus={onFocus.bind(this, "payment")}
                                onBlur={onBlur.bind(this)}
                                onChange={onUpdatePayment}
                            />
                        </Grid> 
                    </Grid>
                    <Grid item container direction="column" spacing={1}>
                        <Grid item xs={4}>
                            <Typography fontSize={16} fontWeight={700} component="div">
                                Catatan
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <TextField
                                fullWidth
                                name="note"
                                value={note}
                                variant="outlined"
                                placeholder="Catatan"
                                onChange={onUpdateField}
                            />
                        </Grid> 
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions sx={{padding: '0px 35px 35px 35px'}}>
                <Button 
                    fullWidth
                    variant="contained"
                    size="large"
                    color="primary"
                    disabled={inProgress}
                    onClick={onCreate}
                    disableElevation>
                        Bayar
                </Button>
            </DialogActions>
        </Dialog>
    )  
}

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
    width: 60,
    height: 34,
    padding: 12,
    '& .MuiSwitch-switchBase': {
        margin: 1,
        padding: 0,
        transform: 'translateX(6px)',
        '&.Mui-checked': {
        color: '#fff',
        transform: 'translateX(22px)',
        '& + .MuiSwitch-track': {
            opacity: 1,
            backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
        },
        },
    },
    '& .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
        borderRadius: 20 / 2,
    },
}));

const SwitchBox = styled(Box)(({ theme }) => ({
    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    backgroundColor: '#001e3c',
    width: 30,
    height: 30,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
}));

const StyledAutocompletePopper = styled(Popper)(({ theme }) => ({
    // position: 'absolute',
    zIndex: '9999'
  }));

  const PickersPopperRoot = styled(Popper)(({ theme }) => ({
    // position: 'absolute',
    zIndex: '9999'
  }));
