import React, { useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import * as purchaseOrderActions from '../../../../../../actions/purchaseOrderActions';
import { 
        Typography,
        Box,
        Grid,
        CircularProgress,
        Button
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate, useParams } from "react-router-dom";
import Information from './Information';
import Product from './Product';
// import Activity from './Activity';
import Confirm from './Confirm';
import Return from './Return';
import Payment from './Payment';
import Loader from '../../../../../Helper/Loader';


export default function Edit() { 
    let navigate = useNavigate();
    let { id } = useParams();
    const dispatch = useDispatch();
    const {purchase_order, errors} = useSelector(state => ({
        ...state.purchase_order
    }), shallowEqual); 

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
    },[errors]);

    useEffect(() => {
        dispatch(purchaseOrderActions.onEdit(id));
        return () => {
            dispatch(purchaseOrderActions.onUnloadPurchaseOrder())
        };
    },[dispatch, id]);

    function onSubmit() {
        const { status } = purchase_order;
        if (status === 'requested') {
            dispatch(purchaseOrderActions.onShowConfirm("ordered"))
        }
        if (status === 'ordered') {
            dispatch(purchaseOrderActions.onShowConfirm("received"))
        }
        // if (status === 'received') {
        //     dispatch(purchaseOrderActions.onShowConfirm("approved"))
        // }
        if (status === 'received') {
            dispatch(purchaseOrderActions.onShowConfirm("updated"))
        }
    }

    if (!purchase_order) {
        return <Loader/>
          
    }

    function error(key) {
        if (errors != null && errors[`purchase_order.${key}`] != null) {
            return errors[`purchase_order.${key}`].msg
        }
        return null;
    }

    const { status } = purchase_order;

    function Cancel() {
        if (status === 'requested' || status === 'ordered') {
            return (
                <Button 
                    variant="contained"
                    size="large"
                    disableElevation
                    onClick={ () => {dispatch(purchaseOrderActions.onShowConfirm("canceled"))}}>
                    Batalkan
                </Button>
            )
        }

        return null;
    }

    return (
        <ContentBox>
            <HeaderBox>
                <Typography fontWeight={700} variant="h6">
                    Detail Pembelian
                </Typography>
            </HeaderBox>
            <Information error={error} />
            <Product error={error} />
            <Box>
                <Grid container sx={{marginBottom: '55px'}} justifyContent="space-between" spacing={3}>
                    <Grid item>
                        {Cancel()}
                    </Grid>
                    <Grid item>
                        <Grid container spacing={3}>
                            <Grid item>
                                <BackButton 
                                    variant="contained"
                                    size="large"
                                    disableElevation
                                    onClick={ () => {navigate(`/dashboard/purchase-orders`)}}>
                                    Kembali
                                </BackButton>
                            </Grid>
                            <Grid item>
                                <Button 
                                    variant="contained"
                                    size="large"
                                    color="primary"
                                    sx={{minWidth: 195}}
                                    onClick={onSubmit}
                                    disabled={status === 'canceled' || status === 'paid' || status === 'on_due'}
                                    disableElevation>
                                    Simpan
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>  
            </Box>
            {/* <Activity /> */}
            <Confirm />
            <Return />
            <Payment />
        </ContentBox>
    )
}

const ContentBox = styled(Box)(({theme}) => ({
    padding: '25px 0',
    [theme.breakpoints.down('sm')]: {
        padding: '15px 15px 0 15px',
    },
    [theme.breakpoints.down('md')]: {
        padding: '15px 15px 0 15px',
    },
}));

const HeaderBox = styled(Box)(({theme}) => ({
    paddingLeft: 0,
    marginBottom: 25,
    [theme.breakpoints.down('sm')]: {
        paddingLeft: 55,
    },
}));

const BackButton = styled(Button)(({theme}) => ({
    color: theme.palette.text.primary,
    backgroundColor: "#FFFFFF",
    '&:hover': {
    backgroundColor: "#FFFFFF",
    },
    fontWeight: 500,
    border: '1px solid #ddd',
    minWidth: 195
}));
