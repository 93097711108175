import React from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { onStoreFrom, onStoreTo, onUpdateStoreTransfer } from '../../../../../../actions/storeTransferActions';
import { 
        Typography,
        Paper,
        Grid,
        TextField,
        Autocomplete,
        Alert,
        CircularProgress
} from '@mui/material';
import { styled } from '@mui/material/styles';

export default function Information() {
    const dispatch = useDispatch();
    const {store_transfer, storesFetching, from_stores, to_stores, errors} = useSelector(state => ({
        ...state.store_transfer
    }), shallowEqual); 

    const { from_store_id, to_store_id, note } = store_transfer;

    function onSelectFrom(value) {
        dispatch(onStoreFrom(value));
    }

    function onSelectTo(value) {
        dispatch(onStoreTo(value));
    }

    function onUpdateField(event) {
		const key = event.target.name;
        const value = event.target.value;
        const newStoreTransfer = {...store_transfer};
        newStoreTransfer[key] = value;
        dispatch(onUpdateStoreTransfer(newStoreTransfer));
    }
 
    function error(key) {
        if (errors != null && errors[`store_transfer.${key}`] != null) {
            return errors[`store_transfer.${key}`].msg
        }
        return null;
    }

    const fromOptions = from_stores.map((option) => {
        const firstLetter = option.name[0].toUpperCase();
        return {
          firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
          ...option,
        };
    });

    const toOptions = to_stores.map((option) => {
        const firstLetter = option.name[0].toUpperCase();
        return {
          firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
          ...option,
        };
    });

    const fromStoreValue = from_stores.find(option => option._id === from_store_id);
    const toStoreValue = to_stores.find(option => option._id === to_store_id);

    return (
        <ContentPaper elevation={3}>
            <Grid container spacing={6} direction="column">
                <Grid item container>
                    <Grid item xs>
                        <Typography fontWeight={700} variant="h6" lineheight="normal">
                            Informasi Transfer
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Alert severity="error">
                            Dibutuhkan
                        </Alert>
                    </Grid>
                </Grid>
                <Grid item container direction="column" spacing={5}>
                    <Grid item container spacing={8}>
                        <Grid item xs={4}>
                            <Typography lineheight="normal" fontSize={16} fontWeight={700} component="div">
                                Cabang Asal
                            </Typography>
                            <Typography variant="body2" gutterBottom color="textSecondary">
                                Silahkan pilih cabang asal.
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Autocomplete
                                options={fromOptions.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                                groupBy={(option) => option.firstLetter}
                                getOptionLabel={(option) => option.name}
                                selectOnFocus
                                clearOnBlur
                                filterSelectedOptions
                                disableClearable
                                onChange={(event, option) => {
                                    onSelectFrom(option._id)
                                }}
                                value={fromStoreValue ? fromStoreValue : null}
                                isOptionEqualToValue={(option, value) => option._id === value._id}
                                renderInput={(params) => <TextField {...params} error={!!error('from_store_id')} helperText={error('from_store_id')} placeholder="Pilih cabang asal" variant="outlined" />}
                            />
                        </Grid> 
                    </Grid>
                    <Grid item container spacing={8}>
                        <Grid item xs={4}>
                            <Typography lineheight="normal" fontSize={16} fontWeight={700} component="div">
                                Cabang Tujuan
                            </Typography>
                            <Typography variant="body2" gutterBottom color="textSecondary">
                                Silahkan pilih cabang tujuan.
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Autocomplete
                                options={toOptions.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                                groupBy={(option) => option.firstLetter}
                                getOptionLabel={(option) => option.name}
                                selectOnFocus
                                clearOnBlur
                                disabled={storesFetching || !from_store_id}
                                filterSelectedOptions
                                disableClearable
                                onChange={(event, option) => {
                                    onSelectTo(option._id)
                                }}
                                value={toStoreValue ? toStoreValue : null}
                                isOptionEqualToValue={(option, value) => option._id === value._id}
                                renderInput={(params) => <TextField {...params} 
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                    <React.Fragment>
                                        {storesFetching ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                    ),
                                }}
                                error={!!error('to_store_id')} helperText={error('to_store_id')} placeholder="Pilih cabang tujuan" variant="outlined" />}
                            />
                        </Grid> 
                    </Grid>
                    <Grid item container spacing={8}>
                        <Grid item xs={4}>
                            <Typography lineheight="normal" fontSize={16} fontWeight={700} component="div">
                                Catatan
                            </Typography>
                            <Typography variant="body2" gutterBottom color="textSecondary">
                                Masukan catatan transfer.
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <TextField
                                fullWidth
                                rows={6}
                                multiline
                                name="note"
                                placeholder="Catatan"
                                value={note}
                                variant="outlined"
                                onChange={onUpdateField}
                            />
                        </Grid> 
                    </Grid>
                </Grid>
            </Grid>
        </ContentPaper>
    )
}

const ContentPaper = styled(Paper)(({theme}) => ({
    padding: 35,
    paddingTop: 32,
    marginBottom: 25
}));