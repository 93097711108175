import React, { useState, useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import * as purchaseOrderActions from '../../../../../actions/purchaseOrderActions';
import { TableContainer,
         Table,
         TableHead,
         TableRow,
         TableCell,
         TableBody,
         Paper,
         Typography,
         TableSortLabel,
         TablePagination,
         Box,
         Grid,
         Stack,
         Chip,
         IconButton
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Header from './Header';
import { visuallyHidden } from '@mui/utils';
import DataEmpty from '../../../../Helper/DataEmpty';
import Loader from '../../../../Helper/Loader';
import NumberFormat from 'react-number-format';
import { FirstPage, LastPage, KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import moment from 'moment';
import 'moment/locale/id' 

const headCells = [
    { id: 'increment_id', numeric: false, sortable: true, disablePadding: false, label: 'ID PO' },
    { id: 'store', numeric: false, sortable: true, disablePadding: false, label: 'Nama Cabang' },
    { id: 'supplier', numeric: false, sortable: true, disablePadding: false, label: 'Nama Supplier' },
    { id: 'created_at', numeric: false, sortable: true, disablePadding: false, label: 'Tgl. Buat' },
    { id: 'invoice_id', numeric: false, sortable: true, disablePadding: false, label: 'No. PO' },
    { id: 'items_count', numeric: true, sortable: false, disablePadding: false, label: 'Total Item' },
    { id: 'grand_total', numeric: true, sortable: true, disablePadding: false, label: 'Total PO' },
    { id: 'due_date', numeric: false, sortable: true, disablePadding: false, label: 'Jatuh Tempo' },
    { id: 'status', numeric: true, sortable: true, disablePadding: false, label: 'Status' },
  ];
 
  
function EnhancedTableHead({ order, orderBy, onRequestSort }) {
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => {
                    if (headCell.sortable) {
                        return (
                            <TableCell
                                key={headCell.id}
                                sx={{fontWeight: 700}}
                                align={headCell.numeric ? 'right' : 'left'}
                                padding={headCell.disablePadding ? 'none' : 'normal'}
                                sortDirection={orderBy === headCell.id ? order : false}
                            >
                                <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                                >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                ) : null}
                                </TableSortLabel>
                            </TableCell>
                        )
                    }
                    return (
                        <TableCell
                        key={headCell.id}
                        sx={{fontWeight: 700}}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        >
                            {headCell.label}
                        </TableCell>
                    )
                })}
            </TableRow>
        </TableHead>
    );
}

function TablePaginationActions(props) {
    const { count, page, rowsPerPage, onPageChange } = props;
  
    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };
  
    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };
  
    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };
  
    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
  
    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          <FirstPage />
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          <KeyboardArrowLeft />
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
        <KeyboardArrowRight />
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
        <LastPage />
        </IconButton>
      </Box>
    );
}

export default function PurchaseOrder() {
    const dispatch = useDispatch();
    const { purchase_orders, purchase_orders_count, stores } = useSelector(state => ({
        ...state.purchase_order
    }), shallowEqual);

    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('name');
   
    const [query, setQuery] = useState({ page: 0, limit: 15, search: '', store_id: '', status: '', start_date: moment().startOf('month').format('YYYY[-]MM[-]DD'), end_date: moment().format('YYYY[-]MM[-]DD') });

    useEffect(() => {
        dispatch(purchaseOrderActions.onLoadPurchaseOrders(query))
    },[dispatch, query]);

    const onRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
        if (isAsc) {
          const newQuery = {...query};
          delete newQuery.sort_asc;
          newQuery.sort_desc = property;
          setQuery(newQuery)
        } else {
          const newQuery = {...query};
          delete newQuery.sort_desc;
          newQuery.sort_asc = property;
          setQuery(newQuery)
        }
    };


    const onChangePage = (event, newPage) => {
        const newQuery = {...query};
        newQuery.page = newPage;
        setQuery(newQuery)
    };

    const onChangeRowsPerPage = (event) => {
        const newQuery = {...query};
        newQuery.limit = parseInt(event.target.value, 10);
        newQuery.page = 0;
        setQuery(newQuery)
    };

    const onSelectStatus = (status, event) => {
        const newQuery = {...query};
        newQuery.status = status;
        setQuery(newQuery)
    };

    if (!purchase_orders) {
        return (
            <Loader />
        )
    }


    function Content() {
        const {page, limit} = query;

        if (purchase_orders.length === 0) {
            return (
                <DataEmpty />
            )
        }
        return (
            <Box>
                <TableContainer sx={{ overflowY: 'hidden' }}>
                    <Table
                        sx={{ width: '135%' }}
                        >
                        <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={onRequestSort}
                        />
                        <TableBody>
                        {
                                purchase_orders.map((order, index) => {
                                    const { increment_id, supplier, store, created_at, invoice_id, items_count, grand_total, due_date, status } = order;
                                    return (
                                        <TableRow
                                            hover
                                            onClick={() => {
                                                window.open(`/dashboard/purchase-orders/${order._id}/edit`);
                                            }}
                                            tabIndex={-1}
                                            key={index}
                                        >
                                            <ItemTableCell component="th" scope="row">{increment_id}</ItemTableCell>
                                            <ItemTableCell>{store}</ItemTableCell>
                                            <ItemTableCell>{supplier}</ItemTableCell>
                                            <ItemTableCell>{moment(created_at).format('LL')}</ItemTableCell>
                                            <ItemTableCell>{invoice_id ? invoice_id : "-"}</ItemTableCell>
                                            <ItemTableCell align="right">{items_count}</ItemTableCell>
                                            <ItemTableCell align="right">
                                                <NumberFormat
                                                    value={grand_total}
                                                    displayType="text"
                                                    thousandSeparator={"."}
                                                    decimalSeparator={","}
                                                    prefix="Rp "
                                                    isNumericString
                                                    decimalScale={2}
                                                    renderText={(value) => <Typography fontSize={"0.875rem"}>
                                                    {value}
                                                    </Typography>}
                                                />
                                            </ItemTableCell>
                                            <ItemTableCell>{due_date ? moment(due_date).format('LL') : "-"}</ItemTableCell>
                                            <ItemTableCell align="right">{status}</ItemTableCell>
                                        </TableRow>
                                    );
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                rowsPerPageOptions={[15, 25, 35]}
                component="div"
                count={purchase_orders_count}
                rowsPerPage={limit}
                page={page}
                onPageChange={onChangePage}
                onRowsPerPageChange={onChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
                />
            </Box>
        )
    }

 
    
    return(
        <ContentBox>
            <Header
                stores={stores}
                query={query}
                setQuery={setQuery} />
            <Paper sx={{ width: '100%', mb: 2, mt: '15px', overflow: 'hidden'}} elevation={3}>
                <Grid container direction="column">
                    <Grid item>
                        <Box sx={{padding: '25px 15px 15px 15px'}}>
                            <Grid container spacing={3} alignItems="center">
                                <Grid item>
                                    <Typography fontWeight={700} variant="body1">
                                        Status
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Stack direction="row" spacing={1}>
                                        <Chip label="Semua" variant="outlined" color={query.status === "" ? "primary" : "default"} onClick={onSelectStatus.bind(this, '')} />
                                        <Chip label="Diajukan" variant="outlined" color={query.status === "requested" ? "primary" : "default"} onClick={onSelectStatus.bind(this, 'requested')}/>
                                        <Chip label="Dipesan" variant="outlined" color={query.status === "ordered" ? "primary" : "default"} onClick={onSelectStatus.bind(this, 'ordered')}/>
                                        <Chip label="Diterima" variant="outlined" color={query.status === "received" ? "primary" : "default"} onClick={onSelectStatus.bind(this, 'received')}/>
                                        {/* <Chip label="Disetujui" variant="outlined" color={query.status === "approved" ? "primary" : "default"} onClick={onSelectStatus.bind(this, 'approved')}/> */}
                                        <Chip label="Jatuh Tempo" variant="outlined" color={query.status === "on_due" ? "primary" : "default"} onClick={onSelectStatus.bind(this, 'on_due')}/>
                                        <Chip label="Dibayar Sebagian" variant="outlined" color={query.status === "partially_paid" ? "primary" : "default"} onClick={onSelectStatus.bind(this, 'partially_paid')}/>
                                        <Chip label="Dibayar" variant="outlined" color={query.status === "paid" ? "primary" : "default"} onClick={onSelectStatus.bind(this, 'paid')}/>
                                        <Chip label="Dibatalkan" variant="outlined" color={query.status === "canceled" ? "primary" : "default"} onClick={onSelectStatus.bind(this, 'canceled')}/>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid item>
                        {Content()}
                    </Grid>
                </Grid>
                
            </Paper>
        </ContentBox>
    )
}

const ContentBox = styled(Box)(({theme}) => ({
    padding: '25px 0',
    [theme.breakpoints.down('sm')]: {
        padding: '15px 15px 0 15px',
    },
    [theme.breakpoints.down('md')]: {
        padding: '15px 15px 0 15px',
    },
}));


const ItemTableCell = styled(TableCell)(({theme}) => ({
    padding: '15px 16px'
}));
