import * as types from './actionTypes';
import { axiosClient } from '../api';

export const onLoadRegister = () => {
	return {
		type: types.USER_REGISTER_LOADED
	}
}


export const onUnloadRegister = () => {
	return {
		type: types.USER_REGISTER_UNLOADED
	}
}

export const onNewUser = (user) => {
	return {
		type: types.NEW_USER_REGISTER_LOADED,
		user
	}
}


export const onUpdateValidationField = (key, value) => {
	return {
        type: types.USER_REGISTER_VALIDATION_FIELD_UPDATED,
        key,
        value
	}
}

export const onUpdateField = (key, value) => {
	return {
        type: types.USER_REGISTER_FIELD_UPDATED,
        key,
        value
	}
}

export const onValidateUser = (user) => {
	const fetch = axiosClient.post(`/user-validations`, {user});
	return {
		type: types.USER_REGISTER_VALIDATION,
		payload: fetch
	}
}

export const onSignUp = (user) => {
	const fetch = axiosClient.post(`/users`, {user});
	return {
		type: types.USER_REGISTERED,
		payload: fetch
	}
}


