

import * as types from './actionTypes';
import { axiosClient } from '../api';


export const onLoadPurchaseOrders = (query) => {
	const fetch = axiosClient.get(`/purchase-orders`,
	{
		params: query
	});
	return {
		type: types.PURCHASE_ORDERS_LOADED,
		payload: fetch
	}
}


export const onNew = () => {
	const fetch = axiosClient.get(`/purchase-orders/new`);
	return {
		type: types.NEW_PURCHASE_ORDER_LOADED,
		payload: fetch
	}
}

export const onEdit = (id) => {
	const fetch = axiosClient.get(`/purchase-orders/${id}/edit`);
	return {
		type: types.EDIT_PURCHASE_ORDER_LOADED,
		payload: fetch
	}
}

export const onLoadStoreInventories = (id) => {
	const fetch = axiosClient.get(`/stores/${id}/inventories`);
	return {
		type: types.STORE_INVENTORIES_LOADED,
        payload: fetch,
        id
	}
}

export const onUpdatePurchaseOrder = (purchase_order) => {
	return {
		type: types.PURCHASE_ORDER_UPDATED,
		purchase_order
	}
}

export const onSelectedProductOptions = (new_order_items, id) => {
	return {
		type: types.PURCHASE_ORDER_ITEMS_SELECTED,
		new_order_items,
		id
	}
}

export const onHandleInventories = (id) => {
	const fetch = axiosClient.get(`/stores/${id}/inventories`);
	return {
		type: types.STORE_INVENTORIES_HANDLED,
        payload: fetch,
        id
	}
}

export const onHandleVariants = (id) => {
	return {
		type: types.PURCHASE_ORDER_VARIANTS_SHOWED,
		id
	}
}

export const onHideVariants = () => {
	return {
		type: types.PURCHASE_ORDER_VARIANTS_HIDED
	}
}

export const onSelectVariant = (variantProduct, label, value) => {
	return {
		type: types.PURCHASE_ORDER_VARIANT_SELECTED,
		variantProduct,
		label,
		value
	}
}

export const onHandleProductVariant = (variantProduct, label, value) => {
	return {
		type: types.PURCHASE_ORDER_PRODUCT_VARIANT_SELECTED,
		variantProduct,
		label,
		value
	}
}

export const onRestoreProductOptions = (options, id, sku) => {
	return {
		type: types.RESTORE_PRODUCT_OPTIONS_HANDLED,
		options,
		id,
		sku
	}
}


export const onSelectedReturnProductOptions = (new_return_items, id) => {
	return {
		type: types.PURCHASE_ORDER_RETURN_ITEMS_SELECTED,
		new_return_items,
		id
	}
}

// export const onHandleSupplierVariants = (id) => {
// 	return {
// 		type: types.PURCHASE_ORDER_SUPPLIER_VARIANTS_SHOWED,
// 		id
// 	}
// }

// export const onHideReturnVariants = () => {
// 	return {
// 		type: types.PURCHASE_ORDER_RETURN_VARIANTS_HIDED
// 	}
// }

// export const onSelectReturnVariant = (returnVariantProduct, label, value) => {
// 	return {
// 		type: types.PURCHASE_ORDER_RETURN_VARIANT_SELECTED,
// 		returnVariantProduct,
// 		label,
// 		value
// 	}
// }

// export const onHandleReturnProductVariant = (returnVariantProduct, value) => {
// 	return {
// 		type: types.PURCHASE_ORDER_RETURN_PRODUCT_VARIANT_SELECTED,
// 		returnVariantProduct,
// 		value
// 	}
// }

// export const onRestoreReturnProductOptions = (options, id, sku) => {
// 	return {
// 		type: types.RESTORE_RETURN_PRODUCT_OPTIONS_HANDLED,
// 		options,
// 		id,
// 		sku

// 	}
// }

export const onSelectProduct = (product_id) => {
	const fetch = axiosClient.get(`/purchase-orders/${product_id}/product`);
	return {
		type: types.PURCHASE_ORDER_PRODUCT_SELECTED, 
		payload: fetch
	}
}

export const onSelectSupplierProduct = (product_id, store_id) => {
	const fetch = axiosClient.get(`/purchase-orders/${product_id}/supplier_product?store_id=${store_id}`);
	return {
		type: types.PURCHASE_ORDER_SUPPLIER_PRODUCT_SELECTED, 
		payload: fetch
	}
}

export const onCreate = (purchase_order) => {
	const fetch = axiosClient.post(`/purchase-orders`, {purchase_order});
	return {
		type: types.PURCHASE_ORDER_CREATED, 
		payload: fetch
	}	
}

export const onShowActivities = () => {
	return {
		type: types.PURCHASE_ORDER_ACTIVITIES_SHOWED
	}
}

export const onHideActivities = () => {
	return {
		type: types.PURCHASE_ORDER_ACTIVITIES_HIDED
	}
}

export const onUnloadPurchaseOrder = () => {
	return {
		type: types.PURCHASE_ORDER_UNLOADED
	}
}

export const onShowConfirm = (action_type) => {
	return {
		type: types.PURCHASE_ORDER_CONFIRM_SHOWED,
		action_type
	}
}

export const onHideConfirm = () => {
	return {
		type: types.PURCHASE_ORDER_CONFIRM_HIDED
	}
}

export const onCanceled = (id, purchase_order) => {
	const fetch = axiosClient.put(`/purchase-orders/${id}/canceled`, {purchase_order});
	return {
		type: types.PURCHASE_ORDER_EDITED, 
		payload: fetch
	}
}

export const onOrdered = (id, purchase_order) => {
	const fetch = axiosClient.put(`/purchase-orders/${id}/ordered`, {purchase_order});
	return {
		type: types.PURCHASE_ORDER_EDITED, 
		payload: fetch
	}
}

export const onReceived = (id, purchase_order) => {
	const fetch = axiosClient.put(`/purchase-orders/${id}/received`, {purchase_order});
	return {
		type: types.PURCHASE_ORDER_EDITED, 
		payload: fetch
	}
}

export const onApproved = (id, purchase_order) => {
	const fetch = axiosClient.put(`/purchase-orders/${id}/approved`, {purchase_order});
	return {
		type: types.PURCHASE_ORDER_EDITED, 
		payload: fetch
	}
}

export const onUpdate = (id, purchase_order) => {
	const fetch = axiosClient.put(`/purchase-orders/${id}`, {purchase_order});
	return {
		type: types.PURCHASE_ORDER_EDITED, 
		payload: fetch
	}
}

export const onShowReturns = () => {
	return {
		type: types.PURCHASE_ORDER_RETURNS_SHOWED
	}
}

export const onHideReturns = () => {
	return {
		type: types.PURCHASE_ORDER_RETURNS_HIDED
	}
}

export const onLoadReturns = (id) => {
	const fetch = axiosClient.get(`/purchase-orders/${id}/returns`);
	return {
		type: types.PURCHASE_ORDER_RETURNS_LOADED, 
		payload: fetch
	}
}

export const onCollapseReturns = (returns) => {
	return {
		type: types.PURCHASE_ORDER_RETURNS_COLLAPSED, 
		returns
	}
}

export const onShowPayments = () => {
	return {
		type: types.PURCHASE_ORDER_PAYMENTS_SHOWED
	}
}

export const onHidePayments = () => {
	return {
		type: types.PURCHASE_ORDER_PAYMENTS_HIDED
	}
}

export const onLoadPayments = (id) => {
	const fetch = axiosClient.get(`/purchase-orders/${id}/payments`);
	return {
		type: types.PURCHASE_ORDER_PAYMENTS_LOADED, 
		payload: fetch
	}
}

export const onCollapsePayments = (payments) => {
	return {
		type: types.PURCHASE_ORDER_PAYMENTS_COLLAPSED, 
		payments
	}
}


// export const onPaid = (id, purchase_order) => {
// 	const fetch = axiosClient.put(`/purchase_orders/${id}/paid`, {purchase_order});
// 	return {
// 		type: types.PURCHASE_ORDER_EDITED, 
// 		payload: fetch
// 	}
// }