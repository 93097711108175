import React, {useState, useEffect} from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import * as employeeActions from '../../../../../../../../actions/employeeActions';
import * as commonActions from '../../../../../../../../actions/commonActions';
import { 
    Typography,
    Grid,
    Button,
    FormControl,
    RadioGroup,
    FormControlLabel,
    Radio,
    FormHelperText,
    Autocomplete,
    TextField,
    CircularProgress,
    Paper,
    IconButton,
    Box,
    LinearProgress
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {ImageRounded, DeleteForeverRounded} from '@mui/icons-material';
import NumberFormat from 'react-number-format';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { useParams } from "react-router-dom";

const CustomFormat = React.forwardRef((props, ref) => (
    <NumberFormat
        {...props.other}
        onValueChange={(values) => {
          props.onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        customInput={TextField}
        placeholder={props.placeholder}
        value={props.value}
        helperText={props.helperText}
        error={props.error}
        fullWidth={props.fullWidth}
        allowLeadingZeros={true}
        allowNegative={false}
        allowedDecimalSeparators={false}
        isNumericString
      />
));
  
export default function Edit() {
    let { id } = useParams();
    const dispatch = useDispatch();
    const { editEmployee, gender_options, marital_statuses, blood_types, religion_options, errors, inProgress, areas, areasFetching } = useSelector(state => ({
        ...state.employee
    }), shallowEqual);

    const [loaded, setLoaded] = useState(false);
    const [value, setValue] = useState(null);
    const [inputValue, setInputValue] = useState('');
    const [areaOptions, setAreaOptions] = useState([]);

    const {email, first_name, last_name, phone, identity_number, date_of_birth, gender_option_id, marital_status_id, blood_type_id, religion_option_id, address, employee_profiles, employee_identities } = editEmployee;

    useEffect(() => {
        return () => {
            dispatch(employeeActions.onUnloadEditEmployee());
        };
    },[dispatch]);

    useEffect(() => {
        if (editEmployee &&  editEmployee.employee_profiles) {
            editEmployee.employee_profiles.map((image, index) => {
                if (image.onUpload && !image.uploading) {
                    dispatch(employeeActions.onEditUpload(image, index, (event) => {
                        image.progress = Math.round((event.loaded * 100) / event.total);
                        image.uploading = true;
                        dispatch(employeeActions.onEditUploading(image, index))
                    }));
                }
            })

            editEmployee.employee_profiles.map((image, index) => {
                if (image.onRemove && !image._destroy) {
                    dispatch(employeeActions.onEditRemove(image, index));
                }                
            })
        }

        if (editEmployee && editEmployee.employee_identities) {
            editEmployee.employee_identities.map((image, index) => {
                if (image.onUpload && !image.uploading) {
                    dispatch(employeeActions.onEditIdentityUpload(image, index, (event) => {
                        image.progress = Math.round((event.loaded * 100) / event.total);
                        image.uploading = true;
                        dispatch(employeeActions.onEditIdentityUploading(image, index))
                    }));
                }
            })

            editEmployee.employee_identities.map((image, index) => {
                if (image.onRemove && !image._destroy) {
                    dispatch(employeeActions.onEditIdentityRemove(image, index));
                }                
            })
        }
        
        function initialize() {
            const { state, city, district, code_area } = editEmployee;
            if (!!state && !!city && !!district && !!code_area) {
                const newOptions = [];
                const selectedOption = {value: `${state}, ${city}, ${district}, ${code_area}`, name: `${state}, ${city}, ${district}, ${code_area}`};
                newOptions.push(selectedOption);
                setAreaOptions(newOptions)
                setValue(selectedOption);
            }
        }
        if (!loaded) {
            initialize();
            setLoaded(true);
        }
    },[editEmployee, loaded]);

    useEffect(() => {
        setAreaOptions(areas)
    }, [areas]);

    function onUpdateField(event) {
		const key = event.target.name;
        const value = event.target.value;
        const newEmployee = {...editEmployee};
        newEmployee[key] = value;
        dispatch(employeeActions.onUpdateEditEmployee(newEmployee));
    }

    function onSelectImage(event) {
        event.preventDefault();
        const files = event.target.files;
        
        let file = files[0]
        const reader = new FileReader();
        reader.onloadend = () => {
            dispatch(employeeActions.onEditUploadRequest(reader.result));
        }
        reader.readAsDataURL(file);
    }

    function onRemoveImage(image, index, event) {
        event.preventDefault();
        dispatch(employeeActions.onEditRemoveRequest(image, index));
    }

    function onSelectIdentity(event) {
        event.preventDefault();
        const files = event.target.files;
        
        let file = files[0]
        const reader = new FileReader();
        reader.onloadend = () => {
            dispatch(employeeActions.onEditIdentityUploadRequest(reader.result));
        }
        reader.readAsDataURL(file);
    }

    function onRemoveIdentity(image, index, event) {
        event.preventDefault();
        dispatch(employeeActions.onEditIdentityRemoveRequest(image, index));
    }


    const onSelectDate = (date) => {
        const newEmployee = {...editEmployee};
        newEmployee.date_of_birth = date;
        dispatch(employeeActions.onUpdateEditEmployee(newEmployee));
    };

    function onSelectMarital(option) {
        const newEmployee = {...editEmployee};
        newEmployee.marital_status_id = option._id;
        dispatch(employeeActions.onUpdateEditEmployee(newEmployee));
    }

    function onSelectBloodType(option) {
        const newEmployee = {...editEmployee};
        newEmployee.blood_type_id = option._id;
        dispatch(employeeActions.onUpdateEditEmployee(newEmployee));
    }

    function onSelectReligion(option) {
        const newEmployee = {...editEmployee};
        newEmployee.religion_option_id = option._id;
        dispatch(employeeActions.onUpdateEditEmployee(newEmployee));
    }


    function onSelectArea(option) {
        if (!option) {
            return;
        }
        setValue(option);
        const newEmployee = {...editEmployee};
        const selectedArea = option.value.split(', ');
        newEmployee.state = selectedArea[0];
        newEmployee.city = selectedArea[1];
        newEmployee.district = selectedArea[2];
        newEmployee.code_area = selectedArea[3];
        dispatch(employeeActions.onUpdateEditEmployee(newEmployee));
    }


    function onCancel() {
        dispatch(employeeActions.onUnloadEditEmployee());
    };

    function onSubmit() {
        dispatch(employeeActions.onEditPersonalInformations(id, editEmployee));
    };
    
    const onKeyDown = (e) => {
        e.preventDefault();
    };

    function error(key) {
        if (errors != null && errors[`employee.${key}`] != null) {
            return errors[`employee.${key}`].msg
        }
        return null;
    }

    const maritalValue = marital_statuses.find(opt => opt._id === marital_status_id)
    const bloodValue = blood_types.find(opt => opt._id === blood_type_id)
    const religionValue = religion_options.find(opt => opt._id === religion_option_id)
    return (
        <Grid container spacing={4} direction="column">
            <Grid item container>
                <Grid item xs>
                    <Typography fontWeight={700} component="h6" lineheight="normal">
                        Ubah Informasi Pribadi
                    </Typography>
                </Grid>
                <Grid item>
                </Grid>
            </Grid>
            <Grid item container direction="column" spacing={3}>
                <Grid item container spacing={8}>
                    <Grid item xs={4}>
                        <Typography fontSize={16} fontWeight={700} component="div">
                            Email
                        </Typography>
                        <Typography variant="body2" gutterBottom color="textSecondary">
                            Email karyawan saat ini.
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <TextField
                            fullWidth
                            error={!!error('email')}
                            name="email"
                            placeholder="Email"
                            value={email}
                            helperText={error('email')}
                            variant="outlined"
                            onChange={onUpdateField}
                        />
                    </Grid> 
                </Grid>
                <Grid item container spacing={8}>
                    <Grid item xs={4}>
                        <Typography fontSize={16} fontWeight={700} component="div">
                            Foto Karyawan
                        </Typography>
                        <Typography variant="body2" gutterBottom color="textSecondary">
                            Foto profile karyawan.
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        { 
                            employee_profiles.map((profile, index) => {
                                if (profile._destroy) {
                                    return null;
                                }
                                if (profile.image && profile.onUpload && profile.progress !== 100) {
                                    return (
                                        <ImageAttachedPaper key={index} >
                                            <ImagePreviewBox>
                                                <Box component="img" alt={profile.image} src={profile.image} />
                                                <Box sx={{ width: '80%', position: 'absolute', bottom: '15px' }}>
                                                    <LinearProgress sx={{height: '8px', borderRadius: '35px'}} variant="determinate" value={profile.progress || 0} />
                                                </Box>
                                            </ImagePreviewBox>
                                        </ImageAttachedPaper>
                                    )
                                }
                                if (profile.image && profile.onRemove) {
                                    return (
                                        <ImageAttachedPaper key={index} >
                                            <ImagePreviewBox>
                                                <Box component="img" alt={profile.image} src={profile.image} />
                                                <Box sx={{
                                                    position: 'absolute', 
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    width: '100%',
                                                    height: '100%'}}>
                                                    <Box sx={{
                                                        position: 'absolute',
                                                        background: '#fff',
                                                        opacity: 0.45,
                                                        width: '100%',
                                                        height: '100%'
                                                     }} />
                                                    <Box sx={{ display: 'flex' }}>
                                                        <CircularProgress />
                                                    </Box>
                                                </Box>
                                            </ImagePreviewBox>
                                        </ImageAttachedPaper>
                                    )
                                }
                                if (profile.image) {
                                    return (
                                        <ImageAttachedPaper key={index} >
                                            <ImagePreviewBox>
                                                <DeleteIconButton size="small" 
                                                     color="inherit" aria-label="menu" onClick={onRemoveImage.bind(this, profile, index)} >
                                                    <DeleteForeverRounded style={{ color: '#999' }} />
                                                </DeleteIconButton>
                                                <Box component="img" alt={profile.image} src={profile.image} />
                                            </ImagePreviewBox>
                                        </ImageAttachedPaper>
                                    )
                                }
                                return (
                                    <ImagePaper key={index}>
                                        <ImageEmptyBox error={!!error('employee_profiles')}>
                                            <ImageRounded
                                                style={{ color: '#999', fontSize: 50 }} />
                                                <input type="file" multiple={true} accept="image/*" onChange={onSelectImage.bind(this)}/>
                                        </ImageEmptyBox>
                                    </ImagePaper>
                                )
                            })
                        }
                    </Grid> 
                </Grid>
                <Grid item container spacing={8}>
                    <Grid item xs={4}>
                        <Typography fontSize={16} fontWeight={700} component="div">
                            Nama Karyawan
                        </Typography>
                        <Typography variant="body2" gutterBottom color="textSecondary">
                            Nama depan dan belakang karyawan.
                        </Typography>
                    </Grid>
                    <Grid item xs={8} container spacing={3}>
                        <Grid item xs>
                            <TextField
                                fullWidth
                                error={!!error('first_name')}
                                name="first_name"
                                placeholder="Fisrt Name"
                                value={first_name}
                                helperText={error('first_name')}
                                variant="outlined"
                                onChange={onUpdateField}
                            />
                        </Grid>
                        <Grid item xs>
                            <TextField
                                fullWidth
                                error={!!error('last_name')}
                                name="last_name"
                                placeholder="Last Name"
                                value={last_name}
                                helperText={error('last_name')}
                                variant="outlined"
                                onChange={onUpdateField}
                            />
                        </Grid>
                    </Grid> 
                </Grid>
                <Grid item container spacing={8}>
                    <Grid item xs={4}>
                        <Typography fontSize={16} fontWeight={700} component="div">
                            Phone
                        </Typography>
                        <Typography variant="body2" gutterBottom color="textSecondary">
                            No. Kontak karyawan.
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <CustomFormat
                            fullWidth
                            error={!!error('phone')}
                            name="phone"
                            placeholder="Phone"
                            value={phone}
                            helperText={error('phone')}
                            variant="outlined"
                            onChange={onUpdateField}
                        />
                    </Grid> 
                </Grid>
                <Grid item container spacing={8}>
                    <Grid item xs={4}>
                        <Typography fontSize={16} fontWeight={700} component="div">
                            Foto Identitas
                        </Typography>
                        <Typography variant="body2" gutterBottom color="textSecondary">
                            Foto identitas karyawan.
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        { 
                            employee_identities.map((identity, index) => {
                                if (identity._destroy) {
                                    return null;
                                }
                                if (identity.image && identity.onUpload && identity.progress !== 100) {
                                    return (
                                        <ImageAttachedPaper key={index} >
                                            <ImagePreviewBox>
                                                <Box component="img" alt={identity.image} src={identity.image} />
                                                <Box sx={{ width: '80%', position: 'absolute', bottom: '15px' }}>
                                                    <LinearProgress sx={{height: '8px', borderRadius: '35px'}} variant="determinate" value={identity.progress || 0} />
                                                </Box>
                                            </ImagePreviewBox>
                                        </ImageAttachedPaper>
                                    )
                                }
                                if (identity.image && identity.onRemove) {
                                    return (
                                        <ImageAttachedPaper key={index} >
                                            <ImagePreviewBox>
                                                <Box component="img" alt={identity.image} src={identity.image} />
                                                <Box sx={{
                                                    position: 'absolute', 
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    width: '100%',
                                                    height: '100%'}}>
                                                    <Box sx={{
                                                        position: 'absolute',
                                                        background: '#fff',
                                                        opacity: 0.45,
                                                        width: '100%',
                                                        height: '100%'
                                                     }} />
                                                    <Box sx={{ display: 'flex' }}>
                                                        <CircularProgress />
                                                    </Box>
                                                </Box>
                                            </ImagePreviewBox>
                                        </ImageAttachedPaper>
                                    )
                                }
                                if (identity.image) {
                                    return (
                                        <ImageAttachedPaper key={index} >
                                            <ImagePreviewBox>
                                                <DeleteIconButton size="small" 
                                                    color="inherit" aria-label="menu" onClick={onRemoveIdentity.bind(this, identity, index)} >
                                                    <DeleteForeverRounded style={{ color: '#999' }} />
                                                </DeleteIconButton>
                                                <Box component="img" alt={identity.image} src={identity.image} />
                                            </ImagePreviewBox>
                                        </ImageAttachedPaper>
                                    )
                                }
                                return (
                                    <ImagePaper key={index}>
                                         <ImageEmptyBox error={!!error('employee_identities')}>
                                            <ImageRounded
                                                style={{ color: '#999', fontSize: 50 }} />
                                                <input type="file" multiple={true} accept="image/*" onChange={onSelectIdentity.bind(this)}/>
                                        </ImageEmptyBox>
                                    </ImagePaper>
                                )
                            })
                        }
                    </Grid> 
                </Grid>
               <Grid item container spacing={8}>
                    <Grid item xs={4}>
                        <Typography fontSize={16} fontWeight={700} component="div">
                            No. KTP / Paspor
                        </Typography>
                        <Typography variant="body2" gutterBottom color="textSecondary">
                            No identitas karyawan.
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <CustomFormat
                            fullWidth
                            error={!!error('identity_number')}
                            name="identity_number"
                            placeholder="No. KTP / Identitas"
                            value={identity_number}
                            helperText={error('identity_number')}
                            variant="outlined"
                            onChange={onUpdateField}
                        />
                    </Grid> 
                </Grid>
                <Grid item container spacing={8}>
                    <Grid item xs={4}>
                        <Typography fontSize={16} fontWeight={700} component="div">
                            Tanggal Lahir
                        </Typography>
                        <Typography variant="body2" gutterBottom color="textSecondary">
                            Tanggal lahir karyawam.
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DatePicker
                                openTo="year"
                                disableFuture
                                value={date_of_birth}
                                format="dd/MM/yyyy"
                                onChange={onSelectDate}
                                renderInput={(params) => <TextField onKeyDown={onKeyDown} {...params} />}
                            />
                        </LocalizationProvider>
                    </Grid> 
                </Grid>
                <Grid item container spacing={8}>
                    <Grid item xs={4}>
                        <Typography fontSize={16} fontWeight={700} component="div">
                            Jenis Kelamin
                        </Typography>
                        <Typography variant="body2" gutterBottom color="textSecondary">
                            Jenis kelamin karyawan.
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <FormControl component="fieldset" error={!!error('gender_option_id')}>
                            <RadioGroup row name="gender_option_id" value={gender_option_id} onChange={onUpdateField}>
                                {
                                    gender_options.map((option, index) => {
                                        return (
                                            <FormControlLabel key={index} value={option._id} control={<Radio />} label={option.name} />
                                        )
                                    })
                                }
                            </RadioGroup>
                            {!!error('gender_option_id') && <FormHelperText>{error('gender_option_id')}</FormHelperText> }
                        </FormControl>
                    </Grid> 
                </Grid>
                <Grid item container spacing={8}>
                    <Grid item xs={4}>
                        <Typography fontSize={16} fontWeight={700} component="div">
                            Marital Status
                        </Typography>
                        <Typography variant="body2" gutterBottom color="textSecondary">
                            Select employee Marital Status.
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Autocomplete
                            options={marital_statuses.sort((a, b) => -b.name.localeCompare(a.name))}
                            getOptionLabel={(option) => option.name}
                            selectOnFocus
                            clearOnBlur
                            filterSelectedOptions
                            disableClearable
                            onChange={(event, option) => {
                                onSelectMarital(option)
                            }}
                            value={maritalValue ? maritalValue : null}
                            renderInput={(params) => <TextField {...params} error={!!error('marital_status_id')} helperText={error('marital_status_id')} placeholder="Pilih Status Perkawinan" variant="outlined" />}
                        />
                    </Grid> 
                </Grid>
               <Grid item container spacing={8}>
                    <Grid item xs={4}>
                        <Typography fontSize={16} fontWeight={700} component="div">
                            Gol. Darah
                        </Typography>
                        <Typography variant="body2" gutterBottom color="textSecondary">
                            Golongan darah karyawan.
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Autocomplete
                            options={blood_types.sort((a, b) => -b.name.localeCompare(a.name))}
                            getOptionLabel={(option) => option.name}
                            selectOnFocus
                            clearOnBlur
                            filterSelectedOptions
                            disableClearable
                            onChange={(event, option) => {
                                onSelectBloodType(option)
                            }}
                            value={bloodValue ? bloodValue : null}
                            renderInput={(params) => <TextField {...params} error={!!error('blood_type_id')} helperText={error('blood_type_id')} placeholder="Pilih Golongan Darah" variant="outlined" />}
                        />
                    </Grid> 
                </Grid>
                <Grid item container spacing={8}>
                    <Grid item xs={4}>
                        <Typography fontSize={16} fontWeight={700} component="div">
                            Agama
                        </Typography>
                        <Typography variant="body2" gutterBottom color="textSecondary">
                            Agama / Kepercayaan karyawan.
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Autocomplete
                            options={religion_options.sort((a, b) => -b.name.localeCompare(a.name))}
                            getOptionLabel={(option) => option.name}
                            selectOnFocus
                            clearOnBlur
                            filterSelectedOptions
                            disableClearable
                            onChange={(event, option) => {
                                onSelectReligion(option)
                            }}
                            value={religionValue ? religionValue : null}
                            renderInput={(params) => <TextField {...params} error={!!error('religion_option_id')} helperText={error('religion_option_id')} placeholder="Pilih Agama" variant="outlined" />}
                        />
                    </Grid> 
                </Grid>
                <Grid item container spacing={8}>
                    <Grid item xs={4}>
                        <Typography fontSize={16} fontWeight={700} component="div">
                            Provinsi, Kota, Kecamatan
                        </Typography>
                        <Typography variant="body2" gutterBottom color="textSecondary">
                            Masukan wilayah supplier beserta kodepos.
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Autocomplete
                            fullWidth
                            onClose={() => {
                                dispatch(commonActions.onUnloadAreas());
                            }}
                            isOptionEqualToValue={(option, value) => true}
                            getOptionLabel={(option) => option.name}
                            options={areaOptions}
                            noOptionsText={!!inputValue && areas.length === 0 && !value ? 'Data tidak ditemukan.' : 'Silahkan masukan kode pos.'}
                            loading={areasFetching}
                            value={value}
                            onChange={(event, newValue) => {
                                onSelectArea(newValue)
                            }}
                            onInputChange={(event, newInputValue) => {
                                setInputValue(newInputValue);
                                if (newInputValue.length < 5 || newInputValue.length > 5) {
                                    return;
                                }
                                dispatch(commonActions.onLoadAreas(newInputValue));
                            }}
                            renderOption={(props, option, { selected }) => (
                                <li {...props} style={{backgroundColor: "#fff"}}>
                                    {option.name}
                                </li>
                            )}
                            inputValue={inputValue}
                            renderInput={(params) => (
                                <TextField
                                {...params}
                                error={!!error('state')}
                                helperText={error('state')}
                                placeholder="Provinsi, Kota, Kecamatan, Kode Pos"
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                    <React.Fragment>
                                        {areasFetching ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                    ),
                                }}
                                />
                            )}/>
                    </Grid> 
                </Grid>
                <Grid item container spacing={8}>
                    <Grid item xs={4}>
                        <Typography fontSize={16} fontWeight={700} component="div">
                            Alamat
                        </Typography>
                        <Typography variant="body2" gutterBottom color="textSecondary">
                            Alamat karyawan.
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <TextField
                            fullWidth
                            error={!!error('address')}
                            rows={6}
                            multiline
                            name="address"
                            placeholder="Alamat"
                            value={address}
                            helperText={error('address')}
                            variant="outlined"
                            onChange={onUpdateField}
                        />
                    </Grid> 
                </Grid> 
            </Grid>
            <Grid item container sx={{marginBottom: '15px'}} justifyContent="space-between" spacing={3}>
                <Grid item>
                </Grid>
                <Grid item>
                    <Grid container spacing={3}>
                        <Grid item>
                            <BackButton 
                                variant="contained"
                                size="large"
                                disableElevation
                                disabled={inProgress}
                                onClick={onCancel}>
                                Batal
                            </BackButton>
                        </Grid>
                        <Grid item>
                            <Button 
                                variant="contained"
                                size="large"
                                color="primary"
                                sx={{minWidth: 195}}
                                onClick={onSubmit}
                                disabled={inProgress}
                                disableElevation>
                                {inProgress ? <CircularProgress size={26} /> : 'Simpan'}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>  
        </Grid>
    )
}

const BackButton = styled(Button)(({theme}) => ({
    color: theme.palette.text.primary,
    backgroundColor: "#FFFFFF",
    '&:hover': {
    backgroundColor: "#FFFFFF",
    },
    fontWeight: 500,
    border: '1px solid #ddd',
    minWidth: 195
}));

const ImageAttachedPaper = styled(Paper)(({theme}) => ({
    width: 141,
    height: 141,
    padding: 3,
    position: 'relative',
    '&:hover': {
        "& button": {
            visibility: 'visible'
        }
    }
}));

const ImagePaper = styled(Paper)(({theme}) => ({
    padding: 10,
    width: 127,
    height: 127,
    position: 'relative'
}));

const DeleteIconButton = styled(IconButton)(({theme}) => ({
    background: '#ddd',
    position: 'absolute',
    right: 10,
    top: 10,
    width: 30,
    height: 30,
    visibility: 'hidden'
}));
const ImagePreviewBox = styled(Box)(({theme}) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    '& img': {
        width: '100%'
    }
}));

const ImageEmptyBox = styled(Box, {
    shouldForwardProp: (prop) => prop !== "error",
  })(({ theme, error }) => ({
    border: error ? '1px dashed #f44336' : '1px dashed #999',
    height: 125,
    width: 125,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 3,
    position: 'relative',
    "& input[type='file']": {
        bottom: 0,
        cursor: 'pointer',
        fontSize: '10em',
        height: '100%',
        left: 0,
        opacity: 0,
        position: 'absolute',
        right: 0,
        top: 0,
        width: '100%',
        zIndex: 3
    },

}));


const ImageBox = styled(Box)(({theme}) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    "& img": {
        width: '100%',
    },
}));
