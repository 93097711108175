import { createTheme } from '@mui/material/styles';

const primary = "#4B00D1";
const secondary = "#6200EE";
const textPrimary = "#112232";

const theme = createTheme({
    typography: {
        fontFamily: [
          'Circular', 
          '-apple-system', 
          'BlinkMacSystemFont', 
          'Roboto', 
          'Helvetica Neue', 'sans-serif'
        ].join(','),
        button: {
          textTransform: 'capitalize'
        }
    },
    palette: {
      primary: {
        main: primary,
      },
      secondary: {
        main: secondary,
        dark: textPrimary
      },
      
    },
});

export default theme;