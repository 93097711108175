import React from 'react';
import { 
    Typography,
    Grid,
    Paper,
    IconButton,
} from '@mui/material';
import * as customerActions from '../../../../../../actions/customerActions';
import { LaunchRounded } from '@mui/icons-material';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import Addresses from './Addresses';

export default function Address() {
    const dispatch = useDispatch();
    const {customer} = useSelector(state => ({
        ...state.customer
    }), shallowEqual); 

    function onShowAddresses() {
        dispatch(customerActions.onShowAddresses());
    }

    const { customer_address } = customer;
    const { recipient, address, state, city, district, code_area, phone } = customer_address
    return (
        <Paper elevation={3} style={{padding: 25, width: '100%'}}>
            <Grid container direction="column" spacing={3}>
                <Grid item xs container>
                    <Grid item xs>
                        <Typography variant="h6" fontSize={16} fontWeight={700}>
                            Alamat Pelanggan
                        </Typography>
                    </Grid>
                    <Grid item>
                        <IconButton size="small" onClick={onShowAddresses}>
                            <LaunchRounded fontSize="small" color="primary" />
                        </IconButton>
                    </Grid>
                </Grid>
                <Grid item container direction="column" spacing={1}>
                    <Grid item container direction="column">
                        <Grid item xs>
                            <Typography variant="body2" color="textSecondary">
                                Penerima
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography component="div" fontWeight={500} variant="body2">
                                {recipient} ({phone})
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item container direction="column">
                        <Grid item xs>
                            <Typography variant="body2" color="textSecondary">
                                Alamat
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography component="div" fontWeight={500} variant="body2">
                                {address}, {state}, {city}, {district}, {code_area}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
             </Grid>
             <Addresses />
        </Paper>
    )
}
