import React, { useState, useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import * as inventoryActions from '../../../../../actions/inventoryActions';
import { Box, Grid, Paper, InputBase, Typography, Stack,
    Card,
    CardMedia,
    CardContent,
    IconButton,
    TablePagination,
    Table,
    Collapse,
    TableContainer,
    Divider,
    TableRow,
    TableCell,
    TableHead,
    TableSortLabel,
    TableBody,
    Tooltip,
} from '@mui/material';
import NumberFormat from 'react-number-format';
import Header from './Header';
import { styled, alpha } from '@mui/material/styles';
import { Inventory2TwoTone, PaymentsTwoTone, SellTwoTone, AssessmentTwoTone, Search, KeyboardArrowDown, KeyboardArrowUp, AccessTimeTwoTone } from '@mui/icons-material';
import { green, red, grey } from '@mui/material/colors';
import DataEmpty from '../../../../Helper/DataEmpty';
import { visuallyHidden } from '@mui/utils';
import Loader from '../../../../Helper/Loader';
import History from './History';
import moment from 'moment';
import 'moment/locale/id';

// export default function formatNumber(num, precision = 2) {
//     const map = [
//       { suffix: 'T', threshold: 1e12 },
//       { suffix: 'B', threshold: 1e9 },
//       { suffix: 'M', threshold: 1e6 },
//       { suffix: 'K', threshold: 1e3 },
//       { suffix: '', threshold: 1 },
//     ];
  
//     const found = map.find((x) => Math.abs(num) >= x.threshold);
//     if (found) {
//       const formatted = (num / found.threshold).toFixed(precision) + found.suffix;
//       return formatted;
//     }
  
//     return num;
//   }

function formatNumber(num, precision = 2) {
    const map = [
      { suffix: 'KT', threshold: 1e15 },
      { suffix: 'T', threshold: 1e12 },
      { suffix: 'M', threshold: 1e9 },
      { suffix: 'K', threshold: 1e6 },
      { suffix: '', threshold: 1e3 },
    ];

  
    const found = map.find((x) => Math.abs(num) >= x.threshold);
    if (found) {
      const value = ((num / found.threshold) * 1000).toFixed(precision)
    //    + found.suffix;
      return  (
            <NumberFormat
                value={value}
                displayType="text"
                thousandSeparator={"."}
                decimalSeparator={","}
                isNumericString
                prefix="Rp "
                suffix={found.suffix}
                decimalScale={0}
                renderText={(value) => <Typography variant="h6" fontSize={16} fontWeight={700} color="#444">
                {value}
            </Typography>}/>
        )
    }
  
    return num;
}

const headCells = [
    { id: 'increment_id', numeric: false, sortable: true, disablePadding: false, label: 'ID Cabang' },
    { id: 'store_name', numeric: false, sortable: true, disablePadding: false, label: 'Nama Cabang' },
    { id: 'unit_price', numeric: true, sortable: true, disablePadding: false, label: 'Harga' },
    { id: 'average_price', numeric: true, sortable: true, disablePadding: false, label: 'Harga Rata-Rata' },
    { id: 'quantity', numeric: true, sortable: true, disablePadding: false, label: 'Stok Tersedia' },
    { id: 'low_stock_notify', numeric: true, sortable: true, disablePadding: false, label: 'Stok Min.' },
    { id: 'status', numeric: true, sortable: true, disablePadding: false, label: 'Status' }
  ];
 
  
function EnhancedTableHead({ order, orderBy, onRequestSort }) {
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => {
                    if (headCell.sortable) {
                        return (
                            <TableCell
                                key={headCell.id}
                                sx={{fontWeight: 700}}
                                align={headCell.numeric ? 'right' : 'left'}
                                padding={headCell.disablePadding ? 'none' : 'normal'}
                                sortDirection={orderBy === headCell.id ? order : false}
                            >
                                <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                                >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                ) : null}
                                </TableSortLabel>
                            </TableCell>
                        )
                    }
                    return (
                        <TableCell
                        key={headCell.id}
                        sx={{fontWeight: 700}}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        >
                            {headCell.label}
                        </TableCell>
                    )
                })}
            </TableRow>
        </TableHead>
    );
}


export default function Inventory() {
    const dispatch = useDispatch();
    const {inventories, inventories_summary, inventories_count, stores} = useSelector(state => ({
        ...state.inventory
    }), shallowEqual); 

    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('name');
    const [query, setQuery] = useState({ page: 0, limit: 15, search: '', store_id: '' });
    const [search, setSearch] = useState(query.search);

    useEffect(() => {
        dispatch(inventoryActions.onLoadInventories(query))
    },[dispatch, query]);
    function onSearch(e) {
        var enterKey = 13;
        if (e.which === enterKey) {
            e.target.blur(); 
            const newQuery = {...query};
            newQuery.page = 0;
            newQuery.search = search;
            setQuery(newQuery)
        }
    }

    const onRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
        if (isAsc) {
          const newQuery = {...query};
          delete newQuery.sort_asc;
          newQuery.sort_desc = property;
          setQuery(newQuery)
        } else {
          const newQuery = {...query};
          delete newQuery.sort_desc;
          newQuery.sort_asc = property;
          setQuery(newQuery)
        }
    };


    const onChangePage = (event, newPage) => {
        const newQuery = {...query};
        newQuery.page = newPage;
        setQuery(newQuery)
    };

    const onChangeRowsPerPage = (event) => {
        const newQuery = {...query};
        newQuery.limit = parseInt(event.target.value, 10);
        newQuery.page = 0;
        setQuery(newQuery)
    };


    function onCollapsed(index, event) {
        const currentItem = inventories[index];
        if (currentItem.expanded) {
            currentItem.expanded = false;
        } else {
            currentItem.expanded = true;
        }
        const newInventories = [...inventories];
        newInventories[index] = currentItem;
        dispatch(inventoryActions.onCollapseInventories(newInventories));
    }

    function onShowHistories(inventory) {
        dispatch(inventoryActions.onShowHistories(inventory));
    }

    if (!inventories || !stores || !inventories_summary) { 
        return <Loader />
    }
    
    function Content() {
        if (inventories.length === 0) {
            return (
                <Grid item xs={12}>
                    <Box sx={{padding: "150px 35px"}}>
                        <Grid container alignItems="center" direction="column" justifyContent="space-between">
                            <Grid item xs>
                                <AssessmentTwoTone color="primary" style={{fontSize: 120}} />
                            </Grid>
                            <Grid item>
                                <Typography fontWeight={700} variant="h6" lineheight="normal">
                                    Tidak ada data.
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            )
        }

        const {page, limit} = query;

        return (
            <Grid item xs={12}>
                <Stack spacing={2}>
                    {inventories.map((item ,index) => {
                        const { image, name, sku, uom, inventories_value, inventories_quantity, average_order, expanded } = item;
                        return (
                            <Item key={index}>
                                <Grid container direction="column">
                                    <Grid item container direction="column" spacing={3}>
                                        <Grid item xs>
                                            <Box sx={{display: 'flex', paddingBottom: '15px'}}>
                                                <Typography fontWeight={700} fontSize={16} color="#444" variant="h6" sx={{display: 'flex'}}>
                                                    <Tooltip title="Kuantitas Barang" placement="top" arrow>
                                                        <Inventory2TwoTone style={{ fontSize: 20, marginRight: '10px', marginTop: '3px' }} color="primary" /> 
                                                    </Tooltip> {inventories_quantity} {uom}
                                                </Typography>
                                                <Slash>
                                                    /
                                                </Slash>
                                                <Typography fontWeight={700} fontSize={16} color="#444" variant="h6" sx={{display: 'flex'}}>
                                                    <Tooltip title="Nilai Persediaan" placement="top" arrow>
                                                        <PaymentsTwoTone style={{ fontSize: 20, marginRight: '10px', marginTop: '3px' }} color="primary" /> 
                                                    </Tooltip> 
                                                    <NumberFormat
                                                        value={inventories_value}
                                                        displayType="text"
                                                        thousandSeparator={"."}
                                                        decimalSeparator={","}
                                                        prefix="Rp "
                                                        isNumericString
                                                        decimalScale={2}
                                                        renderText={(value) => <Typography fontWeight={700} fontSize={16} component="div"  color="#444" variant="h6">
                                                        {value}
                                                        </Typography>}
                                                    />
                                                </Typography>
                                                <Slash>
                                                    /
                                                </Slash>
                                                <Typography fontWeight={700} fontSize={16} color="#444" variant="h6" sx={{display: 'flex'}}>
                                                    <Tooltip title="Rata-Rata Penjualan / Bulan" placement="top" arrow>
                                                        <SellTwoTone style={{ fontSize: 20, marginRight: '10px', marginTop: '3px' }} color="primary" /> 
                                                    </Tooltip> 
                                                    <NumberFormat
                                                        value={average_order}
                                                        displayType="text"
                                                        thousandSeparator={"."}
                                                        decimalSeparator={","}
                                                        prefix="Rp "
                                                        isNumericString
                                                        decimalScale={2}
                                                        renderText={(value) => <Typography fontWeight={700} fontSize={16} color="#444" component="div" variant="h6">
                                                        {value}
                                                        </Typography>}
                                                    />
                                                </Typography>
                                                <Slash>
                                                    /
                                                </Slash>
                                                <Typography style={{display: 'flex'}} onClick={onShowHistories.bind(this, item)} component="div">
                                                    <Tooltip title="Riwayat Persediaan" placement="top" arrow>
                                                        <AccessTimeTwoTone style={{ fontSize: 20, marginRight: '10px', marginTop: '3px' }} color="primary" /> 
                                                    </Tooltip> 
                                                    <Typography color="primary" fontWeight={500} fontSize={14} variant="body2" style={{textTransform: 'capitalize', cursor: 'pointer', marginTop: '3px'}}>
                                                        Riwayat Persediaan
                                                    </Typography>
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item container alignItems="center" justifyContent="flex-end">
                                            <Grid item xs>
                                                <Card variant="outlined" sx={{ display: 'flex', background: "none", border: 0 }}>
                                                    <CardMedia
                                                        component="img"
                                                        sx={{ width: '45px', marginRight: '15px', height: '45px' }}
                                                        image={image}
                                                        alt={name}
                                                    />
                                                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                                        <CardContent sx={{ flex: '1 0 auto', padding: '0px !important' }}>
                                                            <Typography fontWeight={700} variant="subtitle1">
                                                            {name}
                                                            </Typography>
                                                            <Typography fontSize={9} variant="body2" color="textSecondary" component='div' gutterBottom>
                                                                SKU: {sku}
                                                            </Typography>
                                                        </CardContent>
                                                    </Box>
                                                </Card>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <IconButton
                                                    sx={{float: "right"}}
                                                    aria-label="expand row"
                                                    size="small"
                                                    onClick={onCollapsed.bind(this, index)}
                                                >
                                                    {expanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <Collapse in={item.expanded} timeout="auto" unmountOnExit>
                                            <Divider sx={{padding: '15px'}} />
                                            <TableContainer>
                                                <Table sx={{width: '100%'}}>
                                                    <EnhancedTableHead />
                                                    <TableBody>
                                                    {
                                                        item.store_inventories.map((inventory, index) => {
                                                            const { increment_id, store, unit_price, average_price, quantity, status, low_stock_notify } = inventory;
                                                            return (
                                                                <TableRow
                                                                    hover
                                                                    tabIndex={-1}
                                                                    key={index} >
                                                                    <ItemTableCell>
                                                                        {increment_id}
                                                                    </ItemTableCell>
                                                                    <ItemTableCell>
                                                                        {store}
                                                                    </ItemTableCell>
                                                                    <ItemTableCell align="right">
                                                                        <NumberFormat
                                                                            value={unit_price}
                                                                            displayType="text"
                                                                            thousandSeparator={"."}
                                                                            decimalSeparator={","}
                                                                            prefix="Rp "
                                                                            isNumericString
                                                                            decimalScale={2}
                                                                         
                                                                        />
                                                                    </ItemTableCell>
                                                                    <ItemTableCell align="right">
                                                                        <NumberFormat
                                                                            value={average_price}
                                                                            displayType="text"
                                                                            thousandSeparator={"."}
                                                                            decimalSeparator={","}
                                                                            prefix="Rp "
                                                                            isNumericString
                                                                            decimalScale={2}
                                                                          
                                                                        />
                                                                    </ItemTableCell>
                                                                    <ItemTableCell align="right">
                                                                        <NumberFormat
                                                                            value={quantity}
                                                                            displayType="text"
                                                                            thousandSeparator={"."}
                                                                            decimalSeparator={","}
                                                                            isNumericString
                                                                            decimalScale={2}
                                                                        />  {uom}
                                                                    </ItemTableCell>
                                                                    <ItemTableCell align="right">
                                                                        <NumberFormat
                                                                            value={low_stock_notify}
                                                                            displayType="text"
                                                                            thousandSeparator={"."}
                                                                            decimalSeparator={","}
                                                                            isNumericString
                                                                            decimalScale={2}
                                                                        />  {uom}
                                                                    </ItemTableCell>
                                                                    
                                                                    <ItemTableCell align="right">
                                                                        {status}
                                                                    </ItemTableCell>
                                                                </TableRow>
                                                            );
                                                        })
                                                    }
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Collapse>
                                    </Grid>
                                </Grid>
                            </Item>
                        )
                    })}
                </Stack>
                <TablePagination
                    sx={{marginTop: '15px'}}
                    rowsPerPageOptions={[15, 20, 25]}
                    component="div"
                    count={inventories_count}
                    rowsPerPage={limit}
                    page={page}
                    onPageChange={onChangePage}
                    onRowsPerPageChange={onChangeRowsPerPage}
                />
            </Grid>
        )
    }

    const { inventories_value, inventories_quantity, inventories_size, lowest_est_margins, highest_est_margins } = inventories_summary;
    return (
        <ContentBox>
            <Header
                query={query}
                setQuery={setQuery}
                stores={stores} />
            <Grid container spacing={3}>
                <Grid item xs={12} container spacing={3}>
                    <Grid item xs>
                        <ContentPaper elevation={3}>
                            <Typography sx={{marginBottom: '15px'}} variant="body2" fontSize={14} fontWeight={500} color="#444">
                                Est. Laba Penjualan
                            </Typography>
                            <Typography sx={{display: 'flex'}} variant="h6" component="div" fontSize={16} fontWeight={700} color="#444">
                                {formatNumber(lowest_est_margins)} <Box sx={{margin: "0px 5px"}}>-</Box> {formatNumber(highest_est_margins)}
                            </Typography>
                        </ContentPaper>                      
                    </Grid>
                    <Grid item xs>
                        <ContentPaper elevation={3}>
                            <Typography sx={{marginBottom: '15px'}} variant="body2" fontSize={14} fontWeight={500} color="#444">
                                Nilai Persediaan
                            </Typography>
                            {formatNumber(inventories_value)}
                        </ContentPaper>                      
                    </Grid>
                    <Grid item xs>
                        <ContentPaper elevation={3}>
                            <Typography sx={{marginBottom: '15px'}} variant="body2" fontSize={14} fontWeight={500} color="#444">
                                Jumlah Kuantitas
                            </Typography>
                            <NumberFormat
                                value={inventories_quantity}
                                displayType="text"
                                thousandSeparator={"."}
                                decimalSeparator={","}
                                isNumericString
                                decimalScale={2}
                                renderText={(value) => <Typography variant="h6" fontSize={16} fontWeight={700} color="#444">
                                {value}
                            </Typography>}/>
                        </ContentPaper>                      
                    </Grid>
                    <Grid item xs>
                        <ContentPaper elevation={3}>
                            <Typography sx={{marginBottom: '15px'}} variant="body2" fontSize={14} fontWeight={500} color="#444">
                                Jumlah Jenis
                            </Typography>
                            <NumberFormat
                                value={inventories_size}
                                displayType="text"
                                thousandSeparator={"."}
                                decimalSeparator={","}
                                isNumericString
                                decimalScale={0}
                                renderText={(value) => <Typography variant="h6" fontSize={16} fontWeight={700} color="#444">
                                {value}
                            </Typography>}/>
                        </ContentPaper>                      
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Paper sx={{padding: "15px"}} elevation={3}>
                        <Grid container alignItems="center" justifyContent="space-between">
                            <Grid item>
                                <SearchField
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                    onKeyDown={onSearch}>
                                    <SearchIconWrapper>
                                        <Search />
                                    </SearchIconWrapper>
                                    <StyledInputBase
                                    placeholder="Cari…"/>
                                </SearchField>
                            </Grid>
                            <Grid item>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                {Content()}
            </Grid>
            <History />
            {/* <Paper sx={{ width: '100%', mb: 2, mt: '15px' }} elevation={3}>
                {Content()}
            </Paper> */}
        </ContentBox>
    )
}

const ContentBox = styled(Box)(({theme}) => ({
    padding: '25px 0',
    [theme.breakpoints.down('sm')]: {
        padding: '15px 15px 0 15px',
    },
    [theme.breakpoints.down('md')]: {
        padding: '15px 15px 0 15px',
    },
}));

const ContentPaper = styled(Paper)(({theme}) => ({
    padding: '25px',   
    display: 'flex',
    flexDirection: 'column'
}));

const SearchField = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.black, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.black, 0.25),
    },
    marginLeft: 0,
    width: '100%',
}));
  
const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));
  
const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: '12ch',
        '&:focus': {
          width: '20ch',
        },
      },
    },
  }));


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(3),
    color: theme.palette.text.secondary,
}));

const ItemTableCell = styled(TableCell)(({theme}) => ({
    padding: '15px 16px'
}));


const Slash = styled(Typography)(({theme}) => ({
    margin: '0 10px',
    color: 'var(--N75, #E5E7E9)'
}));