import React from 'react';
import {Box, 
        Grid,
        Button,
        Typography} from '@mui/material';
import { styled } from '@mui/material/styles';
import customerImg from '../../../assets/images/home/prices/customer.png';
import chartImg from '../../../assets/images/home/prices/chart.png';
import stockImg from '../../../assets/images/home/prices/stock.png';
import posStockImg from '../../../assets/images/home/prices/pos-stock.png';
import shiftImg from '../../../assets/images/home/prices/pos-shift.png';
import bp1Img from '../../../assets/images/home/prices/pos-bp1.png';
import orderImg from '../../../assets/images/home/prices/pos-order.png';

export default function Price() {
   
    return (
        <SectionBox>
               <BackgroundBox />
            <ContentWrapperBox>
                <ContentGrid container alignItems="center">
                    <ImageGrid item container xs={12} sm={6}>
                        <ContentImageBox>
                            <ContentBox>
                                <ImageColOneBox>
                                    <ImageContentBox>
                                        <ImageBox component="img" src={posStockImg} />
                                    </ImageContentBox>
                                    <ImageContentBox>
                                        <ImageBox component="img" src={shiftImg} />
                                    </ImageContentBox>
                                </ImageColOneBox>
                                <ImageColSecondBox>
                                    <ImageContentBox>
                                        <ImageBox component="img" src={customerImg} />
                                    </ImageContentBox>
                                    <ImageContentBox>
                                        <ImageBox component="img" src={chartImg} />
                                    </ImageContentBox>
                                    <ImageContentBox>
                                        <ImageBox component="img" src={stockImg} />
                                    </ImageContentBox>
                                </ImageColSecondBox>
                                <ImageColThirdBox>
                                    <ImageContentBox>
                                        <ImageBox component="img" src={bp1Img} />
                                    </ImageContentBox>
                                    <ImageContentBox>
                                        <ImageBox component="img" src={orderImg} />
                                    </ImageContentBox>
                                </ImageColThirdBox>
                            </ContentBox>
                        </ContentImageBox>
                    </ImageGrid>
                    <MobileImageGrid item xs={12} sm={6}>
                        <MobileImageFirstBox component="img" src={orderImg} />
                        <MobileImageSecondBox component="img" src={customerImg} />
                    </MobileImageGrid>
                    <TextGrid item xs={12} sm={6}>
                        <TextBox>
                            <Typography
                                component="span"
                                variant="h4"
                                color="primary"
                                style={{textDecoration: 'line-through'}}>
                                Rp. 249.000 
                            </Typography>
                            <Typography
                                component="span"
                                variant="h6">
                            / Outlet
                            </Typography>
                            <FreeText
                                variant="h3"
                                color="primary">
                                GRATIS
                            </FreeText>
                            <Typography
                                variant="h6">
                                Nikmati semua fitur kami tanpa biaya apapun.
                            </Typography>
                            <Typography
                                variant="h6">
                                Laporan penjualan, cetak struk, cetak nota, rekap transaksi, dll.
                            </Typography>
                            <TryButton variant="contained" size="large" color="primary">
                                COBA GRATIS SEKARANG
                            </TryButton>
                        </TextBox>  
                    </TextGrid>
                </ContentGrid>
            </ContentWrapperBox>
            <FooterBox component="svg" preserveAspectRatio="none" x={0} y={0} viewBox="0 0 1920 100.1">
                <path fill="#ffffff" d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"></path>
            </FooterBox>
        </SectionBox>
    )

    
}

const SectionBox = styled(Box)(({theme}) => ({
    backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0), rgb(247, 250, 255) 100%)',
    backgroundRepeat: 'repeat-x',
    position: 'relative',
    marginTop: 175,
    [theme.breakpoints.between('xs', 'sm')]: {
        paddingLeft: 15,
        paddingRight: 15,
        paddingBottom: 15,
    },
    [theme.breakpoints.between('sm', 'lg')]: {
        paddingLeft: 25,
        paddingRight: 25,
        paddingBottom: 35,
    },
    [theme.breakpoints.up('xl')]: {
        paddingLeft: 'calc((100% - 1284px)/2)',
        paddingRight: 'calc((100% - 1284px)/2)'
    },
    position: 'relative',
    paddingBottom: 65,
    zIndex: 55
}));

const BackgroundBox = styled(Box)(({theme}) => ({
    // backgroundColor: 'rgb(109, 75, 255)',
    // backgroundImage: `url(${headerBg})`,
    // backgroundRepeat: 'no-repeat',
    // // backgroundSize: 'cover',
    // backgroundPosition: 'bottom',
    // position: 'absolute',
    // top: 0,
    // left: 0,
    // right: 0,
    // height: '75rem',
}));

const ContentWrapperBox = styled(Box)(({theme}) => ({
    paddingBottom: 60,
    // [theme.breakpoints.between('xs', 'sm')]: {
    //     paddingRight: 15,
    //     paddingLeft: 15,
    //     paddingBottom: 95
    // },
    // [theme.breakpoints.between('sm', 'lg')]: {
    //     paddingLeft: 25,
    //     paddingRight: 25
    // }
}));


const ContentImageBox = styled(Box)(({theme}) => ({
    transform: 'rotate(20deg)',
    display: 'block',
}));

const ContentBox = styled(Box)(({theme}) => ({
    display: 'flex',
    width: '165%',
    left: '50%',
    top: 0,
    transform: 'translate3d(-35%, 0%, 0px)',
    [theme.breakpoints.between('xs', 'sm')]: {
        display: 'none'
    }
}));

const ImageColOneBox = styled(Box)(({theme}) => ({
    marginLeft: 8,
    marginRight: 8,
    marginTop: '15%'
}));

const ImageColSecondBox = styled(Box)(({theme}) => ({
    marginLeft: 8,
    marginRight: 8
}));

const ImageColThirdBox = styled(Box)(({theme}) => ({
    marginLeft: 8,
    marginRight: 8,
    marginTop: '10%'
}));

const ImageContentBox = styled(Box)(({theme}) => ({
    padding: 8,
    backgroundColor: 'rgb(255, 255, 255)',
    borderRadius: 8,
    boxShadow: 'rgb(140 152 164 / 13%) 0px 6px 24px 0px',
    marginTop: 16,
    [theme.breakpoints.between('xs', 'md')]: {
        padding: 5
    },
}));

const ImageBox = styled(Box)(({theme}) => ({
   height: '100%',
   width: '100%',
   maxWidth: 320
}));

const TextBox = styled(Box)(({theme}) => ({
    width: '100%',
    [theme.breakpoints.between('xs', 'sm')]: {
        paddingTop: '75%'
    }
 }));

const ContentGrid = styled(Grid)(() => ({
    boxSizing: 'border-box',
    display: 'flex',
    flexFlow: 'row wrap',
    width: '100%'
}));

const ImageGrid = styled(Grid)(({theme}) => ({
    [theme.breakpoints.between('xs', 'sm')]: {
        display: 'none',
    },
}));

const MobileImageGrid = styled(Grid)(({theme}) => ({
    display: 'none',
    position: 'relative',
    [theme.breakpoints.between('xs', 'sm')]: {
        display: 'block',
    },
}));

const MobileImageFirstBox = styled(Box)(({theme}) => ({
    padding: 8,
    backgroundColor: 'rgb(255, 255, 255)',
    borderRadius: 8,
    boxShadow: 'rgb(140 152 164 / 13%) 0px 6px 24px 0px',
    position: 'absolute',
    width: '70%'
}));

const MobileImageSecondBox = styled(Box)(({theme}) => ({
    padding: 8,
    backgroundColor: 'rgb(255, 255, 255)',
    borderRadius: 8,
    boxShadow: 'rgb(140 152 164 / 13%) 0px 6px 24px 0px',
    position: 'absolute',
    width: '80%',
    right: 0,
    top: 50
}));

const TextGrid = styled(Grid)(({theme}) => ({
    paddingLeft: 135,
    [theme.breakpoints.between('xs', 'sm')]: {
        paddingLeft: 0
    },
    [theme.breakpoints.between('sm', 'md')]: {
        paddingLeft: 75,
        paddingTop: 0
    },
}));

const FreeText = styled(Typography)(({ theme }) => ({
    marginTop: 35,
    marginBottom: 15
}));



const TryButton = styled(Button)(({ theme }) => ({
    height: 50,
    marginTop: 65,
    [theme.breakpoints.between('xs', 'md')]: {
        width: '100%'
    },
}));

const FooterBox = styled(Box)(({theme}) => ({
    position: 'absolute',
    left: 0,
    right: 0,
    zIndex: 99,
    marginBottom: 25
}));