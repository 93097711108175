import React from 'react';
import { 
    Box, 
    Fade,
    Button,
    Typography,
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions
} from '@mui/material';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { onCancelRegister } from '../../../../actions/loginActions';
import { onNewUser } from '../../../../actions/registerActions';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Fade direction="up" ref={ref} {...props} />;
});

export default function UserExists() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { createNewUser, user } = useSelector(state => ({
        ...state.login
    }), shallowEqual);

    function onClose() {
        dispatch(onCancelRegister());
    }

    function onNext() {
        dispatch(onNewUser(user));
        navigate('/sign-up')
    }

    function Label() {
        if ((/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(user.email_phone))) {
            return (
                <Typography>
                    Lanjut buat akun dengan email
                </Typography>
            )
        } else {
            return (
                <Typography>
                    Lanjut buat akun dengan No. HP
                </Typography>
            )
        } 
    }

    function EmailPhone() {
        if ((/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(user.email_phone))) {
            return (
                <Typography onClick={onNext} fontWeight={500} sx={{ cursor: 'pointer'}} color="primary">
                    {user.email_phone}
                </Typography>
            )
        } else {
            return (
                <Typography onClick={onNext} fontWeight={500} sx={{ cursor: 'pointer'}} color="primary">
                    {user.email_phone}
                </Typography>
            )
        } 
    }

    return(
        <Dialog
            open={createNewUser}
            fullWidth
            maxWidth="xs"
            TransitionComponent={Transition}
            scroll={'paper'}>
            <DialogTitle>Akun tidak ditemukan.</DialogTitle>
            <DialogContent>
                <Box>
                    {Label()}
                    {EmailPhone()}
                </Box>
            </DialogContent>    
            <DialogActions sx={{marginTop: 5}}>
                <Button onClick={onClose}>Batal</Button>
            </DialogActions>
        </Dialog>
    )
}

