import React, { useState, useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { onLoadStoreAdjustments } from '../../../../../actions/storeAdjustmentActions';
import { TableContainer,
         Table,
         TableHead,
         TableRow,
         TableCell,
         TableBody,
         Paper,
         TableSortLabel,
         TablePagination,
         Box,
         IconButton
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Header from './Header';
import { visuallyHidden } from '@mui/utils';
import DataEmpty from '../../../../Helper/DataEmpty';
import Loader from '../../../../Helper/Loader';
import { FirstPage, LastPage, KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';

const headCells = [
    { id: 'increment_id', numeric: false, sortable: true, disablePadding: false, label: 'ID Transfer' },
    { id: 'store', numeric: false, sortable: true, disablePadding: false, label: 'Store' },
    { id: 'items_count', numeric: true, sortable: false, disablePadding: false, label: 'Total Barang' },
    { id: 'row_total', numeric: true, sortable: false, disablePadding: false, label: 'Gain / Loss' },
    { id: 'created_at', numeric: true, sortable: true, disablePadding: false, label: 'Date' },
    { id: 'status', numeric: true, sortable: true, disablePadding: false, label: 'Status' },
];
 
  
function EnhancedTableHead({ order, orderBy, onRequestSort }) {
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => {
                    if (headCell.sortable) {
                        return (
                            <TableCell
                                key={headCell.id}
                                sx={{fontWeight: 700}}
                                align={headCell.numeric ? 'right' : 'left'}
                                padding={headCell.disablePadding ? 'none' : 'normal'}
                                sortDirection={orderBy === headCell.id ? order : false}
                            >
                                <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                                >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                ) : null}
                                </TableSortLabel>
                            </TableCell>
                        )
                    }
                    return (
                        <TableCell
                        key={headCell.id}
                        sx={{fontWeight: 700}}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        >
                            {headCell.label}
                        </TableCell>
                    )
                })}
            </TableRow>
        </TableHead>
    );
}

function TablePaginationActions(props) {
    const { count, page, rowsPerPage, onPageChange } = props;
  
    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };
  
    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };
  
    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };
  
    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
  
    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          <FirstPage />
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          <KeyboardArrowLeft />
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
        <KeyboardArrowRight />
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
        <LastPage />
        </IconButton>
      </Box>
    );
}

export default function Adjuestment() {
    const dispatch = useDispatch();
    const { store_adjustments, store_adjustments_count } = useSelector(state => ({
        ...state.store_adjustment
    }), shallowEqual);

    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('name');
    const [query, setQuery] = useState({ page: 0, limit: 5, search: '' });

    useEffect(() => {
        dispatch(onLoadStoreAdjustments(query))
    },[dispatch, query]);

    const onRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
        if (isAsc) {
          const newQuery = {...query};
          delete newQuery.sort_asc;
          newQuery.sort_desc = property;
          setQuery(newQuery)
        } else {
          const newQuery = {...query};
          delete newQuery.sort_desc;
          newQuery.sort_asc = property;
          setQuery(newQuery)
        }
    };

    const onChangePage = (event, newPage) => {
        const newQuery = {...query};
        newQuery.page = newPage;
        setQuery(newQuery)
    };

    const onChangeRowsPerPage = (event) => {
        const newQuery = {...query};
        newQuery.limit = parseInt(event.target.value, 10);
        newQuery.page = 0;
        setQuery(newQuery)
    };

    if (!store_adjustments) {
        return (
            <Loader />
        )
    }


    function Content() {
        if (store_adjustments.length === 0) {
            return (
                <DataEmpty />
            )
        }

        const {page, limit} = query;
        return (
            <Box>
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}>
                        <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={onRequestSort}
                        />
                        <TableBody>
                        {
                                store_adjustments.map((adjustment) => {
                                    const { increment_id, store_name, items_count, created_at, row_total, status_title} = adjustment;
                                    return (
                                        <TableRow
                                            hover
                                            onClick={() => {
                                                window.open(`/dashboard/store-adjustments/${adjustment.id}/edit`);
                                            }}
                                            tabIndex={-1}
                                            key={adjustment.id}
                                        >
                                            <ItemTableCell component="th" scope="row">{increment_id}</ItemTableCell>
                                            <ItemTableCell>{store_name}</ItemTableCell>
                                            <ItemTableCell align="right">{items_count}</ItemTableCell>
                                            <ItemTableCell align="right">{row_total}</ItemTableCell>
                                            <ItemTableCell align="right">{created_at}</ItemTableCell>
                                            <ItemTableCell align="right">{status_title}</ItemTableCell>
                                        </TableRow>
                                    );
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={store_adjustments_count}
                    rowsPerPage={limit}
                    page={page}
                    onPageChange={onChangePage}
                    onRowsPerPageChange={onChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                />
            </Box>
        )
    }
    
    return(
        <ContentBox>
            <Header
                query={query}
                setQuery={setQuery} />
            <Paper sx={{ width: '100%', mb: 2, mt: '15px' }} elevation={3}>
                {Content()}
            </Paper>
        </ContentBox>
    )
}

const ContentBox = styled(Box)(({theme}) => ({
    padding: '25px 0',
    [theme.breakpoints.down('sm')]: {
        padding: '15px 15px 0 15px',
    },
    [theme.breakpoints.down('md')]: {
        padding: '15px 15px 0 15px',
    },
}));


const ItemTableCell = styled(TableCell)(({theme}) => ({
    padding: '15px 16px'
}));
