import * as types from '../actions/actionTypes';

const defaultState = {
	treeOpenIds: []
}

export default function category (state = defaultState, action) {
	function getDestroyedImages(images) {
		return images.filter(el => {
	    	return el._destroy === true;
	  	}).length;
	}

	let categoryImages;
	let currentUpload;
	let currentRemove;
	switch(action.type) {
		case types.CATEGORIES_LOADED:
			return {
				...state,
				categories: action.error ? null : action.payload.categories
			};
		case types.CATEGORIES_SET_TREE_IDS:
			return {
				...state,
				treeOpenIds: action.newOpenIds
			};
		case types.NEW_CATEGORY_LOADED:
			return {
				...state,
				category: {
					parent_id: action.parent_id ? action.parent_id : '',
					name: '',
					category_images: [{
			    		image: ''
			    	}],
			    	status: false
				}
			};
		case types.CATEGORY_IMAGE_UPLOAD_REQUESTED:
			categoryImages = [...state.category.category_images];
			currentUpload = categoryImages[state.category.category_images.length - 1];
			currentUpload.image = action.image;
			currentUpload.onUpload = true;
			return {
				...state,
				category: {...state.category, category_images: categoryImages}
			};
		case types.CATEGORY_IMAGE_UPLOADED:
			categoryImages = [...state.category.category_images];
			categoryImages[action.index] = action.payload;
			return {
				...state,
				category: action.payload ? {...state.category, category_images: categoryImages} : state.category
			};
		case types.CATEGORY_IMAGE_UPLOADING:
			categoryImages = [...state.category.category_images];
			categoryImages[action.index] = action.image;
			return {
				...state,
				category: {...state.category, category_images: categoryImages}
			};
		case types.CATEGORY_IMAGE_REMOVE_REQUESTED:
			categoryImages = [...state.category.category_images];
			currentRemove = categoryImages[action.index];
			currentRemove.onRemove = true;
			return {
				...state,
				category: {...state.category, category_images: categoryImages}
			};
		case types.CATEGORY_IMAGE_REMOVED:
			categoryImages = [...state.category.category_images];
			categoryImages[action.index] = action.payload;
			const emptyImages = categoryImages.length - getDestroyedImages(categoryImages);
			if (emptyImages === 0) {
				categoryImages.push({image: ''})
			}
			return {
				...state,
				category: action.payload ? {...state.category, category_images: categoryImages} : state.category
			};
		case types.EDIT_CATEGORY_LOADED:
			return {
				...state,
				category: action.error ? null : action.payload.category
			};
		case types.SET_CATEGORY_PARENT_ID:
            return {
                ...state,
                parent_id: action.parent_id
			};
		case types.CATEGORY_UPDATED:
			return {
				...state,
				category: action.category
			};
		case types.CATEGORY_CREATED:
		case types.CATEGORY_EDITED:
			return {
				...state,
				inProgress: false,
				errors: action.error ? action.payload.errors : null
			};
		case types.CATEGORY_FORM_UNLOADED:
			return {
				...state,
				category: null,
				errors: [],
				inProgress: false,
				parent_id: null
			};
		case types.ASYNC_START:
			if ( action.subtype === types.CATEGORY_CREATED ||
					action.subtype === types.CATEGORY_EDITED
				) {
				return { 
					...state, 
					inProgress: true };
			};
			return state;
		default:
			return state;
	}
}