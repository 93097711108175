import * as types from '../actions/actionTypes';

const defaultState = {
	
}

export default (state = defaultState, action) => {
	switch(action.type) {
        case types.DASHBOARD_LOADED:
            return {
                ...state,
                stores: action.payload ? action.payload.stores : null,
                reports: action.payload ? action.payload.reports : null,
            };
		// case types.ASYNC_START:
		// 	if ( action.subtype === types.CUSTOMER_GROUP_CREATED ||
        //          action.subtype === types.CUSTOMER_GROUP_EDITED
		// 		) {
		// 		return { 
		// 			...state, 
		// 			inProgress: true };
		// 	};
		// 	return state;
		default:
			return state;
	}
}