import * as types from '../actions/actionTypes';

const defaultState = {
	
}

export default (state = defaultState, action) => {
    function getDestroyedImages(images) {
		return images.filter(el => {
	    	return el._destroy === true;
	  	}).length;
	}

	function getEmptyImages(images) {
		const newImages = [];
        const totalImages = images.filter(el => {
            return el.image && !el._destroy;
        }).length;

        const imagesCount = 5 - totalImages;
        for (let i = 0; i < imagesCount; i++) {
            newImages.push({image: ''})
        };
		
		return newImages;
	}

    let customerDisplays;
    let currentRemove;
	let emptyImages;
	switch(action.type) {
        case types.CUSTOMER_DISPLAYS_LOADED:
            return {
                ...state,
                customer_displays: action.payload ? action.payload.customer_displays : null,
                customer_displays_count: action.error ? null : action.payload.customer_displays_count,
            };
        case types.NEW_CUSTOMER_DISPLAY_LOADED:
            return {
                ...state,
                customer_display: {
                    customer_display_images: [],
                    start_date: new Date(),
                    end_date: new Date(),
                    name: "",
                    active: true
                },
                emptyImages: [
					{image: ''},
					{image: ''},
					{image: ''},
					{image: ''},
					{image: ''}
				],
                errors: null
            };
        case types.EDIT_CUSTOMER_DISPLAY_LOADED:
            customerDisplays = [...action.payload.customer_display.customer_display_images];
			emptyImages = customerDisplays.length - getDestroyedImages(customerDisplays);
			if (emptyImages === 0) {
				customerDisplays.push({image: ''})
			}
            return {
                ...state,
                customer_display: action.payload ? action.payload.customer_display : null,
                emptyImages: action.payload ? getEmptyImages(customerDisplays) : state.emptyImages,
                errors: null
            };
        case types.CUSTOMER_DISPLAY_UPDATED:
			return {
				...state,
				customer_display: action.customer_display
            };
        case types.CUSTOMER_DISPLAY_CREATED:
		case types.CUSTOMER_DISPLAY_EDITED:
			return {
				...state,
				inProgress: false,
				errors: action.error ? action.payload.errors : null
            };
        case types.CUSTOMER_DISPLAY_UPLOAD_REQUESTED:
			customerDisplays = [...state.customer_display.customer_display_images];
			customerDisplays.push({image: action.image, onUpload: true})
			return {
				...state,
				customer_display: {...state.customer_display, customer_display_images: customerDisplays}
			};
		case types.CUSTOMER_DISPLAY_UPLOADING:
			customerDisplays = [...state.customer_display.customer_display_images];
			customerDisplays[action.index] = action.image;
			return {
				...state,
				customer_display: {...state.customer_display, customer_display_images: customerDisplays}
			};
		case types.CUSTOMER_DISPLAY_UPLOADED:
			customerDisplays = [...state.customer_display.customer_display_images];
			customerDisplays[action.index] = action.payload;
			return {
				...state,
				customer_display: action.payload ? {...state.customer_display, customer_display_images: customerDisplays} : state.customer_display
			};
		case types.CUSTOMER_DISPLAY_REMOVE_REQUESTED:
			customerDisplays = [...state.customer_display.customer_display_images];
			currentRemove = customerDisplays[action.index];
			currentRemove.onRemove = true;
			return {
				...state,
				customer_display: {...state.customer_display, customer_display_images: customerDisplays}
			};
		case types.CUSTOMER_DISPLAY_REMOVED:
			customerDisplays = [...state.customer_display.customer_display_images];
			if (action.payload._id) {
				customerDisplays[action.index] = action.payload;
			} else {
				customerDisplays.splice(action.index, 1)
			}
			emptyImages = customerDisplays.length - getDestroyedImages(customerDisplays);
			if (emptyImages === 0) {
				customerDisplays.push({image: ''})
			}
			return {
				...state,
				customer_display: action.payload ? {...state.customer_display, customer_display_images: customerDisplays} : state.customer_display,
				emptyImages: getEmptyImages(customerDisplays)
			};
        case types.CUSTOMER_DISPLAY_FORM_UNLOADED:
            return {
                ...state,
                customer_display: null,
                errors: [],
                inProgress: false,
            };
		case types.ASYNC_START:
			if ( action.subtype === types.CUSTOMER_DISPLAY_CREATED ||
					action.subtype === types.CUSTOMER_DISPLAY_EDITED
				) {
				return { 
					...state, 
					inProgress: true };
			};
			return state;
		default:
			return state;
	}
}