import React from 'react';
import { 
    Box, 
    Typography, 
    Button, 
    Grid,
    } from '@mui/material';
import { styled } from '@mui/material/styles';
import headerBg from '../../../assets/images/header-bg.png';
import dashboard from '../../../assets/images/home/dashboard.png';
import play from '../../../assets/images/google-play.svg';
import Nav from '../Common/Nav';
import { useNavigate } from "react-router-dom";

export default function Banner() {
    const navigate = useNavigate();
    return (
        <SectionBox>
            <Nav />
            <BackgroundBox />
            <Box>
                <BannerGrid>
                    <Grid item container xs={12} sm={6} spacing={2}>
                        <Grid item>
                            <H3Text variant="h3" gutterBottom>
                                Kelola Bisnis Jadi Mudah.
                            </H3Text>
                        </Grid>
                        <Grid item container direction="column">
                            <Grid item>
                                <Box lineHeight="normal">
                                    <DescText variant="h6">
                                        Satu harga untuk semua fitur,
                                    </DescText>
                                    <DescText variant="h6">
                                        tingkatkan efisiensi dan efektifitas bisnis anda.
                                    </DescText>
                                </Box>
                            </Grid>
                            <Grid item xs>
                                <ActionBox>
                                <TryButton onClick={() => navigate('/sign-up')} variant="contained" size="large">
                                    COBA GRATIS SEKARANG
                                </TryButton>
                                <AppsBox component="img" onClick={() => {
                                    window.location.assign('https://play.google.com/store/apps/details?id=com.alipos');
                                }} src={play} />
                            </ActionBox>
                            </Grid>
                        </Grid>
                        {/* <Box lineHeight={2}>
                            <H3Text variant="h3" gutterBottom>
                                Kelola Bisnis Jadi Mudah.
                            </H3Text>
                        </Box> */}
                      
                       
                    </Grid>
                    <Grid item container justifyContent="center" xs={12} sm={6}>
                        <ImageContentBox>
                            <ImageDashboard alt="dashboard" src={dashboard} />
                            <MobileAppsBox component="img" onClick={() => {
                                    window.location.assign('https://play.google.com/store/apps/details?id=com.alipos');
                                }} src={play} />
                        </ImageContentBox>
                    </Grid>
                </BannerGrid>
            </Box>
        </SectionBox>
    )
}

const SectionBox = styled(Box)(({theme}) => ({
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 30,
    paddingTop: 155,
    paddingLeft: 75,
    paddingRight: 75,
    height: '30rem',
    [theme.breakpoints.between('xs','sm')]: {
        paddingLeft: 15,
        paddingRight: 15,
        paddingTop: 115,
        height: '55rem',
    },
    [theme.breakpoints.between('sm', 'lg')]: {
        paddingLeft: 25,
        paddingRight: 25
    },
    [theme.breakpoints.up('xl')]: {
        paddingLeft: 'calc((100% - 1284px)/2)',
        paddingRight: 'calc((100% - 1284px)/2)'
    },
    position: 'relative'
}));

const BackgroundBox = styled(Box)(({theme}) => ({
    backgroundColor: 'rgb(109, 75, 255)',
    backgroundImage: `url(${headerBg})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'bottom',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: '75rem',
    [theme.breakpoints.between('xs','sm')]: {
        height: '88rem',
    },
    // [theme.breakpoints.between('sm', 'lg')]: {
    //     height: '85rem',
    // },
}));

const BannerGrid = styled(Grid)(() => ({
    boxSizing: 'border-box',
    display: 'flex',
    flexFlow: 'row wrap',
    width: '100%',
    zIndex: 99,
    position: 'relative'
}));

const ActionBox = styled(Box)(({theme}) => ({
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
        alignItems: 'center',
        width: '100%'
    }
}));

 
const AppsBox = styled(Box)(({theme}) => ({
    marginTop: 60,
    width: 160,
    [theme.breakpoints.down('sm')]: {
        display: 'none'
    },
    [theme.breakpoints.up('md')]: {
        marginTop: 45
    },
}));

const MobileAppsBox = styled(Box)(({theme}) => ({
    marginTop: 30,
    width: 160,
    display: 'none',
    [theme.breakpoints.down('sm')]: {
        display: 'block'
    },
    
}));

const H3Text = styled(Typography)(({ theme }) => ({
    color: "#FFFFFF",
    fontWeight: 700
}));

const DescText = styled(Typography)(({ theme }) => ({
    color: "#FFFFFF"
}));

const ImageContentBox = styled(Box)(({theme}) => ({
    marginLeft: 'auto',
    marginRight: 'auto',
    perspective: 1500,
    transformStyle: 'preserve-3d',
    perspectiveOrigin: '0px center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
}));

const ImageDashboard = styled('img')(({ theme }) => ({
    width: 590,
    [theme.breakpoints.between('xs', 'md')]: {
        marginTop: 45,
        width: '100%'
    },
    [theme.breakpoints.up('md')]: {
        maxWidth: '95%'
    },
}));

const TryButton = styled(Button)(({ theme }) => ({
    [theme.breakpoints.between('xs','sm')]: {
        width: 245,
    },
    color: "#212141",
    height: '50px',
    fontWeight: '600',
    marginTop: 45,
    width: 275,
    backgroundColor: "#ffffff",
    '&:hover': {
      backgroundColor: "#fafafa",
    },
}));


