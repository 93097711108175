import * as types from '../actions/actionTypes';

const defaultState = {
}

export default function reset (state = defaultState, action) {
	switch(action.type) {
		case types.USER_RESET_LOADED:
            return {
                ...state,
                // user: {
                //     email_phone: "",
                //     // verify_type: "password-reset"
                // },
                // userVerification: false,
                userExist: false,
			};
        case types.USER_RESET_UNLOADED:
            return {
                ...state,
                // user: {
                //     email_phone: ""
                // }, 
                userExist: false,
                errors: null,
                // userVerified: false,
                // userVerification: false
            };
        case types.USER_RESET_FIELD_UPDATED:
            return {
                ...state,
                verified_user: { ...state.verified_user, [action.key]: action.value }
			};
        case types.USER_RESET_VALIDATION_FIELD_UPDATED:
			return {
				...state,
				// user: { ...state.user, [action.key]: action.value }
			};
        case types.USER_RESET_VALIDATION:
            return {
                ...state,
                // userVerification: action.error ? false : true,
                userExist: action.payload.user_exist,
                // user: action.error ? state.user : action.payload.user,
                errors: action.error ? action.payload.errors : null,
                // onVerification: false,
                inProgress: false
            };
        case types.USER_VERIFICATION:
            return {
                ...state,
                verified_user: action.payload ? action.payload.user : state.user,
            };
        case types.USER_RESET_EDITED:
            return {
                ...state,
                errors: action.error ? action.payload.errors : null,
                inProgress: false
            };
        case types.ASYNC_START:
            if ( action.subtype === types.USER_RESET_VALIDATION || action.subtype === types.USER_RESET_EDITED
                ) {
                return { 
                    ...state, 
                    inProgress: true };
            };
            // if ( action.subtype === types.USER_VERIFICATION || action.subtype === types.USER_EMAIL_VERIFICATION || action.subtype === types.USER_MESSAGE_VERIFICATION
            //     ) {
            //     return { 
            //         ...state, 
            //         dialogInProgress: true };
            // };
            return state;
		default:
			return state;
	}
}