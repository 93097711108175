import React from 'react';
import { 
    Typography,
    Box,
    Grid,
    Paper,
    Avatar,
    Divider
} from '@mui/material';
import { styled } from '@mui/material/styles';
import NumberFormat from 'react-number-format';
import { useSelector, shallowEqual } from 'react-redux';
import { AccountBalanceWalletRounded, StyleRounded, MailOutlineRounded, SmartphoneRounded } from '@mui/icons-material';


export default function Profile() {
    const {customer} = useSelector(state => ({
        ...state.customer
    }), shallowEqual); 

    const { balance, points, email, phone, first_name, last_name } = customer;
    return (
        <Paper elevation={3} style={{paddingTop: 25, width: '100%'}}>
            <Grid container direction="column" spacing={5}>
                <Grid item xs container direction="column" alignItems="center" spacing={2}>
                    <Grid item xs>
                        <CustomerProfile variant="rounded" alt="Test Name">
                            {first_name.charAt(0) + last_name.charAt(0)}
                        </CustomerProfile>
                    </Grid>
                    <Grid item>
                        <Typography variant="h6">
                            {first_name + " " + last_name}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item xs container alignItems="center" justifyContent="center" spacing={12}>
                    <Grid item>
                        <AccountBalanceWalletRounded color="primary" style={{ fontSize: 40 }} />
                        <Typography variant="body2" gutterBottom>
                            Saldo
                        </Typography>
                        <Typography variant="h6" gutterBottom>
                            <Box fontWeight={700} fontSize={14}>
                                <NumberFormat
                                    value={balance}
                                    displayType="text"
                                    thousandSeparator={"."}
                                    decimalSeparator={","}
                                    prefix="Rp "
                                    isNumericString
                                    decimalScale={2}
                                />
                            </Box>
                        </Typography>
                    </Grid>
                    <Grid item>
                        <StyleRounded color="primary" style={{ fontSize: 40 }} />
                        <Typography variant="body2" gutterBottom>
                            Poin
                        </Typography>
                        <Typography variant="h6" gutterBottom>
                            <Box fontWeight={700} textAlign="center" fontSize={14}>
                                {points}
                            </Box>
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item xs>
                    <Divider />
                    <Paper style={{padding: "25px", borderRadius: 4}} elevation={0}>
                        <Grid container direction="column" spacing={3}>
                            <Grid item xs container style={{color: '#999'}}>
                                <Grid item>
                                    <MailOutlineRounded fontSize="small" />
                                </Grid>
                                <Grid item xs>
                                    <CustomerContact variant="body2" >
                                        {email ? email : "-"}
                                    </CustomerContact>
                                </Grid>
                            </Grid>
                            <Grid item xs container style={{color: '#999'}}>
                                <Grid item>
                                    <SmartphoneRounded fontSize="small" />
                                </Grid>
                                <Grid item xs>
                                    <CustomerContact variant="body2" >
                                        {phone}
                                    </CustomerContact>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </Paper>
    )
}

const CustomerProfile = styled(Avatar)(({theme}) => ({
    width: theme.spacing(12),
    height: theme.spacing(12),
    color: theme.palette.getContrastText(theme.palette.primary.main),
    backgroundColor: theme.palette.primary.main,
    textTransform: 'uppercase',
}));

const CustomerContact = styled(Typography)(({theme}) => ({
    marginLeft: 5
}));

