import React from "react";
import { useSelector, shallowEqual } from 'react-redux';
import { setAuthorizationToken } from  '../../api';
import Initialize from './Initialize';
import Nav from './Common/Nav';
import Main from './Common/Main';
export default function Backend() {
    const { current_user } = useSelector(state => ({
        ...state.common
    }), shallowEqual);


    const authToken = window.localStorage.getItem('userToken');
    setAuthorizationToken(authToken);

    if (authToken && !current_user.initialized) {
        return <Initialize />
    }

    return (
        <React.Fragment>
            <Nav />
            <Main />
        </React.Fragment>
    )
}

