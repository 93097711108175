import React, { useState } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import * as purchaseOrderReturnActions from '../../../../../../actions/purchaseOrderReturnActions';
import { 
    Typography,
    Paper,
    Box,
    Grid,
    TextField,
    Autocomplete,
    Alert
} from '@mui/material';
import { styled } from '@mui/material/styles';

export default function Information() {
    const dispatch = useDispatch();
    const {purchase_order_return, purchase_orders, errors} = useSelector(state => ({
        ...state.purchase_order_return
    }), shallowEqual); 

    const { purchase_order_id, reason, note } = purchase_order_return;

    const [reason_options] = useState([
        {
            value: 'damaged_goods',
            title: 'Damaged Goods'
        },
        {
            value: 'returned_goods',
            title: 'Returned Goods'
        },
        {
            value: 'canceled_po',
            title: 'Canceled PO'
        }
    ]);

    function onSelectPurchaseOrder(value) {
        const newPurchaseOrderReturn = {...purchase_order_return};
        newPurchaseOrderReturn.purchase_order_id = value;
        dispatch(purchaseOrderReturnActions.onSelectPurchaseOrder(newPurchaseOrderReturn));
    }

    function onSelectReason(value) {
        const selectedReason = reason_options.find(reason => reason.value === value);
        const newPurchaseOrderReturn = {...purchase_order_return};
        newPurchaseOrderReturn.reason = value;
        dispatch(purchaseOrderReturnActions.onUpdatePurchaseOrderReturn(newPurchaseOrderReturn));
    }

    function onUpdateField(event) {
		const key = event.target.name;
        const value = event.target.value;
        const newPurchaseOrderReturn = {...purchase_order_return};
        newPurchaseOrderReturn[key] = value;
        dispatch(purchaseOrderReturnActions.onUpdatePurchaseOrderReturn(newPurchaseOrderReturn));
    }

    function error(key) {
        if (errors != null && errors[`purchase_order_return.${key}`] != null) {
            return errors[`purchase_order_return.${key}`].msg
        }
        return null;
    }


    const purchaseOrderValue = purchase_orders.find(t => t._id === purchase_order_id);
    const reasonValue = reason_options.find(t => t.value === reason);
    return (
        <ContentPaper elevation={3}>
            <Grid container spacing={6} direction="column">
                <Grid item container>
                    <Grid item xs>
                        <Typography fontWeight={700} variant="h6" lineheight="normal">
                            Informasi Pengembalian
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Alert severity="error">
                            Dibutuhkan
                        </Alert>
                    </Grid>
                </Grid>
                <Grid item container direction="column" spacing={5}>
                    <Grid item container spacing={8}>
                        <Grid item xs={4}>
                            <Typography lineheight="normal" fontSize={16} fontWeight={700} component="div">
                                ID Pembelian / No. PO
                            </Typography>
                            <Typography variant="body2" gutterBottom color="textSecondary">
                                Silahkan pilih pembelian.
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Autocomplete
                                options={purchase_orders.sort((a, b) => -b.label.localeCompare(a.label))}
                                getOptionLabel={(option) => option.label}
                                selectOnFocus
                                clearOnBlur
                                filterSelectedOptions
                                disableClearable
                                onChange={(event, option) => {
                                    onSelectPurchaseOrder(option._id)
                                }}
                                value={purchaseOrderValue ? purchaseOrderValue : null}
                                isOptionEqualToValue={(option, value) => option._id === value._id}
                                renderInput={(params) => <TextField {...params} error={!!error('purchase_order_id')} helperText={error('purchase_order_id')} placeholder="Pilih pembelian" variant="outlined" />}
                            />
                        </Grid> 
                    </Grid>
                    <Grid item container spacing={8}>
                        <Grid item xs={4}>
                            <Typography lineheight="normal" fontSize={16} fontWeight={700} component="div">
                                Alasan Pengembalian
                            </Typography>
                            <Typography variant="body2" gutterBottom color="textSecondary">
                                Silahkan pilih alasan pengembalian.
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Autocomplete
                                options={reason_options.sort((a, b) => -b.title.localeCompare(a.title))}
                                getOptionLabel={(option) => option.title}
                                selectOnFocus
                                clearOnBlur
                                filterSelectedOptions
                                disableClearable
                                onChange={(event, option) => {
                                    onSelectReason(option.value)
                                }}
                                value={reasonValue ? reasonValue : null}
                                isOptionEqualToValue={(option, value) => option.value === value.value}
                                renderInput={(params) => <TextField {...params} error={!!error('reason')} helperText={error('reason')} placeholder="Pilih alasan" variant="outlined" />}
                            />
                        </Grid> 
                    </Grid>
                    <Grid item container spacing={8}>
                        <Grid item xs={4}>
                            <Typography lineheight="normal" fontSize={16} fontWeight={700} component="div">
                                Catatan
                            </Typography>
                            <Typography variant="body2" gutterBottom color="textSecondary">
                                Masukan catatan pengembalian.
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <TextField
                                fullWidth
                                rows={6}
                                multiline
                                name="note"
                                placeholder="Catatan"
                                value={note}
                                variant="outlined"
                                onChange={onUpdateField}
                            />
                        </Grid> 
                    </Grid>
                </Grid>
            </Grid>
        </ContentPaper>
    )
}


const ContentPaper = styled(Paper)(({theme}) => ({
    padding: 35,
    paddingTop: 32,
    marginBottom: 25
}));