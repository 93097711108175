import React from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import * as commonActions from '../../../../actions/commonActions';
import { 
    Drawer, 
    List, 
    ListItem, 
    ListItemText, 
    Typography, 
    Box,
    Paper,
    Grid,
    ListSubheader,
    IconButton  
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { MenuRounded } from '@mui/icons-material';
import { useNavigate } from "react-router-dom";
import MenuNav from './MenuNav';
import UserNav from './UserNav';

export default function Nav() {
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const { currentMenu, openMenu, openMobileMenu } = useSelector(state => ({
        ...state.common
    }), shallowEqual);
    
    function setOpen(menu) {
        dispatch(commonActions.onOpenNav(menu))
    }

    function setClose() {
        dispatch(commonActions.onCloseNav())
    }

    function setMobileOpen() {
        dispatch(commonActions.onOpenMobileNav())
    }

    function setMobileClose() {
        dispatch(commonActions.onCloseMobileNav())
    }

    function drawerContent() {
        if (currentMenu.navType === 'menu') {
            return (
                <List component="nav">
                    <MenuListSubheader component="div" disableSticky={true}>
                        {currentMenu.navName}
                    </MenuListSubheader>
                    <Box>
                        <Grid container spacing={3} flexDirection={currentMenu.navItems.length >= 3 ? "row" : "column"} sx={{marginBottom: '65px'}}>
                            {
                                currentMenu.navItems.map((item, index) => {
                                    return (
                                        <Grid key={index} item xs={12} sm={currentMenu.navItems.length >= 3 ? 6 : 12} md={currentMenu.navItems.length >= 3 ? 6 : 12}>
                                            <MenuList component="nav">
                                                <NavListSubheader component="div" disableSticky={true}>
                                                    {item.name}
                                                </NavListSubheader>
                                                {
                                                    item.submenu.map(submenu => {
                                                        if (!submenu.auth) {
                                                            return null;
                                                        }
                                                        return(
                                                            <ListItem key={submenu.name} button onClick={(e) => {
                                                                    setClose();
                                                                    setMobileClose();
                                                                    navigate(submenu.pathUrl);
                                                                }}>
                                                                <ListItemText primary={
                                                                    <NavItemTypography component="div">
                                                                        <Box fontWeight={500} fontSize={14} >
                                                                            {submenu.name}
                                                                        </Box>
                                                                    </NavItemTypography>
                                                                } />
                                                            </ListItem>
                                                        )
                                                    })
                                                }
                                            </MenuList>
                                        </Grid>
                                    )
                                })
                            }
                        </Grid>
                    </Box>
                </List>
            )
        }

        return currentMenu.navItems.map((item, index) => {
            return (
                <List component="nav" key={index}>
                    <NavListSubheader component="div" disableSticky={true}>
                        {item.name}
                    </NavListSubheader>
                    {
                        item.submenu.map(submenu => {
                            return(
                                <ListItem key={submenu.name} button onClick={(e) => {
                                        setClose();
                                        setMobileClose();
                                        if (submenu.pathUrl === '/logout') {
                                            dispatch(commonActions.onLogout());
                                        } else {
                                            navigate(submenu.pathUrl);
                                        }
                                    }}>
                                    <ListItemText primary={
                                        <NavItemTypography component="div">
                                            <Box fontWeight={500} fontSize={14} >
                                                {submenu.name}
                                            </Box>
                                        </NavItemTypography>
                                    } />
                                </ListItem>
                            )
                        })
                    }
                </List>
            )
        })
    }

    return (
        <Box>
            <MenuIconButton color="primary" onClick={setMobileOpen}>
                <MenuRounded />
            </MenuIconButton>
            <MobileDrawer
                sx={{
                    zIndex: 99999
                }}
                open={openMobileMenu}
                
                anchor="left"
                onClose={() => {
                    setClose();
                    setMobileClose();
                }}>
                <NavBox>
                    <MenuNav setOpen={setOpen} setClose={setClose} setMobileClose={setMobileClose} />
                    <UserNav setOpen={setOpen} setClose={setClose}/>
                </NavBox>
                <Drawer
                        anchor={'left'}
                        open={openMenu}
                        sx={{
                            zIndex: 9998
                        }}
                        variant="persistent"
                        PaperProps={{ component : NavPaper }}
                        BackdropProps={{ invisible: true }}
                        transitionDuration={{
                            enter: 350,
                            exit: 250
                        }}
                        onClose={() => {
                            setClose();
                        }}>
                            <Box
                                sx={{ minWidth: 250 }}>
                                {drawerContent()}
                            </Box>
                    </Drawer>
            </MobileDrawer>
            <MenuBox>
                <NavBox sx={{zIndex: 9999}}>
                    <MenuNav setOpen={setOpen} setClose={setClose} />
                    <UserNav setOpen={setOpen} setClose={setClose}/>
                    <DesktopDrawer
                        anchor={'left'}
                        open={openMenu}
                        sx={{
                            zIndex: 9998
                        }}
                        PaperProps={{ component : NavPaper }}
                        BackdropProps={{ invisible: true }}
                        transitionDuration={{
                            enter: 350,
                            exit: 250
                        }}
                        onClose={() => {
                            setClose();
                        }}>
                            <Box
                            sx={{ minWidth: 250 }}>
                                {drawerContent()}
                            </Box>
                    </DesktopDrawer>
                </NavBox>
            </MenuBox>

        </Box>
        
    );

      
}


const MobileDrawer = styled(Drawer)(({theme}) => ({
    display: 'none',
    [theme.breakpoints.down('sm')]: {
        display: 'block'
    },
}));

const DesktopDrawer = styled(Drawer)(({theme}) => ({
    display: 'block',
    [theme.breakpoints.down('sm')]: {
        display: 'none'
    },
}));

const MenuIconButton = styled(IconButton)(({theme}) => ({
    position: 'absolute',
    top: 15,
    left: 15
}));

const MenuBox = styled(Box)(({theme}) => ({
    [theme.breakpoints.down('sm')]: {
        display: 'none'
    },
    [theme.breakpoints.up('md')]: {
        display: 'block'
    },
}));


const NavBox = styled(Box)(({theme}) => ({
    position: 'fixed',
    left: 0,
    top: 0,
    width: 85,
    zIndex: 1301,
    height: "calc(100vh - 0px)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    backgroundColor: "#112232",
}));

const MenuList = styled(List)(({theme}) => ({
    maxWidth: 275
}));

const NavPaper = styled(Paper)(({theme}) => ({
    minWidth: '300px',
    left: '85px !important',
    padding: '15px',
    backgroundColor: "rgb(244, 245, 247) !important",
    zIndex: '99999 !important'
}));

const NavListSubheader = styled(ListSubheader)(({theme}) => ({
    color: theme.palette.primary.main,
    fontSize: '14px',
    textTransform: 'capitalize',
    backgroundColor: "rgb(244, 245, 247) !important"
}));

const MenuListSubheader = styled(ListSubheader)(({theme}) => ({
    color: '#112232',
    fontSize: '24px',
    textTransform: 'capitalize',
    backgroundColor: "rgb(244, 245, 247) !important"
}));

const NavItemTypography = styled(Typography)(({theme}) => ({
    color: '#112232',
}));