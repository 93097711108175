import React, { useState } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import * as purchaseOrderReturnActions from '../../../../../../actions/purchaseOrderReturnActions';
import { 
    InputBase,
    Typography,
    Box,
    Paper,
    Grid,
    TextField,
    InputAdornment,
    List,
    ListItem,
    ListSubheader,
    Alert,
    Divider,
    Tooltip,
    Switch
} from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import { StickyNote2TwoTone, HelpCenterTwoTone, PercentTwoTone, NumbersTwoTone } from '@mui/icons-material';
import NumberFormat from 'react-number-format';
import { tooltipClasses } from '@mui/material/Tooltip';

const QuantityFormat = React.forwardRef((props, ref) => (
    <NumberFormat
        {...props.other}
        onValueChange={(values) => {
          props.onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        disabled={props.disabled}
        InputProps={props.InputProps}
        customInput={TextField}
        placeholder={props.placeholder}
        value={props.value}
        helperText={props.helperText}
        error={props.error}
        onFocus={props.onFocus}
        decimalScale={2}
        fullWidth={props.fullWidth}
        onBlur={props.onBlur}
        thousandSeparator={"."}
        decimalSeparator={","}
        allowNegative={false}
        allowedDecimalSeparators={false}
        isNumericString
      />
));

const CurrencyFormat = React.forwardRef((props, ref) => (
    <NumberFormat
        {...props.other}
        onValueChange={(values) => {
          props.onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        customInput={TextField}
        InputProps={{
            startAdornment: <InputAdornment position="start">
                <Box fontWeight={700}>
                    Rp
                </Box>
            </InputAdornment>,
        }}
        placeholder="0"
        disabled={props.disabled}
        helperText={props.helperText}
        error={props.error}
        value={props.value}
        onFocus={props.onFocus}
        decimalScale={2}
        fullWidth={props.fullWidth}
        onBlur={props.onBlur}
        thousandSeparator={"."}
        decimalSeparator={","}
        allowNegative={false}
        allowedDecimalSeparators={false}
        isNumericString
      />
));


const PercentageFormat = React.forwardRef((props, ref) => (
    <NumberFormat
        {...props.other}
        onValueChange={(values) => {
          props.onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        customInput={TextField}
        InputProps={{
            startAdornment: <InputAdornment position="start">
                <Box fontWeight={700}>
                    {props.checked ? '%' : 'Rp' }
                </Box>
            </InputAdornment>,
            endAdornment: 
                <InputAdornment position="end">
                            <MaterialUISwitch
                                checkedIcon={
                                    <SwitchBox>
                                        <PercentTwoTone fontSize="12px" color="#fffff" />
                                    </SwitchBox>
                                }
                                icon={
                                    <SwitchBox>
                                        <NumbersTwoTone fontSize="12px" color="#fffff" />
                                    </SwitchBox>
                                }
                                checked={props.checked}
                                onChange={props.onToggle}
                                color="primary"
                            />
                </InputAdornment>
        }}
        placeholder="0"
        fullWidth={props.fullWidth}
        helperText={props.helperText}
        error={props.error}
        value={props.value}
        onFocus={props.onFocus}
        decimalScale={2}
        onBlur={props.onBlur}
        thousandSeparator={"."}
        decimalSeparator={","}
        allowNegative={false}
        allowedDecimalSeparators={false}
        isNumericString
      />
));

export default function Product() {   
    const dispatch = useDispatch();
    const {purchase_order_return, errors} = useSelector(state => ({
        ...state.purchase_order_return
    }), shallowEqual); 
    const [open, setOpen] = React.useState(false);
    const [currentFocus, setCurrentFocus] = useState("");
   
    const { purchase_order_return_items, status, note, subtotal, discount, grand_total } = purchase_order_return;

    const handleClose = () => {
      setOpen(false);
    };
  
    const handleOpen = () => {
      setOpen(true);
    };
    
    function onUpdateQtyField(index, event) {
        if (currentFocus === "approved") {
            const key = event.target.name;
            const value = event.target.value;
            const newPurchaseOrderReturn = {...purchase_order_return};
            const currentItem = newPurchaseOrderReturn.purchase_order_return_items[index];
            currentItem[key] = value;
            if (currentItem.discount_type === "amount") {
                currentItem.net_price = currentItem.unit_price - currentItem.discount_amount
            } else {
                currentItem.net_price = currentItem.unit_price - ( currentItem.unit_price * (Number(currentItem.discount_percent / 100)))
            }
            currentItem.row_total = Number(currentItem.net_price) * Number(currentItem.approved_qty);
            const subtotal = purchase_order_return_items.reduce((prev,next) => Number(prev) + Number(next.row_total),0);
            const grand_total = Number(subtotal)
            // const grand_total = Number(subtotal) - Number(purchase_order_return.discount) - Number(purchase_order_return.return);
            // newPurchaseOrderReturn.subtotal = subtotal;
            newPurchaseOrderReturn.grand_total = grand_total;
            dispatch(purchaseOrderReturnActions.onUpdatePurchaseOrderReturn(newPurchaseOrderReturn));
        }
    }

    function onUpdatePriceField(index, event) {
        if (currentFocus === "price") {
            const key = event.target.name;
            const value = event.target.value;
            const newPurchaseOrderReturn = {...purchase_order_return};
            const currentItem = newPurchaseOrderReturn.purchase_order_return_items[index];
            currentItem[key] = value;
            if (currentItem.discount_type === "amount") {
                currentItem.net_price = currentItem.unit_price - currentItem.discount_amount
            } else {
                currentItem.net_price = currentItem.unit_price - ( currentItem.unit_price * (Number(currentItem.discount_percent / 100)))
            }
            currentItem.row_total = Number(currentItem.net_price) * Number(currentItem.approved_qty);
            const subtotal = purchase_order_return_items.reduce((prev,next) => Number(prev) + Number(next.row_total),0);
            const grand_total = Number(subtotal)
            // const grand_total = Number(subtotal) - Number(purchase_order_return.discount) - Number(purchase_order_return.return);
            // newPurchaseOrderReturn.subtotal = subtotal;
            newPurchaseOrderReturn.grand_total = grand_total;
            dispatch(purchaseOrderReturnActions.onUpdatePurchaseOrderReturn(newPurchaseOrderReturn));
        }
    }

    function onUpdateSubtotalField(index, event) {
        if (currentFocus === "row_total") {
            const key = event.target.name;
            const value = event.target.value;
            const newPurchaseOrderReturn = {...purchase_order_return};
            const currentItem = newPurchaseOrderReturn.purchase_order_return_items[index];
            currentItem[key] = value;
            currentItem.net_price = Number(currentItem.row_total) / Number(currentItem.approved_qty);
            if (currentItem.discount_type === "percent") {
                currentItem.unit_price = currentItem.net_price * (100/(100-currentItem.discount_percent));
            } else {
                currentItem.unit_price = currentItem.net_price + currentItem.discount_amount;
            }
            const subtotal = purchase_order_return_items.reduce((prev,next) => Number(prev) + Number(next.row_total),0);
            const grand_total = Number(subtotal)
            // const grand_total = Number(subtotal) - Number(purchase_order_return.discount) - Number(purchase_order_return.return);
            // newPurchaseOrderReturn.subtotal = subtotal;
            newPurchaseOrderReturn.grand_total = grand_total;
            dispatch(purchaseOrderReturnActions.onUpdatePurchaseOrderReturn(newPurchaseOrderReturn));
        }
    }

    function onUpdateDiscountField(index, event) {
        if (currentFocus === 'discount') {
            const value = event.target.value;
            const newPurchaseOrderReturn = {...purchase_order_return};
            const currentItem = newPurchaseOrderReturn.purchase_order_return_items[index];
            if (currentItem.discount_type === "percent") {
                currentItem.discount_percent = value;
                currentItem.net_price = currentItem.unit_price - ( currentItem.unit_price * (Number(currentItem.discount_percent / 100)))
            } else {
                currentItem.discount_amount = value;
                currentItem.net_price = currentItem.unit_price - currentItem.discount_amount
            }
    
            currentItem.row_total = Number(currentItem.net_price) * Number(currentItem.approved_qty);
            const subtotal = purchase_order_return_items.reduce((prev,next) => Number(prev) + Number(next.row_total),0);
            const grand_total = Number(subtotal)
            // // const grand_total = Number(subtotal) - Number(purchase_order_return.discount) - Number(purchase_order_return.return);
            // newPurchaseOrderReturn.subtotal = subtotal;
            newPurchaseOrderReturn.grand_total = grand_total;
            dispatch(purchaseOrderReturnActions.onUpdatePurchaseOrderReturn(newPurchaseOrderReturn));
        }
    }

    function onUpdateNoteField(event) {
		const key = event.target.name;
        const value = event.target.value;
        const newPurchaseOrderReturn = {...purchase_order_return};
        newPurchaseOrderReturn[key] = value;
        dispatch(purchaseOrderReturnActions.onUpdatePurchaseOrderReturn(newPurchaseOrderReturn));
    }

    function onToggleDiscount(index, event) {
        const newPurchaseOrderReturn = {...purchase_order_return};
        const currentItem = newPurchaseOrderReturn.purchase_order_return_items[index];
        if (currentItem.discount_type === "percent") {
            currentItem.discount_type = "amount";
            currentItem.discount_amount = Number(currentItem.discount_percent);
            currentItem.discount_percent = 0;
            currentItem.net_price = currentItem.unit_price - currentItem.discount_amount
        } else {
            currentItem.discount_type = "percent"
            currentItem.discount_percent = Number(currentItem.discount_amount);
            currentItem.discount_amount = 0;
            currentItem.net_price = currentItem.unit_price - ( currentItem.unit_price * (Number(currentItem.discount_percent / 100)))
        }
        currentItem.row_total = Number(currentItem.net_price) * Number(currentItem.approved_qty);
        const subtotal = purchase_order_return_items.reduce((prev,next) => Number(prev) + Number(next.row_total),0);
        const grand_total = Number(subtotal)
        // newPurchaseOrderReturn.subtotal = subtotal;
        newPurchaseOrderReturn.grand_total = grand_total;
        dispatch(purchaseOrderReturnActions.onUpdatePurchaseOrderReturn(newPurchaseOrderReturn));
    }
  
    function onFocus(key) {
        setCurrentFocus(key)
    }

    function onBlur(key) {
        setCurrentFocus(null)
    }

    function error(key) {
        if (errors != null && errors[key] != null) {
            return errors[key][0]
        }

        return null;
    }


    function InStock(item) {
        if (status === 'requested' || status === 'approved') {
            return (
                <QuantityFormat
                    fullWidth
                    name="in_stock"
                    value={item.in_stock}
                    disabled
                    variant="outlined"
                    InputProps={{
                        endAdornment: <InputAdornment position="end">{item.uom}</InputAdornment>,
                    }}
                />
            )
        }

        return (
            <NumberFormat
                value={item.in_stock}
                displayType="text"
                thousandSeparator={"."}
                decimalSeparator={","}
                isNumericString
                decimalScale={2}
                renderText={(value) => <Typography textAlign="right" fontSize={16} fontWeight={500}>
                {value} {item.uom}
                </Typography>}
            />
        )
    }

    function Requested(item) {
        if (status === 'requested' || status === 'approved') {
            return (
                <QuantityFormat
                    fullWidth
                    name="in_stock"
                    value={item.requested_qty}
                    disabled
                    variant="outlined"
                    InputProps={{
                        endAdornment: <InputAdornment position="end">{item.uom}</InputAdornment>,
                    }}
                />
            )
        }

        return (
            <NumberFormat
                value={item.requested_qty}
                displayType="text"
                thousandSeparator={"."}
                decimalSeparator={","}
                isNumericString
                decimalScale={2}
                renderText={(value) => <Typography textAlign="right" fontSize={16} fontWeight={500}>
                {value} {item.uom}
                </Typography>}
            />
        )
    }

    

    function Approved(item, index) {
        if (status === 'requested' || status === 'approved') {
            return (
                <QuantityFormat
                    fullWidth
                    error={!!error(`purchase_order_return_items[${index}].approved_qty`)}
                    helperText={error(`purchase_order_return_items[${index}].approved_qty`)}
                    name="approved_qty"
                    value={item.approved_qty}
                    onFocus={onFocus.bind(this, "approved")}
                    onBlur={onBlur.bind(this)}
                    variant="outlined"
                    onChange={onUpdateQtyField.bind(this, index)}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">{item.uom}</InputAdornment>,
                    }}
                />
            )
        }

        return (
            <NumberFormat
                value={item.approved_qty}
                displayType="text"
                thousandSeparator={"."}
                decimalSeparator={","}
                isNumericString
                decimalScale={2}
                renderText={(value) => <Typography textAlign="right" fontSize={16} fontWeight={500}>
                {value} {item.uom}
                </Typography>}
            />
        )
    }

    function Returned(item) {
        if (status === 'approved') {
            return (
                <QuantityFormat
                    fullWidth
                    name="returned_qty"
                    value={item.returned_qty}
                    disabled
                    variant="outlined"
                    InputProps={{
                        endAdornment: <InputAdornment position="end">{item.uom}</InputAdornment>,
                    }}
                />
            )
        }

        return (
            <NumberFormat
                value={item.returned_qty}
                displayType="text"
                thousandSeparator={"."}
                decimalSeparator={","}
                isNumericString
                decimalScale={2}
                renderText={(value) => <Typography textAlign="right" fontSize={16} fontWeight={500}>
                {value} {item.uom}
                </Typography>}
            />
        )
    }

    

    function UnitPrice(item, index) {
        if (status === 'approved') {
            return (
                <CurrencyFormat
                    name="unit_price"
                    fullWidth
                    error={!!error(`purchase_order_return_items[${index}].unit_price`)}
                    helperText={error(`purchase_order_return_items[${index}].unit_price`)}
                    value={item.unit_price}
                    inputProps={{min: 0, style: { textAlign: 'right' }}}
                    variant="outlined"
                    onFocus={onFocus.bind(this, "price")}
                    onBlur={onBlur.bind(this)}
                    onChange={onUpdatePriceField.bind(this, index)}
                />
            )
        }

        return (
            <NumberFormat
                value={item.unit_price}
                displayType="text"
                thousandSeparator={"."}
                decimalSeparator={","}
                prefix="Rp "
                isNumericString
                decimalScale={2}
                renderText={(value) => <Typography textAlign="right" fontSize={16} fontWeight={500}>
                {value}
                </Typography>}
            />
        )
    }

    function Discount(item, index) {
        if (status === 'approved') {
            return (
                <PercentageFormat
                    fullWidth
                    value={item.discount_type === 'percent' ? item.discount_percent : item.discount_amount}
                    inputProps={{min: 0, style: { textAlign: 'right' }}}
                    variant="outlined"
                    onToggle={onToggleDiscount.bind(this, index)}
                    onFocus={onFocus.bind(this, "discount")}
                    onBlur={onBlur.bind(this)}
                    checked={item.discount_type === 'percent'}
                    onChange={onUpdateDiscountField.bind(this, index)}
                />
            )
        }

        return (
            <NumberFormat
                value={item.discount_type === 'percent' ? item.discount_percent : item.discount_amount}
                displayType="text"
                thousandSeparator={"."}
                decimalSeparator={","}
                prefix={item.discount_type === 'percent' ? "" : "Rp "}
                suffix={item.discount_type === 'percent' ? "%" : ""}
                isNumericString
                decimalScale={2}
                renderText={(value) => <Typography textAlign="right" fontSize={16} fontWeight={500}>
                {value}
                </Typography>}
            />
        )
    }

    function NetPrice(item, index) {
        if (status === 'approved') {
            return (
                <CurrencyFormat
                    fullWidth
                    name="net_price"
                    value={item.net_price}
                    disabled
                    variant="outlined"
                    onFocus={onFocus.bind(this, "received")}
                    onBlur={onBlur.bind(this)}
                    onChange={() => {return}}
                />
            )
        }

        return (
            <NumberFormat
                value={item.net_price}
                displayType="text"
                thousandSeparator={"."}
                decimalSeparator={","}
                prefix="Rp "
                isNumericString
                decimalScale={2}
                renderText={(value) => <Typography textAlign="right" fontSize={16} fontWeight={500}>
                {value}
                </Typography>}
            />
        )
    }

    function Subtotal(item, index) {
        if (status === 'approved') {
            return (
                <CurrencyFormat
                    fullWidth
                    name="row_total"
                    error={!!error(`purchase_order_return_items[${index}].row_total`)}
                    helperText={error(`purchase_order_return_items[${index}].row_total`)}
                    value={item.row_total}
                    inputProps={{min: 0, style: { textAlign: 'right' }}}
                    variant="outlined"
                    onFocus={onFocus.bind(this, "row_total")}
                    onBlur={onBlur.bind(this)}
                    onChange={onUpdateSubtotalField.bind(this, index)}
                />
            )
        }

        return (
            <NumberFormat
                value={item.row_total}
                displayType="text"
                thousandSeparator={"."}
                decimalSeparator={","}
                prefix="Rp "
                isNumericString
                decimalScale={2}
                renderText={(value) => <Typography textAlign="right" fontSize={16} fontWeight={500}>
                {value}
                </Typography>}
            />
        )
    }

    function hasOptions(item) {

        if (item && item.product_options) {
            var optionsKeys = [];
            item.product_options.sort((a, b) => {
                const nameA = a.sort_order;
                const nameB = b.sort_order;
                if (nameA < nameB) {
                return -1;
                }
                if (nameA > nameB) {
                return 1;
                }
            
                // names must be equal
                return 0;
            }).map((option, index) => {
                if (!optionsKeys.includes(option.uom.toLowerCase())) {
                    optionsKeys.push(option.uom.toLowerCase());
                }
            })

            var options = [];
            optionsKeys.map((key, index)  => {
                const option = item.product_options.find(opt => opt.sort_order === index);
                if (index === 0) {
                    options.push({name: option.uom, unit: ""});
                } else {
                    const firstOption = item.product_options.find(opt => opt.sort_order === 0);
                    options.push({name: option.uom, unit: option.unit + " " + firstOption.uom});
                }
            })

            if (options.length >= 2) {
                return (
                    <Tooltip sx={{marginLeft: '15px'}} open={open} onClose={handleClose} onOpen={handleOpen} title={itemOptions(options)}>
                        <HelpCenterTwoTone color="primary" />
                    </Tooltip>
                )
            }
            
            return null;
        }

        return null;
    }
    function itemOptions(options) {
        return (
            <Box>
                <Typography fontWeight={700}>
                    Produk Opsi
                </Typography>
                {options.map((option, index) => {
                    return (
                        <Box key={index} sx={{display: 'flex'}}>
                            <Typography fontWeight={500}>
                                {option.name} 
                            </Typography>
                            <Typography sx={{marginLeft: '5px'}}>
                                {option.unit ? `(${option.unit})` : null}
                            </Typography>
                        </Box>
                    )
                })}
            </Box>
        )
    }

    let titleAlign = 'right'
    if (status === 'requested' || status === 'approved') {
        titleAlign = 'left'
    }

    let requestedStatus = status === 'requested';
    let returnedStatus = status === 'returned';
    let approvedStatus = status === 'approved';
    let canceledStatus = status === 'canceled';

    return (
        <ContentPaper elevation={3}> 
            <Grid container spacing={6} direction="column">
                <Grid item container>
                    <Grid item xs>
                        <Typography fontWeight={700} variant="h6" lineheight="normal">
                            Informasi Produk
                        </Typography>
                    </Grid>
                    <Grid item>
                        {status === 'returned' && <Grid item>
                            <Alert severity="error">
                                Dibutuhkan
                            </Alert>
                        </Grid>}
                    </Grid>
                </Grid>
                <Grid item>
                    <Box sx={{overflowX: 'scroll'}}>
                        <List component="div" sx={{width: (requestedStatus) ? '100%' : '120%'}}>
                            <ListSubheader sx={{display: 'flex', padding: 0}} component="div">
                                <Box sx={{width: '34%', marginRight: '25px'}}>
                                    Nama Produk
                                </Box>
                                <Box textAlign={titleAlign} sx={{width: requestedStatus ? '20%' : '8%', marginRight: '25px'}}>
                                    Stock Awal
                                </Box>
                                {(requestedStatus || returnedStatus || canceledStatus) && <Box textAlign={titleAlign} sx={{width: requestedStatus ? '20%' : '8%', marginRight: '25px'}}>
                                    Diajukan
                                </Box>}
                                {(requestedStatus || returnedStatus || canceledStatus) && <Box textAlign={titleAlign} sx={{width: requestedStatus ? '20%' : '8%', marginRight: status === 'requested' ? '0' : '25px'}}>
                                    Disetujui
                                </Box>}
                                {(approvedStatus || returnedStatus || canceledStatus) && <Box textAlign={titleAlign} sx={{width: '8%', marginRight: '25px'}}>
                                    Dikembalikan
                                </Box>}
                                {(approvedStatus || returnedStatus || canceledStatus) && <Box textAlign={titleAlign} sx={{width: '12%', marginRight: '25px'}}>
                                    Harga Pokok
                                </Box>}
                                {(approvedStatus || returnedStatus || canceledStatus) && <Box textAlign={titleAlign} sx={{width: status === 'returned' ? '15%' : '10%', marginRight: '25px'}}>
                                    Diskon
                                </Box>}
                                {(approvedStatus || returnedStatus || canceledStatus) && <Box textAlign={titleAlign} sx={{width: '12%', marginRight: '25px'}}>
                                    Harga Satuan
                                </Box>}
                                {(approvedStatus || returnedStatus || canceledStatus) && <Box textAlign={titleAlign} sx={{width: '15%'}}>
                                    Subtotal
                                </Box>}
                            </ListSubheader>
                            {
                                purchase_order_return_items.map((item, index) => {
                                    return (
                                        <ListItem key={index} disableGutters divider={index !== purchase_order_return_items.length - 1}>
                                            <Box sx={{width: '34%', marginRight: '25px'}}>
                                                <Typography sx={{display:'flex'}} lineheight="normal" fontSize={16} fontWeight={700}>
                                                    {item.name}{hasOptions(item)}
                                                </Typography>
                                                <Typography lineheight="normal" fontSize={11} style={{color: '#999'}}>
                                                    SKU #{item.sku}
                                                </Typography>
                                            </Box>
                                            <Box sx={{width: requestedStatus ? '20%' : '8%', marginRight: '25px'}}>
                                                {InStock(item)}
                                            </Box>
                                            {(requestedStatus || returnedStatus || canceledStatus) && <Box sx={{width: requestedStatus ? '20%' : '8%', marginRight: '25px'}}>
                                                {Requested(item, index)}
                                            </Box>}
                                            {(requestedStatus || returnedStatus || canceledStatus) && <Box sx={{width: requestedStatus ? '20%' : '8%', marginRight: status === 'requested' ? '0' : '25px'}}>
                                                {Approved(item, index)}
                                            </Box>}
                                            {(approvedStatus || returnedStatus || canceledStatus) && <Box sx={{width: '8%', marginRight: '25px'}}>
                                                {Returned(item, index)}
                                            </Box>}
                                           {(approvedStatus || returnedStatus || canceledStatus) &&  <Box sx={{width: '12%', marginRight: '25px'}}>
                                                {UnitPrice(item, index)}
                                            </Box>}
                                            {(approvedStatus || returnedStatus || canceledStatus) && <Box sx={{width: status === 'returned' ? '15%' : '10%', marginRight: '25px'}}>
                                                {Discount(item, index)}
                                            </Box>}
                                            {(approvedStatus || returnedStatus || canceledStatus) && <Box sx={{width: '12%', marginRight: '25px'}}>
                                                {NetPrice(item, index)}
                                            </Box>}
                                            {(approvedStatus || returnedStatus || canceledStatus) &&  <Box sx={{width: '15%'}}>
                                                {Subtotal(item, index)}
                                            </Box>}
                                        </ListItem>
                                    )
                                })
                            }
                        </List>
                    </Box>
                </Grid>
            </Grid>
            <Divider sx={{marginTop: '15px', marginBottom: '30px'}} />
            <Grid container spacing={3} justifyContent="flex-end">
                <Grid item>
                    <CommentField
                        value={note}
                        onChange={onUpdateNoteField}
                        >
                        <IconWrapper>
                            <StickyNote2TwoTone />
                        </IconWrapper>
                        <StyledInputBase
                            name="note"
                            disabled={status === 'canceled' || status === 'returned'}
                            placeholder="Note…"/>
                    </CommentField>
                </Grid>
                <Grid item container xs spacing={3} alignItems="center" justifyContent="space-between">
                    <Grid item>
                        <Typography sx={{display: 'flex'}} lineheight="normal" fontSize={16} fontWeight={700}>
                            Total Pengembalian
                        </Typography>
                    </Grid>
                    <Grid item>
                        <NumberFormat
                            value={grand_total}
                            displayType="text"
                            thousandSeparator={"."}
                            decimalSeparator={","}
                            prefix="Rp "
                            isNumericString
                            decimalScale={2}
                            renderText={(value) => <Typography lineheight="normal" fontSize={16} fontWeight={700}>
                            {value}
                        </Typography>}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </ContentPaper>
    )
}

const ContentPaper = styled(Paper)(({theme}) => ({
    padding: 35,
    paddingTop: 32,
    marginBottom: 25
}));

const CommentField = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.black, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.black, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    // [theme.breakpoints.up('sm')]: {
    //   marginLeft: theme.spacing(1),
    //   width: 'auto',
    // },
}));

const IconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.primary.main
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: '12ch',
        '&:focus': {
          width: '20ch',
        },
      },
    },
  }));

  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} sx={{marginLeft: '5px'}} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      minWidth: 225,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 11,
      padding: 15
    },
  }));


const MaterialUISwitch = styled(Switch)(({ theme }) => ({
    width: 60,
    height: 34,
    padding: 12,
    '& .MuiSwitch-switchBase': {
        margin: 1,
        padding: 0,
        transform: 'translateX(6px)',
        '&.Mui-checked': {
        color: '#fff',
        transform: 'translateX(22px)',
        '& + .MuiSwitch-track': {
            opacity: 1,
            backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
        },
        },
    },
    '& .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
        borderRadius: 20 / 2,
    },
}));

const SwitchBox = styled(Box)(({ theme }) => ({
    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    backgroundColor: '#001e3c',
    width: 30,
    height: 30,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
}));