import React, { useEffect} from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import * as employeeJobPositionActions from '../../../../../../actions/employeeJobPositionActions';
import { 
        CircularProgress,
        Button,
        Grid,
        Box,
        Alert,
        Typography,
        Paper,
        Switch,
        TextField,
        FormControlLabel,
        FormGroup,
        FormControl,
        Select,
        MenuItem,
        FormHelperText,
        OutlinedInput,
        InputLabel
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate, useParams } from "react-router-dom";
import { AddRounded, DragIndicatorRounded } from '@mui/icons-material';
import Loader from '../../../../../Helper/Loader';

export default function Form() {
    let navigate = useNavigate();
    let { id, parent_id } = useParams();
    const dispatch = useDispatch();
    const {employee_job_position, parent_job_position, employee_organizations, errors, inProgress} = useSelector(state => ({
        ...state.employee_job_position
    }), shallowEqual); 

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
    },[errors]);

    useEffect(() => {
        if (id) {
			dispatch(employeeJobPositionActions.onEmployeeJobPositionEdit(id));
		} else {
			dispatch(employeeJobPositionActions.onEmployeeJobPositionNew(parent_id));
		}
        return () => {
            dispatch(employeeJobPositionActions.onUnloadForm())
        };
    },[dispatch, id]);
  
    function onUpdateField(event) {
		const key = event.target.name;
        const value = event.target.value;
        dispatch(employeeJobPositionActions.onUpdateField(key, value));
    }

    function onToggleChange(value, event) {
		const key = event.target.name;
        dispatch(employeeJobPositionActions.onUpdateField(key, value));
    }

    function onUpdateDescriptionField(index, event) {
        const key = event.target.name;
        const value = event.target.value;
	    const newDescriptions = { ...job_descriptions[index], [key]: value}
	    job_descriptions.splice(index, 1, newDescriptions)
        dispatch(employeeJobPositionActions.onUpdateDescriptions(job_descriptions));
  	}


    function onAddDescription() {
        const addDescriptions = [...job_descriptions, {description: '', sort_order: ''}]
        const newDescriptions = addDescriptions.map((description, index)=> {
            return {...description, sort_order: index}
        })
        dispatch(employeeJobPositionActions.onUpdateDescriptions(newDescriptions));
    }

    function onRemoveDescription(index) {
		
        job_descriptions.splice(index, 1)
        const newDescriptions = job_descriptions.map((description, index)=> {
            return {...description, sort_order: index}
        })
        
        dispatch(employeeJobPositionActions.onUpdateDescriptions(newDescriptions));
    }
   

  
    function onSubmit() {
 
        // if (slug && newConfigurations) {
        //     dispatch(productActions.onEditConfirm());
        // } else {
            if (id) {
                dispatch(employeeJobPositionActions.onEdit(id, employee_job_position));
            } else {
                dispatch(employeeJobPositionActions.onCreate(employee_job_position));
            }
        // }
    }
    
    function error(key) {
        if (errors != null && errors[`employee_job_position.${key}`] != null) {
            return errors[`employee_job_position.${key}`].msg
        }
        return null;
    }


    if (!employee_job_position || !employee_organizations) {
        return (
            <Loader />
        )
    }

    const { name, status, is_root, employee_organization_id, job_position, job_descriptions } = employee_job_position;
    return (
        <Box>
            {console.log(employee_job_position)}
            <ContentPaper elevation={3}>
                <Grid container spacing={4} direction="column">
                    <Grid item container>
                        <Grid item xs>
                            <Typography fontWeight={700} variant="h6" lineheight="normal">
                                Informasi Produk
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Alert severity="error">
                                Wajib
                            </Alert>
                        </Grid>
                    </Grid>
                    <Grid item container direction="column" spacing={3}>
                        <Grid item container alignItems="center" spacing={8}>
                            <Grid item xs={4}>
                                <Typography fontSize={16} fontWeight={700} component="div">
                                    Organisasi Jabatan
                                </Typography>
                                <Typography variant="body2" gutterBottom color="textSecondary">
                                    Masukan organisasi dari jabatan yang akan dibuat / diubah.
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <FormControl 
                                    error={!!error('employee_organization_id')}
                                    fullWidth
                                    disabled={job_position === 'ceo'}
                                    variant="outlined">
                                    <Select
                                        value={employee_organization_id}
                                        onChange={onUpdateField}
                                        name="employee_organization_id"
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}>
                                    <MenuItem value="" disabled>
                                        Pilih Organisasi
                                    </MenuItem>
                                    {
                                        employee_organizations.map(option => {
                                            return(
                                                <MenuItem key={option._id} value={option._id}>
                                                    {option.name}
                                                </MenuItem>
                                            )
                                        })
                                    }
                                    </Select>
                                    {!!error('employee_organization_id') && <FormHelperText>{error('employee_organization_id')}</FormHelperText>}
                                </FormControl>
                            </Grid> 
                        </Grid>
                        <Grid item container alignItems="center" spacing={8}>
                            <Grid item xs={4}>
                                <Typography fontSize={16} fontWeight={700} component="div">
                                    Subposisi Jabatan Dari
                                </Typography>
                                <Typography variant="body2" gutterBottom color="textSecondary">
                                    Menunjukan posisi jabatan yang memimpin subposisi jabatan.
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography fontSize={16} fontWeight={700} component="div">
                                    {is_root ? "-" : parent_job_position.name}
                                </Typography>
                            </Grid> 
                        </Grid>
                        <Grid item container spacing={8}>
                            <Grid item xs={4}>
                                <Typography fontSize={16} fontWeight={700} component="div">
                                    Nama Jabatan
                                </Typography>
                                <Typography variant="body2" gutterBottom color="textSecondary">
                                    Masukan nama jabatan yang akan dibuat / diubah.
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <TextField
                                    fullWidth
                                    disabled={job_position === 'ceo'}
                                    error={!!error('name')}
                                    name="name"
                                    placeholder="Nama Jabatan"
                                    value={name}
                                    helperText={error('name')}
                                    variant="outlined"
                                    onChange={onUpdateField}
                                />
                            </Grid> 
                        </Grid>
                        <Grid item container spacing={8}>
                            <Grid item xs={4}>
                                <Typography fontSize={16} fontWeight={700} component="div">
                                    <Box fontSize={16} fontWeight={700}>
                                        Status Jabatan
                                    </Box>
                                </Typography>
                                <Typography variant="body2" gutterBottom color="textSecondary">
                                    Aktif / Non-Aktifkan jabatan.
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <FormGroup row>
                                    <FormControlLabel
                                        control={
                                        <Switch
                                            disabled={job_position === 'ceo'}
                                            checked={status}
                                            onChange={onToggleChange.bind(this, !status)}
                                            name="status"
                                            color="primary"
                                        />
                                        }
                                        label={status ? 'Aktif' : 'Tidak Aktif'}
                                    />
                                </FormGroup>
                            </Grid> 
                        </Grid>
                        <Grid item container spacing={8}>
                            <Grid item xs={4}>
                                <Typography fontSize={16} fontWeight={700} component="div">
                                    <Box fontSize={16} fontWeight={700}>
                                        Deskripsi Pekerjaan
                                    </Box>
                                </Typography>
                                <Typography variant="body2" gutterBottom color="textSecondary">
                                    Masukan deskripsi pekerjaan.
                                </Typography>
                            </Grid>
                            <Grid item xs={8} container direction="column" spacing={3}>
                                <Grid item container spacing={3}>
                                    { 
                                        job_descriptions.map((description, index) => {
                                            return (
                                                <Grid key={index} item container spacing={3}>
                                                    <Grid item xs>
                                                        <TextField
                                                            fullWidth
                                                            error={!!error('description')}
                                                            name="description"
                                                            placeholder="Deskripsi Pekerjaan"
                                                            value={description.description}
                                                            helperText={error('description')}
                                                            variant="outlined"
                                                            onChange={onUpdateDescriptionField.bind(this, index)}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        <DelButton 
                                                            fullWidth
                                                            variant="contained"
                                                            size="large"
                                                            disableElevation
                                                            onClick={onRemoveDescription.bind(this, index)}
                                                            >
                                                            Hapus
                                                        </DelButton>
                                                    </Grid> 
                                                </Grid>
                                            )
                                        })
                                    }
                                </Grid>
                                <Grid item>
                                    <AddButton 
                                        variant="contained"
                                        size="large"
                                        disableElevation
                                        startIcon={<AddRounded />}
                                        onClick={onAddDescription}
                                        >
                                        Tambah Deskripsi
                                    </AddButton>
                                </Grid>
                            </Grid> 
                        </Grid>
                    </Grid>
                </Grid>
            </ContentPaper>
            <Box>
                <Grid container sx={{marginBottom: '55px'}} justifyContent="space-between" spacing={3}>
                    <Grid item>
                    </Grid>
                    <Grid item>
                        <Grid container spacing={3}>
                            <Grid item>
                                <BackButton 
                                    variant="contained"
                                    size="large"
                                    disableElevation
                                    disabled={inProgress}
                                    onClick={ () => {navigate(`/dashboard/job-positions`)}}>
                                    Batal
                                </BackButton>
                            </Grid>
                            <Grid item>
                                <Button 
                                    variant="contained"
                                    size="large"
                                    color="primary"
                                    sx={{minWidth: 195}}
                                    onClick={onSubmit}
                                    disabled={inProgress}
                                    disableElevation>
                                    {inProgress ? <CircularProgress size={26} /> : 'Simpan'}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>  
            </Box>
        </Box>
    )
}

const ProgressBox = styled(Box)(({theme}) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%'
 }));

const ContentPaper = styled(Paper)(({theme}) => ({
    padding: 35,
    paddingTop: 32,
    marginBottom: 25
}));

const BackButton = styled(Button)(({theme}) => ({
    color: theme.palette.text.primary,
    backgroundColor: "#FFFFFF",
    '&:hover': {
    backgroundColor: "#FFFFFF",
    },
    fontWeight: 500,
    border: '1px solid #ddd',
    minWidth: 195
}));

const DelButton = styled(Button)(({theme}) => ({
    color: theme.palette.text.primary,
    backgroundColor: "#FFFFFF",
    '&:hover': {
    backgroundColor: "#FFFFFF",
    },
    fontWeight: 500,
    border: `1px solid #ddd`,
    height: 56
}));

const AddButton = styled(Button)(({theme}) => ({
    color: theme.palette.primary.main,
    backgroundColor: "#FFFFFF",
    '&:hover': {
      backgroundColor: "#FFFFFF",
    },
    fontWeight: 500,
    border: `1px solid ${theme.palette.primary.main}`
}));
